import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_INFO = gql`
  query GetInfo($id: Int!) {
    getInfo(id: $id) {
      id
      name
      icon_id
      icon
    }
  }
`;

export function useGetInfo(id) {
  const { data, loading, error } = useQuery(GET_INFO, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'no-cache',
  });
  return { data, loading, error };
}
