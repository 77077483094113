import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/get-infos';

export default graphql(
  gql`
    mutation CreateInfo($input: CreateInfoInput!) {
      createInfo(input: $input) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createInfo: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createInfo } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: {
                  input: search,
                },
              });

              const sort = (createInfo) => {
                const newResults = data.getInfos.results.concat(createInfo);

                newResults.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });

                return newResults;
              };

              proxy.writeQuery({
                query,
                variables: {
                  input: search,
                },
                data: {
                  ...data,
                  getInfos: {
                    ...data.getInfos,
                    count: data.getInfos.count + 1,
                    results: sort(createInfo),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
