import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/UseGetCategories';

export default graphql(
  gql`
    mutation UpdateCategory($input: CreateCategoryInput!) {
      updateCategory(input: $input) {
        id
        name
        type
        icon_id
        icon
        code
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateCategory: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateCategory } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query: query,
                variables: { input: { ...search } },
              });

              proxy.writeQuery({
                query: query,
                variables: { input: { ...search } },
                data: {
                  ...data,
                  getCategory: {
                    ...data.getCategory,
                    count: data.getCategory.count,
                    results: data.getCategory.results.reduce(
                      (acc, category) => {
                        if (parseInt(category.id) === parseInt(input.id)) {
                          return acc.concat([updateCategory]);
                        }

                        return acc.concat([category]);
                      },
                      []
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
