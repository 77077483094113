import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    getAccount(id: $id) {
      id
      legal_name
      business_dba
      primary_contact
      email
      title
      country_code
      calling_code
      phone
      currency
      logo
      logo_id
      mailing {
        name
        address_line_1
        address_line_2
        city
        state
        zipcode
      }
      track {
        name
        address_line_1
        address_line_2
        city
        state
        zipcode
      }
      shipping {
        name
        address_line_1
        address_line_2
        city
        state
        zipcode
      }
      open_credits {
        credit_total
        num_credits
      }
      members {
        id
        formatted_name
        email
        cellphone
      }
      track_ids
      series_ids
      stripe_account_id
      stripe_url
      series {
        id
        name
      }
      tracks {
        id
        name
      }
      janam_scanners
    }
  }
`;

export function useGetAccount(currentAccount) {
  const { data, loading, error } = useQuery(GET_ACCOUNT, {
    variables: { id: currentAccount },
    fetchPolicy: 'no-cache',
    skip: !currentAccount,
  });
  return { data, loading, error };
}
