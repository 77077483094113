import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-promos';

export default graphql(
  gql`
    mutation UpdatePromoV2($input: UpdatePromoInput!) {
      updatePromoV2(input: $input) {
        id
        name
        expiration
        no_expiration
        ticket_discount
        service_discount
        is_expired
        limit
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updatePromo: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updatePromo } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: { input: search },
              });

              proxy.writeQuery({
                query,
                variables: { input: search },
                data: {
                  ...data,
                  getPromos: {
                    ...data.getPromos,
                    count: data.getPromos.count + 1,
                    results: data.getPromos.results.reduce((acc, promo) => {
                      if (parseInt(promo.id) === parseInt(input.id)) {
                        return acc.concat([updatePromo]);
                      }
                      return acc.concat([promo]);
                    }, []),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
