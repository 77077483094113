import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import Text from 'components/Text';
import { IssueCreditModal } from 'pages/app/Credits/issueCreditModal';
import { formatTimestamp } from 'shared/formatters';
import PassesModal from '../../../app/Users/passesModal';
import GetCustomerTransaction from '../gql/queries/get-customer-transactions';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const OpenModal = styled.button`
  color: #fa4616;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

const Transactions = (props) => {
  const { data, hideIssueCredit = false } = props;
  const [showModal, setShowModal] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  if (!data.getCustomerTransactions) return false;

  return (
    <>
      <Container>
        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              color="#3C4144"
              inlineStyle={{
                marginRight: !isMobile ? 25 : 0,
                width: isMobile ? '100%' : 400,
                marginBottom: isMobile ? 20 : 0,
              }}
            >
              Transaction History
            </Text>
          </TitleContainer>
          {!hideIssueCredit && (
            <CreditButton>
              <Button
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          )}
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col">Date</TableCell>
                <TableCell scope="col">Event Name</TableCell>
                <TableCell scope="col">Track Name</TableCell>
                <TableCell scope="col">Total</TableCell>
                <TableCell scope="col">Fee</TableCell>
                <TableCell scope="col">Discount</TableCell>
                <TableCell scope="col">Refund</TableCell>

                <TableCell scope="col" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.getCustomerTransactions.results.length ? (
                data.getCustomerTransactions.results.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <LineHeightText>
                          {formatTimestamp(
                            Number(item.purchase_date),
                            'h:mm a, MMM DD, YYYY',
                            true
                          )}
                        </LineHeightText>
                      </TableCell>
                      <TableCell>
                        <OpenModal onClick={() => setShowModal(item)}>
                          {item.event?.name}
                        </OpenModal>
                      </TableCell>
                      <TableCell>
                        <LineHeightText>{item.track?.name} </LineHeightText>
                      </TableCell>
                      <TableCell>{item.total}</TableCell>
                      <TableCell>{item.fee}</TableCell>
                      <TableCell>{item.discount}</TableCell>
                      <TableCell>{item.refund}</TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <p style={{ marginTop: 20 }}>No transactions for this user</p>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {showModal ? (
          <PassesModal
            currentTransaction={showModal}
            close={() => setShowModal(null)}
          />
        ) : null}

        <Pagination
          count={props.data.getCustomerTransactions.count}
          perPage={15}
          currentPage={currentPage || 1}
        />
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={true}
        vieEmployeeSearch={false}
        customerId={data.variables.input.user_id ?? ''}
      />
    </>
  );
};

export default GetCustomerTransaction(Transactions);
