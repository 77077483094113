import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import { withEventYearFilterContext } from 'components/YearDropdown';
import { useGetAccountCreditsBreakdown } from '../../Financials/gql/get-account-credits-breakdown';
import CreditDetail from '../CreditsList/CreditDetail';
import UserCreditList from '../CreditsList/UserCreditList';

const FinancialsMobile = withEventYearFilterContext((props) => {
  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [credits, setCredits] = useState();
  const [count, setCount] = useState();

  const [detailIndex, setDetailIndex] = useState();

  const { res } = useGetAccountCreditsBreakdown();

  useEffect(() => {
    if (res && res.getAccountCreditsBreakdown) {
      setCredits(res?.getAccountCreditsBreakdown);
      setCount(res?.getAccountCreditsBreakdown?.length);
    }
  }, [res]);

  if (!credits) return false;

  return detailIndex === undefined ? (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <Spacer size={10} />
        <UserCreditList
          credits={credits}
          currentPage={currentPage}
          setDetailIndex={setDetailIndex}
          isFinancial={true}
        />
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
    </>
  ) : (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <Spacer size={10} />
        <CreditDetail credit={credits[detailIndex]} isFinancial={true} />
      </div>
    </>
  );
});

export default withTheme(FinancialsMobile);
