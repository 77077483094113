import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import qs from 'qs';
import { logDevError } from 'shared/alerts';
import { GET_ANNUAL_TICKETS } from '../../gql/UseGetAnnualTickets';

export const DUPLICATE_ANNUAL = gql`
  mutation DuplicateAnnual($input: Int!) {
    duplicateAnnual(annual_id: $input) {
      id
      series {
        id
        name
      }
      tracks {
        id
        name
      }
      account {
        business_dba
      }
      ticket {
        name
        sale_end
      }
      name
      status
      events {
        id
        name
      }
    }
  }
`;

export function useDuplicateAnnual() {
  const [duplicateAnnualMutation] = useMutation(DUPLICATE_ANNUAL);
  const duplicateAnnual = async (id, searchData) => {
    try {
      const response = await duplicateAnnualMutation({
        variables: { input: id },
        update: (proxy, { data: { duplicateAnnual } }) => {
          const search = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
          });

          const data = proxy.readQuery({
            query: GET_ANNUAL_TICKETS,
            variables: { getAnnualTicketsInput: { ...search, ...searchData } },
          });

          const sort = (duplicateAnnual) => {
            const newResults =
              data.getAnnualTickets.results.concat(duplicateAnnual);

            newResults.sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            return newResults;
          };

          proxy.writeQuery({
            query: GET_ANNUAL_TICKETS,
            variables: { getAnnualTicketsInput: { ...search, ...searchData } },
            data: {
              ...data,
              getAnnualTickets: {
                ...data.getAnnualTickets,
                count: data.getAnnualTickets.count + 1,
                results: sort(duplicateAnnual),
              },
            },
          });
        },
      });
      return response;
    } catch (error) {
      logDevError(error);
    }
  };

  return duplicateAnnual;
}
