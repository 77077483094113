import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

export const GET_ACCOUNT_MEMBERSHIPS = gql`
  query GetAccountMemberships($input: AccountMembershipsInput!) {
    getAccountAnnualTickets(input: $input) {
      id
      name
    }
  }
`;

export function useGetAccountMemberships({ year, accountId }) {
  const { id } = useParams();

  const skip = !id && !accountId;

  return useQuery(GET_ACCOUNT_MEMBERSHIPS, {
    variables: {
      input: {
        account_id: accountId ? accountId : Number(id),
        year,
      },
    },
    skip,
  });
}
