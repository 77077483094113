import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const TRANSFER_MEMBERSHIP = gql`
  mutation TransferMembership($input: TransferMembershipInput!) {
    transferMembership(input: $input)
  }
`;

export function useTransferMembership() {
  const [transferMembershipMutation] = useMutation(TRANSFER_MEMBERSHIP);

  const transferMembership = useCallback(
    ({ pass_id, user_id }) => {
      return transferMembershipMutation({
        variables: {
          input: {
            user_id,
            pass_id,
          },
        },
      });
    },
    [transferMembershipMutation]
  );
  return transferMembership;
}
