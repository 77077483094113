import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [accountFilter, setAccountFilter] = useState(null);
  const [annualType, setAnnualType] = useState(null);
  const [annualEvent, setAnnualEvent] = useState(null);
  const [year, setYear] = useState(moment().format('YYYY'));

  const [purchaseAccountFilter, setPurchaseAccountFilter] = useState(null);
  const [purchaseAnnualType, setPurchaseAnnualType] = useState(null);
  const [purchaseAnnualEvent, setPurchaseAnnualEvent] = useState(null);
  const [purchaseYear, setPurchaseYear] = useState(moment().format('YYYY'));

  return (
    <FilterContext.Provider
      value={{
        accountFilter,
        setAccountFilter,
        annualType,
        setAnnualType,
        annualEvent,
        setAnnualEvent,
        purchaseAccountFilter,
        setPurchaseAccountFilter,
        purchaseAnnualType,
        setPurchaseAnnualType,
        purchaseAnnualEvent,
        setPurchaseAnnualEvent,
        year,
        setYear,
        purchaseYear,
        setPurchaseYear,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};
