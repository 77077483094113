import React from 'react';
import {
  Container,
  Heading,
  RadarBox,
  RadarRow,
  RadarLabel,
  RadarTypeWrapper,
  RadarGradientWrapper
} from './style';
import { useRadarMap } from './useRadarMap';
import useWindowSize from 'hooks/useWindowSize';

export const Radar = props => {
  let { Width, lat, lng } = props;
  let [width] = useWindowSize();

  useRadarMap({
    lat,
    lng
  });

  if (!lat && !lng) return false;

  return (
    <Container Width={Width}>
      <Heading>Radar</Heading>
      <div>
        <div
          id="radarmap"
          style={{
            width: '100%',
            height: width > 800 ? 295 : 480,
            backgroundColor: 'grey'
          }}
        />
      </div>
      <RadarBox>
        <RadarRow>
          <RadarLabel>Radar</RadarLabel>
          <RadarGradientWrapper>
            <img
              alt="Radar"
              src={'https://d3294qt0f4hbwl.cloudfront.net/radar3.png'}
            />
            <img
              alt="Radar Ice"
              src={'https://d3294qt0f4hbwl.cloudfront.net/radar-ice4.png'}
            />
            <img
              alt="Radar Snow"
              src={'https://d3294qt0f4hbwl.cloudfront.net/radar-snow3.png'}
            />
          </RadarGradientWrapper>
        </RadarRow>

        <RadarRow>
          <RadarLabel>Lightning</RadarLabel>
          <RadarTypeWrapper>
            <img
              alt="Radar Snow"
              src={
                'https://d3294qt0f4hbwl.cloudfront.net/lightning-legend2.png'
              }
            />
          </RadarTypeWrapper>
        </RadarRow>
        <RadarRow>
          <RadarLabel>Storm Cells</RadarLabel>
          <RadarTypeWrapper>
            <img
              alt="Radar Snow"
              src={'https://d3294qt0f4hbwl.cloudfront.net/stormcells.png'}
            />
          </RadarTypeWrapper>
        </RadarRow>
      </RadarBox>
    </Container>
  );
};
