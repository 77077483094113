import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import PromoterDefault from 'components/PromoterDefault';
import { UpcomingEvents } from 'components/UpcomingEvents';
import { VideoResourcesCarousel as VideoResources } from 'components/Video';
import { WeatherWidget } from 'components/Weather';
import { AppContext } from 'shared/AppContext';
import storage from 'shared/storage';
import { Metrics } from '../Metrics';
import PermissionModal from '../PermissionModal/PermissionModal';

const FlexContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`;

export const TrackHome = (props) => {
  const {
    state: { user },
  } = useContext(AppContext);

  const hasOwnership = !user.accounts.length ? false : true;

  const [accessDenied, setAccessDenied] = useState(
    props.location?.state?.from?.accessDenied
  );
  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  useEffect(() => {
    if (storage.get('promoter-user')) {
      storage.set('user', storage.get('promoter-user'));
      storage.removeItem('track-address');
      storage.removeItem('shipping-address');
      storage.removeItem('promoter-user');
      storage.removeItem('showStripe');

      storage.removeItem('accountId');
      storage.removeItem('step');
    }
  }, []);
  return (
    <div style={{ boxSizing: 'border-box' }}>
      <FlexContainer>
        {!hasOwnership ? (
          <PromoterDefault />
        ) : (
          <Fragment>
            <UpcomingEvents />
            <WeatherWidget Width="377px" showButton={true} />
            <Metrics />
          </Fragment>
        )}
      </FlexContainer>

      {hasOwnership && <VideoResources />}

      <PermissionModal
        isVisible={accessDenied}
        hideModal={() => setAccessDenied(false)}
        link={`/admin-${userType}/settings/1`}
        history={props.history}
      />
    </div>
  );
};
