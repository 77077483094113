import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Spacer from 'components/Spacer';
import Text from 'components/Text';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const UserCreditsList = (props) => {
  const { credits, setDetailIndex, isFinancial } = props;

  return (
    <>
      {!isFinancial ? (
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            margin: 'auto',
          }}
        >
          <Text type="heading" fontSize={25} lineHeight={28} color="#fff">
            {credits[0].user.formatted_name}
          </Text>
        </div>
      ) : null}
      <Spacer size={20} />
      {credits.map((credit, index) => (
        <MobileContainer
          key={index}
          onClick={() => {
            setDetailIndex(index);
          }}
        >
          <FlexRow>
            <CardInfo>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Text
                      type="heading"
                      fontSize={25}
                      lineHeight={28}
                      color="#fff"
                    >
                      {!isFinancial
                        ? `${moment(credit?.date)
                            .format('ddd MMM d - YYYY')
                            .toUpperCase()} | ${credit?.amount}`
                        : `${credit?.year} | ${credit?.total}`}
                    </Text>
                    <Spacer size={10} />
                  </div>
                </div>
              </div>
            </CardInfo>
            <div style={{ transform: 'rotate(-90deg)' }}>
              <Icon icon="chevron" size={22} color={'red'} />
            </div>
          </FlexRow>
        </MobileContainer>
      ))}
    </>
  );
};

export default withRouter(UserCreditsList);
