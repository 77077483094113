import { gql } from 'graphql-tag';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNT_OPEN_CREDITS = gql`
  query GetAccountOpenCredits($creditsInput: GetAccountCreditsInput!) {
    getAccountOpenCredits(input: $creditsInput) {
      count
      results {
        id
        formatted_name
      }
    }
  }
`;

export function useGetAccountOpenCredits() {
  const { id: account_id } = useParams();
  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(
    GET_ACCOUNT_OPEN_CREDITS,
    `account-open-credits-${account_id}`,
    {
      variables: {
        creditsInput: {
          account_id: Number(account_id),
          ...(yearFilter ? { year: yearFilter } : null),
          ...omit(search, ['accountName']),
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, res: result.data };
}
