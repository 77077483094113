/* eslint-disable */

import React, { useState } from 'react';
import styled from 'styled-components';
import { EventStatusColorSelect } from 'components/Events';
import { Select } from 'components/Form/Select';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { DateCard } from 'components/DateCard';

import {
  MainRow,
  HeaderMain,
  CardText,
  EventHeaderSection,
} from 'pages/app/Events/AddEvents/styles';
import { toast } from 'react-toastify';
import moment from 'moment';
import Spacer from 'components/Spacer';
import Icon from 'components/Icon';

const HeaderWrapper = styled.div`
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 40px 20px 40px;
  }
`;

const Header = styled.div`
  width: 80vw;
`;

const AnnualTicketsHeader = ({
  values,
  status_color,
  handleChange,
  handleBlur,
  errors,
  SDValue,
  EDValue,
  status,
}) => {
  const theme = useTheme();
  let item = null;
  if (SDValue) {
    item = {
      date: moment(SDValue, 'MM-DD-YYYY').format('MMM D'),
      day: moment(SDValue, 'MM-DD-YYYY').format('ddd'),
      month: moment(SDValue, 'MM-DD-YYYY').format('MMM'),
      end_date: moment(EDValue, 'MM-DD-YYYY').format('MM-DD-YYYY'),
      isMultiDay: true,
      listDates:
        moment(SDValue, 'MM-DD-YYYY').format('MMM YYYY') +
        ' - ' +
        (EDValue ? moment(EDValue, 'MM-DD-YYYY').format('MMM YYYY') : ''),
    };
  }
  return (
    <>
      <HeaderWrapper>
        <Header>
          <div
            style={{
              display: 'flex',
              alignItems: 'right',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <div>
                {item !== null && (
                  <DateCard
                    item={item}
                    margin={'margin: 0 10px 0 0'}
                    isAnnual
                  />
                )}
              </div>
              <div>
                <div>
                  <Text
                    type="heading"
                    fontSize={32}
                    color={theme.colors.secondary}
                  >
                    {values.name}
                  </Text>
                </div>
                {values.account_id ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginTop: 5, marginRight: 5 }}>
                      <Icon size={18} icon="location" color="#fa4616" />
                    </div>
                    <Text
                      type="heading"
                      fontSize={25}
                      color={theme.colors.secondary}
                    >
                      {values.account_id.label}
                    </Text>
                  </div>
                ) : null}
              </div>
            </div>

            <div style={{ width: '20%', display: 'flex' }}>
              <div style={{ width: '70%' }}>
                <Select
                  id="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Published', value: 'published' },
                    { label: 'Postponed', value: 'postponed' },
                    { label: 'Cancelled', value: 'cancelled' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={status}
                  error={errors.status}
                />
              </div>
              <div style={{ width: '30%', paddingLeft: '5px' }}>
                <Spacer size={35} />
                <CardText>
                  <EventStatusColorSelect statusColor={status_color} />
                </CardText>
              </div>
            </div>
          </div>
        </Header>
      </HeaderWrapper>
    </>
  );
};

export default AnnualTicketsHeader;
