import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useTransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ANNUAL_PURCHASE_LIST = gql`
  query GetAnnualPurchaseList($input: AnnualPurchaseListInput!) {
    getAnnualPurchaseList(input: $input) {
      count
      results {
        id
        fee
        total
        refund
        subtotal
        discount
        credit
        subtotal
        transfer_id
        charge_id
        card {
          last4
        }
        purchase_date
        status
        user {
          id
          name
          first_name
          last_name
          formatted_name
        }
        users {
          promo
          user {
            id
            name
            first_name
            last_name
          }
          tickets {
            id
            name
            price
            refunded
            type
            barcode
            credited
          }
        }
        international_fee
      }
    }
  }
`;

export function useGetAnnualPurchaseList() {
  const { input } = useSearchInput();
  const { id } = useParams();
  const { startDateFilter, endDateFilter } = useTransactionDateFilter();

  return useQuery(GET_ANNUAL_PURCHASE_LIST, {
    variables: {
      input: {
        ticket_id: Number(id),
        ...(input?.page ? { page: input?.page } : null),
        ...(startDateFilter ? { start_date: startDateFilter } : null),
        ...(endDateFilter ? { end_date: endDateFilter } : null),
        ...(input?.queryString ? { queryString: input?.queryString } : null),
      },
    },
  });
}
