import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import { query } from '../queries/get-employees';

export default graphql(
  gql`
    mutation DeleteEmployee($input: DeleteEmployeeInput!) {
      deleteEmployeeV2(input: $input)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteEmployee: async (id, account_id) => {
        return mutator(() =>
          mutate({
            variables: { input: { id, account_id } },
            update: (proxy, { data: { deleteEmployeeV2 } }) => {
              if (!deleteEmployeeV2) {
                return;
              }

              const data = proxy.readQuery({
                query,
                variables: {
                  input: {
                    account_id,
                  },
                },
              });

              proxy.writeQuery({
                query,
                variables: {
                  input: {
                    account_id,
                  },
                },
                data: {
                  ...data,
                  getEmployeesV2: {
                    ...data.getEmployeesV2,
                    count: data.getEmployeesV2.count - 1,
                    results: data.getEmployeesV2.results.filter(
                      (user) => user.id !== id
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
