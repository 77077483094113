import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import { query } from '../queries/get-employees';

export default graphql(
  gql`
    mutation UpdateEmployee($input: UpdateEmployeeInputV2!) {
      updateEmployeeV2(input: $input) {
        id
        first_name
        last_name
        email
        role
        cellphone
        calling_code
        country_code
        middle_name
        suffix
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateEmployeeV2: async (input, account_id) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateEmployeeV2 } }) => {
              const data = proxy.readQuery({
                query,
                variables: {
                  input: {
                    account_id,
                  },
                },
              });
              const newEmployee = {
                ...updateEmployeeV2,
                middle_name: '',
                suffix: '',
              };
              proxy.writeQuery({
                query,
                variables: {
                  input: {
                    account_id,
                  },
                },
                data: {
                  ...data,
                  getEmployeesV2: {
                    ...data.getEmployeesV2,
                    count: data.getEmployeesV2.count + 1,
                    results: data.getEmployeesV2.results.reduce((acc, user) => {
                      if (parseInt(user.id) === parseInt(input.id)) {
                        return acc.concat([newEmployee]);
                      }
                      return acc.concat([user]);
                    }, []),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
