import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AsyncAutoSuggest } from 'components/Form/AutoSuggest';

const SEARCH_FORM_STACK_FORMS = gql`
  query SearchFormstackForms($queryString: String!) {
    searchFormstackForms(queryString: $queryString) {
      form_id
      name
    }
  }
`;

function useSearchFormStackForms() {
  return useQuery(SEARCH_FORM_STACK_FORMS, {
    variables: { queryString: '' },
  });
}

export const FormstackAutoSuggest = (props) => {
  const { onChange, value } = props;
  const { data, refetch, loading } = useSearchFormStackForms();

  const [selectedOption, setSelectedOption] = useState();

  const handleChange = (value) => {
    setSelectedOption(value);
    onChange(value);
  };

  useEffect(() => {
    if (!loading && value && !selectedOption) {
      const newSelectedOption = data?.searchFormstackForms.filter(
        (form) => form.form_id === +value
      );

      if (newSelectedOption.length > 0) {
        setSelectedOption({
          label: newSelectedOption[0].name,
          value: newSelectedOption[0].form_id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!data?.searchFormstackForms) return null;

  return (
    <AsyncAutoSuggest
      refetch={refetch}
      prepareVariables={(inputValue) => ({ queryString: inputValue })}
      characterCount={4}
      isModal
      NoFoundText={'No form found'}
      onChange={handleChange}
      queryKey="searchFormstackForms"
      option={(formStack) => ({
        label: formStack.name,
        value: formStack.form_id,
      })}
      value={selectedOption}
    />
  );
};
FormstackAutoSuggest.propTypes = {
  onChange: PropTypes.func.isRequired,
};
