import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { AppContext } from 'shared/AppContext';
import MobileSearchInput from '../../../../track/GuestList/GuestListEventsMobile/search';
import ExportAccountMembers from '../ExportAccountMembers';
import { useGetAccountMembers } from '../gql/useGetAccountMembers';
import { useGetAccountMemberships } from '../gql/useGetAccountMemberships';
import { useGetMembershipDivisions } from '../gql/useGetMembershipDivisions';
import { MembershipFilterContainer } from '../MembersList';
import { PrintAccountMembersReport } from '../PrintAccountMembersReport';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
`;

const MembersListMobile = withEventYearFilterContext((props) => {
  const thisYear = Number(moment().format('YYYY'));
  const [year, setYear] = useState(thisYear);
  const [typeId, setTypeId] = useState();
  const [division, setDivision] = useState();
  const [search, setSearch] = useState('');

  const { location } = props;
  const { name } = useParams();

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const {
    state: { accountId },
  } = useContext(AppContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const { data } = useGetAccountMembers({
    year,
    typeId: typeId ? typeId.value : null,
    division: division ? division.value : null,
    accountId,
    search,
  });
  const { data: memberships } = useGetAccountMemberships({ year, accountId });
  const { data: divisions } = useGetMembershipDivisions(
    typeId ? typeId.value : null
  );

  const handleChange = (e) => {
    setSearch(e.target.value);
    // searchValue(e.target.value);
  };

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  if (!data?.getAccountMembers) return null;

  return (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <ReportContainer>
          <ExportAccountMembers
            icon={<Icon icon="Export-Icon" size={20} />}
            accountId={accountId}
            typeId={typeId ? typeId.value : null}
            division={division ? division.value : null}
            year={year}
            search={search}
          />
          <PrintAccountMembersReport
            text={'Print List'}
            accountId={accountId}
            accountName={name}
            ticketName={typeId ? typeId.label : null}
            typeId={typeId ? typeId.value : null}
            division={division ? division.value : null}
            year={year}
            search={search}
            style={{ marginLeft: 'auto' }}
            color={'white'}
          />
        </ReportContainer>

        <MobileSearchInput
          placeholder="Search Members"
          onChange={handleChange}
          value={search}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
        />
        <Spacer size={10} />

        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <YearDropdown
            showLabel={false}
            onSelect={(year) => setYear(year.value)}
          />
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            margin: 'auto',
          }}
        >
          {memberships && memberships.getAccountMemberships.length ? (
            <MembershipFilterContainer>
              <AutoSuggest
                placeholder="Membership Type"
                options={memberships.getAccountMemberships.map((ticket) => ({
                  label: ticket.name,
                  value: ticket.id,
                }))}
                value={typeId ? typeId : null}
                isSearchable
                isClearable
                onChange={(ticket) => {
                  setTypeId(ticket ?? null);
                }}
              />
            </MembershipFilterContainer>
          ) : null}
        </div>

        <Spacer size={5} />

        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          {typeId &&
          divisions &&
          divisions.getMembershipDivisions &&
          divisions.getMembershipDivisions.length > 0 ? (
            <AutoSuggest
              placeholder="Select Division"
              options={divisions.getMembershipDivisions.map((ticket) => ({
                label: ticket,
                value: ticket,
              }))}
              value={division ? division : null}
              isSearchable
              isClearable
              onChange={(ticket) => {
                setDivision(ticket ?? null);
              }}
            />
          ) : null}
        </div>

        <Spacer size={10} />
        {data.getAccountMembers.results.map((member) => (
          <MobileContainer
            onClick={() => {
              history.push(
                userType === 'admin'
                  ? `/admin/annualMembersDetail/member/${member.id}`
                  : `/admin-${userType}/annualMembersDetail/member/${member.id}`
              );
            }}
          >
            <FlexRow>
              <CardInfo>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Text
                        type="heading"
                        fontSize={25}
                        lineHeight={28}
                        color="#fff"
                      >
                        {member.user.formatted_name}
                      </Text>
                      <Spacer size={10} />
                    </div>
                  </div>
                </div>
              </CardInfo>
              <div style={{ transform: 'rotate(-90deg)' }}>
                <Icon icon="chevron" size={22} color={'red'} />
              </div>
            </FlexRow>
          </MobileContainer>
        ))}
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={data.getAccountMembers.count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
    </>
  );
});

export default MembersListMobile;
