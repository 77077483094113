import React, { createContext, useContext, useState } from 'react';

const MembershipContext = createContext();

export const MembershipProvider = ({ children }) => {
  const [fetchMember, setFetchMember] = useState(null);

  return (
    <MembershipContext.Provider
      value={{
        fetchMember,
        setFetchMember,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

export const useMembership = () => {
  const context = useContext(MembershipContext);
  if (context === undefined) {
    throw new Error('useMembership must be used within a MembershipProvider');
  }
  return context;
};
