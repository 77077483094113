import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useGetEventsReport } from '../PrintEventsReport/gql/useGetEventsReport.mutation';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 860px) {
    margin: 0;
    border: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;

    @media (min-width: 860px) {
      text-align: center;
      font-size: 12px;
      color: #3c4144;
      text-align: center;
      margin-top: 10px;
    }
  }

  svg {
    fill: #fff;
    margin-right: 10px;

    @media (min-width: 860px) {
      fill: #fa4616;
      margin: 0;
    }
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async (getEventsReport, type, headerText) => {
  try {
    const response = await getEventsReport(type);

    if (!response || response.errors) {
      toast.error('Events List Report can not be retrieved');
      return;
    }

    if (response.data.getEventsReport) {
      const { results } = response.data.getEventsReport;

      let _title = '',
        filename = '';

      _title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      filename = headerText + ' Events List Report - ' + _title;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const csv = `${headerText} Events List Report
      Exported:, ${dateTimeStamp} \n,,,
      Date,Event,On Sale Date, Status, added_by, admin_website_url, admin_schedule_url, track, user,
      ${Object.values(results)
        .map(
          (value) =>
            `${
              value.isMultiDay
                ? `${value.month.toUpperCase()} ${value.listDates} - ${
                    value.year
                  }`
                : `${value.day} ${value.date} - ${value.year}`
            },${value.name ?? ''},${
              moment(value.sale_start, 'MM-DD-YYYY')
                .format('ddd MMM D - YYYY')
                .toUpperCase() ?? ''
            },${value.status},${
              value.added_by
                ? value.added_by.includes(',')
                  ? value.added_by.replace(',', '')
                  : value.added_by
                : ''
            },${value.admin_website_url ?? ''},${
              value.admin_schedule_url ?? ''
            },${value.track ? value.track.name : ''},${
              value.user ? value.user.name : ''
            },`
        )
        .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Events List Report can not be retrieved');
    }
  } catch (e) {}
};

const ExportEventsReport = ({ icon, type, headerText }) => {
  const getEventsReport = useGetEventsReport();

  return (
    <div>
      <Container onClick={() => exportCsv(getEventsReport, type, headerText)}>
        <IconWrapper>
          {icon}
          <span>Export List</span>
        </IconWrapper>
      </Container>
    </div>
  );
};

export default ExportEventsReport;
