import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

export const GET_EVENT_REFUND_STATUS = gql`
  query getEventRefundStatus($event_id: Int!) {
    getEventRefundStatus(event_id: $event_id)
  }
`;

export function useGetRefundStatus() {
  const { id } = useParams();
  return useQuery(GET_EVENT_REFUND_STATUS, {
    variables: { event_id: Number(id) },
  });
}
