import moment from 'moment';
import React from 'react';
import { withTheme } from 'styled-components';
import Icon from 'components/Icon';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import { formatPhoneNumber } from 'shared/formatters';
import GetPendingUsers from './gql/get-pending-users';

const TemporaryUsers = (props) => {
  const { data, history } = props;
  const users = data?.getPendingUsers?.results ?? [];

  if (!data?.getPendingUsers) return null;

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Mobile Number',
      key: 'mobile',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'DoB',
      key: 'dob',
    },
    {
      label: 'Requested',
      key: 'requested',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(user) {
    const { id, name, cellphone, email, birthday, created } = user;
    return {
      name: name,
      mobile: formatPhoneNumber(cellphone),
      email: email,
      dob: birthday,
      requested: moment(created).format('ddd MMM D - YYYY').toUpperCase(),
      actions: (
        <>
          <Icon
            icon="edit"
            size={18}
            color={props.theme.colors.primary}
            onClick={async () => {
              history.push(`/admin/temporaryUsers/${id}`);
            }}
            padding="0 15px 0 0"
          />
        </>
      ),
    };
  }

  return (
    <>
      <SearchableListContainer
        pageCount={data.getPendingUsers.count}
        noSearch
        paginated
        title="Temporary Users"
        searchInputPlaceholder="Search Users"
      >
        <Table items={users} columns={columns} renderRows={renderRows} />
      </SearchableListContainer>
    </>
  );
};

export default withTheme(GetPendingUsers(TemporaryUsers));
