import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { usePersistedQuery } from 'hooks/usePersistedQuery';

export const GET_TICKETS_LIST = gql`
  query GetTicketsList(
    $input: GetSpectatorTicketsList!
    $getEventInput: GetEventInput!
    $countInput: SpectatorCountInput!
  ) {
    getEvent(input: $getEventInput) {
      id
      isMultiDay
      name
      month
      day
      date
      start_date
      listDates
      end_date
      track {
        id
        logo
      }
      series {
        id
        logo
      }
      status
    }
    getSpectatorPurchases(input: $input) {
      count
      results {
        id
        ticket_count
        user {
          name
          formatted_name
        }
      }
    }
    getSpectatorCount(input: $countInput)
  }
`;

export function useGetTicketsList({ date, search, page }) {
  const { id: eventId } = useParams();

  const result = usePersistedQuery(
    GET_TICKETS_LIST,
    `tickets_list-${eventId}-${date}`,
    {
      variables: {
        input: {
          event_id: Number(eventId),
          queryString: search ? search.trim() : '',
          date,
          page: page,
        },
        countInput: {
          event_id: Number(eventId),
          date,
        },
        getEventInput: {
          id: Number(eventId),
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result };
}
