import { gql } from 'graphql-tag';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const VERIFIED_USERS = gql`
  query GetVerifiedUsers($input: GetVerifiedUsersInput!) {
    getVerifiedUsers(input: $input) {
      count
      results {
        id
        name
        cellphone
        email
        birthday
        status
        approved
        approved_by {
          id
          name
          formatted_name
        }
      }
    }
  }
`;

export function useGetVerifiedUsers() {
  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(VERIFIED_USERS, `verified-users-${search}`, {
    variables: {
      input: {
        ...(yearFilter ? { year: yearFilter } : null),
        page: search && search.page ? Number(search.page) : 1,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { ...result, data: result.data };
}
