import { Formik } from 'formik';
import React, { useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled from 'styled-components';
import {
  EventNameWrapper,
  HeaderWrapper,
  StatusWrapper,
} from 'components/Events/EventHeader';
import { FaqList } from 'components/Faqs';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import { Select } from 'components/Form/Select';
import { Label } from 'components/Form/styles';
import RowSection from 'components/RowSection';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  AddUpdateBtn,
  Content,
  Header,
  SectionTitle,
  Tab,
  TabContent,
  Tabs,
} from 'pages/app/Events/AddEvents/styles';
import Account from 'pages/app/Events/EventEdit/Dropdown/account';
import InfosRepeater from 'pages/app/Infos/InfosRepeater';
import {
  Column,
  ColumnText,
  TrackLogo,
} from 'pages/track/Events/Header/StyledHeader';
import States from 'shared/us-states';
import { useGetTrackEditData } from './gql';
import CreateTrack from './gql/createTrack';
import UpdateTrack from './gql/updateTrack';
import Types from './Types';

const formValues = [
  'name',
  'short_name',
  'street',
  'state',
  'city',
  'country',
  'zipcode',
  'phone',
  'calling_code',
  'country_code',
  'timezone',
  'website',
  'added_by',
  'schedule',
  'user_id',
  'type_id',
  'bio',
  'twitter',
  'facebook',
  'instagram',
  'youtube',
  'tiktok',
  'status',
  'size',
  'image_id',

  'facebook_image_id',
  'facebook_image',

  'twitter_image_id',
  'twitter_image',

  'image',
  'logo_id',
  'logo',
  'faqs',
  'account_id',
  'infos',
  'infos_with_order',
  'url',
  'promoter_name',
  'promoter_email',
  'promoter_phone',
  'chart_id',
];

export const TrackBody = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

function getInitialValues(track, keys) {
  const { type, faqs, infos } = track ?? {};
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
    faqs: [],
    infos: [],
    infos_with_order: [],
  };
  const initialValues = !track
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return track[key]
            ? {
                ...formValues,
                [key]: track[key],
              }
            : formValues;
        }, {}),
        type_id: type?.id ?? '',
        type: type?.key,
        faqs: faqs?.map((faq) => {
          const { ...rest } = faq;
          return { ...rest };
        }),
        infos: infos
          ? infos.map((info) => {
              return {
                ...info,
                info: { value: info.info.id, label: info.info.name },
              };
            })
          : [],
        infos_with_order: infos
          ? infos.map((info) => {
              return {
                ...info,
                info: { value: info.info.id, label: info.info.name },
              };
            })
          : [],
      };
  return initialValues;
}

function getTrackInput({ data, currentTrack, action }) {
  const input = {
    ...data,
    ...(action === 'update' && currentTrack ? { id: currentTrack } : null),
    ...(action === 'update' && currentTrack
      ? { type_id: String(data.type_id) }
      : null),
    user_id: data?.user_id?.value ?? data?.user_id,
    faqs: data.faqs.map((faq, index) => {
      return {
        question: faq.question,
        answer: faq.answer,
        order: index,
      };
    }),
    account_id: data.account_id ? data.account_id.value : null,
    infos: data.infos
      ? data.infos.map((info) => {
          return {
            ...(info.id ? { id: info.id } : null),
            logo_id: info.logo_id ? Number(info.logo_id) : null,
            link: info.link,
            text: info.text,
            info_id: info.info.value,
            order: info.order,
            video_url: info.video_url,
            image_id: info.image_id ? Number(info.image_id) : null,
            pdf_id: info.pdf_id ? Number(info.pdf_id) : null,
          };
        })
      : null,
  };
  return input;
}

function getInfosWithOrders(infos, index) {
  const { id, info, link, text, logo_id, video_url, image_id, pdf_id } = infos;
  return {
    id,
    info,
    link,
    text,
    logo_id,
    order: index,
    video_url,
    image_id,
    pdf_id,
  };
}

const TrackEdit = ({ createTrack, updateTrack, props }) => {
  const successMessage = () =>
    toast.success(currentTrack ? 'Track Updated' : 'Track Created');
  const errorMessage = (response) =>
    toast.error(
      currentTrack
        ? response.global
          ? 'Error Updating Track'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Track'
    );

  const history = useHistory();
  const params = useParams();

  const currentTrack = Number(params.id);

  const tabsConfig = [
    {
      id: 0,
      name: 'Details',
    },
    {
      id: 1,
      name: 'Contacts',
    },
    {
      id: 2,
      name: 'General Info',
    },
    {
      id: 3,
      name: 'Faqs',
    },
  ];

  const [active, setActive] = useState(0);
  const [chosenTab, setChosenTab] = useState(tabsConfig[0]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { data } = useGetTrackEditData(currentTrack);

  if (currentTrack && !data?.getTrack) return null;

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={getInitialValues(data?.getTrack, formValues)}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        if (!values.timezone) {
          errors.timezone = 'Required';
        }

        if (!values.type_id) {
          errors.type_id = 'Required';
        }

        if (!values.street) {
          errors.street = 'Required';
        }

        if (!values.city) {
          errors.city = 'Required';
        }

        if (!values.state) {
          errors.state = 'Required';
        }

        if (!values.country) {
          errors.country = 'Required';
        }

        if (!values.zipcode) {
          errors.zipcode = 'Required';
        }

        if (!values.phone) {
          errors.phone = 'Required';
        }

        const faqErrors = values.faqs.reduce((errors, faq, index) => {
          const { answer, question } = faq;
          const error =
            !answer || !question
              ? {
                  ...(!answer ? { answer: 'Required' } : null),
                  ...(!question ? { question: 'Required' } : null),
                }
              : undefined;
          return {
            ...errors,
            ...(error ? { [index]: error } : null),
          };
        }, {});
        if (Object.keys(faqErrors).length > 0) errors.faqs = faqErrors;

        if (Object.keys(errors).length) {
          toast.error('Please check your form for errors.');
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        let response;
        setSubmitting(true);

        const fieldsToDelete = [
          'logo',
          'image',
          'waivers',
          'facebook_image',
          'twitter_image',
        ];
        const data = Object.entries(values).reduce((acc, [key, value]) => {
          if (fieldsToDelete.includes(key)) {
            return acc;
          }

          return {
            ...acc,
            [key]: value === '' ? null : value,
          };
        }, {});

        data.phone = data['phone']
          ? data['phone'].replace(
              `+${data['calling_code'] ? data['calling_code'] : 1}`,
              ''
            )
          : '';

        if (data['infos_with_order'] && data['infos_with_order'].length > 0) {
          data['infos'] = data['infos_with_order'].map((info, index) => ({
            ...getInfosWithOrders(info, index),
          }));
        }
        delete data['infos_with_order'];

        if (currentTrack) {
          response = await updateTrack(
            getTrackInput({ data, currentTrack, action: 'update' })
          );
        } else {
          response = await createTrack(
            getTrackInput({ data, currentTrack, action: 'create' })
          );
        }

        setSubmitting(false);

        if (!response || response.errors) {
          errorMessage(response);
          return setErrors(response.errors);
        } else {
          successMessage();
          resetForm();
          history.goBack();
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        setFieldTouched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <Header>
            <HeaderWrapper>
              <EventNameWrapper>
                <TrackLogo>
                  <img
                    style={{ width: '100%', height: 'auto', display: 'block' }}
                    src={values.logo ?? ''}
                    alt="logo"
                  />
                </TrackLogo>
                <Column>
                  <ColumnText>
                    <Text fontSize={24} lineHeight={29} type="heading">
                      {values.name ?? ''}
                    </Text>
                    <Text fontSize={18} lineHeight={28}>
                      {`${values.city} ${values.state}` ?? ''}
                    </Text>
                  </ColumnText>
                </Column>
              </EventNameWrapper>
              <StatusWrapper>
                <Select
                  id="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Published', value: 'published' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                  error={errors.status || touched.status}
                />
              </StatusWrapper>
            </HeaderWrapper>
          </Header>
          <TrackBody>
            <TabContent>
              <Tabs>
                <div style={{ width: '90%' }}>
                  {!isMobile ? (
                    tabsConfig.map((tab) => (
                      <Tab
                        onClick={(e) => handleClick(e)}
                        active={active === tab.id}
                        id={tab.id}
                      >
                        {tab.name}
                      </Tab>
                    ))
                  ) : (
                    <div style={{ width: '90%', margin: '0 5vw' }}>
                      <AutoSuggest
                        value={chosenTab}
                        onChange={(value) => {
                          setChosenTab(value);
                          setActive(value.value);
                        }}
                        onBlur={() => {
                          return;
                        }}
                        closeMenuOnSelect
                        options={tabsConfig.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })}
                      />
                      <Spacer size={10} />
                    </div>
                  )}
                </div>

                <AddUpdateBtn type="submit" disabled={isSubmitting} block>
                  {currentTrack ? 'Update Track' : 'Add Track'}
                </AddUpdateBtn>
              </Tabs>

              <Content active={active === 0}>
                <div style={{ width: '60%', marginLeft: 10 }}>
                  <Account
                    values={values}
                    errors={errors}
                    touched={touched}
                    id={currentTrack}
                    setFieldValue={setFieldValue}
                    currentTrack={currentTrack}
                    setFieldTouched={setFieldValue}
                  />
                  <Spacer size={18} />

                  <Dropzone
                    id="logo_id"
                    files={
                      values.logo_id
                        ? [{ name: values.logo_id, preview: values.logo }]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={errors.logo_id || touched.logo_id}
                    label="Track Logo"
                  />
                  <Dropzone
                    id="image_id"
                    files={
                      values.image_id
                        ? [{ name: values.image_id, preview: values.image }]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={errors.image_id || touched.image_id}
                    label="Track Image"
                  />

                  <Dropzone
                    id="facebook_image_id"
                    files={
                      values.facebook_image_id
                        ? [
                            {
                              name: values.facebook_image_id,
                              preview: values.facebook_image,
                            },
                          ]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={
                      errors.facebook_image_id || touched.facebook_image_id
                    }
                    label="Facebook Share Image"
                  />

                  <Dropzone
                    id="twitter_image_id"
                    files={
                      values.twitter_image_id
                        ? [
                            {
                              name: values.twitter_image_id,
                              preview: values.twitter_image,
                            },
                          ]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={errors.twitter_image_id || touched.twitter_image_id}
                    label="Twitter Share Image"
                  />

                  <Input
                    id="name"
                    label="Track Name"
                    placeholder="Track Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name}
                  />
                  <Spacer size={18} />
                  <Input
                    id="short_name"
                    label="Track Short Name"
                    placeholder="Short Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.short_name}
                    error={errors.short_name}
                  />
                  <Spacer size={18} />
                  <Input
                    id="chart_id"
                    label="Seating Chart ID"
                    placeholder="Chart ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.chart_id}
                    error={errors.chart_id}
                  />
                  <Spacer size={18} />
                  <Input
                    id="street"
                    label="Track Location"
                    placeholder="Street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                    error={errors.street || touched.street}
                  />
                  <Spacer size={10} />
                  <Input
                    id="city"
                    placeholder="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={errors.city || touched.city}
                  />
                  <Spacer size={10} />
                  <Select
                    id="state"
                    placeholder="State"
                    options={States}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                    error={errors.state || touched.state}
                  />
                  <Spacer size={10} />
                  <Input
                    id="zipcode"
                    placeholder="Zip Code / Postal Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipcode}
                    error={errors.zipcode || touched.zipcode}
                  />
                  <Spacer size={10} />
                  <Select
                    id="country"
                    label="Country"
                    placeholder="Country"
                    options={[
                      { label: 'United States', value: 'United States' },
                      { label: 'Canada', value: 'Canada' },
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    error={errors.country || touched.country}
                  />
                  <Spacer size={18} />
                  <PhoneInput
                    id="phone"
                    placeholder="___ ___ ____"
                    size={25}
                    label="Phone Number"
                    onBlur={handleBlur}
                    onChange={(value) => {
                      setFieldValue('phone', value ?? '');
                    }}
                    onCountryChange={(e) => {
                      if (e) {
                        setFieldValue('country_code', e ?? '');
                        setFieldValue(
                          'calling_code',
                          getCountryCallingCode(e) ?? ''
                        );
                      }
                    }}
                    value={values.phone}
                    error={errors.phone}
                  />
                  <Spacer size={18} />
                  <Select
                    id="timezone"
                    label="Time Zone"
                    placeholder="Time Zone"
                    options={[
                      { label: 'EST', value: 'America/New_York' },
                      { label: 'PST', value: 'America/Los_Angeles' },
                      { label: 'CST', value: 'America/Chicago' },
                      { label: 'MST', value: 'America/Denver' },
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.timezone}
                    error={errors.timezone || touched.timezone}
                  />

                  <Spacer size={18} />
                  <AutoSuggest
                    name="user_id"
                    label="Track Owner"
                    value={data?.getAllUsers.map((item) => {
                      return item.id === values.user_id
                        ? {
                            value: item.id,
                            label: `${item.first_name} ${
                              item.middle_name ? item.middle_name : ''
                            } ${item.last_name}`,
                          }
                        : null;
                    })}
                    error={errors.user_id}
                    touched={touched.user_id}
                    onChange={({ value }) => {
                      setFieldValue('user_id', value);
                    }}
                    onBlur={() => setFieldTouched('user_id', true)}
                    closeMenuOnSelect
                    options={data?.getAllUsers.map((item) => ({
                      value: item.id,
                      label: `${item.first_name} ${
                        item.middle_name ? item.middle_name : ''
                      } ${item.last_name}`,
                    }))}
                  />
                  <Spacer size={18} />
                  <AutoSuggest
                    name="added_by"
                    label="Added By"
                    value={data?.getAllUsers.map((item) => {
                      return item.id === values.added_by
                        ? {
                            value: item.id,
                            label: `${item.first_name} ${
                              item.middle_name ? item.middle_name : ''
                            } ${item.last_name}`,
                          }
                        : null;
                    })}
                    error={errors.added_by}
                    touched={touched.added_by}
                    onChange={({ value }) => {
                      setFieldValue('added_by', value);
                    }}
                    onBlur={() => setFieldTouched('added_by', true)}
                    closeMenuOnSelect
                    options={data?.getAllUsers.map((item) => ({
                      value: item.id,
                      label: `${item.first_name} ${
                        item.middle_name ? item.middle_name : ''
                      } ${item.last_name}`,
                    }))}
                  />
                  <Spacer size={18} />
                </div>
              </Content>

              <Content active={active === 1}>
                <div style={{ width: '60%', marginLeft: 10 }}>
                  <Input
                    id="website"
                    label="Website"
                    placeholder="Website URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                    error={errors.website || touched.website}
                  />
                  <Spacer size={10} />
                  <Input
                    id="schedule"
                    placeholder="Schedule URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.schedule}
                    error={errors.schedule || touched.schedule}
                  />
                  <Spacer size={18} />
                  <Types
                    name="type_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type_id}
                    errors={errors}
                  />
                  <Spacer size={10} />
                  <Input
                    id="size"
                    placeholder="Track Size (eg. 1/2)"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.size}
                    error={errors.size || touched.size}
                  />
                  <Spacer size={18} />
                  <Input
                    id="bio"
                    as="textarea"
                    label="Track Bio"
                    placeholder="Enter a short bio about the track"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bio}
                    error={errors.bio || touched.bio}
                  />

                  <Spacer size={18} />
                  <Input
                    id="url"
                    label="Video"
                    placeholder="Video URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.url}
                    error={errors.url && touched.url && errors.url}
                  />

                  <Spacer size={18} />
                  <Label>Social</Label>
                  <RowSection leftText="Twitter">
                    <Input
                      id="twitter"
                      placeholder="Twitter Handle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.twitter}
                      error={errors.twitter || touched.twitter}
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="Facebook">
                    <Input
                      id="facebook"
                      placeholder="Facebook Link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.facebook}
                      error={errors.facebook || touched.facebook}
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="Instagram">
                    <Input
                      id="instagram"
                      placeholder="Instagram Handle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.instagram}
                      error={errors.instagram || touched.instagram}
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="YouTube">
                    <Input
                      id="youtube"
                      placeholder="YouTube link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.youtube}
                      error={
                        errors.youtube && touched.youtube && errors.youtube
                      }
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="TikTok">
                    <Input
                      id="tiktok"
                      placeholder="TikTok Handle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tiktok}
                      error={errors.tiktok && touched.tiktok && errors.tiktok}
                    />
                  </RowSection>
                  <Spacer size={18} />

                  <Label>Promoter Details</Label>
                  <Input
                    id="promoter_name"
                    name="promoter_name"
                    placeholder="Promoter Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promoter_name}
                    error={errors.promoter_name}
                  />
                  <Spacer size={10} />
                  <Input
                    id="promoter_email"
                    name="promoter_email"
                    placeholder="promoter@email.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promoter_email}
                    error={errors.promoter_email}
                  />
                  <Spacer size={10} />
                  <Input
                    id="promoter_phone"
                    name="promoter_phone"
                    placeholder="Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promoter_phone}
                    error={errors.promoter_phone}
                  />
                </div>
              </Content>

              <Content active={active === 2}>
                <SectionTitle>General Info</SectionTitle>
                <InfosRepeater
                  onChange={({ target, order }) => {
                    const newInfos = values.infos.map((info) =>
                      info.order === order
                        ? {
                            ...info,
                            [target.name]: target.value,
                          }
                        : info
                    );
                    const newInfosWithOrder = values.infos_with_order.map(
                      (info) =>
                        info.order === order
                          ? {
                              ...info,
                              [target.name]: target.value,
                            }
                          : info
                    );
                    handleChange({
                      target: {
                        name: 'infos',
                        value: newInfos,
                      },
                    });
                    handleChange({
                      target: {
                        name: 'infos_with_order',
                        value: newInfosWithOrder,
                      },
                    });
                  }}
                  onChangeInfo={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  infos={values.infos}
                  props={props}
                  handleSort={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                />
                <Spacer size={18} />
              </Content>

              <Content active={active === 3}>
                <div style={{ width: '60%', marginLeft: 10 }}>
                  <FaqList />

                  <Spacer size={18} />
                </div>
              </Content>
            </TabContent>
          </TrackBody>
        </form>
      )}
    </Formik>
  );
};

export default compose(CreateTrack, UpdateTrack)(TrackEdit);
