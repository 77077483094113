import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useGetEventCSVReportOptions } from 'components/Participants/gql/useGetEventCSVReportOptions';
import { useGetLapSponsorshipReport } from 'components/Participants/gql/useGetLapSponsorshipReport.mutation';
import { useGetSpectatorTicketsReport } from 'components/Participants/gql/useGetSpectatorTicketsReport.mutation';
import { PrintModal } from 'components/Participants/Modals/PrintModal';
import { logDevError } from 'shared/alerts';
import { formatPhoneNumber } from 'shared/formatters';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 860px) {
    margin: 0;
    border: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;

    @media (min-width: 860px) {
      text-align: center;
      font-size: 12px;
      color: #3c4144;
      text-align: center;
      margin-top: 10px;
    }
  }

  svg {
    fill: #fff;
    margin-right: 10px;

    @media (min-width: 860px) {
      fill: #fa4616;
      margin: 0;
    }
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async (
  { event },
  ticketReport,
  setShouldDisplayPrintModal
) => {
  try {
    const response = await ticketReport(event.id);
    if (!response || response.errors) {
      toast.error('Ticket Holder List can not be retrieved');
      return;
    }

    if (response.data.getSpectatorTicketsReport) {
      const {
        getSpectatorTicketsReport: { tickets, event, total_tickets },
      } = response.data;

      let _title = '',
        filename = '';
      if (event.isMultiDay) {
        _title =
          moment(event.start_date, 'MM-DD-YYYY').format('MMM DD - ') +
          moment(event.end_date, 'MM-DD-YYYY').format('DD - YYYY');
      } else {
        _title = moment(event.date, 'MM-DD-YYYY').format('dddd MMM DD - YYYY');
      }
      filename =
        'Ticket Holders List - ' +
        _title +
        ' - ' +
        event.track.name +
        ' - ' +
        event.name;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const csv = `Ticket Holders List
      Date, ${_title} 
      Event Name, ${event.name} 
      Track Name, ${event.track.name} 
      Ticket Total(#), ${total_tickets}
      Exported:, ${dateTimeStamp} \n,,,,,,
    Purchaser's Name, Purchaser's Mobile Number, Purchaser's Email Address, Purchaser's Zip Code, Ticket, Ticket Number, Ticket Holder's Name, Ticket Price
    ${Object.values(tickets)
      .map(
        (value) =>
          `${
            value.user.formatted_name.replace(',', '') ?? ''
          },"${formatPhoneNumber(value.user.cellphone ?? '')}","${
            value.user.email ?? ''
          }","${value.user.zipcode ?? ''}","${value.ticket.name ?? ''}","${
            value.barcode ?? ''
          }","${value.holder ?? ''}","${value.price ?? ''}"`
      )
      .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
      setShouldDisplayPrintModal(false);
    } else {
      toast.error('Ticket Holder List can not be retrieved');
    }
  } catch (e) {}
};

const exportLapReportCsv = async (
  lapReport,
  id,
  setShouldDisplayPrintModal
) => {
  try {
    const response = await lapReport(id);
    if (!response || response.errors) {
      toast.error('Lap sponsorship report can not be retrieved');
      return;
    }

    if (response.data.getLapSponsorshipReport) {
      const {
        getLapSponsorshipReport: {
          name,
          event,
          payouts,
          data,
          laps_sold,
          num_laps,
        },
      } = response.data;

      let _title = '',
        filename = '';
      if (event.isMultiDay) {
        _title =
          moment(event.start_date, 'MM-DD-YYYY').format('MMM DD - ') +
          moment(event.end_date, 'MM-DD-YYYY').format('DD - YYYY');
      } else {
        _title = moment(event.start_date, 'MM-DD-YYYY').format(
          'dddd MMM DD - YYYY'
        );
      }
      filename =
        'Lap Sponsorship Report - ' +
        _title +
        ' - ' +
        event.track.name +
        ' - ' +
        event.name;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const csv = `Lap Sponsorship Report
      Date, ${_title} 
      Lap Sponsorship Name, ${name}
      # of Laps, ${num_laps}
      # of Laps Sold, ${laps_sold} 
      Track Name, ${event.track.name}
      ${Object.values(payouts)
        .map((payout) => `${payout.title} payout, ${payout.amount}   `)
        .join('\n')}
      Exported:, ${dateTimeStamp} \n,,,,,,
     Lap, Sponsored By, Lap Price, Purchaser's Name
    ${Object.values(data)
      .map(
        (value) =>
          `${value.name ?? ''},${value.lap_text ?? ''},${value.price ?? ''},${
            value.purchaser.replace(',', '') ?? ''
          }`
      )
      .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
      setShouldDisplayPrintModal(false);
    } else {
      toast.error('Lap sponsorship report can not be retrieved');
    }
  } catch (e) {}
};

const Export = ({ icon, ...props }) => {
  const ticketReport = useGetSpectatorTicketsReport();
  const lapReport = useGetLapSponsorshipReport();
  const [shouldDisplayPrintModal, setShouldDisplayPrintModal] = useState(false);

  const { data } = useGetEventCSVReportOptions();
  if (!data || !data.getEventCSVReportOptions) return null;
  const eventCsvReportOptions = data.getEventCSVReportOptions;

  const handleClick = (printOptionData) => {
    if (printOptionData) {
      if (printOptionData.action === 'getLapSponsorshipReport') {
        try {
          exportLapReportCsv(
            lapReport,
            printOptionData.id,
            setShouldDisplayPrintModal
          );
        } catch (error) {
          logDevError(error);
          toast.error('Error exporting CSV');
        }
      } else {
        exportCsv(props, ticketReport, setShouldDisplayPrintModal);
      }
    } else {
      exportCsv(props, ticketReport, setShouldDisplayPrintModal);
    }
  };

  return (
    <div>
      <Container
        onClick={() => {
          if (eventCsvReportOptions) {
            if (eventCsvReportOptions.length > 1) {
              setShouldDisplayPrintModal(true);
            } else {
              exportCsv(props, ticketReport, setShouldDisplayPrintModal);
            }
          }
        }}
      >
        <IconWrapper>
          {icon}
          <span>Export List</span>
        </IconWrapper>
      </Container>
      <PrintModal
        title="Export CSV list Options"
        description="Please select the CSV List you would like to export"
        printReportOptions={eventCsvReportOptions}
        isVisible={!!shouldDisplayPrintModal}
        handleClick={handleClick}
        setIsVisible={setShouldDisplayPrintModal}
      />
    </div>
  );
};

export default Export;
