import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetPendingUser($id: Int!) {
    getPendingUser(id: $id) {
      id
      user_id
      last_name
      middle_name
      first_name
      suffix
      cellphone
      created
      email
      birthday
      address_1
      address_2
      city
      state
      zipcode
      selfie_image
      temp_image_one
      temp_image_two
      status
    }
  }
`;

export default graphql(query, {
  options: ({ currentUser, match }) => {
    return {
      variables: {
        id: currentUser ? currentUser : match ? Number(match.params.id) : null,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
});
