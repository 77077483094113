import React, { useEffect } from 'react';
import Loading from 'components/Loading';
import { useGetSeriesInfo } from '../gql/getSeriesInfo';
import { InfoItem } from './InfoItem';

export const Infos = ({
  trackSeries,
  values,
  handleChange,
  handleBlur,
  trackAndSeries,
  setTrackAndSeries,
}) => {
  const { data, loading } = useGetSeriesInfo(
    trackSeries.infos.length === 0 ? trackSeries.id : 0
  );

  useEffect(() => {
    if (data && data.getSeriesDetail) {
      // Add the infos to the track and series
      const newTracksAndSeries = trackAndSeries.map((item) => {
        if (item.id === trackSeries.id) {
          return {
            ...item,
            infos: data.getSeriesDetail.infos.map((info) => ({
              ...info,
              type: 'series',
            })),
          };
        }

        return item;
      });

      setTrackAndSeries(newTracksAndSeries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return loading ? (
    <Loading />
  ) : (
    trackSeries.infos.map((info) => {
      return (
        <InfoItem
          values={values}
          info={info}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      );
    })
  );
};
