import React from 'react';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import Images from 'images';

const LogoTicketHoss = styled.img`
  width: 230px;
  height: 120px;
  margin: 0 auto 30px auto;

  @media (max-width: 768px) {
    width: 90%;
    height: 120px;
  }
`;

const SliderContainer = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  width: 450px;
  border-radius: 5px;
  margin: 0px auto 0 auto;
  display: flex;
  flex-direction: column;
  padding: 35px;
  box-sizing: border-box;
  justifycontent: 'center';
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const PromoterContainer = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  overflow: hidden;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  width: calc(100% - 20px);

  @media (min-width: 768px) {
    max-width: 450px;
    width: 100%;
  }
`;

const PromoterDefault = () => {
  const isTickethoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;
  return (
    <PromoterContainer>
      <SliderContainer>
        <Text
          style={{
            fontSize: '1.6em',
            fontWeight: 700,
            color: 'white',
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          Welcome To {isTickethoss ? 'Ticket Hoss!' : 'Pit Pay!'}
        </Text>
        <Spacer size={40} />
        <LogoTicketHoss
          src={
            isTickethoss ? Images.ticketHossLogo : Images.logoHorizontalWhite
          }
        />
        <Spacer size={30} />

        <Text
          style={{
            fontSize: '1.4em',
            fontWeight: 600,
            color: '#fa4616',
            lineHeight: 1.4,
            textAlign: 'center',
          }}
        >
          We Have Everything We Need for Now!
        </Text>
        <Spacer size={21} />

        <Text
          style={{
            fontSize: '1.4em',
            fontWeight: 500,
            color: 'white',
            margin: '0 10px',
            lineHeight: 1.4,
            textAlign: 'center',
          }}
        >
          The {isTickethoss ? 'Ticket Hoss' : 'Pit Pay'} team will now finish
          getting your dashboard set up.
        </Text>
        <Spacer size={21} />
        <Text
          style={{
            fontSize: '1.4em',
            fontWeight: 500,
            color: 'white',
            margin: '0 5px',
            textAlign: 'center',
          }}
        >
          Check back Soon!
        </Text>
      </SliderContainer>
    </PromoterContainer>
  );
};

export default PromoterDefault;
