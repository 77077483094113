import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-accounts';

export default graphql(
  gql`
    mutation DeleteAccount($id: Int!) {
      deleteAccount(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteAccount: async (id) => {
        return mutator(() =>
          mutate({
            variables: { id },
            update: (proxy, { data: { deleteAccount } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              if (!deleteAccount) {
                return;
              }

              const data = proxy.readQuery({
                query,
                variables: { input: { ...search } },
              });

              proxy.writeQuery({
                query,
                variables: { input: { ...search } },
                data: {
                  ...data,
                  getAccounts: {
                    ...data.getAccounts,
                    count: data.getAccounts.count - 1,
                    results: data.getAccounts.results.filter(
                      (account) => account.id !== id
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
