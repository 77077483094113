import React, { useState } from 'react';
import { Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import EmulateUser from 'pages/app/Users/gql/mutations/emulate-user';
import { formatPhoneNumber } from 'shared/formatters';
import storage from 'shared/storage';
import DeleteMember from '../AddMember/gql/DeleteMember';

const Text = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  display: block;
`;

const Column = styled.div`
  width: 25%;
  display: flex;
  padding-right: 5px;
`;

const successMessage = () => toast.success('Staff deleted successfully');
const errorMessage = () => toast.error('Error deleting Staff');

const TeamMembers = compose(DeleteMember)(
  ({
    team,
    account_id,
    deleteMember,
    members,
    setFieldValue,
    history,
    emulateUser,
  }) => {
    const { formatted_name, cellphone, email, id } = team;

    const theme = useTheme();
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
      <>
        <Spacer size={10} />
        <Row style={{ margin: 0 }}>
          <Column>
            <Text>{formatted_name}</Text>
          </Column>
          <Column>
            <Text>{formatPhoneNumber(cellphone)}</Text>
          </Column>
          <Column>
            <Text>{email}</Text>
          </Column>
          <Row>
            <div style={{ marginLeft: '20px', width: '10%' }}>
              <Icon
                icon="edit"
                size={18}
                color={theme.colors.primary}
                style={{ margin: '0 10px' }}
                onClick={async () => {
                  history.push(`/admin/users/editUser/${id}`);
                }}
              />
            </div>
            <div style={{ marginLeft: '30px', width: '10%' }}>
              <Icon
                icon="trash"
                size={18}
                color={theme.colors.primary}
                style={{ margin: '0 10px' }}
                onClick={async () => {
                  setShowConfirmation(true);
                }}
              />
            </div>
            <div style={{ marginLeft: '30px', width: '10%' }}>
              <Icon
                icon="user-1"
                size={18}
                color={theme.colors.primary}
                onClick={async () => {
                  if (
                    window.confirm(
                      'Are you sure you want to log in as this user?'
                    )
                  ) {
                    const response = await emulateUser(id);

                    if (!response || response.errors) {
                      toast.error('Something went wrong with the emulation.');
                    } else {
                      storage.set('user', response.data.emulateUser);
                      window.location.reload();
                    }
                  }
                }}
                padding="0"
              />
            </div>
          </Row>
        </Row>
        <Modal
          isVisible={showConfirmation}
          hideModal={() => setShowConfirmation(false)}
          title={'Are you sure you want to remove this member?'}
          maxWidth={600}
        >
          <Spacer size={20} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              width: '50%',
              margin: 'auto',
            }}
          >
            <Button type="button" onClick={() => setShowConfirmation(false)}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={async () => {
                setShowConfirmation(false);

                const response = await deleteMember({
                  user_id: id,
                  account_id: parseInt(account_id),
                });

                if (!response || response.errors) {
                  errorMessage();
                } else {
                  const newMembers = members.filter(
                    (member) => member.id !== team.id
                  );
                  setFieldValue('members', newMembers);
                  successMessage();
                }
              }}
            >
              Okay
            </Button>
          </div>
          <Spacer size={20} />
        </Modal>
      </>
    );
  }
);

export default compose(EmulateUser)(TeamMembers);
