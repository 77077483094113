import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { usePersistedQuery } from 'hooks/usePersistedQuery';

export const GET_EVENT_REPORT = gql`
  query GetEventPrintReportOptions($event_id: Int!) {
    getEventPrintReportOptions(event_id: $event_id) {
      id
      name
      action
    }
  }
`;

export function useGetEventReportOptions() {
  const { id: eventId } = useParams();

  const result = usePersistedQuery(
    GET_EVENT_REPORT,
    `event_report_download-${eventId}`,
    {
      variables: {
        event_id: Number(eventId),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result };
}
