import React from 'react';
import styled from 'styled-components';
import { useGetUpcomingEvent } from 'components/UpcomingEvents/useGetUpcomingEvent';
import { WeatherWidget, Radar } from 'components/Weather';

const Wrapper = styled.div`
  @media (min-width: 864px) {
    display: flex;
    align-items: flex-start;
  }
`;

export const WeatherPage = () => {
  const { data } = useGetUpcomingEvent();

  const events = data?.getUpcomingEventsV3;
  const me = data?.me;

  if (!me) return null;

  function getLatOrLng(which) {
    const lat =
      events && events.length > 0
        ? events[0].track[which]
          ? events[0].track[which]
          : null
        : me.track
        ? me.track[which]
        : null;

    return lat;
  }

  return (
    <Wrapper>
      <WeatherWidget Width="377px" showHourly />
      {events?.length || me.track ? (
        <Radar
          radarwidth={769}
          Width={'800px'}
          lat={getLatOrLng('lat')}
          lng={getLatOrLng('lng')}
        />
      ) : null}
    </Wrapper>
  );
};
