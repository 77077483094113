import { withApollo } from '@apollo/react-hoc';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Icon from 'components/Icon';
import { useMe } from 'hooks/useMe';
import Images from 'images';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import storage from 'shared/storage';
import GetStripeLogin from '../AdminLayout/gql/get-login';
import { TicketHossLogo } from '../AdminLayout/StyledAdminLayout';
import SubMenu from './component/SubMenu';
import {
  GlobalStyle,
  BackgroundContainer,
  NavMenu,
  LogoWrapper,
  Logo,
  NavItem,
  TopBar,
  NavMenuBackground,
  Wrapper,
  NavItemExternal,
  AccountsWrapper,
  NavItemButton,
} from './StyledAdminMobileLayout';

const AdminLayout = ({
  children,
  getStripeLogin,
  backgroundColor,
  history,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { data: user } = useMe();

  const { dispatch } = useContext(AppContext);
  const [account, setAccount] = useState();
  const [capabilities, setCapabilities] = useState();

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const findCapabilities = (accountId) => {
    // eslint-disable-next-line no-unused-expressions
    user?.me?.capabilities?.forEach((capablity) => {
      if (capablity.account_id === accountId) {
        setCapabilities(capablity);
        dispatch({
          type: 'SET_CAPABILITIIES',
          capabilities: capablity,
        });
      }
    });
  };

  const setSelectedAccount = (value) => {
    setAccount(value);
    setAccountId(value.value);
    setAccountName(value.label);
    setAccountScanners(value.janam_scanners);
    findCapabilities(value.value);
  };

  const setAccountId = (accountId) =>
    dispatch({
      type: 'SET_ACCOUNT_ID',
      accountId: accountId,
    });

  const setAccountName = (accountName) =>
    dispatch({
      type: 'SET_ACCOUNT_NAME',
      accountId: accountName,
    });

  const setAccountScanners = (janamScanners) =>
    dispatch({
      type: 'SET_ACCOUNT_SCANNERS',
      janamScanners: janamScanners,
    });

  useEffect(() => {
    if (user && user.me) {
      //save the me value on the context
      dispatch({
        type: 'SET_ME',
        user: user?.me,
      });

      if (user.me?.role !== 'admin') {
        if (Array.isArray(user?.me?.accounts)) {
          if (user.me.accounts && user.me.accounts.length > 0) {
            const selectedAccount = storage.get('selected_account');

            if (selectedAccount) {
              setSelectedAccount(selectedAccount);
            } else {
              setAccount({
                value: user?.me?.accounts[0].id,
                label: user?.me?.accounts[0].business_dba,
              });
              setAccountId(user?.me?.accounts[0].id);
              setAccountName(user?.me?.accounts[0].business_dba);
              setAccountScanners(user?.me?.accounts[0].janam_scanners);
              findCapabilities(user?.me?.accounts[0].id);
            }
          } else {
            setShowPermissionModal(true);
          }
        }
        if (Array.isArray(user?.me?.promoters)) {
          dispatch({
            type: 'SET_PROMOTERS',
            promoters: user?.me?.promoters,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let menu = [];

  if (user?.me?.role === 'track') {
    menu = [
      {
        id: 1,
        link: '/admin-track/home',
        icon: 'home',
        name: 'Home',
        shown: true,
      },
      {
        id: 2,
        link: '/admin-track/events',
        icon: 'flag',
        name: 'Events',
        shown: capabilities?.events ?? false,
      },
      {
        id: 3,
        link: '/admin-track/weather',
        icon: 'bolder-sun',
        name: 'Weather',
        shown: true,
      },
      {
        id: 4,
        link: '/admin-track/guest-lists',
        icon: 'home',
        name: 'Guest List',
        shown: capabilities?.guests ?? false,
      },
      {
        id: 5,
        link: '/admin-track/notifications',
        icon: 'notifications',
        name: 'Message Ctr',
        shown: capabilities?.messages ?? false,
      },
      {
        id: 6,
        link: '/admin-track/promos',
        icon: 'percent',
        name: 'Promo Codes',
        shown: capabilities?.promos ?? false,
      },
      {
        id: 7,
        link: '/admin-track/credits',
        icon: 'credit-icon',
        name: 'Credits',
        shown: capabilities?.credits ?? false,
        submenu: [
          {
            id: 701,
            title: 'Open Credits',
            link: '/admin-track/openCredits',
          },
          {
            id: 702,
            title: 'Redeemed Credits',
            link: '/admin-track/redeemedCredits',
          },
          {
            id: 703,
            title: 'Reports',
            link: '/admin-track/financials',
          },
        ],
      },
      {
        id: 8,
        link: '/admin-track/transactions',
        name: 'Transactions',
        icon: 'invoice-1',
        shown: capabilities?.transactions ?? false,
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 9,
            link: '/admin-track/registrations',
            name: 'Registrations',
            icon: 'google-forms',
            shown: capabilities?.registrations ?? false,
          }
        : { id: 9 },
      {
        id: 14,
        link: '/admin-track/annualPurchases',
        icon: 'flag',
        name:
          'tickethoss' !== process.env.REACT_APP_PLATFORM
            ? 'Memberships'
            : 'Season Tickets',
        shown: capabilities?.members ?? true,
        submenu: [
          {
            title: 'Purchases',
            link: '/admin-track/annualPurchases',
          },
          {
            title: 'Members',
            link: '/admin-track/annualMembers',
          },
          {
            title: 'Annuals',
            link: '/admin-track/annualUsers',
          },
        ],
      },
      {
        id: 10,
        link: '/admin-track/users',
        icon: 'users',
        name: 'Customers',
        shown: capabilities?.customers ?? false,
      },
      {
        id: 11,
        link: '/admin-track/employees',
        icon: 'users',
        name: 'Staff',
        shown: capabilities?.staff ?? false,
      },
      {
        id: 12,
        link: null,
        icon: 'help',
        name: 'Help',
        shown: true,
        external:
          process.env.REACT_APP_PLATFORM === 'tickethoss'
            ? 'https://promoters.tickethoss.com/'
            : 'https://promoters.pitpay.com/',
      },

      {
        id: 13,
        link: '/admin-track/contact',
        icon: 'phone',
        name: 'Contact',
        shown: true,
      },
    ];
  } else if (user?.me?.role === 'admin') {
    menu = [
      {
        id: 1,
        link: '/admin/tracks',
        icon: 'flag',
        name: 'Tracks',
        shown: true,
      },
      {
        id: 2,
        link: '/admin/series',
        icon: 'series',
        name: 'Series',
        shown: true,
      },
      {
        id: 3,
        link: '/admin/events',
        icon: 'flag',
        name: 'Events',
        shown: true,
      },
      {
        id: 17,
        link: '/admin/annualTickets',
        icon: 'flag',
        name: 'Annual Tickets',
        shown: true,
        submenu: [
          {
            title: 'Tickets',
            link: '/admin/annualTickets',
          },
          {
            title: 'Categories',
            link: '/admin/annualcategories',
          },
          {
            title: 'Purchases',
            link: '/admin/annualPurchases',
          },
          {
            title: 'Members',
            link: '/admin/annualMembersAccount',
          },
          {
            title: 'Annuals',
            link: '/admin/annualsAccount',
          },
        ],
      },
      {
        id: 4,
        link: '/admin/accounts',
        icon: 'flag',
        name: 'Accounts',
        shown: true,
      },
      {
        id: 5,
        link: '/admin/users',
        icon: 'users',
        name: 'Users',
        shown: true,
      },
      {
        id: 6,
        link: '/admin/reports',
        icon: 'reports',
        name: 'Reports',
        shown: true,
      },
      {
        id: 7,
        link: '/admin/videos',
        icon: 'videos',
        name: 'Videos',
        shown: true,
      },
      {
        id: 8,
        link: '/admin/promos',
        icon: 'percent',
        name: 'Promos',
        shown: true,
      },
      {
        id: 9,
        link: '/admin/notifications',
        icon: 'notifications',
        name: 'Message Ctr',
        shown: true,
      },
      {
        id: 10,
        link: '/admin/track-types',
        icon: 'flag',
        name: 'Track Types',
        shown: true,
      },
      {
        id: 11,
        link: '/admin/series-types',
        icon: 'series',
        name: 'Series Types',
        shown: true,
      },
      {
        id: 12,
        link: '/admin/credits',
        icon: 'credit-icon',
        name: 'Credits',
        shown: true,
        submenu: [
          {
            id: 1201,
            title: 'Open Credits',
            link: '/admin/openCredits',
          },
          {
            id: 1202,
            title: 'Redeemed Credits',
            link: '/admin/redeemedCredits',
          },
          {
            id: 1203,
            title: 'Reports',
            link: '/admin/financials',
          },
        ],
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 13,
            link: '/admin/waivers',
            icon: 'document',
            name: 'Waivers',
            shown: true,
          }
        : { id: 13 },
      {
        id: 14,
        link: '/admin/transactions',
        icon: 'invoice-1',
        name: 'Transactions',
        shown: true,
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 15,
            link: '/admin/registrations',
            icon: 'google-forms',
            name: 'Registrations',
            shown: true,
          }
        : { id: 15 },
      {
        id: 16,
        link: '/admin/infos',
        icon: 'invoice-1',
        name: 'Infos',
        shown: true,
      },
      {
        id: 17,
        link: '/admin/categories',
        icon: 'invoice-1',
        name: 'Categories',
        shown: true,
      },
    ];
  } else {
    menu = [
      {
        id: 1,
        link: '/admin-employee/home',
        icon: 'home',
        name: 'Home',
        shown: true,
      },
      {
        id: 2,
        link: '/admin-employee/events',
        icon: 'flag',
        name: 'Events',
        shown: capabilities?.events ?? false,
      },
      {
        id: 3,
        link: '/admin-employee/weather',
        icon: 'bolder-sun',
        name: 'Weather',
        shown: true,
      },
      {
        id: 4,
        link: '/admin-employee/guest-lists',
        icon: 'home',
        name: 'Guest List',
        shown: capabilities?.guests ?? false,
      },
      {
        id: 5,
        link: '/admin-employee/notifications',
        icon: 'notifications',
        name: 'Message Ctr',
        shown: capabilities?.messages ?? false,
      },
      {
        id: 6,
        link: '/admin-employee/promos',
        icon: 'percent',
        name: 'Promo Codes',
        shown: capabilities?.promos ?? false,
      },
      {
        id: 7,
        link: '/admin-employee/credits',
        icon: 'credit-icon',
        name: 'Credits',
        shown: capabilities?.credits ?? false,
        submenu: [
          {
            id: 701,
            title: 'Open Credits',
            link: '/admin-employee/openCredits',
          },
          {
            id: 702,
            title: 'Redeemed Credits',
            link: '/admin-employee/redeemedCredits',
          },
          {
            id: 702,
            title: 'Reports',
            link: '/admin-employee/financials',
          },
        ],
      },
      {
        id: 8,
        link: '/admin-employee/transactions',
        icon: 'invoice-1',
        name: 'Transactions',
        shown: capabilities?.transactions ?? false,
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 9,
            link: '/admin-employee/registrations',
            icon: 'google-forms',
            name: 'Registrations',
            shown: capabilities?.registrations ?? false,
          }
        : { id: 9 },
      {
        id: 14,
        link: '/admin-employee/annualPurchases',
        icon: 'flag',
        name:
          'tickethoss' !== process.env.REACT_APP_PLATFORM
            ? 'Memberships'
            : 'Season Tickets',
        shown: capabilities?.members ?? true,
        submenu: [
          {
            title: 'Purchases',
            link: '/admin-employee/annualPurchases',
          },
          {
            title: 'Members',
            link: '/admin-employee/annualMembers',
          },
          {
            title: 'Annuals',
            link: '/admin-employee/annualUsers',
          },
        ],
      },
      {
        id: 10,
        link: '/admin-employee/users',
        icon: 'users',
        name: 'Customers',
        shown: capabilities?.customers ?? false,
      },
      {
        id: 11,
        link: '/admin-employee/employees',
        icon: 'users',
        name: 'Staff',
        shown: capabilities?.staff ?? false,
      },
      {
        id: 12,
        link: null,
        icon: 'help',
        name: 'Help',
        shown: true,
        external:
          process.env.REACT_APP === 'tickethoss'
            ? 'https://promoters.tickethoss.com/'
            : 'https://promoters.pitpay.com/',
      },
      {
        id: 13,
        link: '/admin-employee/contact',
        icon: 'phone',
        name: 'Contact',
        shown: true,
      },
    ];
  }

  const logout = () => {
    storage.clearUser();
    storage.clear();
    rest.client.clearStore();
    history.push('/');
  };

  return !user ? null : (
    <Fragment>
      <BackgroundContainer isVisible={isVisible}>
        <NavMenuBackground isVisible={isVisible}>
          <NavMenu isVisible={isVisible}>
            <Wrapper>
              <NavItem
                exact
                onClick={() => setIsVisible(!isVisible)}
                to={
                  'admin' === user?.me?.role
                    ? '/admin/settings'
                    : 'track' === user?.me?.role
                    ? '/admin-track/settings/0'
                    : '/admin-employee/settings/0'
                }
                key="001"
              >
                <div style={{ marginRight: 15, width: 20 }}>
                  <Icon icon="user" size={24} color="white" />
                </div>{' '}
                <p style={{ textTransform: 'capitalize' }}>
                  {user?.me?.name.toLowerCase()}
                </p>
              </NavItem>
              {user?.me?.accounts?.length > 1 && user?.me?.role !== 'admin' && (
                <AccountsWrapper>
                  <AutoSuggest
                    name="account_id"
                    placeholder="Select Account"
                    value={account}
                    onChange={(value) => {
                      setSelectedAccount(value);

                      setIsVisible(!isVisible);

                      // Store the selected account in local storage
                      storage.set('selected_account', value);
                    }}
                    closeMenuOnSelect
                    options={user?.me?.accounts?.map((item) => ({
                      value: item.id,
                      label: item.business_dba,
                      janam_scanners: item.janam_scanners,
                    }))}
                    customStyles={{
                      control: (provided) => ({
                        ...provided,
                        height: 49,
                        width: 200,
                      }),
                    }}
                  />
                </AccountsWrapper>
              )}

              {menu.map((item) =>
                !item.external ? (
                  item.shown === true ? (
                    item.submenu ? (
                      <SubMenu
                        item={item}
                        hideMenu={() => setIsVisible(!isVisible)}
                        key={item.id}
                      />
                    ) : (
                      <NavItem
                        exact
                        to={item.link}
                        onClick={() => setIsVisible(!isVisible)}
                        key={item.id}
                      >
                        <div style={{ marginRight: 15, width: 20 }}>
                          <Icon icon={item.icon} size={20} color="white" />
                        </div>{' '}
                        {item.name}
                      </NavItem>
                    )
                  ) : item.shown === false ? (
                    <NavItemButton
                      exact
                      onClick={() => {
                        setIsVisible(!isVisible);
                        setShowPermissionModal(true);
                      }}
                      key={item.id}
                    >
                      <div style={{ marginRight: 15, width: 20 }}>
                        <Icon icon={item.icon} size={20} color="white" />
                      </div>{' '}
                      {item.name}
                    </NavItemButton>
                  ) : null
                ) : (
                  <NavItemExternal
                    target="_blank"
                    href={item.external}
                    key={item.key}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon={item.icon} size={20} color="white" />
                    </div>{' '}
                    {item.name}
                  </NavItemExternal>
                )
              )}
              {user?.me?.role !== 'admin' ? (
                capabilities?.stripe ? (
                  <NavItemExternal
                    style={{ fontWeight: 600 }}
                    onClick={async () => {
                      const result = await getStripeLogin();
                      if (result && result.data && result.data.getStripeLogin) {
                        window.location.assign(result.data.getStripeLogin);
                      } else {
                        history.push({
                          pathname: '/stripe',
                          search: '',
                          state: {
                            email: storage.get('user').email,
                            id: storage.get('user').id,
                          },
                        });
                      }
                    }}
                    target="_blank"
                    key={9}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon="dollar" size={20} color="white" />
                    </div>{' '}
                    Stripe
                  </NavItemExternal>
                ) : (
                  <NavItemButton
                    exact
                    onClick={() => {
                      setIsVisible(!isVisible);
                      setShowPermissionModal(true);
                    }}
                    key={9}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon="dollar" size={20} color="white" />
                    </div>{' '}
                    Stripe
                  </NavItemButton>
                )
              ) : null}

              <NavItem
                exact
                to="/"
                onClick={logout}
                style={{ marginBottom: 150 }}
              >
                <div style={{ marginRight: 15, width: 20 }}>
                  <Icon icon="user-1" size={20} color="white" />
                </div>{' '}
                Logout
              </NavItem>
            </Wrapper>
          </NavMenu>
        </NavMenuBackground>

        <TopBar>
          <div
            style={{
              width: 20,
              height: 20,
              position: 'absolute',
              margin: 'auto',
              top: 0,
              left: 20,
              bottom: 0,
              cursor: 'pointer',
            }}
            onClick={() => setIsVisible(!isVisible)}
            onKeyUp={() => setIsVisible(!isVisible)}
            role="button"
            tabIndex={0}
          >
            <Icon icon="menu" size={20} color="white" />
          </div>{' '}
          {'pitpay' === process.env.REACT_APP_PLATFORM ? (
            <LogoWrapper>
              <Logo src={Images.logoHorizontalWhite} />
            </LogoWrapper>
          ) : 'tickethoss' === process.env.REACT_APP_PLATFORM ? (
            <LogoWrapper>
              <TicketHossLogo
                src={Images.logoTickethossHorizontal}
                style={{ width: 100 }}
              />
            </LogoWrapper>
          ) : (
            <LogoWrapper>
              <Logo src={Images.logoKart} />
            </LogoWrapper>
          )}
        </TopBar>

        <div
          style={{
            width: '100%',
          }}
        >
          {children}
          <PermissionModal
            isVisible={showPermissionModal}
            hideModal={() => setShowPermissionModal(false)}
            link={
              'track' === user?.me?.role
                ? '/admin-track/settings/1'
                : '/admin-employee/settings/1'
            }
            history={history}
          />
        </div>
      </BackgroundContainer>
      <GlobalStyle backgroundColor={backgroundColor} />
    </Fragment>
  );
};

export default compose(withRouter, withApollo, GetStripeLogin)(AdminLayout);
