/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Checkbox } from 'components/Form/Checkbox';
import Icon from 'components/Icon';
import { CheckInModal } from 'components/Modal';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import useNewTicket from 'hooks/useNewTicket';
import useTheme from 'hooks/useTheme';
import { useCheckInUser, useUncheckInUser } from '../gql';
import { PassesModal } from '../Modals';
import {
  ButtonAction,
  ParticipantName,
  ParticipantList,
  ParticipantRow,
} from './style';

export const Tickets = (props) => {
  const {
    match,
    location,
    search,
    date,
    subscribeToMore,
    tickets,
    event,
    totalCount,
  } = props;
  const [isChecked, setIsChecked] = useState([]);
  const [showPasses, setShowPasses] = useState(false);

  const [ticket, setTicket] = useState(null);
  const theme = useTheme();
  const { id: eventId } = useParams();

  useNewTicket({ subscribeToMore }, eventId, search, date);

  const checkInUser = useCheckInUser();
  const uncheckInUser = useUncheckInUser();
  const [storedCheckins, setStoredCheckins] = useState([]);
  const [storedUnChecks, setStoredUnChecks] = useState([]);
  const [purchaseItem, setPurchaseItem] = useState(false);
  const [unCheck, setUncheck] = useState(false);

  const [shouldDisplayCheckInModal, setShouldDisplayCheckInModal] =
    useState(false);

  async function selectOne() {
    // check if it's already selected
    if (unCheck) {
      setStoredUnChecks((prev) => [...new Set(prev.concat(purchaseItem.id))]);
    } else {
      setStoredCheckins((prev) => [...new Set(prev.concat(purchaseItem.id))]);
    }

    return unCheck
      ? await uncheckInUser([purchaseItem.id])
      : await checkInUser([purchaseItem.id]);
  }

  if (!tickets) return false;

  return (
    <>
      <div style={{}}>
        (
        <ParticipantList>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text type="heading" inlineStyle={{ padding: '0 10px' }}>
              Tickets ({totalCount})
            </Text>
            <ButtonAction
              style={{
                cursor: 'pointer',
                justifyContent: 'flex-end',
              }}
              onClick={() => {
                setShowPasses(true);
              }}
            >
              <Text
                inlineStyle={{
                  lineHeight: '19px',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {process.env.REACT_APP_PLATFORM !== 'tickethoss'
                  ? 'Passes on Sale'
                  : 'Tickets on Sale'}
              </Text>
              <div style={{ transform: 'rotate(-90deg)' }}>
                <Icon icon="chevron" color={theme.colors.primary} size={22} />
              </div>
            </ButtonAction>
          </div>
          <Spacer size={10} />

          {tickets.length > 0 &&
            tickets.map((person, index) => {
              const { id, is_checked, user } = person;
              return (
                <ParticipantRow key={id}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      inversed
                      name="checkbox1"
                      checked={is_checked}
                      onChange={() => {
                        setPurchaseItem(person);
                        setUncheck(person.is_checked);
                        setShouldDisplayCheckInModal(true);
                      }}
                      size={[36, 20, 3]}
                    />
                    <Link
                      key={id}
                      style={{
                        textDecoration: 'none',
                        color: theme.colors.secondary,
                      }}
                      to={(location) => ({
                        ...location,
                        pathname: `${location.pathname}/${person.id}`,
                      })}
                    >
                      <ParticipantName>
                        {user.formatted_name?.toUpperCase()}
                      </ParticipantName>
                    </Link>
                  </div>
                  <div style={{ transform: 'rotate(-90deg)' }}>
                    <Link
                      key={id}
                      style={{
                        textDecoration: 'none',
                        color: theme.colors.secondary,
                      }}
                      to={(location) => ({
                        ...location,
                        pathname: `${location.pathname}/${person.id}`,
                      })}
                    >
                      <Icon
                        icon="chevron"
                        color={theme.colors.primary}
                        size={22}
                      />
                    </Link>
                  </div>
                </ParticipantRow>
              );
            })}
        </ParticipantList>
        )
      </div>
      {/* {ticket ? (
        <TicketModal
          location={location}
          admin={location.pathname.includes('/admin/transactions/')}
          match={match}
          ticket={ticket}
          close={() => {
            setTicket(null);
          }}
        />
      ) : null} */}
      <PassesModal
        showModal={showPasses}
        event={event}
        close={() => {
          setShowPasses(false);
        }}
        location={location}
        match={match}
      />
      <CheckInModal
        itemType={
          process.env.REACT_APP_PLATFORM !== 'tickethoss' ? 'Pass' : 'Ticket'
        }
        isVisible={!!shouldDisplayCheckInModal}
        onConfirm={selectOne}
        setIsVisible={setShouldDisplayCheckInModal}
        unCheck={unCheck}
      />
    </>
  );
};
