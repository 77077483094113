import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_TRACK = gql`
  query GetTrack($input: GetTrackInput!) {
    getTrack(input: $input) {
      id
      name
      infos {
        id
        logo
        logo_id
        link
        text
        info {
          id
          name
        }
        order
        video_url
        image_id
        image_url
        pdf_id
        pdf_url
      }
    }
  }
`;

export function useGetTrackInfo(currentTrack) {
  const { data, loading, error } = useQuery(GET_TRACK, {
    variables: { input: { id: currentTrack ? currentTrack : 0 } },
    fetchPolicy: 'no-cache',
    skip: !currentTrack,
  });
  return { data, loading, error };
}
