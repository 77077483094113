import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import {
  CardText,
  Card,
  CardLabel,
  CardContent,
} from 'components/Card/cardStyle';
import Container from 'components/Container';
import Icon from 'components/Icon';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { errorMessage, successMessage } from 'shared/alerts';
import { AppContext } from 'shared/AppContext';
import { formatPhoneNumber } from 'shared/formatters';
import DeleteEmployee from '../gql/mutations/delete-employee';
import GetEmployee from './gql/get-employee.js';

const Employees = (props) => {
  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const {
    state: { capabilities, accountId },
  } = useContext(AppContext);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  if (!props.data.getEmployeeV2) return false;

  return props.data.loading ? (
    <div />
  ) : (
    <Container>
      <div style={{ padding: 20 }}>
        <Card key={props.data.getEmployeeV2.id}>
          <CardText>
            <CardLabel style={{ width: '15%' }}>Name:</CardLabel>
            <CardContent
              style={{ width: '85%' }}
            >{`${props.data.getEmployeeV2.last_name}, ${props.data.getEmployeeV2.first_name}`}</CardContent>
          </CardText>
          <CardText>
            <CardLabel style={{ width: '15%' }}>Email:</CardLabel>{' '}
            <CardContent style={{ width: '85%' }}>
              {props.data.getEmployeeV2.email}
            </CardContent>
          </CardText>
          <CardText>
            <CardLabel style={{ width: '15%' }}>Phone:</CardLabel>{' '}
            <CardContent style={{ width: '85%' }}>
              {formatPhoneNumber(props.data.getEmployeeV2.cellphone)}
            </CardContent>
          </CardText>
          <Icon
            icon="edit"
            size={23}
            color={props.theme.colors.primary}
            onClick={async () => {
              if (!capabilities || capabilities?.add_staff)
                props.history.push(
                  `/admin-${userType}/employees/editEmployee/${props.data.getEmployeeV2.id}`
                );
              else setShowPermissionModal(true);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={23}
            color={props.theme.colors.primary}
            onClick={async () => {
              if (!capabilities || capabilities?.add_staff) {
                if (
                  window.confirm('Are you sure you want to delete this user?')
                ) {
                  const response = await props.deleteEmployee(
                    props.data.getEmployeeV2.id,
                    accountId
                  );
                  if (response && !response.errors)
                    successMessage('staff deleted successfully');
                  else {
                    if (response.errors) {
                      errorMessage(response.errors.message);
                    } else errorMessage('Error deleting staff');
                  }
                  props.history.push(`/admin-${userType}/employees`);
                }
              } else setShowPermissionModal(true);
            }}
          />
        </Card>
      </div>

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={props.history}
      />
    </Container>
  );
};

export default compose(GetEmployee, DeleteEmployee)(withTheme(Employees));
