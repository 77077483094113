import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const GET_TRACK_OPEN_CREDITS = gql`
  query GetTrackOpenCredits($creditsInput: GetTrackCreditsInput!) {
    getTrackOpenCredits(input: $creditsInput) {
      count
      results {
        id
        formatted_name
      }
    }
  }
`;

export function useGetTrackOpenCredits() {
  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();
  const {
    state: { accountId },
  } = useContext(AppContext);

  const result = usePersistedQuery(
    GET_TRACK_OPEN_CREDITS,
    `track-open-credits`,
    {
      variables: {
        creditsInput: {
          ...(yearFilter ? { year: yearFilter } : null),
          ...search,
          ...{ account_id: accountId },
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, res: result.data };
}
