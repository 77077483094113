import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import MobileSearchInput from 'pages/track/GuestList/GuestListEventsMobile/search';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import Modal from '../employeeModal';
import { GetEmployees } from '../gql/queries/get-employees';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const IssueButton = styled(Button)`
  border-radius: 5;
  background-color: '#fa4616';
  padding-top: 8;
  padding-bottom: 8;
  text-decoration: 'none';
  color: '#fff';
  font-family: 'Barlow Condensed';
  font-size: 20;
  font-weight: 600;
  text-align: 'center';
  display: 'block';
  line-height: 1.2;
  text-transform: 'uppercase';
  width: 100%;
  height: 40px;
`;

const EmployeeList = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';
  const { page } = useParams();

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [search, setSearch] = useState('');
  const [employeeModal, setEmployeeModal] = useState(false);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const { data } = GetEmployees(search);

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  if (!data?.getEmployeesV2) return null;

  return (
    <div style={{ justifyContent: 'center', alignContent: 'center' }}>
      <MobileSearchInput
        placeholder="Search Employees"
        onChange={handleChange}
        value={search}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleKeyPress={handleKeyPress}
      />
      <Spacer size={10} />

      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          margin: 'auto',
        }}
      >
        <IssueButton
          onClick={
            !capabilities || capabilities?.add_staff
              ? () =>
                  props.history.push(`/admin-${userType}/employees/addEmployee`)
              : () => setShowPermissionModal(true)
          }
        >
          {'Add Staff'}
        </IssueButton>
      </div>
      <Spacer size={10} />
      {data.getEmployeesV2.results.map((item) => (
        <MobileContainer
          onClick={() => {
            history.push(`/admin-${userType}/employees/${item.id}`);
          }}
        >
          <FlexRow>
            <CardInfo>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Text
                      type="heading"
                      fontSize={25}
                      lineHeight={28}
                      color="#fff"
                    >
                      {item.formatted_name}
                    </Text>
                    <Spacer size={10} />
                  </div>
                </div>
              </div>
            </CardInfo>
            <div style={{ transform: 'rotate(-90deg)' }}>
              <Icon icon="chevron" size={22} color={'red'} />
            </div>
          </FlexRow>
        </MobileContainer>
      ))}
      <div style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}>
        <Pagination
          count={data.getEmployeesV2.count ?? 0}
          perPage={15}
          currentPage={page || 1}
          color={'#fff'}
        />
      </div>

      {employeeModal ? (
        <Modal closeModal={() => setEmployeeModal(false)} />
      ) : null}
      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </div>
  );
};

export default EmployeeList;
