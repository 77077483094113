import qs from 'qs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import { useGetUsers } from 'pages/app/Users/gql/queries/get-users';
import { logDevError } from 'shared/alerts';
import { formatPhoneNumber } from 'shared/formatters';
import { useMembership } from 'shared/membershipContext';
import { useTransferMembership } from '../gql/transferMembership';
import TransferConfirmationModal from './TransferConfirmationModal';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const TransferMembership = (props) => {
  const { theme } = props;
  const history = useHistory();

  const [userRoleFilter, setUserRoleFilter] = useState(null);
  const transferMembership = useTransferMembership();

  const { setFetchMember } = useMembership();

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const [isVisible, setIsVisible] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const { id } = useParams();

  const { data } = useGetUsers(userRoleFilter ? userRoleFilter.value : null);

  const columns = [
    {
      label: 'User Name',
      key: 'name',
    },
    {
      label: 'Role',
      key: 'role',
    },
    {
      label: 'Mobile Phone',
      key: 'cellphone',
    },
    {
      label: 'Accounts',
      key: 'accounts',
    },
    {
      label: 'Track / Series',
      key: 'trackSeries',
    },
    {
      label: 'Transfer',
      key: 'actions',
    },
  ];

  const getRole = (role) => {
    if (role === 'admin')
      return process.env.REACT_APP_PLATFORM === 'tickethoss'
        ? 'Ticket Hoss'
        : 'Pit Pay';
    else if (role === 'employee') return 'Staff';
    else if (role === 'track') return 'Account Owner';
    else return 'App User';
  };

  const confirmTransfer = async () => {
    try {
      const response = await transferMembership({
        pass_id: +id,
        user_id: newUser.id,
      });

      if (!response || response.errors) {
        toast.error('Error transferring membership.');
      } else {
        setIsVisible(false);
        toast.success('Membership transferred successfully.');
        setFetchMember(true);

        const { page } = qs.parse(window.location.search.substring(1));
        if (
          window.location.search.includes('queryString') &&
          window.location.search.includes('page')
        ) {
          history.go(-(Number(page) + 1));
        } else if (window.location.search.includes('page')) {
          history.go(-Number(page));
        } else if (window.location.search.includes('queryString'))
          history.go(-2);
        else history.go(-1);
      }
    } catch (error) {
      logDevError(error);
      toast.error('Error transferring membership.....');
    }
  };

  function renderRows(user) {
    const {
      id,
      last_name,
      first_name,
      middle_name,
      suffix,
      inactive,
      role,
      cellphone,
      accounts,
    } = user;

    const tracksAndSeries = accounts
      ? accounts.map((account) => {
          const accountTrack = account.tracks
            ? account.tracks.map((track) => track.name)
            : [];
          const accountSeries = account.series
            ? account.series.map((series) => series.name)
            : [];

          return accountTrack.concat(accountSeries);
        })
      : [];

    return {
      name: (
        <Link
          to={
            userType === 'admin'
              ? `/admin/users/editUser/${id}?role=${
                  userRoleFilter ? userRoleFilter.value : ''
                }&isTransfer=true`
              : `/admin-${userType}/users/editUser/${id}?role=${
                  userRoleFilter ? userRoleFilter.value : ''
                }&isTransfer=true`
          }
          style={{
            color:
              'inactive' === inactive
                ? theme.colors.text.light
                : theme.colors.primary,
          }}
        >
          <LineHeightText>{`${last_name ? `${last_name}, ` : ''}${first_name} ${
            middle_name ? middle_name : ''
          } ${suffix ? `, ${suffix}` : ''}`}</LineHeightText>
        </Link>
      ),
      role: getRole(role),
      cellphone: formatPhoneNumber(cellphone),
      accounts: accounts.map((item, index) => (
        <p key={index} style={{ lineHeight: 1.5 }}>
          {item.business_dba}
        </p>
      )),
      trackSeries: tracksAndSeries.map((item, index) => (
        <p key={index} style={{ lineHeight: 1.5, width: 250 }}>
          {item}
        </p>
      )),
      actions: (
        <div style={{ display: 'flex' }}>
          <Icon
            icon="transaction"
            size={30}
            color={props.theme.colors.primary}
            onClick={async () => {
              setNewUser(user);
              setIsVisible(true);
            }}
            padding="0 15px 0 0"
          />
        </div>
      ),
    };
  }

  return !data?.getUsers ? (
    <div />
  ) : (
    <>
      <SearchableListContainer
        pageCount={data.getUsers.count}
        paginated
        title="Select User"
        searchInputPlaceholder="Search users"
        titleBarContent={
          <FilterContainer>
            <AutoSuggest
              placeholder="User Role"
              options={[
                {
                  label: 'Pit Pay',
                  value: 'admin',
                },
                {
                  label: 'Staff',
                  value: 'employee',
                },
                {
                  label: 'Account Owner',
                  value: 'track',
                },
                {
                  label: 'App User',
                  value: 'user',
                },
              ]}
              value={
                userRoleFilter
                  ? {
                      label:
                        userRoleFilter.label.charAt(0).toUpperCase() +
                        userRoleFilter.label.slice(1),
                      key: userRoleFilter,
                    }
                  : null
              }
              isSearchable
              isClearable
              onChange={(role) => {
                setUserRoleFilter(role ?? null);
              }}
            />
          </FilterContainer>
        }
      >
        <Table
          items={data.getUsers.results}
          columns={columns}
          renderRows={renderRows}
        />
      </SearchableListContainer>
      <TransferConfirmationModal
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        newUserName={`${newUser.last_name ? `${newUser.last_name}, ` : ''}${
          newUser.first_name
        } ${newUser.middle_name ? newUser.middle_name : ''} ${
          newUser.suffix ? `, ${newUser.suffix}` : ''
        }`}
        newUserId={newUser.id}
        confirmTransfer={confirmTransfer}
      />
    </>
  );
};

export default withTheme(TransferMembership);
