import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'components/Form/DatePicker';

const startDate = '',
  endDate = '';

const TransactionDateFilterContext = createContext();

export const TransactionDateFilterProvider = ({ children }) => {
  const [startDateFilter, setStartDateFilter] = useState(startDate);
  const [endDateFilter, setEndDateFilter] = useState(endDate);
  return (
    <TransactionDateFilterContext.Provider
      value={{
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
      }}
    >
      {children}
    </TransactionDateFilterContext.Provider>
  );
};

export const useTransactionDateFilter = () => {
  const context = useContext(TransactionDateFilterContext);
  if (context === undefined) {
    throw new Error(
      'useTransactionDateFilter must be used within an TransactionDateFilterProvider'
    );
  }
  return context;
};

export const withTransactionDateFilterContext = (Component) => {
  return (props) => (
    <TransactionDateFilterProvider>
      <Component {...props} />
    </TransactionDateFilterProvider>
  );
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;

  @media screen and (max-width: 768px) {
    margin-left: 0 0 10px 0;
    padding: 0 auto;
  }
`;

export const TransactionDateFilter = ({
  mobilePicker,
  customStartDate,
  customSetStartDateFilter,
  customEndDate,
  customSetEndDateFilter,
  maxWidth,
  minWidth,
  customStyle,
}) => {
  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = useTransactionDateFilter();

  return (
    <FilterContainer style={customStyle}>
      <DatePicker
        type="date"
        key={'0145'}
        value={customStartDate ? customStartDate : startDateFilter}
        minDateNOw={false}
        onChange={(_name, value) => {
          setStartDateFilter(value);
          if (customSetStartDateFilter) customSetStartDateFilter(value);
        }}
        placeholderText="Start Date"
        minWidth={minWidth ? minWidth : '150px'}
        maxWidth={maxWidth ? maxWidth : '180px'}
        mobilePicker={mobilePicker ? mobilePicker : null}
      />
      <DatePicker
        key={'0244'}
        type="date"
        value={customEndDate ? customEndDate : endDateFilter}
        minDateNOw={false}
        onChange={(_name, value) => {
          setEndDateFilter(value);
          if (customSetEndDateFilter) customSetEndDateFilter(value);
        }}
        placeholderText="End Date"
        minWidth={minWidth ? minWidth : '150px'}
        maxWidth={maxWidth ? maxWidth : '180px'}
        mobilePicker={mobilePicker ? mobilePicker : null}
      />
    </FilterContainer>
  );
};
