import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import Estimate from 'components/Estimate';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Radio } from 'components/Form/Radio';
import Spacer from 'components/Spacer';
import {
  YearDropdown,
  withEventYearFilterContext,
} from 'components/YearDropdown';
import { MemberDropdown } from 'pages/app/Notifications/Edit/MemberDropdown';
import { OpenCreditDropdown } from 'pages/app/Notifications/Edit/OpenCreditDropdown';
import { AppContext } from 'shared/AppContext';
import CreatePushNotification from '../gql/mutations/create-push-notification';
import GetNotificationEstimate from '../gql/mutations/get-estimate';
import { useGetNotificationEvents } from '../gql/queries/get-user-notification-events';
import TextForm from './TextForm';
import { ComposeButton } from './TextMessage';

const Label = styled.label`
  color: black;
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 4.2vw;
  display: block;
  margin-bottom: 15px;

  @media (min-width: 700px) {
    font-size: 16px;
  }
`;

const PushMessageForm = withEventYearFilterContext(
  ({
    createPushNotification,
    onComplete,
    clearState,
    audienceOptions,
    getNotificationEstimate,
    setShowOptions,
    setIsPushNotification,
  }) => {
    const [audienceTarget, setAudienceTarget] = useState();
    const [trackTarget, setTrackTarget] = useState();
    const [seriesTarget, setSeriesTarget] = useState();
    const [eventTarget, setEventTarget] = useState();
    const [estimate, setEstimate] = useState(null);
    const [memberTarget, setMemberTarget] = useState();

    const thisYear = Number(moment().format('YYYY'));
    const [year, setYear] = useState(thisYear);

    const [showComposeMessage, setShowComposeMessage] = useState(false);

    const {
      state: { accountId },
    } = useContext(AppContext);

    const { data, loading } = useGetNotificationEvents();

    useEffect(() => {
      const fetchResults = async () => {
        const results = await getNotificationEstimate({
          audienceTarget: audienceTarget ? audienceTarget : null,
          trackTarget: trackTarget ? trackTarget.value : null,
          seriesTarget: seriesTarget ? seriesTarget.value : null,
          eventTarget: eventTarget ? eventTarget.value : null,
          annualTarget: memberTarget ? memberTarget.value : null,
          account_id: accountId ? accountId : null,
          year: year ? year : null,
        });

        if (results && results.data) {
          setEstimate(results.data.getNotificationEstimate);
        }
      };
      fetchResults();
    }, [
      audienceTarget,
      trackTarget,
      seriesTarget,
      eventTarget,
      getNotificationEstimate,
      memberTarget,
      accountId,
      year,
    ]);

    useEffect(() => {
      if (
        audienceTarget &&
        audienceTarget === 'track-favorites' &&
        data.me.ownership.tracks.length === 1
      ) {
        setTrackTarget({ value: data?.me?.ownership?.tracks[0]?.id });
        setSeriesTarget(null);
      }

      if (
        audienceTarget &&
        audienceTarget === 'series-favorites' &&
        data.me.ownership.series.length === 1
      ) {
        setSeriesTarget({ value: data?.me?.ownership?.series[0]?.id });
        setTrackTarget(null);
      }
    }, [audienceTarget, data]);

    const resetForm = () => {
      setAudienceTarget('');
      setTrackTarget('');
      setSeriesTarget('');
      setEventTarget('');
      setEstimate(null);
      setMemberTarget('');
    };

    useEffect(() => {
      resetForm();
    }, [clearState]);

    if (loading) return null;

    const getAudienceOptions = () => {
      if (data.me.ownership.tracks.length && data.me.ownership.series.length) {
        return audienceOptions.full;
      }

      if (data.me.ownership.tracks.length) {
        return audienceOptions.track;
      }

      if (data.me.ownership.series.length) {
        return audienceOptions.series;
      }
      return audienceOptions.other;
    };

    const showMessageField = () => {
      return (
        (audienceTarget && audienceTarget === 'all-users') ||
        (audienceTarget &&
          audienceTarget === 'track-favorites' &&
          trackTarget) ||
        (audienceTarget &&
          audienceTarget === 'series-favorites' &&
          seriesTarget) ||
        (audienceTarget &&
          audienceTarget === 'event-participants' &&
          eventTarget) ||
        (audienceTarget && audienceTarget === 'memberships' && memberTarget) ||
        (audienceTarget && audienceTarget === 'credits' && accountId && year)
      );
    };

    return !showComposeMessage ? (
      <>
        <Spacer size={25} />
        <Label>Send Message To</Label>
        <Radio
          options={getAudienceOptions()}
          onChange={(val) => {
            setAudienceTarget(val.target.value);
          }}
          // onBlur={handleBlur}
          value={audienceTarget}
        />
        {audienceTarget &&
          audienceTarget === 'track-favorites' &&
          data.me.ownership.tracks.length > 1 && (
            <>
              <Spacer size={25} />
              <AutoSuggest
                name="track_id"
                label="Track"
                value={trackTarget}
                onChange={(value) => {
                  setTrackTarget(value);
                }}
                // onBlur={() => setTrackTarget('track_id', true)}
                closeMenuOnSelect
                options={data.me.ownership.tracks.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </>
          )}

        {audienceTarget &&
          audienceTarget === 'series-favorites' &&
          data.me.ownership.series.length > 1 && (
            <>
              <Spacer size={25} />
              <AutoSuggest
                name="series_id"
                label="Series"
                value={seriesTarget}
                onChange={(value) => {
                  setSeriesTarget(value);
                }}
                closeMenuOnSelect
                options={data.me.ownership.series.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </>
          )}

        {audienceTarget && audienceTarget === 'event-participants' ? (
          <>
            <Spacer size={25} />
            <YearDropdown />
          </>
        ) : null}

        {audienceTarget && audienceTarget === 'event-participants' ? (
          <>
            <Spacer size={25} />
            <AutoSuggest
              name="event_id"
              label="Event Participants"
              isClearable
              placeholder="Select Event"
              onChange={(value) => {
                setEventTarget(value);
                setTrackTarget(null);
                setSeriesTarget(null);
              }}
              value={eventTarget}
              closeMenuOnSelect
              options={data.getUserNotificationEvents.results.map((item) => ({
                value: item.id,
                label: item.isMultiDay
                  ? `${item.month} ${item.listDates} - ${moment(
                      item.fullDate
                    ).format('YYYY')}`.toUpperCase() + ` — ${item.name}`
                  : `${moment(item.fullDate).format(
                      'ddd MMM D - YYYY'
                    )}`.toUpperCase() + `— ${item.name}`,
              }))}
            />
          </>
        ) : null}

        {audienceTarget && audienceTarget === 'memberships' && (
          <MemberDropdown
            setMemberTarget={setMemberTarget}
            accountId={accountId}
          />
        )}

        {audienceTarget && audienceTarget === 'credits' && (
          <OpenCreditDropdown year={year} setYear={setYear} />
        )}

        <Spacer size={70} />
        <Estimate estimate={estimate} type={'push'} />
        <ComposeButton
          onClick={() => {
            setShowOptions(false);
            setIsPushNotification(true);
            setShowComposeMessage(true);
          }}
          buttonStyle={{ height: 35 }}
          disabled={!showMessageField()}
        >
          {'Compose Message'}
        </ComposeButton>
        <Spacer size={30} />
      </>
    ) : (
      <>
        <TextForm
          onSubmit={async ({ title, message }) => {
            const response = await createPushNotification({
              message,
              title,
              audienceTarget,
              trackTarget: trackTarget?.value,
              seriesTarget: seriesTarget?.value,
              eventTarget: eventTarget?.value,
              annualTarget: memberTarget?.value,
              account_id: accountId ? accountId : null,
              year: year ? year : null,
            });

            if (response && !response.errors) {
              resetForm();
              onComplete();
            }

            return response;
          }}
          showTitle={true}
          estimate={estimate}
          type={'push'}
        />
      </>
    );
  }
);

export default compose(
  CreatePushNotification,
  GetNotificationEstimate
)(PushMessageForm);
