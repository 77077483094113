import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'styled-components';

const loadOptions = async (
  inputValue,
  callback,
  refetch,
  setNotFoundText,
  characterCount,
  queryKey,
  option,
  prepareVariables
) => {
  if (inputValue.length >= characterCount) {
    const response = await refetch(prepareVariables(inputValue));
    if (
      response &&
      response.data &&
      response.data[queryKey] &&
      response.data[queryKey].length
    ) {
      const options = response.data[queryKey].map((item) => option(item));

      callback(options);
    } else {
      setNotFoundText();
      return callback([]);
    }
  } else {
    setNotFoundText();
    return callback([]);
  }
};

export const AsyncAutoSuggest = ({
  value,
  refetch,
  characterCount,
  isModal,
  NoFoundText,
  onChange,
  queryKey,
  option,
  prepareVariables,
}) => {
  const theme = useTheme();

  const defaultStyle = {
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: theme.colors.primary,
      },
    }),
    control: (provided) => ({
      ...provided,
      height: 49,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(0,0,0,0.7)',
    }),
  };

  const [notFoundText, setNotFoundText] = useState(false);
  return (
    <div>
      <AsyncSelect
        value={value}
        isClearable
        cacheOptions
        noOptionsMessage={(_) => {
          if (notFoundText) {
            return NoFoundText;
          }
        }}
        placeholder="Start Typing..."
        onChange={(selectedOption) => {
          onChange(selectedOption);
        }}
        defaultOptions={[]}
        maxMenuHeight={isModal ? 160 : undefined}
        menuPortalTarget={isModal ? document.body : undefined}
        loadOptions={(inputValue, callback) => {
          loadOptions(
            inputValue,
            callback,
            refetch,
            () => setNotFoundText(true),
            characterCount,
            queryKey,
            option,
            prepareVariables
          );
        }}
        styles={defaultStyle}
      />
    </div>
  );
};
