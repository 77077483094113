import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetTotalOpenCredits {
    getTotalOpenCredits
  }
`;

export function useGetTotalOpenCredits() {
  return useQuery(query);
}
