import React, { useRef, useState } from 'react';
import { Route, MemoryRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import Icon from 'components/Icon';
import { ModalHeaderContainer } from 'components/Modal/styles';
// import { ContinueButton } from 'components/Transactions/TransactionModal/TransactionCreditModal';
import GetTicketsByDate from '../gql/queries/get-tickets-by-date';
import {
  useCancelEvent,
  useDeleteEvent,
  usePostponeEvent,
} from './CancelModal.mutations';
import { useCreditEvent } from './gql/useCreditEvent.mutation';
import {
  Container,
  ModalContainer,
  Heading,
  OptionsWrapper,
  Option,
  Wrapper,
  ButtonWrapper,
} from './style';

const IssueCreditHeader = styled.h3`
  color: #fa4616;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  margin: 30px 0;
  line-height: 30px;

  @media (max-width: 700px) {
    font-size: 22px;
    margin-top: 15px;
  }
`;

const IssueCreditInfo = styled.p`
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
  line-height: 30px;

  @media (max-width: 700px) {
    font-size: 22px;
    margin-top: 15px;
  }
`;

export const SkipButton = styled.button`
  border-width: 3px;
  border-style: solid;
  border-color: #fa4616;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  color: #fa4616;
  font-size: 22px;
  font-family: 'Barlow Condensed';
  background-color: #fff;
  width: 50%;
  display: block;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.disabled
      ? `border-width: 3px;
  border-style: solid;
  border-color: #ccc; color: #ccc; pointer-events: none`
      : ''};

  &:hover {
    background: rgba(250, 70, 22, 0.1) !important;
  }
`;

export const AlignmentContainer = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-around';
  margin-top: 10;
  margin-bottom: 10;
`;

const BaseModal = (props) => {
  const {
    close,
    selectedEvent,
    allEvents,
    data,
    showCancelModal,
    currentType,
    objectId,
    noDelete,
    adminTrack,
  } = props;

  const ref = useRef();
  const [overflow, setOverflow] = useState();
  const postponeEvent = usePostponeEvent();
  const cancelEvent = useCancelEvent();
  const deleteEvent = useDeleteEvent();
  const issueCredit = useCreditEvent();

  if (!data && !data.getTicketsByDate) return false;
  const { loading } = data;

  //
  return (
    <Container showCancelModal={showCancelModal}>
      <ModalContainer
        ref={ref}
        onClick={(e) => e.stopPropagation()}
        overflow={overflow}
      >
        <DeleteModal
          adminTrack={adminTrack}
          close={close}
          selectedEvent={selectedEvent}
          allEvents={allEvents}
          data={data}
          loading={loading}
          postponeEvent={postponeEvent}
          cancelEvent={cancelEvent}
          deleteEvent={deleteEvent}
          issueCredit={issueCredit}
          currentType={currentType}
          objectId={objectId}
          noDelete={noDelete || adminTrack}
          setOverflow={(value) => {
            setOverflow(value);
          }}
        />
      </ModalContainer>
    </Container>
  );
};

export const CancelModal = GetTicketsByDate(BaseModal);

const DeleteModal = (props) => {
  const {
    close,
    selectedEvent,
    data,
    postponeEvent,
    cancelEvent,
    deleteEvent,
    issueCredit,
    currentType,
    objectId,
    noDelete,
    loading,
    adminTrack,
  } = props;

  if (!data && !data.getTicketsByDate) return false;

  return (
    <MemoryRouter>
      <Route
        path="/"
        exact
        render={(props) => (
          <DeleteEventHeader
            {...props}
            close={close}
            selectedEvent={selectedEvent}
            data={data}
            noDelete={noDelete}
            loading={loading}
            adminTrack={adminTrack}
          />
        )}
      />
      {/* <Route
        path="/postpone"
        render={props => (
          <PostponeSingleDay
            setOverflow={setOverflow}
            props={props}
            close={close}
            event={selectedEvent}
            differentEvents={allEvents}
            onSubmit={async ({ state, initialTickets }) => {
              try {
                let items = '';
                if (!state.tbd) {
                  items = initialTickets.map((ticket, index) => ({
                    old_id: ticket.id,
                    new_id: state.newTickets[index]
                  }));
                }


                let response = await postponeEvent({
                  tbd: state.tbd ? true : false,
                  date: state.different_event_date
                    ? null
                    : state.date
                    ? moment(state.date).format('MM-DD-YYYY')
                    : state.event.start_date,
                  event_id: state.event.id,
                  new_event_id: state.tbd
                    ? null
                    : state.different_event
                    ? state.different_event.id
                    : null,
                  post_date: state.tbd
                    ? null
                    : state.date
                    ? null
                    : moment(state.different_event_date.value).format(
                        'MM-DD-YYYY'
                      ),
                  tickets: state.different_event_date ? items : null
                });


                if (!response.errors) {
                  toast.success('Event postponed successfully');
                  close();
                } else {
                  toast.error(`Cannot postpone events.`);
                }
              } catch (e) {
                                toast.error(`Cannot postpone event, ${e}`);
              }
            }}
          />
        )}
      />
      <Route
        path="/postpone-multiday-event"
        render={props => (
          <PostponeMultiday
            props={props}
            setOverflow={setOverflow}
            close={close}
            event={selectedEvent}
            differentEvents={allEvents}
            onSubmit={async ({ state, initialTickets }) => {
              try {

                let items = initialTickets.map((ticket, index) => ({
                  old_id: ticket.id,
                  new_id: state.newTickets[index]
                }));

                let response = await postponeEvent({
                  tbd: state.tbd,
                  event_id: state.event.id,
                  new_event_id: state.tbd
                    ? null
                    : state.different_event
                    ? state.different_event.id
                    : state.same_event_date_to_move_to
                    ? state.event.id
                    : null,
                  post_date: moment(state.start_date, 'YYYY-MM-DD').format(
                    'MM-DD-YYYY'
                  ),
                  tickets:
                    state.different_event || state.same_event_date_to_move_to
                      ? items
                      : null
                });


                if (!response.errors) {
                  toast.success('Event postponed successfully');
                  close();
                } else {
                  toast.error(`Cannot postpone events.`);
                }
              } catch (e) {
                                toast.error(`Cannot postpone event, ${e}`);
              }
            }}
          />
        )}
      /> */}
      <Route
        path="/postpone"
        render={(props) => (
          <Postpone
            {...props}
            close={close}
            selectedEvent={selectedEvent}
            postponeEvent={postponeEvent}
          />
        )}
      />
      <Route
        path="/cancel"
        render={(props) => (
          <CancelEvent
            {...props}
            close={close}
            selectedEvent={selectedEvent}
            cancelEvent={cancelEvent}
          />
        )}
      />

      <Route
        path="/delete"
        render={(props) => (
          <DeleteEvent
            {...props}
            close={close}
            selectedEvent={selectedEvent}
            deleteEvent={deleteEvent}
            currentType={currentType}
            objectId={objectId}
          />
        )}
      />

      <Route
        path="/issueCredit"
        render={(props) => (
          <IssueEventCredit
            {...props}
            close={close}
            selectedEvent={selectedEvent}
            objectId={objectId}
          />
        )}
      />

      <Route
        path="/issueCreditConfirm"
        render={(props) => (
          <IssueEventCreditConfirm
            {...props}
            close={close}
            selectedEvent={selectedEvent}
            objectId={objectId}
            issueCredit={issueCredit}
          />
        )}
      />
    </MemoryRouter>
  );
};

const DeleteEventHeader = (props) => {
  const { close, noDelete } = props;
  //   if (loading) return <Loading />;

  return (
    <>
      <ModalHeaderContainer>
        <Heading>Do you want to cancel or postpone this entire event?</Heading>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </ModalHeaderContainer>
      <Wrapper>
        <OptionsWrapper>
          <Option>
            <Link to={`/cancel`}>CANCEL</Link>
          </Option>

          <Option>
            <Link to={`/postpone`}>POSTPONE</Link>
          </Option>

          {noDelete ? null : (
            <Option>
              <Link to={`/delete`}>DELETE</Link>
            </Option>
          )}
        </OptionsWrapper>
      </Wrapper>
    </>
  );
};

const Postpone = (props) => {
  const { close, postponeEvent, selectedEvent, history } = props;

  const handleSubmit = async () => {
    try {
      const response = await postponeEvent(selectedEvent.id);

      //
      if (!response.errors) {
        toast.success('Event Postponed');
        history.push('/issueCredit');
      } else {
        toast.error('Cannot postpone event at this time');
      }
    } catch (e) {
      toast.error(`Cannot postpone event, ${e}`);
    }
  };

  return (
    <div>
      <ModalHeaderContainer>
        <Link to={'/'}>
          <Icon icon="left-arrow" size={22} color={'#fa4616'} />
        </Link>
        <Heading>POSTPONE the event and STOP PASS SALES?</Heading>

        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </ModalHeaderContainer>
      <Wrapper>
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes, POSTPONE
          </Button>

          <Link to={`/`} style={{ marginLeft: 20 }}>
            <Button type="button">NO</Button>
          </Link>
        </ButtonWrapper>
      </Wrapper>
    </div>
  );
};

const CancelEvent = (props) => {
  const { close, cancelEvent, selectedEvent, history } = props;

  const handleSubmit = async () => {
    try {
      const response = await cancelEvent(selectedEvent.id);

      //
      if (!response.errors) {
        toast.success('Event Cancelled');

        history.push('/issueCredit');
      } else {
        toast.error('Cannot cancel event at this time');
      }
    } catch (e) {
      toast.error(`Cannot cancel event, ${e}`);
    }
  };

  return (
    <div>
      <ModalHeaderContainer>
        <Link to={'/'}>
          <Icon icon="left-arrow" size={22} color={'#fa4616'} />
        </Link>
        <Heading>CANCEL the event and STOP PASS SALES?</Heading>

        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </ModalHeaderContainer>
      <Wrapper>
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            YES, CANCEL
          </Button>

          <Link to={`/`} style={{ marginLeft: 20 }}>
            <Button type="button">NO</Button>
          </Link>
        </ButtonWrapper>
      </Wrapper>
    </div>
  );
};

const DeleteEvent = (props) => {
  const { close, deleteEvent, selectedEvent, currentType, objectId } = props;

  const handleSubmit = async () => {
    try {
      const response = await deleteEvent(
        { id: selectedEvent.id },
        currentType,
        objectId
      );

      //
      if (!response.errors) {
        toast.success('Event Deleted');
        close();
      } else {
        toast.error('Cannot delete event at this time');
      }
    } catch (e) {
      toast.error(`Cannot delete event, ${e}`);
    }
  };

  return (
    <div>
      <ModalHeaderContainer>
        <Link to={'/'}>
          <Icon icon="left-arrow" size={22} color={'#fa4616'} />
        </Link>
        <Heading>Are you sure you want to delete the event?</Heading>

        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </ModalHeaderContainer>
      <Wrapper>
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes, Delete
          </Button>

          <Link to={`/`} style={{ marginLeft: 20 }}>
            <Button type="button">No</Button>
          </Link>
        </ButtonWrapper>
      </Wrapper>
    </div>
  );
};

const IssueEventCredit = (props) => {
  const { close, history } = props;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: 'inline',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
            maxWidth: '60%',
          }}
        >
          <IssueCreditHeader>ISSUE CREDIT FOR THIS EVENT</IssueCreditHeader>

          <Heading>
            <IssueCreditInfo>
              Would You Like to issue a Credit to EVERYONE that has purchased
              Tickets for this event?
            </IssueCreditInfo>
          </Heading>

          <Heading>
            <IssueCreditInfo>
              IF YOU ISSUE A FULL EVENT CREDIT TAHT CAN NOT BE UNDONE?
            </IssueCreditInfo>
          </Heading>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          margin: '10 0',
        }}
      >
        <Button onClick={() => history.push('/issueCreditConfirm')}>
          CONTINUE
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginBottom: 20,
        }}
      >
        <SkipButton onClick={() => close()}>SKIP FOR NOW</SkipButton>
      </div>
    </div>
  );
};

const IssueEventCreditConfirm = (props) => {
  const { close, issueCredit, selectedEvent } = props;
  const [creditMessage, setCreditMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await issueCredit(selectedEvent.id, creditMessage);

      if (!response.errors) {
        toast.success('Credit Issued Successfully');
        close();
      } else {
        toast.error('Error issuing credit');
      }
    } catch (e) {
      toast.error(`Cannot Issue credit, ${e}`);
    }
  };

  const handleMessageChange = (e) => setCreditMessage(e.target.value);

  return (
    <div>
      <div
        style={{
          display: 'inline',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: 30,
        }}
      >
        <div style={{ padding: 5 }}>
          <Link to={'/issueCredit'}>
            <Icon icon="left-arrow" size={22} color={'#fa4616'} />
          </Link>
        </div>
        <AlignmentContainer
          style={{
            width: '80%',
            margin: 'auto',
          }}
        >
          <Heading>
            <IssueCreditInfo>Include Personalized message</IssueCreditInfo>
          </Heading>

          <Input
            inputStyle={{ height: 200, marginLeft: 18 }}
            id="credit_message"
            as="textarea"
            onChange={handleMessageChange}
          />
          <Heading>
            <IssueCreditInfo>
              A text Message will be sent notifying them of their credit, with
              instructions on how to use the credit.
            </IssueCreditInfo>
          </Heading>
        </AlignmentContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: '10px 0',
          }}
        >
          <Button onClick={handleSubmit}>ISSUE CREDIT</Button>
        </div>
      </div>
    </div>
  );
};
