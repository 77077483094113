import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { SearchInput } from 'components/Form/SearchInput';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { ApproveModal } from 'components/Modal';
import Pagination from 'components/Pagination';
import { WaiverNotSigned } from 'components/SVG/WaiverNotSigned';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import Text from 'components/Text';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { FilterContainer } from 'pages/app/Credits/OpenCredits/OpenCredits';
import { TitleContainer } from 'pages/track/Contact/style';
import { AppContext } from 'shared/AppContext';
import { formatPhoneNumber } from 'shared/formatters';
import { ContainerWrapper } from '../AnnualTickets';
import ExportAccountMembers from './ExportAccountMembers';
import {
  useApproveMember,
  useUnApproveMember,
} from './gql/useApproveMemberships';
import { useGetAccountMembers } from './gql/useGetAccountMembers';
import { useGetAccountMemberships } from './gql/useGetAccountMemberships';
import { useGetMembershipDivisions } from './gql/useGetMembershipDivisions';
import { MembershipDropdown } from './MembershipDropdown';
import { PrintAccountMembersReport } from './PrintAccountMembersReport';

const HeaderContainer = styled.div`
  margin: 40px 40px 0px 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  vertical-align: center;
`;
export const MembershipFilterContainer = styled.div`
  min-width: 250px;
  margin: 5px;
`;

const Td = styled(TableCell)`
  text-align: left;
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  &:last-child {
    padding-right: 30px;
  }
  &:first-child {
    padding-left: 30px;
  }
`;

const MembersList = withTransactionDateFilterContext(
  withEventYearFilterContext(() => {
    const thisYear = Number(moment().format('YYYY'));
    const [year, setYear] = useState(thisYear);
    const [typeId, setTypeId] = useState();
    const [division, setDivision] = useState();
    const [currentMember, setCurrentMember] = useState(null);

    const [count, setCount] = useState();

    const urlParams = new URLSearchParams(window.location.search);
    const currentPage = parseInt(urlParams.get('page'));

    const [search, setSearch] = useState('');

    const [shouldDisplayApproveModal, setShouldDisplayApproveModal] =
      useState(false);

    const [unCheck, setUncheck] = useState(false);
    const theme = useTheme();
    const { name } = useParams();

    const { pathname } = useLocation();
    const userType = pathname?.split('/').includes('admin-track')
      ? 'track'
      : pathname?.split('/').includes('admin-employee')
      ? 'employee'
      : 'admin';

    const {
      state: { accountId, accountName },
    } = useContext(AppContext);

    const { data } = useGetAccountMembers({
      year,
      typeId: typeId ? typeId.value : null,
      division: division ? division.value : null,
      accountId,
    });
    const { data: memberships } = useGetAccountMemberships({ year, accountId });
    const { data: divisions } = useGetMembershipDivisions(
      typeId ? typeId.value : null
    );

    useEffect(() => {
      if (data && data.getAccountMembers) {
        setCount(data?.getAccountMembers?.count);
      }
    }, [data]);

    const approveMember = useApproveMember();
    const unApproveMember = useUnApproveMember();

    async function onConfirmApprove() {
      const { id } = currentMember;
      return unCheck ? await unApproveMember(id) : await approveMember(id);
    }

    const updateQueryString = useUpdateQueryString();

    const handleChange = (e) => setSearch(e.target.value);

    const handleBlur = (e) => {
      updateQueryString(e.target.value);
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
        updateQueryString(e.target.value);
      }
    };

    if (!data?.getAccountMembers) return null;

    return (
      <>
        {data.getAccountMembers.count > 0 ? (
          <HeaderContainer>
            <Text
              fontSize={32}
              lineHeight={29}
              type="heading"
              style={{ marginTop: 20 }}
            >
              {name ? name : ''}
            </Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <ExportAccountMembers
                icon={<Icon icon="Export-Icon" size={40} />}
                accountId={accountId}
                typeId={typeId ? typeId.value : null}
                division={division ? division.value : null}
                year={year}
                type={'membership'}
              />
              <PrintAccountMembersReport
                text={'Print List'}
                accountId={accountId}
                accountName={name ?? accountName}
                ticketName={typeId ? typeId.label : null}
                typeId={typeId ? typeId.value : null}
                division={division ? division.value : null}
                year={year}
                style={{ marginLeft: 'auto' }}
                type={'membership'}
              />
            </div>
          </HeaderContainer>
        ) : null}

        <Container>
          <ContainerHeader style={{ height: 140, gap: 10 }}>
            <ContainerWrapper>
              <TitleContainer
                style={{ justifyContent: 'space-between', width: '100%' }}
              >
                <Text
                  type="heading"
                  color="#3C4144"
                  inlineStyle={{ marginRight: 25, marginTop: 10 }}
                >
                  {`Members (${data?.getAccountMembers.count})`}
                </Text>

                <div style={{ marginRight: 15 }}>
                  <SearchInput
                    placeholder="Search Tickets"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyPress}
                    value={search}
                  />
                </div>
              </TitleContainer>

              <TitleContainer>
                <TitleContainer>
                  <FilterContainer>
                    {' '}
                    <YearDropdown onSelect={(year) => setYear(year.value)} />
                  </FilterContainer>

                  <TransactionDateFilter />

                  {memberships && memberships.getAccountMemberships.length ? (
                    <MembershipFilterContainer>
                      <MembershipDropdown
                        memberships={memberships.getAccountMemberships}
                        typeId={typeId}
                        setTypeId={setTypeId}
                      />
                    </MembershipFilterContainer>
                  ) : null}
                  {typeId &&
                  divisions &&
                  divisions.getMembershipDivisions &&
                  divisions.getMembershipDivisions.length > 0 ? (
                    <FilterContainer>
                      <AutoSuggest
                        placeholder="Select Division"
                        options={divisions.getMembershipDivisions.map(
                          (ticket) => ({
                            label: ticket,
                            value: ticket,
                          })
                        )}
                        value={division ? division : null}
                        isSearchable
                        isClearable
                        onChange={(ticket) => {
                          setDivision(ticket ?? null);
                        }}
                      />
                    </FilterContainer>
                  ) : null}
                </TitleContainer>
              </TitleContainer>
            </ContainerWrapper>
          </ContainerHeader>

          <div style={{ padding: 20 }}>
            <Table>
              <TableHeader>
                <TableRow>
                  <Td scope="col">Approved</Td>
                  <Td scope="col">Name</Td>
                  <Td scope="col">Email</Td>
                  <Td scope="col">Phone</Td>
                  <Td scope="col">Member #</Td>
                  <Td scope="col">Membership Type</Td>
                  <Td scope="col">Division/Class</Td>
                  <Td scope="col">Agreements</Td>
                  {/* <Td scope="col">Total</Td> */}
                </TableRow>
              </TableHeader>
              <TableBody>
                {!data.getAccountMembers.results &&
                data.variables.getAccountMembers.queryString ? (
                  <TableRow>
                    <p
                      style={{
                        color: '#000',
                        fontSize: 20,
                        fontFamily: 'Barlow Condensed',
                        fontWeight: 600,
                        paddingTop: 20,
                        marginLeft: 20,
                      }}
                    >
                      No Members with this name
                    </p>
                  </TableRow>
                ) : !data.getAccountMembers.results.length ? (
                  <TableRow>
                    <p
                      style={{
                        color: '#000',
                        fontSize: 20,
                        fontFamily: 'Barlow Condensed',
                        fontWeight: 600,
                        paddingTop: 20,
                        marginLeft: 20,
                      }}
                    >
                      No Members for this account yet
                    </p>
                  </TableRow>
                ) : null}
                {data.getAccountMembers.results.map((member, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: !member.is_signed
                        ? theme.colors.primaryOpacity(0.1)
                        : '',
                      color: !member.is_signed
                        ? theme.colors.text.gray
                        : 'black',
                    }}
                  >
                    <Td>
                      <Checkbox
                        aria-checked={!!member.approved}
                        checked={!!member.approved}
                        inversed
                        name="check_in"
                        onChange={() => {
                          setCurrentMember(member);
                          setUncheck(!!member.approved);
                          setShouldDisplayApproveModal(true);
                        }}
                        role="checkbox"
                        tabIndex={0}
                      />
                    </Td>
                    <Td>
                      <Link
                        to={
                          userType === 'admin'
                            ? `/admin/annualMembersDetail/member/${member.id}`
                            : `/admin-${userType}/annualMembersDetail/member/${member.id}`
                        }
                        style={{
                          color: theme.colors.primary,
                        }}
                      >
                        <LineHeightText>
                          {member.user.formatted_name}
                        </LineHeightText>
                      </Link>
                    </Td>
                    <Td>{member.user.email}</Td>
                    <Td>{formatPhoneNumber(member.user.cellphone)}</Td>
                    <Td>{member.serial_number}</Td>
                    <Td>{member.membership.name}</Td>
                    <Td>{member.divison}</Td>
                    <Td>
                      {!member.is_signed ? (
                        <WaiverNotSigned width={40} style={{ padding: 5 }} />
                      ) : (
                        'Signed'
                      )}
                    </Td>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Pagination
              count={count}
              perPage={15}
              currentPage={currentPage || 1}
            />
          </div>
        </Container>
        <ApproveModal
          itemType="Member"
          isVisible={shouldDisplayApproveModal}
          onConfirm={onConfirmApprove}
          setIsVisible={setShouldDisplayApproveModal}
          unCheck={unCheck}
        />
      </>
    );
  })
);

export default MembersList;
