import React from 'react';
import { useMe } from 'hooks/useMe';
import Page from './Page/index';

const Events = () => {
  const { data } = useMe();
  if (!data) return null;
  return (
    <div>
      <Page me={data.me} />
    </div>
  );
};

export default Events;
