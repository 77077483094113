import React from 'react';
import Icon from 'components/Icon';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import ExportEventsReport from '../ExportEventsReport';
import GetTrack from '../gql/queries/get-track';
import { PrintEventsReport } from '../PrintEventsReport';
import {
  Container,
  Column,
  ColumnIcon,
  ColumnText,
  TrackLogo,
} from './StyledHeader';

const TrackHeader = ({ data, margin, type }) => {
  const theme = useTheme();

  if (!data || !data.getTrack) return null;

  return (
    <Container margin={margin}>
      <TrackLogo>
        <img
          style={{ width: '100%', height: 'auto', display: 'block' }}
          src={data.getTrack ? data.getTrack.logo : ''}
          alt="logo"
        />
      </TrackLogo>
      <Column>
        <ColumnText>
          <Text fontSize={24} lineHeight={29} type="heading">
            {data.getTrack ? data.getTrack.name : ''}
          </Text>
          <Text fontSize={18} lineHeight={28}>
            {data.getTrack ? data.getTrack.cityAndState : ''}
          </Text>
        </ColumnText>
      </Column>
      <Column>
        <ColumnIcon>
          <Icon icon="location" size={20} color={theme.colors.text.black} />
        </ColumnIcon>
        <ColumnText>
          <Text fontSize={18} lineHeight={28}>
            {data.getTrack ? data.getTrack.street : ''}
          </Text>
          <Text fontSize={18} lineHeight={28}>
            {`${data.getTrack.city ? data.getTrack.city : null}, ${
              data.getTrack.state ? data.getTrack.state : null
            } ${data.getTrack.zipcode ? data.getTrack.zipcode : null}`}
          </Text>
        </ColumnText>
      </Column>
      <Column>
        <ColumnIcon>
          <Icon icon="flag" size={20} color={theme.colors.text.black} />
        </ColumnIcon>
        <ColumnText>
          <Text fontSize={18} lineHeight={28}>
            {data.getTrack && data.getTrack.type ? data.getTrack.type.key : ''}
          </Text>
          <Text fontSize={18} lineHeight={28}>
            {data.getTrack.size}
          </Text>
        </ColumnText>
      </Column>
      <Column noBorder>
        <ExportEventsReport
          icon={<Icon icon="Export-Icon" size={40} />}
          type={type}
          headerText={data.getTrack ? data.getTrack.name : ''}
        />
        <PrintEventsReport
          text={'Print List'}
          type={type}
          headerText={data.getTrack ? data.getTrack.name : ''}
        />
      </Column>
    </Container>
  );
};

export default GetTrack(TrackHeader);
