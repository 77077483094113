import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { usePersistedQuery } from 'hooks/usePersistedQuery';

export const GET_ACCOUNT_CREDITS_BREAKDOWN = gql`
  query GetAccountCreditsBreakdown($account_id: Int!) {
    getAccountCreditsBreakdown(account_id: $account_id) {
      year
      total
      open
      redeemed
    }
  }
`;

export function useGetAccountCreditsBreakdown() {
  const { id: account_id } = useParams();

  const result = usePersistedQuery(
    GET_ACCOUNT_CREDITS_BREAKDOWN,
    `account-credits-breakdown-${account_id}`,
    {
      variables: {
        account_id: Number(account_id),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, res: result.data };
}
