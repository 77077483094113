import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { IssueCreditModal } from '../issueCreditModal';
import { useGetAccountRedeemedCredits } from './gql/get-account-redeemed-credits';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const FilterContainer = styled.div`
  min-width: 150px;
`;

const RedeemedCredits = withEventYearFilterContext((props) => {
  const { theme, match } = props;

  const [redeemedCredits, setRedeemedCredits] = useState();
  const [countRedeemed, setCountRedeemed] = useState();
  const [search, setSearch] = useState('');

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const accountId = match.params.id;
  const { resRedeemed, loading } = useGetAccountRedeemedCredits();

  useEffect(() => {
    if (resRedeemed && resRedeemed.getAccountRedeemedCredits) {
      setRedeemedCredits(resRedeemed?.getAccountRedeemedCredits?.results);
      setCountRedeemed(resRedeemed?.getAccountRedeemedCredits?.count);
    }
  }, [resRedeemed]);

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  function renderRows(credit) {
    const { id, formatted_name } = credit;

    return {
      name: (
        <Link
          to={`/admin/redeemedCredits/${accountId}/creditDetail/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {formatted_name}</LineHeightText>
        </Link>
      ),
    };
  }

  return loading ? (
    <div />
  ) : (
    <>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Redeemed Credits
              </Text>
              <FilterContainer>
                <YearDropdown showLabel={false} />
              </FilterContainer>
              <div style={{ marginLeft: 15 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div>
            </TitleContainer>
            <CreditButton>
              <Button
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>
        <div style={{ padding: 2 }}>{props.children}</div>
        {!redeemedCredits ? (
          <div />
        ) : (
          <div style={{ padding: 20 }}>
            <Table
              items={redeemedCredits}
              columns={columns}
              renderRows={renderRows}
            />
            <Pagination
              count={countRedeemed}
              perPage={15}
              currentPage={currentPage || 1}
            />
          </div>
        )}
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
      />
    </>
  );
});

export default withTheme(RedeemedCredits);
