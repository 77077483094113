import React from 'react';
import { Col } from 'react-grid-system';
import styled from 'styled-components';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';

export const SectionHeader = styled.p`
  font-size: 18px;
  font-weight: 800;
`;

const AddressColumn = ({
  values,
  title,
  addressName,
  handleBlur,
  handleChange,
  errors,
}) => {
  return (
    <Col>
      <SectionHeader>{title}</SectionHeader>

      <Spacer size={20} />
      <Input
        id={`${addressName}.name`}
        name={`${addressName}.name`}
        label="Recepient"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={
          values[`${addressName}`]?.name ? values[`${addressName}`]?.name : ''
        }
        error={
          errors[`${addressName}.name`] ? errors[`${addressName}.name`] : ''
        }
      />

      <Spacer size={20} />
      <Input
        id={`${addressName}.address_line_1`}
        name={`${addressName}.address_line_1`}
        label="Address Line 1"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={
          values[`${addressName}`]?.address_line_1
            ? values[`${addressName}`]?.address_line_1
            : ''
        }
        error={
          errors[`${addressName}.address_line_1`]
            ? errors[`${addressName}.address_line_1`]
            : ''
        }
      />

      <Spacer size={20} />
      <Input
        id={`${addressName}.address_line_2`}
        name={`${addressName}.address_line_2`}
        label="Address Line 2"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={
          values[`${addressName}`]?.address_line_2
            ? values[`${addressName}`]?.address_line_2
            : ''
        }
        error={
          errors[`${addressName}.address_line_2`]
            ? errors[`${addressName}.address_line_2`]
            : ''
        }
      />

      <Spacer size={20} />
      <Input
        id={`${addressName}.city`}
        name={`${addressName}.city`}
        label="City"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={
          values[`${addressName}`]?.city ? values[`${addressName}`]?.city : ''
        }
        error={
          errors[`${addressName}.city`] ? errors[`${addressName}.city`] : ''
        }
      />

      <Spacer size={20} />
      <Input
        id={`${addressName}.state`}
        name={`${addressName}.state`}
        label="State"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={
          values[`${addressName}`]?.state ? values[`${addressName}`]?.state : ''
        }
        error={
          errors[`${addressName}.state`] ? errors[`${addressName}.state`] : ''
        }
      />

      <Spacer size={20} />
      <Input
        id={`${addressName}.zipcode`}
        name={`${addressName}.zipcode`}
        label="Zipcode"
        onChange={handleChange ? handleChange : ''}
        onBlur={handleBlur ? handleBlur : ''}
        value={
          values[`${addressName}`]?.zipcode
            ? values[`${addressName}`]?.zipcode
            : ''
        }
        error={
          errors[`${addressName}.zipcode`]
            ? errors[`${addressName}.zipcode`]
            : ''
        }
      />

      <Spacer size={20} />
    </Col>
  );
};

export default AddressColumn;
