import React, { useEffect } from 'react';
import InfosRepeater from 'pages/app/Infos/InfosRepeater';
import { Tabs } from 'pages/app/Users/UserEdit';
import { Tab } from 'pages/track/Employees/EmployeeEdit';
import { useGetTrackInfo } from '../gql/getTrackInfo';
import { ResponsiveCol, SectionTitle, Content, TabContent } from '../styles';
import { Infos } from './Infos';

export const GeneralInfo = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  props,
}) => {
  const [trackAndSeries, setTrackAndSeries] = React.useState([]);

  const { data: trackInfo, loading } = useGetTrackInfo(values.track_id?.value);

  useEffect(() => {
    if (trackInfo || values.series_ids) {
      // Add type to the infos in track and series
      let track = undefined;
      if (trackInfo && trackInfo.getTrack) {
        track = {
          ...trackInfo.getTrack,
          infos: trackInfo.getTrack.infos?.map((info) => ({
            ...info,
            type: 'track',
          })),
        };
      }

      let series = [];
      if (values.series_ids && values.series_ids.length > 0) {
        // Loop through the series ids and get the infos from values.series if they exist
        series = values.series_ids.map((series) => {
          const seriesInfo =
            values.series && values.series.length > 0
              ? values?.series?.find((serie) => serie.id === series.value)
              : undefined;

          if (seriesInfo && seriesInfo.infos.length > 0) {
            return {
              ...seriesInfo,
              infos: seriesInfo.infos.map((info) => ({
                ...info,
                type: 'series',
              })),
            };
          }
          return { id: series.value, infos: [], name: series.label };
        });
      }

      let newTracksAndSeries = [];

      if (track) {
        newTracksAndSeries = [track];
      }

      if (series.length > 0) {
        newTracksAndSeries = [...newTracksAndSeries, ...series];
      }

      setTrackAndSeries(newTracksAndSeries);
    }
  }, [trackInfo, loading, values.series, values.track_id, values.series_ids]);

  const [active, setActive] = React.useState(0);

  const tabsConfig =
    trackAndSeries &&
    trackAndSeries.length > 0 &&
    trackAndSeries?.map((value, index) => ({
      name: value.name,
      index,
    }));

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <ResponsiveCol style={{ flex: '0 0 100%' }}>
      {trackAndSeries.length > 0 && (
        <>
          <SectionTitle>Related Infos</SectionTitle>
          <TabContent>
            <div
              style={{
                backgroundColor: '#F4F4F4',
                margin: '10 0',
              }}
            >
              <Tabs style={{ backgroundColor: '#F4F4F4' }}>
                {tabsConfig.map((tab, index) => (
                  <Tab
                    onClick={(e) => handleClick(e)}
                    active={active === index}
                    id={index}
                    key={index}
                    style={{ width: '18%', backgroundColor: '#F4F4F4' }}
                  >
                    {tab.name}
                  </Tab>
                ))}
              </Tabs>

              {trackAndSeries &&
                trackAndSeries?.map((trackSeries, index) => (
                  <Content active={active === index}>
                    <Infos
                      trackSeries={trackSeries}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      trackAndSeries={trackAndSeries}
                      setTrackAndSeries={setTrackAndSeries}
                    />
                  </Content>
                ))}
            </div>
          </TabContent>
        </>
      )}

      <SectionTitle>General Info</SectionTitle>
      <InfosRepeater
        type="event"
        onChange={({ target, order }) => {
          const newInfos = values.infos.map((info) =>
            info.order === order
              ? {
                  ...info,
                  [target.name]: target.value,
                }
              : info
          );
          const newInfosWithOrder = values.infos_with_order.map((info) =>
            info.order === order
              ? {
                  ...info,
                  [target.name]: target.value,
                }
              : info
          );
          handleChange({
            target: {
              name: 'infos',
              value: newInfos,
            },
          });
          handleChange({
            target: {
              name: 'infos_with_order',
              value: newInfosWithOrder,
            },
          });
        }}
        onChangeInfo={(name, value) => {
          handleChange({
            target: {
              name: name,
              value,
            },
          });
        }}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
        touched={touched}
        infos={values.infos}
        props={props}
        handleSort={(name, value) => {
          handleChange({
            target: {
              name: name,
              value,
            },
          });
        }}
      />
    </ResponsiveCol>
  );
};
