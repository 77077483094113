import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const GET_TRACK_NOTIFICATIONS = gql`
  query GetTrackNotifications($input: GetNotificationsTrackInput!) {
    getTrackNotificationsV2(input: $input) {
      count
      results {
        id
        message
        title
        date
        series_id
        track_id
        unixTimestamp
        series
        track
        event
        list
        num_users
        type
        user {
          formatted_name
        }
      }
    }
  }
`;

export function useGetTrackNotifications(search, options = {}) {
  const {
    state: { accountId },
  } = useContext(AppContext);
  const variables = {
    input: {
      ...(search ? { queryString: search } : null),
      ...(accountId ? { account_id: accountId } : null),
    },
  };

  const result = usePersistedQuery(
    GET_TRACK_NOTIFICATIONS,
    `user-tracks-${accountId}`,
    { variables, fetchPolicy: 'cache-and-network', ...options }
  );

  return { ...result };
}
