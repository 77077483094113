import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Text from 'components/Text';
import Icon from 'components/Icon';

const Question = styled(Text)`
  margin-bottom: 1rem;
  white-space: normal;
`;

const List = styled.ul`
  list-style-position: outside;
  list-style-type: disc;
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: left;
`;

const Li = styled.li``;

const LiText = styled(Text)`
  white-space: normal;
`;

const IconWrapper = styled.div`
  display: none;

  @media (max-width: 859px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;

export const EventParticipantNoWaiver = () => {
  const history = useHistory();
  return (
    <>
      <IconWrapper>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            history.goBack();
          }}
        />
      </IconWrapper>
      <Question forwardedAs="h2" type="heading">
        Why can't I check in this Participant?
      </Question>
      <List>
        <Li>
          <LiText>
            This Participant has NOT signed the Waiver & Release Agreement
          </LiText>
        </Li>
      </List>
      <Question forwardedAs="h2" type="heading">
        How can the Participant resolve this?
      </Question>
      <List>
        <Li>
          <LiText>
            Adult Participants can find their pass in the My Passes section of
            their app, tap the orange "Sign Release Agreement" button on the
            pass, and sign the waiver.
          </LiText>
        </Li>
        <Li>
          <LiText>
            Minor Participants: The Parent(s)/Legal Guardian(s) for a Minor can
            find the Minor's pass in the My Passes section of the Parent's app
            and tap the orange "Sign Release Agreement" button to see what
            signatures are missing to validate the pass. For some events, BOTH
            of the Parents AND the Minor will have to sign the documents. Links
            to the required documents are sent to the Parents via text and
            email.
          </LiText>
        </Li>
      </List>
    </>
  );
};
