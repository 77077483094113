import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

export const GET_EVENT_CSV_REPORT = gql`
  query GetEventCSVReportOptions($event_id: Int!) {
    getEventCSVReportOptions(event_id: $event_id) {
      id
      name
      action
    }
  }
`;

export function useGetEventCSVReportOptions() {
  const { id } = useParams();
  return useQuery(GET_EVENT_CSV_REPORT, {
    variables: { event_id: Number(id) },
    fetchPolicy: 'no-cache',
  });
}
