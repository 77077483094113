import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { omit } from 'lodash';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const GET_ACCOUNT_OPEN_CREDITS_REPORT = gql`
  mutation GetAccountOpenCreditsReport($input: GetAccountCreditsInput!) {
    getAccountOpenCreditsReport(input: $input) {
      count
      results {
        id
        user {
          id
          formatted_name
        }
        amount
        date
        issued_by {
          formatted_name
        }
        track_credit
        fee_credit
        original_amount
      }
    }
  }
`;

export function useGetAccountOpenCreditsReport(yearFilter) {
  const { id: account_id } = useParams();
  const { input: search } = useSearchInput();

  const [accountOpenCreditReportMutation] = useMutation(
    GET_ACCOUNT_OPEN_CREDITS_REPORT
  );

  const data = useContext(AppContext);

  const result = useCallback(
    (options = {}) => {
      return accountOpenCreditReportMutation({
        variables: {
          input: {
            ...omit(search, ['accountName']),
            account_id: account_id
              ? Number(account_id)
              : Number(data?.state?.accountId),
            ...(yearFilter ? { year: yearFilter } : null),
          },
        },
        ...options,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountOpenCreditReportMutation, search, account_id, yearFilter]
  );
  return result;
}
