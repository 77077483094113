import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import {
  CardText,
  Card,
  CardLabel,
  CardContent,
  HideOnMobile,
  HideOnDesktop,
} from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import Loading from 'components/Loading';
import Pagination from 'components/Pagination';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import useTheme from 'hooks/useTheme';
import { AppContext } from 'shared/AppContext';
import { formatTimestamp } from 'shared/formatters';
import storage from 'shared/storage';
import { Heading } from '../Employees/style';
import PermissionModal from '../PermissionModal/PermissionModal';
import EditNotifications from './Edit';
import { useGetTrackNotifications } from './gql/queries/get-track-notifications';
import ViewNotification from './View';

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const SentHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
`;

const Notifications = ({ location, pathname, history }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const theme = useTheme();

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const { data, loading } = useGetTrackNotifications();

  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const handleOutClick = () => setIsVisible(!isVisible);

  const handleViewOutClick = () => {
    setCurrentNotification(null);
    setIsReading(!isReading);
  };

  const getTarget = (item) =>
    item.track
      ? 'App Favorites'
      : item.series
      ? 'App Favorites'
      : item.event
      ? 'Event Participants'
      : item.list
      ? 'Text List'
      : null;

  if (loading) return <Loading />;

  return (
    <Container>
      <div style={{ padding: 20 }}>
        <HideOnMobile>
          <ContainerHeader>
            <TitleContainer>
              <Heading>Messages</Heading>
              <Button
                onClick={
                  !capabilities || capabilities?.add_guests
                    ? () => setIsVisible(!isVisible)
                    : () => setShowPermissionModal(true)
                }
                buttonStyle={{ height: 35 }}
              >
                {'Send A Message'}
              </Button>
            </TitleContainer>
          </ContainerHeader>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col">Audience</TableCell>
                <TableCell scope="col">Track/Series</TableCell>
                <TableCell scope="col">Users Targeted</TableCell>
                <TableCell scope="col">Type</TableCell>
                <TableCell scope="col">Date/Time</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.getTrackNotificationsV2
                ? data.getTrackNotificationsV2.results.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <LineHeightText>{getTarget(item)}</LineHeightText>
                      </TableCell>
                      <TableCell>
                        <LineHeightText>
                          {item.track
                            ? item.track
                            : item.series
                            ? item.series
                            : ''}
                        </LineHeightText>
                      </TableCell>
                      <TableCell>
                        <LineHeightText>{item.num_users}</LineHeightText>
                      </TableCell>
                      <TableCell>
                        <LineHeightText>
                          {item.type
                            ? item.type === 'push'
                              ? 'App Push'
                              : item.type.charAt(0).toUpperCase() +
                                item.type.slice(1)
                            : ''}
                        </LineHeightText>
                      </TableCell>
                      <TableCell>
                        <LineHeightText>
                          {formatTimestamp(
                            item.unixTimestamp,
                            'MMM D - YYYY h:mm a'
                          ).toUpperCase()}
                        </LineHeightText>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Icon
                            icon="eye"
                            size={22}
                            color={theme.colors.primary}
                            onClick={async () => {
                              setIsReading(!isReading);
                              setCurrentNotification(item);
                            }}
                            padding="0 15px 0 0"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </HideOnMobile>
        <HideOnDesktop>
          <TitleContainer>
            <Button
              onClick={
                !capabilities || capabilities?.add_guests
                  ? () => setIsVisible(!isVisible)
                  : () => setShowPermissionModal(true)
              }
              buttonStyle={{ height: 35 }}
            >
              {'Send A Message'}
            </Button>
          </TitleContainer>
          <SentHeader>
            <Heading>Sent Messages</Heading>
          </SentHeader>
          {data.getTrackNotificationsV2
            ? data.getTrackNotificationsV2.results.map((item) => (
                <Card key={item.id}>
                  <CardText>
                    <CardLabel style={{ width: '35%' }}>From:</CardLabel>{' '}
                    <CardContent
                      style={{
                        width: '70%',
                        textAlign: 'left',
                        marginLeft: 5,
                      }}
                    >
                      {item.user ? item.user.formatted_name : ''}
                    </CardContent>
                  </CardText>
                  <CardText>
                    <CardLabel style={{ width: '35%' }}>Sent To:</CardLabel>{' '}
                    <CardContent
                      style={{
                        width: '70%',
                        textAlign: 'left',
                        marginLeft: 5,
                      }}
                    >
                      {getTarget(item)}
                    </CardContent>
                  </CardText>
                  <CardText>
                    <CardLabel style={{ width: '35%' }}>
                      {' '}
                      {item.List ? 'List' : 'Event:'}
                    </CardLabel>{' '}
                    <CardContent
                      style={{
                        width: '70%',
                        textAlign: 'left',
                        marginLeft: 5,
                      }}
                    >
                      {item.track
                        ? item.track
                        : item.series
                        ? item.series
                        : item.event
                        ? item.event
                        : item.list
                        ? item.list
                        : ''}
                    </CardContent>
                  </CardText>
                  <CardText>
                    <CardLabel style={{ width: '35%' }}>Sent:</CardLabel>{' '}
                    <CardContent
                      style={{
                        width: '70%',
                        textAlign: 'left',
                        marginLeft: 5,
                      }}
                    >
                      {formatTimestamp(
                        item.unixTimestamp,
                        'MMM DD-YYYY h:mm a'
                      ).toUpperCase()}
                    </CardContent>
                  </CardText>
                  <CardText>
                    <CardLabel style={{ width: '35%' }}>Msg Type:</CardLabel>{' '}
                    <CardContent
                      style={{
                        width: '70%',
                        textAlign: 'left',
                        marginLeft: 5,
                      }}
                    >
                      {item.type
                        ? item.type === 'push'
                          ? 'App Push'
                          : item.type.charAt(0).toUpperCase() +
                            item.type.slice(1)
                        : ''}
                    </CardContent>
                  </CardText>
                  <CardText>
                    <CardLabel style={{ width: '35%' }}>Recipients:</CardLabel>{' '}
                    <CardContent
                      style={{
                        width: '70%',
                        textAlign: 'left',
                        marginLeft: 5,
                      }}
                    >
                      {item.num_users}
                    </CardContent>
                  </CardText>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Heading>View Message</Heading>
                    <Icon
                      icon="eye"
                      size={24}
                      color={theme.colors.primary}
                      onClick={async () => {
                        setIsReading(!isReading);
                        setCurrentNotification(item);
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: '100%',
                      borderBottom: '1px solid #e6e6e6',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  ></div>
                </Card>
              ))
            : null}
        </HideOnDesktop>
      </div>

      <Pagination
        count={data?.getTrackNotificationsV2?.count}
        perPage={15}
        currentPage={currentPage || 1}
      />

      <EditNotifications
        isVisible={isVisible}
        location={location}
        seriesid={
          storage.get('user')
            ? storage.get('user').series
              ? storage.get('user').series.id
              : null
            : null
        }
        trackid={
          storage.get('user')
            ? storage.get('user').track
              ? storage.get('user').track.id
              : null
            : null
        }
        handleOutClick={handleOutClick}
      />

      <ViewNotification
        isVisible={isReading}
        handleOutClick={handleViewOutClick}
        notification={currentNotification}
        getTarget={getTarget}
      />

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </Container>
  );
};

export default Notifications;
