import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNT_REDEEMED_CREDITS = gql`
  query GetUserRedeemedCreditsByAccount($creditsInput: GetUserCreditsInput!) {
    getUserRedeemedCreditsByAccount(input: $creditsInput) {
      count
      results {
        id
        user {
          formatted_name
        }
        amount
        date
        issued_by {
          formatted_name
        }
      }
    }
  }
`;

export function useGetUserRedeemedCreditsByAccount() {
  const { id: account_id, id2: user_id } = useParams();

  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(
    GET_ACCOUNT_REDEEMED_CREDITS,
    `user-Redeemed-credits-by-account-${account_id}-${user_id}`,
    {
      variables: {
        creditsInput: {
          account_id: Number(account_id),
          user_id: Number(user_id),
          ...(yearFilter ? { year: yearFilter } : null),
          ...search,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, res: result.data };
}
