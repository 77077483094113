import qs from 'qs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import {
  TransactionModal,
  useGetTrackTransactionsGetEvent,
} from 'components/Transactions';
import { useGetTrackTransaction } from 'components/Transactions/gql/useGetPurchase';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import { formatTimestamp } from 'shared/formatters';
import { TransactionsListHeader as Header } from './header';

const OpenModal = styled.button`
  color: #fa4616;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const AutoSuggestContainer = styled.div`
  margin: 10px;
  min-width: 150px;
`;

const Transactions = withTransactionDateFilterContext((props) => {
  const { location, match } = props;
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const { data } = useGetTrackTransactionsGetEvent(sortBy?.value);
  const { purchase_id } = qs.parse(window.location.search.substring(1));

  const [fetch, setFetch] = useState(false);
  const { data: purchase } = useGetTrackTransaction(purchase_id, fetch);

  useEffect(() => {
    if (purchase_id && data?.getTrackTransactions?.results) {
      const transaction = purchase
        ? purchase.getTrackTransaction
        : data.getTrackTransactions.results.find(
            (transaction) => transaction.id === Number(purchase_id)
          );

      if (transaction) setCurrentTransaction(transaction);
      else {
        setFetch(true);
      }
    }
  }, [data, purchase_id, purchase]);

  if (!data?.getEvent && !data?.getTrackTransactions) return null;

  return (
    <>
      <SearchableListContainer
        header={<Header event={data} {...props} />}
        pageCount={data.getTrackTransactions.count}
        paginated
        searchInputPlaceholder="Search Transactions"
        inputMaxWidth="200px"
        title="Transactions"
        titleBarContent={
          <FilterContainer>
            <AutoSuggestContainer>
              <AutoSuggest
                placeholder="Order By"
                options={[
                  {
                    label: 'Last Name',
                    value: 'name',
                  },
                  {
                    label: 'Date',
                    value: 'date',
                  },
                ]}
                value={sortBy}
                isSearchable
                isClearable
                onChange={(value) => {
                  setFetch(true);
                  setSortBy(value ?? null);
                }}
              />
            </AutoSuggestContainer>

            <TransactionDateFilter />
          </FilterContainer>
        }
        searchCriteria={'Filter by Last Name'}
        endComponent={`Sales: ${data.getEvent.sales}`}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Date/Time
              </TableCell>
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Purchaser Name
              </TableCell>
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Order Total
              </TableCell>
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Discount
              </TableCell>
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Refund
              </TableCell>
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Credit
              </TableCell>
              {data?.getEvent?.hide_fee && (
                <TableCell scope="col" style={{ padding: '15px 4px' }}>
                  Fee
                </TableCell>
              )}
              <TableCell scope="col" style={{ padding: '15px 4px' }}>
                Transaction Total
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!data.getTrackTransactions.results.length &&
            data?.variables?.getTrackTransactionsInput.queryString ? (
              <p
                style={{
                  color: '#000',
                  fontSize: 20,
                  fontFamily: 'Barlow Condensed',
                  fontWeight: 600,
                  paddingTop: 20,
                }}
              >
                No transactions with this name
              </p>
            ) : !data.getTrackTransactions.results.length ? (
              <p
                style={{
                  color: '#000',
                  fontSize: 20,
                  fontFamily: 'Barlow Condensed',
                  fontWeight: 600,
                  paddingTop: 20,
                }}
              >
                No transactions for this event yet
              </p>
            ) : null}
            {data.getTrackTransactions.results.map((transaction) => (
              <TableRow key={0}>
                <TableCell style={{ padding: '15px 4px' }}>
                  {formatTimestamp(
                    Number(transaction.purchase_date),
                    'MMM DD - YYYY h:mm A',
                    true
                  ).toUpperCase()}
                </TableCell>
                <TableCell style={{ padding: '15px 4px' }}>
                  <LineHeightText>
                    <OpenModal
                      onClick={() => setCurrentTransaction(transaction)}
                    >
                      {transaction.user
                        ? `${transaction.user.last_name}, ${transaction.user.first_name}`
                        : ''}
                    </OpenModal>
                  </LineHeightText>
                </TableCell>
                <TableCell style={{ padding: '15px 4px' }}>
                  {transaction.subtotal ?? '$0.00'}
                </TableCell>
                <TableCell style={{ padding: '15px 4px' }}>
                  {transaction.discount ?? '$0.00'}
                </TableCell>
                <TableCell style={{ padding: '15px 4px' }}>
                  {transaction.refund ?? '$0.00'}
                </TableCell>
                <TableCell style={{ padding: '15px 4px' }}>
                  {transaction.credit ?? '$0.00'}
                </TableCell>
                {data?.getEvent?.hide_fee && (
                  <TableCell style={{ padding: '15px 4px' }}>
                    {transaction.fee ?? '$0.00'}
                  </TableCell>
                )}
                <TableCell style={{ padding: '15px 4px' }}>
                  {transaction.total ?? '$0.00'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SearchableListContainer>
      {currentTransaction ? (
        <TransactionModal
          admin={location.pathname.includes('/admin/transactions/')}
          match={match}
          currentTransaction={currentTransaction}
          close={() => {
            setCurrentTransaction(null);
          }}
        />
      ) : null}
    </>
  );
});

export default Transactions;
