import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';

export const query = gql`
  query SearchUsers($query: String!) {
    searchUsers(query: $query) {
      id
      first_name
      last_name
      cellphone
      country_code
      calling_code
      email
    }
  }
`;

export default graphql(query, {
  options: () => ({
    variables: {
      query: '',
    },
    fetchPolicy: 'no-cache',
  }),
});
