import { gql } from 'graphql-tag';
import qs from 'qs';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const query2 = gql`
  query GetPromosV2($input: GetPromosInputV2!) {
    getPromosV2(input: $input) {
      count
      results {
        id
        name
        expiration
        no_expiration
        ticket_discount
        service_discount
        is_expired
        issuer
        limit
      }
    }
  }
`;

export function GetPromosV2() {
  const {
    state: { accountId },
  } = useContext(AppContext);
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  return usePersistedQuery(query2, `get-promos-${accountId}`, {
    variables: {
      input: {
        ...(search ? search : null),
        ...(accountId ? { account_id: accountId } : null),
      },
    },
  });
}
