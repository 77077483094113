import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import { useGetInfo } from '../gql/useGetInfo';
import CreateInfo from './gql/CreateInfo';
import UpdateInfo from './gql/UpdateInfo';

const formValues = ['name', 'icon_id', 'icon'];

function getInitialValues(info, keys) {
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
  };
  const initialValues = !info
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return info[key]
            ? {
                ...formValues,
                [key]: info[key],
              }
            : formValues;
        }, {}),
      };
  return initialValues;
}

function getTrackInput({ data, currentInfo, action }) {
  const input = {
    ...data,
    ...(action === 'update' && currentInfo
      ? { id: Number(currentInfo) }
      : null),
  };

  formValues.forEach((key) => {
    if (input[key] === '' || input[key] === undefined) delete input[key];
  });

  delete input['icon'];

  return input;
}

const EditInfo = ({
  isVisible,
  handleOutClick,
  createInfo,
  currentInfo,
  updateInfo,
}) => {
  const successMessage = () =>
    toast.success(
      currentInfo ? 'General Info Updated' : 'General Info Created'
    );
  const errorMessage = (response) =>
    toast.error(
      currentInfo
        ? response.global
          ? 'Error Updating General Info'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating General Info'
    );

  const { data } = useGetInfo(currentInfo);

  if (currentInfo && !data?.getInfo) return null;

  return (
    <Drawer
      title={!currentInfo ? 'Create General Info' : 'Update General Info'}
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getInitialValues(data?.getInfo, formValues)}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }

          if (!values.icon_id) {
            errors.icon_id = 'Required';
          }

          if (Object.keys(errors).length) {
            toast.error('Please check your form for errors.');
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let response;
          setSubmitting(true);

          const data = Object.entries(values).reduce((acc, [key, value]) => {
            return {
              ...acc,
              [key]: value === '' ? null : value,
            };
          }, {});

          if (currentInfo) {
            response = await updateInfo(
              getTrackInput({ data, currentInfo, action: 'update' })
            );
          } else {
            response = await createInfo(
              getTrackInput({ data, currentInfo, action: 'create' })
            );
          }

          setSubmitting(false);

          if (!response || response.errors) {
            errorMessage(response);
            return setErrors(response.errors);
          }

          successMessage();
          resetForm();
          handleOutClick();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <DrawerPadding border>
              <Input
                id="name"
                label="General Info Name"
                placeholder="General Info"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name}
              />
              <Spacer size={18} />

              <Dropzone
                id="icon_id"
                files={
                  values.icon_id
                    ? [
                        {
                          name: values.icon_id,
                          preview: values.icon,
                        },
                      ]
                    : []
                }
                onChange={setFieldValue}
                setError={setFieldError}
                error={errors.icon_id && touched.icon_id && errors.icon_id}
                label="General Info Icon"
              />
            </DrawerPadding>

            <DrawerPadding>
              <Button type="submit" disabled={isSubmitting} block>
                {currentInfo ? 'Update General Info' : 'Add General Info'}
              </Button>
            </DrawerPadding>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default compose(CreateInfo, UpdateInfo)(EditInfo);
