import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';

export default graphql(
  gql`
    mutation AddMember($input: AddMemberInput!) {
      addMember(input: $input) {
        id
        email
        formatted_name
        role
        cellphone
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      addMember: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
          })
        );
      },
    }),
  }
);
