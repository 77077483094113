import React, { useState } from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Paragraph from 'components/Paragraph';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import { useGetAdminMetrics } from './gql/Reports.queries';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

export const Reports = () => {
  const [reportFilter, setReportFilter] = useState('track');

  const { data } = useGetAdminMetrics(reportFilter);

  if (!data?.getAdminMetricsV2) return null;
  const reports = data.getAdminMetricsV2.results ?? [];
  const pageCount = data.getAdminMetricsV2.count ?? 0;

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Pit Passes',
      key: 'passes',
    },
    {
      label: 'Registrations',
      key: 'registrations',
    },
    {
      label: 'Other Tickets',
      key: 'other_passes',
    },
    {
      label: 'Favorites',
      key: 'favorites',
    },
  ];

  function renderRows(report) {
    const { name, passes, registrations, other_passes, favorites } = report;
    return {
      name,
      passes,
      registrations,
      other_passes,
      favorites,
    };
  }

  return (
    <SearchableListContainer
      pageCount={pageCount}
      paginated
      title="Reports"
      noSearch
      titleBarContent={
        <FilterContainer>
          <AutoSuggest
            placeholder="Track"
            options={[
              {
                label: 'Track',
                value: 'track',
              },
              {
                label: 'Series',
                value: 'series',
              },
            ]}
            value={
              reportFilter
                ? {
                    label:
                      reportFilter.charAt(0).toUpperCase() +
                      reportFilter.slice(1),
                    key: reportFilter,
                  }
                : null
            }
            isSearchable
            onChange={(role) => {
              setReportFilter(role?.value ?? null);
            }}
          />
        </FilterContainer>
      }
    >
      <Table
        columns={columns}
        items={reports}
        noData={
          <Paragraph textAlign="center" lineHeight={100} fontSize={20}>
            No Reports.
          </Paragraph>
        }
        renderRows={renderRows}
      />
    </SearchableListContainer>
  );
};
