import React from 'react';
import {
  CardContentText,
  CardLabelText,
  CardText,
  ReplyMessage,
  CardLabelReply,
} from 'components/Card/cardStyle';
import Spacer from 'components/Spacer';

const TextListData = ({ listTarget }) => {
  if (!listTarget) return null;
  return (
    <>
      <Spacer size={25} />
      <CardText>
        <CardLabelText style={{ width: '35%' }}>Text Word </CardLabelText>{' '}
        <CardContentText
          style={{
            width: '70%',
            textAlign: 'left',
            marginLeft: 5,
          }}
        >
          {listTarget.label}
        </CardContentText>
      </CardText>
      <CardText>
        <CardLabelText style={{ width: '35%' }}>Subscribers </CardLabelText>{' '}
        <CardContentText
          style={{
            width: '70%',
            textAlign: 'left',
            marginLeft: 5,
          }}
        >
          {listTarget.recipientCount}
        </CardContentText>
      </CardText>
      <CardText>
        <CardLabelReply style={{ width: '90%' }}>
          Auto Reply Message{' '}
        </CardLabelReply>{' '}
      </CardText>
      <ReplyMessage
        style={{
          border: '1px solid #ccc',
          padding: 20,
          borderRadius: 16,
          width: '90%',
          textAlign: 'left',
          margin: 'auto',
          color: '#fa4616',
        }}
      >
        {listTarget.replyMessage}
      </ReplyMessage>
      <Spacer size={10} />
      <CardText>
        <CardLabelText style={{ width: '35%' }}>Text </CardLabelText>{' '}
        <CardContentText
          style={{
            width: '70%',
            textAlign: 'left',
            marginLeft: 5,
          }}
        >
          {listTarget.code.toUpperCase()}
        </CardContentText>
      </CardText>
      <CardText>
        <CardLabelText style={{ width: '35%' }}>Text To # </CardLabelText>{' '}
        <CardContentText
          style={{
            width: '70%',
            textAlign: 'left',
            marginLeft: 5,
          }}
        >
          {process.env.REACT_APP_TEXT_TO_NUMBER}
        </CardContentText>
      </CardText>
    </>
  );
};

export default TextListData;
