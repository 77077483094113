import React from 'react';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';
import { useGetExportCustomers } from './gql/queries/get-export-customers';

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 40px 0 40px;
  padding: 0;
  width: calc(100% - 80px);

  &:focus {
    outline: none;
  }

  @media (max-width: 859px) {
    display: none;
  }
`;

const IconWrapper = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  span {
    color: #3c4144;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    margin-top: 10px;
  }

  svg {
    fill: #fa4616;
  }
`;

const Export = ({ icon }) => {
  const getCustomers = useGetExportCustomers();

  const handleExport = async () => {
    const result = await getCustomers();

    if (result?.data?.getCustomerExport) {
      toast.success(
        `Request Successful! You will receive the report attached to an email once we have finished compiling the data.`
      );
    } else {
      toast.error(`Failed to export customers`);
    }
  };

  return (
    <Container>
      <IconWrapper onClick={handleExport}>
        {icon}
        <span>Export Customers</span>
      </IconWrapper>
    </Container>
  );
};

export default withTheme(Export);
