import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router';

export const GET_MEMBER = gql`
  query GetMember($pass_id: Int!) {
    getMember(pass_id: $pass_id) {
      id
      user {
        id
        name
        formatted_name
        first_name
        middle_name
        last_name
        email
        role
        cellphone
        country_code
        calling_code
        address_1
        address_2
        state
        birthday
        city
        state
        zipcode
        address_line_1
        suffix
      }
      serial_number
      divison
      approved
      membership {
        id
        name
      }
      forms {
        id
        name
        form_id
        data {
          name
          value
        }
      }
      waivers {
        id
        name
        is_signed
      }
      purchase {
        id
        purchase_date
      }
      section
      row
      seat
    }
  }
`;

export function useGetMember() {
  const { id } = useParams();

  return useQuery(GET_MEMBER, {
    variables: {
      pass_id: Number(id),
    },
    skip: !id,
  });
}
