import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { PrintButton } from 'components/PrintButton';
import colors from 'components/ThemeProvider/colors';
import { useTransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import useTheme from 'hooks/useTheme';
import { TicketHossLogo } from 'layouts/AdminLayout/StyledAdminLayout';
import { KartPassLogo } from 'pages/app/Transactions/TransactionsPrintList/kartpass-logo';
import { Logo } from 'pages/app/Transactions/TransactionsPrintList/logo';
import { timeout } from 'shared/timeout';
import { useGetAccountMembersReport } from './gql/useGetAccountMembersReport.mutation';

export const PrintAccountMembersReport = ({
  text,
  color,
  accountId,
  year,
  typeId,
  division,
  search,
  accountName,
  ticketName,
  type,
}) => {
  const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : pathname?.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';
  const isStaging = window.location.href.includes('dashboard-staging');

  const getAccountMembersReport = useGetAccountMembersReport();
  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();

  const { startDateFilter, endDateFilter } = useTransactionDateFilter();

  const dateRange =
    startDateFilter && endDateFilter
      ? `${moment(startDateFilter).format('MMM DD')} - ${moment(
          endDateFilter
        ).format('DD')} - ${moment(endDateFilter).format('YYYY')}`
      : 'All Users';

  let filename = '';

  const getData = async () => {
    try {
      const response = await getAccountMembersReport(
        accountId,
        year,
        typeId,
        division,
        search,
        type
      );
      let title = '';
      const accountMembersReport = response.data.getAccountMembersReport;

      title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      filename = 'Annual Purchase List - ' + title;
      return { accountMembersReport, title, filename };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      toast.error('Error generating report');
      setDisabled(false);
    }
  };

  function generatePdf() {
    setDisabled(true);
    return new Promise(async (resolve) => {
      const pdf = await exportPDF();

      setDisabled(false);
      if (filename) {
        pdf.setProperties({
          title: filename,
        });
      }
      if (
        navigator.userAgent.includes('iPhone') &&
        navigator.maxTouchPoints > 1
      ) {
        var blob = new Blob([pdf.output('blob')], {
          type: 'data:application/pdf,',
        });
        FileSaver.saveAs(blob, filename + '.pdf');
      } else if (navigator.maxTouchPoints > 1) {
        pdf.output('datauri', filename + '.pdf');
      } else {
        window.open(
          pdf.output('bloburl', { filename: filename + '.pdf' }),
          '_blank'
        );
      }
      timeout(resolve, 6000);
    });
  }

  //This will generate the spectator PDF report
  const exportPDF = async () => {
    const { accountMembersReport, filename } = await getData();
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    doc.text(ticketName ?? 'All Users', 40, 40);
    doc.text(accountName, 40, 60);

    if (isKartPass) {
      doc.addImage(KartPassLogo, 'PNG', 650, 50, 100, 75, '', 'FAST');
    } else if (isTicketHoss) {
      doc.addImage(TicketHossLogo, 'PNG', 660, 65, 100, 54, '', 'FAST');
    } else {
      doc.addImage(Logo, 'PNG', 660, 50, 100, 100, '', 'FAST');
    }

    const headers = [
      [
        'NAME',
        'EMAIL',
        'PHONE',
        'AGE',
        'ANNUAL ID',
        'ANNUAL NAME',
        'PURCHASE DATE',
        'RESERVED',
        'PRICE',
      ],
    ];

    const tableData = accountMembersReport.results.map((member) => {
      const {
        user,
        membership,
        serial_number,
        purchase,
        section,
        row,
        seat,
        cost,
      } = member;

      return [
        user.formatted_name,
        user.email,
        user.cellphone,
        user.birthday
          ? moment(user.birthday, 'MM/DD/YYYY')
              .month(0)
              .from(moment().month(0))
              .replace('years ago', '')
          : '',
        serial_number,
        membership.name,
        moment(purchase.purchase_date).format('MMM DD - YYYY h:mm A'),
        `${section ? section : ''}${section && (row || seat) ? ',' : ''}${
          row ? row : ''
        }${row && seat ? ',' : ''}${seat ? seat : ''}`,
        cost,
      ];
    });

    doc.autoTable({
      startY: 160,
      rowPageBreak: 'avoid',
      columns: [
        { dataKey: 'Name', header: 'NAME' },
        { dataKey: 'Email', header: 'EMAIL' },
        { dataKey: 'Phone', header: 'PHONE' },
        { dataKey: 'Age', header: 'AGE' },
        { dataKey: 'Annual #', header: 'ANNUAL ID' },
        { dataKey: 'Annual Type', header: 'ANNUAL NAME' },
        { dataKey: 'Purchase Date', header: 'PURCHASE DATE' },
        { dataKey: 'Reserved', header: 'RESERVED' },
        { dataKey: 'Price', header: 'PRICE' },
      ],
      head: headers,
      body: tableData,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: '#fa4616',
      },
      columnStyles: {
        Name: {
          cellWidth: 180,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 20 },
          textColor: '#fa4616',
        },
        'Annual Name': {
          cellWidth: 180,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 20 },
          textColor: theme.colors.text.black,
        },
        email: {
          cellWidth: 180,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 20 },
          textColor: theme.colors.text.black,
        },
      },
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 0) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/annualPurchases/purchase/${
            accountMembersReport.results[rowIndex]?.membership?.id
          }?purchase_id=${
            accountMembersReport.results[rowIndex]?.purchase?.id
          }&ticketName=${accountMembersReport.results[
            rowIndex
          ]?.membership?.name.replace(' ', '%20')}`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
      didParseCell: function (tableData) {
        const hasNoWaiver = tableData.row.raw[6] === false;

        if (hasNoWaiver) {
          tableData.cell.styles.fillColor = '#feece8';
          tableData.cell.styles.textColor = colors.text.gray;
          tableData.cell.styles.opacity = 0.1;
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      //Display date range at the bottom left of the page
      doc.text(`${dateRange}`, 40, pageHeight - 20, {
        align: 'left',
      });

      const dateTimeStamp = moment()
        .format('MMM DD - YYYY h:mm A')
        .toUpperCase();
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: filename,
      subject: 'Annual Member List',
      author: 'Pit Pay',
    });
    return doc;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <PrintButton
        buildPdf={generatePdf}
        text={text}
        variant="minimal"
        disabled={disabled}
        color={color}
      />
    </div>
  );
};
