import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import qs from 'qs';

export const query = gql`
  query GetAccounts($input: GetAccountsInput!) {
    getAccounts(input: $input) {
      count
      results {
        id
        legal_name
        business_dba
        primary_contact
        email
        title
        country_code
        calling_code
        phone
        mailing {
          name
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        track {
          name
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        shipping {
          name
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        open_credits {
          credit_total
          num_credits
        }
        members {
          id
          name
          email
          cellphone
        }
        track_ids
        series_ids
        stripe_account_id
        stripe_url
      }
    }
  }
`;

export default graphql(query, {
  options: (props) => {
    const search = qs.parse(props?.location?.search, {
      ignoreQueryPrefix: true,
    });
    return {
      variables: {
        input: { ...search },
      },
    };
  },
});
