import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { withTheme } from 'styled-components';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { IssueCreditModal } from 'pages/app/Credits/issueCreditModal';
import { IssueButton } from 'pages/track/CreditTrackMobile/TrackOpenCreditMobile/TrackOpenCreditMobile';
import MobileSearchInput from '../../../../employee/Events/search';
import { useGetAccountOpenCredits } from '../../OpenCredits/gql/get-account-open-credits';
import CreditList from '../CreditsList/CreditList';

const OpenCreditsMobile = withEventYearFilterContext((props) => {
  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const { id: account_id } = useParams();

  const [users, setUsers] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');
  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const { res } = useGetAccountOpenCredits();

  useEffect(() => {
    if (res && res.getAccountOpenCredits) {
      setUsers(res?.getAccountOpenCredits?.results);
      setCount(res?.getAccountOpenCredits?.count);
    }
  }, [res]);

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  if (!users) return false;

  return (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <MobileSearchInput
          placeholder="Search Credits"
          onChange={handleChange}
          value={search}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
        />
        <Spacer size={10} />

        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <IssueButton onClick={() => setShowIssueCredit(true)}>
            {'Issue Credit'}
          </IssueButton>
        </div>

        <Spacer size={10} />
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 'auto',
          }}
        >
          <YearDropdown showLabel={false} />
        </div>

        <Spacer size={10} />
        <CreditList
          search={search}
          users={users}
          url={`/admin/openCredits/${account_id}/creditDetail`}
          currentPage={currentPage}
        />
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
      />
    </>
  );
});

export default withTheme(OpenCreditsMobile);
