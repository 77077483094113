import { gql } from 'graphql-tag';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ANNUAL_TICKETS = gql`
  query GetAnnualTickets($getAnnualTicketsInput: GetAnnualTicketsInput!) {
    getAnnualTickets(input: $getAnnualTicketsInput) {
      count
      results {
        id
        series {
          id
          name
        }
        tracks {
          id
          name
        }
        account {
          id
          business_dba
        }
        ticket {
          name
          sale_end
        }
        name
        status
        events {
          id
          name
        }
      }
    }
  }
`;

export function useGetAnnualTickets(
  accountFilter,
  isPurchase,
  userType,
  annualType,
  annualEvent,
  purchaseYear
) {
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(
    GET_ANNUAL_TICKETS,
    `annual-tickets-${accountFilter}-${search.queryString}-${
      isPurchase ? isPurchase : null
    }-${annualType ? annualType : null}`,
    {
      variables: {
        getAnnualTicketsInput: {
          ...search,
          ...(accountFilter ? { accountFilter } : null),
          ...(isPurchase ? { isPurchase } : null),
          ...(annualType ? { annualType: annualType.value } : null),
          ...(annualEvent ? { event_id: annualEvent } : null),
          ...(purchaseYear ? { year: purchaseYear } : null),
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: userType === 'track' ? !accountFilter : false,
    }
  );

  return { ...result, res: result.data };
}
