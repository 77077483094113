import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-users';

export default graphql(
  gql`
    mutation CreateUser($input: CreateUserInput!) {
      createUser(input: $input) {
        id
        first_name
        middle_name
        address_1
        address_2
        city
        state
        zipcode
        last_name
        email
        role
        cellphone
        associations
        suffix
        parents {
          first_name
          last_name
          cellphone
          user_id
        }
        inactive
      }
    }
  `,

  {
    props: ({ mutate }) => ({
      createUser: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createUser } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const { role } = qs.parse(window.location.search.substring(1));

              const { queryString, page } = search;

              const data2 = proxy.readQuery({
                query,
                variables: { input: { queryString, page, role: null } },
              });

              proxy.writeQuery({
                query,
                variables: { input: { queryString, page, role: null } },
                data: {
                  ...data2,
                  getUsers: {
                    ...data2.getUsers,
                    count: data2.getUsers.count + 1,
                    results: data2.getUsers.results.concat(createUser),
                  },
                },
              });

              if (role) {
                try {
                  const data = proxy.readQuery({
                    query,
                    variables: { input: { queryString, page, role: role } },
                  });

                  proxy.writeQuery({
                    query,
                    variables: { input: { queryString, page, role: role } },
                    data: {
                      ...data,
                      getUsers: {
                        ...data.getUsers,
                        count: data.getUsers.count + 1,
                        results: data.getUsers.results.concat({
                          ...createUser,
                          associations: [],
                        }),
                      },
                    },
                  });
                } catch (e) {}
              }
            },
          })
        );
      },
    }),
  }
);
