import { gql } from 'graphql-tag';

export const MemberValuesFragment = gql`
  fragment memberValues on Member {
    id
    user {
      id
      formatted_name
      email
      cellphone
    }
    divison
    membership {
      id
      name
    }
    serial_number
    is_signed
    approved
  }
`;
