import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-users';

export default graphql(
  gql`
    mutation DeleteUser($id: Int!) {
      deleteUser(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteUser: async (id, role) => {
        return mutator(() =>
          mutate({
            variables: { id },
            update: (proxy, { data: { deleteUser } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });
              const { queryString, page } = search;

              if (!deleteUser) {
                return;
              }

              const data2 = proxy.readQuery({
                query,
                variables: { input: { queryString, page, role: null } },
              });

              proxy.writeQuery({
                query,
                variables: { input: { queryString, page, role: null } },
                data: {
                  ...data2,
                  getUsers: {
                    ...data2.getUsers,
                    count: data2.getUsers.count - 1,
                    results: data2.getUsers.results.filter(
                      (user) => user.id !== id
                    ),
                  },
                },
              });

              if (role) {
                try {
                  const data = proxy.readQuery({
                    query,
                    variables: { input: { queryString, page, role } },
                  });

                  proxy.writeQuery({
                    query,
                    variables: { input: { queryString, page, role } },
                    data: {
                      ...data,
                      getUsers: {
                        ...data.getUsers,
                        count: data.getUsers.count - 1,
                        results: data.getUsers.results.filter(
                          (user) => user.id !== id
                        ),
                      },
                    },
                  });
                } catch (e) {}
              }
            },
          })
        );
      },
    }),
  }
);
