import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { PrintParticipants } from 'components/Participants/PrintSpectators';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import Export from './export';

const ButtonAction = styled.button`
  padding: 0;
  margin: 0 10px 0 10px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const HeaderButtons = ({ event, date, match, setShowCancelModal }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const {
    state: { capabilities },
  } = useContext(AppContext);

  const userType = pathname.split('/').includes('admin-employee')
    ? 'admin-employee'
    : pathname.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';

  const handleClickScan = (id, date) => {
    history.push(`/${userType}/scan/${id}?date=${date}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        buttonStyle={{ width: 165, marginRight: 10 }}
        onClick={
          !capabilities ||
          capabilities.length === 0 ||
          capabilities?.scan_tickets
            ? () => handleClickScan(event.id, date)
            : () => setShowPermissionModal(true)
        }
      >
        {process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'SCAN PARTICIPANTS'
          : 'SCAN TICKETS'}
      </Button>

      <Export event={event} icon={<Icon icon="Export-Icon" size={40} />} />
      <PrintParticipants eventId={match.params.id} text="Print List" />
      {'published' === event.status ? (
        <ButtonAction
          onClick={() => {
            setShowCancelModal(true);
          }}
        >
          <Icon icon="cancel-event" size={40} color={theme.colors.primary} />
          <Text
            type="label"
            fontSize={12}
            color={theme.colors.secondary}
            style={{
              marginTop: 10,
              textAlign: 'center',
              fontWeight: 700,
              lineHeight: '16px',
            }}
          >
            Cancel / Postpone
          </Text>
        </ButtonAction>
      ) : null}
      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </div>
  );
};

export default HeaderButtons;
