import React from 'react';
import { Checkbox } from 'components/Form/Checkbox';
import { getHighestItemOrderNumber } from 'components/Form/DraggableFields';

export const InfoItem = ({ values, info, handleChange, handleBlur }) => {
  const checked = values.infos?.find((i) => i.id === info.id);

  return (
    <Checkbox
      key={info.id}
      name={info.info.name}
      checked={checked}
      onChange={(event) => {
        const value = event.target?.checked;

        // Add the info to values.info if checked
        const newInfos = value
          ? [
              ...values.infos,
              {
                ...info,
                info: {
                  value: info.info.id,
                  label: info.info.name,
                },
                order: getHighestItemOrderNumber(values.infos) + 1,
              },
            ]
          : values.infos?.filter((i) => i.id !== info.id);

        const newInfosWithOrder = value
          ? [
              ...values.infos_with_order,
              {
                ...info,
                info: {
                  value: info.info.id,
                  label: info.info.name,
                },
                order: getHighestItemOrderNumber(values.infos) + 1,
              },
            ]
          : values.infos_with_order?.filter((i) => i.id !== info.id);

        handleChange({
          target: {
            name: 'infos',
            value: newInfos,
          },
        });

        handleChange({
          target: {
            name: 'infos_with_order',
            value: newInfosWithOrder,
          },
        });
      }}
      onBlur={handleBlur}
      rightText={info.info.name}
      myStyle={{ margin: '10px 0' }}
    />
  );
};
