import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CREATE_TRANSACTION_CREDIT = gql`
  mutation CreateTransactionCredit(
    $createTransactionCreditInput: CreateTransactionCreditInput!
  ) {
    createTransactionCredit(input: $createTransactionCreditInput) {
      id
    }
  }
`;

export function useCreateTransactionCredit() {
  const [createTransactionCreditMutation] = useMutation(
    CREATE_TRANSACTION_CREDIT
  );

  const refundPasses = useCallback(
    (transaction_id, pass_ids, credit_amount, include_fees, message) => {
      return createTransactionCreditMutation({
        variables: {
          createTransactionCreditInput: {
            transaction_id: transaction_id,
            pass_ids: [...pass_ids],
            credit_amount: credit_amount,
            include_fees: include_fees,
            message: message,
          },
        },
      });
    },
    [createTransactionCreditMutation]
  );
  return refundPasses;
}
