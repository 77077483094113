import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-promos';
import { query2 } from '../queries/get-promos-v2';

export default graphql(
  gql`
    mutation CreatePromoV2($input: CreatePromoInput!) {
      createPromoV2(input: $input) {
        id
        name
        expiration
        no_expiration
        ticket_discount
        service_discount
        is_expired
        issuer
        limit
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createPromo: async (input, isAdmin, account_id) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createPromoV2 } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = isAdmin
                ? proxy.readQuery({
                    query,
                    variables: { input: search },
                  })
                : proxy.readQuery({
                    query: query2,
                    variables: {
                      input: {
                        ...(Object.keys(search).length !== 0
                          ? { ...search }
                          : null),
                        account_id,
                      },
                    },
                  });

              isAdmin
                ? proxy.writeQuery({
                    query,
                    variables: { input: search },
                    data: {
                      ...data,
                      getPromos: {
                        ...data.getPromos,
                        count: data.getPromos.count + 1,
                        results: [createPromoV2, ...data.getPromos.results],
                      },
                    },
                  })
                : proxy.writeQuery({
                    query: query2,
                    variables: {
                      input: {
                        ...(Object.keys(search).length !== 0
                          ? { ...search }
                          : null),
                        account_id,
                      },
                    },
                    data: {
                      ...data,
                      getPromosV2: {
                        ...data.getPromosV2,
                        count: data.getPromosV2.count + 1,
                        results: [createPromoV2, ...data.getPromosV2.results],
                      },
                    },
                  });
            },
          })
        );
      },
    }),
  }
);
