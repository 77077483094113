import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { PrintButton } from 'components/PrintButton';
import { useTransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import { KartPassLogo } from 'pages/app/Transactions/TransactionsPrintList/kartpass-logo';
import { Logo } from 'pages/app/Transactions/TransactionsPrintList/logo';
import { TicketHossLogo } from 'pages/app/Transactions/TransactionsPrintList/tickethoss-logo';
import { timeout } from 'shared/timeout';
import { useGetAnnualPurchaseReport } from './gql/useGetAnnualPurchaseReport.mutation';

const getHeaders = () => {
  return [
    [
      'Date/Time',
      'Purchaser',
      'Order Total',
      'Discount',
      'Refund',
      'Credit',
      'Absorbed Fees',
      'Transaction Total',
    ],
  ];
};

const getColumns = () => {
  return [
    { dataKey: 'date', header: 'Date/Time' },
    { dataKey: 'purchaser', header: 'Purchaser' },
    { dataKey: 'subtotal', header: 'Order Total' },
    { dataKey: 'discount', header: 'Discount' },
    { dataKey: 'refund', header: 'Refund' },
    { dataKey: 'credit', header: 'Credit' },
    { dataKey: 'fee', header: 'Absorbed Fees' },
    { dataKey: 'total', header: 'Transaction Total' },
  ];
};

const buildData = (transactions) => {
  return (
    transactions?.length > 0 &&
    transactions.map((transaction) => [
      transaction.purchase_date,
      transaction.purchaser,
      transaction.subtotal,
      transaction.discount,
      transaction.refund ? `${transaction.refund}` : '',
      transaction.credit,
      transaction.fee,
      transaction.total,
    ])
  );
};

export const PrintAnnualPurchaseReport = ({
  text,
  color,
  annualTicketName,
  accountName,
  ticket_id,
  account_id,
}) => {
  const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const getAnnualPurchaseReport = useGetAnnualPurchaseReport(account_id);
  const [disabled, setDisabled] = useState(false);

  const { startDateFilter, endDateFilter } = useTransactionDateFilter();

  const dateRange =
    startDateFilter && endDateFilter
      ? `${moment(startDateFilter).format('MMM DD')} - ${moment(
          endDateFilter
        ).format('DD')} - ${moment(endDateFilter).format('YYYY')}`
      : 'All Transactions';

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : pathname?.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';
  const isStaging = window.location.href.includes('dashboard-staging');

  let filename = '';

  const getData = async () => {
    try {
      const response = await getAnnualPurchaseReport();
      let title = '';
      const annualPurchaseReport = response.data.getAnnualPurchaseListV2;

      if (startDateFilter && endDateFilter)
        title = `${moment(startDateFilter)
          .format('MMM DD')
          .toUpperCase()} - ${moment(endDateFilter)
          .format('MMM DD - YYYY')
          .toUpperCase()}`;
      else title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      filename = 'Annual Purchase List - ' + title;
      return { annualPurchaseReport, title, filename };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      toast.error('Error generating report');
      setDisabled(false);
    }
  };

  function generatePdf() {
    setDisabled(true);
    return new Promise(async (resolve) => {
      const pdf = await exportPDF();
      if (pdf.fileName || filename) {
        pdf.setProperties({
          title: pdf.fileName ?? filename,
        });
      }
      if (
        navigator.userAgent.includes('iPhone') &&
        navigator.maxTouchPoints > 1
      ) {
        var blob = new Blob([pdf.output('blob')], {
          type: 'data:application/pdf,',
        });
        FileSaver.saveAs(blob, pdf.fileName ?? filename + '.pdf');
      } else if (navigator.maxTouchPoints > 1) {
        pdf.output('datauri', pdf.fileName ?? filename + '.pdf');
      } else {
        // Generate the PDF Blob
        const blob = pdf.output('blob');

        // Create a Blob URL for the PDF
        const blobUrl = URL.createObjectURL(blob);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = pdf.fileName ?? filename + '.pdf'; // Set the desired filename

        // Trigger a click event on the download link
        downloadLink.click();
      }
      timeout(resolve, 6000);
    });
  }

  //This will generate the spectator PDF report
  const exportPDF = async () => {
    const { annualPurchaseReport, filename } = await getData();

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    doc.text(annualPurchaseReport.label, 40, 60);
    doc.text(accountName, 40, 80);
    doc.text(`Total Sales ${annualPurchaseReport.gross_sales}`, 40, 100);

    const extra = 30;
    let Y = 120;

    if (annualPurchaseReport.marketing_fund) {
      doc.text(`1% Cash Back ${annualPurchaseReport.marketing_fund}`, 40, Y);
    }
    Y += 60;

    if (isKartPass) {
      doc.addImage(KartPassLogo, 'PNG', 650, 50, 120, 75, '', 'FAST');
    } else if (isTicketHoss) {
      doc.addImage(TicketHossLogo, 'PNG', 660, 65, 120, 54, '', 'FAST');
    } else {
      doc.addImage(Logo, 'PNG', 660, 50, 120, 100, '', 'FAST');
    }

    const headers = getHeaders();

    const data1 = buildData(annualPurchaseReport?.data[1]?.data);

    doc.setTextColor('#fa4616');

    doc.setTextColor('#000');

    doc.text(
      `Credits Redeemed - ${annualPurchaseReport?.data[1]?.total}`,
      40,
      Y
    );
    Y += 10;

    doc.autoTable({
      startY: Y,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data1,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/annualPurchases/purchase/${ticket_id}?ticketName=${annualTicketName}&purchase_id=${
            annualPurchaseReport.data[1].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data2 = buildData(annualPurchaseReport?.data[2]?.data);

    doc.text(
      `Credits Issued - ${annualPurchaseReport?.data[2]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 10;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data2,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/annualPurchases/purchase/${ticket_id}?ticketName=${annualTicketName}&purchase_id=${
            annualPurchaseReport.data[2].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data3 = buildData(annualPurchaseReport?.data[3]?.data);

    let availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Discounts - ${annualPurchaseReport?.data[3]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data3,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/annualPurchases/purchase/${ticket_id}?ticketName=${annualTicketName}&purchase_id=${
            annualPurchaseReport.data[3].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data4 = buildData(annualPurchaseReport?.data[4]?.data);

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Refunds Issued - ${annualPurchaseReport?.data[4]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data4,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/annualPurchases/purchase/${ticket_id}?ticketName=${annualTicketName}&purchase_id=${
            annualPurchaseReport.data[4].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data5 = buildData(annualPurchaseReport?.data[0]?.data);

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `All Transactions - ${annualPurchaseReport?.data[0]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data5,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/annualPurchases/purchase/${ticket_id}?ticketName=${annualTicketName}&purchase_id=${
            annualPurchaseReport.data[0].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      //Display date range at the bottom left of the page
      doc.text(`${dateRange}`, 40, pageHeight - 20, {
        align: 'left',
      });

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: `${filename}.pdf`,
      subject: 'Annual Purchase Report',
      author: process.env.REACT_APP_PLATFORM,
    });

    return {
      ...doc,
      fileName: `${moment()
        .format('MMM DD - YYYY')
        .toUpperCase()} - ${annualTicketName} - ANNUAL PURCHASE REPORT.pdf`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <PrintButton
        buildPdf={generatePdf}
        text={text}
        variant="minimal"
        disabled={disabled}
        color={color}
      />
    </div>
  );
};
