import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { withTheme } from 'styled-components';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { ConfirmModal } from 'components/Modal';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import { withEventYearFilterContext } from 'components/YearDropdown';
import deleteCredit from 'pages/app/Credits/OpenCredits/gql/delete-credit';
import { useGetUserOpenCreditsByAccount } from 'pages/track/CreditTrack/TrackOpenCredit/gql/get-user-open-credits-by-account';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { logDevError } from 'shared/alerts';
import { AppContext } from 'shared/AppContext';
import CreditDetail from '../CreditsList/CreditDetail';
import UserCreditList from '../CreditsList/UserCreditList';

const TrackOpenCreditDetailMobile = withEventYearFilterContext((props) => {
  const { location, deleteCredit } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [credits, setCredits] = useState();
  const [count, setCount] = useState();

  const [detailIndex, setDetailIndex] = useState();

  const { id } = useParams();
  const userId = parseInt(id);
  const {
    state: { accountId, capabilities },
  } = useContext(AppContext);
  const [shouldDisplayDeleteConfirmation, setShouldDisplayDeleteConfirmation] =
    useState(false);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const { res, loading } = useGetUserOpenCreditsByAccount();

  useEffect(() => {
    if (res && res.getUserOpenCreditsByAccount) {
      setCredits(res?.getUserOpenCreditsByAccount?.results);
      setCount(res?.getUserOpenCreditsByAccount?.count);
    }
  }, [res]);

  async function handleDeleteConfirm() {
    try {
      const response = await deleteCredit(
        { id: credits[detailIndex].id, user_id: credits[detailIndex].user.id },
        {
          account_id: Number(accountId),
          user_id: Number(userId),
          year: Number(moment().format('YYYY')),
        }
      );
      if (response && !response.errors) {
        toast.success('Credit deleted successfully');
        setShouldDisplayDeleteConfirmation(false);
        setDetailIndex(null);
      } else {
        toast.error('Error Deleting Credit');
      }
    } catch (error) {
      logDevError(error);
      toast.error('Error Deleting Credit');
    }
  }

  if (loading) return <Loading />;

  return detailIndex === undefined ? (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <Spacer size={10} />
        {credits && credits.length > 0 && (
          <UserCreditList
            credits={credits}
            currentPage={currentPage}
            setDetailIndex={setDetailIndex}
          />
        )}
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
    </>
  ) : (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <Spacer size={10} />
        <CreditDetail credit={credits[detailIndex]} />
        <div style={{ marginLeft: '10%' }}>
          <Icon
            onClick={() => {
              if (!capabilities || capabilities?.issue_credits)
                setShouldDisplayDeleteConfirmation(true);
              else setShowPermissionModal(true);
            }}
            size={18}
            color={props.theme.colors.primary}
            icon="trash"
          />
        </div>
      </div>

      <ConfirmModal
        confirmText="Delete"
        cancelText="Cancel"
        hideModal={() => setShouldDisplayDeleteConfirmation(false)}
        isVisible={shouldDisplayDeleteConfirmation}
        onConfirm={() => handleDeleteConfirm()}
        title="Do you want to delete this Credit?"
      />

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
      />
    </>
  );
});

export default withTheme(deleteCredit(TrackOpenCreditDetailMobile));
