import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const EVENT_TRANSACTION = gql`
  mutation getTicketSalesReport($event_id: Int!) {
    getTicketSalesReport(event_id: $event_id) {
      results {
        name
        price
        qty
      }
      event {
        name
        fullDate
        track {
          name
        }
        series {
          name
        }
        account_name
      }
      total
      credits
      discounts
    }
  }
`;

export function useGetTicketSalesReport() {
  const [eventTransaction] = useMutation(EVENT_TRANSACTION);

  const result = useCallback(
    (eventId) => {
      return eventTransaction({
        variables: {
          event_id: Number(eventId),
        },
      });
    },
    [eventTransaction]
  );
  return result;
}
