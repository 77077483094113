import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import qs from 'qs';

export const query = gql`
  query GetPendingUsers($page: Int!) {
    getPendingUsers(page: $page) {
      count
      results {
        id
        name
        cellphone
        email
        birthday
        created
      }
    }
  }
`;

export default graphql(query, {
  options: (props) => {
    const { location } = props;
    const pageObj =
      location && location.search
        ? qs.parse(location.search, {
            ignoreQueryPrefix: true,
          })
        : { page: 1 };

    return {
      variables: { page: Number(pageObj.page) },
      fetchPolicy: 'no-cache',
    };
  },
});
