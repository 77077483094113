import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useTransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import { useSearchInput } from 'hooks/useSearchInput';

export const ACCOUNT_MEMBERS_REPORT = gql`
  mutation GetAccountMembersReport($input: AccountMembersInput!) {
    getAccountMembersReport(input: $input) {
      count
      results {
        user {
          id
          formatted_name
          email
          cellphone
          birthday
        }
        divison
        membership {
          id
          name
        }
        serial_number
        purchase {
          id
          purchase_date
        }
        forms {
          data {
            label
            value
          }
        }
        section
        row
        seat
        cost
      }
    }
  }
`;

export function useGetAccountMembersReport() {
  const { input } = useSearchInput();
  const { id } = useParams();

  const { startDateFilter, endDateFilter } = useTransactionDateFilter();

  const [accountMembersMutation] = useMutation(ACCOUNT_MEMBERS_REPORT);

  const result = useCallback(
    (accountId, year, typeId, division, search, type) => {
      const skip = !accountId && !id;

      return accountMembersMutation({
        variables: {
          input: {
            account_id: accountId ? accountId : Number(id),
            year,
            ...input,
            ...(typeId ? { ticket_id: Number(typeId) } : null),
            ...(division ? { division } : null),
            ...(search ? { queryString: search } : null),
            ...(startDateFilter ? { start_date: startDateFilter } : null),
            ...(endDateFilter ? { end_date: endDateFilter } : null),
            ...(type ? { type } : null),
          },
        },
        skip: skip,
      });
    },
    [accountMembersMutation, endDateFilter, id, input, startDateFilter]
  );
  return result;
}
