import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme, withTheme } from 'styled-components';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { FilterContainer } from 'pages/app/Credits/OpenCredits/OpenCredits';
import { TitleContainer } from 'pages/track/Contact/style';
import { formatPhoneNumber } from 'shared/formatters';
import { useGetVerifiedUsers } from './gql/get-verified-users';

const ApprovedUsers = withEventYearFilterContext(() => {
  const theme = useTheme();
  const { data } = useGetVerifiedUsers();
  const users = data?.getVerifiedUsers?.results ?? [];

  if (!data?.getVerifiedUsers) return null;

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Mobile Number',
      key: 'mobile',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'DoB',
      key: 'dob',
    },
    {
      label: 'Approved',
      key: 'approved',
    },
    {
      label: 'Approved By',
      key: 'approvedBy',
    },
  ];

  function renderRows(user) {
    const { id, name, cellphone, email, birthday, approved_by, approved } =
      user;
    return {
      name: (
        <Link
          to={`/admin/users/transactions/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {name}</LineHeightText>
        </Link>
      ),
      mobile: formatPhoneNumber(cellphone),
      email: email,
      dob: birthday,
      approved: moment(approved, 'MM-DD-YYYY')
        .format('ddd MMM D - YYYY')
        .toUpperCase(),
      approvedBy: approved_by?.formatted_name,
    };
  }

  return (
    <>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Approved User History
              </Text>
              {/* <div style={{ marginRight: 15, marginTop: 10 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div> */}
              <FilterContainer>
                <YearDropdown showLabel={false} />
              </FilterContainer>
            </TitleContainer>
          </ContainerWrapper>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table items={users} columns={columns} renderRows={renderRows} />
          <Pagination
            count={data?.getVerifiedUsers?.count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>
      </Container>
    </>
  );
});

export default withTheme(ApprovedUsers);
