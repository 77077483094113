import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { EventHeaderMobile } from 'components/Events';
import Spacer from 'components/Spacer';
import { useGetRefundStatus } from 'components/Transactions';
import { useDebounceCallback } from 'hooks/useDebounceCallback';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import MobileSearchInput from '../../employee/Events/search';
import { List } from './list';

export const MobileTransactionsList = (props) => {
  const { match, location } = props;
  const { data } = useGetRefundStatus();
  const [search, setSearch] = useState('');

  const { page } = qs.parse(window.location.search.substring(1));
  const updateQueryString = useUpdateQueryString();

  const debouncedInputChange = useCallback(
    useDebounceCallback((value) => {
      setSearch(value);
      updateQueryString(value);
    }, 1000),
    []
  );

  const handleChange = (event) => {
    const targetValue = event.target.value;

    debouncedInputChange(targetValue);
    setSearch(targetValue);
  };

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  return (
    <div>
      <MobileSearchInput
        placeholder="Search Transactions.."
        onChange={handleChange}
        value={search}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleKeyPress={handleKeyPress}
      />
      <EventHeaderMobile noDropdown queryDate={'2020-05-23'} noMargin />
      <Spacer size={10} />

      <Spacer size={10} />
      <List
        page={page}
        refundEventStatus={data?.getEventRefundStatus}
        currentPage={currentPage}
        match={match}
        location={{ ...location, search: `?queryString=${search}` }}
      />
    </div>
  );
};
