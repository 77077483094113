import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { logDevError } from 'shared/alerts';

export const VERIFY_USER = gql`
  mutation VerifyUser($userInput: UpdateUserInput!) {
    verifyUser(input: $userInput)
  }
`;

export function useVerifyUser() {
  const [approveUserMutation] = useMutation(VERIFY_USER);

  const verifyUser = useCallback(
    async (user) => {
      try {
        const response = await approveUserMutation({
          variables: { userInput: { ...user } },
        });

        return response;
      } catch (e) {
        logDevError(e);
      }
    },
    [approveUserMutation]
  );
  return verifyUser;
}
