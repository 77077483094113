import React from 'react';
import styled from 'styled-components';
import { useGetAllTracks } from 'components/Events/gql';
import { AutoSuggest } from 'components/Form/AutoSuggest';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px;
  }
`;

export const EventTrackFilter = ({ trackFilter, setTrackFilter }) => {
  const { data, loading } = useGetAllTracks();

  return loading ? null : (
    <FilterContainer>
      <AutoSuggest
        placeholder="Track"
        options={data.getTracks.map((track) => ({
          label: track.name,
          value: track.id,
        }))}
        isSearchable
        isClearable
        value={
          trackFilter
            ? {
                label: trackFilter?.label || null,
                value: trackFilter?.value || null,
              }
            : null
        }
        onChange={(track) => setTrackFilter(track)}
      />
    </FilterContainer>
  );
};
