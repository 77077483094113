import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { EditTicketFieldsFragment } from './Events.fragments';
import { GET_EVENTS_ADMIN } from './Events.queries';

export default graphql(
  gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
      updateEvent(input: $input) {
        ...editTicketFields
      }
    }
    ${EditTicketFieldsFragment}
  `,
  {
    props: ({ mutate }) => ({
      updateEvent: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (cache, { data: { updateEvent } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = cache.readQuery({
                query: GET_EVENTS_ADMIN,
                variables: { input: { ...search, track_id: input.track_id } },
              });

              cache.writeQuery({
                query: GET_EVENTS_ADMIN,
                variables: { input: search },
                data: {
                  ...data,
                  getEventsAdmin: {
                    ...data.getEventsAdmin,
                    count: data.getEventsAdmin.count + 1,
                    results: data.getEventsAdmin.results.reduce(
                      (acc, event) => {
                        if (parseInt(event.id) === parseInt(input.id)) {
                          return acc.concat([updateEvent]);
                        }

                        return acc.concat([event]);
                      },
                      []
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
