import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { GET_EVENT_REFUND_STATUS } from './useGetRefundStatus';

export const REFUND_PASSES = gql`
  mutation RefundPasses($input: CreateTransactionCreditInput) {
    createRefundV3(input: $input)
  }
`;

export function useRefundPasses() {
  const [refundPassesMutation] = useMutation(REFUND_PASSES);

  const refundPasses = useCallback(
    (pass_ids, event_id, transaction_id, include_fees, credit_amount) => {
      return refundPassesMutation({
        variables: {
          input: {
            pass_ids: [...pass_ids],
            transaction_id,
            include_fees,
            credit_amount,
          },
        },
        refetchQueries: [
          {
            query: GET_EVENT_REFUND_STATUS,
            variables: {
              event_id,
            },
          },
        ],
      });
    },
    [refundPassesMutation]
  );
  return refundPasses;
}
