import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';

export const GET_REGISTRATION_TICKETS = gql`
  query GetRegistrationTickets($id: Int!) {
    getRegistrationTickets(event_id: $id) {
      id
      name
    }
  }
`;

export function useGetRegistrationTickets() {
  const { id } = useParams();

  const response = useQuery(GET_REGISTRATION_TICKETS, {
    variables: {
      id: Number(id),
    },
  });
  return response;
}
