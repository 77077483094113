import moment from 'moment';
import React from 'react';
import { TransactionsPrintList } from 'pages/app/Transactions/TransactionsPrintList';
import GetAllEventTransactions from './gql/get-all-event-transactions';

const PrintButton = ({ data, event }) => {
  if (!data.getAllEventTransactions) return false;

  return (
    <TransactionsPrintList
      date={
        event.isMultiDay
          ? `${event.fullMonth} ${event.listDates}, ${moment(
              event.end_date,
              'MM-DD-YYYY'
            ).format('YYYY')} `
          : event.fullDate
      }
      eventId={event.id}
      text="Print List"
    />
  );
};

export default GetAllEventTransactions(PrintButton);
