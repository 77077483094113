import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { withTheme } from 'styled-components';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import { AppContext } from 'shared/AppContext';
import EventsList from './EventsList';
import { useMe } from './gql/queries/me';
import MobileSearchInput from './search';

const Events = (props) => {
  const { location, match } = props;
  const { data } = useMe(props);
  const history = useHistory();

  const {
    state: { user },
  } = useContext(AppContext);
  const hasMoreThanOneAccount = user?.accounts?.length > 1;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const [search, setSearch] = useState('');

  const searchValue = (value) => {
    if (value.length > 0) {
      history.push(`${match.url}?queryString=${value}`);
    } else if (value.length === 0) history.push(`${match.url}`);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    searchValue(e.target.value);
  };

  const handleBlur = (e) => {
    searchValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchValue(e.target.value);
    }
  };

  if (!data) return false;

  return (
    <div>
      <MobileSearchInput
        placeholder="Search Events"
        onChange={handleChange}
        value={search}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleKeyPress={handleKeyPress}
      />
      <Spacer size={10} />

      <EventsList
        search={search}
        series={data.me.series ? true : false}
        track={data.me.track ? true : false}
        currentPage={currentPage}
        hasMoreThanOneAccount={hasMoreThanOneAccount}
      />
      <div style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}>
        <Pagination
          count={data.loading ? 0 : data?.getEventsByAccount?.count}
          perPage={15}
          currentPage={currentPage || 1}
          color={'#fff'}
        />
      </div>
      <Spacer size={40} />
    </div>
  );
};

export default withTheme(Events);
