import { withApollo } from '@apollo/react-hoc';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import Dropdown from 'components/Dropdown';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Icon from 'components/Icon';
import { useMe } from 'hooks/useMe';
import Images from 'images';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import storage from 'shared/storage';
import SubMenu from './component/SubMenu';
import GetStripeLogin from './gql/get-login';
import {
  GlobalStyle,
  BackgroundContainer,
  NavMenu,
  LogoWrapper,
  Logo,
  NavItem,
  NavItemExternal,
  SettingsLink,
  TopBar,
  SettingsWrapper,
  UserName,
  UserRole,
  Wrapper,
  TicketHossLogo,
  AccountsWrapper,
  NavItemButton,
} from './StyledAdminLayout';

const AdminLayout = ({ children, getStripeLogin, history, ...rest }) => {
  const { data: user } = useMe();
  let menu = [];

  const { dispatch } = useContext(AppContext);
  const [account, setAccount] = useState();
  const [capabilities, setCapabilities] = useState();

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const findCapabilities = (accountId) => {
    // eslint-disable-next-line no-unused-expressions
    user?.me?.capabilities?.forEach((capablity) => {
      if (capablity.account_id === accountId) {
        setCapabilities(capablity);
        dispatch({
          type: 'SET_CAPABILITIIES',
          capabilities: capablity,
        });
      }
    });
  };

  const {
    state: {
      accountId,
      accountName,
      janamScanners,
      capabilities: capabilitiesState,
    },
  } = useContext(AppContext);

  const setAccountId = (accountId) =>
    dispatch({
      type: 'SET_ACCOUNT_ID',
      accountId: accountId,
    });

  const setAccountName = (accountName) =>
    dispatch({
      type: 'SET_ACCOUNT_NAME',
      accountId: accountName,
    });

  const setAccountScanners = (janamScanners) =>
    dispatch({
      type: 'SET_ACCOUNT_SCANNERS',
      janamScanners: janamScanners,
    });

  const setSelectedAccount = (value) => {
    setAccount(value);
    setAccountId(value.value);
    setAccountName(value.label);
    setAccountScanners(value.janam_scanners);
    findCapabilities(value.value);
  };

  useEffect(() => {
    if (user && user.me) {
      //save the me value on the context
      dispatch({
        type: 'SET_ME',
        user: user?.me,
      });

      if (user.me?.role !== 'admin') {
        if (Array.isArray(user?.me?.accounts)) {
          if (user.me.accounts && user.me.accounts.length > 0) {
            setAccount({
              value: user?.me?.accounts[0].id,
              label: user?.me?.accounts[0].business_dba,
              janam_scanners: user?.me?.accounts[0].janam_scanners,
            });

            const selectedAccount = storage.get('selected_account');

            if (selectedAccount) {
              setSelectedAccount(selectedAccount);
            } else {
              setAccountId(user?.me?.accounts[0].id);
              setAccountName(user?.me?.accounts[0].business_dba);
              setAccountScanners(user?.me?.accounts[0].janam_scanners);

              findCapabilities(user?.me?.accounts[0].id);
            }
          } else {
            setShowPermissionModal(true);
          }
        }
        if (Array.isArray(user?.me?.promoters)) {
          dispatch({
            type: 'SET_PROMOTERS',
            promoters: user?.me?.promoters,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const logout = () => {
    storage.clearUser();
    rest.client.clearStore();
    storage.clear();
    history.push('/');
  };

  if (user?.me?.role === 'admin') {
    menu = [
      {
        id: 1,
        link: '/admin/tracks',
        icon: 'flag',
        name: 'Tracks',
        shown: true,
      },
      {
        id: 2,
        link: '/admin/series',
        icon: 'series',
        name: 'Series',
        shown: true,
      },
      {
        id: 3,
        link: '/admin/events',
        icon: 'flag',
        name: 'Events',
        shown: true,
      },
      {
        id: 17,
        link: '/admin/annualTickets',
        icon: 'flag',
        name: 'Annual Tickets',
        shown: true,
        submenu: [
          {
            title: 'Tickets',
            link: '/admin/annualTickets',
          },
          {
            title: 'Categories',
            link: '/admin/annualcategories',
          },
          {
            title: 'Purchases',
            link: '/admin/annualPurchases',
          },
          {
            title: 'Members',
            link: '/admin/annualMembersAccount',
          },
          {
            title: 'Annuals',
            link: '/admin/annualsAccount',
          },
        ],
      },
      {
        id: 4,
        link: '/admin/accounts',
        icon: 'building',
        name: 'Accounts',
        shown: true,
      },
      {
        id: 5,
        link: '/admin/users',
        icon: 'users',
        name: 'Users',
        shown: true,
        submenu: [
          {
            title: 'All Users',
            link: '/admin/users',
          },
          {
            title: 'Temporary',
            link: '/admin/temporaryUsers',
          },
          {
            title: 'Approved',
            link: '/admin/approvedUsers',
          },
        ],
      },
      {
        id: 6,
        link: '/admin/reports',
        icon: 'reports',
        name: 'Reports',
        shown: true,
      },
      {
        id: 7,
        link: '/admin/videos',
        icon: 'videos',
        name: 'Videos',
        shown: true,
      },
      {
        id: 8,
        link: '/admin/promos',
        icon: 'percent',
        name: 'Promos',
        shown: true,
      },
      {
        id: 9,
        link: '/admin/notifications',
        icon: 'notifications',
        name: 'Message Ctr',
        shown: true,
      },
      {
        id: 10,
        link: '/admin/track-types',
        icon: 'flag',
        name: 'Track Types',
        shown: true,
      },
      {
        id: 11,
        link: '/admin/series-types',
        icon: 'series',
        name: 'Series Types',
        shown: true,
      },
      {
        id: 12,
        link: '/admin/credits',
        icon: 'credit-icon',
        name: 'Credits',
        shown: true,
        submenu: [
          {
            title: 'Open Credits',
            link: '/admin/openCredits',
          },
          {
            title: 'Redeemed Credits',
            link: '/admin/redeemedCredits',
          },
          {
            title: 'Reports',
            link: '/admin/financials',
          },
        ],
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 13,
            link: '/admin/waivers',
            icon: 'document',
            name: 'Waivers',
            shown: true,
          }
        : { id: 13 },
      {
        id: 14,
        link: '/admin/transactions',
        icon: 'invoice-1',
        name: 'Transactions',
        shown: true,
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 15,
            link: '/admin/registrations',
            icon: 'google-forms',
            name: 'Registrations',
            shown: true,
          }
        : { id: 15 },
      {
        id: 16,
        link: '/admin/infos',
        icon: 'invoice-1',
        name: 'General Info',
        shown: true,
      },
      {
        id: 17,
        link: '/admin/categories',
        icon: 'invoice-1',
        name: 'Categories',
        shown: true,
      },
    ];
  } else if (user?.me?.role === 'track') {
    menu = [
      {
        id: 1,
        link: '/admin-track/home',
        icon: 'home',
        name: 'Home',
        shown: true,
      },
      {
        id: 2,
        link: '/admin-track/events',
        icon: 'flag',
        name: 'Events',
        shown: capabilities?.events ?? false,
      },
      {
        id: 3,
        link: '/admin-track/weather',
        icon: 'bolder-sun',
        name: 'Weather',
        shown: true,
      },
      {
        id: 4,
        link: '/admin-track/guest-lists',
        icon: 'home',
        name: 'Guest List',
        shown: capabilities?.guests ?? false,
      },

      {
        id: 5,
        link: '/admin-track/notifications',
        icon: 'notifications',
        name: 'Message Ctr',
        shown: capabilities?.messages ?? false,
      },
      {
        id: 6,
        link: '/admin-track/promos',
        icon: 'percent',
        name: 'Promo Codes',
        shown: capabilities?.promos ?? false,
      },
      {
        id: 7,
        link: '/admin-track/credits',
        icon: 'credit-icon',
        name: 'Credits',
        shown: capabilities?.credits ?? false,
        submenu: [
          {
            id: 701,
            title: 'Open Credits',
            link: '/admin-track/openCredits',
          },
          {
            id: 702,
            title: 'Redeemed Credits',
            link: '/admin-track/redeemedCredits',
          },
          {
            id: 702,
            title: 'Reports',
            link: '/admin-track/financials',
          },
        ],
      },
      {
        id: 8,
        link: '/admin-track/transactions',
        icon: 'invoice-1',
        name: 'Transactions',
        shown: capabilities?.transactions ?? false,
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 9,
            link: '/admin-track/registrations',
            icon: 'google-forms',
            name: 'Registrations',
            shown: capabilities?.registrations ?? false,
          }
        : { id: 9 },
      {
        id: 14,
        link: '/admin-track/annualPurchases',
        icon: 'flag',
        name:
          'tickethoss' !== process.env.REACT_APP_PLATFORM
            ? 'Memberships'
            : 'Season Tickets',
        shown: capabilities?.members ?? true,
        submenu: [
          {
            title: 'Purchases',
            link: '/admin-track/annualPurchases',
          },
          {
            title: 'Members',
            link: '/admin-track/annualMembers',
          },
          {
            title: 'Annuals',
            link: '/admin-track/annualUsers',
          },
        ],
      },
      {
        id: 10,
        link: '/admin-track/users',
        icon: 'users',
        name: 'Customers',
        shown: capabilities?.customers ?? false,
      },
      {
        id: 11,
        link: '/admin-track/employees',
        icon: 'users',
        name: 'Staff',
        shown: capabilities?.staff ?? false,
      },
      {
        id: 12,
        link: null,
        icon: 'help',
        name: 'Help',
        shown: true,
        external:
          process.env.REACT_APP_PLATFORM === 'tickethoss'
            ? 'https://promoters.tickethoss.com/'
            : 'https://promoters.pitpay.com/',
      },
      {
        id: 13,
        link: '/admin-track/contact',
        icon: 'phone',
        name: 'Contact',
        shown: true,
      },
    ];
  } else {
    menu = [
      {
        id: 1,
        link: '/admin-employee/home',
        icon: 'home',
        name: 'Home',
        shown: true,
      },
      {
        id: 2,
        link: '/admin-employee/events',
        icon: 'flag',
        name: 'Events',
        shown: capabilities?.events ?? false,
      },
      {
        id: 3,
        link: '/admin-employee/weather',
        icon: 'bolder-sun',
        name: 'Weather',
        shown: true,
      },
      {
        id: 4,
        link: '/admin-employee/guest-lists',
        icon: 'home',
        name: 'Guest List',
        shown: capabilities?.guests ?? false,
      },

      {
        id: 5,
        link: '/admin-employee/notifications',
        icon: 'notifications',
        name: 'Message Ctr',
        shown: capabilities?.messages ?? false,
      },
      {
        id: 6,
        link: '/admin-employee/promos',
        icon: 'percent',
        name: 'Promo Codes',
        shown: capabilities?.promos ?? false,
      },
      {
        id: 7,
        link: '/admin-employee/credits',
        icon: 'credit-icon',
        name: 'Credits',
        shown: capabilities?.credits ?? false,
        submenu: [
          {
            id: 701,
            title: 'Open Credits',
            link: '/admin-employee/openCredits',
          },
          {
            id: 702,
            title: 'Redeemed Credits',
            link: '/admin-employee/redeemedCredits',
          },
          {
            id: 702,
            title: 'Reports',
            link: '/admin-employee/financials',
          },
        ],
      },
      {
        id: 8,
        link: '/admin-employee/transactions',
        icon: 'invoice-1',
        name: 'Transactions',
        shown: capabilities?.transactions ?? false,
      },
      'tickethoss' !== process.env.REACT_APP_PLATFORM
        ? {
            id: 9,
            link: '/admin-employee/registrations',
            icon: 'google-forms',
            name: 'Registrations',
            shown: capabilities?.registrations ?? false,
          }
        : { id: 9 },
      {
        id: 14,
        link: '/admin-employee/annualPurchases',
        icon: 'flag',
        name:
          'tickethoss' !== process.env.REACT_APP_PLATFORM
            ? 'Memberships'
            : 'Season Tickets',
        shown: capabilities?.members ?? true,
        submenu: [
          {
            title: 'Purchases',
            link: '/admin-employee/annualPurchases',
          },
          {
            title: 'Members',
            link: '/admin-employee/annualMembers',
          },
          {
            title: 'Annuals',
            link: '/admin-employee/annualUsers',
          },
        ],
      },
      {
        id: 10,
        link: '/admin-employee/users',
        icon: 'users',
        name: 'Customers',
        shown: capabilities?.customers ?? false,
      },
      {
        id: 11,
        link: '/admin-employee/employees',
        icon: 'users',
        name: 'Staff',
        shown: capabilities?.staff ?? false,
      },
      {
        id: 12,
        link: null,
        icon: 'help',
        name: 'Help',
        shown: true,
        external:
          process.env.REACT_APP === 'tickethoss'
            ? 'https://promoters.tickethoss.com/'
            : 'https://promoters.pitpay.com/',
      },
      {
        id: 13,
        link: '/admin-employee/contact',
        icon: 'phone',
        name: 'Contact',
        shown: true,
      },
    ];
  }

  return !user ? null : (
    <Fragment>
      <BackgroundContainer>
        <NavMenu>
          <Wrapper>
            {'pitpay' === process.env.REACT_APP_PLATFORM ? (
              <LogoWrapper>
                <Logo src={Images.logoHorizontalWhite} />
              </LogoWrapper>
            ) : 'tickethoss' === process.env.REACT_APP_PLATFORM ? (
              <LogoWrapper style={{ width: '100%' }}>
                <TicketHossLogo
                  src={Images.logoTickethossHorizontal}
                  style={{ width: 200 }}
                />
              </LogoWrapper>
            ) : (
              <LogoWrapper>
                <Logo src={Images.logoKart} />
              </LogoWrapper>
            )}

            {menu.map((item) =>
              !item.external ? (
                item.shown === true ? (
                  item.submenu &&
                  item.id === 5 &&
                  'tickethoss' !== process.env.REACT_APP_PLATFORM ? (
                    <SubMenu item={item} />
                  ) : item.submenu && item.id !== 5 ? (
                    <SubMenu item={item} />
                  ) : (
                    <NavItem exact to={item.link} key={item.id}>
                      <div style={{ marginRight: 15, width: 20 }}>
                        <Icon icon={item.icon} size={20} color="white" />
                      </div>{' '}
                      {item.name}
                    </NavItem>
                  )
                ) : item.shown === false ? (
                  <NavItemButton
                    exact
                    onClick={() => {
                      setShowPermissionModal(true);
                    }}
                    key={item.id}
                  >
                    <div style={{ marginRight: 15, width: 20 }}>
                      <Icon icon={item.icon} size={20} color="white" />
                    </div>{' '}
                    {item.name}
                  </NavItemButton>
                ) : null
              ) : (
                <NavItemExternal
                  href={item.external}
                  key={item.id}
                  target="_blank"
                >
                  <div style={{ marginRight: 15, width: 20 }}>
                    <Icon icon={item.icon} size={20} color="white" />
                  </div>{' '}
                  {item.name}
                </NavItemExternal>
              )
            )}
            {user?.me?.role !== 'admin' ? (
              capabilities?.stripe ? (
                <NavItemExternal
                  onClick={async () => {
                    const result = await getStripeLogin();

                    if (result && result.data && result.data.getStripeLogin) {
                      window.location.assign(result.data.getStripeLogin);
                    } else {
                      history.push({
                        pathname: '/stripe',
                        search: '',
                        state: {
                          email: storage.get('user').email,
                          id: storage.get('user').id,
                          role: storage.get('user').role,
                        },
                      });
                    }
                  }}
                  target="_blank"
                  key={9}
                >
                  <div style={{ marginRight: 15, width: 20 }}>
                    <Icon icon="dollar" size={20} color="white" />
                  </div>{' '}
                  Stripe
                </NavItemExternal>
              ) : (
                <NavItemButton
                  exact
                  onClick={() => {
                    setShowPermissionModal(true);
                  }}
                  key={9}
                >
                  <div style={{ marginRight: 15, width: 20 }}>
                    <Icon icon="dollar" size={20} color="white" />
                  </div>{' '}
                  Stripe
                </NavItemButton>
              )
            ) : null}
          </Wrapper>
        </NavMenu>

        <TopBar>
          {user?.me?.role !== 'admin' && user?.me?.accounts?.length > 1 && (
            <AccountsWrapper>
              <AutoSuggest
                name="account_id"
                placeholder="Select Account"
                value={account}
                onChange={(value) => {
                  setSelectedAccount(value);

                  // Store the selected account in local storage
                  storage.set('selected_account', value);
                }}
                closeMenuOnSelect
                options={user?.me?.accounts?.map((item) => ({
                  value: item.id,
                  label: item.business_dba,
                  janam_scanners: item.janam_scanners,
                }))}
                customStyles={{
                  control: (provided) => ({
                    ...provided,
                    height: 40,
                    width: 200,
                  }),
                }}
              />
            </AccountsWrapper>
          )}
          <SettingsWrapper>
            <SettingsLink
              to={
                'admin' === user?.me?.role
                  ? '/admin/settings'
                  : 'track' === user?.me?.role
                  ? '/admin-track/settings/0'
                  : '/admin-employee/settings/0'
              }
            >
              <Icon icon="settings" size={20} color="white" />
            </SettingsLink>
          </SettingsWrapper>
          <Dropdown
            items={[{ id: 1, text: 'Logout', onClick: () => logout() }]}
          >
            <UserName>{`${user?.me?.first_name} ${user?.me?.last_name}`}</UserName>
            <UserRole>
              {'admin' === user?.me?.role
                ? 'APP ADMIN'
                : 'track' === user?.me?.role
                ? 'TRACK ADMIN'
                : 'EMPLOYEE'}
            </UserRole>
          </Dropdown>
        </TopBar>

        <div
          style={{
            width: 'calc(100% - 200px)',
            marginLeft: 200,
            height: '100%',
          }}
        >
          {children}
          <PermissionModal
            isVisible={showPermissionModal}
            hideModal={() => setShowPermissionModal(false)}
            link={
              'track' === user?.me?.role
                ? '/admin-track/settings/1'
                : '/admin-employee/settings/1'
            }
            history={history}
          />
        </div>
      </BackgroundContainer>
      <GlobalStyle />
    </Fragment>
  );
};

export default compose(withRouter, withApollo, GetStripeLogin)(AdminLayout);
