import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-promos';
import { query2 } from '../queries/get-promos-v2';

export default graphql(
  gql`
    mutation DeletePromo($id: Int!) {
      deletePromo(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deletePromo: async ({ id }, account_id) => {
        return mutator(() =>
          mutate({
            variables: { id },
            update: (proxy, { data: { deletePromo } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const isAdmin = window.location.pathname.includes('/admin/');

              if (!deletePromo) {
                return;
              }

              const data = isAdmin
                ? proxy.readQuery({
                    query,
                    variables: { input: search },
                  })
                : proxy.readQuery({
                    query: query2,
                    variables: {
                      input: {
                        ...(Object.keys(search).length !== 0
                          ? { ...search }
                          : null),
                        account_id,
                      },
                    },
                  });
              isAdmin
                ? proxy.writeQuery({
                    query,
                    variables: { input: search },
                    data: {
                      ...data,
                      getPromos: {
                        ...data.getPromos,
                        count: data.getPromos.count - 1,
                        results: data.getPromos.results.filter(
                          (promo) => promo.id !== id
                        ),
                      },
                    },
                  })
                : proxy.writeQuery({
                    query: query2,
                    variables: {
                      input: {
                        ...(Object.keys(search).length !== 0
                          ? { ...search }
                          : null),
                        account_id,
                      },
                    },
                    data: {
                      ...data,
                      getPromosV2: {
                        ...data.getPromosV2,
                        count: data.getPromosV2.count - 1,
                        results: data.getPromosV2.results.filter(
                          (promo) => promo.id !== id
                        ),
                      },
                    },
                  });
            },
          })
        );
      },
    }),
  }
);
