import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/queries/get-accounts';

export default graphql(
  gql`
    mutation CreateAccount($input: CreateAccountInput!) {
      createAccount(input: $input) {
        business_dba
        legal_name
        primary_contact
        email
        title
        country_code
        calling_code
        phone
        track {
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        mailing {
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        shipping {
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        track_ids
        series_ids
        stripe_account_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createAccount: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createAccount } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: { input: search },
              });

              const sort = (createAccount) => {
                const newResults =
                  data.getAccounts.results.concat(createAccount);

                newResults.sort(function (a, b) {
                  if (
                    a.business_dba.toLowerCase() < b.business_dba.toLowerCase()
                  ) {
                    return -1;
                  }
                  if (a.business_dba > b.business_dba) {
                    return 1;
                  }
                  return 0;
                });

                return newResults;
              };

              proxy.writeQuery({
                query,
                variables: { input: search },
                data: {
                  ...data,
                  getAccounts: {
                    ...data.getAccounts,
                    count: data.getAccounts.count + 1,
                    results: sort(createAccount),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
