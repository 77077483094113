import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetAccountTotalOpenCredits($accountId: Int!) {
    getAccountTotalOpenCredits(account_id: $accountId)
  }
`;

export function useGetaccountTotalOpenCredits(accountId) {
  return useQuery(query, {
    variables: {
      accountId: Number(accountId),
    },
  });
}
