const Emojis = [
  {
    id: 1,
    symbol: '😀',
    tags_EN: 'smile, grinning, face',
    tags_ES: 'risa, cara, sonriente',
    category: 'smileys',
  },
  {
    id: 2,
    symbol: '😁',
    tags_EN: 'smile, beaming, face',
    tags_ES: 'sonrisa, cara, dientes',
    category: 'smileys',
  },
  {
    id: 3,
    symbol: '😂',
    tags_EN: 'smile, tears, face',
    tags_ES: 'sonrisa, cara, llorar de risa',
    category: 'smileys',
  },
  {
    id: 4,
    symbol: '🤣',
    tags_EN: 'smile, tears, rolling',
    tags_ES: 'sonrisa, cara, llorar de risa',
    category: 'smileys',
  },
  {
    id: 5,
    symbol: '😃',
    tags_EN: 'smile, grinning, big eyes',
    tags_ES: 'sonrisa, cara, ojos grandes',
    category: 'smileys',
  },
  {
    id: 6,
    symbol: '😄',
    tags_EN: 'smile, grinning, small eyes',
    tags_ES: 'sonrisa, cara, ojos pequeños',
    category: 'smileys',
  },
  {
    id: 7,
    symbol: '😅',
    tags_EN: 'smile, grinning, sweat',
    tags_ES: 'sonrisa, cara, gota sudor',
    category: 'smileys',
  },
  {
    id: 8,
    symbol: '😆',
    tags_EN: 'smile, grinning, squinting',
    tags_ES: 'sonrisa, cara, ojos cerrados',
    category: 'smileys',
  },
  {
    id: 9,
    symbol: '😉',
    tags_EN: 'smile, winking, face',
    tags_ES: 'guiño, cara, guiñar ojo',
    category: 'smileys',
  },
  {
    id: 10,
    symbol: '😊',
    tags_EN: 'smile, red cheeks, face',
    tags_ES: 'sonrisa, cara, mofletes colorados',
    category: 'smileys',
  },
  {
    id: 11,
    symbol: '😋',
    tags_EN: 'savoring, food, face',
    tags_ES: 'comida, cara, deliciosa',
    category: 'smileys',
  },
  {
    id: 12,
    symbol: '😎',
    tags_EN: 'smiling, face, sunglasses',
    tags_ES: 'risa, cara, gafas de sol',
    category: 'smileys',
  },
  {
    id: 13,
    symbol: '😍',
    tags_EN: 'smiling, face, heart-eyes',
    tags_ES: 'risa, cara, ojos de corazón',
    category: 'smileys',
  },
  {
    id: 14,
    symbol: '😘',
    tags_EN: 'face , blowing, kiss',
    tags_ES: 'cara, beso, corazón',
    category: 'smileys',
  },
  {
    id: 16,
    symbol: '😗',
    tags_EN: 'kissing, face, love',
    tags_ES: 'cara, beso, amor',
    category: 'smileys',
  },
  {
    id: 17,
    symbol: '😙',
    tags_EN: 'kissing, smiling, eyes',
    tags_ES: 'besar, ojos, sonriendo',
    category: 'smileys',
  },
  {
    id: 18,
    symbol: '😚',
    tags_EN: 'kissing, face, closed eyes',
    tags_ES: 'besar, cara, ojos cerrados',
    category: 'smileys',
  },
  {
    id: 19,
    symbol: '☺️',
    tags_EN: 'smiling, face, happy',
    tags_ES: 'sonriendo, cara, feliz',
    category: 'smileys',
  },
  {
    id: 20,
    symbol: '🙂',
    tags_EN: 'slightly, smiling, face',
    tags_ES: 'sonriendo, cara, ligeramente',
    category: 'smileys',
  },
  {
    id: 21,
    symbol: '🤗',
    tags_EN: 'hugging, face, love',
    tags_ES: 'abrazando, cara, amor',
    category: 'smileys',
  },
  {
    id: 22,
    symbol: '🤩',
    tags_EN: 'star, struck, smile',
    tags_ES: 'cara, estrellas, sonriendo',
    category: 'smileys',
  },
  {
    id: 23,
    symbol: '🤔',
    tags_EN: 'thinking, face, think',
    tags_ES: 'cara, pensando, pensar',
    category: 'smileys',
  },
  {
    id: 24,
    symbol: '🤨',
    tags_EN: 'face, raised, eyebrow',
    tags_ES: 'cara, ceja, levantada',
    category: 'smileys',
  },
  {
    id: 25,
    symbol: '😐',
    tags_EN: 'face, neutral, plain',
    tags_ES: 'cara, neutral, plana',
    category: 'smileys',
  },
  {
    id: 26,
    symbol: '😑',
    tags_EN: 'expresionless, face, neutral',
    tags_ES: 'cara, sin expresión, neutral',
    category: 'smileys',
  },
  {
    id: 27,
    symbol: '😶',
    tags_EN: 'face, without mouth, no mouth',
    tags_ES: 'cara, sin boca, no boca',
    category: 'smileys',
  },
  {
    id: 28,
    symbol: '🙄',
    tags_EN: 'face, rolling, eyes',
    tags_ES: 'cara, ojos, incrédulo',
    category: 'smileys',
  },
  {
    id: 29,
    symbol: '😏',
    tags_EN: 'smirking, face, smile',
    tags_ES: 'sonrisa, traviesa, cara',
    category: 'smileys',
  },
  {
    id: 30,
    symbol: '😣',
    tags_EN: 'persevering, face, persevere',
    tags_ES: 'cara, perseverante, perseverar',
    category: 'smileys',
  },
  {
    id: 31,
    symbol: '😥',
    tags_EN: 'sad, relieved , face',
    tags_ES: 'cara, triste, aliviada',
    category: 'smileys',
  },
  {
    id: 32,
    symbol: '😮',
    tags_EN: 'face, open, mouth',
    tags_ES: 'cara, boca, abierta',
    category: 'smileys',
  },
  {
    id: 33,
    symbol: '🤐',
    tags_EN: 'zipper, mouth, face',
    tags_ES: 'cara, cremallera, boca',
    category: 'smileys',
  },
  {
    id: 34,
    symbol: '😯',
    tags_EN: 'husted, face, suprised',
    tags_ES: 'cara, sorpresa, oh',
    category: 'smileys',
  },
  {
    id: 35,
    symbol: '😪',
    tags_EN: 'sleepy, face, sleep',
    tags_ES: 'cara, sueño, dormir',
    category: 'smileys',
  },
  {
    id: 36,
    symbol: '😫',
    tags_EN: 'tired, face, sleep',
    tags_ES: 'cara, cansada, bostezar',
    category: 'smileys',
  },
  {
    id: 37,
    symbol: '😴',
    tags_EN: 'sleeping, face, zzz',
    tags_ES: 'cara, durmiendo, zzz',
    category: 'smileys',
  },
  {
    id: 38,
    symbol: '😌',
    tags_EN: 'relieved, face, happy',
    tags_ES: 'cara, aliviada, feliz',
    category: 'smileys',
  },
  {
    id: 39,
    symbol: '😛',
    tags_EN: 'face, tongue, happy',
    tags_ES: 'cara, lengua fuera, feliz',
    category: 'smileys',
  },
  {
    id: 40,
    symbol: '😜',
    tags_EN: 'winking, face, tongue',
    tags_ES: 'cara, lengua fuera, guiñar ojo',
    category: 'smileys',
  },
  {
    id: 41,
    symbol: '😝',
    tags_EN: 'squinting, face, tongue',
    tags_ES: 'cara, lengua fuera, ojos cerrados',
    category: 'smileys',
  },
  {
    id: 42,
    symbol: '🤤',
    tags_EN: 'drooling, face, food',
    tags_ES: 'cara, babear, comida',
    category: 'smileys',
  },
  {
    id: 43,
    symbol: '😒',
    tags_EN: 'unamused, face, not happy',
    tags_ES: 'cara, no divertida, borde',
    category: 'smileys',
  },
  {
    id: 44,
    symbol: '😓',
    tags_EN: 'downcast, face, sweat',
    tags_ES: 'cara, gota, sudor',
    category: 'smileys',
  },
  {
    id: 45,
    symbol: '😔',
    tags_EN: 'pensive, face, think',
    tags_ES: 'cara, pensativa, pensar',
    category: 'smileys',
  },
  {
    id: 46,
    symbol: '😕',
    tags_EN: 'confused, face, why',
    tags_ES: 'cara, confundida, cómo',
    category: 'smileys',
  },
  {
    id: 47,
    symbol: '🙃',
    tags_EN: 'upside, down, face',
    tags_ES: 'cara, al revés, hacia abajo',
    category: 'smileys',
  },
  {
    id: 48,
    symbol: '🤑',
    tags_EN: 'money, mouth, face',
    tags_ES: 'cara, lengua, dinero',
    category: 'smileys',
  },
  {
    id: 49,
    symbol: '😲',
    tags_EN: 'atonished, face, suprised',
    tags_ES: 'cara, atónita, sorprendida',
    category: 'smileys',
  },
  {
    id: 50,
    symbol: '☹️',
    tags_EN: 'frowning, face, serious',
    tags_ES: 'cara, fruncir ceño, mirar mal',
    category: 'smileys',
  },
  {
    id: 51,
    symbol: '🙁',
    tags_EN: 'slightly frowning, face, serious',
    tags_ES: 'cara, ligeramente fruncir ceño, mirar mal',
    category: 'smileys',
  },
  {
    id: 52,
    symbol: '😖',
    tags_EN: 'confounded, face, ouch',
    tags_ES: 'cara, confundida, no saber',
    category: 'smileys',
  },
  {
    id: 53,
    symbol: '😞',
    tags_EN: 'disappointed, face, frustrated',
    tags_ES: 'cara, desacuerdo, frustrado',
    category: 'smileys',
  },
  {
    id: 54,
    symbol: '😟',
    tags_EN: 'worried, face, anxious',
    tags_ES: 'cara, preocupada, ansiosa',
    category: 'smileys',
  },
  {
    id: 55,
    symbol: '😤',
    tags_EN: 'face, steam, nose',
    tags_ES: 'cara, humo, nariz',
    category: 'smileys',
  },
  {
    id: 56,
    symbol: '😢',
    tags_EN: 'crying, face, sad',
    tags_ES: 'cara, triste, llorar',
    category: 'smileys',
  },
  {
    id: 57,
    symbol: '😭',
    tags_EN: 'loudly, crying, face',
    tags_ES: 'cara, llorar, fuerte',
    category: 'smileys',
  },
  {
    id: 58,
    symbol: '😦',
    tags_EN: 'frowning, face, open mouth',
    tags_ES: 'cara sorpresa, boca, abierta',
    category: 'smileys',
  },
  {
    id: 59,
    symbol: '😧',
    tags_EN: 'anguished, face, open mouth',
    tags_ES: 'cara sorpresa, boca , abierta',
    category: 'smileys',
  },
  {
    id: 60,
    symbol: '😨',
    tags_EN: 'fearful, face, blue head',
    tags_ES: 'cara, miedosa, frente azul',
    category: 'smileys',
  },
  {
    id: 61,
    symbol: '😩',
    tags_EN: 'weary, face, tired',
    tags_ES: 'cara, cansada, suspirar',
    category: 'smileys',
  },
  {
    id: 62,
    symbol: '🤯',
    tags_EN: 'exploding, head, boom',
    tags_ES: 'cabeza, explota, abierta',
    category: 'smileys',
  },
  {
    id: 63,
    symbol: '😬',
    tags_EN: 'grimacing, face, smile',
    tags_ES: 'cara, mueca, sonrisa',
    category: 'smileys',
  },
  {
    id: 64,
    symbol: '😰',
    tags_EN: 'anxious, face, sweat',
    tags_ES: 'cara, ansiedad, sudor',
    category: 'smileys',
  },
  {
    id: 65,
    symbol: '😱',
    tags_EN: 'face, screaming, fear',
    tags_ES: 'cara, gritar, miedo',
    category: 'smileys',
  },
  {
    id: 68,
    symbol: '😳',
    tags_EN: 'flushed, face, red cheeks',
    tags_ES: 'cara, vergüenza, mejillas coloradas',
    category: 'smileys',
  },
  {
    id: 69,
    symbol: '🤪',
    tags_EN: 'zany, face, crazy',
    tags_ES: 'cara, loco, mueca',
    category: 'smileys',
  },
  {
    id: 70,
    symbol: '😵',
    tags_EN: 'dizzy, face, die',
    tags_ES: 'cara, muerte, mareada',
    category: 'smileys',
  },
  {
    id: 71,
    symbol: '😡',
    tags_EN: 'pouting, face, angry',
    tags_ES: 'cara, enfadada, roja',
    category: 'smileys',
  },
  {
    id: 72,
    symbol: '😠',
    tags_EN: 'angry, face, not happy',
    tags_ES: 'cara, enfadada, infeliz',
    category: 'smileys',
  },
  {
    id: 73,
    symbol: '🤬',
    tags_EN: 'face, symbols, mouth',
    tags_ES: 'cara, símbolos, boca',
    category: 'smileys',
  },
  {
    id: 74,
    symbol: '😷',
    tags_EN: 'face, medical, mask',
    tags_ES: 'cara, máscara, médico',
    category: 'smileys',
  },
  {
    id: 75,
    symbol: '🤒',
    tags_EN: 'face, thermometer, ill',
    tags_ES: 'cara, termómetro, enferma',
    category: 'smileys',
  },
  {
    id: 76,
    symbol: '🤕',
    tags_EN: 'face, head, bandage',
    tags_ES: 'cara, vendaje, lesión',
    category: 'smileys',
  },
  {
    id: 77,
    symbol: '🤢',
    tags_EN: 'nauseated, face, ill',
    tags_ES: 'náuseas, cara, enferma',
    category: 'smileys',
  },
  {
    id: 78,
    symbol: '🤮',
    tags_EN: 'face, vomiting, ill',
    tags_ES: 'cara, vómitos, enferma',
    category: 'smileys',
  },
  {
    id: 79,
    symbol: '🤧',
    tags_EN: 'sneezing, face, cold',
    tags_ES: 'sonarse, mocos, cara',
    category: 'smileys',
  },
  {
    id: 80,
    symbol: '😇',
    tags_EN: 'smiling, face, halo',
    tags_ES: 'cara, sonriendo, halo',
    category: 'smileys',
  },
  {
    id: 81,
    symbol: '🤠',
    tags_EN: 'cowboy, hat, face',
    tags_ES: 'cowboy, sombrero, cara',
    category: 'smileys',
  },
  {
    id: 82,
    symbol: '🤡',
    tags_EN: 'clown, face, joke',
    tags_ES: 'cara, payaso, broma',
    category: 'smileys',
  },
  {
    id: 86,
    symbol: '🤥',
    tags_EN: 'lying, face, nose',
    tags_ES: 'cara, mentira, nariz',
    category: 'smileys',
  },
  {
    id: 87,
    symbol: '🤫',
    tags_EN: 'shushing, face, silence',
    tags_ES: 'silencio, cara, callarse',
    category: 'smileys',
  },
  {
    id: 88,
    symbol: '🤭',
    tags_EN: 'face, hand, over mouth',
    tags_ES: 'cara, mano, boca',
    category: 'smileys',
  },
  {
    id: 89,
    symbol: '🧐',
    tags_EN: 'face, monocle, search',
    tags_ES: 'cara, monóculo, buscar',
    category: 'smileys',
  },
  {
    id: 90,
    symbol: '🤓',
    tags_EN: 'nerd, face, glasses',
    tags_ES: 'cara, lerdo, gafas',
    category: 'smileys',
  },
  {
    id: 91,
    symbol: '😈',
    tags_EN: 'smiling, face, horns',
    tags_ES: 'demonio, cuernos, sonriendo',
    category: 'smileys',
  },
  {
    id: 92,
    symbol: '👿',
    tags_EN: 'angry, face, horns',
    tags_ES: 'demonio, cuernos, enfadado',
    category: 'smileys',
  },
  {
    id: 93,
    symbol: '👹',
    tags_EN: 'ogre, red, ugly',
    tags_ES: 'ogro, rojo, feo',
    category: 'smileys',
  },
  {
    id: 94,
    symbol: '👺',
    tags_EN: 'goblin, red, ugly',
    tags_ES: 'duende, rojo, feo',
    category: 'smileys',
  },
  {
    id: 95,
    symbol: '💀',
    tags_EN: 'skull, white, dead',
    tags_ES: 'calavera, blanca, muerte',
    category: 'smileys',
  },
  {
    id: 96,
    symbol: '☠',
    tags_EN: 'skull, crossbones, dead',
    tags_ES: 'calavera, huesos, muerte',
    category: 'smileys',
  },
  {
    id: 97,
    symbol: '👻',
    tags_EN: 'ghost, white, tongue',
    tags_ES: 'fantasma, lengua, blanca',
    category: 'smileys',
  },
  {
    id: 98,
    symbol: '👽',
    tags_EN: 'alien, big eyes, monster',
    tags_ES: 'alien, ojos grandes, extraterrestre',
    category: 'smileys',
  },
  {
    id: 99,
    symbol: '👾',
    tags_EN: 'alien, monster, purple',
    tags_ES: 'alien, monstrúo, extraterrestre',
    category: 'smileys',
  },
  {
    id: 100,
    symbol: '🤖',
    tags_EN: 'robot, face, mechanic',
    tags_ES: 'robot, cara, mecánica',
    category: 'smileys',
  },
  {
    id: 101,
    symbol: '💩',
    tags_EN: 'pile, poo, shit',
    tags_ES: 'caca, mierda, marrón',
    category: 'smileys',
  },
  {
    id: 102,
    symbol: '😺',
    tags_EN: 'grinning, cat, face',
    tags_ES: 'gato, risa, feliz',
    category: 'smileys',
  },
  {
    id: 103,
    symbol: '😸',
    tags_EN: 'grinning, cat, face',
    tags_ES: 'gato, risa, feliz',
    category: 'smileys',
  },
  {
    id: 104,
    symbol: '😹',
    tags_EN: 'cat, face, tears',
    tags_ES: 'gato, risa, lágrimas',
    category: 'smileys',
  },
  {
    id: 105,
    symbol: '😻',
    tags_EN: 'cat, smiling, heart-eyes',
    tags_ES: 'gato, risa, ojos de corazón',
    category: 'smileys',
  },
  {
    id: 106,
    symbol: '😼',
    tags_EN: 'cat, face, wry smile',
    tags_ES: 'gato, risa, confíada',
    category: 'smileys',
  },
  {
    id: 107,
    symbol: '😽',
    tags_EN: 'kissing, cat, face',
    tags_ES: 'gato, beso, cara',
    category: 'smileys',
  },
  {
    id: 108,
    symbol: '🙀',
    tags_EN: 'weary, cat, face',
    tags_ES: 'gato, flipa, asombrado',
    category: 'smileys',
  },
  {
    id: 109,
    symbol: '😿',
    tags_EN: 'crying, cat, face',
    tags_ES: 'gato, llora, cara',
    category: 'smileys',
  },
  {
    id: 110,
    symbol: '😾',
    tags_EN: 'pouting, cat, face',
    tags_ES: 'gato, pucheros, enfadado',
    category: 'smileys',
  },
  {
    id: 111,
    symbol: '🙈',
    tags_EN: 'see-no-evil, monkey, hide',
    tags_ES: 'mono, esconde, tapa ojos',
    category: 'smileys',
  },
  {
    id: 112,
    symbol: '🙉',
    tags_EN: 'hear-no-evil, monkey, show',
    tags_ES: 'mono, muestra, ojos',
    category: 'smileys',
  },
  {
    id: 113,
    symbol: '🙊',
    tags_EN: 'speak-no-evil, monkey, mouth',
    tags_ES: 'mono, esconde, boca',
    category: 'smileys',
  },
  {
    id: 114,
    symbol: '👶',
    tags_EN: 'baby, child, yellow',
    tags_ES: 'bebé, niño, amarillo',
    category: 'gente',
  },
  {
    id: 115,
    symbol: '👶🏻',
    tags_EN: 'baby, light-skin tone, white',
    tags_ES: 'bebé, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 116,
    symbol: '👶🏼',
    tags_EN: 'baby, medium-light-skin tone, white',
    tags_ES: 'bebé, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 117,
    symbol: '👶🏽',
    tags_EN: 'baby, medium-skin tone, white',
    tags_ES: 'bebé, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 118,
    symbol: '👶🏾',
    tags_EN: 'baby, medium-dark-skin tone, black',
    tags_ES: 'bebé, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 119,
    symbol: '👶🏿',
    tags_EN: 'baby, dark-skin-tone, black',
    tags_ES: 'bebé, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 120,
    symbol: '🧒',
    tags_EN: 'child, kid, yellow',
    tags_ES: 'niño, crío, amarillo',
    category: 'gente',
  },
  {
    id: 121,
    symbol: '🧒🏻',
    tags_EN: 'child, light-skin tone, white',
    tags_ES: 'niño, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 122,
    symbol: '🧒🏼',
    tags_EN: 'child, medium-light-skin tone, white',
    tags_ES: 'niño, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 123,
    symbol: '🧒🏽',
    tags_EN: 'child, medium-skin tone, white',
    tags_ES: 'niño, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 124,
    symbol: '🧒🏾',
    tags_EN: 'child, medium-dark-skin tone, black',
    tags_ES: 'niño, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 125,
    symbol: '🧒🏿',
    tags_EN: 'child, dark-skin-tone, black',
    tags_ES: 'niño, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 126,
    symbol: '👦',
    tags_EN: 'boy, kid, yellow',
    tags_ES: 'chico, crío, amarillo',
    category: 'gente',
  },
  {
    id: 127,
    symbol: '👦🏻',
    tags_EN: 'boy, light-skin tone, white',
    tags_ES: 'chico, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 128,
    symbol: '👦🏼',
    tags_EN: 'boy, medium-light-skin tone, white',
    tags_ES: 'chico, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 129,
    symbol: '👦🏽',
    tags_EN: 'boy, medium-skin tone, white',
    tags_ES: 'chico, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 130,
    symbol: '👦🏾',
    tags_EN: 'boy, medium-dark-skin tone, black',
    tags_ES: 'chico, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 131,
    symbol: '👦🏿',
    tags_EN: 'boy, dark-skin-tone, black',
    tags_ES: 'chico, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 132,
    symbol: '👧',
    tags_EN: 'girl, kid, yellow',
    tags_ES: 'chica, cría, amarillo',
    category: 'gente',
  },
  {
    id: 133,
    symbol: '👧🏻',
    tags_EN: 'girl, light-skin tone, white',
    tags_ES: 'chica, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 134,
    symbol: '👧🏼',
    tags_EN: 'girl, medium-light-skin tone, white',
    tags_ES: 'chica, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 135,
    symbol: '👧🏽',
    tags_EN: 'girl, medium-skin tone, white',
    tags_ES: 'chica, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 136,
    symbol: '👧🏾',
    tags_EN: 'girl, medium-dark-skin tone, black',
    tags_ES: 'chica, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 137,
    symbol: '👧🏿',
    tags_EN: 'girl, dark-skin-tone, black',
    tags_ES: 'chica, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 138,
    symbol: '🧑',
    tags_EN: 'adult, man, yellow',
    tags_ES: 'adulto, hombre, amarillo',
    category: 'gente',
  },
  {
    id: 139,
    symbol: '🧑🏻',
    tags_EN: 'adult, light-skin tone, white',
    tags_ES: 'adulto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 140,
    symbol: '🧑🏼',
    tags_EN: 'adult, medium-light-skin tone, white',
    tags_ES: 'adulto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 141,
    symbol: '🧑🏽',
    tags_EN: 'adult, medium-skin tone, white',
    tags_ES: 'adulto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 142,
    symbol: '🧑🏾',
    tags_EN: 'adult, medium-dark-skin tone, black',
    tags_ES: 'adulto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 143,
    symbol: '🧑🏿',
    tags_EN: 'adult, dark-skin-tone, black',
    tags_ES: 'adulto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 144,
    symbol: '👨',
    tags_EN: 'man, moustache, yellow',
    tags_ES: 'hombre, bigote, amarillo',
    category: 'gente',
  },
  {
    id: 145,
    symbol: '👨🏻',
    tags_EN: 'man, light-skin tone, white',
    tags_ES: 'hombre, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 146,
    symbol: '👨🏼',
    tags_EN: 'man, medium-light-skin tone, white',
    tags_ES: 'hombre, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 147,
    symbol: '👨🏽',
    tags_EN: 'man, medium-skin tone, white',
    tags_ES: 'hombre, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 148,
    symbol: '👨🏾',
    tags_EN: 'man, medium-dark-skin tone, black',
    tags_ES: 'hombre, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 149,
    symbol: '👨🏿',
    tags_EN: 'man, dark-skin-tone, black',
    tags_ES: 'hombre, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 150,
    symbol: '👩',
    tags_EN: 'woman, long hair, yellow',
    tags_ES: 'mujer, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 151,
    symbol: '👩🏻',
    tags_EN: 'woman, light-skin tone, white',
    tags_ES: 'mujer, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 152,
    symbol: '👩🏼',
    tags_EN: 'woman, medium-light-skin tone, white',
    tags_ES: 'mujer, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 153,
    symbol: '👩🏽',
    tags_EN: 'woman, medium-skin tone, white',
    tags_ES: 'mujer, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 154,
    symbol: '👩🏾',
    tags_EN: 'woman, medium-dark-skin tone, black',
    tags_ES: 'mujer, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 155,
    symbol: '👩🏿',
    tags_EN: 'woman, dark-skin-tone, black',
    tags_ES: 'mujer, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 156,
    symbol: '🧓',
    tags_EN: 'older adult, short hair, yellow',
    tags_ES: 'adulto mayor, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 157,
    symbol: '🧓🏻',
    tags_EN: 'older adult, light-skin tone, white',
    tags_ES: 'adulto mayor, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 158,
    symbol: '🧓🏼',
    tags_EN: 'older adult, medium-light-skin tone, white',
    tags_ES: 'adulto mayor, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 159,
    symbol: '🧓🏽',
    tags_EN: 'older adult, medium-skin tone, white',
    tags_ES: 'adulto mayor, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 160,
    symbol: '🧓🏾',
    tags_EN: 'older adult, medium-dark-skin tone, black',
    tags_ES: 'adulto mayor, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 161,
    symbol: '🧓🏿',
    tags_EN: 'older adult, dark-skin-tone, black',
    tags_ES: 'adulto mayor, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 162,
    symbol: '👴',
    tags_EN: 'old man, no hair, yellow',
    tags_ES: 'señor mayor, sin pelo, amarillo',
    category: 'gente',
  },
  {
    id: 163,
    symbol: '👴🏻',
    tags_EN: 'old man, light-skin tone, white',
    tags_ES: 'señor mayor, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 164,
    symbol: '👴🏼',
    tags_EN: 'old man, medium-light-skin tone, white',
    tags_ES: 'señor mayor, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 165,
    symbol: '👴🏽',
    tags_EN: 'old man, medium-skin tone, white',
    tags_ES: 'señor mayor, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 166,
    symbol: '👴🏾',
    tags_EN: 'old man, medium-dark-skin tone, black',
    tags_ES: 'señor mayor, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 167,
    symbol: '👴🏿',
    tags_EN: 'old man, dark-skin-tone, black',
    tags_ES: 'señor mayor, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 168,
    symbol: '👵',
    tags_EN: 'old woman, long hair, yellow',
    tags_ES: 'señora mayor, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 169,
    symbol: '👵🏻',
    tags_EN: 'old woman, light-skin tone, white',
    tags_ES: 'señora mayor, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 170,
    symbol: '👵🏼',
    tags_EN: 'old woman, medium-light-skin tone, white',
    tags_ES: 'señora mayor, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 171,
    symbol: '👵🏽',
    tags_EN: 'old woman, medium-skin tone, white',
    tags_ES: 'señora mayor, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 172,
    symbol: '👵🏾',
    tags_EN: 'old woman, medium-dark-skin tone, black',
    tags_ES: 'señora mayor, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 173,
    symbol: '👵🏿',
    tags_EN: 'old woman, dark-skin-tone, black',
    tags_ES: 'señora mayor, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 174,
    symbol: '👨‍⚕️',
    tags_EN: 'man health worker, short hair, yellow',
    tags_ES: 'hombre trabajador salud, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 175,
    symbol: '👨🏻‍⚕️',
    tags_EN: 'man health worker, light-skin tone, white',
    tags_ES: 'hombre trabajador salud, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 176,
    symbol: '👨🏼‍⚕️',
    tags_EN: 'man health worker, medium-light-skin tone, white',
    tags_ES: 'hombre trabajador salud, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 177,
    symbol: '👨🏽‍⚕️',
    tags_EN: 'man health worker, medium-skin tone, white',
    tags_ES: 'hombre trabajador salud, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 178,
    symbol: '👨🏾‍⚕️',
    tags_EN: 'man health worker, medium-dark-skin tone, black',
    tags_ES: 'hombre trabajador salud, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 179,
    symbol: '👨🏿‍⚕️',
    tags_EN: 'man health worker, dark-skin-tone, black',
    tags_ES: 'hombre trabajador salud, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 180,
    symbol: '👩‍⚕️',
    tags_EN: 'woman health worker, long hair, yellow',
    tags_ES: 'mujer trabajadora salud, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 181,
    symbol: '👩🏻‍⚕️',
    tags_EN: 'woman health worker, light-skin tone, white',
    tags_ES: 'mujer trabajadora salud, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 182,
    symbol: '👩🏼‍⚕️',
    tags_EN: 'woman health worker, medium-light-skin tone, white',
    tags_ES: 'mujer trabajadora salud, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 183,
    symbol: '👩🏽‍⚕️',
    tags_EN: 'woman health worker, medium-skin tone, white',
    tags_ES: 'mujer trabajadora salud, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 184,
    symbol: '👩🏾‍⚕️',
    tags_EN: 'woman health worker, medium-dark-skin tone, black',
    tags_ES: 'mujer trabajadora salud, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 185,
    symbol: '👩🏿‍⚕️',
    tags_EN: 'woman health worker, dark-skin-tone, black',
    tags_ES: 'mujer trabajadora salud, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 186,
    symbol: '👨‍🎓',
    tags_EN: 'man student, short hair, yellow',
    tags_ES: 'hombre estudiante, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 187,
    symbol: '👨🏻‍🎓',
    tags_EN: 'man student, light-skin tone, white',
    tags_ES: 'hombre estudiante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 188,
    symbol: '👨🏼‍🎓',
    tags_EN: 'man student, medium-light-skin tone, white',
    tags_ES: 'hombre estudiante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 189,
    symbol: '👨🏽‍🎓',
    tags_EN: 'man student, medium-skin tone, white',
    tags_ES: 'hombre estudiante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 190,
    symbol: '👨🏾‍🎓',
    tags_EN: 'man student, medium-dark-skin tone, black',
    tags_ES: 'hombre estudiante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 191,
    symbol: '👨🏿‍🎓',
    tags_EN: 'man student, dark-skin-tone, black',
    tags_ES: 'hombre estudiante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 192,
    symbol: '👩‍🎓',
    tags_EN: 'woman student, short hair, yellow',
    tags_ES: 'mujer estudiante, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 193,
    symbol: '👩🏻‍🎓',
    tags_EN: 'woman student, light-skin tone, white',
    tags_ES: 'mujer estudiante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 194,
    symbol: '👩🏼‍🎓',
    tags_EN: 'woman student, medium-light-skin tone, white',
    tags_ES: 'mujer estudiante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 195,
    symbol: '👩🏽‍🎓',
    tags_EN: 'woman student, medium-skin tone, white',
    tags_ES: 'mujer estudiante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 196,
    symbol: '👩🏾‍🎓',
    tags_EN: 'woman student, medium-dark-skin tone, black',
    tags_ES: 'mujer estudiante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 197,
    symbol: '👩🏿‍🎓',
    tags_EN: 'woman student, dark-skin-tone, black',
    tags_ES: 'mujer estudiante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 198,
    symbol: '👨‍🏫',
    tags_EN: 'man teacher, short hair, yellow',
    tags_ES: 'hombre profesor, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 199,
    symbol: '👨🏻‍🏫',
    tags_EN: 'man teacher, light-skin tone, white',
    tags_ES: 'hombre profesor, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 200,
    symbol: '👨🏼‍🏫',
    tags_EN: 'man teacher, medium-light-skin tone, white',
    tags_ES: 'hombre profesor, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 201,
    symbol: '👨🏽‍🏫',
    tags_EN: 'man teacher, medium-skin tone, white',
    tags_ES: 'hombre profesor, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 202,
    symbol: '👨🏾‍🏫',
    tags_EN: 'man teacher, medium-dark-skin tone, black',
    tags_ES: 'hombre profesor, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 203,
    symbol: '👨🏿‍🏫',
    tags_EN: 'man teacher, dark-skin-tone, black',
    tags_ES: 'hombre profesor, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 204,
    symbol: '👩‍🏫',
    tags_EN: 'woman teacher, long hair, yellow',
    tags_ES: 'mujer profesora, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 205,
    symbol: '👩🏻‍🏫',
    tags_EN: 'woman teacher, light-skin tone, white',
    tags_ES: 'mujer profesora, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 206,
    symbol: '👩🏼‍🏫',
    tags_EN: 'woman teacher, medium-light-skin tone, white',
    tags_ES: 'mujer profesora, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 207,
    symbol: '👩🏽‍🏫',
    tags_EN: 'woman teacher, medium-skin tone, white',
    tags_ES: 'mujer profesora, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 208,
    symbol: '👩🏾‍🏫',
    tags_EN: 'woman teacher, medium-dark-skin tone, black',
    tags_ES: 'mujer profesora, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 209,
    symbol: '👩🏿‍🏫',
    tags_EN: 'woman teacher, dark-skin-tone, black',
    tags_ES: 'mujer profesora, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 210,
    symbol: '👨‍⚖️',
    tags_EN: 'man judge, short hair, yellow',
    tags_ES: 'hombre juez, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 211,
    symbol: '👨🏻‍⚖️',
    tags_EN: 'man judge, light-skin tone, white',
    tags_ES: 'hombre juez, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 212,
    symbol: '👨🏼‍⚖️',
    tags_EN: 'man judge, medium-light-skin tone, white',
    tags_ES: 'hombre juez, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 213,
    symbol: '👨🏽‍⚖️',
    tags_EN: 'man judge, medium-skin tone, white',
    tags_ES: 'hombre juez, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 214,
    symbol: '👨🏾‍⚖️',
    tags_EN: 'man judge, medium-dark-skin tone, black',
    tags_ES: 'hombre juez, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 215,
    symbol: '👨🏿‍⚖️',
    tags_EN: 'man judge, dark-skin-tone, black',
    tags_ES: 'hombre juez, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 216,
    symbol: '👩‍⚖️',
    tags_EN: 'woman judge, long hair, yellow',
    tags_ES: 'mujer juez, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 217,
    symbol: '👩🏻‍⚖️',
    tags_EN: 'woman judge, light-skin tone, white',
    tags_ES: 'mujer juez, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 218,
    symbol: '👩🏼‍⚖️',
    tags_EN: 'woman judge, medium-light-skin tone, white',
    tags_ES: 'mujer juez, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 219,
    symbol: '👩🏽‍⚖️',
    tags_EN: 'woman judge, medium-skin tone, white',
    tags_ES: 'mujer juez, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 220,
    symbol: '👩🏾‍⚖️',
    tags_EN: 'woman judge, medium-dark-skin tone, black',
    tags_ES: 'mujer juez, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 221,
    symbol: '👩🏿‍⚖️',
    tags_EN: 'woman judge, dark-skin-tone, black',
    tags_ES: 'mujer juez, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 222,
    symbol: '👨‍🌾',
    tags_EN: 'man farmer, short hair, yellow',
    tags_ES: 'hombre agricultor, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 223,
    symbol: '👨🏻‍🌾',
    tags_EN: 'man farmer, light-skin tone, white',
    tags_ES: 'hombre agricultor, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 224,
    symbol: '👨🏼‍🌾',
    tags_EN: 'man farmer, medium-light-skin tone, white',
    tags_ES: 'hombre agricultor, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 225,
    symbol: '👨🏽‍🌾',
    tags_EN: 'man farmer, medium-skin tone, white',
    tags_ES: 'hombre agricultor, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 226,
    symbol: '👨🏾‍🌾',
    tags_EN: 'man farmer, medium-dark-skin tone, black',
    tags_ES: 'hombre agricultor, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 227,
    symbol: '👨🏿‍🌾',
    tags_EN: 'man farmer, dark-skin-tone, black',
    tags_ES: 'hombre agricultor, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 228,
    symbol: '👩‍🌾',
    tags_EN: 'woman farmer, long hair, yellow',
    tags_ES: 'mujer agricultora, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 229,
    symbol: '👩🏻‍🌾',
    tags_EN: 'woman farmer, light-skin tone, white',
    tags_ES: 'mujer agricultora, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 230,
    symbol: '👩🏼‍🌾',
    tags_EN: 'woman farmer, medium-light-skin tone, white',
    tags_ES: 'mujer agricultora, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 231,
    symbol: '👩🏽‍🌾',
    tags_EN: 'woman farmer, medium-skin tone, white',
    tags_ES: 'mujer agricultora, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 232,
    symbol: '👩🏾‍🌾',
    tags_EN: 'woman farmer, medium-dark-skin tone, black',
    tags_ES: 'mujer agricultora, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 233,
    symbol: '👩🏿‍🌾',
    tags_EN: 'woman farmer, dark-skin-tone, black',
    tags_ES: 'mujer agricultora, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 234,
    symbol: '👨‍🍳',
    tags_EN: 'man cook, short hair, yellow',
    tags_ES: 'hombre cocinero, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 235,
    symbol: '👨🏻‍🍳',
    tags_EN: 'man cook, light-skin tone, white',
    tags_ES: 'hombre cocinero, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 236,
    symbol: '👨🏼‍🍳',
    tags_EN: 'man cook, medium-light-skin tone, white',
    tags_ES: 'hombre cocinero, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 237,
    symbol: '👨🏽‍🍳',
    tags_EN: 'man cook, medium-skin tone, white',
    tags_ES: 'hombre cocinero, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 238,
    symbol: '👨🏾‍🍳',
    tags_EN: 'man cook, medium-dark-skin tone, black',
    tags_ES: 'hombre cocinero, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 239,
    symbol: '👨🏿‍🍳',
    tags_EN: 'man cook, dark-skin-tone, black',
    tags_ES: 'hombre cocinero, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 240,
    symbol: '👩‍🍳',
    tags_EN: 'woman cook, long hair, yellow',
    tags_ES: 'mujer cocinera, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 241,
    symbol: '👩🏻‍🍳',
    tags_EN: 'woman cook, light-skin tone, white',
    tags_ES: 'mujer cocinera, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 242,
    symbol: '👩🏼‍🍳',
    tags_EN: 'woman cook, medium-light-skin tone, white',
    tags_ES: 'mujer cocinera, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 243,
    symbol: '👩🏽‍🍳',
    tags_EN: 'woman cook, medium-skin tone, white',
    tags_ES: 'mujer cocinera, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 244,
    symbol: '👩🏾‍🍳',
    tags_EN: 'woman cook, medium-dark-skin tone, black',
    tags_ES: 'mujer cocinera, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 245,
    symbol: '👩🏿‍🍳',
    tags_EN: 'woman cook, dark-skin-tone, black',
    tags_ES: 'mujer cocinera, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 246,
    symbol: '👨‍🔧',
    tags_EN: 'man mechanic, short hair, yellow',
    tags_ES: 'hombre mecánico, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 247,
    symbol: '👨🏻‍🔧',
    tags_EN: 'man mechanic, light-skin tone, white',
    tags_ES: 'hombre mecánico, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 248,
    symbol: '👨🏼‍🔧',
    tags_EN: 'man mechanic, medium-light-skin tone, white',
    tags_ES: 'hombre mecánico, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 249,
    symbol: '👨🏽‍🔧',
    tags_EN: 'man mechanic, medium-skin tone, white',
    tags_ES: 'hombre mecánico, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 250,
    symbol: '👨🏾‍🔧',
    tags_EN: 'man mechanic, medium-dark-skin tone, black',
    tags_ES: 'hombre mecánico, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 251,
    symbol: '👨🏿‍🔧',
    tags_EN: 'man mechanic, dark-skin-tone, black',
    tags_ES: 'hombre mecánico, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 252,
    symbol: '👩‍🔧',
    tags_EN: 'woman mechanic, long hair, yellow',
    tags_ES: 'mujer mecánica, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 253,
    symbol: '👩🏻‍🔧',
    tags_EN: 'woman mechanic, light-skin tone, white',
    tags_ES: 'mujer mecánica, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 254,
    symbol: '👩🏼‍🔧',
    tags_EN: 'woman mechanic, medium-light-skin tone, white',
    tags_ES: 'mujer mecánica, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 255,
    symbol: '👩🏽‍🔧',
    tags_EN: 'woman mechanic, medium-skin tone, white',
    tags_ES: 'mujer mecánica, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 256,
    symbol: '👩🏾‍🔧',
    tags_EN: 'woman mechanic, medium-dark-skin tone, black',
    tags_ES: 'mujer mecánica, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 257,
    symbol: '👩🏿‍🔧',
    tags_EN: 'woman mechanic, dark-skin-tone, black',
    tags_ES: 'mujer mecánica, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 258,
    symbol: '👨‍🏭',
    tags_EN: 'man factory worker, short hair, yellow',
    tags_ES: 'hombre obrero, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 259,
    symbol: '👨🏻‍🏭',
    tags_EN: 'man factory worker, light-skin tone, white',
    tags_ES: 'hombre obrero, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 260,
    symbol: '👨🏼‍🏭',
    tags_EN: 'man factory worker, medium-light-skin tone, white',
    tags_ES: 'hombre obrero, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 261,
    symbol: '👨🏽‍🏭',
    tags_EN: 'man factory worker, medium-skin tone, white',
    tags_ES: 'hombre obrero, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 262,
    symbol: '👨🏾‍🏭',
    tags_EN: 'man factory worker, medium-dark-skin tone, black',
    tags_ES: 'hombre obrero, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 263,
    symbol: '👨🏿‍🏭',
    tags_EN: 'man factory worker, dark-skin-tone, black',
    tags_ES: 'hombre obrero, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 264,
    symbol: '👩‍🏭',
    tags_EN: 'woman factory worker, long hair, yellow',
    tags_ES: 'mujer obrera, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 265,
    symbol: '👩🏻‍🏭',
    tags_EN: 'woman factory worker, light-skin tone, white',
    tags_ES: 'mujer obrera, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 266,
    symbol: '👩🏼‍🏭',
    tags_EN: 'woman factory worker, medium-light-skin tone, white',
    tags_ES: 'mujer obrera, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 267,
    symbol: '👩🏽‍🏭',
    tags_EN: 'woman factory worker, medium-skin tone, white',
    tags_ES: 'mujer obrera, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 268,
    symbol: '👩🏾‍🏭',
    tags_EN: 'woman factory worker, medium-dark-skin tone, black',
    tags_ES: 'mujer obrera, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 269,
    symbol: '👩🏿‍🏭',
    tags_EN: 'woman factory worker, dark-skin-tone, black',
    tags_ES: 'mujer obrera, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 270,
    symbol: '👨‍💼',
    tags_EN: 'man office worker, short hair, yellow',
    tags_ES: 'hombre oficinista, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 271,
    symbol: '👨🏻‍💼',
    tags_EN: 'man office worker, light-skin tone, white',
    tags_ES: 'hombre oficinista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 272,
    symbol: '👨🏼‍💼',
    tags_EN: 'man office worker, medium-light-skin tone, white',
    tags_ES: 'hombre oficinista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 273,
    symbol: '👨🏽‍💼',
    tags_EN: 'man office worker, medium-skin tone, white',
    tags_ES: 'hombre oficinista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 274,
    symbol: '👨🏾‍💼',
    tags_EN: 'man office worker, medium-dark-skin tone, black',
    tags_ES: 'hombre oficinista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 275,
    symbol: '👨🏿‍💼',
    tags_EN: 'man office worker, dark-skin-tone, black',
    tags_ES: 'hombre oficinista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 276,
    symbol: '👩‍💼',
    tags_EN: 'woman office worker, long hair, yellow',
    tags_ES: 'mujer oficinista, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 277,
    symbol: '👩🏻‍💼',
    tags_EN: 'woman office worker, light-skin tone, white',
    tags_ES: 'mujer oficinista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 278,
    symbol: '👩🏼‍💼',
    tags_EN: 'woman office worker, medium-light-skin tone, white',
    tags_ES: 'mujer oficinista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 279,
    symbol: '👩🏽‍💼',
    tags_EN: 'woman office worker, medium-skin tone, white',
    tags_ES: 'mujer oficinista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 280,
    symbol: '👩🏾‍💼',
    tags_EN: 'woman office worker, medium-dark-skin tone, black',
    tags_ES: 'mujer oficinista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 281,
    symbol: '👩🏿‍💼',
    tags_EN: 'woman office worker, dark-skin-tone, black',
    tags_ES: 'mujer oficinista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 282,
    symbol: '👨‍🔬',
    tags_EN: 'man scientist, short hair, yellow',
    tags_ES: 'hombre científico, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 283,
    symbol: '👨🏻‍🔬',
    tags_EN: 'man scientist, light-skin tone, white',
    tags_ES: 'hombre científico, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 284,
    symbol: '👨🏼‍🔬',
    tags_EN: 'man scientist, medium-light-skin tone, white',
    tags_ES: 'hombre científico, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 285,
    symbol: '👨🏽‍🔬',
    tags_EN: 'man scientist, medium-skin tone, white',
    tags_ES: 'hombre científico, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 286,
    symbol: '👨🏾‍🔬',
    tags_EN: 'man scientist, medium-dark-skin tone, black',
    tags_ES: 'hombre científico, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 287,
    symbol: '👨🏿‍🔬',
    tags_EN: 'man scientist, dark-skin-tone, black',
    tags_ES: 'hombre científico, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 288,
    symbol: '👩‍🔬',
    tags_EN: 'woman scientist, long hair, yellow',
    tags_ES: 'mujer científica, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 289,
    symbol: '👩🏻‍🔬',
    tags_EN: 'woman scientist, light-skin tone, white',
    tags_ES: 'mujer científica, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 290,
    symbol: '👩🏼‍🔬',
    tags_EN: 'woman scientist, medium-light-skin tone, white',
    tags_ES: 'mujer científica, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 291,
    symbol: '👩🏽‍🔬',
    tags_EN: 'woman scientist, medium-skin tone, white',
    tags_ES: 'mujer científica, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 292,
    symbol: '👩🏾‍🔬',
    tags_EN: 'woman scientist, medium-dark-skin tone, black',
    tags_ES: 'mujer científica, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 293,
    symbol: '👩🏿‍🔬',
    tags_EN: 'woman scientist, dark-skin-tone, black',
    tags_ES: 'mujer científica, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 294,
    symbol: '👨‍💻',
    tags_EN: 'man technologist, short hair, yellow',
    tags_ES: 'hombre informático, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 295,
    symbol: '👨🏻‍💻',
    tags_EN: 'man technologist, light-skin tone, white',
    tags_ES: 'hombre informático, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 296,
    symbol: '👨🏼‍💻',
    tags_EN: 'man technologist, medium-light-skin tone, white',
    tags_ES: 'hombre informático, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 297,
    symbol: '👨🏽‍💻',
    tags_EN: 'man technologist, medium-skin tone, white',
    tags_ES: 'hombre informático, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 298,
    symbol: '👨🏾‍💻',
    tags_EN: 'man technologist, medium-dark-skin tone, black',
    tags_ES: 'hombre informático, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 299,
    symbol: '👨🏿‍💻',
    tags_EN: 'man technologist, dark-skin-tone, black',
    tags_ES: 'hombre informático, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 300,
    symbol: '👩‍💻',
    tags_EN: 'woman technologist, long hair, yellow',
    tags_ES: 'mujer informática, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 301,
    symbol: '👩🏻‍💻',
    tags_EN: 'woman technologist, light-skin tone, white',
    tags_ES: 'mujer informática, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 302,
    symbol: '👩🏼‍💻',
    tags_EN: 'woman technologist, medium-light-skin tone, white',
    tags_ES: 'mujer informática, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 303,
    symbol: '👩🏽‍💻',
    tags_EN: 'woman technologist, medium-skin tone, white',
    tags_ES: 'mujer informática, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 304,
    symbol: '👩🏾‍💻',
    tags_EN: 'woman technologist, medium-dark-skin tone, black',
    tags_ES: 'mujer informática, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 305,
    symbol: '👩🏿‍💻',
    tags_EN: 'woman technologist, dark-skin-tone, black',
    tags_ES: 'mujer informática, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 306,
    symbol: '👨‍🎤',
    tags_EN: 'man singer, short hair, yellow',
    tags_ES: 'hombre cantante, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 307,
    symbol: '👨🏻‍🎤',
    tags_EN: 'man singer, light-skin tone, white',
    tags_ES: 'hombre cantante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 308,
    symbol: '👨🏼‍🎤',
    tags_EN: 'man singer, medium-light-skin tone, white',
    tags_ES: 'hombre cantante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 309,
    symbol: '👨🏽‍🎤',
    tags_EN: 'man singer, medium-skin tone, white',
    tags_ES: 'hombre cantante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 310,
    symbol: '👨🏾‍🎤',
    tags_EN: 'man singer, medium-dark-skin tone, black',
    tags_ES: 'hombre cantante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 311,
    symbol: '👨🏿‍🎤',
    tags_EN: 'man singer, dark-skin-tone, black',
    tags_ES: 'hombre cantante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 312,
    symbol: '👩‍🎤',
    tags_EN: 'woman singer, long hair, yellow',
    tags_ES: 'mujer cantante, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 313,
    symbol: '👩🏻‍🎤',
    tags_EN: 'woman singer, light-skin tone, white',
    tags_ES: 'mujer cantante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 314,
    symbol: '👩🏼‍🎤',
    tags_EN: 'woman singer, medium-light-skin tone, white',
    tags_ES: 'mujer cantante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 315,
    symbol: '👩🏽‍🎤',
    tags_EN: 'woman singer, medium-skin tone, white',
    tags_ES: 'mujer cantante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 316,
    symbol: '👩🏾‍🎤',
    tags_EN: 'woman singer, medium-dark-skin tone, black',
    tags_ES: 'mujer cantante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 317,
    symbol: '👩🏿‍🎤',
    tags_EN: 'woman singer, dark-skin-tone, black',
    tags_ES: 'mujer cantante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 318,
    symbol: '👨‍🎨',
    tags_EN: 'man artist, short hair, yellow',
    tags_ES: 'hombre artista, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 319,
    symbol: '👨🏻‍🎨',
    tags_EN: 'man artist, light-skin tone, white',
    tags_ES: 'hombre artista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 320,
    symbol: '👨🏼‍🎨',
    tags_EN: 'man artist, medium-light-skin tone, white',
    tags_ES: 'hombre artista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 321,
    symbol: '👨🏽‍🎨',
    tags_EN: 'man artist, medium-skin tone, white',
    tags_ES: 'hombre artista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 322,
    symbol: '👨🏾‍🎨',
    tags_EN: 'man artist, medium-dark-skin tone, black',
    tags_ES: 'hombre artista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 323,
    symbol: '👨🏿‍🎨',
    tags_EN: 'man artist, dark-skin-tone, black',
    tags_ES: 'hombre artista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 324,
    symbol: '👩‍🎨',
    tags_EN: 'woman artist, long hair, yellow',
    tags_ES: 'mujer artista, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 325,
    symbol: '👩🏻‍🎨',
    tags_EN: 'woman artist, light-skin tone, white',
    tags_ES: 'mujer artista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 326,
    symbol: '👩🏼‍🎨',
    tags_EN: 'woman artist, medium-light-skin tone, white',
    tags_ES: 'mujer artista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 327,
    symbol: '👩🏽‍🎨',
    tags_EN: 'woman artist, medium-skin tone, white',
    tags_ES: 'mujer artista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 328,
    symbol: '👩🏾‍🎨',
    tags_EN: 'woman artist, medium-dark-skin tone, black',
    tags_ES: 'mujer artista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 329,
    symbol: '👩🏿‍🎨',
    tags_EN: 'woman artist, dark-skin-tone, black',
    tags_ES: 'mujer artista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 330,
    symbol: '👨‍✈️',
    tags_EN: 'man pilot, short hair, yellow',
    tags_ES: 'hombre piloto, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 331,
    symbol: '👨🏻‍✈️',
    tags_EN: 'man pilot, light-skin tone, white',
    tags_ES: 'hombre piloto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 332,
    symbol: '👨🏼‍✈️',
    tags_EN: 'man pilot, medium-light-skin tone, white',
    tags_ES: 'hombre piloto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 333,
    symbol: '👨🏽‍✈️',
    tags_EN: 'man pilot, medium-skin tone, white',
    tags_ES: 'hombre piloto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 334,
    symbol: '👨🏾‍✈️',
    tags_EN: 'man pilot, medium-dark-skin tone, black',
    tags_ES: 'hombre piloto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 335,
    symbol: '👨🏿‍✈️',
    tags_EN: 'man pilot, dark-skin-tone, black',
    tags_ES: 'hombre piloto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 336,
    symbol: '👩‍✈️',
    tags_EN: 'woman pilot, long hair, yellow',
    tags_ES: 'mujer piloto, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 337,
    symbol: '👩🏻‍✈️',
    tags_EN: 'woman pilot, light-skin tone, white',
    tags_ES: 'mujer piloto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 338,
    symbol: '👩🏼‍✈️',
    tags_EN: 'woman pilot, medium-light-skin tone, white',
    tags_ES: 'mujer piloto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 339,
    symbol: '👩🏽‍✈️',
    tags_EN: 'woman pilot, medium-skin tone, white',
    tags_ES: 'mujer piloto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 340,
    symbol: '👩🏾‍✈️',
    tags_EN: 'woman pilot, medium-dark-skin tone, black',
    tags_ES: 'mujer piloto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 341,
    symbol: '👩🏿‍✈️',
    tags_EN: 'woman pilot, dark-skin-tone, black',
    tags_ES: 'mujer piloto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 342,
    symbol: '👨‍🚀',
    tags_EN: 'man astronaut, short hair, yellow',
    tags_ES: 'hombre astronauta, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 343,
    symbol: '👨🏻‍🚀',
    tags_EN: 'man astronaut, light-skin tone, white',
    tags_ES: 'hombre astronauta, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 344,
    symbol: '👨🏼‍🚀',
    tags_EN: 'man astronaut, medium-light-skin tone, white',
    tags_ES: 'hombre astronauta, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 345,
    symbol: '👨🏽‍🚀',
    tags_EN: 'man astronaut, medium-skin tone, white',
    tags_ES: 'hombre astronauta, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 346,
    symbol: '👨🏾‍🚀',
    tags_EN: 'man astronaut, medium-dark-skin tone, black',
    tags_ES: 'hombre astronauta, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 347,
    symbol: '👨🏿‍🚀',
    tags_EN: 'man astronaut, dark-skin-tone, black',
    tags_ES: 'hombre astronauta, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 348,
    symbol: '👩‍🚀',
    tags_EN: 'woman astronaut, long hair, yellow',
    tags_ES: 'mujer astronauta, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 349,
    symbol: '👩🏻‍🚀',
    tags_EN: 'woman astronaut, light-skin tone, white',
    tags_ES: 'mujer astronauta, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 350,
    symbol: '👩🏼‍🚀',
    tags_EN: 'woman astronaut, medium-light-skin tone, white',
    tags_ES: 'mujer astronauta, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 351,
    symbol: '👩🏽‍🚀',
    tags_EN: 'woman astronaut, medium-skin tone, white',
    tags_ES: 'mujer astronauta, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 352,
    symbol: '👩🏾‍🚀',
    tags_EN: 'woman astronaut, medium-dark-skin tone, black',
    tags_ES: 'mujer astronauta, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 353,
    symbol: '👩🏿‍🚀',
    tags_EN: 'woman astronaut, dark-skin-tone, black',
    tags_ES: 'mujer astronauta, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 354,
    symbol: '👨‍🚒',
    tags_EN: 'man firefighter, short hair, yellow',
    tags_ES: 'hombre bombero, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 355,
    symbol: '👨🏻‍🚒',
    tags_EN: 'man firefighter, light-skin tone, white',
    tags_ES: 'hombre bombero, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 356,
    symbol: '👨🏼‍🚒',
    tags_EN: 'man firefighter, medium-light-skin tone, white',
    tags_ES: 'hombre bombero, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 357,
    symbol: '👨🏽‍🚒',
    tags_EN: 'man firefighter, medium-skin tone, white',
    tags_ES: 'hombre bombero, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 358,
    symbol: '👨🏾‍🚒',
    tags_EN: 'man firefighter, medium-dark-skin tone, black',
    tags_ES: 'hombre bombero, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 359,
    symbol: '👨🏿‍🚒',
    tags_EN: 'man firefighter, dark-skin-tone, black',
    tags_ES: 'hombre bombero, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 360,
    symbol: '👩‍🚒',
    tags_EN: 'woman firefighter, long hair, yellow',
    tags_ES: 'mujer bombero, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 361,
    symbol: '👩🏻‍🚒',
    tags_EN: 'woman firefighter, light-skin tone, white',
    tags_ES: 'mujer bombero, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 362,
    symbol: '👩🏼‍🚒',
    tags_EN: 'woman firefighter, medium-light-skin tone, white',
    tags_ES: 'mujer bombero, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 363,
    symbol: '👩🏽‍🚒',
    tags_EN: 'woman firefighter, medium-skin tone, white',
    tags_ES: 'mujer bombero, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 364,
    symbol: '👩🏾‍🚒',
    tags_EN: 'woman firefighter, medium-dark-skin tone, black',
    tags_ES: 'mujer bombero, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 365,
    symbol: '👩🏿‍🚒',
    tags_EN: 'woman firefighter, dark-skin-tone, black',
    tags_ES: 'mujer bombero, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 366,
    symbol: '👮',
    tags_EN: 'police officer, short hair, yellow',
    tags_ES: 'oficial policía, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 367,
    symbol: '👮🏻',
    tags_EN: 'police officer, light-skin tone, white',
    tags_ES: 'oficial policía, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 368,
    symbol: '👮🏼',
    tags_EN: 'police officer, medium-light-skin tone, white',
    tags_ES: 'oficial policía, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 369,
    symbol: '👮🏽',
    tags_EN: 'police officer, medium-skin tone, white',
    tags_ES: 'oficial policía, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 370,
    symbol: '👮🏾',
    tags_EN: 'police officer, medium-dark-skin tone, black',
    tags_ES: 'oficial policía, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 371,
    symbol: '👮🏿',
    tags_EN: 'police officer, dark-skin-tone, black',
    tags_ES: 'oficial policía, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 372,
    symbol: '👮‍♂️',
    tags_EN: 'man police officer, short hair, yellow',
    tags_ES: 'hombre oficial policía, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 373,
    symbol: '👮🏻‍♂️',
    tags_EN: 'man police officer, light-skin tone, white',
    tags_ES: 'hombre oficial policía, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 374,
    symbol: '👮🏼‍♂️',
    tags_EN: 'man police officer, medium-light-skin tone, white',
    tags_ES: 'hombre oficial policía, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 375,
    symbol: '👮🏽‍♂️',
    tags_EN: 'man police officer, medium-skin tone, white',
    tags_ES: 'hombre oficial policía, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 376,
    symbol: '👮🏾‍♂️',
    tags_EN: 'man police officer, medium-dark-skin tone, black',
    tags_ES: 'hombre oficial policía, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 377,
    symbol: '👮🏿‍♂️',
    tags_EN: 'man police officer, dark-skin-tone, black',
    tags_ES: 'hombre oficial policía, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 378,
    symbol: '👮‍♀️',
    tags_EN: 'woman police officer, long hair, yellow',
    tags_ES: 'mujer oficial policía, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 379,
    symbol: '👮🏻‍♀️',
    tags_EN: 'woman police officer, light-skin tone, white',
    tags_ES: 'mujer oficial policía, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 380,
    symbol: '👮🏼‍♀️',
    tags_EN: 'woman police officer, medium-light-skin tone, white',
    tags_ES: 'mujer oficial policía, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 381,
    symbol: '👮🏽‍♀️',
    tags_EN: 'woman police officer, medium-skin tone, white',
    tags_ES: 'mujer oficial policía, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 382,
    symbol: '👮🏾‍♀️',
    tags_EN: 'woman police officer, medium-dark-skin tone, black',
    tags_ES: 'mujer oficial policía, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 383,
    symbol: '👮🏿‍♀️',
    tags_EN: 'woman police officer, dark-skin-tone, black',
    tags_ES: 'mujer oficial policía, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 384,
    symbol: '🕵',
    tags_EN: 'detective, short hair, yellow',
    tags_ES: 'detective, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 385,
    symbol: '🕵🏻',
    tags_EN: 'detective, light-skin tone, white',
    tags_ES: 'detective, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 386,
    symbol: '🕵🏼',
    tags_EN: 'detective, medium-light-skin tone, white',
    tags_ES: 'detective, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 387,
    symbol: '🕵🏽',
    tags_EN: 'detective, medium-skin tone, white',
    tags_ES: 'detective, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 388,
    symbol: '🕵🏾',
    tags_EN: 'detective, medium-dark-skin tone, black',
    tags_ES: 'detective, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 389,
    symbol: '🕵🏿',
    tags_EN: 'detective, dark-skin-tone, black',
    tags_ES: 'detective, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 390,
    symbol: '🕵️‍♂️',
    tags_EN: 'man detective, short hair, yellow',
    tags_ES: 'hombre detective, pelo corto, amarillo',
    category: 'gente',
  },
  {
    id: 391,
    symbol: '🕵🏻‍♂️',
    tags_EN: 'man detective, light-skin tone, white',
    tags_ES: 'hombre detective, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 392,
    symbol: '🕵🏼‍♂️',
    tags_EN: 'man detective, medium-light-skin tone, white',
    tags_ES: 'hombre detective, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 393,
    symbol: '🕵🏽‍♂️',
    tags_EN: 'man detective, medium-skin tone, white',
    tags_ES: 'hombre detective, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 394,
    symbol: '🕵🏾‍♂️',
    tags_EN: 'man detective, medium-dark-skin tone, black',
    tags_ES: 'hombre detective, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 395,
    symbol: '🕵🏿‍♂️',
    tags_EN: 'man detective, dark-skin-tone, black',
    tags_ES: 'hombre detective, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 396,
    symbol: '🕵️‍♀️',
    tags_EN: 'woman detective, long hair, yellow',
    tags_ES: 'mujer detective, pelo largo, amarillo',
    category: 'gente',
  },
  {
    id: 397,
    symbol: '🕵🏻‍♀️',
    tags_EN: 'woman detective, light-skin tone, white',
    tags_ES: 'mujer detective, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 398,
    symbol: '🕵🏼‍♀️',
    tags_EN: 'woman detective, medium-light-skin tone, white',
    tags_ES: 'mujer detective, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 399,
    symbol: '🕵🏽‍♀️',
    tags_EN: 'woman detective, medium-skin tone, white',
    tags_ES: 'mujer detective, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 400,
    symbol: '🕵🏾‍♀️',
    tags_EN: 'woman detective, medium-dark-skin tone, black',
    tags_ES: 'mujer detective, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 401,
    symbol: '🕵🏿‍♀️',
    tags_EN: 'woman detective, dark-skin-tone, black',
    tags_ES: 'mujer detective, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 408,
    symbol: '💂',
    tags_EN: 'guard, hat, yellow',
    tags_ES: 'guardia, sombrero, amarillo',
    category: 'gente',
  },
  {
    id: 409,
    symbol: '💂🏻',
    tags_EN: 'guard, light-skin tone, white',
    tags_ES: 'guardia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 410,
    symbol: '💂🏼',
    tags_EN: 'guard, medium-light-skin tone, white',
    tags_ES: 'guardia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 411,
    symbol: '💂🏽',
    tags_EN: 'guard, medium-skin tone, white',
    tags_ES: 'guardia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 412,
    symbol: '💂🏾',
    tags_EN: 'guard, medium-dark-skin tone, black',
    tags_ES: 'guardia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 413,
    symbol: '💂🏿',
    tags_EN: 'guard, dark-skin-tone, black',
    tags_ES: 'guardia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 414,
    symbol: '💂‍♂️',
    tags_EN: 'man guard, hat, yellow',
    tags_ES: 'hombre guardia, sombrero, amarillo',
    category: 'gente',
  },
  {
    id: 415,
    symbol: '💂🏻‍♂️',
    tags_EN: 'man guard, light-skin tone, white',
    tags_ES: 'hombre guardia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 416,
    symbol: '💂🏼‍♂️',
    tags_EN: 'man guard, medium-light-skin tone, white',
    tags_ES: 'hombre guardia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 417,
    symbol: '💂🏽‍♂️',
    tags_EN: 'man guard, medium-skin tone, white',
    tags_ES: 'hombre guardia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 418,
    symbol: '💂🏾‍♂️',
    tags_EN: 'man guard, medium-dark-skin tone, black',
    tags_ES: 'hombre guardia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 419,
    symbol: '💂🏿‍♂️',
    tags_EN: 'man guard, dark-skin-tone, black',
    tags_ES: 'hombre guardia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 420,
    symbol: '💂‍♀️',
    tags_EN: 'woman guard, hat, yellow',
    tags_ES: 'mujer guardia, sombrero, amarillo',
    category: 'gente',
  },
  {
    id: 421,
    symbol: '💂🏻‍♀️',
    tags_EN: 'woman guard, light-skin tone, white',
    tags_ES: 'mujer guardia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 422,
    symbol: '💂🏼‍♀️',
    tags_EN: 'woman guard, medium-light-skin tone, white',
    tags_ES: 'mujer guardia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 423,
    symbol: '💂🏽‍♀️',
    tags_EN: 'woman guard, medium-skin tone, white',
    tags_ES: 'mujer guardia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 424,
    symbol: '💂🏾‍♀️',
    tags_EN: 'woman guard, medium-dark-skin tone, black',
    tags_ES: 'mujer guardia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 425,
    symbol: '💂🏿‍♀️',
    tags_EN: 'woman guard, dark-skin-tone, black',
    tags_ES: 'mujer guardia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 426,
    symbol: '👷',
    tags_EN: 'construction worker, helmet, yellow',
    tags_ES: 'obrero, casco, amarillo',
    category: 'gente',
  },
  {
    id: 427,
    symbol: '👷🏻',
    tags_EN: 'construction worker, light-skin tone, white',
    tags_ES: 'obrero, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 428,
    symbol: '👷🏼',
    tags_EN: 'construction worker, medium-light-skin tone, white',
    tags_ES: 'obrero, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 429,
    symbol: '👷🏽',
    tags_EN: 'construction worker, medium-skin tone, white',
    tags_ES: 'obrero, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 430,
    symbol: '👷🏾',
    tags_EN: 'construction worker, medium-dark-skin tone, black',
    tags_ES: 'obrero, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 431,
    symbol: '👷🏿',
    tags_EN: 'construction worker, dark-skin-tone, black',
    tags_ES: 'obrero, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 432,
    symbol: '👷‍♂️',
    tags_EN: 'man construction worker, helmet, yellow',
    tags_ES: 'hombre obrero, casco, amarillo',
    category: 'gente',
  },
  {
    id: 433,
    symbol: '👷🏻‍♂️',
    tags_EN: 'man construction worker, light-skin tone, white',
    tags_ES: 'hombre obrero, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 434,
    symbol: '👷🏼‍♂️',
    tags_EN: 'man construction worker, medium-light-skin tone, white',
    tags_ES: 'hombre obrero, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 435,
    symbol: '👷🏽‍♂️',
    tags_EN: 'man construction worker, medium-skin tone, white',
    tags_ES: 'hombre obrero, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 436,
    symbol: '👷🏾‍♂️',
    tags_EN: 'man construction worker, medium-dark-skin tone, black',
    tags_ES: 'hombre obrero, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 437,
    symbol: '👷🏿‍♂️',
    tags_EN: 'man construction worker, dark-skin-tone, black',
    tags_ES: 'hombre obrero, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 438,
    symbol: '👷‍♀️',
    tags_EN: 'woman construction worker, helmet, yellow',
    tags_ES: 'mujer obrera, casco, amarillo',
    category: 'gente',
  },
  {
    id: 439,
    symbol: '👷🏻‍♀️',
    tags_EN: 'woman construction worker, light-skin tone, white',
    tags_ES: 'mujer obrera, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 440,
    symbol: '👷🏼‍♀️',
    tags_EN: 'woman construction worker, medium-light-skin tone, white',
    tags_ES: 'mujer obrera, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 441,
    symbol: '👷🏽‍♀️',
    tags_EN: 'woman construction worker, medium-skin tone, white',
    tags_ES: 'mujer obrera, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 442,
    symbol: '👷🏾‍♀️',
    tags_EN: 'woman construction worker, medium-dark-skin tone, black',
    tags_ES: 'mujer obrera, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 443,
    symbol: '👷🏿‍♀️',
    tags_EN: 'woman construction worker, dark-skin-tone, black',
    tags_ES: 'mujer obrera, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 444,
    symbol: '🤴',
    tags_EN: 'prince, corona, yellow',
    tags_ES: 'príncipe, corona, amarillo',
    category: 'gente',
  },
  {
    id: 445,
    symbol: '🤴🏻',
    tags_EN: 'prince, light-skin tone, white',
    tags_ES: 'príncipe, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 446,
    symbol: '🤴🏼',
    tags_EN: 'prince, medium-light-skin tone, white',
    tags_ES: 'príncipe, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 447,
    symbol: '🤴🏽',
    tags_EN: 'prince, medium-skin tone, white',
    tags_ES: 'príncipe, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 448,
    symbol: '🤴🏾',
    tags_EN: 'prince, medium-dark-skin tone, black',
    tags_ES: 'príncipe, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 449,
    symbol: '🤴🏿',
    tags_EN: 'prince, dark-skin-tone, black',
    tags_ES: 'príncipe, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 450,
    symbol: '👸',
    tags_EN: 'princess, corona, yellow',
    tags_ES: 'princesa, corona, amarillo',
    category: 'gente',
  },
  {
    id: 451,
    symbol: '👸🏻',
    tags_EN: 'princess, light-skin tone, white',
    tags_ES: 'princesa, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 452,
    symbol: '👸🏼',
    tags_EN: 'princess, medium-light-skin tone, white',
    tags_ES: 'princesa, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 453,
    symbol: '👸🏽',
    tags_EN: 'princess, medium-skin tone, white',
    tags_ES: 'princesa, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 454,
    symbol: '👸🏾',
    tags_EN: 'princess, medium-dark-skin tone, black',
    tags_ES: 'princesa, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 455,
    symbol: '👸🏿',
    tags_EN: 'princess, dark-skin-tone, black',
    tags_ES: 'princesa, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 456,
    symbol: '👳',
    tags_EN: 'person wearing turban, beard, yellow',
    tags_ES: 'persona con turbante, barba, amarillo',
    category: 'gente',
  },
  {
    id: 457,
    symbol: '👳🏻',
    tags_EN: 'person wearing turban, light-skin tone, white',
    tags_ES: 'persona con turbante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 458,
    symbol: '👳🏼',
    tags_EN: 'person wearing turban, medium-light-skin tone, white',
    tags_ES: 'persona con turbante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 459,
    symbol: '👳🏽',
    tags_EN: 'person wearing turban, medium-skin tone, white',
    tags_ES: 'persona con turbante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 460,
    symbol: '👳🏾',
    tags_EN: 'person wearing turban, medium-dark-skin tone, black',
    tags_ES: 'persona con turbante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 461,
    symbol: '👳🏿',
    tags_EN: 'person wearing turban, dark-skin-tone, black',
    tags_ES: 'persona con turbante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 462,
    symbol: '👳‍♂️',
    tags_EN: 'man wearing turban, beard, yellow',
    tags_ES: 'hombre con turbante, barba, amarillo',
    category: 'gente',
  },
  {
    id: 463,
    symbol: '👳🏻‍♂️',
    tags_EN: 'man wearing turban, light-skin tone, white',
    tags_ES: 'hombre con turbante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 464,
    symbol: '👳🏼‍♂️',
    tags_EN: 'man wearing turban, medium-light-skin tone, white',
    tags_ES: 'hombre con turbante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 465,
    symbol: '👳🏽‍♂️',
    tags_EN: 'man wearing turban, medium-skin tone, white',
    tags_ES: 'hombre con turbante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 466,
    symbol: '👳🏾‍♂️',
    tags_EN: 'man wearing turban, medium-dark-skin tone, black',
    tags_ES: 'hombre con turbante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 467,
    symbol: '👳🏿‍♂️',
    tags_EN: 'man wearing turban, dark-skin-tone, black',
    tags_ES: 'hombre con turbante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 468,
    symbol: '👳‍♀️',
    tags_EN: 'woman wearing turban, big eyes, yellow',
    tags_ES: 'mujer con turbante, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 469,
    symbol: '👳🏻‍♀️',
    tags_EN: 'woman wearing turban, light-skin tone, white',
    tags_ES: 'mujer con turbante, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 470,
    symbol: '👳🏼‍♀️',
    tags_EN: 'woman wearing turban, medium-light-skin tone, white',
    tags_ES: 'mujer con turbante, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 471,
    symbol: '👳🏽‍♀️',
    tags_EN: 'woman wearing turban, medium-skin tone, white',
    tags_ES: 'mujer con turbante, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 472,
    symbol: '👳🏾‍♀️',
    tags_EN: 'woman wearing turban, medium-dark-skin tone, black',
    tags_ES: 'mujer con turbante, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 473,
    symbol: '👳🏿‍♀️',
    tags_EN: 'woman wearing turban, dark-skin-tone, black',
    tags_ES: 'mujer con turbante, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 474,
    symbol: '👲',
    tags_EN: 'man chinese cap, hat, yellow',
    tags_ES: 'hombre sombrero chino, gorro, amarillo',
    category: 'gente',
  },
  {
    id: 475,
    symbol: '👲🏻',
    tags_EN: 'man chinese cap, light-skin tone, white',
    tags_ES: 'hombre sombrero chino, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 476,
    symbol: '👲🏼',
    tags_EN: 'man chinese cap, medium-light-skin tone, white',
    tags_ES: 'hombre sombrero chino, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 477,
    symbol: '👲🏽',
    tags_EN: 'man chinese cap, medium-skin tone, white',
    tags_ES: 'hombre sombrero chino, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 478,
    symbol: '👲🏾',
    tags_EN: 'man chinese cap, medium-dark-skin tone, black',
    tags_ES: 'hombre sombrero chino, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 479,
    symbol: '👲🏿',
    tags_EN: 'man chinese cap, dark-skin-tone, black',
    tags_ES: 'hombre sombrero chino, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 480,
    symbol: '🧕',
    tags_EN: 'woman headscarf, big eyes, yellow',
    tags_ES: 'mujer con pañuelo, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 481,
    symbol: '🧕🏻',
    tags_EN: 'woman headscarf, light-skin tone, white',
    tags_ES: 'mujer con pañuelo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 482,
    symbol: '🧕🏼',
    tags_EN: 'woman headscarf, medium-light-skin tone, white',
    tags_ES: 'mujer con pañuelo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 483,
    symbol: '🧕🏽',
    tags_EN: 'woman headscarf, medium-skin tone, white',
    tags_ES: 'mujer con pañuelo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 484,
    symbol: '🧕🏾',
    tags_EN: 'woman headscarf, medium-dark-skin tone, black',
    tags_ES: 'mujer con pañuelo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 485,
    symbol: '🧕🏿',
    tags_EN: 'woman headscarf, dark-skin-tone, black',
    tags_ES: 'mujer con pañuelo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 486,
    symbol: '🧔',
    tags_EN: 'bearded person, beard, yellow',
    tags_ES: 'persona con barba, barba, amarillo',
    category: 'gente',
  },
  {
    id: 487,
    symbol: '🧔🏻',
    tags_EN: 'bearded person, light-skin tone, white',
    tags_ES: 'persona con barba, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 488,
    symbol: '🧔🏼',
    tags_EN: 'bearded person, medium-light-skin tone, white',
    tags_ES: 'persona con barba, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 489,
    symbol: '🧔🏽',
    tags_EN: 'bearded person, medium-skin tone, white',
    tags_ES: 'persona con barba, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 490,
    symbol: '🧔🏾',
    tags_EN: 'bearded person, medium-dark-skin tone, black',
    tags_ES: 'persona con barba, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 491,
    symbol: '🧔🏿',
    tags_EN: 'bearded person, dark-skin-tone, black',
    tags_ES: 'persona con barba, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 492,
    symbol: '👱',
    tags_EN: 'blond-haired person, big eyes, yellow',
    tags_ES: 'persona rubia, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 493,
    symbol: '👱🏻',
    tags_EN: 'blond-haired person, light-skin tone, white',
    tags_ES: 'persona rubia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 494,
    symbol: '👱🏼',
    tags_EN: 'blond-haired person, medium-light-skin tone, white',
    tags_ES: 'persona rubia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 495,
    symbol: '👱🏽',
    tags_EN: 'blond-haired person, medium-skin tone, white',
    tags_ES: 'persona rubia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 496,
    symbol: '👱🏾',
    tags_EN: 'blond-haired person, medium-dark-skin tone, black',
    tags_ES: 'persona rubia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 497,
    symbol: '👱🏿',
    tags_EN: 'blond-haired person, dark-skin-tone, black',
    tags_ES: 'persona rubia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 498,
    symbol: '👱‍♂️',
    tags_EN: 'blond-haired man, big eyes, yellow',
    tags_ES: 'hombre rubio, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 499,
    symbol: '👱🏻‍♂️',
    tags_EN: 'blond-haired man, light-skin tone, white',
    tags_ES: 'hombre rubio, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 500,
    symbol: '👱🏼‍♂️',
    tags_EN: 'blond-haired man, medium-light-skin tone, white',
    tags_ES: 'hombre rubio, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 501,
    symbol: '👱🏽‍♂️',
    tags_EN: 'blond-haired man, medium-skin tone, white',
    tags_ES: 'hombre rubio, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 502,
    symbol: '👱🏾‍♂️',
    tags_EN: 'blond-haired man, medium-dark-skin tone, black',
    tags_ES: 'hombre rubio, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 503,
    symbol: '👱🏿‍♂️',
    tags_EN: 'blond-haired man, dark-skin-tone, black',
    tags_ES: 'hombre rubio, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 504,
    symbol: '👱‍♀️',
    tags_EN: 'blond-haired woman, big eyes, yellow',
    tags_ES: 'mujer rubia, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 505,
    symbol: '👱🏻‍♀️',
    tags_EN: 'blond-haired woman, light-skin tone, white',
    tags_ES: 'mujer rubia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 506,
    symbol: '👱🏼‍♀️',
    tags_EN: 'blond-haired woman, medium-light-skin tone, white',
    tags_ES: 'mujer rubia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 507,
    symbol: '👱🏽‍♀️',
    tags_EN: 'blond-haired woman, medium-skin tone, white',
    tags_ES: 'mujer rubia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 508,
    symbol: '👱🏾‍♀️',
    tags_EN: 'blond-haired woman, medium-dark-skin tone, black',
    tags_ES: 'mujer rubia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 509,
    symbol: '👱🏿‍♀️',
    tags_EN: 'blond-haired woman, dark-skin-tone, black',
    tags_ES: 'mujer rubia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 558,
    symbol: '🤵',
    tags_EN: 'man in tuxedo, moustache, yellow',
    tags_ES: 'hombre esmoquin, bigote, amarillo',
    category: 'gente',
  },
  {
    id: 559,
    symbol: '🤵🏻',
    tags_EN: 'man in tuxedo, light-skin tone, white',
    tags_ES: 'hombre esmoquin, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 560,
    symbol: '🤵🏼',
    tags_EN: 'man in tuxedo, medium-light-skin tone, white',
    tags_ES: 'hombre esmoquin, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 561,
    symbol: '🤵🏽',
    tags_EN: 'man in tuxedo, medium-skin tone, white',
    tags_ES: 'hombre esmoquin, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 562,
    symbol: '🤵🏾',
    tags_EN: 'man in tuxedo, medium-dark-skin tone, black',
    tags_ES: 'hombre esmoquin, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 563,
    symbol: '🤵🏿',
    tags_EN: 'man in tuxedo, dark-skin-tone, black',
    tags_ES: 'hombre esmoquin, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 564,
    symbol: '👰',
    tags_EN: 'bride with veil, big eyes, yellow',
    tags_ES: 'vestido de novia, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 565,
    symbol: '👰🏻',
    tags_EN: 'bride with veil, light-skin tone, white',
    tags_ES: 'vestido de novia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 566,
    symbol: '👰🏼',
    tags_EN: 'bride with veil, medium-light-skin tone, white',
    tags_ES: 'vestido de novia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 567,
    symbol: '👰🏽',
    tags_EN: 'bride with veil, medium-skin tone, white',
    tags_ES: 'vestido de novia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 568,
    symbol: '👰🏾',
    tags_EN: 'bride with veil, medium-dark-skin tone, black',
    tags_ES: 'vestido de novia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 569,
    symbol: '👰🏿',
    tags_EN: 'bride with veil, dark-skin-tone, black',
    tags_ES: 'vestido de novia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 570,
    symbol: '🤰',
    tags_EN: 'pregnant woman, big eyes, yellow',
    tags_ES: 'mujer embarazada, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 571,
    symbol: '🤰🏻',
    tags_EN: 'pregnant woman, light-skin tone, white',
    tags_ES: 'mujer embarazada, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 572,
    symbol: '🤰🏼',
    tags_EN: 'pregnant woman, medium-light-skin tone, white',
    tags_ES: 'mujer embarazada, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 573,
    symbol: '🤰🏽',
    tags_EN: 'pregnant woman, medium-skin tone, white',
    tags_ES: 'mujer embarazada, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 574,
    symbol: '🤰🏾',
    tags_EN: 'pregnant woman, medium-dark-skin tone, black',
    tags_ES: 'mujer embarazada, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 575,
    symbol: '🤰🏿',
    tags_EN: 'pregnant woman, dark-skin-tone, black',
    tags_ES: 'mujer embarazada, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 576,
    symbol: '🤱',
    tags_EN: 'breast-feeding, big eyes, yellow',
    tags_ES: 'mujer amamanta, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 577,
    symbol: '🤱🏻',
    tags_EN: 'breast-feeding, light-skin tone, white',
    tags_ES: 'mujer amamanta, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 578,
    symbol: '🤱🏼',
    tags_EN: 'breast-feeding, medium-light-skin tone, white',
    tags_ES: 'mujer amamanta, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 579,
    symbol: '🤱🏽',
    tags_EN: 'breast-feeding, medium-skin tone, white',
    tags_ES: 'mujer amamanta, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 580,
    symbol: '🤱🏾',
    tags_EN: 'breast-feeding, medium-dark-skin tone, black',
    tags_ES: 'mujer amamanta, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 581,
    symbol: '🤱🏿',
    tags_EN: 'breast-feeding, dark-skin-tone, black',
    tags_ES: 'mujer amamanta, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 582,
    symbol: '👼',
    tags_EN: 'baby angel, big eyes, yellow',
    tags_ES: 'bebé ángel, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 583,
    symbol: '👼🏻',
    tags_EN: 'baby angel, light-skin tone, white',
    tags_ES: 'bebé ángel, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 584,
    symbol: '👼🏼',
    tags_EN: 'baby angel, medium-light-skin tone, white',
    tags_ES: 'bebé ángel, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 585,
    symbol: '👼🏽',
    tags_EN: 'baby angel, medium-skin tone, white',
    tags_ES: 'bebé ángel, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 586,
    symbol: '👼🏾',
    tags_EN: 'baby angel, medium-dark-skin tone, black',
    tags_ES: 'bebé ángel, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 587,
    symbol: '👼🏿',
    tags_EN: 'baby angel, dark-skin-tone, black',
    tags_ES: 'bebé ángel, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 588,
    symbol: '🎅',
    tags_EN: 'santa claus, beard, yellow',
    tags_ES: 'papá noel, barba, amarillo',
    category: 'gente',
  },
  {
    id: 589,
    symbol: '🎅🏻',
    tags_EN: 'santa claus, light-skin tone, white',
    tags_ES: 'papá noel, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 590,
    symbol: '🎅🏼',
    tags_EN: 'santa claus, medium-light-skin tone, white',
    tags_ES: 'papá noel, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 591,
    symbol: '🎅🏽',
    tags_EN: 'santa claus, medium-skin tone, white',
    tags_ES: 'papá noel, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 592,
    symbol: '🎅🏾',
    tags_EN: 'santa claus, medium-dark-skin tone, black',
    tags_ES: 'papá noel, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 593,
    symbol: '🎅🏿',
    tags_EN: 'santa claus, dark-skin-tone, black',
    tags_ES: 'papá noel, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 594,
    symbol: '🤶',
    tags_EN: 'mrs. claus, big eyes, yellow',
    tags_ES: 'mamá noel, ojos grandes, amarillo',
    category: 'gente',
  },
  {
    id: 595,
    symbol: '🤶🏻',
    tags_EN: 'mrs. claus, light-skin tone, white',
    tags_ES: 'mamá noel, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 596,
    symbol: '🤶🏼',
    tags_EN: 'mrs. claus, medium-light-skin tone, white',
    tags_ES: 'mamá noel, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 597,
    symbol: '🤶🏽',
    tags_EN: 'mrs. claus, medium-skin tone, white',
    tags_ES: 'mamá noel, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 598,
    symbol: '🤶🏾',
    tags_EN: 'mrs. claus, medium-dark-skin tone, black',
    tags_ES: 'mamá noel, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 599,
    symbol: '🤶🏿',
    tags_EN: 'mrs. claus, dark-skin-tone, black',
    tags_ES: 'mamá noel, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 600,
    symbol: '🧙',
    tags_EN: 'mage, glasses, yellow',
    tags_ES: 'bruja, gafas, amarillo',
    category: 'gente',
  },
  {
    id: 601,
    symbol: '🧙🏻',
    tags_EN: 'mage, light-skin tone, white',
    tags_ES: 'bruja, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 602,
    symbol: '🧙🏼',
    tags_EN: 'mage, medium-light-skin tone, white',
    tags_ES: 'bruja, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 603,
    symbol: '🧙🏽',
    tags_EN: 'mage, medium-skin tone, white',
    tags_ES: 'bruja, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 604,
    symbol: '🧙🏾',
    tags_EN: 'mage, medium-dark-skin tone, black',
    tags_ES: 'bruja, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 605,
    symbol: '🧙🏿',
    tags_EN: 'mage, dark-skin-tone, black',
    tags_ES: 'bruja, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 606,
    symbol: '🧙‍♀️',
    tags_EN: 'woman mage, glasses, yellow',
    tags_ES: 'mujer bruja, gafas, amarillo',
    category: 'gente',
  },
  {
    id: 607,
    symbol: '🧙🏻‍♀️',
    tags_EN: 'woman mage, light-skin tone, white',
    tags_ES: 'mujer bruja, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 608,
    symbol: '🧙🏼‍♀️',
    tags_EN: 'woman mage, medium-light-skin tone, white',
    tags_ES: 'mujer bruja, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 609,
    symbol: '🧙🏽‍♀️',
    tags_EN: 'woman mage, medium-skin tone, white',
    tags_ES: 'mujer bruja, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 610,
    symbol: '🧙🏾‍♀️',
    tags_EN: 'woman mage, medium-dark-skin tone, black',
    tags_ES: 'mujer bruja, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 611,
    symbol: '🧙🏿‍♀️',
    tags_EN: 'woman mage, dark-skin-tone, black',
    tags_ES: 'mujer bruja, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 612,
    symbol: '🧙‍♂️',
    tags_EN: 'man mage, beard, yellow',
    tags_ES: 'hombre brujo, barba, amarillo',
    category: 'gente',
  },
  {
    id: 613,
    symbol: '🧙🏻‍♂️',
    tags_EN: 'man mage, light-skin tone, white',
    tags_ES: 'hombre brujo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 614,
    symbol: '🧙🏼‍♂️',
    tags_EN: 'man mage, medium-light-skin tone, white',
    tags_ES: 'hombre brujo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 615,
    symbol: '🧙🏽‍♂️',
    tags_EN: 'man mage, medium-skin tone, white',
    tags_ES: 'hombre brujo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 616,
    symbol: '🧙🏾‍♂️',
    tags_EN: 'man mage, medium-dark-skin tone, black',
    tags_ES: 'hombre brujo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 617,
    symbol: '🧙🏿‍♂️',
    tags_EN: 'man mage, dark-skin-tone, black',
    tags_ES: 'hombre brujo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 618,
    symbol: '🧚',
    tags_EN: 'fairy, wings, yellow',
    tags_ES: 'hada, alas, amarillo',
    category: 'gente',
  },
  {
    id: 619,
    symbol: '🧚🏻',
    tags_EN: 'fairy, light-skin tone, white',
    tags_ES: 'hada, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 620,
    symbol: '🧚🏼',
    tags_EN: 'fairy, medium-light-skin tone, white',
    tags_ES: 'hada, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 621,
    symbol: '🧚🏽',
    tags_EN: 'fairy, medium-skin tone, white',
    tags_ES: 'hada, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 622,
    symbol: '🧚🏾',
    tags_EN: 'fairy, medium-dark-skin tone, black',
    tags_ES: 'hada, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 623,
    symbol: '🧚🏿',
    tags_EN: 'fairy, dark-skin-tone, black',
    tags_ES: 'hada, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 624,
    symbol: '🧚‍♀️',
    tags_EN: 'woman fairy, wings, yellow',
    tags_ES: 'mujer hada, alas, amarillo',
    category: 'gente',
  },
  {
    id: 625,
    symbol: '🧚🏻‍♀️',
    tags_EN: 'woman fairy, light-skin tone, white',
    tags_ES: 'mujer hada, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 626,
    symbol: '🧚🏼‍♀️',
    tags_EN: 'woman fairy, medium-light-skin tone, white',
    tags_ES: 'mujer hada, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 627,
    symbol: '🧚🏽‍♀️',
    tags_EN: 'woman fairy, medium-skin tone, white',
    tags_ES: 'mujer hada, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 628,
    symbol: '🧚🏾‍♀️',
    tags_EN: 'woman fairy, medium-dark-skin tone, black',
    tags_ES: 'mujer hada, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 629,
    symbol: '🧚🏿‍♀️',
    tags_EN: 'woman fairy, dark-skin-tone, black',
    tags_ES: 'mujer hada, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 630,
    symbol: '🧚‍♂️',
    tags_EN: 'man fairy, wings, yellow',
    tags_ES: 'hombre hada, alas, amarillo',
    category: 'gente',
  },
  {
    id: 631,
    symbol: '🧚🏻‍♂️',
    tags_EN: 'man fairy, light-skin tone, white',
    tags_ES: 'hombre hada, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 632,
    symbol: '🧚🏼‍♂️',
    tags_EN: 'man fairy, medium-light-skin tone, white',
    tags_ES: 'hombre hada, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 633,
    symbol: '🧚🏽‍♂️',
    tags_EN: 'man fairy, medium-skin tone, white',
    tags_ES: 'hombre hada, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 634,
    symbol: '🧚🏾‍♂️',
    tags_EN: 'man fairy, medium-dark-skin tone, black',
    tags_ES: 'hombre hada, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 635,
    symbol: '🧚🏿‍♂️',
    tags_EN: 'man fairy, dark-skin-tone, black',
    tags_ES: 'hombre hada, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 636,
    symbol: '🧛',
    tags_EN: 'vampire, blood, yellow',
    tags_ES: 'vampiro, sangre, amarillo',
    category: 'gente',
  },
  {
    id: 637,
    symbol: '🧛🏻',
    tags_EN: 'vampire, light-skin tone, white',
    tags_ES: 'vampiro, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 638,
    symbol: '🧛🏼',
    tags_EN: 'vampire, medium-light-skin tone, white',
    tags_ES: 'vampiro, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 639,
    symbol: '🧛🏽',
    tags_EN: 'vampire, medium-skin tone, white',
    tags_ES: 'vampiro, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 640,
    symbol: '🧛🏾',
    tags_EN: 'vampire, medium-dark-skin tone, black',
    tags_ES: 'vampiro, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 641,
    symbol: '🧛🏿',
    tags_EN: 'vampire, dark-skin-tone, black',
    tags_ES: 'vampiro, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 642,
    symbol: '🧛‍♀️',
    tags_EN: 'woman vampire, blood, yellow',
    tags_ES: 'mujer vampiro, sangre, amarillo',
    category: 'gente',
  },
  {
    id: 643,
    symbol: '🧛🏻‍♀️',
    tags_EN: 'woman vampire, light-skin tone, white',
    tags_ES: 'mujer vampiro, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 644,
    symbol: '🧛🏼‍♀️',
    tags_EN: 'woman vampire, medium-light-skin tone, white',
    tags_ES: 'mujer vampiro, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 645,
    symbol: '🧛🏽‍♀️',
    tags_EN: 'woman vampire, medium-skin tone, white',
    tags_ES: 'mujer vampiro, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 646,
    symbol: '🧛🏾‍♀️',
    tags_EN: 'woman vampire, medium-dark-skin tone, black',
    tags_ES: 'mujer vampiro, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 647,
    symbol: '🧛🏿‍♀️',
    tags_EN: 'woman vampire, dark-skin-tone, black',
    tags_ES: 'mujer vampiro, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 648,
    symbol: '🧛‍♂️',
    tags_EN: 'man vampire, blood, yellow',
    tags_ES: 'hombre vampiro, sangre, amarillo',
    category: 'gente',
  },
  {
    id: 649,
    symbol: '🧛🏻‍♂️',
    tags_EN: 'man vampire, light-skin tone, white',
    tags_ES: 'hombre vampiro, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 650,
    symbol: '🧛🏼‍♂️',
    tags_EN: 'man vampire, medium-light-skin tone, white',
    tags_ES: 'hombre vampiro, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 651,
    symbol: '🧛🏽‍♂️',
    tags_EN: 'man vampire, medium-skin tone, white',
    tags_ES: 'hombre vampiro, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 652,
    symbol: '🧛🏾‍♂️',
    tags_EN: 'man vampire, medium-dark-skin tone, black',
    tags_ES: 'hombre vampiro, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 653,
    symbol: '🧛🏿‍♂️',
    tags_EN: 'man vampire, dark-skin-tone, black',
    tags_ES: 'hombre vampiro, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 654,
    symbol: '🧜',
    tags_EN: 'merperson, trident, yellow',
    tags_ES: 'sirena, tridente, amarillo',
    category: 'gente',
  },
  {
    id: 655,
    symbol: '🧜🏻',
    tags_EN: 'merperson, light-skin tone, white',
    tags_ES: 'sirena, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 656,
    symbol: '🧜🏼',
    tags_EN: 'merperson, medium-light-skin tone, white',
    tags_ES: 'sirena, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 657,
    symbol: '🧜🏽',
    tags_EN: 'merperson, medium-skin tone, white',
    tags_ES: 'sirena, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 658,
    symbol: '🧜🏾',
    tags_EN: 'merperson, medium-dark-skin tone, black',
    tags_ES: 'sirena, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 659,
    symbol: '🧜🏿',
    tags_EN: 'merperson, dark-skin-tone, black',
    tags_ES: 'sirena, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 660,
    symbol: '🧜‍♀️',
    tags_EN: 'mermaid, fish, yellow',
    tags_ES: 'sirena mujer, pez, amarillo',
    category: 'gente',
  },
  {
    id: 661,
    symbol: '🧜🏻‍♀️',
    tags_EN: 'mermaid, light-skin tone, white',
    tags_ES: 'sirena mujer, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 662,
    symbol: '🧜🏼‍♀️',
    tags_EN: 'mermaid, medium-light-skin tone, white',
    tags_ES: 'sirena mujer, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 663,
    symbol: '🧜🏽‍♀️',
    tags_EN: 'mermaid, medium-skin tone, white',
    tags_ES: 'sirena mujer, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 664,
    symbol: '🧜🏾‍♀️',
    tags_EN: 'mermaid, medium-dark-skin tone, black',
    tags_ES: 'sirena mujer, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 665,
    symbol: '🧜🏿‍♀️',
    tags_EN: 'mermaid, dark-skin-tone, black',
    tags_ES: 'sirena mujer, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 666,
    symbol: '🧜‍♂️',
    tags_EN: 'merman, trident, yellow',
    tags_ES: 'sirena hombre, tridente, amarillo',
    category: 'gente',
  },
  {
    id: 667,
    symbol: '🧜🏻‍♂️',
    tags_EN: 'merman, light-skin tone, white',
    tags_ES: 'sirena hombre, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 668,
    symbol: '🧜🏼‍♂️',
    tags_EN: 'merman, medium-light-skin tone, white',
    tags_ES: 'sirena hombre, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 669,
    symbol: '🧜🏽‍♂️',
    tags_EN: 'merman, medium-skin tone, white',
    tags_ES: 'sirena hombre, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 670,
    symbol: '🧜🏾‍♂️',
    tags_EN: 'merman, medium-dark-skin tone, black',
    tags_ES: 'sirena hombre, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 671,
    symbol: '🧜🏿‍♂️',
    tags_EN: 'merman, dark-skin-tone, black',
    tags_ES: 'sirena hombre, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 672,
    symbol: '🧝',
    tags_EN: 'elf, big ears, yellow',
    tags_ES: 'elfo, orejas grandes, amarillo',
    category: 'gente',
  },
  {
    id: 673,
    symbol: '🧝🏻',
    tags_EN: 'elf, light-skin tone, white',
    tags_ES: 'elfo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 674,
    symbol: '🧝🏼',
    tags_EN: 'elf, medium-light-skin tone, white',
    tags_ES: 'elfo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 675,
    symbol: '🧝🏽',
    tags_EN: 'elf, medium-skin tone, white',
    tags_ES: 'elfo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 676,
    symbol: '🧝🏾',
    tags_EN: 'elf, medium-dark-skin tone, black',
    tags_ES: 'elfo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 677,
    symbol: '🧝🏿',
    tags_EN: 'elf, dark-skin-tone, black',
    tags_ES: 'elfo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 678,
    symbol: '🧝‍♀️',
    tags_EN: 'woman elf, big ears, yellow',
    tags_ES: 'mujer elfo, orejas grandes, amarillo',
    category: 'gente',
  },
  {
    id: 679,
    symbol: '🧝🏻‍♀️',
    tags_EN: 'woman elf, light-skin tone, white',
    tags_ES: 'mujer elfo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 680,
    symbol: '🧝🏼‍♀️',
    tags_EN: 'woman elf, medium-light-skin tone, white',
    tags_ES: 'mujer elfo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 681,
    symbol: '🧝🏽‍♀️',
    tags_EN: 'woman elf, medium-skin tone, white',
    tags_ES: 'mujer elfo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 682,
    symbol: '🧝🏾‍♀️',
    tags_EN: 'woman elf, medium-dark-skin tone, black',
    tags_ES: 'mujer elfo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 683,
    symbol: '🧝🏿‍♀️',
    tags_EN: 'woman elf, dark-skin-tone, black',
    tags_ES: 'mujer elfo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 684,
    symbol: '🧝‍♂️',
    tags_EN: 'man elf, big ears, yellow',
    tags_ES: 'hombre elfo, orejas grandes, amarillo',
    category: 'gente',
  },
  {
    id: 685,
    symbol: '🧝🏻‍♂️',
    tags_EN: 'man elf, light-skin tone, white',
    tags_ES: 'hombre elfo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 686,
    symbol: '🧝🏼‍♂️',
    tags_EN: 'man elf, medium-light-skin tone, white',
    tags_ES: 'hombre elfo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 687,
    symbol: '🧝🏽‍♂️',
    tags_EN: 'man elf, medium-skin tone, white',
    tags_ES: 'hombre elfo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 688,
    symbol: '🧝🏾‍♂️',
    tags_EN: 'man elf, medium-dark-skin tone, black',
    tags_ES: 'hombre elfo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 689,
    symbol: '🧝🏿‍♂️',
    tags_EN: 'man elf, dark-skin-tone, black',
    tags_ES: 'hombre elfo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 690,
    symbol: '🧞',
    tags_EN: 'genie, blue, magic',
    tags_ES: 'genio, azul, mágico',
    category: 'gente',
  },
  {
    id: 691,
    symbol: '🧞‍♀️',
    tags_EN: 'woman genie, pink, magic',
    tags_ES: 'mujer genio, rosa, mágico',
    category: 'gente',
  },
  {
    id: 692,
    symbol: '🧞‍♂️',
    tags_EN: 'man genie, blue, magic',
    tags_ES: 'hombre genio, azul, mágico',
    category: 'gente',
  },
  {
    id: 693,
    symbol: '🧟',
    tags_EN: 'zombie, green, dead',
    tags_ES: 'zombi, verde, muerte',
    category: 'gente',
  },
  {
    id: 694,
    symbol: '🧟‍♀️',
    tags_EN: 'woman zombie, green, dead',
    tags_ES: 'mujer zombi, verde, muerte',
    category: 'gente',
  },
  {
    id: 695,
    symbol: '🧟‍♂️',
    tags_EN: 'man zombie, green, dead',
    tags_ES: 'hombre zombi, verde, muerte',
    category: 'gente',
  },
  {
    id: 696,
    symbol: '🙍',
    tags_EN: 'person frowning, angry, yellow',
    tags_ES: 'persona frunciendo ceño, enfadado, amarillo',
    category: 'gente',
  },
  {
    id: 697,
    symbol: '🙍🏻',
    tags_EN: 'person frowning, light-skin tone, white',
    tags_ES: 'persona frunciendo ceño, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 698,
    symbol: '🙍🏼',
    tags_EN: 'person frowning, medium-light-skin tone, white',
    tags_ES: 'persona frunciendo ceño, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 699,
    symbol: '🙍🏽',
    tags_EN: 'person frowning, medium-skin tone, white',
    tags_ES: 'persona frunciendo ceño, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 700,
    symbol: '🙍🏾',
    tags_EN: 'person frowning, medium-dark-skin tone, black',
    tags_ES: 'persona frunciendo ceño, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 701,
    symbol: '🙍🏿',
    tags_EN: 'person frowning, dark-skin-tone, black',
    tags_ES: 'persona frunciendo ceño, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 702,
    symbol: '🙍‍♂️',
    tags_EN: 'man frowning, angry, yellow',
    tags_ES: 'hombre frunciendo ceño, enfadado, amarillo',
    category: 'gente',
  },
  {
    id: 703,
    symbol: '🙍🏻‍♂️',
    tags_EN: 'man frowning, light-skin tone, white',
    tags_ES: 'hombre frunciendo ceño, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 704,
    symbol: '🙍🏼‍♂️',
    tags_EN: 'man frowning, medium-light-skin tone, white',
    tags_ES: 'hombre frunciendo ceño, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 705,
    symbol: '🙍🏽‍♂️',
    tags_EN: 'man frowning, medium-skin tone, white',
    tags_ES: 'hombre frunciendo ceño, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 706,
    symbol: '🙍🏾‍♂️',
    tags_EN: 'man frowning, medium-dark-skin tone, black',
    tags_ES: 'hombre frunciendo ceño, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 707,
    symbol: '🙍🏿‍♂️',
    tags_EN: 'man frowning, dark-skin-tone, black',
    tags_ES: 'hombre frunciendo ceño, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 708,
    symbol: '🙍‍♀️',
    tags_EN: 'woman frowning, angry, yellow',
    tags_ES: 'mujer frunciendo ceño, enfadada, amarillo',
    category: 'gente',
  },
  {
    id: 709,
    symbol: '🙍🏻‍♀️',
    tags_EN: 'woman frowning, light-skin tone, white',
    tags_ES: 'mujer frunciendo ceño, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 710,
    symbol: '🙍🏼‍♀️',
    tags_EN: 'woman frowning, medium-light-skin tone, white',
    tags_ES: 'mujer frunciendo ceño, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 711,
    symbol: '🙍🏽‍♀️',
    tags_EN: 'woman frowning, medium-skin tone, white',
    tags_ES: 'mujer frunciendo ceño, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 712,
    symbol: '🙍🏾‍♀️',
    tags_EN: 'woman frowning, medium-dark-skin tone, black',
    tags_ES: 'mujer frunciendo ceño, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 713,
    symbol: '🙍🏿‍♀️',
    tags_EN: 'woman frowning, dark-skin-tone, black',
    tags_ES: 'mujer frunciendo ceño, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 714,
    symbol: '🙎',
    tags_EN: 'person pouting, sad, yellow',
    tags_ES: 'persona haciendo pucheros, triste, amarillo',
    category: 'gente',
  },
  {
    id: 715,
    symbol: '🙎🏻',
    tags_EN: 'person pouting, light-skin tone, white',
    tags_ES: 'persona haciendo pucheros, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 716,
    symbol: '🙎🏼',
    tags_EN: 'person pouting, medium-light-skin tone, white',
    tags_ES: 'persona haciendo pucheros, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 717,
    symbol: '🙎🏽',
    tags_EN: 'person pouting, medium-skin tone, white',
    tags_ES: 'persona haciendo pucheros, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 718,
    symbol: '🙎🏾',
    tags_EN: 'person pouting, medium-dark-skin tone, black',
    tags_ES: 'persona haciendo pucheros, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 719,
    symbol: '🙎🏿',
    tags_EN: 'person pouting, dark-skin-tone, black',
    tags_ES: 'persona haciendo pucheros, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 720,
    symbol: '🙎‍♂️',
    tags_EN: 'man pouting, sad, yellow',
    tags_ES: 'hombre haciendo pucheros, triste, amarillo',
    category: 'gente',
  },
  {
    id: 721,
    symbol: '🙎🏻‍♂️',
    tags_EN: 'man pouting, light-skin tone, white',
    tags_ES: 'hombre haciendo pucheros, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 722,
    symbol: '🙎🏼‍♂️',
    tags_EN: 'man pouting, medium-light-skin tone, white',
    tags_ES: 'hombre haciendo pucheros, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 723,
    symbol: '🙎🏽‍♂️',
    tags_EN: 'man pouting, medium-skin tone, white',
    tags_ES: 'hombre haciendo pucheros, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 724,
    symbol: '🙎🏾‍♂️',
    tags_EN: 'man pouting, medium-dark-skin tone, black',
    tags_ES: 'hombre haciendo pucheros, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 725,
    symbol: '🙎🏿‍♂️',
    tags_EN: 'man pouting, dark-skin-tone, black',
    tags_ES: 'hombre haciendo pucheros, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 726,
    symbol: '🙎‍♀️',
    tags_EN: 'woman pouting, sad, yellow',
    tags_ES: 'mujer haciendo pucheros, triste, amarillo',
    category: 'gente',
  },
  {
    id: 727,
    symbol: '🙎🏻‍♀️',
    tags_EN: 'woman pouting, light-skin tone, white',
    tags_ES: 'mujer haciendo pucheros, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 728,
    symbol: '🙎🏼‍♀️',
    tags_EN: 'woman pouting, medium-light-skin tone, white',
    tags_ES: 'mujer haciendo pucheros, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 729,
    symbol: '🙎🏽‍♀️',
    tags_EN: 'woman pouting, medium-skin tone, white',
    tags_ES: 'mujer haciendo pucheros, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 730,
    symbol: '🙎🏾‍♀️',
    tags_EN: 'woman pouting, medium-dark-skin tone, black',
    tags_ES: 'mujer haciendo pucheros, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 731,
    symbol: '🙎🏿‍♀️',
    tags_EN: 'woman pouting, dark-skin-tone, black',
    tags_ES: 'mujer haciendo pucheros, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 732,
    symbol: '🙅',
    tags_EN: 'person gesturing no, no, yellow',
    tags_ES: 'persona gesticulando no, no, amarillo',
    category: 'gente',
  },
  {
    id: 733,
    symbol: '🙅🏻',
    tags_EN: 'person gesturing no, light-skin tone, white',
    tags_ES: 'persona gesticulando no, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 734,
    symbol: '🙅🏼',
    tags_EN: 'person gesturing no, medium-light-skin tone, white',
    tags_ES: 'persona gesticulando no, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 735,
    symbol: '🙅🏽',
    tags_EN: 'person gesturing no, medium-skin tone, white',
    tags_ES: 'persona gesticulando no, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 736,
    symbol: '🙅🏾',
    tags_EN: 'person gesturing no, medium-dark-skin tone, black',
    tags_ES: 'persona gesticulando no, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 737,
    symbol: '🙅🏿',
    tags_EN: 'person gesturing no, dark-skin-tone, black',
    tags_ES: 'persona gesticulando no, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 738,
    symbol: '🙅‍♂️',
    tags_EN: 'man gesturing no, no, yellow',
    tags_ES: 'hombre gesticulando no, no, amarillo',
    category: 'gente',
  },
  {
    id: 739,
    symbol: '🙅🏻‍♂️',
    tags_EN: 'man gesturing no, light-skin tone, white',
    tags_ES: 'hombre gesticulando no, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 740,
    symbol: '🙅🏼‍♂️',
    tags_EN: 'man gesturing no, medium-light-skin tone, white',
    tags_ES: 'hombre gesticulando no, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 741,
    symbol: '🙅🏽‍♂️',
    tags_EN: 'man gesturing no, medium-skin tone, white',
    tags_ES: 'hombre gesticulando no, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 742,
    symbol: '🙅🏾‍♂️',
    tags_EN: 'man gesturing no, medium-dark-skin tone, black',
    tags_ES: 'hombre gesticulando no, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 743,
    symbol: '🙅🏿‍♂️',
    tags_EN: 'man gesturing no, dark-skin-tone, black',
    tags_ES: 'hombre gesticulando no, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 744,
    symbol: '🙅‍♀️',
    tags_EN: 'woman gesturing no, no, yellow',
    tags_ES: 'mujer gesticulando no, no, amarillo',
    category: 'gente',
  },
  {
    id: 745,
    symbol: '🙅🏻‍♀️',
    tags_EN: 'woman gesturing no, light-skin tone, white',
    tags_ES: 'mujer gesticulando no, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 746,
    symbol: '🙅🏼‍♀️',
    tags_EN: 'woman gesturing no, medium-light-skin tone, white',
    tags_ES: 'mujer gesticulando no, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 747,
    symbol: '🙅🏽‍♀️',
    tags_EN: 'woman gesturing no, medium-skin tone, white',
    tags_ES: 'mujer gesticulando no, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 748,
    symbol: '🙅🏾‍♀️',
    tags_EN: 'woman gesturing no, medium-dark-skin tone, black',
    tags_ES: 'mujer gesticulando no, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 749,
    symbol: '🙅🏿‍♀️',
    tags_EN: 'woman gesturing no, dark-skin-tone, black',
    tags_ES: 'mujer gesticulando no, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 750,
    symbol: '🙆',
    tags_EN: 'person gesturing ok, ok, yellow',
    tags_ES: 'persona gesticulando ok, ok, amarillo',
    category: 'gente',
  },
  {
    id: 751,
    symbol: '🙆🏻',
    tags_EN: 'person gesturing ok, light-skin tone, white',
    tags_ES: 'persona gesticulando ok, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 752,
    symbol: '🙆🏼',
    tags_EN: 'person gesturing ok, medium-light-skin tone, white',
    tags_ES: 'persona gesticulando ok, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 753,
    symbol: '🙆🏽',
    tags_EN: 'person gesturing ok, medium-skin tone, white',
    tags_ES: 'persona gesticulando ok, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 754,
    symbol: '🙆🏾',
    tags_EN: 'person gesturing ok, medium-dark-skin tone, black',
    tags_ES: 'persona gesticulando ok, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 755,
    symbol: '🙆🏿',
    tags_EN: 'person gesturing ok, dark-skin-tone, black',
    tags_ES: 'persona gesticulando ok, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 756,
    symbol: '🙆‍♂️',
    tags_EN: 'man gesturing ok, ok, yellow',
    tags_ES: 'hombre gesticulando ok, ok, amarillo',
    category: 'gente',
  },
  {
    id: 757,
    symbol: '🙆🏻‍♂️',
    tags_EN: 'man gesturing ok, light-skin tone, white',
    tags_ES: 'hombre gesticulando ok, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 758,
    symbol: '🙆🏼‍♂️',
    tags_EN: 'man gesturing ok, medium-light-skin tone, white',
    tags_ES: 'hombre gesticulando ok, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 759,
    symbol: '🙆🏽‍♂️',
    tags_EN: 'man gesturing ok, medium-skin tone, white',
    tags_ES: 'hombre gesticulando ok, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 760,
    symbol: '🙆🏾‍♂️',
    tags_EN: 'man gesturing ok, medium-dark-skin tone, black',
    tags_ES: 'hombre gesticulando ok, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 761,
    symbol: '🙆🏿‍♂️',
    tags_EN: 'man gesturing ok, dark-skin-tone, black',
    tags_ES: 'hombre gesticulando ok, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 762,
    symbol: '🙆‍♀️',
    tags_EN: 'woman gesturing ok, ok, yellow',
    tags_ES: 'mujer gesticulando ok, ok, amarillo',
    category: 'gente',
  },
  {
    id: 763,
    symbol: '🙆🏻‍♀️',
    tags_EN: 'woman gesturing ok, light-skin tone, white',
    tags_ES: 'mujer gesticulando ok, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 764,
    symbol: '🙆🏼‍♀️',
    tags_EN: 'woman gesturing ok, medium-light-skin tone, white',
    tags_ES: 'mujer gesticulando ok, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 765,
    symbol: '🙆🏽‍♀️',
    tags_EN: 'woman gesturing ok, medium-skin tone, white',
    tags_ES: 'mujer gesticulando ok, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 766,
    symbol: '🙆🏾‍♀️',
    tags_EN: 'woman gesturing ok, medium-dark-skin tone, black',
    tags_ES: 'mujer gesticulando ok, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 767,
    symbol: '🙆🏿‍♀️',
    tags_EN: 'woman gesturing ok, dark-skin-tone, black',
    tags_ES: 'mujer gesticulando ok, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 768,
    symbol: '💁',
    tags_EN: 'person tipping hand, cash, yellow',
    tags_ES: 'persona pidiendo propina, dinero, amarillo',
    category: 'gente',
  },
  {
    id: 769,
    symbol: '💁🏻',
    tags_EN: 'person tipping hand, light-skin tone, white',
    tags_ES: 'persona pidiendo propina, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 770,
    symbol: '💁🏼',
    tags_EN: 'person tipping hand, medium-light-skin tone, white',
    tags_ES: 'persona pidiendo propina, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 771,
    symbol: '💁🏽',
    tags_EN: 'person tipping hand, medium-skin tone, white',
    tags_ES: 'persona pidiendo propina, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 772,
    symbol: '💁🏾',
    tags_EN: 'person tipping hand, medium-dark-skin tone, black',
    tags_ES: 'persona pidiendo propina, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 773,
    symbol: '💁🏿',
    tags_EN: 'person tipping hand, dark-skin-tone, black',
    tags_ES: 'persona pidiendo propina, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 774,
    symbol: '💁‍♂️',
    tags_EN: 'man tipping hand, cash, yellow',
    tags_ES: 'hombre pidiendo propina, dinero, amarillo',
    category: 'gente',
  },
  {
    id: 775,
    symbol: '💁🏻‍♂️',
    tags_EN: 'man tipping hand, light-skin tone, white',
    tags_ES: 'hombre pidiendo propina, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 776,
    symbol: '💁🏼‍♂️',
    tags_EN: 'man tipping hand, medium-light-skin tone, white',
    tags_ES: 'hombre pidiendo propina, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 777,
    symbol: '💁🏽‍♂️',
    tags_EN: 'man tipping hand, medium-skin tone, white',
    tags_ES: 'hombre pidiendo propina, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 778,
    symbol: '💁🏾‍♂️',
    tags_EN: 'man tipping hand, medium-dark-skin tone, black',
    tags_ES: 'hombre pidiendo propina, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 779,
    symbol: '💁🏿‍♂️',
    tags_EN: 'man tipping hand, dark-skin-tone, black',
    tags_ES: 'hombre pidiendo propina, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 780,
    symbol: '💁‍♀️',
    tags_EN: 'woman tipping hand, cash, yellow',
    tags_ES: 'mujer pidiendo propina, dinero, amarillo',
    category: 'gente',
  },
  {
    id: 781,
    symbol: '💁🏻‍♀️',
    tags_EN: 'woman tipping hand, light-skin tone, white',
    tags_ES: 'mujer pidiendo propina, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 782,
    symbol: '💁🏼‍♀️',
    tags_EN: 'woman tipping hand, medium-light-skin tone, white',
    tags_ES: 'mujer pidiendo propina, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 783,
    symbol: '💁🏽‍♀️',
    tags_EN: 'woman tipping hand, medium-skin tone, white',
    tags_ES: 'mujer pidiendo propina, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 784,
    symbol: '💁🏾‍♀️',
    tags_EN: 'woman tipping hand, medium-dark-skin tone, black',
    tags_ES: 'mujer pidiendo propina, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 785,
    symbol: '💁🏿‍♀️',
    tags_EN: 'woman tipping hand, dark-skin-tone, black',
    tags_ES: 'mujer pidiendo propina, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 786,
    symbol: '🙋',
    tags_EN: 'person raising hand, me, yellow',
    tags_ES: 'persona levantando mano, yo, amarillo',
    category: 'gente',
  },
  {
    id: 787,
    symbol: '🙋🏻',
    tags_EN: 'person raising hand, light-skin tone, white',
    tags_ES: 'persona levantando mano, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 788,
    symbol: '🙋🏼',
    tags_EN: 'person raising hand, medium-light-skin tone, white',
    tags_ES: 'persona levantando mano, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 789,
    symbol: '🙋🏽',
    tags_EN: 'person raising hand, medium-skin tone, white',
    tags_ES: 'persona levantando mano, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 790,
    symbol: '🙋🏾',
    tags_EN: 'person raising hand, medium-dark-skin tone, black',
    tags_ES: 'persona levantando mano, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 791,
    symbol: '🙋🏿',
    tags_EN: 'person raising hand, dark-skin-tone, black',
    tags_ES: 'persona levantando mano, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 792,
    symbol: '🙋‍♂️',
    tags_EN: 'man raising hand, me, yellow',
    tags_ES: 'hombre levantando mano, yo, amarillo',
    category: 'gente',
  },
  {
    id: 793,
    symbol: '🙋🏻‍♂️',
    tags_EN: 'man raising hand, light-skin tone, white',
    tags_ES: 'hombre levantando mano, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 794,
    symbol: '🙋🏼‍♂️',
    tags_EN: 'man raising hand, medium-light-skin tone, white',
    tags_ES: 'hombre levantando mano, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 795,
    symbol: '🙋🏽‍♂️',
    tags_EN: 'man raising hand, medium-skin tone, white',
    tags_ES: 'hombre levantando mano, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 796,
    symbol: '🙋🏾‍♂️',
    tags_EN: 'man raising hand, medium-dark-skin tone, black',
    tags_ES: 'hombre levantando mano, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 797,
    symbol: '🙋🏿‍♂️',
    tags_EN: 'man raising hand, dark-skin-tone, black',
    tags_ES: 'hombre levantando mano, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 798,
    symbol: '🙋‍♀️',
    tags_EN: 'woman raising hand, me, yellow',
    tags_ES: 'mujer levantando mano, yo, amarillo',
    category: 'gente',
  },
  {
    id: 799,
    symbol: '🙋🏻‍♀️',
    tags_EN: 'woman raising hand, light-skin tone, white',
    tags_ES: 'mujer levantando mano, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 800,
    symbol: '🙋🏼‍♀️',
    tags_EN: 'woman raising hand, medium-light-skin tone, white',
    tags_ES: 'mujer levantando mano, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 801,
    symbol: '🙋🏽‍♀️',
    tags_EN: 'woman raising hand, medium-skin tone, white',
    tags_ES: 'mujer levantando mano, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 802,
    symbol: '🙋🏾‍♀️',
    tags_EN: 'woman raising hand, medium-dark-skin tone, black',
    tags_ES: 'mujer levantando mano, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 803,
    symbol: '🙋🏿‍♀️',
    tags_EN: 'woman raising hand, dark-skin-tone, black',
    tags_ES: 'mujer levantando mano, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 804,
    symbol: '🙇',
    tags_EN: 'person bowing, devote, yellow',
    tags_ES: 'persona haciendo reverencia, devoto, amarillo',
    category: 'gente',
  },
  {
    id: 805,
    symbol: '🙇🏻',
    tags_EN: 'person bowing, light-skin tone, white',
    tags_ES: 'persona haciendo reverencia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 806,
    symbol: '🙇🏼',
    tags_EN: 'person bowing, medium-light-skin tone, white',
    tags_ES: 'persona haciendo reverencia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 807,
    symbol: '🙇🏽',
    tags_EN: 'person bowing, medium-skin tone, white',
    tags_ES: 'persona haciendo reverencia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 808,
    symbol: '🙇🏾',
    tags_EN: 'person bowing, medium-dark-skin tone, black',
    tags_ES: 'persona haciendo reverencia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 809,
    symbol: '🙇🏿',
    tags_EN: 'person bowing, dark-skin-tone, black',
    tags_ES: 'persona haciendo reverencia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 810,
    symbol: '🙇‍♂️',
    tags_EN: 'man bowing, devote, yellow',
    tags_ES: 'hombre haciendo reverencia, devoto, amarillo',
    category: 'gente',
  },
  {
    id: 811,
    symbol: '🙇🏻‍♂️',
    tags_EN: 'man bowing, light-skin tone, white',
    tags_ES: 'hombre haciendo reverencia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 812,
    symbol: '🙇🏼‍♂️',
    tags_EN: 'man bowing, medium-light-skin tone, white',
    tags_ES: 'hombre haciendo reverencia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 813,
    symbol: '🙇🏽‍♂️',
    tags_EN: 'man bowing, medium-skin tone, white',
    tags_ES: 'hombre haciendo reverencia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 814,
    symbol: '🙇🏾‍♂️',
    tags_EN: 'man bowing, medium-dark-skin tone, black',
    tags_ES: 'hombre haciendo reverencia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 815,
    symbol: '🙇🏿‍♂️',
    tags_EN: 'man bowing, dark-skin-tone, black',
    tags_ES: 'hombre haciendo reverencia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 816,
    symbol: '🙇‍♀️',
    tags_EN: 'woman bowing, devote, yellow',
    tags_ES: 'mujer haciendo reverencia, devota, amarillo',
    category: 'gente',
  },
  {
    id: 817,
    symbol: '🙇🏻‍♀️',
    tags_EN: 'woman bowing, light-skin tone, white',
    tags_ES: 'mujer haciendo reverencia, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 818,
    symbol: '🙇🏼‍♀️',
    tags_EN: 'woman bowing, medium-light-skin tone, white',
    tags_ES: 'mujer haciendo reverencia, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 819,
    symbol: '🙇🏽‍♀️',
    tags_EN: 'woman bowing, medium-skin tone, white',
    tags_ES: 'mujer haciendo reverencia, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 820,
    symbol: '🙇🏾‍♀️',
    tags_EN: 'woman bowing, medium-dark-skin tone, black',
    tags_ES: 'mujer haciendo reverencia, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 821,
    symbol: '🙇🏿‍♀️',
    tags_EN: 'woman bowing, dark-skin-tone, black',
    tags_ES: 'mujer haciendo reverencia, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 822,
    symbol: '🤦',
    tags_EN: 'person facepalming, shame, yellow',
    tags_ES: 'persona tapándose cara, vergüenza, amarillo',
    category: 'gente',
  },
  {
    id: 823,
    symbol: '🤦🏻',
    tags_EN: 'person facepalming, light-skin tone, white',
    tags_ES: 'persona tapándose cara, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 824,
    symbol: '🤦🏼',
    tags_EN: 'person facepalming, medium-light-skin tone, white',
    tags_ES: 'persona tapándose cara, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 825,
    symbol: '🤦🏽',
    tags_EN: 'person facepalming, medium-skin tone, white',
    tags_ES: 'persona tapándose cara, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 826,
    symbol: '🤦🏾',
    tags_EN: 'person facepalming, medium-dark-skin tone, black',
    tags_ES: 'persona tapándose cara, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 827,
    symbol: '🤦🏿',
    tags_EN: 'person facepalming, dark-skin-tone, black',
    tags_ES: 'persona tapándose cara, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 828,
    symbol: '🤦‍♂️',
    tags_EN: 'man facepalming, shame, yellow',
    tags_ES: 'hombre tapándose cara, vergüenza, amarillo',
    category: 'gente',
  },
  {
    id: 829,
    symbol: '🤦🏻‍♂️',
    tags_EN: 'man facepalming, light-skin tone, white',
    tags_ES: 'hombre tapándose cara, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 830,
    symbol: '🤦🏼‍♂️',
    tags_EN: 'man facepalming, medium-light-skin tone, white',
    tags_ES: 'hombre tapándose cara, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 831,
    symbol: '🤦🏽‍♂️',
    tags_EN: 'man facepalming, medium-skin tone, white',
    tags_ES: 'hombre tapándose cara, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 832,
    symbol: '🤦🏾‍♂️',
    tags_EN: 'man facepalming, medium-dark-skin tone, black',
    tags_ES: 'hombre tapándose cara, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 833,
    symbol: '🤦🏿‍♂️',
    tags_EN: 'man facepalming, dark-skin-tone, black',
    tags_ES: 'hombre tapándose cara, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 834,
    symbol: '🤦‍♀️',
    tags_EN: 'woman facepalming, shame, yellow',
    tags_ES: 'mujer tapándose cara, vergüenza, amarillo',
    category: 'gente',
  },
  {
    id: 835,
    symbol: '🤦🏻‍♀️',
    tags_EN: 'woman facepalming, light-skin tone, white',
    tags_ES: 'mujer tapándose cara, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 836,
    symbol: '🤦🏼‍♀️',
    tags_EN: 'woman facepalming, medium-light-skin tone, white',
    tags_ES: 'mujer tapándose cara, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 837,
    symbol: '🤦🏽‍♀️',
    tags_EN: 'woman facepalming, medium-skin tone, white',
    tags_ES: 'mujer tapándose cara, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 838,
    symbol: '🤦🏾‍♀️',
    tags_EN: 'woman facepalming, medium-dark-skin tone, black',
    tags_ES: 'mujer tapándose cara, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 839,
    symbol: '🤦🏿‍♀️',
    tags_EN: 'woman facepalming, dark-skin-tone, black',
    tags_ES: 'mujer tapándose cara, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 840,
    symbol: '🤷',
    tags_EN: 'person shrugging, confused, yellow',
    tags_ES: 'persona ecogiéndose hombros, confundido, amarillo',
    category: 'gente',
  },
  {
    id: 841,
    symbol: '🤷🏻',
    tags_EN: 'person shrugging, light-skin tone, white',
    tags_ES: 'persona ecogiéndose hombros, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 842,
    symbol: '🤷🏼',
    tags_EN: 'person shrugging, medium-light-skin tone, white',
    tags_ES: 'persona ecogiéndose hombros, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 843,
    symbol: '🤷🏽',
    tags_EN: 'person shrugging, medium-skin tone, white',
    tags_ES: 'persona ecogiéndose hombros, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 844,
    symbol: '🤷🏾',
    tags_EN: 'person shrugging, medium-dark-skin tone, black',
    tags_ES: 'persona ecogiéndose hombros, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 845,
    symbol: '🤷🏿',
    tags_EN: 'person shrugging, dark-skin-tone, black',
    tags_ES: 'persona ecogiéndose hombros, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 846,
    symbol: '🤷‍♂️',
    tags_EN: 'man shrugging, confused, yellow',
    tags_ES: 'hombre encogiéndose hombros, confundido, amarillo',
    category: 'gente',
  },
  {
    id: 847,
    symbol: '🤷🏻‍♂️',
    tags_EN: 'man shrugging, light-skin tone, white',
    tags_ES: 'hombre encogiéndose hombros, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 848,
    symbol: '🤷🏼‍♂️',
    tags_EN: 'man shrugging, medium-light-skin tone, white',
    tags_ES: 'hombre encogiéndose hombros, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 849,
    symbol: '🤷🏽‍♂️',
    tags_EN: 'man shrugging, medium-skin tone, white',
    tags_ES: 'hombre encogiéndose hombros, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 850,
    symbol: '🤷🏾‍♂️',
    tags_EN: 'man shrugging, medium-dark-skin tone, black',
    tags_ES: 'hombre encogiéndose hombros, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 851,
    symbol: '🤷🏿‍♂️',
    tags_EN: 'man shrugging, dark-skin-tone, black',
    tags_ES: 'hombre encogiéndose hombros, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 852,
    symbol: '🤷‍♀️',
    tags_EN: 'woman shrugging, confused, yellow',
    tags_ES: 'mujer encogiéndose mujer, confundido, amarillo',
    category: 'gente',
  },
  {
    id: 853,
    symbol: '🤷🏻‍♀️',
    tags_EN: 'woman shrugging, light-skin tone, white',
    tags_ES: 'mujer encogiéndose mujer, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 854,
    symbol: '🤷🏼‍♀️',
    tags_EN: 'woman shrugging, medium-light-skin tone, white',
    tags_ES: 'mujer encogiéndose mujer, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 855,
    symbol: '🤷🏽‍♀️',
    tags_EN: 'woman shrugging, medium-skin tone, white',
    tags_ES: 'mujer encogiéndose mujer, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 856,
    symbol: '🤷🏾‍♀️',
    tags_EN: 'woman shrugging, medium-dark-skin tone, black',
    tags_ES: 'mujer encogiéndose mujer, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 857,
    symbol: '🤷🏿‍♀️',
    tags_EN: 'woman shrugging, dark-skin-tone, black',
    tags_ES: 'mujer encogiéndose mujer, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 858,
    symbol: '💆',
    tags_EN: 'person getting massage, relaxed, yellow',
    tags_ES: 'persona recibiendo masaje, relajado, amarillo',
    category: 'gente',
  },
  {
    id: 859,
    symbol: '💆🏻',
    tags_EN: 'person getting massage, light-skin tone, white',
    tags_ES: 'persona recibiendo masaje, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 860,
    symbol: '💆🏼',
    tags_EN: 'person getting massage, medium-light-skin tone, white',
    tags_ES: 'persona recibiendo masaje, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 861,
    symbol: '💆🏽',
    tags_EN: 'person getting massage, medium-skin tone, white',
    tags_ES: 'persona recibiendo masaje, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 862,
    symbol: '💆🏾',
    tags_EN: 'person getting massage, medium-dark-skin tone, black',
    tags_ES: 'persona recibiendo masaje, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 863,
    symbol: '💆🏿',
    tags_EN: 'person getting massage, dark-skin-tone, black',
    tags_ES: 'persona recibiendo masaje, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 864,
    symbol: '💆‍♂️',
    tags_EN: 'man getting massage, relaxed, yellow',
    tags_ES: 'hombre recibiendo masaje, relajado, amarillo',
    category: 'gente',
  },
  {
    id: 865,
    symbol: '💆🏻‍♂️',
    tags_EN: 'man getting massage, light-skin tone, white',
    tags_ES: 'hombre recibiendo masaje, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 866,
    symbol: '💆🏼‍♂️',
    tags_EN: 'man getting massage, medium-light-skin tone, white',
    tags_ES: 'hombre recibiendo masaje, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 867,
    symbol: '💆🏽‍♂️',
    tags_EN: 'man getting massage, medium-skin tone, white',
    tags_ES: 'hombre recibiendo masaje, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 868,
    symbol: '💆🏾‍♂️',
    tags_EN: 'man getting massage, medium-dark-skin tone, black',
    tags_ES: 'hombre recibiendo masaje, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 869,
    symbol: '💆🏿‍♂️',
    tags_EN: 'man getting massage, dark-skin-tone, black',
    tags_ES: 'hombre recibiendo masaje, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 870,
    symbol: '💆‍♀️',
    tags_EN: 'woman getting massage, relaxed, yellow',
    tags_ES: 'mujer recibiendo masaje, relajado, amarillo',
    category: 'gente',
  },
  {
    id: 871,
    symbol: '💆🏻‍♀️',
    tags_EN: 'woman getting massage, light-skin tone, white',
    tags_ES: 'mujer recibiendo masaje, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 872,
    symbol: '💆🏼‍♀️',
    tags_EN: 'woman getting massage, medium-light-skin tone, white',
    tags_ES: 'mujer recibiendo masaje, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 873,
    symbol: '💆🏽‍♀️',
    tags_EN: 'woman getting massage, medium-skin tone, white',
    tags_ES: 'mujer recibiendo masaje, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 874,
    symbol: '💆🏾‍♀️',
    tags_EN: 'woman getting massage, medium-dark-skin tone, black',
    tags_ES: 'mujer recibiendo masaje, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 875,
    symbol: '💆🏿‍♀️',
    tags_EN: 'woman getting massage, dark-skin-tone, black',
    tags_ES: 'mujer recibiendo masaje, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 876,
    symbol: '💇',
    tags_EN: 'person getting haircut, beauty, yellow',
    tags_ES: 'persona cortándose pelo, belleza, amarillo',
    category: 'gente',
  },
  {
    id: 877,
    symbol: '💇🏻',
    tags_EN: 'person getting haircut, light-skin tone, white',
    tags_ES: 'persona cortándose pelo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 878,
    symbol: '💇🏼',
    tags_EN: 'person getting haircut, medium-light-skin tone, white',
    tags_ES: 'persona cortándose pelo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 879,
    symbol: '💇🏽',
    tags_EN: 'person getting haircut, medium-skin tone, white',
    tags_ES: 'persona cortándose pelo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 880,
    symbol: '💇🏾',
    tags_EN: 'person getting haircut, medium-dark-skin tone, black',
    tags_ES: 'persona cortándose pelo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 881,
    symbol: '💇🏿',
    tags_EN: 'person getting haircut, dark-skin-tone, black',
    tags_ES: 'persona cortándose pelo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 882,
    symbol: '💇‍♂️',
    tags_EN: 'man getting haircut, beauty, yellow',
    tags_ES: 'hombre cortándose pelo, belleza, amarillo',
    category: 'gente',
  },
  {
    id: 883,
    symbol: '💇🏻‍♂️',
    tags_EN: 'man getting haircut, light-skin tone, white',
    tags_ES: 'hombre cortándose pelo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 884,
    symbol: '💇🏼‍♂️',
    tags_EN: 'man getting haircut, medium-light-skin tone, white',
    tags_ES: 'hombre cortándose pelo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 885,
    symbol: '💇🏽‍♂️',
    tags_EN: 'man getting haircut, medium-skin tone, white',
    tags_ES: 'hombre cortándose pelo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 886,
    symbol: '💇🏾‍♂️',
    tags_EN: 'man getting haircut, medium-dark-skin tone, black',
    tags_ES: 'hombre cortándose pelo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 887,
    symbol: '💇🏿‍♂️',
    tags_EN: 'man getting haircut, dark-skin-tone, black',
    tags_ES: 'hombre cortándose pelo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 888,
    symbol: '💇‍♀️',
    tags_EN: 'woman getting haircut, beauty, yellow',
    tags_ES: 'mujer cortándose pelo, belleza, amarillo',
    category: 'gente',
  },
  {
    id: 889,
    symbol: '💇🏻‍♀️',
    tags_EN: 'woman getting haircut, light-skin tone, white',
    tags_ES: 'mujer cortándose pelo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 890,
    symbol: '💇🏼‍♀️',
    tags_EN: 'woman getting haircut, medium-light-skin tone, white',
    tags_ES: 'mujer cortándose pelo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 891,
    symbol: '💇🏽‍♀️',
    tags_EN: 'woman getting haircut, medium-skin tone, white',
    tags_ES: 'mujer cortándose pelo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 892,
    symbol: '💇🏾‍♀️',
    tags_EN: 'woman getting haircut, medium-dark-skin tone, black',
    tags_ES: 'mujer cortándose pelo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 893,
    symbol: '💇🏿‍♀️',
    tags_EN: 'woman getting haircut, dark-skin-tone, black',
    tags_ES: 'mujer cortándose pelo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 894,
    symbol: '🚶',
    tags_EN: 'person walking, walk, yellow',
    tags_ES: 'persona caminando, paseo, amarillo',
    category: 'gente',
  },
  {
    id: 895,
    symbol: '🚶🏻',
    tags_EN: 'person walking, light-skin tone, white',
    tags_ES: 'persona caminando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 896,
    symbol: '🚶🏼',
    tags_EN: 'person walking, medium-light-skin tone, white',
    tags_ES: 'persona caminando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 897,
    symbol: '🚶🏽',
    tags_EN: 'person walking, medium-skin tone, white',
    tags_ES: 'persona caminando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 898,
    symbol: '🚶🏾',
    tags_EN: 'person walking, medium-dark-skin tone, black',
    tags_ES: 'persona caminando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 899,
    symbol: '🚶🏿',
    tags_EN: 'person walking, dark-skin-tone, black',
    tags_ES: 'persona caminando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 900,
    symbol: '🚶‍♂️',
    tags_EN: 'man walking, walk, yellow',
    tags_ES: 'hombre caminando, paseo, amarillo',
    category: 'gente',
  },
  {
    id: 901,
    symbol: '🚶🏻‍♂️',
    tags_EN: 'man walking, light-skin tone, white',
    tags_ES: 'hombre caminando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 902,
    symbol: '🚶🏼‍♂️',
    tags_EN: 'man walking, medium-light-skin tone, white',
    tags_ES: 'hombre caminando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 903,
    symbol: '🚶🏽‍♂️',
    tags_EN: 'man walking, medium-skin tone, white',
    tags_ES: 'hombre caminando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 904,
    symbol: '🚶🏾‍♂️',
    tags_EN: 'man walking, medium-dark-skin tone, black',
    tags_ES: 'hombre caminando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 905,
    symbol: '🚶🏿‍♂️',
    tags_EN: 'man walking, dark-skin-tone, black',
    tags_ES: 'hombre caminando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 906,
    symbol: '🚶‍♀️',
    tags_EN: 'woman walking, walk, yellow',
    tags_ES: 'mujer caminando, paseo, amarillo',
    category: 'gente',
  },
  {
    id: 907,
    symbol: '🚶🏻‍♀️',
    tags_EN: 'woman walking, light-skin tone, white',
    tags_ES: 'mujer caminando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 908,
    symbol: '🚶🏼‍♀️',
    tags_EN: 'woman walking, medium-light-skin tone, white',
    tags_ES: 'mujer caminando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 909,
    symbol: '🚶🏽‍♀️',
    tags_EN: 'woman walking, medium-skin tone, white',
    tags_ES: 'mujer caminando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 910,
    symbol: '🚶🏾‍♀️',
    tags_EN: 'woman walking, medium-dark-skin tone, black',
    tags_ES: 'mujer caminando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 911,
    symbol: '🚶🏿‍♀️',
    tags_EN: 'woman walking, dark-skin-tone, black',
    tags_ES: 'mujer caminando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 912,
    symbol: '🏃',
    tags_EN: 'person running, run, yellow',
    tags_ES: 'persona corriendo, correr, amarillo',
    category: 'gente',
  },
  {
    id: 913,
    symbol: '🏃🏻',
    tags_EN: 'person running, light-skin tone, white',
    tags_ES: 'persona corriendo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 914,
    symbol: '🏃🏼',
    tags_EN: 'person running, medium-light-skin tone, white',
    tags_ES: 'persona corriendo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 915,
    symbol: '🏃🏽',
    tags_EN: 'person running, medium-skin tone, white',
    tags_ES: 'persona corriendo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 916,
    symbol: '🏃🏾',
    tags_EN: 'person running, medium-dark-skin tone, black',
    tags_ES: 'persona corriendo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 917,
    symbol: '🏃🏿',
    tags_EN: 'person running, dark-skin-tone, black',
    tags_ES: 'persona corriendo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 918,
    symbol: '🏃‍♂️',
    tags_EN: 'man running, run, yellow',
    tags_ES: 'hombre corriendo, correr, amarillo',
    category: 'gente',
  },
  {
    id: 919,
    symbol: '🏃🏻‍♂️',
    tags_EN: 'man running, light-skin tone, white',
    tags_ES: 'hombre corriendo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 920,
    symbol: '🏃🏼‍♂️',
    tags_EN: 'man running, medium-light-skin tone, white',
    tags_ES: 'hombre corriendo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 921,
    symbol: '🏃🏽‍♂️',
    tags_EN: 'man running, medium-skin tone, white',
    tags_ES: 'hombre corriendo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 922,
    symbol: '🏃🏾‍♂️',
    tags_EN: 'man running, medium-dark-skin tone, black',
    tags_ES: 'hombre corriendo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 923,
    symbol: '🏃🏿‍♂️',
    tags_EN: 'man running, dark-skin-tone, black',
    tags_ES: 'hombre corriendo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 924,
    symbol: '🏃‍♀️',
    tags_EN: 'woman running, run, yellow',
    tags_ES: 'mujer corriendo, correr, amarillo',
    category: 'gente',
  },
  {
    id: 925,
    symbol: '🏃🏻‍♀️',
    tags_EN: 'woman running, light-skin tone, white',
    tags_ES: 'mujer corriendo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 926,
    symbol: '🏃🏼‍♀️',
    tags_EN: 'woman running, medium-light-skin tone, white',
    tags_ES: 'mujer corriendo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 927,
    symbol: '🏃🏽‍♀️',
    tags_EN: 'woman running, medium-skin tone, white',
    tags_ES: 'mujer corriendo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 928,
    symbol: '🏃🏾‍♀️',
    tags_EN: 'woman running, medium-dark-skin tone, black',
    tags_ES: 'mujer corriendo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 929,
    symbol: '🏃🏿‍♀️',
    tags_EN: 'woman running, dark-skin-tone, black',
    tags_ES: 'mujer corriendo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 930,
    symbol: '💃',
    tags_EN: 'woman dancing, dance, yellow',
    tags_ES: 'mujer bailando, baile, amarillo',
    category: 'gente',
  },
  {
    id: 931,
    symbol: '💃🏻',
    tags_EN: 'woman dancing, light-skin tone, white',
    tags_ES: 'mujer bailando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 932,
    symbol: '💃🏼',
    tags_EN: 'woman dancing, medium-light-skin tone, white',
    tags_ES: 'mujer bailando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 933,
    symbol: '💃🏽',
    tags_EN: 'woman dancing, medium-skin tone, white',
    tags_ES: 'mujer bailando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 934,
    symbol: '💃🏾',
    tags_EN: 'woman dancing, medium-dark-skin tone, black',
    tags_ES: 'mujer bailando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 935,
    symbol: '💃🏿',
    tags_EN: 'woman dancing, dark-skin-tone, black',
    tags_ES: 'mujer bailando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 936,
    symbol: '🕺',
    tags_EN: 'man dancing, dance, yellow',
    tags_ES: 'hombre bailando, baile, amarillo',
    category: 'gente',
  },
  {
    id: 937,
    symbol: '🕺🏻',
    tags_EN: 'man dancing, light-skin tone, white',
    tags_ES: 'hombre bailando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 938,
    symbol: '🕺🏼',
    tags_EN: 'man dancing, medium-light-skin tone, white',
    tags_ES: 'hombre bailando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 939,
    symbol: '🕺🏽',
    tags_EN: 'man dancing, medium-skin tone, white',
    tags_ES: 'hombre bailando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 940,
    symbol: '🕺🏾',
    tags_EN: 'man dancing, medium-dark-skin tone, black',
    tags_ES: 'hombre bailando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 941,
    symbol: '🕺🏿',
    tags_EN: 'man dancing, dark-skin-tone, black',
    tags_ES: 'hombre bailando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 942,
    symbol: '👯',
    tags_EN: 'people, bunny, ears',
    tags_ES: 'gente, orejas, conejo',
    category: 'gente',
  },
  {
    id: 943,
    symbol: '👯‍♂️',
    tags_EN: 'men, bunny, ears',
    tags_ES: 'hombre, orejas, conejo',
    category: 'gente',
  },
  {
    id: 944,
    symbol: '👯‍♀️',
    tags_EN: 'women, bunny, ears',
    tags_ES: 'mujer, orejas, conejo',
    category: 'gente',
  },
  {
    id: 945,
    symbol: '🧖',
    tags_EN: 'person steamy room, steam, yellow',
    tags_ES: 'persona sauna, vapor, amarillo',
    category: 'gente',
  },
  {
    id: 946,
    symbol: '🧖🏻',
    tags_EN: 'person steamy room, light-skin tone, white',
    tags_ES: 'persona sauna, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 947,
    symbol: '🧖🏼',
    tags_EN: 'person steamy room, medium-light-skin tone, white',
    tags_ES: 'persona sauna, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 948,
    symbol: '🧖🏽',
    tags_EN: 'person steamy room, medium-skin tone, white',
    tags_ES: 'persona sauna, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 949,
    symbol: '🧖🏾',
    tags_EN: 'person steamy room, medium-dark-skin tone, black',
    tags_ES: 'persona sauna, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 950,
    symbol: '🧖🏿',
    tags_EN: 'person steamy room, dark-skin-tone, black',
    tags_ES: 'persona sauna, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 951,
    symbol: '🧖‍♀️',
    tags_EN: 'woman steamy room, steam, yellow',
    tags_ES: 'mujer sauna, vapor, amarillo',
    category: 'gente',
  },
  {
    id: 952,
    symbol: '🧖🏻‍♀️',
    tags_EN: 'woman steamy room, light-skin tone, white',
    tags_ES: 'mujer sauna, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 953,
    symbol: '🧖🏼‍♀️',
    tags_EN: 'woman steamy room, medium-light-skin tone, white',
    tags_ES: 'mujer sauna, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 954,
    symbol: '🧖🏽‍♀️',
    tags_EN: 'woman steamy room, medium-skin tone, white',
    tags_ES: 'mujer sauna, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 955,
    symbol: '🧖🏾‍♀️',
    tags_EN: 'woman steamy room, medium-dark-skin tone, black',
    tags_ES: 'mujer sauna, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 956,
    symbol: '🧖🏿‍♀️',
    tags_EN: 'woman steamy room, dark-skin-tone, black',
    tags_ES: 'mujer sauna, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 957,
    symbol: '🧖‍♂️',
    tags_EN: 'man steamy room, steam, yellow',
    tags_ES: 'hombre sauna, vapor, amarillo',
    category: 'gente',
  },
  {
    id: 958,
    symbol: '🧖🏻‍♂️',
    tags_EN: 'man steamy room, light-skin tone, white',
    tags_ES: 'hombre sauna, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 959,
    symbol: '🧖🏼‍♂️',
    tags_EN: 'man steamy room, medium-light-skin tone, white',
    tags_ES: 'hombre sauna, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 960,
    symbol: '🧖🏽‍♂️',
    tags_EN: 'man steamy room, medium-skin tone, white',
    tags_ES: 'hombre sauna, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 961,
    symbol: '🧖🏾‍♂️',
    tags_EN: 'man steamy room, medium-dark-skin tone, black',
    tags_ES: 'hombre sauna, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 962,
    symbol: '🧖🏿‍♂️',
    tags_EN: 'man steamy room, dark-skin-tone, black',
    tags_ES: 'hombre sauna, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 963,
    symbol: '🧗',
    tags_EN: 'person climbing, climb, yellow',
    tags_ES: 'persona escalando, escalar, amarillo',
    category: 'gente',
  },
  {
    id: 964,
    symbol: '🧗🏻',
    tags_EN: 'person climbing, light-skin tone, white',
    tags_ES: 'persona escalando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 965,
    symbol: '🧗🏼',
    tags_EN: 'person climbing, medium-light-skin tone, white',
    tags_ES: 'persona escalando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 966,
    symbol: '🧗🏽',
    tags_EN: 'person climbing, medium-skin tone, white',
    tags_ES: 'persona escalando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 967,
    symbol: '🧗🏾',
    tags_EN: 'person climbing, medium-dark-skin tone, black',
    tags_ES: 'persona escalando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 968,
    symbol: '🧗🏿',
    tags_EN: 'person climbing, dark-skin-tone, black',
    tags_ES: 'persona escalando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 969,
    symbol: '🧗‍♀️',
    tags_EN: 'woman climbing, climb, yellow',
    tags_ES: 'mujer escalando, escalar, amarillo',
    category: 'gente',
  },
  {
    id: 970,
    symbol: '🧗🏻‍♀️',
    tags_EN: 'woman climbing, light-skin tone, white',
    tags_ES: 'mujer escalando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 971,
    symbol: '🧗🏼‍♀️',
    tags_EN: 'woman climbing, medium-light-skin tone, white',
    tags_ES: 'mujer escalando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 972,
    symbol: '🧗🏽‍♀️',
    tags_EN: 'woman climbing, medium-skin tone, white',
    tags_ES: 'mujer escalando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 973,
    symbol: '🧗🏾‍♀️',
    tags_EN: 'woman climbing, medium-dark-skin tone, black',
    tags_ES: 'mujer escalando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 974,
    symbol: '🧗🏿‍♀️',
    tags_EN: 'woman climbing, dark-skin-tone, black',
    tags_ES: 'mujer escalando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 975,
    symbol: '🧗‍♂️',
    tags_EN: 'man climbing, climb, yellow',
    tags_ES: 'hombre escalando, escalar, amarillo',
    category: 'gente',
  },
  {
    id: 976,
    symbol: '🧗🏻‍♂️',
    tags_EN: 'man climbing, light-skin tone, white',
    tags_ES: 'hombre escalando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 977,
    symbol: '🧗🏼‍♂️',
    tags_EN: 'man climbing, medium-light-skin tone, white',
    tags_ES: 'hombre escalando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 978,
    symbol: '🧗🏽‍♂️',
    tags_EN: 'man climbing, medium-skin tone, white',
    tags_ES: 'hombre escalando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 979,
    symbol: '🧗🏾‍♂️',
    tags_EN: 'man climbing, medium-dark-skin tone, black',
    tags_ES: 'hombre escalando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 980,
    symbol: '🧗🏿‍♂️',
    tags_EN: 'man climbing, dark-skin-tone, black',
    tags_ES: 'hombre escalando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 981,
    symbol: '🧘',
    tags_EN: 'person lotus position, meditation, yellow',
    tags_ES: 'persona posición loto, meditación, amarillo',
    category: 'gente',
  },
  {
    id: 982,
    symbol: '🧘🏻',
    tags_EN: 'person lotus position, light-skin tone, white',
    tags_ES: 'persona posición loto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 983,
    symbol: '🧘🏼',
    tags_EN: 'person lotus position, medium-light-skin tone, white',
    tags_ES: 'persona posición loto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 984,
    symbol: '🧘🏽',
    tags_EN: 'person lotus position, medium-skin tone, white',
    tags_ES: 'persona posición loto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 985,
    symbol: '🧘🏾',
    tags_EN: 'person lotus position, medium-dark-skin tone, black',
    tags_ES: 'persona posición loto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 986,
    symbol: '🧘🏿',
    tags_EN: 'person lotus position, dark-skin-tone, black',
    tags_ES: 'persona posición loto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 987,
    symbol: '🧘‍♀️',
    tags_EN: 'woman lotus position, meditation, yellow',
    tags_ES: 'mujer posición loto, meditación, amarillo',
    category: 'gente',
  },
  {
    id: 988,
    symbol: '🧘🏻‍♀️',
    tags_EN: 'woman lotus position, light-skin tone, white',
    tags_ES: 'mujer posición loto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 989,
    symbol: '🧘🏼‍♀️',
    tags_EN: 'woman lotus position, medium-light-skin tone, white',
    tags_ES: 'mujer posición loto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 990,
    symbol: '🧘🏽‍♀️',
    tags_EN: 'woman lotus position, medium-skin tone, white',
    tags_ES: 'mujer posición loto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 991,
    symbol: '🧘🏾‍♀️',
    tags_EN: 'woman lotus position, medium-dark-skin tone, black',
    tags_ES: 'mujer posición loto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 992,
    symbol: '🧘🏿‍♀️',
    tags_EN: 'woman lotus position, dark-skin-tone, black',
    tags_ES: 'mujer posición loto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 993,
    symbol: '🧘‍♂️',
    tags_EN: 'man lotus position, meditation, yellow',
    tags_ES: 'hombre posición loto, meditación, amarillo',
    category: 'gente',
  },
  {
    id: 994,
    symbol: '🧘🏻‍♂️',
    tags_EN: 'man lotus position, light-skin tone, white',
    tags_ES: 'hombre posición loto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 995,
    symbol: '🧘🏼‍♂️',
    tags_EN: 'man lotus position, medium-light-skin tone, white',
    tags_ES: 'hombre posición loto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 996,
    symbol: '🧘🏽‍♂️',
    tags_EN: 'man lotus position, medium-skin tone, white',
    tags_ES: 'hombre posición loto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 997,
    symbol: '🧘🏾‍♂️',
    tags_EN: 'man lotus position, medium-dark-skin tone, black',
    tags_ES: 'hombre posición loto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 998,
    symbol: '🧘🏿‍♂️',
    tags_EN: 'man lotus position, dark-skin-tone, black',
    tags_ES: 'hombre posición loto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 999,
    symbol: '🛀',
    tags_EN: 'person taking bath, bath, yellow',
    tags_ES: 'persona tomándose baño, bañera, amarillo',
    category: 'gente',
  },
  {
    id: 1000,
    symbol: '🛀🏻',
    tags_EN: 'person taking bath, light-skin tone, white',
    tags_ES: 'persona tomándose baño, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1001,
    symbol: '🛀🏼',
    tags_EN: 'person taking bath, medium-light-skin tone, white',
    tags_ES: 'persona tomándose baño, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1002,
    symbol: '🛀🏽',
    tags_EN: 'person taking bath, medium-skin tone, white',
    tags_ES: 'persona tomándose baño, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1003,
    symbol: '🛀🏾',
    tags_EN: 'person taking bath, medium-dark-skin tone, black',
    tags_ES: 'persona tomándose baño, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1004,
    symbol: '🛀🏿',
    tags_EN: 'person taking bath, dark-skin-tone, black',
    tags_ES: 'persona tomándose baño, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1005,
    symbol: '🛌',
    tags_EN: 'person in bed, sleep, yellow',
    tags_ES: 'persona en cama, dormir, amarillo',
    category: 'gente',
  },
  {
    id: 1006,
    symbol: '🛌🏻',
    tags_EN: 'person in bed, light-skin tone, white',
    tags_ES: 'persona en cama, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1007,
    symbol: '🛌🏼',
    tags_EN: 'person in bed, medium-light-skin tone, white',
    tags_ES: 'persona en cama, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1008,
    symbol: '🛌🏽',
    tags_EN: 'person in bed, medium-skin tone, white',
    tags_ES: 'persona en cama, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1009,
    symbol: '🛌🏾',
    tags_EN: 'person in bed, medium-dark-skin tone, black',
    tags_ES: 'persona en cama, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1010,
    symbol: '🛌🏿',
    tags_EN: 'person in bed, dark-skin-tone, black',
    tags_ES: 'persona en cama, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1011,
    symbol: '🕴',
    tags_EN: 'man levitating, fly, yellow',
    tags_ES: 'hombre levitando, volar, amarillo',
    category: 'gente',
  },
  {
    id: 1012,
    symbol: '🕴🏻',
    tags_EN: 'man levitating, light-skin tone, white',
    tags_ES: 'hombre levitando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1013,
    symbol: '🕴🏼',
    tags_EN: 'man levitating, medium-light-skin tone, white',
    tags_ES: 'hombre levitando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1014,
    symbol: '🕴🏽',
    tags_EN: 'man levitating, medium-skin tone, white',
    tags_ES: 'hombre levitando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1015,
    symbol: '🕴🏾',
    tags_EN: 'man levitating, medium-dark-skin tone, black',
    tags_ES: 'hombre levitando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1016,
    symbol: '🕴🏿',
    tags_EN: 'man levitating, dark-skin-tone, black',
    tags_ES: 'hombre levitando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1017,
    symbol: '🗣',
    tags_EN: 'speaking, head, shout',
    tags_ES: 'cabeza, hablando, gritar',
    category: 'gente',
  },
  {
    id: 1018,
    symbol: '👤',
    tags_EN: 'bust, silhouette, person',
    tags_ES: 'persona, silueta, busto',
    category: 'gente',
  },
  {
    id: 1019,
    symbol: '👥',
    tags_EN: 'busts, silhouettes, persons',
    tags_ES: 'personas, siluetas, bustos',
    category: 'gente',
  },
  {
    id: 1020,
    symbol: '🤺',
    tags_EN: 'person, fencing, sword',
    tags_ES: 'persona, esgrima, espada',
    category: 'gente',
  },
  {
    id: 1021,
    symbol: '🏇',
    tags_EN: 'horse racing, jockey, yellow',
    tags_ES: 'caballo carreras, equitación, amarillo',
    category: 'gente',
  },
  {
    id: 1022,
    symbol: '🏇🏻',
    tags_EN: 'horse racing, light-skin tone, white',
    tags_ES: 'caballo carreras, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1023,
    symbol: '🏇🏼',
    tags_EN: 'horse racing, medium-light-skin tone, white',
    tags_ES: 'caballo carreras, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1024,
    symbol: '🏇🏽',
    tags_EN: 'horse racing, medium-skin tone, white',
    tags_ES: 'caballo carreras, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1025,
    symbol: '🏇🏾',
    tags_EN: 'horse racing, medium-dark-skin tone, black',
    tags_ES: 'caballo carreras, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1026,
    symbol: '🏇🏿',
    tags_EN: 'horse racing, dark-skin-tone, black',
    tags_ES: 'caballo carreras, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1027,
    symbol: '⛷',
    tags_EN: 'skier, ski, snow',
    tags_ES: 'esquiador, esquí, nieve',
    category: 'gente',
  },
  {
    id: 1028,
    symbol: '🏂',
    tags_EN: 'snowboarder, snow, yellow',
    tags_ES: 'snowboarder, nieve, amarillo',
    category: 'gente',
  },
  {
    id: 1029,
    symbol: '🏂🏻',
    tags_EN: 'snowboarder, light-skin tone, white',
    tags_ES: 'snowboarder, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1030,
    symbol: '🏂🏼',
    tags_EN: 'snowboarder, medium-light-skin tone, white',
    tags_ES: 'snowboarder, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1031,
    symbol: '🏂🏽',
    tags_EN: 'snowboarder, medium-skin tone, white',
    tags_ES: 'snowboarder, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1032,
    symbol: '🏂🏾',
    tags_EN: 'snowboarder, medium-dark-skin tone, black',
    tags_ES: 'snowboarder, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1033,
    symbol: '🏂🏿',
    tags_EN: 'snowboarder, dark-skin-tone, black',
    tags_ES: 'snowboarder, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1034,
    symbol: '🏌',
    tags_EN: 'person golfing, swing, yellow',
    tags_ES: 'persona golf, swing, amarillo',
    category: 'gente',
  },
  {
    id: 1035,
    symbol: '🏌🏻',
    tags_EN: 'person golfing, light-skin tone, white',
    tags_ES: 'persona golf, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1036,
    symbol: '🏌🏼',
    tags_EN: 'person golfing, medium-light-skin tone, white',
    tags_ES: 'persona golf, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1037,
    symbol: '🏌🏽',
    tags_EN: 'person golfing, medium-skin tone, white',
    tags_ES: 'persona golf, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1038,
    symbol: '🏌🏾',
    tags_EN: 'person golfing, medium-dark-skin tone, black',
    tags_ES: 'persona golf, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1039,
    symbol: '🏌🏿',
    tags_EN: 'person golfing, dark-skin-tone, black',
    tags_ES: 'persona golf, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1040,
    symbol: '🏌️‍♂️',
    tags_EN: 'man golfing, swing, yellow',
    tags_ES: 'hombre golf, swing, amarillo',
    category: 'gente',
  },
  {
    id: 1041,
    symbol: '🏌🏻‍♂️',
    tags_EN: 'man golfing, light-skin tone, white',
    tags_ES: 'hombre golf, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1042,
    symbol: '🏌🏼‍♂️',
    tags_EN: 'man golfing, medium-light-skin tone, white',
    tags_ES: 'hombre golf, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1043,
    symbol: '🏌🏽‍♂️',
    tags_EN: 'man golfing, medium-skin tone, white',
    tags_ES: 'hombre golf, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1044,
    symbol: '🏌🏾‍♂️',
    tags_EN: 'man golfing, medium-dark-skin tone, black',
    tags_ES: 'hombre golf, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1045,
    symbol: '🏌🏿‍♂️',
    tags_EN: 'man golfing, dark-skin-tone, black',
    tags_ES: 'hombre golf, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1046,
    symbol: '🏌️‍♀️',
    tags_EN: 'woman golfing, swing, yellow',
    tags_ES: 'mujer golf, swing, amarillo',
    category: 'gente',
  },
  {
    id: 1047,
    symbol: '🏌🏻‍♀️',
    tags_EN: 'woman golfing, light-skin tone, white',
    tags_ES: 'mujer golf, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1048,
    symbol: '🏌🏼‍♀️',
    tags_EN: 'woman golfing, medium-light-skin tone, white',
    tags_ES: 'mujer golf, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1049,
    symbol: '🏌🏽‍♀️',
    tags_EN: 'woman golfing, medium-skin tone, white',
    tags_ES: 'mujer golf, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1050,
    symbol: '🏌🏾‍♀️',
    tags_EN: 'woman golfing, medium-dark-skin tone, black',
    tags_ES: 'mujer golf, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1051,
    symbol: '🏌🏿‍♀️',
    tags_EN: 'woman golfing, dark-skin-tone, black',
    tags_ES: 'mujer golf, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1052,
    symbol: '🏄',
    tags_EN: 'person surfing, surf, yellow',
    tags_ES: 'persona surfeando, surf, amarillo',
    category: 'gente',
  },
  {
    id: 1053,
    symbol: '🏄🏻',
    tags_EN: 'person surfing, light-skin tone, white',
    tags_ES: 'persona surfeando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1054,
    symbol: '🏄🏼',
    tags_EN: 'person surfing, medium-light-skin tone, white',
    tags_ES: 'persona surfeando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1055,
    symbol: '🏄🏽',
    tags_EN: 'person surfing, medium-skin tone, white',
    tags_ES: 'persona surfeando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1056,
    symbol: '🏄🏾',
    tags_EN: 'person surfing, medium-dark-skin tone, black',
    tags_ES: 'persona surfeando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1057,
    symbol: '🏄🏿',
    tags_EN: 'person surfing, dark-skin-tone, black',
    tags_ES: 'persona surfeando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1058,
    symbol: '🏄‍♂️',
    tags_EN: 'man surfing, surf, yellow',
    tags_ES: 'hombre surfeando, surf, amarillo',
    category: 'gente',
  },
  {
    id: 1059,
    symbol: '🏄🏻‍♂️',
    tags_EN: 'man surfing, light-skin tone, white',
    tags_ES: 'hombre surfeando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1060,
    symbol: '🏄🏼‍♂️',
    tags_EN: 'man surfing, medium-light-skin tone, white',
    tags_ES: 'hombre surfeando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1061,
    symbol: '🏄🏽‍♂️',
    tags_EN: 'man surfing, medium-skin tone, white',
    tags_ES: 'hombre surfeando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1062,
    symbol: '🏄🏾‍♂️',
    tags_EN: 'man surfing, medium-dark-skin tone, black',
    tags_ES: 'hombre surfeando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1063,
    symbol: '🏄🏿‍♂️',
    tags_EN: 'man surfing, dark-skin-tone, black',
    tags_ES: 'hombre surfeando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1064,
    symbol: '🏄‍♀️',
    tags_EN: 'woman surfing, surf, yellow',
    tags_ES: 'mujer surfeando, surf, amarillo',
    category: 'gente',
  },
  {
    id: 1065,
    symbol: '🏄🏻‍♀️',
    tags_EN: 'woman surfing, light-skin tone, white',
    tags_ES: 'mujer surfeando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1066,
    symbol: '🏄🏼‍♀️',
    tags_EN: 'woman surfing, medium-light-skin tone, white',
    tags_ES: 'mujer surfeando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1067,
    symbol: '🏄🏽‍♀️',
    tags_EN: 'woman surfing, medium-skin tone, white',
    tags_ES: 'mujer surfeando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1068,
    symbol: '🏄🏾‍♀️',
    tags_EN: 'woman surfing, medium-dark-skin tone, black',
    tags_ES: 'mujer surfeando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1069,
    symbol: '🏄🏿‍♀️',
    tags_EN: 'woman surfing, dark-skin-tone, black',
    tags_ES: 'mujer surfeando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1070,
    symbol: '🚣',
    tags_EN: 'person rowing, boat, yellow',
    tags_ES: 'persona remando, barco, amarillo',
    category: 'gente',
  },
  {
    id: 1071,
    symbol: '🚣🏻',
    tags_EN: 'person rowing, light-skin tone, white',
    tags_ES: 'persona remando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1072,
    symbol: '🚣🏼',
    tags_EN: 'person rowing, medium-light-skin tone, white',
    tags_ES: 'persona remando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1073,
    symbol: '🚣🏽',
    tags_EN: 'person rowing, medium-skin tone, white',
    tags_ES: 'persona remando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1074,
    symbol: '🚣🏾',
    tags_EN: 'person rowing, medium-dark-skin tone, black',
    tags_ES: 'persona remando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1075,
    symbol: '🚣🏿',
    tags_EN: 'person rowing, dark-skin-tone, black',
    tags_ES: 'persona remando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1076,
    symbol: '🚣‍♂️',
    tags_EN: 'man rowing, boat, yellow',
    tags_ES: 'hombre remando, barco, amarillo',
    category: 'gente',
  },
  {
    id: 1077,
    symbol: '🚣🏻‍♂️',
    tags_EN: 'man rowing, light-skin tone, white',
    tags_ES: 'hombre remando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1078,
    symbol: '🚣🏼‍♂️',
    tags_EN: 'man rowing, medium-light-skin tone, white',
    tags_ES: 'hombre remando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1079,
    symbol: '🚣🏽‍♂️',
    tags_EN: 'man rowing, medium-skin tone, white',
    tags_ES: 'hombre remando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1080,
    symbol: '🚣🏾‍♂️',
    tags_EN: 'man rowing, medium-dark-skin tone, black',
    tags_ES: 'hombre remando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1081,
    symbol: '🚣🏿‍♂️',
    tags_EN: 'man rowing, dark-skin-tone, black',
    tags_ES: 'hombre remando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1082,
    symbol: '🚣‍♀️',
    tags_EN: 'woman rowing, boat, yellow',
    tags_ES: 'mujer remando, barco, amarillo',
    category: 'gente',
  },
  {
    id: 1083,
    symbol: '🚣🏻‍♀️',
    tags_EN: 'woman rowing, light-skin tone, white',
    tags_ES: 'mujer remando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1084,
    symbol: '🚣🏼‍♀️',
    tags_EN: 'woman rowing, medium-light-skin tone, white',
    tags_ES: 'mujer remando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1085,
    symbol: '🚣🏽‍♀️',
    tags_EN: 'woman rowing, medium-skin tone, white',
    tags_ES: 'mujer remando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1086,
    symbol: '🚣🏾‍♀️',
    tags_EN: 'woman rowing, medium-dark-skin tone, black',
    tags_ES: 'mujer remando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1087,
    symbol: '🚣🏿‍♀️',
    tags_EN: 'woman rowing, dark-skin-tone, black',
    tags_ES: 'mujer remando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1088,
    symbol: '🏊',
    tags_EN: 'person swimming, swim, yellow',
    tags_ES: 'persona nadando, natación, amarillo',
    category: 'gente',
  },
  {
    id: 1089,
    symbol: '🏊🏻',
    tags_EN: 'person swimming, light-skin tone, white',
    tags_ES: 'persona nadando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1090,
    symbol: '🏊🏼',
    tags_EN: 'person swimming, medium-light-skin tone, white',
    tags_ES: 'persona nadando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1091,
    symbol: '🏊🏽',
    tags_EN: 'person swimming, medium-skin tone, white',
    tags_ES: 'persona nadando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1092,
    symbol: '🏊🏾',
    tags_EN: 'person swimming, medium-dark-skin tone, black',
    tags_ES: 'persona nadando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1093,
    symbol: '🏊🏿',
    tags_EN: 'person swimming, dark-skin-tone, black',
    tags_ES: 'persona nadando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1094,
    symbol: '🏊‍♂️',
    tags_EN: 'man swimming, swim, yellow',
    tags_ES: 'hombre nadando, natación, amarillo',
    category: 'gente',
  },
  {
    id: 1095,
    symbol: '🏊🏻‍♂️',
    tags_EN: 'man swimming, light-skin tone, white',
    tags_ES: 'hombre nadando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1096,
    symbol: '🏊🏼‍♂️',
    tags_EN: 'man swimming, medium-light-skin tone, white',
    tags_ES: 'hombre nadando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1097,
    symbol: '🏊🏽‍♂️',
    tags_EN: 'man swimming, medium-skin tone, white',
    tags_ES: 'hombre nadando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1098,
    symbol: '🏊🏾‍♂️',
    tags_EN: 'man swimming, medium-dark-skin tone, black',
    tags_ES: 'hombre nadando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1099,
    symbol: '🏊🏿‍♂️',
    tags_EN: 'man swimming, dark-skin-tone, black',
    tags_ES: 'hombre nadando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1100,
    symbol: '🏊‍♀️',
    tags_EN: 'woman swimming, swim, yellow',
    tags_ES: 'mujer nadando, natación, amarillo',
    category: 'gente',
  },
  {
    id: 1101,
    symbol: '🏊🏻‍♀️',
    tags_EN: 'woman swimming, light-skin tone, white',
    tags_ES: 'mujer nadando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1102,
    symbol: '🏊🏼‍♀️',
    tags_EN: 'woman swimming, medium-light-skin tone, white',
    tags_ES: 'mujer nadando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1103,
    symbol: '🏊🏽‍♀️',
    tags_EN: 'woman swimming, medium-skin tone, white',
    tags_ES: 'mujer nadando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1104,
    symbol: '🏊🏾‍♀️',
    tags_EN: 'woman swimming, medium-dark-skin tone, black',
    tags_ES: 'mujer nadando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1105,
    symbol: '🏊🏿‍♀️',
    tags_EN: 'woman swimming, dark-skin-tone, black',
    tags_ES: 'mujer nadando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1106,
    symbol: '⛹',
    tags_EN: 'person bouncing, basketball, yellow',
    tags_ES: 'persona botando, baloncesto, amarillo',
    category: 'gente',
  },
  {
    id: 1107,
    symbol: '⛹🏻',
    tags_EN: 'person bouncing, light-skin tone, white',
    tags_ES: 'persona botando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1108,
    symbol: '⛹🏼',
    tags_EN: 'person bouncing, medium-light-skin tone, white',
    tags_ES: 'persona botando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1109,
    symbol: '⛹🏽',
    tags_EN: 'person bouncing, medium-skin tone, white',
    tags_ES: 'persona botando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1110,
    symbol: '⛹🏾',
    tags_EN: 'person bouncing, medium-dark-skin tone, black',
    tags_ES: 'persona botando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1111,
    symbol: '⛹🏿',
    tags_EN: 'person bouncing, dark-skin-tone, black',
    tags_ES: 'persona botando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1112,
    symbol: '⛹️‍♂️',
    tags_EN: 'man bouncing, basketball, yellow',
    tags_ES: 'hombre botando, baloncesto, amarillo',
    category: 'gente',
  },
  {
    id: 1113,
    symbol: '⛹🏻‍♂️',
    tags_EN: 'man bouncing, light-skin tone, white',
    tags_ES: 'hombre botando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1114,
    symbol: '⛹🏼‍♂️',
    tags_EN: 'man bouncing, medium-light-skin tone, white',
    tags_ES: 'hombre botando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1115,
    symbol: '⛹🏽‍♂️',
    tags_EN: 'man bouncing, medium-skin tone, white',
    tags_ES: 'hombre botando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1116,
    symbol: '⛹🏾‍♂️',
    tags_EN: 'man bouncing, medium-dark-skin tone, black',
    tags_ES: 'hombre botando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1117,
    symbol: '⛹🏿‍♂️',
    tags_EN: 'man bouncing, dark-skin-tone, black',
    tags_ES: 'hombre botando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1118,
    symbol: '⛹️‍♀️',
    tags_EN: 'woman bouncing, basketball, yellow',
    tags_ES: 'mujer botando, baloncesto, amarillo',
    category: 'gente',
  },
  {
    id: 1119,
    symbol: '⛹🏻‍♀️',
    tags_EN: 'woman bouncing, light-skin tone, white',
    tags_ES: 'mujer botando, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1120,
    symbol: '⛹🏼‍♀️',
    tags_EN: 'woman bouncing, medium-light-skin tone, white',
    tags_ES: 'mujer botando, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1121,
    symbol: '⛹🏽‍♀️',
    tags_EN: 'woman bouncing, medium-skin tone, white',
    tags_ES: 'mujer botando, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1122,
    symbol: '⛹🏾‍♀️',
    tags_EN: 'woman bouncing, medium-dark-skin tone, black',
    tags_ES: 'mujer botando, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1123,
    symbol: '⛹🏿‍♀️',
    tags_EN: 'woman bouncing, dark-skin-tone, black',
    tags_ES: 'mujer botando, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1124,
    symbol: '🏋',
    tags_EN: 'person lifting weights, bodybuilding, yellow',
    tags_ES: 'persona levantando pesos, culturismo, amarillo',
    category: 'gente',
  },
  {
    id: 1125,
    symbol: '🏋🏻',
    tags_EN: 'person lifting weights, light-skin tone, white',
    tags_ES: 'persona levantando pesos, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1126,
    symbol: '🏋🏼',
    tags_EN: 'person lifting weights, medium-light-skin tone, white',
    tags_ES: 'persona levantando pesos, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1127,
    symbol: '🏋🏽',
    tags_EN: 'person lifting weights, medium-skin tone, white',
    tags_ES: 'persona levantando pesos, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1128,
    symbol: '🏋🏾',
    tags_EN: 'person lifting weights, medium-dark-skin tone, black',
    tags_ES: 'persona levantando pesos, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1129,
    symbol: '🏋🏿',
    tags_EN: 'person lifting weights, dark-skin-tone, black',
    tags_ES: 'persona levantando pesos, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1130,
    symbol: '🏋️‍♂️',
    tags_EN: 'man lifting weights, bodybuilding, yellow',
    tags_ES: 'hombre levantando pesos, culturismo, amarillo',
    category: 'gente',
  },
  {
    id: 1131,
    symbol: '🏋🏻‍♂️',
    tags_EN: 'man lifting weights, light-skin tone, white',
    tags_ES: 'hombre levantando pesos, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1132,
    symbol: '🏋🏼‍♂️',
    tags_EN: 'man lifting weights, medium-light-skin tone, white',
    tags_ES: 'hombre levantando pesos, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1133,
    symbol: '🏋🏽‍♂️',
    tags_EN: 'man lifting weights, medium-skin tone, white',
    tags_ES: 'hombre levantando pesos, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1134,
    symbol: '🏋🏾‍♂️',
    tags_EN: 'man lifting weights, medium-dark-skin tone, black',
    tags_ES: 'hombre levantando pesos, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1135,
    symbol: '🏋🏿‍♂️',
    tags_EN: 'man lifting weights, dark-skin-tone, black',
    tags_ES: 'hombre levantando pesos, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1136,
    symbol: '🏋️‍♀️',
    tags_EN: 'woman lifting weights, bodybuilding, yellow',
    tags_ES: 'mujer levantando pesos, culturismo, amarillo',
    category: 'gente',
  },
  {
    id: 1137,
    symbol: '🏋🏻‍♀️',
    tags_EN: 'woman lifting weights, light-skin tone, white',
    tags_ES: 'mujer levantando pesos, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1138,
    symbol: '🏋🏼‍♀️',
    tags_EN: 'woman lifting weights, medium-light-skin tone, white',
    tags_ES: 'mujer levantando pesos, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1139,
    symbol: '🏋🏽‍♀️',
    tags_EN: 'woman lifting weights, medium-skin tone, white',
    tags_ES: 'mujer levantando pesos, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1140,
    symbol: '🏋🏾‍♀️',
    tags_EN: 'woman lifting weights, medium-dark-skin tone, black',
    tags_ES: 'mujer levantando pesos, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1141,
    symbol: '🏋🏿‍♀️',
    tags_EN: 'woman lifting weights, dark-skin-tone, black',
    tags_ES: 'mujer levantando pesos, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1142,
    symbol: '🚴',
    tags_EN: 'person biking, bike, yellow',
    tags_ES: 'persona ciclista, bici, amarillo',
    category: 'gente',
  },
  {
    id: 1143,
    symbol: '🚴🏻',
    tags_EN: 'person biking, light-skin tone, white',
    tags_ES: 'persona ciclista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1144,
    symbol: '🚴🏼',
    tags_EN: 'person biking, medium-light-skin tone, white',
    tags_ES: 'persona ciclista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1145,
    symbol: '🚴🏽',
    tags_EN: 'person biking, medium-skin tone, white',
    tags_ES: 'persona ciclista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1146,
    symbol: '🚴🏾',
    tags_EN: 'person biking, medium-dark-skin tone, black',
    tags_ES: 'persona ciclista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1147,
    symbol: '🚴🏿',
    tags_EN: 'person biking, dark-skin-tone, black',
    tags_ES: 'persona ciclista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1148,
    symbol: '🚴‍♂️',
    tags_EN: 'man biking, bike, yellow',
    tags_ES: 'hombre ciclista, bici, amarillo',
    category: 'gente',
  },
  {
    id: 1149,
    symbol: '🚴🏻‍♂️',
    tags_EN: 'man biking, light-skin tone, white',
    tags_ES: 'hombre ciclista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1150,
    symbol: '🚴🏼‍♂️',
    tags_EN: 'man biking, medium-light-skin tone, white',
    tags_ES: 'hombre ciclista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1151,
    symbol: '🚴🏽‍♂️',
    tags_EN: 'man biking, medium-skin tone, white',
    tags_ES: 'hombre ciclista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1152,
    symbol: '🚴🏾‍♂️',
    tags_EN: 'man biking, medium-dark-skin tone, black',
    tags_ES: 'hombre ciclista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1153,
    symbol: '🚴🏿‍♂️',
    tags_EN: 'man biking, dark-skin-tone, black',
    tags_ES: 'hombre ciclista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1154,
    symbol: '🚴‍♀️',
    tags_EN: 'woman biking, bike, yellow',
    tags_ES: 'mujer ciclista, bici, amarillo',
    category: 'gente',
  },
  {
    id: 1155,
    symbol: '🚴🏻‍♀️',
    tags_EN: 'woman biking, light-skin tone, white',
    tags_ES: 'mujer ciclista, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1156,
    symbol: '🚴🏼‍♀️',
    tags_EN: 'woman biking, medium-light-skin tone, white',
    tags_ES: 'mujer ciclista, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1157,
    symbol: '🚴🏽‍♀️',
    tags_EN: 'woman biking, medium-skin tone, white',
    tags_ES: 'mujer ciclista, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1158,
    symbol: '🚴🏾‍♀️',
    tags_EN: 'woman biking, medium-dark-skin tone, black',
    tags_ES: 'mujer ciclista, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1159,
    symbol: '🚴🏿‍♀️',
    tags_EN: 'woman biking, dark-skin-tone, black',
    tags_ES: 'mujer ciclista, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1160,
    symbol: '🚵',
    tags_EN: 'person mountain biking, bike, yellow',
    tags_ES: 'persona mountain bike, bici, amarillo',
    category: 'gente',
  },
  {
    id: 1161,
    symbol: '🚵🏻',
    tags_EN: 'person mountain biking, light-skin tone, white',
    tags_ES: 'persona mountain bike, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1162,
    symbol: '🚵🏼',
    tags_EN: 'person mountain biking, medium-light-skin tone, white',
    tags_ES: 'persona mountain bike, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1163,
    symbol: '🚵🏽',
    tags_EN: 'person mountain biking, medium-skin tone, white',
    tags_ES: 'persona mountain bike, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1164,
    symbol: '🚵🏾',
    tags_EN: 'person mountain biking, medium-dark-skin tone, black',
    tags_ES: 'persona mountain bike, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1165,
    symbol: '🚵🏿',
    tags_EN: 'person mountain biking, dark-skin-tone, black',
    tags_ES: 'persona mountain bike, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1166,
    symbol: '🚵‍♂️',
    tags_EN: 'man mountain biking, bike, yellow',
    tags_ES: 'hombre mountain bike, bici, amarillo',
    category: 'gente',
  },
  {
    id: 1167,
    symbol: '🚵🏻‍♂️',
    tags_EN: 'man mountain biking, light-skin tone, white',
    tags_ES: 'hombre mountain bike, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1168,
    symbol: '🚵🏼‍♂️',
    tags_EN: 'man mountain biking, medium-light-skin tone, white',
    tags_ES: 'hombre mountain bike, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1169,
    symbol: '🚵🏽‍♂️',
    tags_EN: 'man mountain biking, medium-skin tone, white',
    tags_ES: 'hombre mountain bike, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1170,
    symbol: '🚵🏾‍♂️',
    tags_EN: 'man mountain biking, medium-dark-skin tone, black',
    tags_ES: 'hombre mountain bike, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1171,
    symbol: '🚵🏿‍♂️',
    tags_EN: 'man mountain biking, dark-skin-tone, black',
    tags_ES: 'hombre mountain bike, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1172,
    symbol: '🚵‍♀️',
    tags_EN: 'woman mountain biking, bike, yellow',
    tags_ES: 'mujer mountain bike, bici, amarillo',
    category: 'gente',
  },
  {
    id: 1173,
    symbol: '🚵🏻‍♀️',
    tags_EN: 'woman mountain biking, light-skin tone, white',
    tags_ES: 'mujer mountain bike, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1174,
    symbol: '🚵🏼‍♀️',
    tags_EN: 'woman mountain biking, medium-light-skin tone, white',
    tags_ES: 'mujer mountain bike, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1175,
    symbol: '🚵🏽‍♀️',
    tags_EN: 'woman mountain biking, medium-skin tone, white',
    tags_ES: 'mujer mountain bike, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1176,
    symbol: '🚵🏾‍♀️',
    tags_EN: 'woman mountain biking, medium-dark-skin tone, black',
    tags_ES: 'mujer mountain bike, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1177,
    symbol: '🚵🏿‍♀️',
    tags_EN: 'woman mountain biking, dark-skin-tone, black',
    tags_ES: 'mujer mountain bike, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1178,
    symbol: '🏎',
    tags_EN: 'racing, car, formula 1',
    tags_ES: 'coche, carreras, formula 1',
    category: 'gente',
  },
  {
    id: 1179,
    symbol: '🏍',
    tags_EN: 'motorcycle, motobike, bike',
    tags_ES: 'motocicleta, moto, moto gp',
    category: 'gente',
  },
  {
    id: 1180,
    symbol: '🤸',
    tags_EN: 'person cartwheeling, exercise, yellow',
    tags_ES: 'persona rueda, ejercicio, amarillo',
    category: 'gente',
  },
  {
    id: 1181,
    symbol: '🤸🏻',
    tags_EN: 'person cartwheeling, light-skin tone, white',
    tags_ES: 'persona rueda, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1182,
    symbol: '🤸🏼',
    tags_EN: 'person cartwheeling, medium-light-skin tone, white',
    tags_ES: 'persona rueda, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1183,
    symbol: '🤸🏽',
    tags_EN: 'person cartwheeling, medium-skin tone, white',
    tags_ES: 'persona rueda, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1184,
    symbol: '🤸🏾',
    tags_EN: 'person cartwheeling, medium-dark-skin tone, black',
    tags_ES: 'persona rueda, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1185,
    symbol: '🤸🏿',
    tags_EN: 'person cartwheeling, dark-skin-tone, black',
    tags_ES: 'persona rueda, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1186,
    symbol: '🤸‍♂️',
    tags_EN: 'man cartwheeling, exercise, yellow',
    tags_ES: 'hombre rueda, ejercicio, amarillo',
    category: 'gente',
  },
  {
    id: 1187,
    symbol: '🤸🏻‍♂️',
    tags_EN: 'man cartwheeling, light-skin tone, white',
    tags_ES: 'hombre rueda, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1188,
    symbol: '🤸🏼‍♂️',
    tags_EN: 'man cartwheeling, medium-light-skin tone, white',
    tags_ES: 'hombre rueda, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1189,
    symbol: '🤸🏽‍♂️',
    tags_EN: 'man cartwheeling, medium-skin tone, white',
    tags_ES: 'hombre rueda, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1190,
    symbol: '🤸🏾‍♂️',
    tags_EN: 'man cartwheeling, medium-dark-skin tone, black',
    tags_ES: 'hombre rueda, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1191,
    symbol: '🤸🏿‍♂️',
    tags_EN: 'man cartwheeling, dark-skin-tone, black',
    tags_ES: 'hombre rueda, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1192,
    symbol: '🤸‍♀️',
    tags_EN: 'woman cartwheeling, exercise, yellow',
    tags_ES: 'mujer rueda, ejercicio, amarillo',
    category: 'gente',
  },
  {
    id: 1193,
    symbol: '🤸🏻‍♀️',
    tags_EN: 'woman cartwheeling, light-skin tone, white',
    tags_ES: 'mujer rueda, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1194,
    symbol: '🤸🏼‍♀️',
    tags_EN: 'woman cartwheeling, medium-light-skin tone, white',
    tags_ES: 'mujer rueda, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1195,
    symbol: '🤸🏽‍♀️',
    tags_EN: 'woman cartwheeling, medium-skin tone, white',
    tags_ES: 'mujer rueda, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1196,
    symbol: '🤸🏾‍♀️',
    tags_EN: 'woman cartwheeling, medium-dark-skin tone, black',
    tags_ES: 'mujer rueda, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1197,
    symbol: '🤸🏿‍♀️',
    tags_EN: 'woman cartwheeling, dark-skin-tone, black',
    tags_ES: 'mujer rueda, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1198,
    symbol: '🤼',
    tags_EN: 'people, wrestling, fighting',
    tags_ES: 'gente, lucha, pelea',
    category: 'gente',
  },
  {
    id: 1199,
    symbol: '🤼‍♂️',
    tags_EN: 'men, wrestling, fighting',
    tags_ES: 'hombres, lucha, pelea',
    category: 'gente',
  },
  {
    id: 1200,
    symbol: '🤼‍♀️',
    tags_EN: 'women, wrestling, fighting',
    tags_ES: 'mujeres, lucha, pelea',
    category: 'gente',
  },
  {
    id: 1201,
    symbol: '🤽',
    tags_EN: 'person water polo, swim, yellow',
    tags_ES: 'persona waterpolo, piscina, amarillo',
    category: 'gente',
  },
  {
    id: 1202,
    symbol: '🤽🏻',
    tags_EN: 'person water polo, light-skin tone, white',
    tags_ES: 'persona waterpolo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1203,
    symbol: '🤽🏼',
    tags_EN: 'person water polo, medium-light-skin tone, white',
    tags_ES: 'persona waterpolo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1204,
    symbol: '🤽🏽',
    tags_EN: 'person water polo, medium-skin tone, white',
    tags_ES: 'persona waterpolo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1205,
    symbol: '🤽🏾',
    tags_EN: 'person water polo, medium-dark-skin tone, black',
    tags_ES: 'persona waterpolo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1206,
    symbol: '🤽🏿',
    tags_EN: 'person water polo, dark-skin-tone, black',
    tags_ES: 'persona waterpolo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1207,
    symbol: '🤽‍♂️',
    tags_EN: 'man water polo, swim, yellow',
    tags_ES: 'hombre waterpolo, piscina, amarillo',
    category: 'gente',
  },
  {
    id: 1208,
    symbol: '🤽🏻‍♂️',
    tags_EN: 'man water polo, light-skin tone, white',
    tags_ES: 'hombre waterpolo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1209,
    symbol: '🤽🏼‍♂️',
    tags_EN: 'man water polo, medium-light-skin tone, white',
    tags_ES: 'hombre waterpolo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1210,
    symbol: '🤽🏽‍♂️',
    tags_EN: 'man water polo, medium-skin tone, white',
    tags_ES: 'hombre waterpolo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1211,
    symbol: '🤽🏾‍♂️',
    tags_EN: 'man water polo, medium-dark-skin tone, black',
    tags_ES: 'hombre waterpolo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1212,
    symbol: '🤽🏿‍♂️',
    tags_EN: 'man water polo, dark-skin-tone, black',
    tags_ES: 'hombre waterpolo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1213,
    symbol: '🤽‍♀️',
    tags_EN: 'woman water polo, swim, yellow',
    tags_ES: 'mujer waterpolo, piscina, amarillo',
    category: 'gente',
  },
  {
    id: 1214,
    symbol: '🤽🏻‍♀️',
    tags_EN: 'woman water polo, light-skin tone, white',
    tags_ES: 'mujer waterpolo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1215,
    symbol: '🤽🏼‍♀️',
    tags_EN: 'woman water polo, medium-light-skin tone, white',
    tags_ES: 'mujer waterpolo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1216,
    symbol: '🤽🏽‍♀️',
    tags_EN: 'woman water polo, medium-skin tone, white',
    tags_ES: 'mujer waterpolo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1217,
    symbol: '🤽🏾‍♀️',
    tags_EN: 'woman water polo, medium-dark-skin tone, black',
    tags_ES: 'mujer waterpolo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1218,
    symbol: '🤽🏿‍♀️',
    tags_EN: 'woman water polo, dark-skin-tone, black',
    tags_ES: 'mujer waterpolo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1219,
    symbol: '🤾',
    tags_EN: 'person handball, ball, yellow',
    tags_ES: 'persona balonmano, pelota, amarillo',
    category: 'gente',
  },
  {
    id: 1220,
    symbol: '🤾🏻',
    tags_EN: 'person handball, light-skin tone, white',
    tags_ES: 'persona balonmano, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1221,
    symbol: '🤾🏼',
    tags_EN: 'person handball, medium-light-skin tone, white',
    tags_ES: 'persona balonmano, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1222,
    symbol: '🤾🏽',
    tags_EN: 'person handball, medium-skin tone, white',
    tags_ES: 'persona balonmano, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1223,
    symbol: '🤾🏾',
    tags_EN: 'person handball, medium-dark-skin tone, black',
    tags_ES: 'persona balonmano, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1224,
    symbol: '🤾🏿',
    tags_EN: 'person handball, dark-skin-tone, black',
    tags_ES: 'persona balonmano, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1225,
    symbol: '🤾‍♂️',
    tags_EN: 'man handball, ball, yellow',
    tags_ES: 'hombre balonmano, pelota, amarillo',
    category: 'gente',
  },
  {
    id: 1226,
    symbol: '🤾🏻‍♂️',
    tags_EN: 'man handball, light-skin tone, white',
    tags_ES: 'hombre balonmano, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1227,
    symbol: '🤾🏼‍♂️',
    tags_EN: 'man handball, medium-light-skin tone, white',
    tags_ES: 'hombre balonmano, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1228,
    symbol: '🤾🏽‍♂️',
    tags_EN: 'man handball, medium-skin tone, white',
    tags_ES: 'hombre balonmano, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1229,
    symbol: '🤾🏾‍♂️',
    tags_EN: 'man handball, medium-dark-skin tone, black',
    tags_ES: 'hombre balonmano, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1230,
    symbol: '🤾🏿‍♂️',
    tags_EN: 'man handball, dark-skin-tone, black',
    tags_ES: 'hombre balonmano, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1231,
    symbol: '🤾‍♀️',
    tags_EN: 'woman handball, ball, yellow',
    tags_ES: 'mujer balonmano, pelota, amarillo',
    category: 'gente',
  },
  {
    id: 1232,
    symbol: '🤾🏻‍♀️',
    tags_EN: 'woman handball, light-skin tone, white',
    tags_ES: 'mujer balonmano, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1233,
    symbol: '🤾🏼‍♀️',
    tags_EN: 'woman handball, medium-light-skin tone, white',
    tags_ES: 'mujer balonmano, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1234,
    symbol: '🤾🏽‍♀️',
    tags_EN: 'woman handball, medium-skin tone, white',
    tags_ES: 'mujer balonmano, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1235,
    symbol: '🤾🏾‍♀️',
    tags_EN: 'woman handball, medium-dark-skin tone, black',
    tags_ES: 'mujer balonmano, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1236,
    symbol: '🤾🏿‍♀️',
    tags_EN: 'woman handball, dark-skin-tone, black',
    tags_ES: 'mujer balonmano, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1237,
    symbol: '🤹',
    tags_EN: 'person juggling, balls, yellow',
    tags_ES: 'persona malabares, pelotas, amarillo',
    category: 'gente',
  },
  {
    id: 1238,
    symbol: '🤹🏻',
    tags_EN: 'person juggling, light-skin tone, white',
    tags_ES: 'persona malabares, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1239,
    symbol: '🤹🏼',
    tags_EN: 'person juggling, medium-light-skin tone, white',
    tags_ES: 'persona malabares, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1240,
    symbol: '🤹🏽',
    tags_EN: 'person juggling, medium-skin tone, white',
    tags_ES: 'persona malabares, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1241,
    symbol: '🤹🏾',
    tags_EN: 'person juggling, medium-dark-skin tone, black',
    tags_ES: 'persona malabares, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1242,
    symbol: '🤹🏿',
    tags_EN: 'person juggling, dark-skin-tone, black',
    tags_ES: 'persona malabares, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1243,
    symbol: '🤹‍♂️',
    tags_EN: 'man juggling, balls, yellow',
    tags_ES: 'hombre malabares, pelotas, amarillo',
    category: 'gente',
  },
  {
    id: 1244,
    symbol: '🤹🏻‍♂️',
    tags_EN: 'man juggling, light-skin tone, white',
    tags_ES: 'hombre malabares, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1245,
    symbol: '🤹🏼‍♂️',
    tags_EN: 'man juggling, medium-light-skin tone, white',
    tags_ES: 'hombre malabares, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1246,
    symbol: '🤹🏽‍♂️',
    tags_EN: 'man juggling, medium-skin tone, white',
    tags_ES: 'hombre malabares, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1247,
    symbol: '🤹🏾‍♂️',
    tags_EN: 'man juggling, medium-dark-skin tone, black',
    tags_ES: 'hombre malabares, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1248,
    symbol: '🤹🏿‍♂️',
    tags_EN: 'man juggling, dark-skin-tone, black',
    tags_ES: 'hombre malabares, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1249,
    symbol: '🤹‍♀️',
    tags_EN: 'woman juggling, balls, yellow',
    tags_ES: 'mujer malabares, pelotas, amarillo',
    category: 'gente',
  },
  {
    id: 1250,
    symbol: '🤹🏻‍♀️',
    tags_EN: 'woman juggling, light-skin tone, white',
    tags_ES: 'mujer malabares, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1251,
    symbol: '🤹🏼‍♀️',
    tags_EN: 'woman juggling, medium-light-skin tone, white',
    tags_ES: 'mujer malabares, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1252,
    symbol: '🤹🏽‍♀️',
    tags_EN: 'woman juggling, medium-skin tone, white',
    tags_ES: 'mujer malabares, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1253,
    symbol: '🤹🏾‍♀️',
    tags_EN: 'woman juggling, medium-dark-skin tone, black',
    tags_ES: 'mujer malabares, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1254,
    symbol: '🤹🏿‍♀️',
    tags_EN: 'woman juggling, dark-skin-tone, black',
    tags_ES: 'mujer malabares, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1255,
    symbol: '👫',
    tags_EN: 'man woman, holding, hands',
    tags_ES: 'hombre mujer, cogidos, manos',
    category: 'gente',
  },
  {
    id: 1256,
    symbol: '👬',
    tags_EN: 'men, holding, hands',
    tags_ES: 'hombres, cogidos, manos',
    category: 'gente',
  },
  {
    id: 1257,
    symbol: '👭',
    tags_EN: 'women, holding, hands',
    tags_ES: 'mujeres, cogidas, manos',
    category: 'gente',
  },
  {
    id: 1258,
    symbol: '💏',
    tags_EN: 'kiss, love, heart',
    tags_ES: 'beso, amor, corazón',
    category: 'gente',
  },
  {
    id: 1259,
    symbol: '👩‍❤️‍💋‍👨',
    tags_EN: 'kiss, woman, man',
    tags_ES: 'beso, mujer, hombre',
    category: 'gente',
  },
  {
    id: 1260,
    symbol: '👨‍❤️‍💋‍👨',
    tags_EN: 'kiss, man, man',
    tags_ES: 'beso, hombre, hombre',
    category: 'gente',
  },
  {
    id: 1261,
    symbol: '👩‍❤️‍💋‍👩',
    tags_EN: 'kiss, woman, woman',
    tags_ES: 'beso, mujer, mujer',
    category: 'gente',
  },
  {
    id: 1262,
    symbol: '💑',
    tags_EN: 'couple, heart, love',
    tags_ES: 'pareja, corazón, amor',
    category: 'gente',
  },
  {
    id: 1263,
    symbol: '👩‍❤️‍👨',
    tags_EN: 'couple, heart, love',
    tags_ES: 'pareja, corazón, amor',
    category: 'gente',
  },
  {
    id: 1264,
    symbol: '👨‍❤️‍👨',
    tags_EN: 'couple, gay, love',
    tags_ES: 'pareja, gay, amor',
    category: 'gente',
  },
  {
    id: 1265,
    symbol: '👩‍❤️‍👩',
    tags_EN: 'couple, lesbian, love',
    tags_ES: 'pareja, lesbiana, amor',
    category: 'gente',
  },
  {
    id: 1266,
    symbol: '👪',
    tags_EN: 'family, parents, kid',
    tags_ES: 'familia, padres, hijo',
    category: 'gente',
  },
  {
    id: 1267,
    symbol: '👨‍👩‍👦',
    tags_EN: 'family, parents, boy',
    tags_ES: 'familia, padres, hijo',
    category: 'gente',
  },
  {
    id: 1268,
    symbol: '👨‍👩‍👧',
    tags_EN: 'family, parents, girl',
    tags_ES: 'familia, padres, hija',
    category: 'gente',
  },
  {
    id: 1269,
    symbol: '👨‍👩‍👧‍👦',
    tags_EN: 'family, parents, kids',
    tags_ES: 'familia, padres, hijos',
    category: 'gente',
  },
  {
    id: 1270,
    symbol: '👨‍👩‍👦‍👦',
    tags_EN: 'family, parents, kids',
    tags_ES: 'familia, padres, hijos',
    category: 'gente',
  },
  {
    id: 1271,
    symbol: '👨‍👩‍👧‍👧',
    tags_EN: 'family, parents, kids',
    tags_ES: 'familia, padres, hijas',
    category: 'gente',
  },
  {
    id: 1272,
    symbol: '👨‍👨‍👦',
    tags_EN: 'family, men, boy',
    tags_ES: 'famila, hombres, hijo',
    category: 'gente',
  },
  {
    id: 1273,
    symbol: '👨‍👨‍👧',
    tags_EN: 'family, men, girl',
    tags_ES: 'familia, hombres, hija',
    category: 'gente',
  },
  {
    id: 1274,
    symbol: '👨‍👨‍👧‍👦',
    tags_EN: 'family, men, kids',
    tags_ES: 'familia, hombres, hijos',
    category: 'gente',
  },
  {
    id: 1275,
    symbol: '👨‍👨‍👦‍👦',
    tags_EN: 'family, men, kids',
    tags_ES: 'familia, hombres, hijos',
    category: 'gente',
  },
  {
    id: 1276,
    symbol: '👨‍👨‍👧‍👧',
    tags_EN: 'family, men, kids',
    tags_ES: 'familia, hombres, hijas',
    category: 'gente',
  },
  {
    id: 1277,
    symbol: '👩‍👩‍👦',
    tags_EN: 'family, women, boy',
    tags_ES: 'familia, hombres, hijo',
    category: 'gente',
  },
  {
    id: 1278,
    symbol: '👩‍👩‍👧',
    tags_EN: 'family, women, girl',
    tags_ES: 'familia, hombres, hija',
    category: 'gente',
  },
  {
    id: 1279,
    symbol: '👩‍👩‍👧‍👦',
    tags_EN: 'family, women, kids',
    tags_ES: 'familia, hombres, hijos',
    category: 'gente',
  },
  {
    id: 1280,
    symbol: '👩‍👩‍👦‍👦',
    tags_EN: 'family, women, kids',
    tags_ES: 'familia, hombres, hijos',
    category: 'gente',
  },
  {
    id: 1281,
    symbol: '👩‍👩‍👧‍👧',
    tags_EN: 'family, women, kids',
    tags_ES: 'familia, hombres, hijas',
    category: 'gente',
  },
  {
    id: 1282,
    symbol: '👨‍👦',
    tags_EN: 'family, man, boy',
    tags_ES: 'familia, hombre, hijo',
    category: 'gente',
  },
  {
    id: 1283,
    symbol: '👨‍👦‍👦',
    tags_EN: 'family, man, boys',
    tags_ES: 'familia, hombre, hijos',
    category: 'gente',
  },
  {
    id: 1284,
    symbol: '👨‍👧',
    tags_EN: 'family, man, girl',
    tags_ES: 'familia, hombre, hija',
    category: 'gente',
  },
  {
    id: 1285,
    symbol: '👨‍👧‍👦',
    tags_EN: 'family, man, kids',
    tags_ES: 'familia, hombre, hijos',
    category: 'gente',
  },
  {
    id: 1286,
    symbol: '👨‍👧‍👧',
    tags_EN: 'family, man, girls',
    tags_ES: 'familia, hombre, hijas',
    category: 'gente',
  },
  {
    id: 1287,
    symbol: '👩‍👦',
    tags_EN: 'family, woman, boy',
    tags_ES: 'familia, mujer, hijo',
    category: 'gente',
  },
  {
    id: 1288,
    symbol: '👩‍👦‍👦',
    tags_EN: 'family, woman, kids',
    tags_ES: 'familia, mujer, hijos',
    category: 'gente',
  },
  {
    id: 1289,
    symbol: '👩‍👧',
    tags_EN: 'family, woman, girl',
    tags_ES: 'familia, mujer, hija',
    category: 'gente',
  },
  {
    id: 1290,
    symbol: '👩‍👧‍👦',
    tags_EN: 'family, woman, kids',
    tags_ES: 'familia, mujer, hijos',
    category: 'gente',
  },
  {
    id: 1291,
    symbol: '👩‍👧‍👧',
    tags_EN: 'family, woman, kids',
    tags_ES: 'familia, mujer, hijas',
    category: 'gente',
  },
  {
    id: 1292,
    symbol: '🤳',
    tags_EN: 'selfie, photo, yellow',
    tags_ES: 'selfie, foto, amarillo',
    category: 'gente',
  },
  {
    id: 1293,
    symbol: '🤳🏻',
    tags_EN: 'selfie, light-skin tone, white',
    tags_ES: 'selfie, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1294,
    symbol: '🤳🏼',
    tags_EN: 'selfie, medium-light-skin tone, white',
    tags_ES: 'selfie, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1295,
    symbol: '🤳🏽',
    tags_EN: 'selfie, medium-skin tone, white',
    tags_ES: 'selfie, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1296,
    symbol: '🤳🏾',
    tags_EN: 'selfie, medium-dark-skin tone, black',
    tags_ES: 'selfie, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1297,
    symbol: '🤳🏿',
    tags_EN: 'selfie, dark-skin-tone, black',
    tags_ES: 'selfie, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1298,
    symbol: '💪',
    tags_EN: 'flexed biceps, strong, yellow',
    tags_ES: 'bíceps, fuerza, amarillo',
    category: 'gente',
  },
  {
    id: 1299,
    symbol: '💪🏻',
    tags_EN: 'flexed biceps, light-skin tone, white',
    tags_ES: 'bíceps, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1300,
    symbol: '💪🏼',
    tags_EN: 'flexed biceps, medium-light-skin tone, white',
    tags_ES: 'bíceps, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1301,
    symbol: '💪🏽',
    tags_EN: 'flexed biceps, medium-skin tone, white',
    tags_ES: 'bíceps, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1302,
    symbol: '💪🏾',
    tags_EN: 'flexed biceps, medium-dark-skin tone, black',
    tags_ES: 'bíceps, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1303,
    symbol: '💪🏿',
    tags_EN: 'flexed biceps, dark-skin-tone, black',
    tags_ES: 'bíceps, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1316,
    symbol: '👈',
    tags_EN: 'index pointing left, go left, yellow',
    tags_ES: 'índice izquierda, ir izquierda, amarillo',
    category: 'gente',
  },
  {
    id: 1317,
    symbol: '👈🏻',
    tags_EN: 'index pointing left, light-skin tone, white',
    tags_ES: 'índice izquierda, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1318,
    symbol: '👈🏼',
    tags_EN: 'index pointing left, medium-light-skin tone, white',
    tags_ES: 'índice izquierda, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1319,
    symbol: '👈🏽',
    tags_EN: 'index pointing left, medium-skin tone, white',
    tags_ES: 'índice izquierda, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1320,
    symbol: '👈🏾',
    tags_EN: 'index pointing left, medium-dark-skin tone, black',
    tags_ES: 'índice izquierda, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1321,
    symbol: '👈🏿',
    tags_EN: 'index pointing left, dark-skin-tone, black',
    tags_ES: 'índice izquierda, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1322,
    symbol: '👉',
    tags_EN: 'index pointing right, go right, yellow',
    tags_ES: 'índice derecha, ir derecha, amarillo',
    category: 'gente',
  },
  {
    id: 1323,
    symbol: '👉🏻',
    tags_EN: 'index pointing right, light-skin tone, white',
    tags_ES: 'índice derecha, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1324,
    symbol: '👉🏼',
    tags_EN: 'index pointing right, medium-light-skin tone, white',
    tags_ES: 'índice derecha, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1325,
    symbol: '👉🏽',
    tags_EN: 'index pointing right, medium-skin tone, white',
    tags_ES: 'índice derecha, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1326,
    symbol: '👉🏾',
    tags_EN: 'index pointing right, medium-dark-skin tone, black',
    tags_ES: 'índice derecha, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1327,
    symbol: '👉🏿',
    tags_EN: 'index pointing right, dark-skin-tone, black',
    tags_ES: 'índice derecha, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1328,
    symbol: '☝',
    tags_EN: 'index pointing up, go up, yellow',
    tags_ES: 'índice arriba, ir arriba, amarillo',
    category: 'gente',
  },
  {
    id: 1329,
    symbol: '☝🏻',
    tags_EN: 'index pointing up, light-skin tone, white',
    tags_ES: 'índice arriba, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1330,
    symbol: '☝🏼',
    tags_EN: 'index pointing up, medium-light-skin tone, white',
    tags_ES: 'índice arriba, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1331,
    symbol: '☝🏽',
    tags_EN: 'index pointing up, medium-skin tone, white',
    tags_ES: 'índice arriba, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1332,
    symbol: '☝🏾',
    tags_EN: 'index pointing up, medium-dark-skin tone, black',
    tags_ES: 'índice arriba, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1333,
    symbol: '☝🏿',
    tags_EN: 'index pointing up, dark-skin-tone, black',
    tags_ES: 'índice arriba, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1334,
    symbol: '👆',
    tags_EN: 'backhand index pointing up, go up, yellow',
    tags_ES: 'índice arriba, ir arriba, amarillo',
    category: 'gente',
  },
  {
    id: 1335,
    symbol: '👆🏻',
    tags_EN: 'backhand index pointing up, light-skin tone, white',
    tags_ES: 'índice arriba, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1336,
    symbol: '👆🏼',
    tags_EN: 'backhand index pointing up, medium-light-skin tone, white',
    tags_ES: 'índice arriba, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1337,
    symbol: '👆🏽',
    tags_EN: 'backhand index pointing up, medium-skin tone, white',
    tags_ES: 'índice arriba, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1338,
    symbol: '👆🏾',
    tags_EN: 'backhand index pointing up, medium-dark-skin tone, black',
    tags_ES: 'índice arriba, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1339,
    symbol: '👆🏿',
    tags_EN: 'backhand index pointing up, dark-skin-tone, black',
    tags_ES: 'índice arriba, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1340,
    symbol: '🖕',
    tags_EN: 'middle finger, fuck you, yellow',
    tags_ES: 'dedo corazón, que te jodan, amarillo',
    category: 'gente',
  },
  {
    id: 1341,
    symbol: '🖕🏻',
    tags_EN: 'middle finger, light-skin tone, white',
    tags_ES: 'dedo corazón, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1342,
    symbol: '🖕🏼',
    tags_EN: 'middle finger, medium-light-skin tone, white',
    tags_ES: 'dedo corazón, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1343,
    symbol: '🖕🏽',
    tags_EN: 'middle finger, medium-skin tone, white',
    tags_ES: 'dedo corazón, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1344,
    symbol: '🖕🏾',
    tags_EN: 'middle finger, medium-dark-skin tone, black',
    tags_ES: 'dedo corazón, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1345,
    symbol: '🖕🏿',
    tags_EN: 'middle finger, dark-skin-tone, black',
    tags_ES: 'dedo corazón, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1346,
    symbol: '👇',
    tags_EN: 'index pointing down, go down, yellow',
    tags_ES: 'índice abajo, ir abajo, amarillo',
    category: 'gente',
  },
  {
    id: 1347,
    symbol: '👇🏻',
    tags_EN: 'index pointing down, light-skin tone, white',
    tags_ES: 'índice abajo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1348,
    symbol: '👇🏼',
    tags_EN: 'index pointing down, medium-light-skin tone, white',
    tags_ES: 'índice abajo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1349,
    symbol: '👇🏽',
    tags_EN: 'index pointing down, medium-skin tone, white',
    tags_ES: 'índice abajo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1350,
    symbol: '👇🏾',
    tags_EN: 'index pointing down, medium-dark-skin tone, black',
    tags_ES: 'índice abajo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1351,
    symbol: '👇🏿',
    tags_EN: 'index pointing down, dark-skin-tone, black',
    tags_ES: 'índice abajo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1352,
    symbol: '✌',
    tags_EN: 'victory hand, victoria, yellow',
    tags_ES: 'victoria, dedos, amarillo',
    category: 'gente',
  },
  {
    id: 1353,
    symbol: '✌🏻',
    tags_EN: 'victory hand, light-skin tone, white',
    tags_ES: 'victoria, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1354,
    symbol: '✌🏼',
    tags_EN: 'victory hand, medium-light-skin tone, white',
    tags_ES: 'victoria, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1355,
    symbol: '✌🏽',
    tags_EN: 'victory hand, medium-skin tone, white',
    tags_ES: 'victoria, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1356,
    symbol: '✌🏾',
    tags_EN: 'victory hand, medium-dark-skin tone, black',
    tags_ES: 'victoria, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1357,
    symbol: '✌🏿',
    tags_EN: 'victory hand, dark-skin-tone, black',
    tags_ES: 'victoria, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1358,
    symbol: '🤞',
    tags_EN: 'crossed fingers, cross, yellow',
    tags_ES: 'cruzar dedos, cruzado, amarillo',
    category: 'gente',
  },
  {
    id: 1359,
    symbol: '🤞🏻',
    tags_EN: 'crossed fingers, light-skin tone, white',
    tags_ES: 'cruzar dedos, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1360,
    symbol: '🤞🏼',
    tags_EN: 'crossed fingers, medium-light-skin tone, white',
    tags_ES: 'cruzar dedos, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1361,
    symbol: '🤞🏽',
    tags_EN: 'crossed fingers, medium-skin tone, white',
    tags_ES: 'cruzar dedos, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1362,
    symbol: '🤞🏾',
    tags_EN: 'crossed fingers, medium-dark-skin tone, black',
    tags_ES: 'cruzar dedos, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1363,
    symbol: '🤞🏿',
    tags_EN: 'crossed fingers, dark-skin-tone, black',
    tags_ES: 'cruzar dedos, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1364,
    symbol: '🖖',
    tags_EN: 'vulcan salute, hi, yellow',
    tags_ES: 'saludo uve, hola, amarillo',
    category: 'gente',
  },
  {
    id: 1365,
    symbol: '🖖🏻',
    tags_EN: 'vulcan salute, light-skin tone, white',
    tags_ES: 'saludo uve, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1366,
    symbol: '🖖🏼',
    tags_EN: 'vulcan salute, medium-light-skin tone, white',
    tags_ES: 'saludo uve, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1367,
    symbol: '🖖🏽',
    tags_EN: 'vulcan salute, medium-skin tone, white',
    tags_ES: 'saludo uve, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1368,
    symbol: '🖖🏾',
    tags_EN: 'vulcan salute, medium-dark-skin tone, black',
    tags_ES: 'saludo uve, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1369,
    symbol: '🖖🏿',
    tags_EN: 'vulcan salute, dark-skin-tone, black',
    tags_ES: 'saludo uve, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1370,
    symbol: '🤘',
    tags_EN: 'sign horns, heavy, yellow',
    tags_ES: 'cuernos, rock, amarillo',
    category: 'gente',
  },
  {
    id: 1371,
    symbol: '🤘🏻',
    tags_EN: 'sign horns, light-skin tone, white',
    tags_ES: 'cuernos, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1372,
    symbol: '🤘🏼',
    tags_EN: 'sign horns, medium-light-skin tone, white',
    tags_ES: 'cuernos, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1373,
    symbol: '🤘🏽',
    tags_EN: 'sign horns, medium-skin tone, white',
    tags_ES: 'cuernos, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1374,
    symbol: '🤘🏾',
    tags_EN: 'sign horns, medium-dark-skin tone, black',
    tags_ES: 'cuernos, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1375,
    symbol: '🤘🏿',
    tags_EN: 'sign horns, dark-skin-tone, black',
    tags_ES: 'cuernos, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1376,
    symbol: '🤙',
    tags_EN: 'call me, phone, yellow',
    tags_ES: 'llámame, teléfono, amarillo',
    category: 'gente',
  },
  {
    id: 1377,
    symbol: '🤙🏻',
    tags_EN: 'call me, light-skin tone, white',
    tags_ES: 'llámame, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1378,
    symbol: '🤙🏼',
    tags_EN: 'call me, medium-light-skin tone, white',
    tags_ES: 'llámame, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1379,
    symbol: '🤙🏽',
    tags_EN: 'call me, medium-skin tone, white',
    tags_ES: 'llámame, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1380,
    symbol: '🤙🏾',
    tags_EN: 'call me, medium-dark-skin tone, black',
    tags_ES: 'llámame, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1381,
    symbol: '🤙🏿',
    tags_EN: 'call me, dark-skin-tone, black',
    tags_ES: 'llámame, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1382,
    symbol: '🖐',
    tags_EN: 'fingers splayed, hand, yellow',
    tags_ES: 'dedos abiertos, mano, amarillo',
    category: 'gente',
  },
  {
    id: 1383,
    symbol: '🖐🏻',
    tags_EN: 'fingers splayed, light-skin tone, white',
    tags_ES: 'dedos abiertos, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1384,
    symbol: '🖐🏼',
    tags_EN: 'fingers splayed, medium-light-skin tone, white',
    tags_ES: 'dedos abiertos, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1385,
    symbol: '🖐🏽',
    tags_EN: 'fingers splayed, medium-skin tone, white',
    tags_ES: 'dedos abiertos, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1386,
    symbol: '🖐🏾',
    tags_EN: 'fingers splayed, medium-dark-skin tone, black',
    tags_ES: 'dedos abiertos, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1387,
    symbol: '🖐🏿',
    tags_EN: 'fingers splayed, dark-skin-tone, black',
    tags_ES: 'dedos abiertos, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1388,
    symbol: '✋',
    tags_EN: 'raised hand, stop, yellow',
    tags_ES: 'mano arriba, parar, amarillo',
    category: 'gente',
  },
  {
    id: 1389,
    symbol: '✋🏻',
    tags_EN: 'raised hand, light-skin tone, white',
    tags_ES: 'mano arriba, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1390,
    symbol: '✋🏼',
    tags_EN: 'raised hand, medium-light-skin tone, white',
    tags_ES: 'mano arriba, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1391,
    symbol: '✋🏽',
    tags_EN: 'raised hand, medium-skin tone, white',
    tags_ES: 'mano arriba, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1392,
    symbol: '✋🏾',
    tags_EN: 'raised hand, medium-dark-skin tone, black',
    tags_ES: 'mano arriba, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1393,
    symbol: '✋🏿',
    tags_EN: 'raised hand, dark-skin-tone, black',
    tags_ES: 'mano arriba, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1394,
    symbol: '👌',
    tags_EN: 'ok hand, good, yellow',
    tags_ES: 'mano ok, bien, amarillo',
    category: 'gente',
  },
  {
    id: 1395,
    symbol: '👌🏻',
    tags_EN: 'ok hand, light-skin tone, white',
    tags_ES: 'mano ok, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1396,
    symbol: '👌🏼',
    tags_EN: 'ok hand, medium-light-skin tone, white',
    tags_ES: 'mano ok, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1397,
    symbol: '👌🏽',
    tags_EN: 'ok hand, medium-skin tone, white',
    tags_ES: 'mano ok, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1398,
    symbol: '👌🏾',
    tags_EN: 'ok hand, medium-dark-skin tone, black',
    tags_ES: 'mano ok, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1399,
    symbol: '👌🏿',
    tags_EN: 'ok hand, dark-skin-tone, black',
    tags_ES: 'mano ok, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1400,
    symbol: '👍',
    tags_EN: 'thumbs up, okay, yellow',
    tags_ES: 'dedo pulgar, me gusta, amarillo',
    category: 'gente',
  },
  {
    id: 1401,
    symbol: '👍🏻',
    tags_EN: 'thumbs up, light-skin tone, white',
    tags_ES: 'dedo pulgar, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1402,
    symbol: '👍🏼',
    tags_EN: 'thumbs up, medium-light-skin tone, white',
    tags_ES: 'dedo pulgar, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1403,
    symbol: '👍🏽',
    tags_EN: 'thumbs up, medium-skin tone, white',
    tags_ES: 'dedo pulgar, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1404,
    symbol: '👍🏾',
    tags_EN: 'thumbs up, medium-dark-skin tone, black',
    tags_ES: 'dedo pulgar, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1405,
    symbol: '👍🏿',
    tags_EN: 'thumbs up, dark-skin-tone, black',
    tags_ES: 'dedo pulgar, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1406,
    symbol: '👎',
    tags_EN: 'thumbs down, not okay, yellow',
    tags_ES: 'dedo pulgar, no me gusta, amarillo',
    category: 'gente',
  },
  {
    id: 1407,
    symbol: '👎🏻',
    tags_EN: 'thumbs down, light-skin tone, white',
    tags_ES: 'dedo pulgar, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1408,
    symbol: '👎🏼',
    tags_EN: 'thumbs down, medium-light-skin tone, white',
    tags_ES: 'dedo pulgar, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1409,
    symbol: '👎🏽',
    tags_EN: 'thumbs down, medium-skin tone, white',
    tags_ES: 'dedo pulgar, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1410,
    symbol: '👎🏾',
    tags_EN: 'thumbs down, medium-dark-skin tone, black',
    tags_ES: 'dedo pulgar, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1411,
    symbol: '👎🏿',
    tags_EN: 'thumbs down, dark-skin-tone, black',
    tags_ES: 'dedo pulgar, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1412,
    symbol: '✊',
    tags_EN: 'raised fist, fist, yellow',
    tags_ES: 'puño alto, vamos, amarillo',
    category: 'gente',
  },
  {
    id: 1413,
    symbol: '✊🏻',
    tags_EN: 'raised fist, light-skin tone, white',
    tags_ES: 'puño alto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1414,
    symbol: '✊🏼',
    tags_EN: 'raised fist, medium-light-skin tone, white',
    tags_ES: 'puño alto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1415,
    symbol: '✊🏽',
    tags_EN: 'raised fist, medium-skin tone, white',
    tags_ES: 'puño alto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1416,
    symbol: '✊🏾',
    tags_EN: 'raised fist, medium-dark-skin tone, black',
    tags_ES: 'puño alto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1417,
    symbol: '✊🏿',
    tags_EN: 'raised fist, dark-skin-tone, black',
    tags_ES: 'puño alto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1418,
    symbol: '👊',
    tags_EN: 'oncoming fist, hey yo, yellow',
    tags_ES: 'puño saludo, qué pasa, amarillo',
    category: 'gente',
  },
  {
    id: 1419,
    symbol: '👊🏻',
    tags_EN: 'oncoming fist, light-skin tone, white',
    tags_ES: 'puño saludo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1420,
    symbol: '👊🏼',
    tags_EN: 'oncoming fist, medium-light-skin tone, white',
    tags_ES: 'puño saludo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1421,
    symbol: '👊🏽',
    tags_EN: 'oncoming fist, medium-skin tone, white',
    tags_ES: 'puño saludo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1422,
    symbol: '👊🏾',
    tags_EN: 'oncoming fist, medium-dark-skin tone, black',
    tags_ES: 'puño saludo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1423,
    symbol: '👊🏿',
    tags_EN: 'oncoming fist, dark-skin-tone, black',
    tags_ES: 'puño saludo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1424,
    symbol: '🤛',
    tags_EN: 'left-facing fist, hey yo, yellow',
    tags_ES: 'puño saludo izquierda, qué pasa, amarillo',
    category: 'gente',
  },
  {
    id: 1425,
    symbol: '🤛🏻',
    tags_EN: 'left-facing fist, light-skin tone, white',
    tags_ES: 'puño saludo izquierda, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1426,
    symbol: '🤛🏼',
    tags_EN: 'left-facing fist, medium-light-skin tone, white',
    tags_ES: 'puño saludo izquierda, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1427,
    symbol: '🤛🏽',
    tags_EN: 'left-facing fist, medium-skin tone, white',
    tags_ES: 'puño saludo izquierda, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1428,
    symbol: '🤛🏾',
    tags_EN: 'left-facing fist, medium-dark-skin tone, black',
    tags_ES: 'puño saludo izquierda, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1429,
    symbol: '🤛🏿',
    tags_EN: 'left-facing fist, dark-skin-tone, black',
    tags_ES: 'puño saludo izquierda, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1430,
    symbol: '🤜',
    tags_EN: 'right-facing fist, hey yo, yellow',
    tags_ES: 'puño saludo derecha, qué pasa, amarillo',
    category: 'gente',
  },
  {
    id: 1431,
    symbol: '🤜🏻',
    tags_EN: 'right-facing fist, light-skin tone, white',
    tags_ES: 'puño saludo derecha, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1432,
    symbol: '🤜🏼',
    tags_EN: 'right-facing fist, medium-light-skin tone, white',
    tags_ES: 'puño saludo derecha, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1433,
    symbol: '🤜🏽',
    tags_EN: 'right-facing fist, medium-skin tone, white',
    tags_ES: 'puño saludo derecha, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1434,
    symbol: '🤜🏾',
    tags_EN: 'right-facing fist, medium-dark-skin tone, black',
    tags_ES: 'puño saludo derecha, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1435,
    symbol: '🤜🏿',
    tags_EN: 'right-facing fist, dark-skin-tone, black',
    tags_ES: 'puño saludo derecha, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1436,
    symbol: '🤚',
    tags_EN: 'raised back hand, five fingers, yellow',
    tags_ES: 'mano levantada, cinco dedos, amarillo',
    category: 'gente',
  },
  {
    id: 1437,
    symbol: '🤚🏻',
    tags_EN: 'raised back hand, light-skin tone, white',
    tags_ES: 'mano levantada, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1438,
    symbol: '🤚🏼',
    tags_EN: 'raised back hand, medium-light-skin tone, white',
    tags_ES: 'mano levantada, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1439,
    symbol: '🤚🏽',
    tags_EN: 'raised back hand, medium-skin tone, white',
    tags_ES: 'mano levantada, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1440,
    symbol: '🤚🏾',
    tags_EN: 'raised back hand, medium-dark-skin tone, black',
    tags_ES: 'mano levantada, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1441,
    symbol: '🤚🏿',
    tags_EN: 'raised back hand, dark-skin-tone, black',
    tags_ES: 'mano levantada, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1442,
    symbol: '👋',
    tags_EN: 'hi, waving hand, yellow',
    tags_ES: 'hola, saludo, amarillo',
    category: 'gente',
  },
  {
    id: 1443,
    symbol: '👋🏻',
    tags_EN: 'hi, light-skin tone, white',
    tags_ES: 'hola, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1444,
    symbol: '👋🏼',
    tags_EN: 'hi, medium-light-skin tone, white',
    tags_ES: 'hola, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1445,
    symbol: '👋🏽',
    tags_EN: 'hi, medium-skin tone, white',
    tags_ES: 'hola, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1446,
    symbol: '👋🏾',
    tags_EN: 'hi, medium-dark-skin tone, black',
    tags_ES: 'hola, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1447,
    symbol: '👋🏿',
    tags_EN: 'hi, dark-skin-tone, black',
    tags_ES: 'hola, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1448,
    symbol: '🤟',
    tags_EN: 'love you gesture, three fingers, yellow',
    tags_ES: 'te quiero gesto, tres dedos, amarillo',
    category: 'gente',
  },
  {
    id: 1449,
    symbol: '🤟🏻',
    tags_EN: 'love you gesture, light-skin tone, white',
    tags_ES: 'te quiero gesto, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1450,
    symbol: '🤟🏼',
    tags_EN: 'love you gesture, medium-light-skin tone, white',
    tags_ES: 'te quiero gesto, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1451,
    symbol: '🤟🏽',
    tags_EN: 'love you gesture, medium-skin tone, white',
    tags_ES: 'te quiero gesto, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1452,
    symbol: '🤟🏾',
    tags_EN: 'love you gesture, medium-dark-skin tone, black',
    tags_ES: 'te quiero gesto, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1453,
    symbol: '🤟🏿',
    tags_EN: 'love you gesture, dark-skin-tone, black',
    tags_ES: 'te quiero gesto, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1454,
    symbol: '✍',
    tags_EN: 'writing hand, write, yellow',
    tags_ES: 'mano escribiendo, escritura, amarillo',
    category: 'gente',
  },
  {
    id: 1455,
    symbol: '✍🏻',
    tags_EN: 'writing hand, light-skin tone, white',
    tags_ES: 'mano escribiendo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1456,
    symbol: '✍🏼',
    tags_EN: 'writing hand, medium-light-skin tone, white',
    tags_ES: 'mano escribiendo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1457,
    symbol: '✍🏽',
    tags_EN: 'writing hand, medium-skin tone, white',
    tags_ES: 'mano escribiendo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1458,
    symbol: '✍🏾',
    tags_EN: 'writing hand, medium-dark-skin tone, black',
    tags_ES: 'mano escribiendo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1459,
    symbol: '✍🏿',
    tags_EN: 'writing hand, dark-skin-tone, black',
    tags_ES: 'mano escribiendo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1460,
    symbol: '👏',
    tags_EN: 'clapping hands, applause, yellow',
    tags_ES: 'manos aplaudiendo, aplauso, amarillo',
    category: 'gente',
  },
  {
    id: 1461,
    symbol: '👏🏻',
    tags_EN: 'clapping hands, light-skin tone, white',
    tags_ES: 'manos aplaudiendo, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1462,
    symbol: '👏🏼',
    tags_EN: 'clapping hands, medium-light-skin tone, white',
    tags_ES: 'manos aplaudiendo, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1463,
    symbol: '👏🏽',
    tags_EN: 'clapping hands, medium-skin tone, white',
    tags_ES: 'manos aplaudiendo, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1464,
    symbol: '👏🏾',
    tags_EN: 'clapping hands, medium-dark-skin tone, black',
    tags_ES: 'manos aplaudiendo, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1465,
    symbol: '👏🏿',
    tags_EN: 'clapping hands, dark-skin-tone, black',
    tags_ES: 'manos aplaudiendo, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1466,
    symbol: '👐',
    tags_EN: 'open hands, hug, yellow',
    tags_ES: 'manos abiertas, abrazo, amarillo',
    category: 'gente',
  },
  {
    id: 1467,
    symbol: '👐🏻',
    tags_EN: 'open hands, light-skin tone, white',
    tags_ES: 'manos abiertas, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1468,
    symbol: '👐🏼',
    tags_EN: 'open hands, medium-light-skin tone, white',
    tags_ES: 'manos abiertas, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1469,
    symbol: '👐🏽',
    tags_EN: 'open hands, medium-skin tone, white',
    tags_ES: 'manos abiertas, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1470,
    symbol: '👐🏾',
    tags_EN: 'open hands, medium-dark-skin tone, black',
    tags_ES: 'manos abiertas, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1471,
    symbol: '👐🏿',
    tags_EN: 'open hands, dark-skin-tone, black',
    tags_ES: 'manos abiertas, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1472,
    symbol: '🙌',
    tags_EN: 'raising hands, yeah, yellow',
    tags_ES: 'manos arriba, vamos, amarillo',
    category: 'gente',
  },
  {
    id: 1473,
    symbol: '🙌🏻',
    tags_EN: 'raising hands, light-skin tone, white',
    tags_ES: 'manos arriba, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1474,
    symbol: '🙌🏼',
    tags_EN: 'raising hands, medium-light-skin tone, white',
    tags_ES: 'manos arriba, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1475,
    symbol: '🙌🏽',
    tags_EN: 'raising hands, medium-skin tone, white',
    tags_ES: 'manos arriba, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1476,
    symbol: '🙌🏾',
    tags_EN: 'raising hands, medium-dark-skin tone, black',
    tags_ES: 'manos arriba, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1477,
    symbol: '🙌🏿',
    tags_EN: 'raising hands, dark-skin-tone, black',
    tags_ES: 'manos arriba, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1478,
    symbol: '🤲',
    tags_EN: 'palms up together, give me, yellow',
    tags_ES: 'palmas juntas, dame algo, amarillo',
    category: 'gente',
  },
  {
    id: 1479,
    symbol: '🤲🏻',
    tags_EN: 'palms up together, light-skin tone, white',
    tags_ES: 'palmas juntas, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1480,
    symbol: '🤲🏼',
    tags_EN: 'palms up together, medium-light-skin tone, white',
    tags_ES: 'palmas juntas, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1481,
    symbol: '🤲🏽',
    tags_EN: 'palms up together, medium-skin tone, white',
    tags_ES: 'palmas juntas, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1482,
    symbol: '🤲🏾',
    tags_EN: 'palms up together, medium-dark-skin tone, black',
    tags_ES: 'palmas juntas, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1483,
    symbol: '🤲🏿',
    tags_EN: 'palms up together, dark-skin-tone, black',
    tags_ES: 'palmas juntas, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1484,
    symbol: '🙏',
    tags_EN: 'folded hands, sorry, yellow',
    tags_ES: 'por favor, perdón, amarillo',
    category: 'gente',
  },
  {
    id: 1485,
    symbol: '🙏🏻',
    tags_EN: 'folded hands, light-skin tone, white',
    tags_ES: 'por favor, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1486,
    symbol: '🙏🏼',
    tags_EN: 'folded hands, medium-light-skin tone, white',
    tags_ES: 'por favor, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1487,
    symbol: '🙏🏽',
    tags_EN: 'folded hands, medium-skin tone, white',
    tags_ES: 'por favor, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1488,
    symbol: '🙏🏾',
    tags_EN: 'folded hands, medium-dark-skin tone, black',
    tags_ES: 'por favor, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1489,
    symbol: '🙏🏿',
    tags_EN: 'folded hands, dark-skin-tone, black',
    tags_ES: 'por favor, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1490,
    symbol: '🤝',
    tags_EN: 'handshake, hands, men',
    tags_ES: 'saludo, manos, hombres',
    category: 'gente',
  },
  {
    id: 1491,
    symbol: '💅',
    tags_EN: 'nail polish, manicure, yellow',
    tags_ES: 'pintar uñas, manicura, amarillo',
    category: 'gente',
  },
  {
    id: 1492,
    symbol: '💅🏻',
    tags_EN: 'nail polish, light-skin tone, white',
    tags_ES: 'pintar uñas, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1493,
    symbol: '💅🏼',
    tags_EN: 'nail polish, medium-light-skin tone, white',
    tags_ES: 'pintar uñas, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1494,
    symbol: '💅🏽',
    tags_EN: 'nail polish, medium-skin tone, white',
    tags_ES: 'pintar uñas, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1495,
    symbol: '💅🏾',
    tags_EN: 'nail polish, medium-dark-skin tone, black',
    tags_ES: 'pintar uñas, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1496,
    symbol: '💅🏿',
    tags_EN: 'nail polish, dark-skin-tone, black',
    tags_ES: 'pintar uñas, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1497,
    symbol: '👂',
    tags_EN: 'ear, hear, yellow',
    tags_ES: 'oreja, oír, amarillo',
    category: 'gente',
  },
  {
    id: 1498,
    symbol: '👂🏻',
    tags_EN: 'ear, light-skin tone, white',
    tags_ES: 'oreja, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1499,
    symbol: '👂🏼',
    tags_EN: 'ear, medium-light-skin tone, white',
    tags_ES: 'oreja, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1500,
    symbol: '👂🏽',
    tags_EN: 'ear, medium-skin tone, white',
    tags_ES: 'oreja, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1501,
    symbol: '👂🏾',
    tags_EN: 'ear, medium-dark-skin tone, black',
    tags_ES: 'oreja, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1502,
    symbol: '👂🏿',
    tags_EN: 'ear, dark-skin-tone, black',
    tags_ES: 'oreja, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1503,
    symbol: '👃',
    tags_EN: 'nose, manicure, yellow',
    tags_ES: 'nariz, oler, amarillo',
    category: 'gente',
  },
  {
    id: 1504,
    symbol: '👃🏻',
    tags_EN: 'nose, light-skin tone, white',
    tags_ES: 'nariz, tono claro piel, blanco',
    category: 'gente',
  },
  {
    id: 1505,
    symbol: '👃🏼',
    tags_EN: 'nose, medium-light-skin tone, white',
    tags_ES: 'nariz, tono claro medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1506,
    symbol: '👃🏽',
    tags_EN: 'nose, medium-skin tone, white',
    tags_ES: 'nariz, tono medio piel, blanco',
    category: 'gente',
  },
  {
    id: 1507,
    symbol: '👃🏾',
    tags_EN: 'nose, medium-dark-skin tone, black',
    tags_ES: 'nariz, tono medio-oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1508,
    symbol: '👃🏿',
    tags_EN: 'nose, dark-skin-tone, black',
    tags_ES: 'nariz, tono oscuro piel, negro',
    category: 'gente',
  },
  {
    id: 1513,
    symbol: '👣',
    tags_EN: 'footprints, feet, prints',
    tags_ES: 'huellas, pies, marcas',
    category: 'gente',
  },
  {
    id: 1514,
    symbol: '👀',
    tags_EN: 'eyes, big, two',
    tags_ES: 'ojos, grandes, dos',
    category: 'gente',
  },
  {
    id: 1515,
    symbol: '👁',
    tags_EN: 'eyes, big, one',
    tags_ES: 'ojos, grande, uno',
    category: 'gente',
  },
  {
    id: 1516,
    symbol: '👁️‍🗨️',
    tags_EN: 'eyes, speech, bubble',
    tags_ES: 'ojos, burbuja, chat',
    category: 'gente',
  },
  {
    id: 1517,
    symbol: '🧠',
    tags_EN: 'brain, head, think',
    tags_ES: 'cerebro, cabeza, pensar',
    category: 'gente',
  },
  {
    id: 1520,
    symbol: '👅',
    tags_EN: 'tongue, pink, mouth',
    tags_ES: 'lengua, rosa, boca',
    category: 'gente',
  },
  {
    id: 1521,
    symbol: '👄',
    tags_EN: 'mouth, lips, pink',
    tags_ES: 'boca, labios, rosa',
    category: 'gente',
  },
  {
    id: 1522,
    symbol: '💋',
    tags_EN: 'kiss, mark, lipstick',
    tags_ES: 'beso, marca, pintalabios',
    category: 'gente',
  },
  {
    id: 1523,
    symbol: '💘',
    tags_EN: 'heart, arrow, love',
    tags_ES: 'corazón, flecha, amor',
    category: 'gente',
  },
  {
    id: 1524,
    symbol: '❤',
    tags_EN: 'red, heart, love',
    tags_ES: 'corazón, rojo, amor',
    category: 'gente',
  },
  {
    id: 1525,
    symbol: '💓',
    tags_EN: 'beating, heart, love',
    tags_ES: 'corazón, latiendo, amor',
    category: 'gente',
  },
  {
    id: 1526,
    symbol: '💔',
    tags_EN: 'broken, heart, love',
    tags_ES: 'corazón, roto, amor',
    category: 'gente',
  },
  {
    id: 1527,
    symbol: '💕',
    tags_EN: 'two, hearts, love',
    tags_ES: 'dos, corazones, amor',
    category: 'gente',
  },
  {
    id: 1528,
    symbol: '💖',
    tags_EN: 'sparkling, heart, love',
    tags_ES: 'corazón, brillante, amor',
    category: 'gente',
  },
  {
    id: 1529,
    symbol: '💗',
    tags_EN: 'growing, heart, love',
    tags_ES: 'corazón, creciendo, amor',
    category: 'gente',
  },
  {
    id: 1530,
    symbol: '💙',
    tags_EN: 'blue, heart, love',
    tags_ES: 'corazón, azul, amor',
    category: 'gente',
  },
  {
    id: 1531,
    symbol: '💚',
    tags_EN: 'green, heart, love',
    tags_ES: 'corazón, verde, amor',
    category: 'gente',
  },
  {
    id: 1532,
    symbol: '💛',
    tags_EN: 'yellow, heart, love',
    tags_ES: 'corazón, amarillo, amor',
    category: 'gente',
  },
  {
    id: 1533,
    symbol: '🧡',
    tags_EN: 'orange, heart, love',
    tags_ES: 'corazón, naranja, amor',
    category: 'gente',
  },
  {
    id: 1534,
    symbol: '💜',
    tags_EN: 'purple, heart, love',
    tags_ES: 'corazón, lila, amor',
    category: 'gente',
  },
  {
    id: 1535,
    symbol: '🖤',
    tags_EN: 'black, heart, love',
    tags_ES: 'corazón, negro, amor',
    category: 'gente',
  },
  {
    id: 1536,
    symbol: '💝',
    tags_EN: 'heart, ribbon, love',
    tags_ES: 'corazón, lazo, amor',
    category: 'gente',
  },
  {
    id: 1537,
    symbol: '💞',
    tags_EN: 'revolving, hearts, love',
    tags_ES: 'corazones, revueltos, amor',
    category: 'gente',
  },
  {
    id: 1538,
    symbol: '💟',
    tags_EN: 'heart, decoration, love',
    tags_ES: 'decoración, corazón, amor',
    category: 'gente',
  },
  {
    id: 1539,
    symbol: '❣',
    tags_EN: 'heavy, heart, exclamation',
    tags_ES: 'exclamación, corazón, fuerte',
    category: 'gente',
  },
  {
    id: 1540,
    symbol: '💌',
    tags_EN: 'love, letter, post',
    tags_ES: 'carta, amor, sobre',
    category: 'objetos',
  },
  {
    id: 1541,
    symbol: '💤',
    tags_EN: 'sleep, zzz, dream',
    tags_ES: 'dormir, zzz, soñar',
    category: 'objetos',
  },
  {
    id: 1542,
    symbol: '💢',
    tags_EN: 'anger, symbol, angry',
    tags_ES: 'símbolo, enfado, enfadado',
    category: 'objetos',
  },
  {
    id: 1543,
    symbol: '💣',
    tags_EN: 'bomb, boom, explosion',
    tags_ES: 'bomba, explosión, explotar',
    category: 'objetos',
  },
  {
    id: 1544,
    symbol: '💥',
    tags_EN: 'collision, crash, flame',
    tags_ES: 'colisión, explosión, colisionar',
    category: 'objetos',
  },
  {
    id: 1545,
    symbol: '💦',
    tags_EN: 'sweat, droplets, water',
    tags_ES: 'gotas, sudor, agua',
    category: 'objetos',
  },
  {
    id: 1546,
    symbol: '💨',
    tags_EN: 'dashing, away, smoke',
    tags_ES: 'humo, irse, pedo',
    category: 'objetos',
  },
  {
    id: 1547,
    symbol: '💫',
    tags_EN: 'dizzy, brilliant, star',
    tags_ES: 'brillante, estrella, cometa',
    category: 'objetos',
  },
  {
    id: 1548,
    symbol: '💬',
    tags_EN: 'speech, balloon, chat',
    tags_ES: 'bocadillo, burbuja, pensamiento',
    category: 'objetos',
  },
  {
    id: 1549,
    symbol: '🗨',
    tags_EN: 'speech, balloon, chat',
    tags_ES: 'bocadillo, burbuja, pensamiento',
    category: 'objetos',
  },
  {
    id: 1550,
    symbol: '🗯',
    tags_EN: 'speech, balloon, chat',
    tags_ES: 'bocadillo, burbuja, pensamiento',
    category: 'objetos',
  },
  {
    id: 1551,
    symbol: '💭',
    tags_EN: 'speech, balloon, chat',
    tags_ES: 'bocadillo, burbuja, pensamiento',
    category: 'objetos',
  },
  {
    id: 1552,
    symbol: '🕳',
    tags_EN: 'hole, black, floor',
    tags_ES: 'agujero, negro, suelo',
    category: 'objetos',
  },
  {
    id: 1553,
    symbol: '👓',
    tags_EN: 'glasses, black, see',
    tags_ES: 'gafas, negras, ver',
    category: 'objetos',
  },
  {
    id: 1554,
    symbol: '🕶',
    tags_EN: 'sunglasses, black, sun',
    tags_ES: 'gafas, sol, negras',
    category: 'objetos',
  },
  {
    id: 1557,
    symbol: '👔',
    tags_EN: 'necktie, shirt, tie',
    tags_ES: 'camisa, corbata, traje',
    category: 'objetos',
  },
  {
    id: 1558,
    symbol: '👕',
    tags_EN: 't-shirt, polo, blue',
    tags_ES: 'camiseta, azul, polo',
    category: 'objetos',
  },
  {
    id: 1559,
    symbol: '👖',
    tags_EN: 'jeans, pants, bottom',
    tags_ES: 'vaqueros, tejanos, jeans',
    category: 'objetos',
  },
  {
    id: 1560,
    symbol: '🧣',
    tags_EN: 'scarf, neck, red',
    tags_ES: 'bufanda, roja, cuello',
    category: 'objetos',
  },
  {
    id: 1561,
    symbol: '🧤',
    tags_EN: 'gloves, green, hands',
    tags_ES: 'guantes, verdes, manos',
    category: 'objetos',
  },
  {
    id: 1562,
    symbol: '🧥',
    tags_EN: 'coat, winter, cold',
    tags_ES: 'chaqueta, invierno, frío',
    category: 'objetos',
  },
  {
    id: 1563,
    symbol: '🧦',
    tags_EN: 'socks, feet, shoes',
    tags_ES: 'calcetines, pies, zapatos',
    category: 'objetos',
  },
  {
    id: 1564,
    symbol: '👗',
    tags_EN: 'dress, girl, green',
    tags_ES: 'vestido, chica, verda',
    category: 'objetos',
  },
  {
    id: 1565,
    symbol: '👘',
    tags_EN: 'kimono, japanese, dress',
    tags_ES: 'kimono, vestido, japonés',
    category: 'objetos',
  },
  {
    id: 1566,
    symbol: '👙',
    tags_EN: 'bikini, swimsuit, beach',
    tags_ES: 'bikini, traje baño, playa',
    category: 'objetos',
  },
  {
    id: 1567,
    symbol: '👚',
    tags_EN: 'woman, clothes, pink',
    tags_ES: 'ropa, mujer, rosa',
    category: 'objetos',
  },
  {
    id: 1568,
    symbol: '👛',
    tags_EN: 'purse, coins, money',
    tags_ES: 'monedero, mujer, rosa',
    category: 'objetos',
  },
  {
    id: 1569,
    symbol: '👜',
    tags_EN: 'handbag, woman, leather',
    tags_ES: 'bolso, cuero, mujer',
    category: 'objetos',
  },
  {
    id: 1570,
    symbol: '👝',
    tags_EN: 'clutch, bag, leather',
    tags_ES: 'neceser, bolsito, mujer',
    category: 'objetos',
  },
  {
    id: 1571,
    symbol: '🛍',
    tags_EN: 'shopping, bags, buy',
    tags_ES: 'bolsas, compra, comprar',
    category: 'objetos',
  },
  {
    id: 1572,
    symbol: '🎒',
    tags_EN: 'school, bakcpack, red',
    tags_ES: 'mochila, colegio, roja',
    category: 'objetos',
  },
  {
    id: 1573,
    symbol: '👞',
    tags_EN: 'man, shoe, dress',
    tags_ES: 'hombre, zapato, vestir',
    category: 'objetos',
  },
  {
    id: 1574,
    symbol: '👟',
    tags_EN: 'running, shoe, sport',
    tags_ES: 'correr, zapatilla, deporte',
    category: 'objetos',
  },
  {
    id: 1577,
    symbol: '👠',
    tags_EN: 'high, heel, shoes',
    tags_ES: 'tacones, zapatos, tacón',
    category: 'objetos',
  },
  {
    id: 1578,
    symbol: '👡',
    tags_EN: 'woman, sandal, shoes',
    tags_ES: 'sandalia, mujer, zapatos',
    category: 'objetos',
  },
  {
    id: 1579,
    symbol: '👢',
    tags_EN: 'woman, boot, shoes',
    tags_ES: 'botas, mujer, zapatos',
    category: 'objetos',
  },
  {
    id: 1580,
    symbol: '👑',
    tags_EN: 'crown, king, hat',
    tags_ES: 'corona, rey, sombrero',
    category: 'objetos',
  },
  {
    id: 1581,
    symbol: '👒',
    tags_EN: 'woman, hat, green',
    tags_ES: 'sombrero, mujer, lazo',
    category: 'objetos',
  },
  {
    id: 1582,
    symbol: '🎩',
    tags_EN: 'top, hat, man',
    tags_ES: 'sombrero, copa, hombre',
    category: 'objetos',
  },
  {
    id: 1583,
    symbol: '🎓',
    tags_EN: 'graduation, cap, college',
    tags_ES: 'sombrero, graduación, universidad',
    category: 'objetos',
  },
  {
    id: 1584,
    symbol: '🧢',
    tags_EN: 'billed, cap, blue',
    tags_ES: 'gorra, azul, hombre',
    category: 'objetos',
  },
  {
    id: 1585,
    symbol: '⛑',
    tags_EN: 'rescue, worker, helmet',
    tags_ES: 'casco, rescate, trabajador',
    category: 'objetos',
  },
  {
    id: 1586,
    symbol: '📿',
    tags_EN: 'prayer, beads, god',
    tags_ES: 'rosario, rezar, dios',
    category: 'objetos',
  },
  {
    id: 1587,
    symbol: '💄',
    tags_EN: 'lipstick, make up, red',
    tags_ES: 'pintalabios, maquillaje, rojo',
    category: 'objetos',
  },
  {
    id: 1588,
    symbol: '💍',
    tags_EN: 'ring, finger, woman',
    tags_ES: 'anillo, dedo, mujer',
    category: 'objetos',
  },
  {
    id: 1589,
    symbol: '💎',
    tags_EN: 'gem, stone, diamond',
    tags_ES: 'diamante, piedra, preciosa',
    category: 'objetos',
  },
  {
    id: 1590,
    symbol: '🐵',
    tags_EN: 'monkey, face, smile',
    tags_ES: 'cara, mono, risa',
    category: 'animales',
  },
  {
    id: 1591,
    symbol: '🐒',
    tags_EN: 'monkey, full, body',
    tags_ES: 'mono, cuerpo, entero',
    category: 'animales',
  },
  {
    id: 1592,
    symbol: '🦍',
    tags_EN: 'gorilla, full, body',
    tags_ES: 'gorila, cuerpo, entero',
    category: 'animales',
  },
  {
    id: 1593,
    symbol: '🐶',
    tags_EN: 'dog, face, bark',
    tags_ES: 'cara, perro, ladrar',
    category: 'animales',
  },
  {
    id: 1594,
    symbol: '🐕',
    tags_EN: 'dog, full, body',
    tags_ES: 'perro, cuerpo, entero',
    category: 'animales',
  },
  {
    id: 1595,
    symbol: '🐩',
    tags_EN: 'poodle, dog, white',
    tags_ES: 'perro, caniche, blanco',
    category: 'animales',
  },
  {
    id: 1596,
    symbol: '🐺',
    tags_EN: 'wolf, face, wild',
    tags_ES: 'lobo, cara, feroz',
    category: 'animales',
  },
  {
    id: 1597,
    symbol: '🦊',
    tags_EN: 'fox, face, wild',
    tags_ES: 'zorro, cara, feroz',
    category: 'animales',
  },
  {
    id: 1599,
    symbol: '🐱',
    tags_EN: 'cat, face, cute',
    tags_ES: 'gato, cara, bonito',
    category: 'animales',
  },
  {
    id: 1600,
    symbol: '🐈',
    tags_EN: 'cat, full, body',
    tags_ES: 'gato, cuerpo, entero',
    category: 'animales',
  },
  {
    id: 1601,
    symbol: '🦁',
    tags_EN: 'lion, face, wild',
    tags_ES: 'león, cara, feroz',
    category: 'animales',
  },
  {
    id: 1602,
    symbol: '🐯',
    tags_EN: 'tiger, face, wild',
    tags_ES: 'tigre, cara, feroz',
    category: 'animales',
  },
  {
    id: 1603,
    symbol: '🐅',
    tags_EN: 'tiger, full, body',
    tags_ES: 'tigre, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1604,
    symbol: '🐆',
    tags_EN: 'leopard, full, body',
    tags_ES: 'leopardo, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1605,
    symbol: '🐴',
    tags_EN: 'horse, face, wild',
    tags_ES: 'caballo, cara, salvaje',
    category: 'animales',
  },
  {
    id: 1606,
    symbol: '🐎',
    tags_EN: 'horse, full, body',
    tags_ES: 'caballo, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1607,
    symbol: '🦄',
    tags_EN: 'unicorn, face, horse',
    tags_ES: 'unicornio, cara, caballo',
    category: 'animales',
  },
  {
    id: 1608,
    symbol: '🦓',
    tags_EN: 'zebra, animal, wild',
    tags_ES: 'cebra, animal, salvaje',
    category: 'animales',
  },
  {
    id: 1609,
    symbol: '🦌',
    tags_EN: 'deer, animal, wild',
    tags_ES: 'ciervo, animal, salvaje',
    category: 'animales',
  },
  {
    id: 1610,
    symbol: '🐮',
    tags_EN: 'cow, face, milk',
    tags_ES: 'vaca, cara, leche',
    category: 'animales',
  },
  {
    id: 1611,
    symbol: '🐂',
    tags_EN: 'ox, full, body',
    tags_ES: 'buey, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1612,
    symbol: '🐃',
    tags_EN: 'water, buffalo, wild',
    tags_ES: 'búfalo, agua, salvaje',
    category: 'animales',
  },
  {
    id: 1613,
    symbol: '🐄',
    tags_EN: 'cow, full, body',
    tags_ES: 'vaca, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1614,
    symbol: '🐷',
    tags_EN: 'pig, face, pork',
    tags_ES: 'cerdo, cara, puerco',
    category: 'animales',
  },
  {
    id: 1615,
    symbol: '🐖',
    tags_EN: 'pig, full, body',
    tags_ES: 'cerdo, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1616,
    symbol: '🐗',
    tags_EN: 'boar, face, wild',
    tags_ES: 'jabalí, cara, salvaje',
    category: 'animales',
  },
  {
    id: 1617,
    symbol: '🐽',
    tags_EN: 'pig, nose, pork',
    tags_ES: 'cerdo, nariz, puerco',
    category: 'animales',
  },
  {
    id: 1618,
    symbol: '🐏',
    tags_EN: 'ram, full, body',
    tags_ES: 'cabra, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1619,
    symbol: '🐑',
    tags_EN: 'ewe, full, body',
    tags_ES: 'oveja, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1620,
    symbol: '🐐',
    tags_EN: 'goat, full, body',
    tags_ES: 'cabra, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1621,
    symbol: '🐪',
    tags_EN: 'camel, full, body',
    tags_ES: 'camello, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1622,
    symbol: '🐫',
    tags_EN: 'two, hump, camel',
    tags_ES: 'camello, dos, jorobas',
    category: 'animales',
  },
  {
    id: 1624,
    symbol: '🦒',
    tags_EN: 'giraffe, full, body',
    tags_ES: 'girafa, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1625,
    symbol: '🐘',
    tags_EN: 'elephant, full, body',
    tags_ES: 'elefante, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1626,
    symbol: '🦏',
    tags_EN: 'rhinoceros, full, body',
    tags_ES: 'rinoceronte, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1628,
    symbol: '🐭',
    tags_EN: 'mouse, face, rat',
    tags_ES: 'ratón, cara, rata',
    category: 'animales',
  },
  {
    id: 1629,
    symbol: '🐁',
    tags_EN: 'mouse, full, body',
    tags_ES: 'rata, ratón, cuerpo',
    category: 'animales',
  },
  {
    id: 1630,
    symbol: '🐀',
    tags_EN: 'rat, full, body',
    tags_ES: 'rata, ratón, cuerpo',
    category: 'animales',
  },
  {
    id: 1631,
    symbol: '🐹',
    tags_EN: 'hamster, face, rat',
    tags_ES: 'hamster, ratón, rata',
    category: 'animales',
  },
  {
    id: 1632,
    symbol: '🐰',
    tags_EN: 'rabbit, face, wild',
    tags_ES: 'conejo, cara, salvaje',
    category: 'animales',
  },
  {
    id: 1633,
    symbol: '🐇',
    tags_EN: 'rabbit, full, body',
    tags_ES: 'conejo, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1634,
    symbol: '🐿',
    tags_EN: 'chipmunk, full, body',
    tags_ES: 'ardilla, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1635,
    symbol: '🦔',
    tags_EN: 'hedgehog, full, body',
    tags_ES: 'erizo, puercoespín, pincha',
    category: 'animales',
  },
  {
    id: 1636,
    symbol: '🦇',
    tags_EN: 'bat, fly, rat',
    tags_ES: 'murciélago, rata, voladora',
    category: 'animales',
  },
  {
    id: 1637,
    symbol: '🐻',
    tags_EN: 'bear, face, wild',
    tags_ES: 'oso, cara, salvaje',
    category: 'animales',
  },
  {
    id: 1638,
    symbol: '🐨',
    tags_EN: 'koala, face, wild',
    tags_ES: 'koala, cara, salvaje',
    category: 'animales',
  },
  {
    id: 1639,
    symbol: '🐼',
    tags_EN: 'panda, face, wild',
    tags_ES: 'panda, cara, salvaje',
    category: 'animales',
  },
  {
    id: 1642,
    symbol: '🐾',
    tags_EN: 'paw, prints, animal',
    tags_ES: 'huellas, animal, pisadas',
    category: 'animales',
  },
  {
    id: 1643,
    symbol: '🦃',
    tags_EN: 'turkey, bird, animal',
    tags_ES: 'pavo, pájaro, ave',
    category: 'animales',
  },
  {
    id: 1644,
    symbol: '🐔',
    tags_EN: 'chicken, bird, animal',
    tags_ES: 'gallina, pájaro, ave',
    category: 'animales',
  },
  {
    id: 1645,
    symbol: '🐓',
    tags_EN: 'rooster, bird, animal',
    tags_ES: 'gallo, pájaro, ave',
    category: 'animales',
  },
  {
    id: 1646,
    symbol: '🐣',
    tags_EN: 'hatching, chick, egg',
    tags_ES: 'polluelo, pollo, huevo',
    category: 'animales',
  },
  {
    id: 1647,
    symbol: '🐤',
    tags_EN: 'baby, chick, bird',
    tags_ES: 'pollito, polluelo, pollo',
    category: 'animales',
  },
  {
    id: 1648,
    symbol: '🐥',
    tags_EN: 'front-facing, baby, chick',
    tags_ES: 'pollito, polluelo, pollo',
    category: 'animales',
  },
  {
    id: 1649,
    symbol: '🐦',
    tags_EN: 'bird, fly, beak',
    tags_ES: 'pájaro, volar, ave',
    category: 'animales',
  },
  {
    id: 1650,
    symbol: '🐧',
    tags_EN: 'penguin, bird, beak',
    tags_ES: 'pingüino, ave, pájaro',
    category: 'animales',
  },
  {
    id: 1651,
    symbol: '🕊',
    tags_EN: 'dove, bird, fly',
    tags_ES: 'paloma, pájaro, ave',
    category: 'animales',
  },
  {
    id: 1652,
    symbol: '🦅',
    tags_EN: 'eagle, bird, fly',
    tags_ES: 'águila, pájaro, ave',
    category: 'animales',
  },
  {
    id: 1653,
    symbol: '🦆',
    tags_EN: 'duck, bird, beak',
    tags_ES: 'pato, pájaro, ave',
    category: 'animales',
  },
  {
    id: 1655,
    symbol: '🦉',
    tags_EN: 'owl, bird, night',
    tags_ES: 'búho, pájaro, noche',
    category: 'animales',
  },
  {
    id: 1658,
    symbol: '🐸',
    tags_EN: 'frog, face, jump',
    tags_ES: 'rana, cara, saltar',
    category: 'animales',
  },
  {
    id: 1659,
    symbol: '🐊',
    tags_EN: 'crocodile, wild, animal',
    tags_ES: 'cocodrilo, animal, salvaje',
    category: 'animales',
  },
  {
    id: 1660,
    symbol: '🐢',
    tags_EN: 'turtle, slow, animal',
    tags_ES: 'tortuga, animal, lento',
    category: 'animales',
  },
  {
    id: 1661,
    symbol: '🦎',
    tags_EN: 'lizard, earth, animal',
    tags_ES: 'lagartija, lagarto, dragón',
    category: 'animales',
  },
  {
    id: 1662,
    symbol: '🐍',
    tags_EN: 'snake, wild, animal',
    tags_ES: 'serpiente, animal, salvaje',
    category: 'animales',
  },
  {
    id: 1663,
    symbol: '🐲',
    tags_EN: 'dragon, face, china',
    tags_ES: 'cara, dragón, china',
    category: 'animales',
  },
  {
    id: 1664,
    symbol: '🐉',
    tags_EN: 'dragon, full, body',
    tags_ES: 'dragón, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1665,
    symbol: '🦕',
    tags_EN: 'sauropod, dinosaur, body',
    tags_ES: 'dinosaurio, gigante, animal',
    category: 'animales',
  },
  {
    id: 1666,
    symbol: '🦖',
    tags_EN: 't-rex, dinosaur, body',
    tags_ES: 'dinosaurio, rex, animal',
    category: 'animales',
  },
  {
    id: 1667,
    symbol: '🐳',
    tags_EN: 'spouting, whale, sea',
    tags_ES: 'ballena, expulsa, agua',
    category: 'animales',
  },
  {
    id: 1668,
    symbol: '🐋',
    tags_EN: 'whale, sea, fish',
    tags_ES: 'ballena, cuerpo, completo',
    category: 'animales',
  },
  {
    id: 1669,
    symbol: '🐬',
    tags_EN: 'dolphin, sea, fish',
    tags_ES: 'delfín, mar, pez',
    category: 'animales',
  },
  {
    id: 1670,
    symbol: '🐟',
    tags_EN: 'fish, sea, swim',
    tags_ES: 'pez, pescado, mar',
    category: 'animales',
  },
  {
    id: 1671,
    symbol: '🐠',
    tags_EN: 'tropical, fish, sea',
    tags_ES: 'pez, pescado, tropical',
    category: 'animales',
  },
  {
    id: 1672,
    symbol: '🐡',
    tags_EN: 'blowfish, fish, sea',
    tags_ES: 'pez, globo, pescado',
    category: 'animales',
  },
  {
    id: 1673,
    symbol: '🦈',
    tags_EN: 'shark, wild, fish',
    tags_ES: 'tiburón, mar, salvaje',
    category: 'animales',
  },
  {
    id: 1674,
    symbol: '🐙',
    tags_EN: 'octopus, sea, food',
    tags_ES: 'pulpo, mar, calamar',
    category: 'animales',
  },
  {
    id: 1675,
    symbol: '🐚',
    tags_EN: 'spiral, shell, sea',
    tags_ES: 'caracola, mar, ermitaño',
    category: 'animales',
  },
  {
    id: 1676,
    symbol: '🦀',
    tags_EN: 'crab, sea, food',
    tags_ES: 'cangrejo, mar, marisco',
    category: 'animales',
  },
  {
    id: 1678,
    symbol: '🦐',
    tags_EN: 'shrimp, sea, food',
    tags_ES: 'gamba, mar, marisco',
    category: 'animales',
  },
  {
    id: 1679,
    symbol: '🦑',
    tags_EN: 'squid, sea, food',
    tags_ES: 'calamar, mar, marisco',
    category: 'animales',
  },
  {
    id: 1680,
    symbol: '🐌',
    tags_EN: 'snail, slow, dribble',
    tags_ES: 'caracol, lento, babas',
    category: 'animales',
  },
  {
    id: 1681,
    symbol: '🦋',
    tags_EN: 'butterfly, fly, insect',
    tags_ES: 'mariposa, volar, insecto',
    category: 'animales',
  },
  {
    id: 1682,
    symbol: '🐛',
    tags_EN: 'bug, slow, insect',
    tags_ES: 'oruga, gusano, verde',
    category: 'animales',
  },
  {
    id: 1683,
    symbol: '🐜',
    tags_EN: 'ant, black, insect',
    tags_ES: 'hormiga, insecto, negra',
    category: 'animales',
  },
  {
    id: 1684,
    symbol: '🐝',
    tags_EN: 'honeybee, fly, insect',
    tags_ES: 'abeja, insecto, volar',
    category: 'animales',
  },
  {
    id: 1685,
    symbol: '🐞',
    tags_EN: 'lady, beetle, insect',
    tags_ES: 'mariquita, caparazón, volar',
    category: 'animales',
  },
  {
    id: 1686,
    symbol: '🦗',
    tags_EN: 'cricket, jump, insect',
    tags_ES: 'saltamontes, saltar, insecto',
    category: 'animales',
  },
  {
    id: 1687,
    symbol: '🕷',
    tags_EN: 'spider, black, tarantula',
    tags_ES: 'araña, tarántula, insecto',
    category: 'animales',
  },
  {
    id: 1688,
    symbol: '🕸',
    tags_EN: 'spider, web, tarantula',
    tags_ES: 'araña, red, tarántula',
    category: 'animales',
  },
  {
    id: 1689,
    symbol: '🦂',
    tags_EN: 'scorpion, insect, bite',
    tags_ES: 'escorpión, insecto, picar',
    category: 'animales',
  },
  {
    id: 1692,
    symbol: '💐',
    tags_EN: 'bouquet, flowers, gift',
    tags_ES: 'ramo, flores, rosas',
    category: 'naturaleza',
  },
  {
    id: 1693,
    symbol: '🌸',
    tags_EN: 'cherry, blossom, flower',
    tags_ES: 'cerezo, flores, japonesa',
    category: 'naturaleza',
  },
  {
    id: 1694,
    symbol: '💮',
    tags_EN: 'white, flower, plant',
    tags_ES: 'flor, blanca, planta',
    category: 'naturaleza',
  },
  {
    id: 1695,
    symbol: '🏵',
    tags_EN: 'rosette, flower, plant',
    tags_ES: 'roseta, planta, flor',
    category: 'naturaleza',
  },
  {
    id: 1696,
    symbol: '🌹',
    tags_EN: 'rose, flower, plant',
    tags_ES: 'rosa, planta, flor',
    category: 'naturaleza',
  },
  {
    id: 1697,
    symbol: '🥀',
    tags_EN: 'wilted, flower, plant',
    tags_ES: 'flor, marchita, planta',
    category: 'naturaleza',
  },
  {
    id: 1698,
    symbol: '🌺',
    tags_EN: 'hibiscus, flower, plant',
    tags_ES: 'hibiscos, planta, tropical',
    category: 'naturaleza',
  },
  {
    id: 1699,
    symbol: '🌻',
    tags_EN: 'sunflower, flower, plant',
    tags_ES: 'girasol, planta, sol',
    category: 'naturaleza',
  },
  {
    id: 1700,
    symbol: '🌼',
    tags_EN: 'blossom, flower, plant',
    tags_ES: 'margarita, planta, flor',
    category: 'naturaleza',
  },
  {
    id: 1701,
    symbol: '🌷',
    tags_EN: 'tulip, flower, plant',
    tags_ES: 'tulipán, planta, flor',
    category: 'naturaleza',
  },
  {
    id: 1702,
    symbol: '🌱',
    tags_EN: 'seeding, plant, growing',
    tags_ES: 'semilla, planta, crecer',
    category: 'naturaleza',
  },
  {
    id: 1703,
    symbol: '🌲',
    tags_EN: 'evergreen, tree, christmas',
    tags_ES: 'abeto, árbol, navidad',
    category: 'naturaleza',
  },
  {
    id: 1704,
    symbol: '🌳',
    tags_EN: 'deciduous, tree, big',
    tags_ES: 'árbol, copa, caducifolio',
    category: 'naturaleza',
  },
  {
    id: 1705,
    symbol: '🌴',
    tags_EN: 'palm, tree, beach',
    tags_ES: 'palmera, playa, árbol',
    category: 'naturaleza',
  },
  {
    id: 1706,
    symbol: '🌵',
    tags_EN: 'cactus, desert, tree',
    tags_ES: 'cactus, árbol, desierto',
    category: 'naturaleza',
  },
  {
    id: 1707,
    symbol: '🌾',
    tags_EN: 'sheaf, rice, plant',
    tags_ES: 'trigo, arroz, planta',
    category: 'naturaleza',
  },
  {
    id: 1708,
    symbol: '🌿',
    tags_EN: 'herb, plant, natural',
    tags_ES: 'planta, hierba, yerba',
    category: 'naturaleza',
  },
  {
    id: 1709,
    symbol: '☘',
    tags_EN: 'shamrock, good, luck',
    tags_ES: 'trébol, buena, suerte',
    category: 'naturaleza',
  },
  {
    id: 1710,
    symbol: '🍀',
    tags_EN: 'four, leaf, clover',
    tags_ES: 'trébol, cuatro, hojas',
    category: 'naturaleza',
  },
  {
    id: 1711,
    symbol: '🍁',
    tags_EN: 'maple, leaf, plant',
    tags_ES: 'hoja, seca, arce',
    category: 'naturaleza',
  },
  {
    id: 1712,
    symbol: '🍂',
    tags_EN: 'fallen, leaf, plant',
    tags_ES: 'hoja, seca, otoño',
    category: 'naturaleza',
  },
  {
    id: 1713,
    symbol: '🍃',
    tags_EN: 'leaf, fluttering, wind',
    tags_ES: 'hoja, cae, viento',
    category: 'naturaleza',
  },
  {
    id: 1714,
    symbol: '🍇',
    tags_EN: 'grapes, fruit, purple',
    tags_ES: 'uvas, fruta, lila',
    category: 'naturaleza',
  },
  {
    id: 1715,
    symbol: '🍈',
    tags_EN: 'melon, fruit, sweet',
    tags_ES: 'melón, dulce, fruta',
    category: 'naturaleza',
  },
  {
    id: 1716,
    symbol: '🍉',
    tags_EN: 'watermelon, fruit, sweet',
    tags_ES: 'sandía, dulce, fruta',
    category: 'naturaleza',
  },
  {
    id: 1717,
    symbol: '🍊',
    tags_EN: 'tangerin, fruit, orange',
    tags_ES: 'mandarina, dulce, naranja',
    category: 'naturaleza',
  },
  {
    id: 1718,
    symbol: '🍋',
    tags_EN: 'lemon, fruit, acid',
    tags_ES: 'limón, ácido, fruta',
    category: 'naturaleza',
  },
  {
    id: 1719,
    symbol: '🍌',
    tags_EN: 'banana, fruit, sweet',
    tags_ES: 'banana, plátano, fruta',
    category: 'naturaleza',
  },
  {
    id: 1720,
    symbol: '🍍',
    tags_EN: 'pineapple, fruit, sweet',
    tags_ES: 'piña, dulce, fruta',
    category: 'naturaleza',
  },
  {
    id: 1722,
    symbol: '🍎',
    tags_EN: 'red, apple, fruit',
    tags_ES: 'manzana, roja, fruta',
    category: 'naturaleza',
  },
  {
    id: 1723,
    symbol: '🍏',
    tags_EN: 'green, apple, fruit',
    tags_ES: 'manzana, verde, fruta',
    category: 'naturaleza',
  },
  {
    id: 1724,
    symbol: '🍐',
    tags_EN: 'pear, fruit, green',
    tags_ES: 'pera, verde, fruta',
    category: 'naturaleza',
  },
  {
    id: 1725,
    symbol: '🍑',
    tags_EN: 'peach, fruit, sweet',
    tags_ES: 'melocotón, dulce, fruta',
    category: 'naturaleza',
  },
  {
    id: 1726,
    symbol: '🍒',
    tags_EN: 'cherries, fruit, red',
    tags_ES: 'cerezas, fruta, roja',
    category: 'naturaleza',
  },
  {
    id: 1727,
    symbol: '🍓',
    tags_EN: 'strawberry, fruit, red',
    tags_ES: 'fresa, fruta, roja',
    category: 'naturaleza',
  },
  {
    id: 1728,
    symbol: '🥝',
    tags_EN: 'kiwi, fruit, green',
    tags_ES: 'kiwi, fruta, verde',
    category: 'naturaleza',
  },
  {
    id: 1729,
    symbol: '🍅',
    tags_EN: 'tomato, fruit, red',
    tags_ES: 'tomate, fruta, roja',
    category: 'naturaleza',
  },
  {
    id: 1730,
    symbol: '🥥',
    tags_EN: 'coconut, fruit, tropical',
    tags_ES: 'coco, fruta, tropical',
    category: 'naturaleza',
  },
  {
    id: 1731,
    symbol: '🥑',
    tags_EN: 'avocado, vegetable, mexico',
    tags_ES: 'aguacate, vegetal, mexico',
    category: 'naturaleza',
  },
  {
    id: 1732,
    symbol: '🍆',
    tags_EN: 'eggplant, vegetable, purple',
    tags_ES: 'berenjena, vegetal, lila',
    category: 'naturaleza',
  },
  {
    id: 1733,
    symbol: '🥔',
    tags_EN: 'potato, vegetable, earth',
    tags_ES: 'patata, vegetal, tierra',
    category: 'naturaleza',
  },
  {
    id: 1734,
    symbol: '🥕',
    tags_EN: 'carrot, vegetable, orange',
    tags_ES: 'zanahoria, vegetal, tierra',
    category: 'naturaleza',
  },
  {
    id: 1735,
    symbol: '🌽',
    tags_EN: 'ear of corn, vegetable, yellow',
    tags_ES: 'maíz, vegetal, tierra',
    category: 'naturaleza',
  },
  {
    id: 1736,
    symbol: '🌶',
    tags_EN: 'hot pepper, vegetable, chili',
    tags_ES: 'chile, vegetal, guindilla',
    category: 'naturaleza',
  },
  {
    id: 1737,
    symbol: '🥒',
    tags_EN: 'cucumber, vegetable, green',
    tags_ES: 'pepino, vegetal, verde',
    category: 'naturaleza',
  },
  {
    id: 1739,
    symbol: '🥦',
    tags_EN: 'broccoli, vegetable, green',
    tags_ES: 'brócoli, vegetal, verde',
    category: 'naturaleza',
  },
  {
    id: 1740,
    symbol: '🍄',
    tags_EN: 'mushroom, vegetable, red',
    tags_ES: 'seta, vegetal, roja',
    category: 'naturaleza',
  },
  {
    id: 1741,
    symbol: '🥜',
    tags_EN: 'peanuts, vegetable, salt',
    tags_ES: 'cacahuetes, vegetal, salado',
    category: 'objetos',
  },
  {
    id: 1742,
    symbol: '🌰',
    tags_EN: 'chestnut, vegetable, fall',
    tags_ES: 'castaña, vegetal, otoño',
    category: 'objetos',
  },
  {
    id: 1743,
    symbol: '🍞',
    tags_EN: 'bread, food, bakery',
    tags_ES: 'pan, comida, panadería',
    category: 'objetos',
  },
  {
    id: 1744,
    symbol: '🥐',
    tags_EN: 'croissant, food, bakery',
    tags_ES: 'cruasán, comida, pasta',
    category: 'objetos',
  },
  {
    id: 1745,
    symbol: '🥖',
    tags_EN: 'baguette, bread, bakery',
    tags_ES: 'barra, pan, panadería',
    category: 'objetos',
  },
  {
    id: 1746,
    symbol: '🥨',
    tags_EN: 'pretzel, bread, bakery',
    tags_ES: 'pretzel, pan, alemán',
    category: 'objetos',
  },
  {
    id: 1748,
    symbol: '🥞',
    tags_EN: 'pancakes, food, sweet',
    tags_ES: 'tortitas, miel, mantequilla',
    category: 'objetos',
  },
  {
    id: 1749,
    symbol: '🧀',
    tags_EN: 'cheese, wedge, milk',
    tags_ES: 'queso, gruyer, suizo',
    category: 'objetos',
  },
  {
    id: 1750,
    symbol: '🍖',
    tags_EN: 'meat, bone, beef',
    tags_ES: 'carne, hueso, ternera',
    category: 'objetos',
  },
  {
    id: 1751,
    symbol: '🍗',
    tags_EN: 'poultry, leg, food',
    tags_ES: 'pata, pollo, comida',
    category: 'objetos',
  },
  {
    id: 1752,
    symbol: '🥩',
    tags_EN: 'cut, meat, beef',
    tags_ES: 'filete, ternera, entrecot',
    category: 'objetos',
  },
  {
    id: 1753,
    symbol: '🥓',
    tags_EN: 'bacon, pork, fat',
    tags_ES: 'beicon, cerdo, grasa',
    category: 'objetos',
  },
  {
    id: 1754,
    symbol: '🍔',
    tags_EN: 'hamburger, mc donalds, cheese',
    tags_ES: 'hamburguesa, queso, grasa',
    category: 'objetos',
  },
  {
    id: 1755,
    symbol: '🍟',
    tags_EN: 'french, fries, mc donalds',
    tags_ES: 'patatas, fritas, grasa',
    category: 'objetos',
  },
  {
    id: 1756,
    symbol: '🍕',
    tags_EN: 'pizza, italian, food',
    tags_ES: 'comida, italiana, pizza',
    category: 'objetos',
  },
  {
    id: 1757,
    symbol: '🌭',
    tags_EN: 'hot, dog, sandwich',
    tags_ES: 'perrito, caliente, frankfurt',
    category: 'objetos',
  },
  {
    id: 1758,
    symbol: '🥪',
    tags_EN: 'sandwich, bread, food',
    tags_ES: 'emparedado, bocadillo, pan de molde',
    category: 'objetos',
  },
  {
    id: 1759,
    symbol: '🌮',
    tags_EN: 'taco, mexican, food',
    tags_ES: 'comida, mexicana, wrap',
    category: 'objetos',
  },
  {
    id: 1760,
    symbol: '🌯',
    tags_EN: 'burrito, mexican, food',
    tags_ES: 'comida, mexicana, rollito',
    category: 'objetos',
  },
  {
    id: 1761,
    symbol: '🥙',
    tags_EN: 'stuffed, flatbread, food',
    tags_ES: 'kebab, pan, bocadillo',
    category: 'objetos',
  },
  {
    id: 1762,
    symbol: '🥚',
    tags_EN: 'egg, chicken, white',
    tags_ES: 'huevo, pollo, gallina',
    category: 'objetos',
  },
  {
    id: 1763,
    symbol: '🍳',
    tags_EN: 'cooking, cook, egg',
    tags_ES: 'huevo, frito, freír',
    category: 'objetos',
  },
  {
    id: 1764,
    symbol: '🥘',
    tags_EN: 'shallow, pan, food',
    tags_ES: 'paella, sartén, arroz',
    category: 'objetos',
  },
  {
    id: 1765,
    symbol: '🍲',
    tags_EN: 'pot, food, hot',
    tags_ES: 'cuenco, comida, sopa',
    category: 'objetos',
  },
  {
    id: 1766,
    symbol: '🥣',
    tags_EN: 'bowl, spoon, dish',
    tags_ES: 'bol, cuchara, sopa',
    category: 'objetos',
  },
  {
    id: 1767,
    symbol: '🥗',
    tags_EN: 'green, salad, tomato',
    tags_ES: 'ensalada, verde, tomate',
    category: 'objetos',
  },
  {
    id: 1768,
    symbol: '🍿',
    tags_EN: 'popcorn, cinema, food',
    tags_ES: 'palomitas, cine, comida',
    category: 'objetos',
  },
  {
    id: 1770,
    symbol: '🥫',
    tags_EN: 'canned, food, tomato',
    tags_ES: 'tomate, lata, triturado',
    category: 'objetos',
  },
  {
    id: 1771,
    symbol: '🍱',
    tags_EN: 'bento, box, sushi',
    tags_ES: 'comida, japonesa, oriental',
    category: 'objetos',
  },
  {
    id: 1772,
    symbol: '🍘',
    tags_EN: 'rice, cracker, crunchy',
    tags_ES: 'crujiente, arroz, japonés',
    category: 'objetos',
  },
  {
    id: 1773,
    symbol: '🍙',
    tags_EN: 'rice, ball, japanese',
    tags_ES: 'bola, arroz, japonés',
    category: 'objetos',
  },
  {
    id: 1774,
    symbol: '🍚',
    tags_EN: 'cooked, rice, boiled',
    tags_ES: 'arroz, cocido, hervido',
    category: 'objetos',
  },
  {
    id: 1775,
    symbol: '🍛',
    tags_EN: 'curry, rice, spicy',
    tags_ES: 'arroz, curry, tomate',
    category: 'objetos',
  },
  {
    id: 1776,
    symbol: '🍜',
    tags_EN: 'steaming, bowl, noodles',
    tags_ES: 'bola, pasta, fideos',
    category: 'objetos',
  },
  {
    id: 1777,
    symbol: '🍝',
    tags_EN: 'spaghetti, tomato, italian',
    tags_ES: 'espaguetis, comida, italiana',
    category: 'objetos',
  },
  {
    id: 1778,
    symbol: '🍠',
    tags_EN: 'roasted, sweet, potato',
    tags_ES: 'boniato, patata, dulce',
    category: 'objetos',
  },
  {
    id: 1779,
    symbol: '🍢',
    tags_EN: 'oden, food, japanese',
    tags_ES: 'pincho, japonés, comida',
    category: 'objetos',
  },
  {
    id: 1780,
    symbol: '🍣',
    tags_EN: 'sushi, fish, japanese',
    tags_ES: 'pescado, crudo, japonés',
    category: 'objetos',
  },
  {
    id: 1781,
    symbol: '🍤',
    tags_EN: 'fried, shrimp, japanese',
    tags_ES: 'gamba, frita, japonés',
    category: 'objetos',
  },
  {
    id: 1782,
    symbol: '🍥',
    tags_EN: 'fish, cake, swirl',
    tags_ES: 'pastel, pescado, remolino',
    category: 'objetos',
  },
  {
    id: 1784,
    symbol: '🍡',
    tags_EN: 'dango, dumpling, japanese',
    tags_ES: 'mochi, té, japonés',
    category: 'objetos',
  },
  {
    id: 1785,
    symbol: '🥟',
    tags_EN: 'dumpling, japanese, food',
    tags_ES: 'bola, carne, japonés',
    category: 'objetos',
  },
  {
    id: 1786,
    symbol: '🥠',
    tags_EN: 'fortune, cookie, chinese',
    tags_ES: 'galleta, suerte, chino',
    category: 'objetos',
  },
  {
    id: 1787,
    symbol: '🥡',
    tags_EN: 'takeout, box, food',
    tags_ES: 'caja, comida, llevar',
    category: 'objetos',
  },
  {
    id: 1788,
    symbol: '🍦',
    tags_EN: 'soft, ice, cream',
    tags_ES: 'helado, nata, suave',
    category: 'objetos',
  },
  {
    id: 1789,
    symbol: '🍧',
    tags_EN: 'shaved, ice, dessert',
    tags_ES: 'helado, hielo, postre',
    category: 'objetos',
  },
  {
    id: 1790,
    symbol: '🍨',
    tags_EN: 'ice, cream, vanilla',
    tags_ES: 'bola, helado, vainilla',
    category: 'objetos',
  },
  {
    id: 1791,
    symbol: '🍩',
    tags_EN: 'doughnut, chocolate, sweet',
    tags_ES: 'donut, chocolate, dulce',
    category: 'objetos',
  },
  {
    id: 1792,
    symbol: '🍪',
    tags_EN: 'cookie, chips, ahoy',
    tags_ES: 'galleta, pepitas, chocolate',
    category: 'objetos',
  },
  {
    id: 1793,
    symbol: '🎂',
    tags_EN: 'birthday, cake, happy',
    tags_ES: 'pastel, tarta, cumpleaños',
    category: 'objetos',
  },
  {
    id: 1794,
    symbol: '🍰',
    tags_EN: 'shortcake, cake, dessert',
    tags_ES: 'trozo, pastel, nata',
    category: 'objetos',
  },
  {
    id: 1796,
    symbol: '🥧',
    tags_EN: 'pie, cake, dessert',
    tags_ES: 'tarta, bizcocho, postre',
    category: 'objetos',
  },
  {
    id: 1797,
    symbol: '🍫',
    tags_EN: 'chocolate, bar, sweet',
    tags_ES: 'tableta, chocolate, negro',
    category: 'objetos',
  },
  {
    id: 1798,
    symbol: '🍬',
    tags_EN: 'candy, sweet, kid',
    tags_ES: 'caramelo, niño, dulce',
    category: 'objetos',
  },
  {
    id: 1799,
    symbol: '🍭',
    tags_EN: 'lollipop, sweet, colorful',
    tags_ES: 'piruleta, niño, dulce',
    category: 'objetos',
  },
  {
    id: 1800,
    symbol: '🍮',
    tags_EN: 'custard, dessert, sweet',
    tags_ES: 'flan, dulce, postre',
    category: 'objetos',
  },
  {
    id: 1801,
    symbol: '🍯',
    tags_EN: 'honey, pot, sweet',
    tags_ES: 'miel, tarro, dulce',
    category: 'objetos',
  },
  {
    id: 1802,
    symbol: '🍼',
    tags_EN: 'baby, bottle, kid',
    tags_ES: 'biberón, niño, leche',
    category: 'objetos',
  },
  {
    id: 1803,
    symbol: '🥛',
    tags_EN: 'glass, milk, white',
    tags_ES: 'vaso, leche, vaca',
    category: 'objetos',
  },
  {
    id: 1804,
    symbol: '☕',
    tags_EN: 'hot, beverage, coffee',
    tags_ES: 'bebida, caliente, café',
    category: 'objetos',
  },
  {
    id: 1805,
    symbol: '🍵',
    tags_EN: 'teacup, without, handle',
    tags_ES: 'taza, sin, asas',
    category: 'objetos',
  },
  {
    id: 1806,
    symbol: '🍶',
    tags_EN: 'sake, japanese, drink',
    tags_ES: 'bebida, japonesa, alcohol',
    category: 'objetos',
  },
  {
    id: 1807,
    symbol: '🍾',
    tags_EN: 'bottle, popping, cork',
    tags_ES: 'botella, champán, cava',
    category: 'objetos',
  },
  {
    id: 1808,
    symbol: '🍷',
    tags_EN: 'wine, glass, alcohol',
    tags_ES: 'copa, vino, tinto',
    category: 'objetos',
  },
  {
    id: 1809,
    symbol: '🍸',
    tags_EN: 'cocktail, glass, party',
    tags_ES: 'copa, bebida, alcohol',
    category: 'objetos',
  },
  {
    id: 1810,
    symbol: '🍹',
    tags_EN: 'tropical, drink, sweet',
    tags_ES: 'bebida, tropical, dulce',
    category: 'objetos',
  },
  {
    id: 1811,
    symbol: '🍺',
    tags_EN: 'beer, mug, alcohol',
    tags_ES: 'jarra, cerveza, rubia',
    category: 'objetos',
  },
  {
    id: 1812,
    symbol: '🍻',
    tags_EN: 'clinking, beer, mugs',
    tags_ES: 'jarras, cerveza, birras',
    category: 'objetos',
  },
  {
    id: 1813,
    symbol: '🥂',
    tags_EN: 'clinking, glasses, champagne',
    tags_ES: 'copas, cava, champán',
    category: 'objetos',
  },
  {
    id: 1814,
    symbol: '🥃',
    tags_EN: 'tumbler, glass, alcohol',
    tags_ES: 'whisky, vaso, bebida',
    category: 'objetos',
  },
  {
    id: 1815,
    symbol: '🥤',
    tags_EN: 'cup, straw, mc donalds',
    tags_ES: 'vaso, pajita, cañita',
    category: 'objetos',
  },
  {
    id: 1816,
    symbol: '🥢',
    tags_EN: 'chopsticks, chinese, food',
    tags_ES: 'palillos, comida, china',
    category: 'objetos',
  },
  {
    id: 1817,
    symbol: '🍽',
    tags_EN: 'fork, knife, plate',
    tags_ES: 'cuchillo, tenedor, plato',
    category: 'objetos',
  },
  {
    id: 1818,
    symbol: '🍴',
    tags_EN: 'fork, knife, kitchen',
    tags_ES: 'cuchillo, tenedor, cocina',
    category: 'objetos',
  },
  {
    id: 1819,
    symbol: '🥄',
    tags_EN: 'spoon, kitchen, utensil',
    tags_ES: 'cuchara, cocina, cubierto',
    category: 'objetos',
  },
  {
    id: 1820,
    symbol: '🔪',
    tags_EN: 'kitchen, knife, utensil',
    tags_ES: 'cuchillo, cocina, cubierto',
    category: 'objetos',
  },
  {
    id: 1821,
    symbol: '🏺',
    tags_EN: 'amphora, ancient, greek',
    tags_ES: 'botijo, antigua, grecia',
    category: 'objetos',
  },
  {
    id: 1822,
    symbol: '🌍',
    tags_EN: 'globe, europa, africa',
    tags_ES: 'globo, terráqueo, mapa',
    category: 'objetos',
  },
  {
    id: 1823,
    symbol: '🌎',
    tags_EN: 'globe, america, latin',
    tags_ES: 'globo, terráqueo, mapa',
    category: 'objetos',
  },
  {
    id: 1824,
    symbol: '🌏',
    tags_EN: 'globe, asia, australia',
    tags_ES: 'globo, terráqueo, mapa',
    category: 'objetos',
  },
  {
    id: 1825,
    symbol: '🌐',
    tags_EN: 'globe, meridians, map',
    tags_ES: 'globo, meridianos, mapa',
    category: 'objetos',
  },
  {
    id: 1826,
    symbol: '🗺',
    tags_EN: 'world, map, planet',
    tags_ES: 'mapa, mundi, mundo',
    category: 'objetos',
  },
  {
    id: 1827,
    symbol: '🗾',
    tags_EN: 'map, japan, asia',
    tags_ES: 'mapa, japón, asia',
    category: 'objetos',
  },
  {
    id: 1829,
    symbol: '🏔',
    tags_EN: 'snow, capped, mountain',
    tags_ES: 'montaña, nevada, monte',
    category: 'naturaleza',
  },
  {
    id: 1830,
    symbol: '⛰',
    tags_EN: 'mountain, climb, high',
    tags_ES: 'montaña, escalar, monte',
    category: 'naturaleza',
  },
  {
    id: 1831,
    symbol: '🌋',
    tags_EN: 'volcano, lava, mountain',
    tags_ES: 'volcán, lava, montaña',
    category: 'naturaleza',
  },
  {
    id: 1832,
    symbol: '🗻',
    tags_EN: 'mountain, fuji, mount',
    tags_ES: 'monte, montaña, japonés',
    category: 'naturaleza',
  },
  {
    id: 1833,
    symbol: '🏕',
    tags_EN: 'camping, tree, picnic',
    tags_ES: 'tienda, campaña, bosque',
    category: 'naturaleza',
  },
  {
    id: 1834,
    symbol: '🏖',
    tags_EN: 'beach, umbrella, sun',
    tags_ES: 'playa, sombrilla, isla',
    category: 'naturaleza',
  },
  {
    id: 1835,
    symbol: '🏜',
    tags_EN: 'desert, hot, west',
    tags_ES: 'oeste, desierto, calor',
    category: 'naturaleza',
  },
  {
    id: 1836,
    symbol: '🏝',
    tags_EN: 'desert, island, palm',
    tags_ES: 'isla, desierta, palmera',
    category: 'naturaleza',
  },
  {
    id: 1837,
    symbol: '🏞',
    tags_EN: 'national, park, nature',
    tags_ES: 'parque, nacional, naturaleza',
    category: 'naturaleza',
  },
  {
    id: 1838,
    symbol: '🏟',
    tags_EN: 'stadium, player, match',
    tags_ES: 'estadio, jugadores, equipos',
    category: 'objetos',
  },
  {
    id: 1839,
    symbol: '🏛',
    tags_EN: 'classical, building, museum',
    tags_ES: 'edificio, clásico, museo',
    category: 'objetos',
  },
  {
    id: 1840,
    symbol: '🏗',
    tags_EN: 'building, construction, crane',
    tags_ES: 'construcción, edificio, grúa',
    category: 'objetos',
  },
  {
    id: 1841,
    symbol: '🏘',
    tags_EN: 'houses, home, building',
    tags_ES: 'casas, edificios, viviendas',
    category: 'objetos',
  },
  {
    id: 1842,
    symbol: '🏚',
    tags_EN: 'derelict, house, closed',
    tags_ES: 'casa, abandonada, tapiada',
    category: 'objetos',
  },
  {
    id: 1843,
    symbol: '🏠',
    tags_EN: 'house, home, building',
    tags_ES: 'casa, edificio, viviendas',
    category: 'objetos',
  },
  {
    id: 1844,
    symbol: '🏡',
    tags_EN: 'house, garden, home',
    tags_ES: 'casa, jardín, vivienda',
    category: 'objetos',
  },
  {
    id: 1846,
    symbol: '🏢',
    tags_EN: 'office, building, windows',
    tags_ES: 'edificio, oficinas, ventanas',
    category: 'objetos',
  },
  {
    id: 1847,
    symbol: '🏣',
    tags_EN: 'japanese, post, office',
    tags_ES: 'edificio, correos, japonés',
    category: 'objetos',
  },
  {
    id: 1848,
    symbol: '🏤',
    tags_EN: 'post, office, postal',
    tags_ES: 'oficina, postal, carta',
    category: 'objetos',
  },
  {
    id: 1849,
    symbol: '🏥',
    tags_EN: 'hospital, doctor, health',
    tags_ES: 'hospital, doctor, salud',
    category: 'objetos',
  },
  {
    id: 1850,
    symbol: '🏦',
    tags_EN: 'bank, money, cash',
    tags_ES: 'banco, dinero, monedas',
    category: 'objetos',
  },
  {
    id: 1851,
    symbol: '🏨',
    tags_EN: 'hotel, hostel, holidays',
    tags_ES: 'hostal, vacaciones, hospedarse',
    category: 'objetos',
  },
  {
    id: 1852,
    symbol: '🏩',
    tags_EN: 'love, hotel, couple',
    tags_ES: 'amor, hotel, hospedarse',
    category: 'objetos',
  },
  {
    id: 1853,
    symbol: '🏪',
    tags_EN: 'convenience, store, 7-eleven',
    tags_ES: 'badulaque, colmado, tienda',
    category: 'objetos',
  },
  {
    id: 1854,
    symbol: '🏫',
    tags_EN: 'school, education, student',
    tags_ES: 'educación, colegio, estudiantes',
    category: 'objetos',
  },
  {
    id: 1855,
    symbol: '🏬',
    tags_EN: 'department, store, buy',
    tags_ES: 'comercio, tienda, comprar',
    category: 'objetos',
  },
  {
    id: 1856,
    symbol: '🏭',
    tags_EN: 'factory, production, worker',
    tags_ES: 'factoría, fábrica, trabajador',
    category: 'objetos',
  },
  {
    id: 1857,
    symbol: '🏯',
    tags_EN: 'japanese, castle, japan',
    tags_ES: 'castillo, japonés, japón',
    category: 'objetos',
  },
  {
    id: 1858,
    symbol: '🏰',
    tags_EN: 'castle, princess, king',
    tags_ES: 'castillo, princesa, rey',
    category: 'objetos',
  },
  {
    id: 1859,
    symbol: '💒',
    tags_EN: 'wedding, couple, marry',
    tags_ES: 'boda, pareja, casarse',
    category: 'objetos',
  },
  {
    id: 1860,
    symbol: '🗼',
    tags_EN: 'tokyo, tower, japan',
    tags_ES: 'torre, japón, tokyo',
    category: 'objetos',
  },
  {
    id: 1861,
    symbol: '🗽',
    tags_EN: 'statue, liberty, new york',
    tags_ES: 'estatua, libertad, nueva york',
    category: 'objetos',
  },
  {
    id: 1862,
    symbol: '⛪',
    tags_EN: 'church, pray, jesus',
    tags_ES: 'iglesia, cristiana, jesús',
    category: 'objetos',
  },
  {
    id: 1863,
    symbol: '🕌',
    tags_EN: 'mosque, muslim, pray',
    tags_ES: 'mezquita, musulmán, islam',
    category: 'objetos',
  },
  {
    id: 1864,
    symbol: '🕍',
    tags_EN: 'synagogue, jewish, pray',
    tags_ES: 'sinagoga, judío, rezar',
    category: 'objetos',
  },
  {
    id: 1865,
    symbol: '⛩',
    tags_EN: 'shinto, shrine, japan',
    tags_ES: 'sintoísmo, japón, jinja',
    category: 'objetos',
  },
  {
    id: 1866,
    symbol: '🕋',
    tags_EN: 'kaaba, islam, meca',
    tags_ES: 'religión, musulmán, árabe',
    category: 'objetos',
  },
  {
    id: 1867,
    symbol: '⛲',
    tags_EN: 'fountain, water, spot',
    tags_ES: 'fuente, agua, chorro',
    category: 'objetos',
  },
  {
    id: 1868,
    symbol: '⛺',
    tags_EN: 'tent, camping, nature',
    tags_ES: 'tienda, campaña, campo',
    category: 'objetos',
  },
  {
    id: 1869,
    symbol: '🌁',
    tags_EN: 'foggy, cloud, fuzzy',
    tags_ES: 'borroso, niebla, nube',
    category: 'objetos',
  },
  {
    id: 1870,
    symbol: '🌃',
    tags_EN: 'night, stars, picture',
    tags_ES: 'dibujo, ciudad, noche',
    category: 'objetos',
  },
  {
    id: 1871,
    symbol: '🏙',
    tags_EN: 'cityscape, buildings, picture',
    tags_ES: 'dibujo, silueta, ciudad',
    category: 'objetos',
  },
  {
    id: 1872,
    symbol: '🌄',
    tags_EN: 'sunrise, over, mountains',
    tags_ES: 'amanecer, sol, montañas',
    category: 'objetos',
  },
  {
    id: 1873,
    symbol: '🌅',
    tags_EN: 'sunrise, sun, morning',
    tags_ES: 'amanecer, sol, mañana',
    category: 'objetos',
  },
  {
    id: 1874,
    symbol: '🌆',
    tags_EN: 'cityscape, dusk, evening',
    tags_ES: 'ciudad, dibujo, atardecer',
    category: 'objetos',
  },
  {
    id: 1875,
    symbol: '🌇',
    tags_EN: 'sunset, city, picture',
    tags_ES: 'ciudad, puesta, sol',
    category: 'objetos',
  },
  {
    id: 1876,
    symbol: '🌉',
    tags_EN: 'bridge, night, golden gate',
    tags_ES: 'puente, noche, san francisco',
    category: 'objetos',
  },
  {
    id: 1877,
    symbol: '♨',
    tags_EN: 'hot, springs, water',
    tags_ES: 'cascadas, agua, caliente',
    category: 'objetos',
  },
  {
    id: 1878,
    symbol: '🌌',
    tags_EN: 'milky, way, galaxy',
    tags_ES: 'vía, láctea, galaxia',
    category: 'objetos',
  },
  {
    id: 1879,
    symbol: '🎠',
    tags_EN: 'carousel, horse, amusement',
    tags_ES: 'carrusel, caballo, atracción',
    category: 'objetos',
  },
  {
    id: 1880,
    symbol: '🎡',
    tags_EN: 'ferris, wheel, amusement',
    tags_ES: 'noria, diversión, rueda',
    category: 'objetos',
  },
  {
    id: 1881,
    symbol: '🎢',
    tags_EN: 'roller, coaster, amusement',
    tags_ES: 'montaña, rusa, diversión',
    category: 'objetos',
  },
  {
    id: 1882,
    symbol: '💈',
    tags_EN: 'barber, pole, haircut',
    tags_ES: 'barbería, cortar, pelo',
    category: 'objetos',
  },
  {
    id: 1883,
    symbol: '🎪',
    tags_EN: 'circus, tent, clown',
    tags_ES: 'circo, carpa, payaso',
    category: 'objetos',
  },
  {
    id: 1884,
    symbol: '🚂',
    tags_EN: 'locomotive, train, smoke',
    tags_ES: 'locomotora, tren, humo',
    category: 'objetos',
  },
  {
    id: 1885,
    symbol: '🚃',
    tags_EN: 'railway, car, train',
    tags_ES: 'tranvía, tren, raíl',
    category: 'objetos',
  },
  {
    id: 1886,
    symbol: '🚄',
    tags_EN: 'high, speed, train',
    tags_ES: 'tren, alta, velocidad',
    category: 'objetos',
  },
  {
    id: 1887,
    symbol: '🚅',
    tags_EN: 'bullet, train, speed',
    tags_ES: 'tren, bala, velocidad',
    category: 'objetos',
  },
  {
    id: 1888,
    symbol: '🚆',
    tags_EN: 'train, track, rail',
    tags_ES: 'tren, raíl, vía',
    category: 'objetos',
  },
  {
    id: 1889,
    symbol: '🚇',
    tags_EN: 'metro, train, city',
    tags_ES: 'tren, metro, transporte',
    category: 'objetos',
  },
  {
    id: 1890,
    symbol: '🚈',
    tags_EN: 'light, rail, train',
    tags_ES: 'tren, ligero, transporte',
    category: 'objetos',
  },
  {
    id: 1891,
    symbol: '🚉',
    tags_EN: 'station, passenger, wait',
    tags_ES: 'estación, tren, pasajero',
    category: 'objetos',
  },
  {
    id: 1892,
    symbol: '🚊',
    tags_EN: 'tram, train, city',
    tags_ES: 'tranvía, ciudad, tren',
    category: 'objetos',
  },
  {
    id: 1893,
    symbol: '🚝',
    tags_EN: 'monorail, train, rail',
    tags_ES: 'monorraíl, tren, ciudad',
    category: 'objetos',
  },
  {
    id: 1894,
    symbol: '🚞',
    tags_EN: 'mountain, railway, train',
    tags_ES: 'tren, montaña, vía',
    category: 'objetos',
  },
  {
    id: 1895,
    symbol: '🚋',
    tags_EN: 'tram, car, train',
    tags_ES: 'tranvía, tren, antiguo',
    category: 'objetos',
  },
  {
    id: 1896,
    symbol: '🚌',
    tags_EN: 'bus, passenger, yellow',
    tags_ES: 'autobús, bus, escolar',
    category: 'objetos',
  },
  {
    id: 1897,
    symbol: '🚍',
    tags_EN: 'oncoming, bus, transport',
    tags_ES: 'autobús, transporte, pasajeros',
    category: 'objetos',
  },
  {
    id: 1898,
    symbol: '🚎',
    tags_EN: 'trolleybus, bus, electric',
    tags_ES: 'trolebús, bus, eléctrico',
    category: 'objetos',
  },
  {
    id: 1899,
    symbol: '🚐',
    tags_EN: 'minibus, bus, small',
    tags_ES: 'minibús, bus, pequeño',
    category: 'objetos',
  },
  {
    id: 1900,
    symbol: '🚑',
    tags_EN: 'ambulance, injured, emergency',
    tags_ES: 'ambulancia, herido, hospital',
    category: 'objetos',
  },
  {
    id: 1901,
    symbol: '🚒',
    tags_EN: 'fire, engine, car',
    tags_ES: 'bomberos, coche, fuego',
    category: 'objetos',
  },
  {
    id: 1902,
    symbol: '🚓',
    tags_EN: 'police, car, siren',
    tags_ES: 'coche, policía, sirena',
    category: 'objetos',
  },
  {
    id: 1903,
    symbol: '🚔',
    tags_EN: 'oncoming, police, car',
    tags_ES: 'coche, policía, sirena',
    category: 'objetos',
  },
  {
    id: 1904,
    symbol: '🚕',
    tags_EN: 'taxi, car, yellow',
    tags_ES: 'coche, taxi, amarillo',
    category: 'objetos',
  },
  {
    id: 1905,
    symbol: '🚖',
    tags_EN: 'oncoming, taxi, car',
    tags_ES: 'coche, taxi, amarillo',
    category: 'objetos',
  },
  {
    id: 1906,
    symbol: '🚗',
    tags_EN: 'automobile, car, red',
    tags_ES: 'automóvil, coche, rojo',
    category: 'objetos',
  },
  {
    id: 1907,
    symbol: '🚘',
    tags_EN: 'oncoming, automobile, car',
    tags_ES: 'automóvil, coche, rojo',
    category: 'objetos',
  },
  {
    id: 1908,
    symbol: '🚙',
    tags_EN: 'sport, utility, vehicle',
    tags_ES: 'vehículo, utilitario, coche',
    category: 'objetos',
  },
  {
    id: 1909,
    symbol: '🚚',
    tags_EN: 'delivery, truck, transport',
    tags_ES: 'camión, reparto, vehículo',
    category: 'objetos',
  },
  {
    id: 1910,
    symbol: '🚛',
    tags_EN: 'articulated, lorry, truck',
    tags_ES: 'camión, carga, remolque',
    category: 'objetos',
  },
  {
    id: 1911,
    symbol: '🚜',
    tags_EN: 'tractor, farm, yellow',
    tags_ES: 'tractor, amarillo, campo',
    category: 'objetos',
  },
  {
    id: 1912,
    symbol: '🚲',
    tags_EN: 'bicycle, two, wheels',
    tags_ES: 'bicicleta, dos, ruedas',
    category: 'objetos',
  },
  {
    id: 1913,
    symbol: '🛴',
    tags_EN: 'kick, scooter, wheels',
    tags_ES: 'patinete, patín, juguete',
    category: 'objetos',
  },
  {
    id: 1915,
    symbol: '🛵',
    tags_EN: 'motor, scooter, motobike',
    tags_ES: 'ciclomotor, moto, vespa',
    category: 'objetos',
  },
  {
    id: 1916,
    symbol: '🚏',
    tags_EN: 'bus, stop, city',
    tags_ES: 'parada, bus, ciudad',
    category: 'objetos',
  },
  {
    id: 1917,
    symbol: '🛣',
    tags_EN: 'motorway, cars, drive',
    tags_ES: 'carretera, autopista, circular',
    category: 'objetos',
  },
  {
    id: 1918,
    symbol: '🛤',
    tags_EN: 'railway, track, train',
    tags_ES: 'vía, tren, raíl',
    category: 'objetos',
  },
  {
    id: 1919,
    symbol: '🛢',
    tags_EN: 'oil, drum, container',
    tags_ES: 'barril, aceite, contenedor',
    category: 'objetos',
  },
  {
    id: 1920,
    symbol: '⛽',
    tags_EN: 'fuel, pump, gasoil',
    tags_ES: 'gasolina, combustible, gasolinera',
    category: 'objetos',
  },
  {
    id: 1921,
    symbol: '🚨',
    tags_EN: 'police, car, light',
    tags_ES: 'sirena, luz, policía',
    category: 'objetos',
  },
  {
    id: 1922,
    symbol: '🚥',
    tags_EN: 'horizontal, traffic, light',
    tags_ES: 'semáforo, horizontal, luces',
    category: 'objetos',
  },
  {
    id: 1923,
    symbol: '🚦',
    tags_EN: 'vertical, traffic, light',
    tags_ES: 'semáforo, vertical, luces',
    category: 'objetos',
  },
  {
    id: 1924,
    symbol: '🛑',
    tags_EN: 'stop, sign, red',
    tags_ES: 'señal, stop, roja',
    category: 'objetos',
  },
  {
    id: 1925,
    symbol: '🚧',
    tags_EN: 'construction, sign, yellow',
    tags_ES: 'señal, construcción, obras',
    category: 'objetos',
  },
  {
    id: 1926,
    symbol: '⚓',
    tags_EN: 'anchor, sea, boat',
    tags_ES: 'ancla, barco, mar',
    category: 'objetos',
  },
  {
    id: 1927,
    symbol: '⛵',
    tags_EN: 'sailboat, sea, boat',
    tags_ES: 'velero, barco, mar',
    category: 'objetos',
  },
  {
    id: 1928,
    symbol: '🛶',
    tags_EN: 'canoe, sea, boat',
    tags_ES: 'canoa, barco, mar',
    category: 'objetos',
  },
  {
    id: 1929,
    symbol: '🚤',
    tags_EN: 'speedboat, sea, boat',
    tags_ES: 'lancha, barco, mar',
    category: 'objetos',
  },
  {
    id: 1930,
    symbol: '🛳',
    tags_EN: 'passenger, ship, boat',
    tags_ES: 'pasajeros, barco, mar',
    category: 'objetos',
  },
  {
    id: 1931,
    symbol: '⛴',
    tags_EN: 'ferry, sea, boat',
    tags_ES: 'ferry, barco, mar',
    category: 'objetos',
  },
  {
    id: 1932,
    symbol: '🛥',
    tags_EN: 'motor, sea, boat',
    tags_ES: 'yate, barco, mar',
    category: 'objetos',
  },
  {
    id: 1933,
    symbol: '🚢',
    tags_EN: 'ship, sea, boat',
    tags_ES: 'barco, barco, mar',
    category: 'objetos',
  },
  {
    id: 1934,
    symbol: '✈',
    tags_EN: 'airplane, air, fly',
    tags_ES: 'avión, aire, volar',
    category: 'objetos',
  },
  {
    id: 1935,
    symbol: '🛩',
    tags_EN: 'small, airplane, fly',
    tags_ES: 'avión, pequeño, volar',
    category: 'objetos',
  },
  {
    id: 1936,
    symbol: '🛫',
    tags_EN: 'airplane, departure, fly',
    tags_ES: 'avión, despegue, volar',
    category: 'objetos',
  },
  {
    id: 1937,
    symbol: '🛬',
    tags_EN: 'airplane, arrival, fly',
    tags_ES: 'avión, aterrizaje, volar',
    category: 'objetos',
  },
  {
    id: 1938,
    symbol: '💺',
    tags_EN: 'seat, plane, fly',
    tags_ES: 'avión, asiento, volar',
    category: 'objetos',
  },
  {
    id: 1939,
    symbol: '🚁',
    tags_EN: 'helicopter, fly, red',
    tags_ES: 'helicóptero, rojo, volar',
    category: 'objetos',
  },
  {
    id: 1940,
    symbol: '🚟',
    tags_EN: 'suspension, railway, transport',
    tags_ES: 'teleférico, suspensión, cabina',
    category: 'objetos',
  },
  {
    id: 1941,
    symbol: '🚠',
    tags_EN: 'mountain, cableway, ski',
    tags_ES: 'telecabina, suspensión, teleférico',
    category: 'objetos',
  },
  {
    id: 1942,
    symbol: '🚡',
    tags_EN: 'aerial, tramway, ski',
    tags_ES: 'telecabina, teleférico, suspensión',
    category: 'objetos',
  },
  {
    id: 1943,
    symbol: '🛰',
    tags_EN: 'satellite, plane, space',
    tags_ES: 'satélite, espacio, comunicaciones',
    category: 'objetos',
  },
  {
    id: 1944,
    symbol: '🚀',
    tags_EN: 'rocket, space, fly',
    tags_ES: 'cohete, espacio, volar',
    category: 'objetos',
  },
  {
    id: 1945,
    symbol: '🛸',
    tags_EN: 'flying, saucer, ovni',
    tags_ES: 'platillo, volante, extraterrestre',
    category: 'objetos',
  },
  {
    id: 1946,
    symbol: '🛎',
    tags_EN: 'hellhop, bell, ring',
    tags_ES: 'timbre, hotel, ding',
    category: 'objetos',
  },
  {
    id: 1948,
    symbol: '⌛',
    tags_EN: 'hourglass, done, hour',
    tags_ES: 'reloj, arena, tiempo',
    category: 'objetos',
  },
  {
    id: 1949,
    symbol: '⏳',
    tags_EN: 'hourglass, not, done',
    tags_ES: 'reloj, arena, tiempo',
    category: 'objetos',
  },
  {
    id: 1950,
    symbol: '⌚',
    tags_EN: 'watch, wrist, time',
    tags_ES: 'reloj, muñeca, hora',
    category: 'objetos',
  },
  {
    id: 1951,
    symbol: '⏰',
    tags_EN: 'alarm, clock, ring',
    tags_ES: 'reloj, alarma, despertador',
    category: 'objetos',
  },
  {
    id: 1952,
    symbol: '⏱',
    tags_EN: 'stopwatch, clock, cronometer',
    tags_ES: 'cronómetro, reloj, cuenta atrás',
    category: 'objetos',
  },
  {
    id: 1953,
    symbol: '⏲',
    tags_EN: 'timer, clock, time',
    tags_ES: 'temporizador, reloj, tiempo',
    category: 'objetos',
  },
  {
    id: 1954,
    symbol: '🕰',
    tags_EN: 'mantelpiece, clock, time',
    tags_ES: 'reloj, antiguo, hora',
    category: 'objetos',
  },
  {
    id: 1955,
    symbol: '🕛',
    tags_EN: 'twelve, clock, time',
    tags_ES: 'doce, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1956,
    symbol: '🕧',
    tags_EN: 'twelve-thirty, clock, time',
    tags_ES: 'doce y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1957,
    symbol: '🕐',
    tags_EN: 'one, clock, time',
    tags_ES: 'una, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1958,
    symbol: '🕜',
    tags_EN: 'one-thirty, clock, time',
    tags_ES: 'una y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1959,
    symbol: '🕑',
    tags_EN: 'two, clock, time',
    tags_ES: 'dos, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1960,
    symbol: '🕝',
    tags_EN: 'two-thirty, clock, time',
    tags_ES: 'dos y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1961,
    symbol: '🕒',
    tags_EN: 'three, clock, time',
    tags_ES: 'tres, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1962,
    symbol: '🕞',
    tags_EN: 'three-thirty, clock, time',
    tags_ES: 'tres y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1963,
    symbol: '🕓',
    tags_EN: 'four, clock, time',
    tags_ES: 'cuatro, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1964,
    symbol: '🕟',
    tags_EN: 'four-thirty, clock, time',
    tags_ES: 'cuatro y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1965,
    symbol: '🕔',
    tags_EN: 'five, clock, time',
    tags_ES: 'cinco, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1966,
    symbol: '🕠',
    tags_EN: 'five-thirty, clock, time',
    tags_ES: 'cinco y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1967,
    symbol: '🕕',
    tags_EN: 'six, clock, time',
    tags_ES: 'seis, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1968,
    symbol: '🕡',
    tags_EN: 'six-thirty, clock, time',
    tags_ES: 'seis y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1969,
    symbol: '🕖',
    tags_EN: 'seven, clock, time',
    tags_ES: 'siete, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1970,
    symbol: '🕢',
    tags_EN: 'seven-thirty, clock, time',
    tags_ES: 'siete y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1971,
    symbol: '🕗',
    tags_EN: 'eight, clock, time',
    tags_ES: 'ocho, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1972,
    symbol: '🕣',
    tags_EN: 'eight-thirty, clock, time',
    tags_ES: 'ocho y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1973,
    symbol: '🕘',
    tags_EN: 'nine, clock, time',
    tags_ES: 'nueve, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1974,
    symbol: '🕤',
    tags_EN: 'nine-thirty, clock, time',
    tags_ES: 'nueve y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1975,
    symbol: '🕙',
    tags_EN: 'ten, clock, time',
    tags_ES: 'diez, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1976,
    symbol: '🕥',
    tags_EN: 'ten-thirty, clock, time',
    tags_ES: 'diez y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1977,
    symbol: '🕚',
    tags_EN: 'eleven, clock, time',
    tags_ES: 'once, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1978,
    symbol: '🕦',
    tags_EN: 'eleven-thirty, clock, time',
    tags_ES: 'once y media, hora, reloj',
    category: 'objetos',
  },
  {
    id: 1979,
    symbol: '🌑',
    tags_EN: 'new, moon, satellite',
    tags_ES: 'luna, nueva, satélite',
    category: 'objetos',
  },
  {
    id: 1980,
    symbol: '🌒',
    tags_EN: 'waxing, crescent, moon',
    tags_ES: 'luna, creciente, satélite',
    category: 'objetos',
  },
  {
    id: 1981,
    symbol: '🌓',
    tags_EN: 'first, quarter, moon',
    tags_ES: 'luna, cuarto, creciente',
    category: 'objetos',
  },
  {
    id: 1982,
    symbol: '🌔',
    tags_EN: 'waxing, gibbous, moon',
    tags_ES: 'luna, gibosa, creciente',
    category: 'objetos',
  },
  {
    id: 1983,
    symbol: '🌕',
    tags_EN: 'full, moon, satellite',
    tags_ES: 'luna, llena, satélite',
    category: 'objetos',
  },
  {
    id: 1984,
    symbol: '🌖',
    tags_EN: 'waning, gibbous, moon',
    tags_ES: 'luna, gibosa, menguante',
    category: 'objetos',
  },
  {
    id: 1985,
    symbol: '🌗',
    tags_EN: 'last, quarter, moon',
    tags_ES: 'luna, cuarto, menguante',
    category: 'objetos',
  },
  {
    id: 1986,
    symbol: '🌘',
    tags_EN: 'waning, crescent, moon',
    tags_ES: 'luna, menguante, satélite',
    category: 'objetos',
  },
  {
    id: 1987,
    symbol: '🌙',
    tags_EN: 'crescent, moon, satellite',
    tags_ES: 'luna, creciente, satélite',
    category: 'objetos',
  },
  {
    id: 1988,
    symbol: '🌚',
    tags_EN: 'new, moon, face',
    tags_ES: 'luna, nueva, cara',
    category: 'objetos',
  },
  {
    id: 1989,
    symbol: '🌛',
    tags_EN: 'first, quarter, moon',
    tags_ES: 'luna, cuarto, cara',
    category: 'objetos',
  },
  {
    id: 1990,
    symbol: '🌜',
    tags_EN: 'last, quarter, moon',
    tags_ES: 'luna, cuarto, cara',
    category: 'objetos',
  },
  {
    id: 1991,
    symbol: '🌡',
    tags_EN: 'thermometer, temperature, measure',
    tags_ES: 'termómetro, temperatura, medir',
    category: 'objetos',
  },
  {
    id: 1992,
    symbol: '☀',
    tags_EN: 'sun, star, bright',
    tags_ES: 'sol, estrella, fuego',
    category: 'objetos',
  },
  {
    id: 1993,
    symbol: '🌝',
    tags_EN: 'full, moon, face',
    tags_ES: 'luna, llena, cara',
    category: 'objetos',
  },
  {
    id: 1994,
    symbol: '🌞',
    tags_EN: 'sun, face, star',
    tags_ES: 'sol, cara, ojos',
    category: 'objetos',
  },
  {
    id: 1995,
    symbol: '⭐',
    tags_EN: 'white, medium, star',
    tags_ES: 'estrella, blanca, media',
    category: 'objetos',
  },
  {
    id: 1996,
    symbol: '🌟',
    tags_EN: 'glowing, star, shine',
    tags_ES: 'estrella, brilla, relucir',
    category: 'objetos',
  },
  {
    id: 1997,
    symbol: '🌠',
    tags_EN: 'shooting, star, bright',
    tags_ES: 'cometa, estrella, brilla',
    category: 'objetos',
  },
  {
    id: 1998,
    symbol: '☁',
    tags_EN: 'cloud, heaven, rain',
    tags_ES: 'nube, llover, cielo',
    category: 'objetos',
  },
  {
    id: 1999,
    symbol: '⛅',
    tags_EN: 'sun, behind, cloud',
    tags_ES: 'sol, nube, nublado',
    category: 'objetos',
  },
  {
    id: 2000,
    symbol: '⛈',
    tags_EN: 'cloud, lighting, rain',
    tags_ES: 'lluvia, ligera, nubes',
    category: 'objetos',
  },
  {
    id: 2001,
    symbol: '🌤',
    tags_EN: 'sun, behind, cloud',
    tags_ES: 'sol, detrás, nube',
    category: 'objetos',
  },
  {
    id: 2002,
    symbol: '🌥',
    tags_EN: 'sun, behind, cloud',
    tags_ES: 'sol, detrás, nube',
    category: 'objetos',
  },
  {
    id: 2003,
    symbol: '🌦',
    tags_EN: 'sun, behind, cloud',
    tags_ES: 'sol, detrás, nube',
    category: 'objetos',
  },
  {
    id: 2004,
    symbol: '🌧',
    tags_EN: 'cloud, rain, raining',
    tags_ES: 'nube, lluvia, llover',
    category: 'objetos',
  },
  {
    id: 2005,
    symbol: '🌨',
    tags_EN: 'cloud, snow, snowing',
    tags_ES: 'nube, nieve, nevar',
    category: 'objetos',
  },
  {
    id: 2006,
    symbol: '🌩',
    tags_EN: 'cloud, lighting, light',
    tags_ES: 'nube, rayo, tormenta',
    category: 'objetos',
  },
  {
    id: 2007,
    symbol: '🌪',
    tags_EN: 'tornado, wind, strong',
    tags_ES: 'tornado, viento, remolino',
    category: 'objetos',
  },
  {
    id: 2008,
    symbol: '🌫',
    tags_EN: 'fog, smoke, foggy',
    tags_ES: 'niebla, nublado, borroso',
    category: 'objetos',
  },
  {
    id: 2009,
    symbol: '🌬',
    tags_EN: 'wind, face, windy',
    tags_ES: 'cara, viento, vendaval',
    category: 'objetos',
  },
  {
    id: 2010,
    symbol: '🌀',
    tags_EN: 'cyclone, spiral, blue',
    tags_ES: 'espiral, ciclón, azul',
    category: 'objetos',
  },
  {
    id: 2011,
    symbol: '🌈',
    tags_EN: 'rainbow, colors, rain',
    tags_ES: 'arcoíris, colores, lluvia',
    category: 'objetos',
  },
  {
    id: 2012,
    symbol: '🌂',
    tags_EN: 'closed, umbrella, rain',
    tags_ES: 'paraguas, cerrado, lluvia',
    category: 'objetos',
  },
  {
    id: 2013,
    symbol: '☂',
    tags_EN: 'umbrella, rain, open',
    tags_ES: 'paraguas, abierto, lluvia',
    category: 'objetos',
  },
  {
    id: 2014,
    symbol: '☔',
    tags_EN: 'umbrella, rain, drops',
    tags_ES: 'paraguas, lluvia, agua',
    category: 'objetos',
  },
  {
    id: 2015,
    symbol: '⛱',
    tags_EN: 'umbrella, ground, beach',
    tags_ES: 'paraguas, sombrilla, playa',
    category: 'objetos',
  },
  {
    id: 2016,
    symbol: '⚡',
    tags_EN: 'high, voltage, light',
    tags_ES: 'rayo, alto, voltaje',
    category: 'objetos',
  },
  {
    id: 2017,
    symbol: '❄',
    tags_EN: 'snowflake, snow, winter',
    tags_ES: 'invierno, copo, nieve',
    category: 'objetos',
  },
  {
    id: 2018,
    symbol: '☃',
    tags_EN: 'snowman, snow, winter',
    tags_ES: 'muñeco, nieve, invierno',
    category: 'objetos',
  },
  {
    id: 2019,
    symbol: '⛄',
    tags_EN: 'snowman, without, snow',
    tags_ES: 'muñeco, nieve, invierno',
    category: 'objetos',
  },
  {
    id: 2020,
    symbol: '☄',
    tags_EN: 'comet, star, space',
    tags_ES: 'cometa, espacio, meteorito',
    category: 'objetos',
  },
  {
    id: 2021,
    symbol: '🔥',
    tags_EN: 'fire, burn, hot',
    tags_ES: 'fuego, quemar, caliente',
    category: 'objetos',
  },
  {
    id: 2022,
    symbol: '💧',
    tags_EN: 'droplet, water, wet',
    tags_ES: 'gota, agua, mojar',
    category: 'objetos',
  },
  {
    id: 2023,
    symbol: '🌊',
    tags_EN: 'water, wave, sea',
    tags_ES: 'ola, mar, surf',
    category: 'objetos',
  },
  {
    id: 2024,
    symbol: '🎃',
    tags_EN: 'jack-o-lantern, halloween, pumpkin',
    tags_ES: 'calabaza, decoración, santos',
    category: 'objetos',
  },
  {
    id: 2025,
    symbol: '🎄',
    tags_EN: 'christmas, tree, decoration',
    tags_ES: 'árbol, navidad, luces',
    category: 'objetos',
  },
  {
    id: 2026,
    symbol: '🎆',
    tags_EN: 'fireworks, explosion, lights',
    tags_ES: 'fuegos, artificiales, petardo',
    category: 'objetos',
  },
  {
    id: 2027,
    symbol: '🎇',
    tags_EN: 'sparkler, light, fire',
    tags_ES: 'bengala, fuego, petardo',
    category: 'objetos',
  },
  {
    id: 2029,
    symbol: '✨',
    tags_EN: 'sparkles, star, bright',
    tags_ES: 'destellos, brillar, estrella',
    category: 'objetos',
  },
  {
    id: 2030,
    symbol: '🎈',
    tags_EN: 'balloon, red, party',
    tags_ES: 'globo, fiesta, rojo',
    category: 'objetos',
  },
  {
    id: 2031,
    symbol: '🎉',
    tags_EN: 'party, popper, birthday',
    tags_ES: 'cumpleaños, fiesta, celebrar',
    category: 'objetos',
  },
  {
    id: 2032,
    symbol: '🎊',
    tags_EN: 'party, confetti, ball',
    tags_ES: 'cumpleaños, fiesta, celebrar',
    category: 'objetos',
  },
  {
    id: 2033,
    symbol: '🎋',
    tags_EN: 'tanabata, tree, japan',
    tags_ES: 'árbol, tradición, japonesa',
    category: 'objetos',
  },
  {
    id: 2034,
    symbol: '🎍',
    tags_EN: 'pine, decoration, tree',
    tags_ES: 'árbol, decoración, piña',
    category: 'objetos',
  },
  {
    id: 2035,
    symbol: '🎎',
    tags_EN: 'japanese, dolls, traditional',
    tags_ES: 'japonesa, muñecas, tradicional',
    category: 'objetos',
  },
  {
    id: 2036,
    symbol: '🎏',
    tags_EN: 'carp, streamer, flag',
    tags_ES: 'bandera, peces, japonés',
    category: 'objetos',
  },
  {
    id: 2037,
    symbol: '🎐',
    tags_EN: 'wind, chime, instrument',
    tags_ES: 'campana, viento, instrumento',
    category: 'objetos',
  },
  {
    id: 2038,
    symbol: '🎑',
    tags_EN: 'moon, ceremony, night',
    tags_ES: 'luna, ceremonia, noche',
    category: 'objetos',
  },
  {
    id: 2040,
    symbol: '🎀',
    tags_EN: 'ribbon, decoration, pink',
    tags_ES: 'lazo, decoración, rosa',
    category: 'objetos',
  },
  {
    id: 2041,
    symbol: '🎁',
    tags_EN: 'wrapped, gift, birthday',
    tags_ES: 'regalo, cumpleaños, envuelto',
    category: 'objetos',
  },
  {
    id: 2042,
    symbol: '🎗',
    tags_EN: 'reminder, ribbon, yellow',
    tags_ES: 'lazo, recordar, amarillo',
    category: 'objetos',
  },
  {
    id: 2043,
    symbol: '🎟',
    tags_EN: 'admission, tickets, entry',
    tags_ES: 'tique, admisión, rosa',
    category: 'objetos',
  },
  {
    id: 2044,
    symbol: '🎫',
    tags_EN: 'admission, ticket, entry',
    tags_ES: 'tique, admisión, amarillo',
    category: 'objetos',
  },
  {
    id: 2045,
    symbol: '🎖',
    tags_EN: 'military, medal, star',
    tags_ES: 'medalla, militar, estrella',
    category: 'objetos',
  },
  {
    id: 2046,
    symbol: '🏆',
    tags_EN: 'trophy, champion, cup',
    tags_ES: 'trofeo, copa, campeón',
    category: 'objetos',
  },
  {
    id: 2047,
    symbol: '🏅',
    tags_EN: 'sports, medal, gold',
    tags_ES: 'medalla, ganador, oro',
    category: 'objetos',
  },
  {
    id: 2048,
    symbol: '🥇',
    tags_EN: '1st, place, medal',
    tags_ES: 'primera, oro, medalla',
    category: 'objetos',
  },
  {
    id: 2049,
    symbol: '🥈',
    tags_EN: '2nd, place, medal',
    tags_ES: 'segunda, plata, medalla',
    category: 'objetos',
  },
  {
    id: 2050,
    symbol: '🥉',
    tags_EN: '3rd, place, medal',
    tags_ES: 'tercera, bronce, medalla',
    category: 'objetos',
  },
  {
    id: 2051,
    symbol: '⚽',
    tags_EN: 'soccer, ball, football',
    tags_ES: 'fútbol, pelota, cuero',
    category: 'objetos',
  },
  {
    id: 2052,
    symbol: '⚾',
    tags_EN: 'baseball, ball, white',
    tags_ES: 'béisbol, pelota, blanca',
    category: 'objetos',
  },
  {
    id: 2054,
    symbol: '🏀',
    tags_EN: 'basketball, ball, orange',
    tags_ES: 'pelota, baloncesto, básquet',
    category: 'objetos',
  },
  {
    id: 2055,
    symbol: '🏐',
    tags_EN: 'volleyball, ball, white',
    tags_ES: 'pelota, voleibol, blanca',
    category: 'objetos',
  },
  {
    id: 2056,
    symbol: '🏈',
    tags_EN: 'american, football, rugby',
    tags_ES: 'pelota, fútbol, americano',
    category: 'objetos',
  },
  {
    id: 2057,
    symbol: '🏉',
    tags_EN: 'american, football, rugby',
    tags_ES: 'pelota, rugby, ovalada',
    category: 'objetos',
  },
  {
    id: 2058,
    symbol: '🎾',
    tags_EN: 'tennis, racket, ball',
    tags_ES: 'tenis, pelota, verde',
    category: 'objetos',
  },
  {
    id: 2060,
    symbol: '🎳',
    tags_EN: 'bowling, strike, ball',
    tags_ES: 'bolos, bola, juego',
    category: 'objetos',
  },
  {
    id: 2061,
    symbol: '🏏',
    tags_EN: 'cricket, game, ball',
    tags_ES: 'india, deporte, pelota',
    category: 'objetos',
  },
  {
    id: 2062,
    symbol: '🏑',
    tags_EN: 'field, hockey, ball',
    tags_ES: 'stick, palo, pelota',
    category: 'objetos',
  },
  {
    id: 2063,
    symbol: '🏒',
    tags_EN: 'ice, hockey, stick',
    tags_ES: 'hielo, patines, palo',
    category: 'objetos',
  },
  {
    id: 2065,
    symbol: '🏓',
    tags_EN: 'ping, pong, racket',
    tags_ES: 'pala, pelota, juego',
    category: 'objetos',
  },
  {
    id: 2066,
    symbol: '🏸',
    tags_EN: 'badminton, racket, game',
    tags_ES: 'raqueta, pluma, red',
    category: 'objetos',
  },
  {
    id: 2067,
    symbol: '🥊',
    tags_EN: 'boxing, glove, ring',
    tags_ES: 'boxeo, guante, boxear',
    category: 'objetos',
  },
  {
    id: 2068,
    symbol: '🥋',
    tags_EN: 'martial, arts, uniform',
    tags_ES: 'uniforme, artes, marciales',
    category: 'objetos',
  },
  {
    id: 2069,
    symbol: '🥅',
    tags_EN: 'goal, net, football',
    tags_ES: 'red, fútbol, portería',
    category: 'objetos',
  },
  {
    id: 2070,
    symbol: '⛳',
    tags_EN: 'flag, hole, golf',
    tags_ES: 'banderín, agujero, hoyo',
    category: 'objetos',
  },
  {
    id: 2071,
    symbol: '⛸',
    tags_EN: 'ice, skate, skating',
    tags_ES: 'hielo, patinar, patines',
    category: 'objetos',
  },
  {
    id: 2072,
    symbol: '🎣',
    tags_EN: 'fishing, pole, fish',
    tags_ES: 'caña, pescar, pez',
    category: 'objetos',
  },
  {
    id: 2073,
    symbol: '🎽',
    tags_EN: 'running, shirt, run',
    tags_ES: 'camiseta, correr, tirantes',
    category: 'objetos',
  },
  {
    id: 2074,
    symbol: '🎿',
    tags_EN: 'skis, snow, ski',
    tags_ES: 'esquiar, esquís, nieve',
    category: 'objetos',
  },
  {
    id: 2075,
    symbol: '🛷',
    tags_EN: 'sled, snow, ski',
    tags_ES: 'trineo, nieve, esquiar',
    category: 'objetos',
  },
  {
    id: 2076,
    symbol: '🥌',
    tags_EN: 'curling, stone, ice',
    tags_ES: 'piedra, hielo, deporte',
    category: 'objetos',
  },
  {
    id: 2077,
    symbol: '🎯',
    tags_EN: 'direct, hit, dart',
    tags_ES: 'diana, dardo, centro',
    category: 'objetos',
  },
  {
    id: 2078,
    symbol: '🎱',
    tags_EN: 'pool, ball, black',
    tags_ES: 'billar, bola, negra',
    category: 'objetos',
  },
  {
    id: 2079,
    symbol: '🔮',
    tags_EN: 'crystall, ball, purple',
    tags_ES: 'bola, cristal, púrpura',
    category: 'objetos',
  },
  {
    id: 2081,
    symbol: '🎮',
    tags_EN: 'video, game, play',
    tags_ES: 'videojuego, jugar, mando',
    category: 'objetos',
  },
  {
    id: 2082,
    symbol: '🕹',
    tags_EN: 'joystick, game, play',
    tags_ES: 'videojuego, palanca, mando',
    category: 'objetos',
  },
  {
    id: 2083,
    symbol: '🎰',
    tags_EN: 'slot, machine, play',
    tags_ES: 'máquina, tragaperras, casino',
    category: 'objetos',
  },
  {
    id: 2084,
    symbol: '🎲',
    tags_EN: 'game, die, luck',
    tags_ES: 'dado, seis, caras',
    category: 'objetos',
  },
  {
    id: 2087,
    symbol: '♠',
    tags_EN: 'spade, suit, poker',
    tags_ES: 'cartas, picas, casino',
    category: 'objetos',
  },
  {
    id: 2088,
    symbol: '♥',
    tags_EN: 'heart, suit, poker',
    tags_ES: 'cartas, corazones, casino',
    category: 'objetos',
  },
  {
    id: 2089,
    symbol: '♦',
    tags_EN: 'diamond, suit, poker',
    tags_ES: 'cartas, diamantes, casino',
    category: 'objetos',
  },
  {
    id: 2090,
    symbol: '♣',
    tags_EN: 'club, suit, poker',
    tags_ES: 'cartas, tréboles, casino',
    category: 'objetos',
  },
  {
    id: 2091,
    symbol: '🃏',
    tags_EN: 'joker, cards, poker',
    tags_ES: 'cartas, comodín, j',
    category: 'objetos',
  },
  {
    id: 2092,
    symbol: '🀄',
    tags_EN: 'mahjong, red, dragon',
    tags_ES: 'solitario, juego, chino',
    category: 'objetos',
  },
  {
    id: 2093,
    symbol: '🎴',
    tags_EN: 'flower, playing, cards',
    tags_ES: 'cartas, juego, japonés',
    category: 'objetos',
  },
  {
    id: 2094,
    symbol: '🎭',
    tags_EN: 'performing, arts, mask',
    tags_ES: 'máscaras, arte, teatro',
    category: 'objetos',
  },
  {
    id: 2095,
    symbol: '🖼',
    tags_EN: 'framed, picture, landscape',
    tags_ES: 'cuadro, paisaje, marco',
    category: 'objetos',
  },
  {
    id: 2096,
    symbol: '🎨',
    tags_EN: 'artist, palette, colors',
    tags_ES: 'paleta, colores, pintar',
    category: 'objetos',
  },
  {
    id: 2097,
    symbol: '🔇',
    tags_EN: 'muted, speaker, silence',
    tags_ES: 'altavoz, silencio, sin sonido',
    category: 'objetos',
  },
  {
    id: 2098,
    symbol: '🔈',
    tags_EN: 'low, speaker, volume',
    tags_ES: 'altavoz, audio, bajo',
    category: 'objetos',
  },
  {
    id: 2099,
    symbol: '🔉',
    tags_EN: 'medium, speaker, volume',
    tags_ES: 'altavoz, audio, medio',
    category: 'objetos',
  },
  {
    id: 2100,
    symbol: '🔊',
    tags_EN: 'high, speaker, volume',
    tags_ES: 'altavoz, audio, alto',
    category: 'objetos',
  },
  {
    id: 2101,
    symbol: '📢',
    tags_EN: 'loudspeaker, speaker, volume',
    tags_ES: 'altavoz, megáfono, audio',
    category: 'objetos',
  },
  {
    id: 2102,
    symbol: '📣',
    tags_EN: 'magaphone, speaker, volume',
    tags_ES: 'altavoz, megáfono, audio',
    category: 'objetos',
  },
  {
    id: 2103,
    symbol: '📯',
    tags_EN: 'postal, horn, audio',
    tags_ES: 'altavoz, megáfono, correos',
    category: 'objetos',
  },
  {
    id: 2104,
    symbol: '🔔',
    tags_EN: 'bell, volume, audio',
    tags_ES: 'campana, volumen, activado',
    category: 'objetos',
  },
  {
    id: 2105,
    symbol: '🔕',
    tags_EN: 'bell, slash, no audio',
    tags_ES: 'campana, volumen, desactivado',
    category: 'objetos',
  },
  {
    id: 2106,
    symbol: '🎼',
    tags_EN: 'musical, score, music',
    tags_ES: 'partitura, música, clave de sol',
    category: 'objetos',
  },
  {
    id: 2107,
    symbol: '🎵',
    tags_EN: 'musical, note, music',
    tags_ES: 'nota, musical, música',
    category: 'objetos',
  },
  {
    id: 2108,
    symbol: '🎶',
    tags_EN: 'musical, notes, music',
    tags_ES: 'notas, musicales, música',
    category: 'objetos',
  },
  {
    id: 2109,
    symbol: '🎙',
    tags_EN: 'studio, microphone, music',
    tags_ES: 'micrófono, estudio, audio',
    category: 'objetos',
  },
  {
    id: 2110,
    symbol: '🎚',
    tags_EN: 'level, slider, music',
    tags_ES: 'control, volumen, dj',
    category: 'objetos',
  },
  {
    id: 2111,
    symbol: '🎛',
    tags_EN: 'control, knobs, music',
    tags_ES: 'control, dj, controladores',
    category: 'objetos',
  },
  {
    id: 2112,
    symbol: '🎤',
    tags_EN: 'microphone, audio, music',
    tags_ES: 'micrófono, música, voz',
    category: 'objetos',
  },
  {
    id: 2113,
    symbol: '🎧',
    tags_EN: 'headphone, audio, listen',
    tags_ES: 'auriculares, cascos, escuchar',
    category: 'objetos',
  },
  {
    id: 2114,
    symbol: '📻',
    tags_EN: 'radio, audio, listen',
    tags_ES: 'escuchar, programa, radio',
    category: 'objetos',
  },
  {
    id: 2115,
    symbol: '🎷',
    tags_EN: 'saxophone, audio, listen',
    tags_ES: 'instrumento, saxo, saxofón',
    category: 'objetos',
  },
  {
    id: 2116,
    symbol: '🎸',
    tags_EN: 'guitar, acoustic, instrument',
    tags_ES: 'guitarra, instrumento, eléctrica',
    category: 'objetos',
  },
  {
    id: 2117,
    symbol: '🎹',
    tags_EN: 'musical, keyboard, piano',
    tags_ES: 'teclado, música, instrumento',
    category: 'objetos',
  },
  {
    id: 2118,
    symbol: '🎺',
    tags_EN: 'trumpet, audio, blown',
    tags_ES: 'trompeta, viento, instrumento',
    category: 'objetos',
  },
  {
    id: 2119,
    symbol: '🎻',
    tags_EN: 'violin, audio, acoustic',
    tags_ES: 'violín, acústico, instrumento',
    category: 'objetos',
  },
  {
    id: 2120,
    symbol: '🥁',
    tags_EN: 'drum, audio, percussion',
    tags_ES: 'tambor, percusión, instrumento',
    category: 'objetos',
  },
  {
    id: 2121,
    symbol: '📱',
    tags_EN: 'mobile, phone, call',
    tags_ES: 'teléfono, móvil, llamar',
    category: 'objetos',
  },
  {
    id: 2122,
    symbol: '📲',
    tags_EN: 'mobile, phone, arrow',
    tags_ES: 'teléfono, móvil, flecha',
    category: 'objetos',
  },
  {
    id: 2123,
    symbol: '☎',
    tags_EN: 'telephone, analog, clásico',
    tags_ES: 'teléfono, analógico, clásico',
    category: 'objetos',
  },
  {
    id: 2124,
    symbol: '📞',
    tags_EN: 'telephone, receiver, classic',
    tags_ES: 'teléfono, auricular, clásico',
    category: 'objetos',
  },
  {
    id: 2125,
    symbol: '📟',
    tags_EN: 'pager, search, people',
    tags_ES: 'busca, buscapersonas, mensáfono',
    category: 'objetos',
  },
  {
    id: 2126,
    symbol: '📠',
    tags_EN: 'fax, machine, message',
    tags_ES: 'máquina, fax, mensaje',
    category: 'objetos',
  },
  {
    id: 2127,
    symbol: '🔋',
    tags_EN: 'battery, lithium, charge',
    tags_ES: 'batería, litio, cargar',
    category: 'objetos',
  },
  {
    id: 2128,
    symbol: '🔌',
    tags_EN: 'electric, plug, charge',
    tags_ES: 'enchufe, conector, eléctrico',
    category: 'objetos',
  },
  {
    id: 2129,
    symbol: '💻',
    tags_EN: 'laptop, computer, macbook',
    tags_ES: 'ordenador, portátil, apple',
    category: 'objetos',
  },
  {
    id: 2130,
    symbol: '🖥',
    tags_EN: 'desktopo, computer, imac',
    tags_ES: 'ordenador, sobremesa, apple',
    category: 'objetos',
  },
  {
    id: 2131,
    symbol: '🖨',
    tags_EN: 'printer, print, epson',
    tags_ES: 'impresora, imprimir, láser',
    category: 'objetos',
  },
  {
    id: 2132,
    symbol: '⌨',
    tags_EN: 'keyboard, write, key',
    tags_ES: 'teclado , teclas, escribir',
    category: 'objetos',
  },
  {
    id: 2133,
    symbol: '🖱',
    tags_EN: 'computer, mouse, control',
    tags_ES: 'ratón, ordenador, controlar',
    category: 'objetos',
  },
  {
    id: 2134,
    symbol: '🖲',
    tags_EN: 'trackball, computer, control',
    tags_ES: 'controlador, ordenador, controlar',
    category: 'objetos',
  },
  {
    id: 2135,
    symbol: '💽',
    tags_EN: 'computer, disk, cd',
    tags_ES: 'cd-rom, disco, carátula',
    category: 'objetos',
  },
  {
    id: 2136,
    symbol: '💾',
    tags_EN: 'floppy, disk, old',
    tags_ES: 'disquete, 3 1/2, disco',
    category: 'objetos',
  },
  {
    id: 2137,
    symbol: '💿',
    tags_EN: 'computer, disk, cd',
    tags_ES: 'cd-rom, disco, óptico',
    category: 'objetos',
  },
  {
    id: 2138,
    symbol: '📀',
    tags_EN: 'computer, disk, cd',
    tags_ES: 'cd-rom, disco, dvd',
    category: 'objetos',
  },
  {
    id: 2140,
    symbol: '🎥',
    tags_EN: 'movie, camera, film',
    tags_ES: 'cámara, cine, grabar',
    category: 'objetos',
  },
  {
    id: 2141,
    symbol: '🎞',
    tags_EN: 'film, frames, cinema',
    tags_ES: 'cine, fotogramas, película',
    category: 'objetos',
  },
  {
    id: 2142,
    symbol: '📽',
    tags_EN: 'film, projector, cinema',
    tags_ES: 'proyecto, cine, película',
    category: 'objetos',
  },
  {
    id: 2143,
    symbol: '🎬',
    tags_EN: 'clapper, board, cinema',
    tags_ES: 'claqueta, cine, película',
    category: 'objetos',
  },
  {
    id: 2144,
    symbol: '📺',
    tags_EN: 'television, tv, old',
    tags_ES: 'televisión, antigua, tele',
    category: 'objetos',
  },
  {
    id: 2145,
    symbol: '📷',
    tags_EN: 'camera, digital, photo',
    tags_ES: 'cámara, foto, imagen',
    category: 'objetos',
  },
  {
    id: 2146,
    symbol: '📸',
    tags_EN: 'camera, digital, flash',
    tags_ES: 'cámara, digital, flash',
    category: 'objetos',
  },
  {
    id: 2147,
    symbol: '📹',
    tags_EN: 'video, camera, film',
    tags_ES: 'cámara, vídeo, película',
    category: 'objetos',
  },
  {
    id: 2148,
    symbol: '📼',
    tags_EN: 'videocassette, video, film',
    tags_ES: 'vhs, vídeo, película',
    category: 'objetos',
  },
  {
    id: 2149,
    symbol: '🔍',
    tags_EN: 'magnifying, glass, left',
    tags_ES: 'lupa, izquierda, aumentar',
    category: 'objetos',
  },
  {
    id: 2150,
    symbol: '🔎',
    tags_EN: 'magnifying, glass, right',
    tags_ES: 'lupa, derecha, aumentar',
    category: 'objetos',
  },
  {
    id: 2151,
    symbol: '🕯',
    tags_EN: 'candle, fire, old',
    tags_ES: 'vela, cera, antigua',
    category: 'objetos',
  },
  {
    id: 2152,
    symbol: '💡',
    tags_EN: 'light, bulb, illuminate',
    tags_ES: 'bombilla, iluminar, lámpara',
    category: 'objetos',
  },
  {
    id: 2153,
    symbol: '🔦',
    tags_EN: 'flashlight, illuminate, light',
    tags_ES: 'linterna, iluminar, ver',
    category: 'objetos',
  },
  {
    id: 2154,
    symbol: '🏮',
    tags_EN: 'red, paper, lantern',
    tags_ES: 'linterna, papel, roja',
    category: 'objetos',
  },
  {
    id: 2155,
    symbol: '📔',
    tags_EN: 'notebook, decorative, cover',
    tags_ES: 'libreta, libro, apuntes',
    category: 'objetos',
  },
  {
    id: 2156,
    symbol: '📕',
    tags_EN: 'closed, book, red',
    tags_ES: 'libro, cerrado, cerrado',
    category: 'objetos',
  },
  {
    id: 2157,
    symbol: '📖',
    tags_EN: 'open, book, pages',
    tags_ES: 'libro, abierto, páginas',
    category: 'objetos',
  },
  {
    id: 2158,
    symbol: '📗',
    tags_EN: 'green, book, closed',
    tags_ES: 'libro, cerrado, verde',
    category: 'objetos',
  },
  {
    id: 2159,
    symbol: '📘',
    tags_EN: 'blue, book, closed',
    tags_ES: 'libro, cerrado, azul',
    category: 'objetos',
  },
  {
    id: 2160,
    symbol: '📙',
    tags_EN: 'orange, book, closed',
    tags_ES: 'libro, cerrado, naranja',
    category: 'objetos',
  },
  {
    id: 2161,
    symbol: '📚',
    tags_EN: 'books, closed, library',
    tags_ES: 'libros, biblioteca, leer',
    category: 'objetos',
  },
  {
    id: 2162,
    symbol: '📓',
    tags_EN: 'notebook, notes, write',
    tags_ES: 'libreta, libro, apuntes',
    category: 'objetos',
  },
  {
    id: 2163,
    symbol: '📒',
    tags_EN: 'ledger, notes, write',
    tags_ES: 'libro, contabilidad, contable',
    category: 'objetos',
  },
  {
    id: 2164,
    symbol: '📃',
    tags_EN: 'page, curl, paper',
    tags_ES: 'página, doblada, documento',
    category: 'objetos',
  },
  {
    id: 2165,
    symbol: '📜',
    tags_EN: 'scroll, page, curl',
    tags_ES: 'pergamino, página, documento',
    category: 'objetos',
  },
  {
    id: 2166,
    symbol: '📄',
    tags_EN: 'page, facing, up',
    tags_ES: 'página, doblada, documento',
    category: 'objetos',
  },
  {
    id: 2167,
    symbol: '📰',
    tags_EN: 'newspaper, news, publication',
    tags_ES: 'diario, periódico, noticias',
    category: 'objetos',
  },
  {
    id: 2168,
    symbol: '🗞',
    tags_EN: 'rolled-up, newspaper, news',
    tags_ES: 'periódico, diario, enrollado',
    category: 'objetos',
  },
  {
    id: 2169,
    symbol: '📑',
    tags_EN: 'bookmark, tabs, book',
    tags_ES: 'marcadores, libro, punto',
    category: 'objetos',
  },
  {
    id: 2170,
    symbol: '🔖',
    tags_EN: 'bookmark, book, mark',
    tags_ES: 'marcadores, libro, punto',
    category: 'objetos',
  },
  {
    id: 2171,
    symbol: '🏷',
    tags_EN: 'label, tag, tagging',
    tags_ES: 'etiqueta, etiquetar, etiquetado',
    category: 'objetos',
  },
  {
    id: 2172,
    symbol: '💰',
    tags_EN: 'money, bag, cash',
    tags_ES: 'bolsa, dinero, monedas',
    category: 'objetos',
  },
  {
    id: 2173,
    symbol: '💴',
    tags_EN: 'yen, banknote, money',
    tags_ES: 'moneda, japonesa, billete',
    category: 'objetos',
  },
  {
    id: 2174,
    symbol: '💵',
    tags_EN: 'dollar, banknote, money',
    tags_ES: 'moneda, americano, billete',
    category: 'objetos',
  },
  {
    id: 2175,
    symbol: '💶',
    tags_EN: 'euro, banknote, money',
    tags_ES: 'moneda, europeo, billete',
    category: 'objetos',
  },
  {
    id: 2176,
    symbol: '💷',
    tags_EN: 'pound, banknote, money',
    tags_ES: 'moneda, inglés, billete',
    category: 'objetos',
  },
  {
    id: 2177,
    symbol: '💸',
    tags_EN: 'money, wings, fly',
    tags_ES: 'dinero, alas, volar',
    category: 'objetos',
  },
  {
    id: 2178,
    symbol: '💳',
    tags_EN: 'credit, card, money',
    tags_ES: 'tarjeta, crédito, deuda',
    category: 'objetos',
  },
  {
    id: 2180,
    symbol: '💹',
    tags_EN: 'chart, increasing, yen',
    tags_ES: 'gráfico, ascendente, japonés',
    category: 'objetos',
  },
  {
    id: 2181,
    symbol: '💱',
    tags_EN: 'currency, exchange, money',
    tags_ES: 'cambio, moneda, casa',
    category: 'objetos',
  },
  {
    id: 2182,
    symbol: '💲',
    tags_EN: 'heavy, dollar, sign',
    tags_ES: 'dólar, símbolo, negrita',
    category: 'objetos',
  },
  {
    id: 2183,
    symbol: '✉',
    tags_EN: 'envelope, letter, send',
    tags_ES: 'sobre, carta, correo',
    category: 'objetos',
  },
  {
    id: 2184,
    symbol: '📧',
    tags_EN: 'e-mail, mail, send',
    tags_ES: 'correo, electrónico, enviar',
    category: 'objetos',
  },
  {
    id: 2185,
    symbol: '📨',
    tags_EN: 'incoming, envelope, mail',
    tags_ES: 'bandeja, entrada, correo',
    category: 'objetos',
  },
  {
    id: 2186,
    symbol: '📩',
    tags_EN: 'envelope, arrow, mail',
    tags_ES: 'carta, flecha, correo',
    category: 'objetos',
  },
  {
    id: 2187,
    symbol: '📤',
    tags_EN: 'outbox, tray, mail',
    tags_ES: 'bandeja, salida, correo',
    category: 'objetos',
  },
  {
    id: 2188,
    symbol: '📥',
    tags_EN: 'inbox, tray, mail',
    tags_ES: 'bandeja, entrada, correo',
    category: 'objetos',
  },
  {
    id: 2189,
    symbol: '📦',
    tags_EN: 'package, send, box',
    tags_ES: 'paquete, enviar, caja',
    category: 'objetos',
  },
  {
    id: 2190,
    symbol: '📫',
    tags_EN: 'closed, mailbox, flag',
    tags_ES: 'bandeja, correo, bandera roja',
    category: 'objetos',
  },
  {
    id: 2191,
    symbol: '📪',
    tags_EN: 'closed, mailbox, flag',
    tags_ES: 'bandeja, correo, bandera roja',
    category: 'objetos',
  },
  {
    id: 2192,
    symbol: '📬',
    tags_EN: 'open, mailbox, flag',
    tags_ES: 'bandeja, correo, bandera roja',
    category: 'objetos',
  },
  {
    id: 2193,
    symbol: '📭',
    tags_EN: 'open, mailbox, flag',
    tags_ES: 'bandeja, correo, bandera roja',
    category: 'objetos',
  },
  {
    id: 2194,
    symbol: '📮',
    tags_EN: 'postbox, mailbox, mail',
    tags_ES: 'buzón, correo, carta',
    category: 'objetos',
  },
  {
    id: 2195,
    symbol: '🗳',
    tags_EN: 'ballot, box, ballot',
    tags_ES: 'urna, votar, votación',
    category: 'objetos',
  },
  {
    id: 2196,
    symbol: '✏',
    tags_EN: 'pencil, write, sketch',
    tags_ES: 'lápiz, dibujar, esbozar',
    category: 'objetos',
  },
  {
    id: 2197,
    symbol: '✒',
    tags_EN: 'black, nib, write',
    tags_ES: 'pluma, negra, escribir',
    category: 'objetos',
  },
  {
    id: 2198,
    symbol: '🖋',
    tags_EN: 'fountain, pen, write',
    tags_ES: 'pluma, negra, escribir',
    category: 'objetos',
  },
  {
    id: 2199,
    symbol: '🖊',
    tags_EN: 'pen, write, sketch',
    tags_ES: 'bolígrafo, boli, escribir',
    category: 'objetos',
  },
  {
    id: 2200,
    symbol: '🖌',
    tags_EN: 'paintbrush, draw, paint',
    tags_ES: 'pintar, esbozar, pintura',
    category: 'objetos',
  },
  {
    id: 2201,
    symbol: '🖍',
    tags_EN: 'crayon, red, paint',
    tags_ES: 'rotulador, pintar, rojo',
    category: 'objetos',
  },
  {
    id: 2202,
    symbol: '📝',
    tags_EN: 'memo, note, write',
    tags_ES: 'nota, recordatorio, escribir',
    category: 'objetos',
  },
  {
    id: 2203,
    symbol: '💼',
    tags_EN: 'briefcase, bag, papers',
    tags_ES: 'maletín, maleta, papeles',
    category: 'objetos',
  },
  {
    id: 2204,
    symbol: '📁',
    tags_EN: 'file, folder, documents',
    tags_ES: 'archivo, carpeta, documentos',
    category: 'objetos',
  },
  {
    id: 2205,
    symbol: '📂',
    tags_EN: 'open, file, folder',
    tags_ES: 'carpeta, abierta, documentos',
    category: 'objetos',
  },
  {
    id: 2206,
    symbol: '🗂',
    tags_EN: 'card, index, dividers',
    tags_ES: 'divisores, carpetas, índice',
    category: 'objetos',
  },
  {
    id: 2207,
    symbol: '📅',
    tags_EN: 'calendar, day, week',
    tags_ES: 'calendario, días, semanas',
    category: 'objetos',
  },
  {
    id: 2208,
    symbol: '📆',
    tags_EN: 'tear-off, calendar, day',
    tags_ES: 'calendario, días, semanas',
    category: 'objetos',
  },
  {
    id: 2209,
    symbol: '🗒',
    tags_EN: 'spiral, notepad, write',
    tags_ES: 'libreta, espiral, escribir',
    category: 'objetos',
  },
  {
    id: 2210,
    symbol: '🗓',
    tags_EN: 'spiral, calendar, days',
    tags_ES: 'calendario, espiral, días',
    category: 'objetos',
  },
  {
    id: 2211,
    symbol: '📇',
    tags_EN: 'card, index, box',
    tags_ES: 'fichas, caja, bandeja',
    category: 'objetos',
  },
  {
    id: 2212,
    symbol: '📈',
    tags_EN: 'chart, increasing, up',
    tags_ES: 'gráfica, ascendente, incrementa',
    category: 'objetos',
  },
  {
    id: 2213,
    symbol: '📉',
    tags_EN: 'chart, decreasing, down',
    tags_ES: 'gráfica, descendente, decrece',
    category: 'objetos',
  },
  {
    id: 2214,
    symbol: '📊',
    tags_EN: 'bar, chart, graph',
    tags_ES: 'gráfica, barras, gráfico',
    category: 'objetos',
  },
  {
    id: 2215,
    symbol: '📋',
    tags_EN: 'clipboard, paper, copy',
    tags_ES: 'portapapeles, copiar, papeles',
    category: 'objetos',
  },
  {
    id: 2216,
    symbol: '📌',
    tags_EN: 'pushpin, paper, note',
    tags_ES: 'pin, corcho, papeles',
    category: 'objetos',
  },
  {
    id: 2217,
    symbol: '📍',
    tags_EN: 'round, pushpin, location',
    tags_ES: 'localización, pin, ciudad',
    category: 'objetos',
  },
  {
    id: 2218,
    symbol: '📎',
    tags_EN: 'paperclip, paper, clip',
    tags_ES: 'papel, agrupar, juntar',
    category: 'objetos',
  },
  {
    id: 2219,
    symbol: '🖇',
    tags_EN: 'linked, paperclips, clips',
    tags_ES: 'clip, papeles, agrupar',
    category: 'objetos',
  },
  {
    id: 2220,
    symbol: '📏',
    tags_EN: 'straight, ruler, draw',
    tags_ES: 'regla, línea, dibujar',
    category: 'objetos',
  },
  {
    id: 2221,
    symbol: '📐',
    tags_EN: 'triangular, ruler, draw',
    tags_ES: 'escuadra, cartabón, regla',
    category: 'objetos',
  },
  {
    id: 2222,
    symbol: '✂',
    tags_EN: 'scissors, cut, paper',
    tags_ES: 'cortar, tijeras, papel',
    category: 'objetos',
  },
  {
    id: 2223,
    symbol: '🗃',
    tags_EN: 'card, file, box',
    tags_ES: 'archivador, archivo, caja',
    category: 'objetos',
  },
  {
    id: 2224,
    symbol: '🗄',
    tags_EN: 'file, cabinet, box',
    tags_ES: 'archivador, archivo, caja',
    category: 'objetos',
  },
  {
    id: 2225,
    symbol: '🗑',
    tags_EN: 'wastebasket, trash, paper',
    tags_ES: 'papelera, basura, papel',
    category: 'objetos',
  },
  {
    id: 2226,
    symbol: '🔒',
    tags_EN: 'locked, locker, block',
    tags_ES: 'candado, bloquear, bloqueo',
    category: 'objetos',
  },
  {
    id: 2227,
    symbol: '🔓',
    tags_EN: 'unlocked, locker, opening',
    tags_ES: 'candado, desbloquear, bloqueo',
    category: 'objetos',
  },
  {
    id: 2228,
    symbol: '🔏',
    tags_EN: 'locked, pen, locker',
    tags_ES: 'bloquear, candado, bolígrafo',
    category: 'objetos',
  },
  {
    id: 2229,
    symbol: '🔐',
    tags_EN: 'locked, key, locker',
    tags_ES: 'bloquear, candado, llave',
    category: 'objetos',
  },
  {
    id: 2230,
    symbol: '🔑',
    tags_EN: 'key, locker, close',
    tags_ES: 'llave, cerrar, candado',
    category: 'objetos',
  },
  {
    id: 2231,
    symbol: '🗝',
    tags_EN: 'old, key, close',
    tags_ES: 'llave, antigua, cerrar',
    category: 'objetos',
  },
  {
    id: 2232,
    symbol: '🔨',
    tags_EN: 'hammer, build, nail',
    tags_ES: 'martillo, clavar, clavo',
    category: 'objetos',
  },
  {
    id: 2233,
    symbol: '⛏',
    tags_EN: 'pick, dig, hole',
    tags_ES: 'pico, cavar, hoyo',
    category: 'objetos',
  },
  {
    id: 2234,
    symbol: '⚒',
    tags_EN: 'hammer, pick, dig',
    tags_ES: 'pico, martillo, clavar',
    category: 'objetos',
  },
  {
    id: 2235,
    symbol: '🛠',
    tags_EN: 'hammer, wrench, repair',
    tags_ES: 'martillo, llave inglesa, arreglar',
    category: 'objetos',
  },
  {
    id: 2236,
    symbol: '🗡',
    tags_EN: 'dagger, fight, sword',
    tags_ES: 'puñal, clavar, espada',
    category: 'objetos',
  },
  {
    id: 2237,
    symbol: '⚔',
    tags_EN: 'crossed, swords, sword',
    tags_ES: 'espadas, clavar, cruzadas',
    category: 'objetos',
  },
  {
    id: 2238,
    symbol: '🔫',
    tags_EN: 'pistol, green, shoot',
    tags_ES: 'pistola, disparar, verde',
    category: 'objetos',
  },
  {
    id: 2239,
    symbol: '🏹',
    tags_EN: 'box, arrow, fight',
    tags_ES: 'arco, flecha, clavar',
    category: 'objetos',
  },
  {
    id: 2240,
    symbol: '🛡',
    tags_EN: 'shield, protect, fight',
    tags_ES: 'escudo, protegerse, protección',
    category: 'objetos',
  },
  {
    id: 2241,
    symbol: '🔧',
    tags_EN: 'wrench, repair, fix',
    tags_ES: 'llave inglesa, reparar, arreglar',
    category: 'objetos',
  },
  {
    id: 2242,
    symbol: '🔩',
    tags_EN: 'nut, bolt, fix',
    tags_ES: 'tuerca, tornillo, arreglar',
    category: 'objetos',
  },
  {
    id: 2243,
    symbol: '⚙',
    tags_EN: 'gear, mechanic, repair',
    tags_ES: 'engranaje, reparar, ajustes',
    category: 'objetos',
  },
  {
    id: 2244,
    symbol: '🗜',
    tags_EN: 'clamp, carpenter, repair',
    tags_ES: 'abrazadera, carpintero, reparar',
    category: 'objetos',
  },
  {
    id: 2245,
    symbol: '⚖',
    tags_EN: 'balance, scale, measure',
    tags_ES: 'balanza, medir, peso',
    category: 'objetos',
  },
  {
    id: 2246,
    symbol: '🔗',
    tags_EN: 'link, metal, chain',
    tags_ES: 'cadena, unión, unir',
    category: 'objetos',
  },
  {
    id: 2247,
    symbol: '⛓',
    tags_EN: 'chains, metal, chain',
    tags_ES: 'cadena, unión, unir',
    category: 'objetos',
  },
  {
    id: 2250,
    symbol: '⚗',
    tags_EN: 'alembic, experiment, laboratory',
    tags_ES: 'alambique, probeta, laboratorio',
    category: 'objetos',
  },
  {
    id: 2255,
    symbol: '🔬',
    tags_EN: 'microscope, experiment, laboratory',
    tags_ES: 'microscopio, experimentar, laboratorio',
    category: 'objetos',
  },
  {
    id: 2256,
    symbol: '🔭',
    tags_EN: 'telescope, experiment, stars',
    tags_ES: 'telescopio, estrellas, astronomía',
    category: 'objetos',
  },
  {
    id: 2257,
    symbol: '📡',
    tags_EN: 'satellite, antenna, space',
    tags_ES: 'satélite, antena, espacio',
    category: 'objetos',
  },
  {
    id: 2258,
    symbol: '💉',
    tags_EN: 'syringe, blood, analysis',
    tags_ES: 'jeringuilla, aguja, sangre',
    category: 'objetos',
  },
  {
    id: 2259,
    symbol: '💊',
    tags_EN: 'pill, medicine, drug',
    tags_ES: 'pastilla, droga, medicina',
    category: 'objetos',
  },
  {
    id: 2260,
    symbol: '🚪',
    tags_EN: 'door, open, close',
    tags_ES: 'puerta, abrir, cerrar',
    category: 'objetos',
  },
  {
    id: 2261,
    symbol: '🛏',
    tags_EN: 'bed, sleep, rest',
    tags_ES: 'cama, dormir, descansar',
    category: 'objetos',
  },
  {
    id: 2262,
    symbol: '🛋',
    tags_EN: 'couch, lamp, living room',
    tags_ES: 'sofá, lámpara, salón',
    category: 'objetos',
  },
  {
    id: 2263,
    symbol: '🚽',
    tags_EN: 'toilet, wc, bathroom',
    tags_ES: 'váter, roca, baño',
    category: 'objetos',
  },
  {
    id: 2264,
    symbol: '🚿',
    tags_EN: 'shower, water, clean',
    tags_ES: 'ducha, baño, bañera',
    category: 'objetos',
  },
  {
    id: 2265,
    symbol: '🛁',
    tags_EN: 'bathtub, shower, clean',
    tags_ES: 'ducha, baño, bañera',
    category: 'objetos',
  },
  {
    id: 2275,
    symbol: '🛒',
    tags_EN: 'shopping, cart, buy',
    tags_ES: 'carrito, compra, comprar',
    category: 'objetos',
  },
  {
    id: 2276,
    symbol: '🚬',
    tags_EN: 'cigarette, smoke, cancer',
    tags_ES: 'cigarrillo, fumar, humo',
    category: 'objetos',
  },
  {
    id: 2277,
    symbol: '⚰',
    tags_EN: 'coffin, die, dead',
    tags_ES: 'nicho, morir, ataúd',
    category: 'objetos',
  },
  {
    id: 2278,
    symbol: '⚱',
    tags_EN: 'funeral, urn, dead',
    tags_ES: 'urna, funeral, morir',
    category: 'objetos',
  },
  {
    id: 2279,
    symbol: '🗿',
    tags_EN: 'moai, figure, sculpture',
    tags_ES: 'escultura, chile, monolito',
    category: 'objetos',
  },
  {
    id: 2280,
    symbol: '🏧',
    tags_EN: 'atm, cash, money',
    tags_ES: 'cajero, dinero, extraer',
    category: 'objetos',
  },
  {
    id: 2281,
    symbol: '🚮',
    tags_EN: 'litter, bin, sign',
    tags_ES: 'basura, señal, papelera',
    category: 'objetos',
  },
  {
    id: 2282,
    symbol: '🚰',
    tags_EN: 'potable, water, drink',
    tags_ES: 'agua, potable, beber',
    category: 'objetos',
  },
  {
    id: 2283,
    symbol: '♿',
    tags_EN: 'wheelchair, symbol, disabled',
    tags_ES: 'minusválido, símbolo, silla de ruedas',
    category: 'objetos',
  },
  {
    id: 2284,
    symbol: '🚹',
    tags_EN: 'men, room, man',
    tags_ES: 'vestuario, hombres, hombre',
    category: 'objetos',
  },
  {
    id: 2285,
    symbol: '🚺',
    tags_EN: 'women, room, woman',
    tags_ES: 'vestuario, mujeres, mujer',
    category: 'objetos',
  },
  {
    id: 2286,
    symbol: '🚻',
    tags_EN: 'restroom, bathroom, toilet',
    tags_ES: 'lavabo, baño, servicio',
    category: 'objetos',
  },
  {
    id: 2287,
    symbol: '🚼',
    tags_EN: 'baby, symbol, kid',
    tags_ES: 'bebé, señal, símbolo',
    category: 'objetos',
  },
  {
    id: 2288,
    symbol: '🚾',
    tags_EN: 'water, closet, bathroom',
    tags_ES: 'inodoro, wc, baño',
    category: 'objetos',
  },
  {
    id: 2289,
    symbol: '🛂',
    tags_EN: 'passport, control, immigration',
    tags_ES: 'inmigración, control, pasaporte',
    category: 'objetos',
  },
  {
    id: 2290,
    symbol: '🛃',
    tags_EN: 'customs, control, objects',
    tags_ES: 'aduana, control, objetos',
    category: 'objetos',
  },
  {
    id: 2291,
    symbol: '🛄',
    tags_EN: 'baggage, claim, luggage',
    tags_ES: 'equipaje, reclamación, maleta',
    category: 'objetos',
  },
  {
    id: 2292,
    symbol: '🛅',
    tags_EN: 'left, luggage, security',
    tags_ES: 'equipaje, armario, maleta',
    category: 'objetos',
  },
  {
    id: 2293,
    symbol: '⚠',
    tags_EN: 'warning, alert, stop',
    tags_ES: 'aviso, alerta, cuidado',
    category: 'objetos',
  },
  {
    id: 2294,
    symbol: '🚸',
    tags_EN: 'children, crossing, street',
    tags_ES: 'niños, cruzando, zona escolar',
    category: 'objetos',
  },
  {
    id: 2295,
    symbol: '⛔',
    tags_EN: 'no, entry, stop',
    tags_ES: 'prohibido, entrar, stop',
    category: 'objetos',
  },
  {
    id: 2296,
    symbol: '🚫',
    tags_EN: 'prohibited, stop, not allowed',
    tags_ES: 'prohibido, parar, stop',
    category: 'objetos',
  },
  {
    id: 2297,
    symbol: '🚳',
    tags_EN: 'no, bicycles, prohibited',
    tags_ES: 'prohibido, bicicletas, stop',
    category: 'objetos',
  },
  {
    id: 2298,
    symbol: '🚭',
    tags_EN: 'no, smoking, smoke',
    tags_ES: 'prohibido, fumar, no fumar',
    category: 'objetos',
  },
  {
    id: 2299,
    symbol: '🚯',
    tags_EN: 'no, littering, trash',
    tags_ES: 'prohibido, tirar, basura',
    category: 'objetos',
  },
  {
    id: 2300,
    symbol: '🚱',
    tags_EN: 'no, potable, water',
    tags_ES: 'prohibido, beber, agua',
    category: 'objetos',
  },
  {
    id: 2301,
    symbol: '🚷',
    tags_EN: 'no , pedestrians, walk',
    tags_ES: 'prohibido, peatones, caminar',
    category: 'objetos',
  },
  {
    id: 2302,
    symbol: '📵',
    tags_EN: 'no, mobile, phones',
    tags_ES: 'prohibido, hablar, móvil',
    category: 'objetos',
  },
  {
    id: 2303,
    symbol: '🔞',
    tags_EN: 'no, under, eighteen',
    tags_ES: 'prohibido, menores, edad',
    category: 'objetos',
  },
  {
    id: 2304,
    symbol: '☢',
    tags_EN: 'radioactive, dangerous, nuclear',
    tags_ES: 'radioactivo, peligro, radiación',
    category: 'objetos',
  },
  {
    id: 2305,
    symbol: '☣',
    tags_EN: 'biohazard, dangerous, zone',
    tags_ES: 'peligro, biológico, virus',
    category: 'objetos',
  },
  {
    id: 2306,
    symbol: '⬆',
    tags_EN: 'up, arrow, sign',
    tags_ES: 'flecha, arriba, símbolo',
    category: 'objetos',
  },
  {
    id: 2307,
    symbol: '↗',
    tags_EN: 'up, right, arrow',
    tags_ES: 'flecha, arriba, derecha',
    category: 'objetos',
  },
  {
    id: 2308,
    symbol: '➡',
    tags_EN: 'right, arrow, sign',
    tags_ES: 'flecha, derecha, símbolo',
    category: 'objetos',
  },
  {
    id: 2309,
    symbol: '↘',
    tags_EN: 'down, right, arrow',
    tags_ES: 'fecha, abajo, derecha',
    category: 'objetos',
  },
  {
    id: 2310,
    symbol: '⬇',
    tags_EN: 'down, arrow, sign',
    tags_ES: 'flecha, abajo, símbolo',
    category: 'objetos',
  },
  {
    id: 2311,
    symbol: '↙',
    tags_EN: 'down, left, arrow',
    tags_ES: 'flecha, abajo, izquierda',
    category: 'objetos',
  },
  {
    id: 2312,
    symbol: '⬅',
    tags_EN: 'left, arrow, sign',
    tags_ES: 'flecha, izquierda, símbolo',
    category: 'objetos',
  },
  {
    id: 2313,
    symbol: '↖',
    tags_EN: 'up, left, arrow',
    tags_ES: 'flecha, arriba, izquierda',
    category: 'objetos',
  },
  {
    id: 2314,
    symbol: '↕',
    tags_EN: 'up, down, arrow',
    tags_ES: 'flecha, arriba, abajo',
    category: 'objetos',
  },
  {
    id: 2315,
    symbol: '↔',
    tags_EN: 'left, right, arrow',
    tags_ES: 'flecha, izquierda, derecha',
    category: 'objetos',
  },
  {
    id: 2316,
    symbol: '↩',
    tags_EN: 'right, arrow, left',
    tags_ES: 'derecha, flecha, izquierda',
    category: 'objetos',
  },
  {
    id: 2317,
    symbol: '↪',
    tags_EN: 'left, arrow, right',
    tags_ES: 'izquierda, flecha, derecha',
    category: 'objetos',
  },
  {
    id: 2318,
    symbol: '⤴',
    tags_EN: 'right, arrow, up',
    tags_ES: 'derecha, flecha, arriba',
    category: 'objetos',
  },
  {
    id: 2319,
    symbol: '⤵',
    tags_EN: 'right, arrow, down',
    tags_ES: 'derecha, flecha, abajo',
    category: 'objetos',
  },
  {
    id: 2320,
    symbol: '🔃',
    tags_EN: 'clockwise, vertical, arrows',
    tags_ES: 'flechas, sentido, horario',
    category: 'objetos',
  },
  {
    id: 2321,
    symbol: '🔄',
    tags_EN: 'counterclockwise, arrows, button',
    tags_ES: 'flechas, sentido, antihorario',
    category: 'objetos',
  },
  {
    id: 2322,
    symbol: '🔙',
    tags_EN: 'back, arrow, sign',
    tags_ES: 'flecha, volver, atrás',
    category: 'objetos',
  },
  {
    id: 2323,
    symbol: '🔚',
    tags_EN: 'end, arrow, sign',
    tags_ES: 'flecha, fin, final',
    category: 'objetos',
  },
  {
    id: 2324,
    symbol: '🔛',
    tags_EN: 'on, arrow, sign',
    tags_ES: 'flecha, vamos, venga',
    category: 'objetos',
  },
  {
    id: 2325,
    symbol: '🔜',
    tags_EN: 'soon, arrow, sign',
    tags_ES: 'flecha, pronto, breve',
    category: 'objetos',
  },
  {
    id: 2326,
    symbol: '🔝',
    tags_EN: 'top, arrow, sign',
    tags_ES: 'flecha, arriba, top',
    category: 'objetos',
  },
  {
    id: 2327,
    symbol: '🛐',
    tags_EN: 'place, worship, pray',
    tags_ES: 'rezar, rendir, culto',
    category: 'objetos',
  },
  {
    id: 2328,
    symbol: '⚛',
    tags_EN: 'atom, symbol, core',
    tags_ES: 'átomo, símbolo, núcleo',
    category: 'objetos',
  },
  {
    id: 2330,
    symbol: '🕉',
    tags_EN: 'om, mantra, buddhism',
    tags_ES: 'religión, budismo, india',
    category: 'objetos',
  },
  {
    id: 2331,
    symbol: '✡',
    tags_EN: 'star, david, religion',
    tags_ES: 'símbolo, judío, judaísmo',
    category: 'objetos',
  },
  {
    id: 2332,
    symbol: '☸',
    tags_EN: 'wheel, religion, dharma',
    tags_ES: 'rueda, budismo, religión',
    category: 'objetos',
  },
  {
    id: 2333,
    symbol: '☯',
    tags_EN: 'yin, yang, tao',
    tags_ES: 'dual, bien, mal',
    category: 'objetos',
  },
  {
    id: 2334,
    symbol: '✝',
    tags_EN: 'latin, cross, jesus',
    tags_ES: 'religión, cristiana, jesús',
    category: 'objetos',
  },
  {
    id: 2335,
    symbol: '☦',
    tags_EN: 'orthodox, cross, church',
    tags_ES: 'religión, iglesia, ortodoxa',
    category: 'objetos',
  },
  {
    id: 2336,
    symbol: '☪',
    tags_EN: 'star, crescent, islamic',
    tags_ES: 'religión, islámica, símbolo',
    category: 'objetos',
  },
  {
    id: 2337,
    symbol: '☮',
    tags_EN: 'peace, symbol, hippie',
    tags_ES: 'símbolo, paz, amor',
    category: 'objetos',
  },
  {
    id: 2338,
    symbol: '🕎',
    tags_EN: 'menorah, lamp, seven branches',
    tags_ES: 'menorá, lámpara, siete brazos',
    category: 'objetos',
  },
  {
    id: 2339,
    symbol: '🔯',
    tags_EN: 'dotted, six-pointed, star',
    tags_ES: 'estrella, seis puntas, david',
    category: 'objetos',
  },
  {
    id: 2340,
    symbol: '♈',
    tags_EN: 'aries, zodiac, symbol',
    tags_ES: 'zodiaco, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2341,
    symbol: '♉',
    tags_EN: 'taurus, zodiac, symbol',
    tags_ES: 'tauro, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2342,
    symbol: '♊',
    tags_EN: 'gemini, zodiac, symbol',
    tags_ES: 'géminis, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2343,
    symbol: '♋',
    tags_EN: 'cancer, zodiac, symbol',
    tags_ES: 'cáncer, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2344,
    symbol: '♌',
    tags_EN: 'leo, zodiac, symbol',
    tags_ES: 'zodiaco, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2345,
    symbol: '♍',
    tags_EN: 'virgo, zodiac, symbol',
    tags_ES: 'zodiaco, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2346,
    symbol: '♎',
    tags_EN: 'libra, zodiac, symbol',
    tags_ES: 'zodiaco, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2347,
    symbol: '♏',
    tags_EN: 'scorpio, zodiac, symbol',
    tags_ES: 'escorpio, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2348,
    symbol: '♐',
    tags_EN: 'sagittarius, zodiac, symbol',
    tags_ES: 'sagitario, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2349,
    symbol: '♑',
    tags_EN: 'capricorn, zodiac, symbol',
    tags_ES: 'capricornio, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2350,
    symbol: '♒',
    tags_EN: 'aquarius, zodiac, symbol',
    tags_ES: 'acuario, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2351,
    symbol: '♓',
    tags_EN: 'pisces, zodiac, symbol',
    tags_ES: 'piscis, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2352,
    symbol: '⛎',
    tags_EN: 'ophiuchus, zodiac, symbol',
    tags_ES: 'ofiuco, horóscopo, tarot',
    category: 'objetos',
  },
  {
    id: 2353,
    symbol: '🔀',
    tags_EN: 'shuffle, tracks, button',
    tags_ES: 'aleatorio, mezclar, canciones',
    category: 'objetos',
  },
  {
    id: 2354,
    symbol: '🔁',
    tags_EN: 'repeat, button, again',
    tags_ES: 'repetir, canciones, botón',
    category: 'objetos',
  },
  {
    id: 2355,
    symbol: '🔂',
    tags_EN: 'repeat, single, button',
    tags_ES: 'repetir, una, canción',
    category: 'objetos',
  },
  {
    id: 2356,
    symbol: '▶',
    tags_EN: 'play, button, song',
    tags_ES: 'reproducir, canción, botón',
    category: 'objetos',
  },
  {
    id: 2357,
    symbol: '⏩',
    tags_EN: 'fast, forward, button',
    tags_ES: 'avance, rápido, avanzar',
    category: 'objetos',
  },
  {
    id: 2358,
    symbol: '⏭',
    tags_EN: 'next, tracks, button',
    tags_ES: 'siguiente, canción, botón',
    category: 'objetos',
  },
  {
    id: 2359,
    symbol: '⏯',
    tags_EN: 'play, pause, button',
    tags_ES: 'reproducir, pausar, botón',
    category: 'objetos',
  },
  {
    id: 2360,
    symbol: '◀',
    tags_EN: 'reverse, button, back',
    tags_ES: 'volver, atrás, botón',
    category: 'objetos',
  },
  {
    id: 2361,
    symbol: '⏪',
    tags_EN: 'fast, revers, button',
    tags_ES: 'rebobinar, atrás, botón',
    category: 'objetos',
  },
  {
    id: 2362,
    symbol: '⏮',
    tags_EN: 'last, track, button',
    tags_ES: 'última, canción, botón',
    category: 'objetos',
  },
  {
    id: 2363,
    symbol: '🔼',
    tags_EN: 'upwards, button, up',
    tags_ES: 'botón, arriba, subir',
    category: 'objetos',
  },
  {
    id: 2364,
    symbol: '⏫',
    tags_EN: 'fast, up, button',
    tags_ES: 'botón, rápido, arriba',
    category: 'objetos',
  },
  {
    id: 2365,
    symbol: '🔽',
    tags_EN: 'downwards, button, down',
    tags_ES: 'botón, abajo, bajar',
    category: 'objetos',
  },
  {
    id: 2366,
    symbol: '⏬',
    tags_EN: 'fast, down, button',
    tags_ES: 'botón, rápido, abajo',
    category: 'objetos',
  },
  {
    id: 2367,
    symbol: '⏸',
    tags_EN: 'pause, button, stop',
    tags_ES: 'botón, pausar, parar',
    category: 'objetos',
  },
  {
    id: 2368,
    symbol: '⏹',
    tags_EN: 'stop, button, block',
    tags_ES: 'botón, parar, detener',
    category: 'objetos',
  },
  {
    id: 2369,
    symbol: '⏺',
    tags_EN: 'record, button, memoir',
    tags_ES: 'grabar, botón, grabadora',
    category: 'objetos',
  },
  {
    id: 2370,
    symbol: '⏏',
    tags_EN: 'eject, button, expulse',
    tags_ES: 'expulsar, devolver, botón',
    category: 'objetos',
  },
  {
    id: 2371,
    symbol: '🎦',
    tags_EN: 'cinema, button, film',
    tags_ES: 'cine, botón, película',
    category: 'objetos',
  },
  {
    id: 2372,
    symbol: '🔅',
    tags_EN: 'dim, button, bright',
    tags_ES: 'disminuir, brillo, contraste',
    category: 'objetos',
  },
  {
    id: 2373,
    symbol: '🔆',
    tags_EN: 'brihgt, button, bright',
    tags_ES: 'aumentar, brillo, contraste',
    category: 'objetos',
  },
  {
    id: 2374,
    symbol: '📶',
    tags_EN: 'antenna, bars, cellphone',
    tags_ES: 'antena, cobertura, barras',
    category: 'objetos',
  },
  {
    id: 2375,
    symbol: '📳',
    tags_EN: 'vibration, mode, phone',
    tags_ES: 'vibrar, teléfono, móvil',
    category: 'objetos',
  },
  {
    id: 2376,
    symbol: '📴',
    tags_EN: 'mobile, phone, off',
    tags_ES: 'teléfono, móvil, apagar',
    category: 'objetos',
  },
  {
    id: 2377,
    symbol: '♀',
    tags_EN: 'female, sign, woman',
    tags_ES: 'símbolo, mujer, hembra',
    category: 'objetos',
  },
  {
    id: 2378,
    symbol: '♂',
    tags_EN: 'male, sign, man',
    tags_ES: 'símbolo, hombre, macho',
    category: 'objetos',
  },
  {
    id: 2379,
    symbol: '⚕',
    tags_EN: 'medical, symbol, doctor',
    tags_ES: 'símbolo, médico, doctor',
    category: 'objetos',
  },
  {
    id: 2380,
    symbol: '♻',
    tags_EN: 'recycling, symbol, waste',
    tags_ES: 'símbolo, reciclar, basura',
    category: 'objetos',
  },
  {
    id: 2381,
    symbol: '⚜',
    tags_EN: 'fleur, de, lis',
    tags_ES: 'flor, de, lirio',
    category: 'objetos',
  },
  {
    id: 2382,
    symbol: '🔱',
    tags_EN: 'trident, emblem, sea',
    tags_ES: 'tridente, símbolo, emblema',
    category: 'objetos',
  },
  {
    id: 2383,
    symbol: '📛',
    tags_EN: 'name, badge, tag',
    tags_ES: 'etiqueta, nombre, señal',
    category: 'objetos',
  },
  {
    id: 2384,
    symbol: '🔰',
    tags_EN: 'japanese, symbol, beginner',
    tags_ES: 'símbolo, japonés, principiante',
    category: 'objetos',
  },
  {
    id: 2385,
    symbol: '⭕',
    tags_EN: 'heavy, large, circle',
    tags_ES: 'círculo, largo, fuerte',
    category: 'objetos',
  },
  {
    id: 2386,
    symbol: '✅',
    tags_EN: 'white, heavy, mark',
    tags_ES: 'check, tilde, whatsapp',
    category: 'objetos',
  },
  {
    id: 2387,
    symbol: '☑',
    tags_EN: 'ballot, box, check',
    tags_ES: 'tilde, marca, correcto',
    category: 'objetos',
  },
  {
    id: 2388,
    symbol: '✔',
    tags_EN: 'heavy, check, mark',
    tags_ES: 'verificar, tilde, correcto',
    category: 'objetos',
  },
  {
    id: 2389,
    symbol: '✖',
    tags_EN: 'heavy, multiplication, x',
    tags_ES: 'signo, multiplicar, equis',
    category: 'objetos',
  },
  {
    id: 2390,
    symbol: '❌',
    tags_EN: 'cross, mark, x',
    tags_ES: 'marca, signo, equis',
    category: 'objetos',
  },
  {
    id: 2391,
    symbol: '❎',
    tags_EN: 'cross, mark, button',
    tags_ES: 'marca, botón, cruzado',
    category: 'objetos',
  },
  {
    id: 2392,
    symbol: '➕',
    tags_EN: 'heavy, plus, sign',
    tags_ES: 'símbolo, más, sumar',
    category: 'objetos',
  },
  {
    id: 2393,
    symbol: '➖',
    tags_EN: 'heavy, minus, sign',
    tags_ES: 'símbolo, menos, restar',
    category: 'objetos',
  },
  {
    id: 2394,
    symbol: '➗',
    tags_EN: 'heavy, division, sign',
    tags_ES: 'símbolo, división, dividir',
    category: 'objetos',
  },
  {
    id: 2395,
    symbol: '➰',
    tags_EN: 'curly, loop, sign',
    tags_ES: 'curva, signo, bucle',
    category: 'objetos',
  },
  {
    id: 2396,
    symbol: '➿',
    tags_EN: 'double, curly, loop',
    tags_ES: 'curva, doble, signo',
    category: 'objetos',
  },
  {
    id: 2397,
    symbol: '〽',
    tags_EN: 'part, alternation, mark',
    tags_ES: 'marca, inicio, canción',
    category: 'objetos',
  },
  {
    id: 2398,
    symbol: '✳',
    tags_EN: 'eight, spoked, asterisk',
    tags_ES: 'asterisco, ocho, puntas',
    category: 'objetos',
  },
  {
    id: 2399,
    symbol: '✴',
    tags_EN: 'eight, pointed, star',
    tags_ES: 'estrella, ocho, puntas',
    category: 'objetos',
  },
  {
    id: 2400,
    symbol: '❇',
    tags_EN: 'sparkle, sign, symbol',
    tags_ES: 'asterisco, estrella, símbolo',
    category: 'objetos',
  },
  {
    id: 2401,
    symbol: '‼',
    tags_EN: 'double, exclamation, mark',
    tags_ES: 'símbolo, doble, exclamación',
    category: 'objetos',
  },
  {
    id: 2402,
    symbol: '⁉',
    tags_EN: 'exclamation, question, mark',
    tags_ES: 'exclamación, interrogante, símbolo',
    category: 'objetos',
  },
  {
    id: 2403,
    symbol: '❓',
    tags_EN: 'question, mark, ask',
    tags_ES: 'interrogante, pregunta, símbolo',
    category: 'objetos',
  },
  {
    id: 2404,
    symbol: '❔',
    tags_EN: 'white, question, mark',
    tags_ES: 'interrogante, pregunta, símbolo',
    category: 'objetos',
  },
  {
    id: 2405,
    symbol: '❕',
    tags_EN: 'white, exclamation, mark',
    tags_ES: 'exclamación, exclamar, símbolo',
    category: 'objetos',
  },
  {
    id: 2406,
    symbol: '❗',
    tags_EN: 'exclamation, mark, symbol',
    tags_ES: 'exclamación, exclamar, símbolo',
    category: 'objetos',
  },
  {
    id: 2407,
    symbol: '〰',
    tags_EN: 'wavy, dash, symbol',
    tags_ES: 'guión, ola, símbolo',
    category: 'objetos',
  },
  {
    id: 2408,
    symbol: '©',
    tags_EN: 'copyright, symbol, c',
    tags_ES: 'derechos, copia, ley',
    category: 'objetos',
  },
  {
    id: 2409,
    symbol: '®',
    tags_EN: 'registered, symbol, r',
    tags_ES: 'registrado, símbolo, registrar',
    category: 'objetos',
  },
  {
    id: 2410,
    symbol: '™',
    tags_EN: 'trade, mark, tm',
    tags_ES: 'símbolo, marca, registrada',
    category: 'objetos',
  },
  {
    id: 2411,
    symbol: '#️⃣',
    tags_EN: 'keycap, #, symbol',
    tags_ES: 'tecla, almohadilla, símbolo',
    category: 'objetos',
  },
  {
    id: 2412,
    symbol: '*️⃣',
    tags_EN: 'keycap, *, symbol',
    tags_ES: 'tecla, asterisco, símbolo',
    category: 'objetos',
  },
  {
    id: 2413,
    symbol: '0️⃣',
    tags_EN: 'keycap, 0, number',
    tags_ES: 'tecla, cero, número',
    category: 'objetos',
  },
  {
    id: 2414,
    symbol: '1️⃣',
    tags_EN: 'keycap, 1, number',
    tags_ES: 'tecla, uno, número',
    category: 'objetos',
  },
  {
    id: 2415,
    symbol: '2️⃣',
    tags_EN: 'keycap, 2, number',
    tags_ES: 'tecla, dos, número',
    category: 'objetos',
  },
  {
    id: 2416,
    symbol: '3️⃣',
    tags_EN: 'keycap, 3, number',
    tags_ES: 'tecla, tres, número',
    category: 'objetos',
  },
  {
    id: 2417,
    symbol: '4️⃣',
    tags_EN: 'keycap, 4, number',
    tags_ES: 'tecla, cuatro, número',
    category: 'objetos',
  },
  {
    id: 2418,
    symbol: '5️⃣',
    tags_EN: 'keycap, 5, number',
    tags_ES: 'tecla, cinco, número',
    category: 'objetos',
  },
  {
    id: 2419,
    symbol: '6️⃣',
    tags_EN: 'keycap, 6, number',
    tags_ES: 'tecla, seis, número',
    category: 'objetos',
  },
  {
    id: 2420,
    symbol: '7️⃣',
    tags_EN: 'keycap, 7, number',
    tags_ES: 'tecla, siete, número',
    category: 'objetos',
  },
  {
    id: 2421,
    symbol: '8️⃣',
    tags_EN: 'keycap, 8, number',
    tags_ES: 'tecla, ocho, número',
    category: 'objetos',
  },
  {
    id: 2422,
    symbol: '9️⃣',
    tags_EN: 'keycap, 9, number',
    tags_ES: 'tecla, nueve, número',
    category: 'objetos',
  },
  {
    id: 2423,
    symbol: '🔟',
    tags_EN: 'keycap, 10, number',
    tags_ES: 'tecla, diez, número',
    category: 'objetos',
  },
  {
    id: 2424,
    symbol: '💯',
    tags_EN: 'hundred, points, number',
    tags_ES: 'cien, puntos, 100',
    category: 'objetos',
  },
  {
    id: 2425,
    symbol: '🔠',
    tags_EN: 'input, latin, uppercase',
    tags_ES: 'alfabeto, mayúscula, abc',
    category: 'objetos',
  },
  {
    id: 2426,
    symbol: '🔡',
    tags_EN: 'input, latin, lowercase',
    tags_ES: 'alfabeto, minúscula, abc',
    category: 'objetos',
  },
  {
    id: 2427,
    symbol: '🔢',
    tags_EN: 'input, numbers, numeric',
    tags_ES: 'numbers, numérico, 1234',
    category: 'objetos',
  },
  {
    id: 2428,
    symbol: '🔣',
    tags_EN: 'input, symbols, signs',
    tags_ES: 'elementos, símbolos, botón',
    category: 'objetos',
  },
  {
    id: 2429,
    symbol: '🔤',
    tags_EN: 'input, latin, letters',
    tags_ES: 'letras, abc, botón',
    category: 'objetos',
  },
  {
    id: 2430,
    symbol: '🅰',
    tags_EN: 'a, button, letter',
    tags_ES: 'botón, letra, a',
    category: 'objetos',
  },
  {
    id: 2431,
    symbol: '🆎',
    tags_EN: 'ab, button, letters',
    tags_ES: 'botón, letras, ab',
    category: 'objetos',
  },
  {
    id: 2432,
    symbol: '🅱',
    tags_EN: 'b, button, letter',
    tags_ES: 'botón, letra, b',
    category: 'objetos',
  },
  {
    id: 2433,
    symbol: '🆑',
    tags_EN: 'cl, button, letters',
    tags_ES: 'botón, letras, cl',
    category: 'objetos',
  },
  {
    id: 2434,
    symbol: '🆒',
    tags_EN: 'cool, button, letters',
    tags_ES: 'botón, letras, cool',
    category: 'objetos',
  },
  {
    id: 2435,
    symbol: '🆓',
    tags_EN: 'free, button, letters',
    tags_ES: 'botón, letras, free',
    category: 'objetos',
  },
  {
    id: 2436,
    symbol: 'ℹ',
    tags_EN: 'information, button, info',
    tags_ES: 'información, botón, informarse',
    category: 'objetos',
  },
  {
    id: 2437,
    symbol: '🆔',
    tags_EN: 'id, button, purple',
    tags_ES: 'botón, id, identificación',
    category: 'objetos',
  },
  {
    id: 2438,
    symbol: 'Ⓜ',
    tags_EN: 'circled, m, letter',
    tags_ES: 'círculo, letra, eme',
    category: 'objetos',
  },
  {
    id: 2439,
    symbol: '🆕',
    tags_EN: 'new, button, current',
    tags_ES: 'nuevo, botón, new',
    category: 'objetos',
  },
  {
    id: 2440,
    symbol: '🆖',
    tags_EN: 'ng, button, blue',
    tags_ES: 'botón, ng, azul',
    category: 'objetos',
  },
  {
    id: 2441,
    symbol: '🅾',
    tags_EN: 'o, button, letter',
    tags_ES: 'botón, letra, o',
    category: 'objetos',
  },
  {
    id: 2442,
    symbol: '🆗',
    tags_EN: 'ok, button, azul',
    tags_ES: 'botón, ok, letra',
    category: 'objetos',
  },
  {
    id: 2443,
    symbol: '🅿',
    tags_EN: 'p, button, letter',
    tags_ES: 'botón, letra, p',
    category: 'objetos',
  },
  {
    id: 2444,
    symbol: '🆘',
    tags_EN: 'sos, button, red',
    tags_ES: 'socorro, botón, rojo',
    category: 'objetos',
  },
  {
    id: 2445,
    symbol: '🆙',
    tags_EN: 'up, button, blue',
    tags_ES: 'arriba, botón, azul',
    category: 'objetos',
  },
  {
    id: 2446,
    symbol: '🆚',
    tags_EN: 'vs, button, yellow',
    tags_ES: 'versus, botón, amarillo',
    category: 'objetos',
  },
  {
    id: 2447,
    symbol: '🈁',
    tags_EN: 'japanese, here, button',
    tags_ES: 'botón, japonés, aquí',
    category: 'objetos',
  },
  {
    id: 2448,
    symbol: '🈂',
    tags_EN: 'japanese, service charge, button',
    tags_ES: 'japonés, pagar, botón',
    category: 'objetos',
  },
  {
    id: 2449,
    symbol: '🈷',
    tags_EN: 'japanese, monthly amount, button',
    tags_ES: 'japonés, pago mensual, botón',
    category: 'objetos',
  },
  {
    id: 2450,
    symbol: '🈶',
    tags_EN: 'japanese, not free, button',
    tags_ES: 'japonés, no gratis, botón',
    category: 'objetos',
  },
  {
    id: 2451,
    symbol: '🈯',
    tags_EN: 'japanese, reserved, button',
    tags_ES: 'japonés, reservado, botón',
    category: 'objetos',
  },
  {
    id: 2452,
    symbol: '🉐',
    tags_EN: 'japanese, bargain, button',
    tags_ES: 'japonés, baratija, botón',
    category: 'objetos',
  },
  {
    id: 2453,
    symbol: '🈹',
    tags_EN: 'japanese, discount, button',
    tags_ES: 'japonés, descuento, botón',
    category: 'objetos',
  },
  {
    id: 2454,
    symbol: '🈚',
    tags_EN: 'japanese, free charge, button',
    tags_ES: 'japonés, gratis, botón',
    category: 'objetos',
  },
  {
    id: 2455,
    symbol: '🈲',
    tags_EN: 'japanese, prohibited, button',
    tags_ES: 'japonés, prohibido, botón',
    category: 'objetos',
  },
  {
    id: 2456,
    symbol: '🉑',
    tags_EN: 'japanese, acceptable, button',
    tags_ES: 'japonés, aceptable, botón',
    category: 'objetos',
  },
  {
    id: 2457,
    symbol: '🈸',
    tags_EN: 'japanese, application, button',
    tags_ES: 'japonés, aplicación, botón',
    category: 'objetos',
  },
  {
    id: 2458,
    symbol: '🈴',
    tags_EN: 'japanese, passing grade, button',
    tags_ES: 'japonés, aprobar, botón',
    category: 'objetos',
  },
  {
    id: 2459,
    symbol: '🈳',
    tags_EN: 'japanese, vacancy, button',
    tags_ES: 'japonés, vacante, botón',
    category: 'objetos',
  },
  {
    id: 2460,
    symbol: '㊗',
    tags_EN: 'japanese, congratulations, button',
    tags_ES: 'japonés, felicidades, botón',
    category: 'objetos',
  },
  {
    id: 2461,
    symbol: '㊙',
    tags_EN: 'japanese, secret, button',
    tags_ES: 'japonés, secreto, botón',
    category: 'objetos',
  },
  {
    id: 2462,
    symbol: '🈺',
    tags_EN: 'japanese, open for business, button',
    tags_ES: 'japonés, negocio abierto, botón',
    category: 'objetos',
  },
  {
    id: 2463,
    symbol: '🈵',
    tags_EN: 'japanese, no vacancy, button',
    tags_ES: 'japonés, no vacante, botón',
    category: 'objetos',
  },
  {
    id: 2464,
    symbol: '▪',
    tags_EN: 'black, small, square',
    tags_ES: 'cuadrado, negro, pequeño',
    category: 'objetos',
  },
  {
    id: 2465,
    symbol: '▫',
    tags_EN: 'white, small, square',
    tags_ES: 'cuadrado, blanco, pequeño',
    category: 'objetos',
  },
  {
    id: 2466,
    symbol: '◻',
    tags_EN: 'white, medium, square',
    tags_ES: 'cuadrado, blanco, mediano',
    category: 'objetos',
  },
  {
    id: 2467,
    symbol: '◼',
    tags_EN: 'black, medium, square',
    tags_ES: 'cuadrado, negro, mediano',
    category: 'objetos',
  },
  {
    id: 2468,
    symbol: '◽',
    tags_EN: 'white, medium-small, square',
    tags_ES: 'cuadrado, blanco, mediano',
    category: 'objetos',
  },
  {
    id: 2469,
    symbol: '◾',
    tags_EN: 'black, medium-small, square',
    tags_ES: 'cuadrado, negro, mediano',
    category: 'objetos',
  },
  {
    id: 2470,
    symbol: '⬛',
    tags_EN: 'black, large, square',
    tags_ES: 'cuadrado, negro, grande',
    category: 'objetos',
  },
  {
    id: 2471,
    symbol: '⬜',
    tags_EN: 'white, large, square',
    tags_ES: 'cuadrado, blanco, grande',
    category: 'objetos',
  },
  {
    id: 2472,
    symbol: '🔶',
    tags_EN: 'large, orange, diamond',
    tags_ES: 'diamante, grande, naranja',
    category: 'objetos',
  },
  {
    id: 2473,
    symbol: '🔷',
    tags_EN: 'large, blue, diamond',
    tags_ES: 'diamante, grande, azul',
    category: 'objetos',
  },
  {
    id: 2474,
    symbol: '🔸',
    tags_EN: 'small, orange, diamond',
    tags_ES: 'diamante, pequeño, naranja',
    category: 'objetos',
  },
  {
    id: 2475,
    symbol: '🔹',
    tags_EN: 'small, blue, diamond',
    tags_ES: 'diamante, pequeño, azul',
    category: 'objetos',
  },
  {
    id: 2476,
    symbol: '🔺',
    tags_EN: 'red, triangle, up',
    tags_ES: 'triángulo, rojo, arriba',
    category: 'objetos',
  },
  {
    id: 2477,
    symbol: '🔻',
    tags_EN: 'red, triangle, down',
    tags_ES: 'triángulo, rojo, abajo',
    category: 'objetos',
  },
  {
    id: 2478,
    symbol: '💠',
    tags_EN: 'diamond, dot, shiny',
    tags_ES: 'diamante, brillante, brillo',
    category: 'objetos',
  },
  {
    id: 2479,
    symbol: '🔘',
    tags_EN: 'radio, button, gray',
    tags_ES: 'botón, radio, gris',
    category: 'objetos',
  },
  {
    id: 2480,
    symbol: '🔲',
    tags_EN: 'black, square, button',
    tags_ES: 'cuadrado, negro, botón',
    category: 'objetos',
  },
  {
    id: 2481,
    symbol: '🔳',
    tags_EN: 'white, square, button',
    tags_ES: 'cuadrado, blanco, botón',
    category: 'objetos',
  },
  {
    id: 2482,
    symbol: '⚪',
    tags_EN: 'white, circle, round',
    tags_ES: 'blanco, círculo, redondo',
    category: 'objetos',
  },
  {
    id: 2483,
    symbol: '⚫',
    tags_EN: 'black, circle, round',
    tags_ES: 'negro, círculo, redondo',
    category: 'objetos',
  },
  {
    id: 2484,
    symbol: '🔴',
    tags_EN: 'red, circle, round',
    tags_ES: 'rojo, círculo, redondo',
    category: 'objetos',
  },
  {
    id: 2485,
    symbol: '🔵',
    tags_EN: 'blue, circle, round',
    tags_ES: 'azul, círculo, redondo',
    category: 'objetos',
  },
  {
    id: 2486,
    symbol: '🏁',
    tags_EN: 'chequered, flag, f1',
    tags_ES: 'bandera, cuadros, fórmula 1',
    category: 'objetos',
  },
  {
    id: 2487,
    symbol: '🚩',
    tags_EN: 'triangular, flag, red',
    tags_ES: 'bandera, triangular, roja',
    category: 'objetos',
  },
  {
    id: 2488,
    symbol: '🎌',
    tags_EN: 'crossed, flags, japan',
    tags_ES: 'banderas, cruzadas, japón',
    category: 'objetos',
  },
  {
    id: 2489,
    symbol: '🏴',
    tags_EN: 'black, flag, object',
    tags_ES: 'bandera, negra, objeto',
    category: 'objetos',
  },
  {
    id: 2490,
    symbol: '🏳',
    tags_EN: 'white, flag, object',
    tags_ES: 'bandera, blanca, objeto',
    category: 'objetos',
  },
  {
    id: 2491,
    symbol: '🏳️‍🌈',
    tags_EN: 'rainbow, flag, gay',
    tags_ES: 'bandera, orgullo, homosexual',
    category: 'objetos',
  },
  {
    id: 2493,
    symbol: '🇦🇨',
    tags_EN: 'ascension island, flag, country',
    tags_ES: 'isla ascensión, bandera, país',
    category: 'países',
  },
  {
    id: 2494,
    symbol: '🇦🇩',
    tags_EN: 'andorra, flag, country',
    tags_ES: 'andorra, bandera, país',
    category: 'países',
  },
  {
    id: 2495,
    symbol: '🇦🇪',
    tags_EN: 'united arab emirates, flag, country',
    tags_ES: 'emiratos árabes, bandera, país',
    category: 'países',
  },
  {
    id: 2496,
    symbol: '🇦🇫',
    tags_EN: 'afghanistan, flag, country',
    tags_ES: 'afganistán, bandera, país',
    category: 'países',
  },
  {
    id: 2497,
    symbol: '🇦🇬',
    tags_EN: 'antigua & barbuda, flag, country',
    tags_ES: 'antigua y barbuda, bandera, país',
    category: 'países',
  },
  {
    id: 2498,
    symbol: '🇦🇮',
    tags_EN: 'anguilla, flag, country',
    tags_ES: 'anguila, bandera, país',
    category: 'países',
  },
  {
    id: 2499,
    symbol: '🇦🇱',
    tags_EN: 'albania, flag, country',
    tags_ES: 'albania, bandera, país',
    category: 'países',
  },
  {
    id: 2500,
    symbol: '🇦🇲',
    tags_EN: 'armenia, flag, country',
    tags_ES: 'armenia, bandera, país',
    category: 'países',
  },
  {
    id: 2501,
    symbol: '🇦🇴',
    tags_EN: 'angola, flag, country',
    tags_ES: 'angola, bandera, país',
    category: 'países',
  },
  {
    id: 2502,
    symbol: '🇦🇶',
    tags_EN: 'antarctica, flag, country',
    tags_ES: 'antártica, bandera, país',
    category: 'países',
  },
  {
    id: 2503,
    symbol: '🇦🇷',
    tags_EN: 'argentina, flag, country',
    tags_ES: 'argentina, bandera, país',
    category: 'países',
  },
  {
    id: 2504,
    symbol: '🇦🇸',
    tags_EN: 'american samoa, flag, country',
    tags_ES: 'samoa americana, bandera, país',
    category: 'países',
  },
  {
    id: 2505,
    symbol: '🇦🇹',
    tags_EN: 'austria, flag, country',
    tags_ES: 'austria, bandera, país',
    category: 'países',
  },
  {
    id: 2506,
    symbol: '🇦🇺',
    tags_EN: 'australia, flag, country',
    tags_ES: 'australia, bandera, país',
    category: 'países',
  },
  {
    id: 2507,
    symbol: '🇦🇼',
    tags_EN: 'aruba, flag, country',
    tags_ES: 'aruba, bandera, país',
    category: 'países',
  },
  {
    id: 2508,
    symbol: '🇦🇽',
    tags_EN: 'aland islands, flag, country',
    tags_ES: 'islas aland, bandera, país',
    category: 'países',
  },
  {
    id: 2509,
    symbol: '🇦🇿',
    tags_EN: 'azerbaijan, flag, country',
    tags_ES: 'azerbaiyán, bandera, país',
    category: 'países',
  },
  {
    id: 2510,
    symbol: '🇧🇦',
    tags_EN: 'bosnia & herzegovina, flag, country',
    tags_ES: 'bosnia y herzegovina, bandera, país',
    category: 'países',
  },
  {
    id: 2511,
    symbol: '🇧🇧',
    tags_EN: 'barbados, flag, country',
    tags_ES: 'barbados, bandera, país',
    category: 'países',
  },
  {
    id: 2512,
    symbol: '🇧🇩',
    tags_EN: 'bangladesh, flag, country',
    tags_ES: 'bangladesh, bandera, país',
    category: 'países',
  },
  {
    id: 2513,
    symbol: '🇧🇪',
    tags_EN: 'belgium, flag, country',
    tags_ES: 'bélgica, bandera, país',
    category: 'países',
  },
  {
    id: 2514,
    symbol: '🇧🇫',
    tags_EN: 'burkina faso, flag, country',
    tags_ES: 'burkina faso, bandera, país',
    category: 'países',
  },
  {
    id: 2515,
    symbol: '🇧🇬',
    tags_EN: 'bulgaria, flag, country',
    tags_ES: 'bulgaria, bandera, país',
    category: 'países',
  },
  {
    id: 2516,
    symbol: '🇧🇭',
    tags_EN: 'bahrain, flag, country',
    tags_ES: 'bahrain, bandera, país',
    category: 'países',
  },
  {
    id: 2517,
    symbol: '🇧🇮',
    tags_EN: 'burundi, flag, country',
    tags_ES: 'burundi, bandera, país',
    category: 'países',
  },
  {
    id: 2518,
    symbol: '🇧🇯',
    tags_EN: 'benin, flag, country',
    tags_ES: 'benin, bandera, país',
    category: 'países',
  },
  {
    id: 2519,
    symbol: '🇧🇱',
    tags_EN: 'st. barthélemy, flag, country',
    tags_ES: 'st. barthélemy, bandera, país',
    category: 'países',
  },
  {
    id: 2520,
    symbol: '🇧🇲',
    tags_EN: 'bermuda, flag, country',
    tags_ES: 'bermuda, bandera, país',
    category: 'países',
  },
  {
    id: 2521,
    symbol: '🇧🇳',
    tags_EN: 'brunei, flag, country',
    tags_ES: 'brunei, bandera, país',
    category: 'países',
  },
  {
    id: 2522,
    symbol: '🇧🇴',
    tags_EN: 'bolivia, flag, country',
    tags_ES: 'bolivia, bandera, país',
    category: 'países',
  },
  {
    id: 2523,
    symbol: '🇧🇶',
    tags_EN: 'caribbean netherlands, flag, country',
    tags_ES: 'islas del caribe, bandera, país',
    category: 'países',
  },
  {
    id: 2524,
    symbol: '🇧🇷',
    tags_EN: 'brazil, flag, country',
    tags_ES: 'brasil, bandera, país',
    category: 'países',
  },
  {
    id: 2525,
    symbol: '🇧🇸',
    tags_EN: 'bahamas, flag, country',
    tags_ES: 'bahamas, bandera, país',
    category: 'países',
  },
  {
    id: 2526,
    symbol: '🇧🇹',
    tags_EN: 'bhutan, flag, country',
    tags_ES: 'bután, bandera, país',
    category: 'países',
  },
  {
    id: 2527,
    symbol: '🇧🇻',
    tags_EN: 'bouvet island, flag, country',
    tags_ES: 'isla bouvet, bandera, país',
    category: 'países',
  },
  {
    id: 2528,
    symbol: '🇧🇼',
    tags_EN: 'botswana, flag, country',
    tags_ES: 'botswana, bandera, país',
    category: 'países',
  },
  {
    id: 2529,
    symbol: '🇧🇾',
    tags_EN: 'belarus, flag, country',
    tags_ES: 'bielorrusia, bandera, país',
    category: 'países',
  },
  {
    id: 2530,
    symbol: '🇧🇿',
    tags_EN: 'belize, flag, country',
    tags_ES: 'belice, bandera, país',
    category: 'países',
  },
  {
    id: 2531,
    symbol: '🇨🇦',
    tags_EN: 'canada, flag, country',
    tags_ES: 'canadá, bandera, país',
    category: 'países',
  },
  {
    id: 2532,
    symbol: '🇨🇨',
    tags_EN: 'cocos, flag, country',
    tags_ES: 'islas cocos, bandera, país',
    category: 'países',
  },
  {
    id: 2533,
    symbol: '🇨🇩',
    tags_EN: 'congo, flag, country',
    tags_ES: 'congo, bandera, país',
    category: 'países',
  },
  {
    id: 2534,
    symbol: '🇨🇫',
    tags_EN: 'central african republic, flag, country',
    tags_ES: 'república central africana, bandera, país',
    category: 'países',
  },
  {
    id: 2535,
    symbol: '🇨🇬',
    tags_EN: 'congo, flag, country',
    tags_ES: 'congo, bandera, país',
    category: 'países',
  },
  {
    id: 2536,
    symbol: '🇨🇭',
    tags_EN: 'switzerland, flag, country',
    tags_ES: 'suiza, bandera, país',
    category: 'países',
  },
  {
    id: 2537,
    symbol: '🇨🇮',
    tags_EN: 'cote ivoire, flag, country',
    tags_ES: 'costa de marfil, bandera, país',
    category: 'países',
  },
  {
    id: 2538,
    symbol: '🇨🇰',
    tags_EN: 'cook islands, flag, country',
    tags_ES: 'islas cook, bandera, país',
    category: 'países',
  },
  {
    id: 2539,
    symbol: '🇨🇱',
    tags_EN: 'chile, flag, country',
    tags_ES: 'chile, bandera, país',
    category: 'países',
  },
  {
    id: 2540,
    symbol: '🇨🇲',
    tags_EN: 'cameroon, flag, country',
    tags_ES: 'camerún, bandera, país',
    category: 'países',
  },
  {
    id: 2541,
    symbol: '🇨🇳',
    tags_EN: 'china, flag, country',
    tags_ES: 'china, bandera, país',
    category: 'países',
  },
  {
    id: 2542,
    symbol: '🇨🇴',
    tags_EN: 'colombia, flag, country',
    tags_ES: 'colombia, bandera, país',
    category: 'países',
  },
  {
    id: 2543,
    symbol: '🇨🇵',
    tags_EN: 'clipperton island, flag, country',
    tags_ES: 'isla clipperton, bandera, país',
    category: 'países',
  },
  {
    id: 2544,
    symbol: '🇨🇷',
    tags_EN: 'costa rica, flag, country',
    tags_ES: 'costa rica, bandera, país',
    category: 'países',
  },
  {
    id: 2545,
    symbol: '🇨🇺',
    tags_EN: 'cuba, flag, country',
    tags_ES: 'cuba, bandera, país',
    category: 'países',
  },
  {
    id: 2546,
    symbol: '🇨🇻',
    tags_EN: 'cape verde, flag, country',
    tags_ES: 'cabo verde, bandera, país',
    category: 'países',
  },
  {
    id: 2547,
    symbol: '🇨🇼',
    tags_EN: 'curaçao, flag, country',
    tags_ES: 'curazao, bandera, país',
    category: 'países',
  },
  {
    id: 2548,
    symbol: '🇨🇽',
    tags_EN: 'christmas island, flag, country',
    tags_ES: 'isla de navidad, bandera, país',
    category: 'países',
  },
  {
    id: 2549,
    symbol: '🇨🇾',
    tags_EN: 'cyprus, flag, country',
    tags_ES: 'chipre, bandera, país',
    category: 'países',
  },
  {
    id: 2550,
    symbol: '🇨🇿',
    tags_EN: 'czechia, flag, country',
    tags_ES: 'república checa, bandera, país',
    category: 'países',
  },
  {
    id: 2551,
    symbol: '🇩🇪',
    tags_EN: 'germany, flag, country',
    tags_ES: 'alemania, bandera, país',
    category: 'países',
  },
  {
    id: 2552,
    symbol: '🇩🇬',
    tags_EN: 'diego garcia, flag, country',
    tags_ES: 'diego garcia, bandera, país',
    category: 'países',
  },
  {
    id: 2553,
    symbol: '🇩🇯',
    tags_EN: 'djibouti, flag, country',
    tags_ES: 'yibuti, bandera, país',
    category: 'países',
  },
  {
    id: 2554,
    symbol: '🇩🇰',
    tags_EN: 'denmark, flag, country',
    tags_ES: 'dinamarca, bandera, país',
    category: 'países',
  },
  {
    id: 2555,
    symbol: '🇩🇲',
    tags_EN: 'dominica, flag, country',
    tags_ES: 'dominica, bandera, país',
    category: 'países',
  },
  {
    id: 2556,
    symbol: '🇩🇴',
    tags_EN: 'dominican republic, flag, country',
    tags_ES: 'república dominicana, bandera, país',
    category: 'países',
  },
  {
    id: 2557,
    symbol: '🇩🇿',
    tags_EN: 'algeria, flag, country',
    tags_ES: 'algeria, bandera, país',
    category: 'países',
  },
  {
    id: 2558,
    symbol: '🇪🇦',
    tags_EN: 'ceuta & melilla, flag, country',
    tags_ES: 'ceuta y melilla, bandera, país',
    category: 'países',
  },
  {
    id: 2559,
    symbol: '🇪🇨',
    tags_EN: 'ecuador, flag, country',
    tags_ES: 'ecuador, bandera, país',
    category: 'países',
  },
  {
    id: 2560,
    symbol: '🇪🇪',
    tags_EN: 'estonia, flag, country',
    tags_ES: 'estonia, bandera, país',
    category: 'países',
  },
  {
    id: 2561,
    symbol: '🇪🇬',
    tags_EN: 'egypt, flag, country',
    tags_ES: 'egipto, bandera, país',
    category: 'países',
  },
  {
    id: 2562,
    symbol: '🇪🇭',
    tags_EN: 'western sahara, flag, country',
    tags_ES: 'sahara occidental, bandera, país',
    category: 'países',
  },
  {
    id: 2563,
    symbol: '🇪🇷',
    tags_EN: 'eritrea, flag, country',
    tags_ES: 'eritrea, bandera, país',
    category: 'países',
  },
  {
    id: 2564,
    symbol: '🇪🇸',
    tags_EN: 'spain, flag, country',
    tags_ES: 'españa, bandera, país',
    category: 'países',
  },
  {
    id: 2565,
    symbol: '🇪🇹',
    tags_EN: 'ethiopia, flag, country',
    tags_ES: 'etiopía, bandera, país',
    category: 'países',
  },
  {
    id: 2566,
    symbol: '🇪🇺',
    tags_EN: 'european union, flag, country',
    tags_ES: 'unión europea, bandera, país',
    category: 'países',
  },
  {
    id: 2567,
    symbol: '🇫🇮',
    tags_EN: 'finland, flag, country',
    tags_ES: 'finlandia, bandera, país',
    category: 'países',
  },
  {
    id: 2568,
    symbol: '🇫🇯',
    tags_EN: 'fiji, flag, country',
    tags_ES: 'fiji, bandera, país',
    category: 'países',
  },
  {
    id: 2569,
    symbol: '🇫🇰',
    tags_EN: 'falkand islands, flag, country',
    tags_ES: 'islas malvinas, bandera, país',
    category: 'países',
  },
  {
    id: 2570,
    symbol: '🇫🇲',
    tags_EN: 'micronesia, flag, country',
    tags_ES: 'micronesia, bandera, país',
    category: 'países',
  },
  {
    id: 2571,
    symbol: '🇫🇴',
    tags_EN: 'faroe islands, flag, country',
    tags_ES: 'islas feroe, bandera, país',
    category: 'países',
  },
  {
    id: 2572,
    symbol: '🇫🇷',
    tags_EN: 'france, flag, country',
    tags_ES: 'francia, bandera, país',
    category: 'países',
  },
  {
    id: 2573,
    symbol: '🇬🇦',
    tags_EN: 'gabon, flag, country',
    tags_ES: 'gabón, bandera, país',
    category: 'países',
  },
  {
    id: 2574,
    symbol: '🇬🇧',
    tags_EN: 'united kingdom, flag, country',
    tags_ES: 'reino unido, bandera, país',
    category: 'países',
  },
  {
    id: 2575,
    symbol: '🇬🇩',
    tags_EN: 'grenada, flag, country',
    tags_ES: 'granada, bandera, país',
    category: 'países',
  },
  {
    id: 2576,
    symbol: '🇬🇪',
    tags_EN: 'georgia, flag, country',
    tags_ES: 'georgia, bandera, país',
    category: 'países',
  },
  {
    id: 2577,
    symbol: '🇬🇫',
    tags_EN: 'french guiana, flag, country',
    tags_ES: 'guyana francesa, bandera, país',
    category: 'países',
  },
  {
    id: 2578,
    symbol: '🇬🇬',
    tags_EN: 'guernsey, flag, country',
    tags_ES: 'guernsey, bandera, país',
    category: 'países',
  },
  {
    id: 2579,
    symbol: '🇬🇭',
    tags_EN: 'ghana, flag, country',
    tags_ES: 'ghana, bandera, país',
    category: 'países',
  },
  {
    id: 2580,
    symbol: '🇬🇮',
    tags_EN: 'girbaltar, flag, country',
    tags_ES: 'gibraltar, bandera, país',
    category: 'países',
  },
  {
    id: 2581,
    symbol: '🇬🇱',
    tags_EN: 'greenland, flag, country',
    tags_ES: 'groenlandia, bandera, país',
    category: 'países',
  },
  {
    id: 2582,
    symbol: '🇬🇲',
    tags_EN: 'gambia, flag, country',
    tags_ES: 'gambia, bandera, país',
    category: 'países',
  },
  {
    id: 2583,
    symbol: '🇬🇳',
    tags_EN: 'guinea, flag, country',
    tags_ES: 'guinea, bandera, país',
    category: 'países',
  },
  {
    id: 2584,
    symbol: '🇬🇵',
    tags_EN: 'guadeloupe, flag, country',
    tags_ES: 'guadalupe, bandera, país',
    category: 'países',
  },
  {
    id: 2585,
    symbol: '🇬🇶',
    tags_EN: 'equatorial guinea, flag, country',
    tags_ES: 'guinea ecuatorial, bandera, país',
    category: 'países',
  },
  {
    id: 2586,
    symbol: '🇬🇷',
    tags_EN: 'greece, flag, country',
    tags_ES: 'grecia, bandera, país',
    category: 'países',
  },
  {
    id: 2587,
    symbol: '🇬🇸',
    tags_EN: 'south georgia & sout sandwich islands, flag, country',
    tags_ES: 'islas georgias del sur y sandwich del sur, bandera, país',
    category: 'países',
  },
  {
    id: 2588,
    symbol: '🇬🇹',
    tags_EN: 'guatemala, flag, country',
    tags_ES: 'guatemala, bandera, país',
    category: 'países',
  },
  {
    id: 2589,
    symbol: '🇬🇺',
    tags_EN: 'guam, flag, country',
    tags_ES: 'guam, bandera, país',
    category: 'países',
  },
  {
    id: 2590,
    symbol: '🇬🇼',
    tags_EN: 'guinea-bissau, flag, country',
    tags_ES: 'guinea-bissau, bandera, país',
    category: 'países',
  },
  {
    id: 2591,
    symbol: '🇬🇾',
    tags_EN: 'guyana, flag, country',
    tags_ES: 'guyana, bandera, país',
    category: 'países',
  },
  {
    id: 2592,
    symbol: '🇭🇰',
    tags_EN: 'hong kong, flag, country',
    tags_ES: 'hong kong, bandera, país',
    category: 'países',
  },
  {
    id: 2593,
    symbol: '🇭🇲',
    tags_EN: 'heard & mcdonald islands, flag, country',
    tags_ES: 'islas heard y mcdonald, bandera, país',
    category: 'países',
  },
  {
    id: 2594,
    symbol: '🇭🇳',
    tags_EN: 'honduras, flag, country',
    tags_ES: 'honduras, bandera, país',
    category: 'países',
  },
  {
    id: 2595,
    symbol: '🇭🇷',
    tags_EN: 'croatia, flag, country',
    tags_ES: 'croacia, bandera, país',
    category: 'países',
  },
  {
    id: 2596,
    symbol: '🇭🇹',
    tags_EN: 'haiti, flag, country',
    tags_ES: 'haiti, bandera, país',
    category: 'países',
  },
  {
    id: 2597,
    symbol: '🇭🇺',
    tags_EN: 'hungary, flag, country',
    tags_ES: 'hungría, bandera, país',
    category: 'países',
  },
  {
    id: 2598,
    symbol: '🇮🇨',
    tags_EN: 'canary islands, flag, country',
    tags_ES: 'islas canarias, bandera, país',
    category: 'países',
  },
  {
    id: 2599,
    symbol: '🇮🇩',
    tags_EN: 'indonesia, flag, country',
    tags_ES: 'indonesia, bandera, país',
    category: 'países',
  },
  {
    id: 2600,
    symbol: '🇮🇪',
    tags_EN: 'ireland, flag, country',
    tags_ES: 'irlanda, bandera, país',
    category: 'países',
  },
  {
    id: 2601,
    symbol: '🇮🇱',
    tags_EN: 'israel, flag, country',
    tags_ES: 'israel, bandera, país',
    category: 'países',
  },
  {
    id: 2602,
    symbol: '🇮🇲',
    tags_EN: 'isle of man, flag, country',
    tags_ES: 'isla de man, bandera, país',
    category: 'países',
  },
  {
    id: 2603,
    symbol: '🇮🇳',
    tags_EN: 'india, flag, country',
    tags_ES: 'india, bandera, país',
    category: 'países',
  },
  {
    id: 2604,
    symbol: '🇮🇴',
    tags_EN: 'british indian ocean territory, flag, country',
    tags_ES: 'territorio británico del océano índico, bandera, país',
    category: 'países',
  },
  {
    id: 2605,
    symbol: '🇮🇶',
    tags_EN: 'iraq, flag, country',
    tags_ES: 'iraq, bandera, país',
    category: 'países',
  },
  {
    id: 2606,
    symbol: '🇮🇷',
    tags_EN: 'iran, flag, country',
    tags_ES: 'irán, bandera, país',
    category: 'países',
  },
  {
    id: 2607,
    symbol: '🇮🇸',
    tags_EN: 'iceland, flag, country',
    tags_ES: 'islandia, bandera, país',
    category: 'países',
  },
  {
    id: 2608,
    symbol: '🇮🇹',
    tags_EN: 'italy, flag, country',
    tags_ES: 'italia, bandera, país',
    category: 'países',
  },
  {
    id: 2609,
    symbol: '🇯🇪',
    tags_EN: 'jersey, flag, country',
    tags_ES: 'jersey, bandera, país',
    category: 'países',
  },
  {
    id: 2610,
    symbol: '🇯🇲',
    tags_EN: 'jamaica, flag, country',
    tags_ES: 'jamaica, bandera, país',
    category: 'países',
  },
  {
    id: 2611,
    symbol: '🇯🇴',
    tags_EN: 'jordan, flag, country',
    tags_ES: 'jordania, bandera, país',
    category: 'países',
  },
  {
    id: 2612,
    symbol: '🇯🇵',
    tags_EN: 'japan, flag, country',
    tags_ES: 'japón, bandera, país',
    category: 'países',
  },
  {
    id: 2613,
    symbol: '🇰🇪',
    tags_EN: 'kenya, flag, country',
    tags_ES: 'kenya, bandera, país',
    category: 'países',
  },
  {
    id: 2614,
    symbol: '🇰🇬',
    tags_EN: 'kyrgyzstan, flag, country',
    tags_ES: 'kyrgyzstan, bandera, país',
    category: 'países',
  },
  {
    id: 2615,
    symbol: '🇰🇭',
    tags_EN: 'cambodia, flag, country',
    tags_ES: 'camboya, bandera, país',
    category: 'países',
  },
  {
    id: 2616,
    symbol: '🇰🇮',
    tags_EN: 'kiribati, flag, country',
    tags_ES: 'kiribati, bandera, país',
    category: 'países',
  },
  {
    id: 2617,
    symbol: '🇰🇲',
    tags_EN: 'comoros, flag, country',
    tags_ES: 'comoras, bandera, país',
    category: 'países',
  },
  {
    id: 2618,
    symbol: '🇰🇳',
    tags_EN: 'st kitts & nevis, flag, country',
    tags_ES: 'st. kitts & nevis, bandera, país',
    category: 'países',
  },
  {
    id: 2619,
    symbol: '🇰🇵',
    tags_EN: 'north korea, flag, country',
    tags_ES: 'corea del norte, bandera, país',
    category: 'países',
  },
  {
    id: 2620,
    symbol: '🇰🇷',
    tags_EN: 'south korea, flag, country',
    tags_ES: 'corea del sur, bandera, país',
    category: 'países',
  },
  {
    id: 2621,
    symbol: '🇰🇼',
    tags_EN: 'kuwait, flag, country',
    tags_ES: 'kuwait, bandera, país',
    category: 'países',
  },
  {
    id: 2622,
    symbol: '🇰🇾',
    tags_EN: 'cayman islands, flag, country',
    tags_ES: 'islas caimán, bandera, país',
    category: 'países',
  },
  {
    id: 2623,
    symbol: '🇰🇿',
    tags_EN: 'kazakhstan, flag, country',
    tags_ES: 'kazakhstan, bandera, país',
    category: 'países',
  },
  {
    id: 2624,
    symbol: '🇱🇦',
    tags_EN: 'laos, flag, country',
    tags_ES: 'laos, bandera, país',
    category: 'países',
  },
  {
    id: 2625,
    symbol: '🇱🇧',
    tags_EN: 'lebanon, flag, country',
    tags_ES: 'líbano, bandera, país',
    category: 'países',
  },
  {
    id: 2626,
    symbol: '🇱🇨',
    tags_EN: 'st. lucia, flag, country',
    tags_ES: 'santa lucía, bandera, país',
    category: 'países',
  },
  {
    id: 2627,
    symbol: '🇱🇮',
    tags_EN: 'liechtenstein, flag, country',
    tags_ES: 'liechtenstein, bandera, país',
    category: 'países',
  },
  {
    id: 2628,
    symbol: '🇱🇰',
    tags_EN: 'sri lanka, flag, country',
    tags_ES: 'sri lanka, bandera, país',
    category: 'países',
  },
  {
    id: 2629,
    symbol: '🇱🇷',
    tags_EN: 'liberia, flag, country',
    tags_ES: 'liberia, bandera, país',
    category: 'países',
  },
  {
    id: 2630,
    symbol: '🇱🇸',
    tags_EN: 'lesotho, flag, country',
    tags_ES: 'lesoto, bandera, país',
    category: 'países',
  },
  {
    id: 2631,
    symbol: '🇱🇹',
    tags_EN: 'lithuania, flag, country',
    tags_ES: 'lituania, bandera, país',
    category: 'países',
  },
  {
    id: 2632,
    symbol: '🇱🇺',
    tags_EN: 'luxembourg, flag, country',
    tags_ES: 'luxemburgo, bandera, país',
    category: 'países',
  },
  {
    id: 2633,
    symbol: '🇱🇻',
    tags_EN: 'latvia, flag, country',
    tags_ES: 'latvia, bandera, país',
    category: 'países',
  },
  {
    id: 2634,
    symbol: '🇱🇾',
    tags_EN: 'libya, flag, country',
    tags_ES: 'libia, bandera, país',
    category: 'países',
  },
  {
    id: 2635,
    symbol: '🇲🇦',
    tags_EN: 'morocco, flag, country',
    tags_ES: 'marruecos, bandera, país',
    category: 'países',
  },
  {
    id: 2636,
    symbol: '🇲🇨',
    tags_EN: 'monaco, flag, country',
    tags_ES: 'mónaco, bandera, país',
    category: 'países',
  },
  {
    id: 2637,
    symbol: '🇲🇩',
    tags_EN: 'moldova, flag, country',
    tags_ES: 'moldavia, bandera, país',
    category: 'países',
  },
  {
    id: 2638,
    symbol: '🇲🇪',
    tags_EN: 'montenegro, flag, country',
    tags_ES: 'montenegro, bandera, país',
    category: 'países',
  },
  {
    id: 2639,
    symbol: '🇲🇫',
    tags_EN: 'st. martin, flag, country',
    tags_ES: 'san martín, bandera, país',
    category: 'países',
  },
  {
    id: 2640,
    symbol: '🇲🇬',
    tags_EN: 'madagascar, flag, country',
    tags_ES: 'madagascar, bandera, país',
    category: 'países',
  },
  {
    id: 2641,
    symbol: '🇲🇭',
    tags_EN: 'marshall islands, flag, country',
    tags_ES: 'islas marshall, bandera, país',
    category: 'países',
  },
  {
    id: 2642,
    symbol: '🇲🇰',
    tags_EN: 'macedonia, flag, country',
    tags_ES: 'macedonia, bandera, país',
    category: 'países',
  },
  {
    id: 2643,
    symbol: '🇲🇱',
    tags_EN: 'mali, flag, country',
    tags_ES: 'mali, bandera, país',
    category: 'países',
  },
  {
    id: 2644,
    symbol: '🇲🇲',
    tags_EN: 'myanmar, flag, country',
    tags_ES: 'myanmar, bandera, país',
    category: 'países',
  },
  {
    id: 2645,
    symbol: '🇲🇳',
    tags_EN: 'mongolia, flag, country',
    tags_ES: 'mongolia, bandera, país',
    category: 'países',
  },
  {
    id: 2646,
    symbol: '🇲🇴',
    tags_EN: 'macau, flag, country',
    tags_ES: 'macau, bandera, país',
    category: 'países',
  },
  {
    id: 2647,
    symbol: '🇲🇵',
    tags_EN: 'northern mariana islands, flag, country',
    tags_ES: 'islas mariana del norte, bandera, país',
    category: 'países',
  },
  {
    id: 2648,
    symbol: '🇲🇶',
    tags_EN: 'martinique, flag, country',
    tags_ES: 'martinica, bandera, país',
    category: 'países',
  },
  {
    id: 2649,
    symbol: '🇲🇷',
    tags_EN: 'mauritania, flag, country',
    tags_ES: 'mauritania, bandera, país',
    category: 'países',
  },
  {
    id: 2650,
    symbol: '🇲🇸',
    tags_EN: 'montserrat, flag, country',
    tags_ES: 'montserrat, bandera, país',
    category: 'países',
  },
  {
    id: 2651,
    symbol: '🇲🇹',
    tags_EN: 'malta, flag, country',
    tags_ES: 'malta, bandera, país',
    category: 'países',
  },
  {
    id: 2652,
    symbol: '🇲🇺',
    tags_EN: 'mauritius, flag, country',
    tags_ES: 'mauricio, bandera, país',
    category: 'países',
  },
  {
    id: 2653,
    symbol: '🇲🇻',
    tags_EN: 'maldives, flag, country',
    tags_ES: 'maldivas, bandera, país',
    category: 'países',
  },
  {
    id: 2654,
    symbol: '🇲🇼',
    tags_EN: 'malawi, flag, country',
    tags_ES: 'malawi, bandera, país',
    category: 'países',
  },
  {
    id: 2655,
    symbol: '🇲🇽',
    tags_EN: 'mexico, flag, country',
    tags_ES: 'méxico, bandera, país',
    category: 'países',
  },
  {
    id: 2656,
    symbol: '🇲🇾',
    tags_EN: 'malaysia, flag, country',
    tags_ES: 'malasia, bandera, país',
    category: 'países',
  },
  {
    id: 2657,
    symbol: '🇲🇿',
    tags_EN: 'mozambique, flag, country',
    tags_ES: 'mozambique, bandera, país',
    category: 'países',
  },
  {
    id: 2658,
    symbol: '🇳🇦',
    tags_EN: 'namibia, flag, country',
    tags_ES: 'namibia, bandera, país',
    category: 'países',
  },
  {
    id: 2659,
    symbol: '🇳🇨',
    tags_EN: 'new caledonia, flag, country',
    tags_ES: 'nueva caledonia, bandera, país',
    category: 'países',
  },
  {
    id: 2660,
    symbol: '🇳🇪',
    tags_EN: 'niger, flag, country',
    tags_ES: 'nigeria, bandera, país',
    category: 'países',
  },
  {
    id: 2661,
    symbol: '🇳🇫',
    tags_EN: 'norfolk island, flag, country',
    tags_ES: 'isla norfolk, bandera, país',
    category: 'países',
  },
  {
    id: 2662,
    symbol: '🇳🇬',
    tags_EN: 'nigeria, flag, country',
    tags_ES: 'nigeria, bandera, país',
    category: 'países',
  },
  {
    id: 2663,
    symbol: '🇳🇮',
    tags_EN: 'nicaragua, flag, country',
    tags_ES: 'nicaragua, bandera, país',
    category: 'países',
  },
  {
    id: 2664,
    symbol: '🇳🇱',
    tags_EN: 'netherlands, flag, country',
    tags_ES: 'países bajos, bandera, país',
    category: 'países',
  },
  {
    id: 2665,
    symbol: '🇳🇴',
    tags_EN: 'norway, flag, country',
    tags_ES: 'noruega, bandera, país',
    category: 'países',
  },
  {
    id: 2666,
    symbol: '🇳🇵',
    tags_EN: 'nepal, flag, country',
    tags_ES: 'nepal, bandera, país',
    category: 'países',
  },
  {
    id: 2667,
    symbol: '🇳🇷',
    tags_EN: 'nauru, flag, country',
    tags_ES: 'nauru, bandera, país',
    category: 'países',
  },
  {
    id: 2668,
    symbol: '🇳🇺',
    tags_EN: 'niue, flag, country',
    tags_ES: 'niue, bandera, país',
    category: 'países',
  },
  {
    id: 2669,
    symbol: '🇳🇿',
    tags_EN: 'new zealand, flag, country',
    tags_ES: 'nueva zelanda, bandera, país',
    category: 'países',
  },
  {
    id: 2670,
    symbol: '🇴🇲',
    tags_EN: 'oman, flag, country',
    tags_ES: 'omán, bandera, país',
    category: 'países',
  },
  {
    id: 2671,
    symbol: '🇵🇦',
    tags_EN: 'panama, flag, country',
    tags_ES: 'panamá, bandera, país',
    category: 'países',
  },
  {
    id: 2672,
    symbol: '🇵🇪',
    tags_EN: 'peru, flag, country',
    tags_ES: 'perú, bandera, país',
    category: 'países',
  },
  {
    id: 2673,
    symbol: '🇵🇫',
    tags_EN: 'french polynesia, flag, country',
    tags_ES: 'polinesia francesa, bandera, país',
    category: 'países',
  },
  {
    id: 2674,
    symbol: '🇵🇬',
    tags_EN: 'papua new guinea, flag, country',
    tags_ES: 'papúa nueva guinea, bandera, país',
    category: 'países',
  },
  {
    id: 2675,
    symbol: '🇵🇭',
    tags_EN: 'philippines, flag, country',
    tags_ES: 'filipinas, bandera, país',
    category: 'países',
  },
  {
    id: 2676,
    symbol: '🇵🇰',
    tags_EN: 'pakistan, flag, country',
    tags_ES: 'pakistán, bandera, país',
    category: 'países',
  },
  {
    id: 2677,
    symbol: '🇵🇱',
    tags_EN: 'poland, flag, country',
    tags_ES: 'polonia, bandera, país',
    category: 'países',
  },
  {
    id: 2678,
    symbol: '🇵🇲',
    tags_EN: 'st. pierre & miquelon, flag, country',
    tags_ES: 'st.pierre & miquelon, bandera, país',
    category: 'países',
  },
  {
    id: 2679,
    symbol: '🇵🇳',
    tags_EN: 'pitcairn islands, flag, country',
    tags_ES: 'islas pitcairn, bandera, país',
    category: 'países',
  },
  {
    id: 2680,
    symbol: '🇵🇷',
    tags_EN: 'puerto rico, flag, country',
    tags_ES: 'puerto rico, bandera, país',
    category: 'países',
  },
  {
    id: 2681,
    symbol: '🇵🇸',
    tags_EN: 'palestinian territories, flag, country',
    tags_ES: 'palestina, bandera, país',
    category: 'países',
  },
  {
    id: 2682,
    symbol: '🇵🇹',
    tags_EN: 'portugal, flag, country',
    tags_ES: 'portugal, bandera, país',
    category: 'países',
  },
  {
    id: 2683,
    symbol: '🇵🇼',
    tags_EN: 'palau, flag, country',
    tags_ES: 'palaos, bandera, país',
    category: 'países',
  },
  {
    id: 2684,
    symbol: '🇵🇾',
    tags_EN: 'paraguay, flag, country',
    tags_ES: 'paraguay, bandera, país',
    category: 'países',
  },
  {
    id: 2685,
    symbol: '🇶🇦',
    tags_EN: 'qatar, flag, country',
    tags_ES: 'qatar, bandera, país',
    category: 'países',
  },
  {
    id: 2686,
    symbol: '🇷🇪',
    tags_EN: 'réunion, flag, country',
    tags_ES: 'reunión, bandera, país',
    category: 'países',
  },
  {
    id: 2687,
    symbol: '🇷🇴',
    tags_EN: 'romania, flag, country',
    tags_ES: 'rumania, bandera, país',
    category: 'países',
  },
  {
    id: 2688,
    symbol: '🇷🇸',
    tags_EN: 'belarus, flag, country',
    tags_ES: 'serbia, bandera, país',
    category: 'países',
  },
  {
    id: 2689,
    symbol: '🇷🇺',
    tags_EN: 'russia, flag, country',
    tags_ES: 'rusia, bandera, país',
    category: 'países',
  },
  {
    id: 2690,
    symbol: '🇷🇼',
    tags_EN: 'rwanda, flag, country',
    tags_ES: 'ruanda, bandera, país',
    category: 'países',
  },
  {
    id: 2691,
    symbol: '🇸🇦',
    tags_EN: 'saudi arabia, flag, country',
    tags_ES: 'arabia saudí, bandera, país',
    category: 'países',
  },
  {
    id: 2692,
    symbol: '🇸🇧',
    tags_EN: 'solomon islands, flag, country',
    tags_ES: 'islas salomón, bandera, país',
    category: 'países',
  },
  {
    id: 2693,
    symbol: '🇸🇨',
    tags_EN: 'seychelles, flag, country',
    tags_ES: 'seychelles, bandera, país',
    category: 'países',
  },
  {
    id: 2694,
    symbol: '🇸🇩',
    tags_EN: 'sudan, flag, country',
    tags_ES: 'sudán, bandera, país',
    category: 'países',
  },
  {
    id: 2695,
    symbol: '🇸🇪',
    tags_EN: 'sweden, flag, country',
    tags_ES: 'suecia, bandera, país',
    category: 'países',
  },
  {
    id: 2696,
    symbol: '🇸🇬',
    tags_EN: 'singapoer, flag, country',
    tags_ES: 'singapur, bandera, país',
    category: 'países',
  },
  {
    id: 2697,
    symbol: '🇸🇭',
    tags_EN: 'st. helena, flag, country',
    tags_ES: 'st. helena, bandera, país',
    category: 'países',
  },
  {
    id: 2698,
    symbol: '🇸🇮',
    tags_EN: 'slovenia, flag, country',
    tags_ES: 'eslovenia, bandera, país',
    category: 'países',
  },
  {
    id: 2699,
    symbol: '🇸🇯',
    tags_EN: 'svalbard & jan mayen, flag, country',
    tags_ES: 'svalbard & jan mayen, bandera, país',
    category: 'países',
  },
  {
    id: 2700,
    symbol: '🇸🇰',
    tags_EN: 'slovakia, flag, country',
    tags_ES: 'eslovaquia, bandera, país',
    category: 'países',
  },
  {
    id: 2701,
    symbol: '🇸🇱',
    tags_EN: 'sierra leone, flag, country',
    tags_ES: 'sierra leona, bandera, país',
    category: 'países',
  },
  {
    id: 2702,
    symbol: '🇸🇲',
    tags_EN: 'san marino, flag, country',
    tags_ES: 'san marino, bandera, país',
    category: 'países',
  },
  {
    id: 2703,
    symbol: '🇸🇳',
    tags_EN: 'senegal, flag, country',
    tags_ES: 'senegal, bandera, país',
    category: 'países',
  },
  {
    id: 2704,
    symbol: '🇸🇴',
    tags_EN: 'somalia, flag, country',
    tags_ES: 'somalia, bandera, país',
    category: 'países',
  },
  {
    id: 2705,
    symbol: '🇸🇷',
    tags_EN: 'suriname, flag, country',
    tags_ES: 'surinam, bandera, país',
    category: 'países',
  },
  {
    id: 2706,
    symbol: '🇸🇸',
    tags_EN: 'south sudan, flag, country',
    tags_ES: 'sudán del sur, bandera, país',
    category: 'países',
  },
  {
    id: 2707,
    symbol: '🇸🇹',
    tags_EN: 'sao tome & principe, flag, country',
    tags_ES: 'santo tomé y príncipe, bandera, país',
    category: 'países',
  },
  {
    id: 2708,
    symbol: '🇸🇻',
    tags_EN: 'el salvador, flag, country',
    tags_ES: 'el salvador, bandera, país',
    category: 'países',
  },
  {
    id: 2709,
    symbol: '🇸🇽',
    tags_EN: 'sint maarten, flag, country',
    tags_ES: 'sint maarten, bandera, país',
    category: 'países',
  },
  {
    id: 2710,
    symbol: '🇸🇾',
    tags_EN: 'syria, flag, country',
    tags_ES: 'siria, bandera, país',
    category: 'países',
  },
  {
    id: 2711,
    symbol: '🇸🇿',
    tags_EN: 'swaziland, flag, country',
    tags_ES: 'suazilandia, bandera, país',
    category: 'países',
  },
  {
    id: 2712,
    symbol: '🇹🇦',
    tags_EN: 'tristan da cunha, flag, country',
    tags_ES: 'tristán de acuña, bandera, país',
    category: 'países',
  },
  {
    id: 2713,
    symbol: '🇹🇨',
    tags_EN: 'truks & caicos islands, flag, country',
    tags_ES: 'islas turcas y caicos, bandera, país',
    category: 'países',
  },
  {
    id: 2714,
    symbol: '🇹🇩',
    tags_EN: 'chad, flag, country',
    tags_ES: 'chad, bandera, país',
    category: 'países',
  },
  {
    id: 2715,
    symbol: '🇹🇫',
    tags_EN: 'french southern territories, flag, country',
    tags_ES: 'territorios franceses del sur, bandera, país',
    category: 'países',
  },
  {
    id: 2716,
    symbol: '🇹🇬',
    tags_EN: 'togo, flag, country',
    tags_ES: 'togo, bandera, país',
    category: 'países',
  },
  {
    id: 2717,
    symbol: '🇹🇭',
    tags_EN: 'thailand, flag, country',
    tags_ES: 'tailandia, bandera, país',
    category: 'países',
  },
  {
    id: 2718,
    symbol: '🇹🇯',
    tags_EN: 'tajikistan, flag, country',
    tags_ES: 'tayikistán, bandera, país',
    category: 'países',
  },
  {
    id: 2719,
    symbol: '🇹🇰',
    tags_EN: 'tokelau, flag, country',
    tags_ES: 'tokelau, bandera, país',
    category: 'países',
  },
  {
    id: 2720,
    symbol: '🇹🇱',
    tags_EN: 'timor-leste, flag, country',
    tags_ES: 'timor oriental, bandera, país',
    category: 'países',
  },
  {
    id: 2721,
    symbol: '🇹🇲',
    tags_EN: 'turkmenistan, flag, country',
    tags_ES: 'turkmenistán, bandera, país',
    category: 'países',
  },
  {
    id: 2722,
    symbol: '🇹🇳',
    tags_EN: 'tunisia, flag, country',
    tags_ES: 'túnez, bandera, país',
    category: 'países',
  },
  {
    id: 2723,
    symbol: '🇹🇴',
    tags_EN: 'tonga, flag, country',
    tags_ES: 'tonga, bandera, país',
    category: 'países',
  },
  {
    id: 2724,
    symbol: '🇹🇷',
    tags_EN: 'turkey, flag, country',
    tags_ES: 'turquía, bandera, país',
    category: 'países',
  },
  {
    id: 2725,
    symbol: '🇹🇹',
    tags_EN: 'trinidad & tobago, flag, country',
    tags_ES: 'trinidad y tobago, bandera, país',
    category: 'países',
  },
  {
    id: 2726,
    symbol: '🇹🇻',
    tags_EN: 'tuvalu, flag, country',
    tags_ES: 'tuvalu, bandera, país',
    category: 'países',
  },
  {
    id: 2727,
    symbol: '🇹🇼',
    tags_EN: 'taiwan, flag, country',
    tags_ES: 'república de china, bandera, país',
    category: 'países',
  },
  {
    id: 2728,
    symbol: '🇹🇿',
    tags_EN: 'tanzania, flag, country',
    tags_ES: 'tanzania, bandera, país',
    category: 'países',
  },
  {
    id: 2729,
    symbol: '🇺🇦',
    tags_EN: 'ukraine, flag, country',
    tags_ES: 'ucrania, bandera, país',
    category: 'países',
  },
  {
    id: 2730,
    symbol: '🇺🇬',
    tags_EN: 'uganda, flag, country',
    tags_ES: 'uganda, bandera, país',
    category: 'países',
  },
  {
    id: 2731,
    symbol: '🇺🇲',
    tags_EN: 'u.s. otulying islands, flag, country',
    tags_ES: 'islas ultramarinas menores de estados unidos, bandera, país',
    category: 'países',
  },
  {
    id: 2732,
    symbol: '🇺🇳',
    tags_EN: 'united nations, flag, country',
    tags_ES: 'naciones unidas, bandera, país',
    category: 'países',
  },
  {
    id: 2733,
    symbol: '🇺🇸',
    tags_EN: 'united states, flag, country',
    tags_ES: 'estados unidos, bandera, país',
    category: 'países',
  },
  {
    id: 2734,
    symbol: '🇺🇾',
    tags_EN: 'uruguay, flag, country',
    tags_ES: 'uruguay, bandera, país',
    category: 'países',
  },
  {
    id: 2735,
    symbol: '🇺🇿',
    tags_EN: 'uzbekistan, flag, country',
    tags_ES: 'uzbekistán, bandera, país',
    category: 'países',
  },
  {
    id: 2736,
    symbol: '🇻🇦',
    tags_EN: 'vatican city, flag, country',
    tags_ES: 'ciudad del vaticano, bandera, país',
    category: 'países',
  },
  {
    id: 2737,
    symbol: '🇻🇨',
    tags_EN: 'st. vincent & grenadines, flag, country',
    tags_ES: 'san vicente y las granadinas, bandera, país',
    category: 'países',
  },
  {
    id: 2738,
    symbol: '🇻🇪',
    tags_EN: 'venezuela, flag, country',
    tags_ES: 'venezuela, bandera, país',
    category: 'países',
  },
  {
    id: 2739,
    symbol: '🇻🇬',
    tags_EN: 'british virgin islands, flag, country',
    tags_ES: 'islas vírgenes británicas, bandera, país',
    category: 'países',
  },
  {
    id: 2740,
    symbol: '🇻🇮',
    tags_EN: 'u.s. virgin islands, flag, country',
    tags_ES: 'islas vírgenes U.S., bandera, país',
    category: 'países',
  },
  {
    id: 2741,
    symbol: '🇻🇳',
    tags_EN: 'vietnam, flag, country',
    tags_ES: 'vietnam, bandera, país',
    category: 'países',
  },
  {
    id: 2742,
    symbol: '🇻🇺',
    tags_EN: 'vanuatu, flag, country',
    tags_ES: 'vanuatu, bandera, país',
    category: 'países',
  },
  {
    id: 2743,
    symbol: '🇼🇫',
    tags_EN: 'wallis & futuna, flag, country',
    tags_ES: 'wallis y futuna, bandera, país',
    category: 'países',
  },
  {
    id: 2744,
    symbol: '🇼🇸',
    tags_EN: 'samoa, flag, country',
    tags_ES: 'samoa, bandera, país',
    category: 'países',
  },
  {
    id: 2745,
    symbol: '🇽🇰',
    tags_EN: 'kosovo, flag, country',
    tags_ES: 'kosovo, bandera, país',
    category: 'países',
  },
  {
    id: 2746,
    symbol: '🇾🇪',
    tags_EN: 'yemen, flag, country',
    tags_ES: 'yemen, bandera, país',
    category: 'países',
  },
  {
    id: 2747,
    symbol: '🇾🇹',
    tags_EN: 'mayotte, flag, country',
    tags_ES: 'mayotte, bandera, país',
    category: 'países',
  },
  {
    id: 2748,
    symbol: '🇿🇦',
    tags_EN: 'sout africa, flag, country',
    tags_ES: 'sudáfrica, bandera, país',
    category: 'países',
  },
  {
    id: 2749,
    symbol: '🇿🇲',
    tags_EN: 'zambia, flag, country',
    tags_ES: 'zambia, bandera, país',
    category: 'países',
  },
  {
    id: 2750,
    symbol: '🇿🇼',
    tags_EN: 'zimbabwe, flag, country',
    tags_ES: 'zimbaue, bandera, país',
    category: 'países',
  },
];

export default Emojis;
