import React from 'react';
import styled from 'styled-components';
import useTheme from 'hooks/useTheme';
import Text from 'components/Text';

const Heading = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
`;

const List = styled.ul`
  list-style-position: outside;
  list-style-type: disc;
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: left;
  margin-top: 20px;
`;

const Li = styled.li`
  margin-bottom: 15px;
`;

const LiText = styled(Text)`
  white-space: normal;
  margin-right: 20px;
  font-size: 18px;
`;

export const WaiverLinks = ({ currentUser }) => {
  const theme = useTheme();

  return currentUser.waiver_links.length ? (
    <div style={{ marginBottom: 20 }}>
      <Heading>
        <Text type="heading">Waiver Links</Text>
      </Heading>
      <List>
        {currentUser.waiver_links.map(link => (
          <Li key={link.type}>
            <LiText>
              {'minor' === link.type ? 'Minor Signature' : 'Parent Signature'}
            </LiText>

            <a
              href={`${link.link}`}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: theme.colors.primary,
                textDecoration: 'none',
                fontSize: 18
              }}
            >
              Link
            </a>
          </Li>
        ))}
      </List>
    </div>
  ) : null;
};
