import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { logDevError } from 'shared/alerts';
import { MemberValuesFragment } from './member.fragments';

export const APPROVE_MEMBER = gql`
  mutation ApproveMember($pass_id: Int!) {
    approveMember(pass_id: $pass_id) {
      ...memberValues
    }
  }
  ${MemberValuesFragment}
`;

export const UN_APPROVE_MEMBER = gql`
  mutation UnApproveMember($pass_id: Int!) {
    unApproveMember(pass_id: $pass_id) {
      ...memberValues
    }
  }
  ${MemberValuesFragment}
`;

export function useApproveMember() {
  const [approveMemberMutation] = useMutation(APPROVE_MEMBER);

  const approveMember = useCallback(
    async (id) => {
      try {
        const response = await approveMemberMutation({
          variables: { pass_id: id },
        });

        return response;
      } catch (error) {
        logDevError(error);
      }
    },
    [approveMemberMutation]
  );
  return approveMember;
}

export function useUnApproveMember() {
  const [unUnapproveMemberMutation] = useMutation(UN_APPROVE_MEMBER);

  const unApproveMember = useCallback(
    async (id) => {
      try {
        const response = await unUnapproveMemberMutation({
          variables: { pass_id: id },
        });

        return response;
      } catch (error) {
        logDevError(error);
      }
    },
    [unUnapproveMemberMutation]
  );
  return unApproveMember;
}
