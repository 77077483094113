import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Spacer from 'components/Spacer';
import {
  YearDropdown,
  withEventYearFilterContext,
} from 'components/YearDropdown';
import AccountFilter from 'pages/app/AnnualTickets/component/AccountFilter';

export const OpenCreditDropdown = withEventYearFilterContext(
  ({ setAccountId, setYear }) => {
    const location = useLocation();
    const isTrackAdmin = location.pathname.includes('/admin-track/');

    const [accountFilter, setAccountFilter] = useState(null);

    const setAccount = (account) => {
      setAccountFilter(account);
      setAccountId(account.value);
    };

    return (
      <>
        <Spacer size={18} />
        {!isTrackAdmin && (
          <AccountFilter
            accountFilter={accountFilter}
            setAccountFilter={setAccount}
            label={true}
          />
        )}

        <Spacer size={18} />
        <YearDropdown
          onSelect={(year) => setYear(year.value)}
          showLabel={true}
        />
      </>
    );
  }
);
