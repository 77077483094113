import { Formik } from 'formik';
import React, { useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import Spacer from 'components/Spacer';
import EmployeeSearch from 'pages/track/Employees/EmployeeEdit/employee-search';
import { useGetEmployee } from 'pages/track/Employees/gql/queries/get-employee';
import AddMember from './gql/AddMember';

const Label = styled.p`
  margin-bottom: 20px;
  color: #fff;
  font-weight: 600;
  font-family: 'Barlow Condensed';
  text-align: left;
  font-size: 5.4vw;
  line-height: 29px;

  @media (min-width: 700px) {
    font-size: 20px;
  }

  @media (min-width: 768px) {
    color: #000;
  }
`;

const formValues = [
  'first_name',
  'last_name',
  'cellphone',
  'country_code',
  'calling_code',
  'email',
  'employee',
  'account_ids',
];

export const AddMemberForm = compose(AddMember)(
  ({
    isVisible,
    handleOutClick,
    addMember,
    currentEmployee,
    account_id,
    members,
    setFieldValue,
    history,
  }) => {
    const successMessage = () =>
      toast.success(currentEmployee ? 'Staff Updated' : 'Staff Created');
    const errorMessage = () =>
      toast.error(
        currentEmployee ? 'Error Updating Staff' : 'Error Creating Staff'
      );

    const { data } = useGetEmployee(currentEmployee);
    const employee = currentEmployee ? data?.getEmployeeV2 : null;

    const [showForm, setShowForm] = useState(false);

    const platform =
      'tickethoss' === process.env.REACT_APP_PLATFORM
        ? 'Ticket Hoss'
        : 'pitpay' === process.env.REACT_APP_PLATFORM
        ? 'Pit Pay'
        : 'Kart Pass';

    function validate(values) {
      const errors = {};
      if (!values.first_name) {
        errors.first_name = 'Required';
      }

      if (!values.last_name) {
        errors.last_name = 'Required';
      }
      return errors;
    }

    const initialValues = formValues.reduce((formValues, value) => {
      const initialValue = employee ? employee[value] ?? '' : '';

      return {
        ...formValues,
        [value]: initialValue,
      };
    }, {});

    async function handleSubmit(
      values,
      { setSubmitting, setErrors, resetForm }
    ) {
      setSubmitting(true);

      const data = Object.entries(values).reduce((formValues, [key, value]) => {
        return {
          ...formValues,
          ...(value ? { [key]: value } : null),
        };
      }, {});

      let response;
      if (!showForm) {
        response = await addMember({
          user_id: data.user_id,
          account_id: parseInt(account_id),
        });
      } else {
        response = await addMember({
          ...data,
          calling_code: data.calling_code ?? '1',
          country_code: data.country_code ?? 'US',
          cellphone: data.calling_code
            ? data.cellphone.replace(`+${data.calling_code}`, '')
            : data.cellphone.replace(`+1`, ''),
          account_id: parseInt(account_id),
        });
      }

      setSubmitting(false);

      if (!response || response.errors) {
        errorMessage();
        return setErrors(response.errors);
      } else {
        const newMembers = [...members, response.data.addMember];
        setFieldValue('members', newMembers);

        successMessage();
        resetForm();
        handleOutClick();
        history.push(`/admin/users/editUser/${response.data.addMember.id}`);
      }
    }

    return (
      <Drawer
        title={currentEmployee ? 'Edit Staff Details' : 'Add Staff'}
        isVisible={isVisible}
        handleOutClick={handleOutClick}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                {currentEmployee ? null : (
                  <DrawerPadding border>
                    <Label style={{ color: '#00001f' }}>
                      Enter the mobile number of the Staff you wish to add.
                    </Label>
                    <Label style={{ color: '#00001f' }}>
                      If they are already a {platform} App user, their number
                      will autopopulate and their {platform} profile will be
                      updated once you select their name and tap to add the
                      Staff.
                    </Label>
                    <Label style={{ color: '#00001f' }}>
                      If they are not a {platform} App user, you will see a form
                      to add them. Fill out the additional fields as required
                      and tap to add Staff.
                    </Label>
                    <Spacer size={18} />

                    <EmployeeSearch
                      value={values.employee}
                      showForm={(cellphone) => {
                        setFieldValue('cellphone', cellphone);
                        setShowForm(true);
                      }}
                      hideForm={() => setShowForm(false)}
                      setFieldValue={setFieldValue}
                    />
                    <Spacer size={18} />
                  </DrawerPadding>
                )}

                {showForm || currentEmployee ? (
                  <>
                    <DrawerPadding border>
                      <Label style={{ color: '#00001f' }}>
                        {currentEmployee
                          ? ''
                          : 'Fill out the form to add a new Staff'}
                      </Label>

                      <Spacer size={18} />
                      <PhoneInput
                        id="cellphone"
                        label="Phone"
                        placeholder="Phone Number"
                        onBlur={handleBlur}
                        onChange={(value) => {
                          setFieldValue('cellphone', value);
                        }}
                        onCountryChange={(e) => {
                          if (e) {
                            setFieldValue('country_code', e ?? '');
                            setFieldValue(
                              'calling_code',
                              getCountryCallingCode(e) ?? ''
                            );
                          }
                        }}
                        value={values.cellphone ?? ''}
                        error={touched.cellphone && errors.cellphone}
                      />
                      <Spacer size={18} />
                      <Input
                        id="first_name"
                        label="First Name"
                        placeholder="First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        error={
                          errors.first_name &&
                          touched.first_name &&
                          errors.first_name
                        }
                      />
                      <Spacer size={18} />
                      <Input
                        id="last_name"
                        label="Last Name"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        error={
                          errors.last_name &&
                          touched.last_name &&
                          errors.last_name
                        }
                      />
                      <Spacer size={18} />
                      <Input
                        id="email"
                        label="Email"
                        placeholder="name@email.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email && errors.email}
                      />
                    </DrawerPadding>
                  </>
                ) : null}
                <DrawerPadding>
                  <Button type="submit" disabled={isSubmitting} block>
                    {currentEmployee ? 'Update Staff' : 'Add Staff'}
                  </Button>
                </DrawerPadding>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    );
  }
);
