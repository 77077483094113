import React from 'react';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { H3 } from 'components/Heading';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Spacer from 'components/Spacer';
import {
  TitleContainer,
  ListBlock,
  ListItem,
  ListItemText,
  SocialContainer,
  SocialItem,
  Note,
  Name,
  Link,
  Text,
  SocialHeading,
  FormLink,
} from './style';

export const getFacebookLink = () => {
  if (process.env.REACT_APP_PLATFORM === 'pitpay') {
    return 'https://www.facebook.com/PitPayApp';
  }

  if (process.env.REACT_APP_PLATFORM === 'kartpass') {
    return 'https://www.facebook.com/KartPassApp/';
  }

  if (process.env.REACT_APP_PLATFORM === 'tickethoss') {
    return 'https://www.facebook.com/TicketHoss/';
  }

  return 'https://www.facebook.com/PitPayApp';
};

export const getTwitterLink = () => {
  if (process.env.REACT_APP_PLATFORM === 'pitpay') {
    return 'https://twitter.com/pitpayapp';
  }

  if (process.env.REACT_APP_PLATFORM === 'kartpass') {
    return 'https://twitter.com/kartpassapp';
  }

  if (process.env.REACT_APP_PLATFORM === 'tickethoss') {
    return 'https://twitter.com/tickethoss';
  }

  return 'https://twitter.com/pitpayapp';
};

export const getInstagramLink = () => {
  if (process.env.REACT_APP_PLATFORM === 'pitpay') {
    return 'https://www.instagram.com/pitpayapp';
  }

  if (process.env.REACT_APP_PLATFORM === 'kartpass') {
    return 'https://www.instagram.com/kartpassapp/';
  }

  if (process.env.REACT_APP_PLATFORM === 'tickethoss') {
    return 'https://www.instagram.com/tickethoss/';
  }

  return 'https://www.instagram.com/pitpayapp';
};

const getFormLink = (form) => {
  const platform = process.env.REACT_APP_PLATFORM;
  const forms = {
    tickethoss: {
      new_event: 'https://form.jotform.com/241994930773166',
      event_change: 'https://form.jotform.com/232355700099153',
      help_site: 'https://help.tickethoss.com',
      new_season: 'https://formcrafts.com/a/thyearlyscheduleupdate',
      support_request: 'https://form.jotform.com/232355742236152',
    },
    pitpay: {
      new_event: 'https://form.jotform.com/232397277974069',
      event_change: 'https://form.jotform.com/232427336611047',
      help_site: 'https://help.pitpay.com',
      new_season: 'https://formcrafts.com/a/mcuvjzd',
      support_request: 'https://form.jotform.com/232427394013047',
    },
    kartpass: {
      new_event: 'https://form.jotform.com/241976637156165',
      event_change: 'https://form.jotform.com/241976363802159',
      help_site: 'https://help.kartpass.com',
      new_season: 'https://formcrafts.com/a/kpyearlyscheduleupdate',
      support_request: 'https://form.jotform.com/241976470415158',
    },
  };

  return forms[platform][form];
};

const getName = () => {
  if (process.env.REACT_APP_PLATFORM === 'pitpay') {
    return 'Pit Pay';
  }

  if (process.env.REACT_APP_PLATFORM === 'kartpass') {
    return 'Kart Pass';
  }

  if (process.env.REACT_APP_PLATFORM === 'tickethoss') {
    return 'Ticket Hoss';
  }

  return 'Pit Pay';
};

const data = [
  {
    id: 1,
    title: 'Support',
    data: [
      {
        id: 1,
        icon: null,
        name: 'Submit New Event',
        link: getFormLink('new_event'),
      },
      {
        id: 2,
        icon: null,
        name: 'Submit Event Change',
        link: getFormLink('event_change'),
      },
      // {
      //   id: 3,
      //   icon: null,
      //   name: 'Submit New Season Schedule',
      //   link: getFormLink('new_season'),
      // },
      {
        id: 4,
        icon: null,
        name: `${getName()} Help Site`,
        link: getFormLink('help_site'),
      },
      {
        id: 5,
        icon: null,
        name: 'Submit Support Request',
        link: getFormLink('support_request'),
      },
      {
        id: 6,
        icon: 'mail',
        name:
          process.env.REACT_APP_PLATFORM !== 'tickethoss'
            ? 'events@pitpay.com'
            : 'support@TicketHoss.com',
        link:
          process.env.REACT_APP_PLATFORM !== 'tickethoss'
            ? 'mailto:events@pitpay.com'
            : 'mailto:support@TicketHoss.com',
      },
      { id: 7, icon: 'phone', name: '(855) 748-1290', link: 'tel:8557481290' },
      { id: 8, icon: 'phone', name: '(855) 748-7292', link: 'tel:8557487292' },
    ],
  },
  {
    id: 2,
    title: 'Headquarters',
    data: [
      { id: 1, icon: 'location', name: 'Pit Pay Inc. Charlotte, NC' },
      {
        id: 2,
        icon: 'mail',
        name: 'info@PitPay.com',
        link: 'mailto:info@PitPay.com',
      },
      { id: 3, icon: 'phone', name: '(855) 748-7291', link: 'tel:8557487291' },
    ],
  },

  // {
  //   id: 3,
  //   title: 'Business Inquires',
  //   data: [
  //     {
  //       id: 1,
  //       icon: 'mail',
  //       name: 'Partners@PitPay.com',
  //       link: 'mailto:Partners@PitPay.com',
  //     },
  //     {
  //       id: 2,
  //       icon: 'phone',
  //       name: '(704) 428-9020',
  //       link: 'tel:7044289020',
  //     },
  //   ],
  // },
];

const Contact = () => {
  return (
    <Container>
      <ContainerHeader>
        <TitleContainer>
          <H3 color="#3c4144">Contact</H3>
        </TitleContainer>
      </ContainerHeader>
      <div>
        <Spacer size={28} />
        <MobileContainer background="transparent" padding="0 20px">
          {data.map((item, index) => (
            <ListBlock
              key={item.id}
              style={index + 1 === data.length ? { marginBottom: 0 } : null}
            >
              <Name>{item.title}</Name>
              <Spacer size={10} />

              {item.data.map((subItem) => (
                <ListItem key={subItem.id}>
                  <div>
                    <Icon icon={subItem.icon} size={22} color="black" />
                  </div>
                  {subItem.icon ? (
                    <ListItemText>
                      {subItem.link ? (
                        <Link href={subItem.link}>
                          {subItem.name}
                          {subItem.icon === 'phone' ? (
                            <Note>Text or Call</Note>
                          ) : null}
                        </Link>
                      ) : (
                        <Text>
                          {subItem.name}
                          {subItem.icon === 'phone' ? (
                            <Note>Text or Call</Note>
                          ) : null}
                        </Text>
                      )}
                    </ListItemText>
                  ) : (
                    <FormLink href={subItem.link} target="_blank">
                      {subItem.name}
                    </FormLink>
                  )}
                </ListItem>
              ))}
            </ListBlock>
          ))}
        </MobileContainer>
        <div style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
          <SocialHeading>Follow Us</SocialHeading>
          <SocialContainer>
            <React.Fragment>
              <SocialItem href={getFacebookLink()} target="_blank">
                <Icon icon="facebook" size={30} color={'#fff'} />
              </SocialItem>
              <SocialItem href={getInstagramLink()} target="_blank">
                <Icon icon="instagram" size={30} color={'#fff'} />
              </SocialItem>
              <SocialItem href={getTwitterLink()} target="_blank">
                <Icon icon="twitter" size={30} color={'#fff'} />
              </SocialItem>
              <SocialItem
                href="https://www.linkedin.com/company/pitpay"
                target="_blank"
              >
                <Icon icon="linkedin" size={30} color={'#fff'} />
              </SocialItem>
            </React.Fragment>
          </SocialContainer>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
