import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useEventYearFilter } from 'components/YearDropdown';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const GET_ACCOUNT_OPEN_CREDITS = gql`
  query GetUserOpenCreditsByAccount($creditsInput: GetUserCreditsInput!) {
    getUserOpenCreditsByAccount(input: $creditsInput) {
      count
      results {
        id
        user {
          id
          formatted_name
        }
        amount
        date
        issued_by {
          formatted_name
        }
        track_credit
        fee_credit
        original_amount
        transaction {
          id
          fee
          total
          refund
          subtotal
          discount
          credit
          transfer_id
          charge_id
          card {
            last4
          }
          purchase_date
          status
          user {
            id
            name
            first_name
            last_name
            formatted_name
          }
          users {
            promo
            user {
              id
              name
              first_name
              last_name
            }
            tickets {
              id
              name
              price
              refunded
              type
              barcode
              credited
            }
          }
          international_fee
        }
      }
    }
  }
`;

export function useGetUserOpenCreditsByAccount() {
  const { id: user_id } = useParams();
  const { yearFilter } = useEventYearFilter();
  const {
    state: { accountId },
  } = useContext(AppContext);
  const { input: search } = useSearchInput();

  const result = useQuery(GET_ACCOUNT_OPEN_CREDITS, {
    variables: {
      creditsInput: {
        user_id: Number(user_id),
        account_id: accountId,
        ...(yearFilter ? { year: yearFilter } : null),
        ...search,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { ...result, res: result.data };
}
