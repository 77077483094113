import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/UseGetAnnualCategories';

export default graphql(
  gql`
    mutation UpdateAnnualType($input: UpdateAnnualTypeInput!) {
      updateAnnualType(input: $input) {
        id
        name
        account {
          id
          business_dba
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateAnnualType: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateAnnualType } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query: query,
                variables: { input: { ...search } },
              });

              proxy.writeQuery({
                query: query,
                variables: { input: { ...search } },
                data: {
                  ...data,
                  getAnnualTypesAdmin: {
                    ...data.getAnnualTypesAdmin,
                    count: data.getAnnualTypesAdmin.count,
                    results: data.getAnnualTypesAdmin.results.reduce(
                      (acc, event) => {
                        if (parseInt(event.id) === parseInt(input.id)) {
                          return acc.concat([updateAnnualType]);
                        }

                        return acc.concat([event]);
                      },
                      []
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
