import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const query = gql`
  query GetEmployeesV2($input: GetEmployeeInputV2!) {
    getEmployeesV2(input: $input) {
      count
      results {
        id
        first_name
        last_name
        formatted_name
        email
        role
        cellphone
        calling_code
        country_code
        middle_name
        suffix
      }
    }
  }
`;

export function GetEmployees(search) {
  const {
    state: { accountId },
  } = useContext(AppContext);

  return usePersistedQuery(query, `get-employees-${accountId}-${search}`, {
    variables: {
      input: {
        ...(search ? { queryString: search } : null),
        ...(accountId ? { account_id: accountId } : null),
      },
    },
  });
}
