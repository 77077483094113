import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_MEMBERSHIP_DIVISIONS = gql`
  query GetMembershipDivisions($ticket_id: Int!) {
    getMembershipDivisions(ticket_id: $ticket_id)
  }
`;

export function useGetMembershipDivisions(ticket_id) {
  return useQuery(GET_MEMBERSHIP_DIVISIONS, {
    variables: {
      ticket_id: Number(ticket_id),
    },
    skip: !ticket_id,
  });
}
