import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNT_OPEN_CREDITS_REPORT = gql`
  mutation GetUserOpenCreditsByAccountReport($input: GetUserCreditsInput!) {
    getUserOpenCreditsByAccountReport(input: $input) {
      count
      results {
        id
        user {
          id
          formatted_name
        }
        amount
        date
        issued_by {
          formatted_name
        }
        track_credit
        fee_credit
        original_amount
      }
    }
  }
`;

export function useGetUserOpenCreditsReport(yearFilter) {
  const { id: account_id, id2: user_id } = useParams();
  const { input: search } = useSearchInput();

  const [userOpenCreditReportMutation] = useMutation(
    GET_ACCOUNT_OPEN_CREDITS_REPORT
  );

  const result = useCallback(
    (options = {}) => {
      return userOpenCreditReportMutation({
        variables: {
          input: {
            ...search,
            account_id: Number(account_id),
            user_id: Number(user_id),
            ...(yearFilter ? { year: yearFilter } : null),
          },
        },
        ...options,
      });
    },
    [userOpenCreditReportMutation, search, account_id, user_id, yearFilter]
  );
  return result;
}
