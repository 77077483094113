import React from 'react';
import { compose } from 'recompose';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import GetAccountData from './gql/get-account-events';

const Account = ({ values, errors, touched, setFieldValue, data }) => {
  if (data.loading || !data.getAllAccounts) return null;

  const selectedItem = data.getAllAccounts.find(
    (item) => values.account_id === item.id
  );
  if (selectedItem) {
    setFieldValue('account_id.label', selectedItem.business_dba);
    setFieldValue('account_id.value', selectedItem.id);
  }

  return (
    <AutoSuggest
      name="account_id"
      label="Account"
      value={
        typeof values.account_id == 'number' && selectedItem
          ? {
              value: selectedItem.id,
              label: selectedItem.business_dba,
            }
          : values.account_id
      }
      error={errors.account_id}
      touched={touched.account_id}
      onChange={(value) => {
        setFieldValue('account_id', value);
      }}
      closeMenuOnSelect
      options={data.getAllAccounts.map((item) => ({
        value: item.id,
        label: item.business_dba,
      }))}
    />
  );
};

export default compose(GetAccountData)(Account);
