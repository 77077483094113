import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  YearDropdown,
  withEventYearFilterContext,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { AppContext } from 'shared/AppContext';
import { useFilter } from 'shared/filterContext';
import { FilterContainer } from '../AnnualTickets';
import AccountFilter from '../component/AccountFilter';
import AnnualTypeFilter from '../component/AnnualTypeFilter';
import EventFilter from '../component/EventFilter';
import { useGetAnnualTickets } from '../gql/UseGetAnnualTickets';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const AnnualPurchases = withEventYearFilterContext((props) => {
  const { theme } = props;
  const [search, setSearch] = useState('');

  const {
    purchaseAccountFilter,
    setPurchaseAccountFilter,
    purchaseAnnualType,
    setPurchaseAnnualType,
    purchaseAnnualEvent,
    setPurchaseAnnualEvent,
    purchaseYear,
    setPurchaseYear,
  } = useFilter();

  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState();

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const {
    state: { accountId },
  } = useContext(AppContext);

  const { res } = useGetAnnualTickets(
    userType === 'admin'
      ? purchaseAccountFilter
        ? purchaseAccountFilter.value
        : null
      : accountId,
    true,
    userType,
    purchaseAnnualType ? purchaseAnnualType : null,
    purchaseAnnualEvent ? purchaseAnnualEvent.value : null,
    purchaseYear ? +purchaseYear : null
  );

  useEffect(() => {
    if (res && res.getAnnualTickets) {
      setTickets(res?.getAnnualTickets?.results);
      setCount(res?.getAnnualTickets?.count);
    }
  }, [res]);

  const columns = [
    {
      label: 'Track / Series',
      key: 'track',
    },
    {
      label: 'Account',
      key: 'account',
    },
    {
      label: 'Annual / Season Ticket Name',
      key: 'name',
    },
  ];

  function renderRows(ticket) {
    const { id, tracks, series, account, name } = ticket;
    return {
      track:
        tracks && tracks.length > 0 ? tracks[0]?.name : series[0]?.name ?? '',
      account: account?.business_dba,
      name: (
        <Link
          to={
            userType === 'admin'
              ? `/admin/annualPurchases/purchase/${id}?ticketName=${name}&&accountName=${account?.business_dba}&&account_id=${account.id}`
              : `/admin-${userType}/annualPurchases/purchase/${id}?ticketName=${name}&&accountName=${account?.business_dba}&&account_id=${account.id}`
          }
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {name}</LineHeightText>
        </Link>
      ),
    };
  }

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  if (!tickets) return null;

  return (
    <>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Annual / Season
              </Text>

              <FilterContainer>
                <YearDropdown
                  customYear={purchaseYear}
                  onSelect={(year) => setPurchaseYear(year.value)}
                />
              </FilterContainer>

              <AccountFilter
                accountFilter={purchaseAccountFilter}
                setAccountFilter={setPurchaseAccountFilter}
              />

              {purchaseAccountFilter && (
                <AnnualTypeFilter
                  annualType={purchaseAnnualType}
                  setAnnualType={setPurchaseAnnualType}
                />
              )}

              {purchaseAnnualType &&
                purchaseAnnualType?.value === 'registration' && (
                  <EventFilter
                    annualEvent={purchaseAnnualEvent}
                    setAnnualEvent={setPurchaseAnnualEvent}
                    account_id={purchaseAccountFilter.value}
                  />
                )}

              <div style={{ marginRight: 15 }}>
                <SearchInput
                  placeholder="Search Tickets"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div>
            </TitleContainer>
          </ContainerWrapper>
        </ContainerHeader>

        <div style={{ padding: 20 }}>
          <Table items={tickets} columns={columns} renderRows={renderRows} />
          <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>
      </Container>
    </>
  );
});

export default withTheme(AnnualPurchases);
