import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useEventYearFilter } from 'components/YearDropdown';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNT_OPEN_CREDITS = gql`
  query GetUserOpenCreditsByAccount($input: GetUserCreditsInput!) {
    getUserOpenCreditsByAccount(input: $input) {
      count
      results {
        id
        user {
          id
          formatted_name
        }
        amount
        date
        issued_by {
          formatted_name
        }
        track_credit
        fee_credit
        original_amount
        transaction {
          id
          fee
          total
          refund
          subtotal
          discount
          credit
          transfer_id
          charge_id
          card {
            last4
          }
          purchase_date
          status
          user {
            id
            name
            first_name
            last_name
            formatted_name
          }
          users {
            promo
            user {
              id
              name
              first_name
              last_name
            }
            tickets {
              id
              name
              price
              refunded
              type
              barcode
              credited
            }
          }
          international_fee
        }
      }
    }
  }
`;

export function useGetUserOpenCreditsByAccount() {
  const { id: account_id, id2: user_id } = useParams();
  const { yearFilter } = useEventYearFilter();
  const { input: search } = useSearchInput();

  const result = usePersistedQuery(
    GET_ACCOUNT_OPEN_CREDITS,
    `user-open-credits-by-account-${account_id}-${user_id}`,
    {
      variables: {
        input: {
          account_id: Number(account_id),
          user_id: Number(user_id),
          ...(yearFilter ? { year: yearFilter } : null),
          ...search,
        },
      },
      fetchPolicy: 'cache-first',
    }
  );

  return { ...result, res: result.data };
}
