import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/UseGetAnnualCategories';

export default graphql(
  gql`
    mutation createAnnualType($input: CreateAnnualTypeInput!) {
      createAnnualType(input: $input) {
        id
        name
        account {
          id
          business_dba
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createAnnualType: async (input, accountFilter) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createAnnualType } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: {
                  input: {
                    ...search,
                    ...(accountFilter ? { accountFilter } : null),
                  },
                },
              });

              const sort = (createAnnualType) => {
                const newResults =
                  data.getAnnualTypesAdmin.results.concat(createAnnualType);

                newResults.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });

                return newResults;
              };

              proxy.writeQuery({
                query,
                variables: {
                  input: {
                    ...search,
                    ...(accountFilter ? { accountFilter } : null),
                  },
                },
                data: {
                  ...data,
                  getAnnualTypesAdmin: {
                    ...data.getAnnualTypesAdmin,
                    count: data.getAnnualTypesAdmin.count + 1,
                    results: sort(createAnnualType),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
