import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import { useEventYearFilter } from 'components/Events';
import { useSearchInput } from 'hooks/useSearchInput';

export const EVENTS_REPORT = gql`
  mutation GetEventsReport($input: GetEventsAdminInput!) {
    getEventsReport(input: $input) {
      count
      results {
        id
        fullDate
        start_date
        added_by
        end_date
        name
        eventDates
        admin_website_url
        admin_schedule_url
        date
        month
        listDates
        year
        day
        fullDate
        nextStartTime
        isMultiDay
        nextGateTime
        nextFullDate
        status
        sale_start
        track {
          id
          name
        }
        user {
          id
          first_name
          last_name
        }
        series {
          id
          name
        }
        status
        color_code
        isMultiDay
      }
    }
  }
`;

export function useGetEventsReport() {
  const {
    yearFilter,
    trackFilter,
    seriesFilter,
    accountFilter,
    startDateFilter,
    endDateFilter,
    startTransactionDateFilter,
    endTransactionDateFilter,
  } = useEventYearFilter();
  const { input: search } = useSearchInput();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [eventReportMutation] = useMutation(EVENTS_REPORT);

  const result = useCallback(
    (type, options = {}) => {
      return eventReportMutation({
        variables: {
          input: {
            ...search,
            ...(type === 'track' ? { track_id: Number(id) } : null),
            ...(type === 'series' ? { series_id: Number(id) } : null),
            ...(yearFilter &&
            pathname !== '/admin/events' &&
            !(
              (startDateFilter && endDateFilter) ||
              (startTransactionDateFilter && endTransactionDateFilter)
            )
              ? { year: yearFilter }
              : null),
            ...(trackFilter ? { track_id: trackFilter.value } : null),
            ...(seriesFilter ? { series_id: seriesFilter.value } : null),
            ...(accountFilter ? { account_id: accountFilter.value } : null),
            ...((startDateFilter && endDateFilter) ||
            (startTransactionDateFilter && endTransactionDateFilter)
              ? {
                  start_date:
                    pathname === '/admin/transactions'
                      ? startTransactionDateFilter
                      : startDateFilter,
                }
              : null),
            ...(endDateFilter || endTransactionDateFilter
              ? {
                  end_date:
                    pathname === '/admin/transactions'
                      ? endTransactionDateFilter
                      : endDateFilter,
                }
              : null),
            ...(type === 'events' || type === 'transaction'
              ? { show_drafts: false }
              : null),
          },
        },
        ...options,
      });
    },
    [
      eventReportMutation,
      id,
      pathname,
      search,
      yearFilter,
      trackFilter,
      seriesFilter,
      accountFilter,
      startDateFilter,
      endDateFilter,
      startTransactionDateFilter,
      endTransactionDateFilter,
    ]
  );
  return result;
}
