import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const query = gql`
  query Me($getUserEventsInput: GetAccountEventsInput!) {
    me {
      id
      track {
        id
        name
        logo
        cityAndState
      }
      series {
        id
        name
        logo
        website
        type {
          key
          value
        }
      }
      first_name
      last_name
    }
    getEventsByAccount(input: $getUserEventsInput) {
      count
      results {
        id
        name
        image
        date
        start_date
        fullDate
        nextStartTime
        series {
          id
          name
        }
      }
    }
  }
`;

export function useMe() {
  const {
    state: { accountId },
  } = useContext(AppContext);
  const result = usePersistedQuery(query, `ME-${accountId}`, {
    variables: {
      getUserEventsInput: {
        account_id: accountId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return result;
}
