import { Field, FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, RemoveButton } from 'components/Button';
import { FormWrapper } from 'components/Form/DraggableFields';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import Text from 'components/Text';

export const NoteList = (props) => {
  const { fieldName = 'notes', label = 'NOTES', buttonText = 'NOTE' } = props;
  const { values } = useFormikContext();
  const notes = values[fieldName] ?? [];

  return (
    <div>
      <Text type="label" color="#000">
        {label}
      </Text>
      <Spacer size={5} />
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => (
          <>
            {notes?.length > 0 &&
              notes.map((_, index) => (
                <div key={index} style={{ marginBottom: 20 }}>
                  <FormWrapper>
                    <RemoveButton
                      containerStyle={{ marginBottom: 5 }}
                      onClick={() => arrayHelpers.remove(index)}
                    />
                    <Field name={`${fieldName}.${index}.note`}>
                      {({ field, meta }) => {
                        const { touched, error } = meta;
                        const { value, onChange, onBlur, name } = field;
                        return (
                          <Input
                            inputStyle={{ height: 300 }}
                            as="textarea"
                            error={touched ? error : undefined}
                            id={name}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder="Enter Note"
                            value={value}
                          />
                        );
                      }}
                    </Field>
                  </FormWrapper>
                </div>
              ))}
            <Button
              type="button"
              onClick={() =>
                arrayHelpers.push({
                  note: '',
                })
              }
            >
              {`+ Add Another ${buttonText}`}
            </Button>
          </>
        )}
      />
    </div>
  );
};
NoteList.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
};
