import { Formik } from 'formik';
import React from 'react';
import { Checkbox } from 'components/Form/Checkbox';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  ResponsiveCol,
  ResponsiveRow,
} from 'pages/app/Events/AddEvents/styles';

const formValues = ['capabilities'];

const capabilityKeys = [
  'account_id',
  'events',
  'guests',
  'add_guests',
  'messages',
  'add_promos',
  'promos',
  'credits',
  'issue_credits',
  'transactions',
  'issue_refunds',
  'registrations',
  'customers',
  'memberships',
  'staff',
  'add_staff',
  'stripe',
  'scan_tickets',
  'check_tickets',
  'create_messages',
  'edit_registrations',
  'check_in_guests',
  'edit_memberships',
];

function Capabilities({ data, successMessage }) {
  let viewCapabilities = {};
  let viewRequest = false;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues.reduce((acc, value) => {
          if ('capabilities' === value) {
            acc[value] = data.me && data.me[value] ? data.me[value][0] : {};

            capabilityKeys.forEach((key) => {
              acc = { ...acc, [key]: '' };
              acc[key] = acc['capabilities'][key];
              if (!acc['capabilities'][key]) viewRequest = true;
            });
          }

          viewCapabilities = { ...acc };

          return acc;
        }, {})}
        validate={() => {
          const errors = {};

          return errors;
        }}
        onSubmit={async ({ setSubmitting }) => {
          setSubmitting(true);

          // const data = Object.entries(values).reduce((acc, entry) => {
          //   acc[entry[0]] = '' === entry[1] ? null : entry[1];
          //   if (entry[0] === 'cellphone') {
          //     if (acc[entry[0]].includes('+1'));
          //     acc[entry[0]] = acc[entry[0]].replaceAll('+1', '');
          //   }
          //   return acc;
          // }, {});

          // const response = await updateSelf(data);

          // setSubmitting(false);

          // if (!response || response.errors) {
          //   errorMessage();
          //   return setErrors(response.errors);
          // }

          successMessage();
        }}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Text fontSize={23} fontWeight="500" color="#3C4144">
              Allowed Capabilities
            </Text>

            <Spacer size={24} />
            <ResponsiveRow>
              <ResponsiveCol>
                {viewCapabilities.events && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="events"
                      check={!!values.events}
                      checked={!!values.events}
                      rightText="View Events"
                    />
                  </>
                )}
                {viewCapabilities.scan_tickets && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="scan_tickets"
                      check={!!values.scan_tickets}
                      checked={!!values.scan_tickets}
                      rightText="Scan Tickets"
                    />
                  </>
                )}
                {viewCapabilities.check_tickets && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="check_tickets"
                      check={!!values.check_tickets}
                      checked={!!values.check_tickets}
                      rightText="Manually Check-in Tickets"
                    />
                  </>
                )}
                {viewCapabilities.guests && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="guests"
                      check={!!values.guests}
                      checked={!!values.guests}
                      rightText="View Guests"
                    />
                  </>
                )}
                {viewCapabilities.add_guests && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="add_guests"
                      check={!!values.add_guests}
                      checked={!!values.add_guests}
                      rightText="Create Guests"
                    />
                  </>
                )}
                {viewCapabilities.create_messages && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="create_messages"
                      check={!!values.create_messages}
                      checked={!!values.create_messages}
                      rightText="Create Messages"
                    />
                  </>
                )}
                {viewCapabilities.messages && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="messages"
                      check={!!values.messages}
                      checked={!!values.messages}
                      rightText="View Messages"
                    />
                  </>
                )}
                {viewCapabilities.memberships && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="memberships"
                      check={!!values.memberships}
                      checked={!!values.memberships}
                      rightText="View Memberships"
                    />
                  </>
                )}
                {viewCapabilities.registrations &&
                  process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
                    <>
                      {' '}
                      <Spacer size={18} />
                      <Checkbox
                        name="registrations"
                        check={!!values.registrations}
                        checked={!!values.registrations}
                        rightText="Registrations"
                      />{' '}
                    </>
                  )}
                {viewCapabilities.edit_registrations &&
                  process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
                    <>
                      {' '}
                      <Spacer size={18} />
                      <Checkbox
                        name="edit_registrations"
                        check={!!values.edit_registrations}
                        checked={!!values.edit_registrations}
                        rightText="Edit Registrations"
                      />{' '}
                    </>
                  )}
              </ResponsiveCol>

              <ResponsiveCol>
                {viewCapabilities.promos && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="promos"
                      check={!!values.promos}
                      checked={!!values.promos}
                      rightText="View Promo Code"
                    />
                  </>
                )}
                {viewCapabilities.add_promos && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="add_promos"
                      check={!!values.add_promos}
                      checked={!!values.add_promos}
                      rightText="Create Promo Codes"
                    />
                  </>
                )}
                {viewCapabilities.credits && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="credits"
                      check={!!values.credits}
                      checked={!!values.credits}
                      rightText="View Customer Credits"
                    />
                  </>
                )}
                {viewCapabilities.issue_credits && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="issue_credits"
                      check={!!values.issue_credits}
                      checked={!!values.issue_credits}
                      rightText="Create Credits"
                    />
                  </>
                )}
                {viewCapabilities.transactions && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="transactions"
                      check={!!values.transactions}
                      checked={!!values.transactions}
                      rightText="View Transactions"
                    />
                  </>
                )}
                {viewCapabilities.issue_refunds && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="issue_refunds"
                      check={!!values.issue_refunds}
                      checked={!!values.issue_refunds}
                      rightText="Issue Refunds"
                    />
                  </>
                )}
                {viewCapabilities.stripe && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="stripe"
                      check={!!values.stripe}
                      checked={!!values.stripe}
                      rightText="Stripe Access"
                    />{' '}
                  </>
                )}
              </ResponsiveCol>

              <ResponsiveCol>
                {viewCapabilities.customers && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="customers"
                      check={!!values.customers}
                      checked={!!values.customers}
                      rightText="Customers"
                    />
                  </>
                )}
                {viewCapabilities.staff && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="staff"
                      check={!!values.staff}
                      checked={!!values.staff}
                      rightText="View Staff"
                    />
                  </>
                )}
                {viewCapabilities.add_staff && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="add_staff"
                      check={!!values.add_staff}
                      checked={!!values.add_staff}
                      rightText="Add Staff"
                    />{' '}
                  </>
                )}
              </ResponsiveCol>
            </ResponsiveRow>

            {viewRequest && (
              <>
                <Spacer size={35} />
                <Text fontSize={23} fontWeight="500" color="#3C4144">
                  Restricted Capabilities
                </Text>

                <Spacer size={24} />
              </>
            )}

            <ResponsiveRow>
              <ResponsiveCol>
                {!viewCapabilities.events && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="events"
                      inversed
                      check={!!values.events}
                      checked={!values.events}
                      rightText="View Events / Scan Tickets"
                    />
                  </>
                )}
                {!viewCapabilities.scan_tickets && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="scan_tickets"
                      inversed
                      check={!!values.scan_tickets}
                      checked={!values.scan_tickets}
                      rightText="Scan Tickets"
                    />
                  </>
                )}
                {!viewCapabilities.check_tickets && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="check_tickets"
                      inversed
                      check={!!values.check_tickets}
                      checked={!values.check_tickets}
                      rightText="Manually Check-in Tickets"
                    />
                  </>
                )}
                {!viewCapabilities.guests && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="guests"
                      inversed
                      check={!!values.guests}
                      checked={!values.guests}
                      rightText="View Guests"
                    />
                  </>
                )}
                {!viewCapabilities.add_guests && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="add_guests"
                      inversed
                      check={!!values.add_guests}
                      checked={!values.add_guests}
                      rightText="Create Guests"
                    />
                  </>
                )}
                {!viewCapabilities.create_messages && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="create_messages"
                      inversed
                      check={!!values.create_messages}
                      checked={!values.create_messages}
                      rightText="Create Messages"
                    />
                  </>
                )}
                {!viewCapabilities.messages && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="messages"
                      inversed
                      check={!!values.messages}
                      checked={!values.messages}
                      rightText="View Messages"
                    />
                  </>
                )}
                {!viewCapabilities.memberships && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="memberships"
                      inversed
                      check={!!values.memberships}
                      checked={!values.memberships}
                      rightText="View Memberships"
                    />
                  </>
                )}
                {!viewCapabilities.registrations &&
                  process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
                    <>
                      {' '}
                      <Spacer size={18} />
                      <Checkbox
                        name="registrations"
                        inversed
                        check={!!values.registrations}
                        checked={!values.registrations}
                        rightText="Registrations"
                      />{' '}
                    </>
                  )}
                {!viewCapabilities.edit_registrations &&
                  process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
                    <>
                      {' '}
                      <Spacer size={18} />
                      <Checkbox
                        name="edit_registrations"
                        inversed
                        check={!!values.edit_registrations}
                        checked={!values.edit_registrations}
                        rightText="Edit Registrations"
                      />{' '}
                    </>
                  )}
              </ResponsiveCol>

              <ResponsiveCol>
                {!viewCapabilities.promos && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="promos"
                      inversed
                      check={!!values.promos}
                      checked={!values.promos}
                      rightText="View Promo Code"
                    />
                  </>
                )}
                {!viewCapabilities.add_promos && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="add_promos"
                      inversed
                      check={!!values.add_promos}
                      checked={!values.add_promos}
                      rightText="Create Promo Codes"
                    />
                  </>
                )}
                {!viewCapabilities.credits && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="credits"
                      inversed
                      check={!!values.credits}
                      checked={!values.credits}
                      rightText="View Customer Credits"
                    />
                  </>
                )}
                {!viewCapabilities.issue_credits && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="issue_credits"
                      inversed
                      check={!!values.issue_credits}
                      checked={!values.issue_credits}
                      rightText="Create Credits"
                    />
                  </>
                )}
                {!viewCapabilities.transactions && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="transactions"
                      inversed
                      check={!!values.transactions}
                      checked={!values.transactions}
                      rightText="View Transactions"
                    />
                  </>
                )}
                {!viewCapabilities.issue_refunds && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="issue_refunds"
                      inversed
                      check={!!values.issue_refunds}
                      checked={!values.issue_refunds}
                      rightText="Issue Refunds"
                    />
                  </>
                )}
                {!viewCapabilities.stripe && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="stripe"
                      inversed
                      check={!!values.stripe}
                      checked={!values.stripe}
                      rightText="Stripe Access"
                    />{' '}
                  </>
                )}
              </ResponsiveCol>

              <ResponsiveCol>
                {!viewCapabilities.customers && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="customers"
                      inversed
                      check={!!values.customers}
                      checked={!values.customers}
                      rightText="Customers"
                    />
                  </>
                )}
                {!viewCapabilities.staff && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="staff"
                      inversed
                      check={!!values.staff}
                      checked={!values.staff}
                      rightText="View Staff"
                    />
                  </>
                )}
                {!viewCapabilities.add_staff && (
                  <>
                    {' '}
                    <Spacer size={18} />
                    <Checkbox
                      name="add_staff"
                      inversed
                      check={!!values.add_staff}
                      checked={!values.add_staff}
                      rightText="Add Staff"
                    />{' '}
                  </>
                )}
              </ResponsiveCol>
            </ResponsiveRow>

            <Spacer size={24} />
          </form>
        )}
      </Formik>
    </>
  );
}

export default Capabilities;
