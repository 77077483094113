import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
// import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  YearDropdown,
  withEventYearFilterContext,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { useFilter } from 'shared/filterContext';
import DeleteAnnualTicket from './AddAnnualTickets/gql/DeleteAnnualTicket';
import { useDuplicateAnnual } from './AddAnnualTickets/gql/DuplicateAnnualTicket';
import AccountFilter from './component/AccountFilter';
import AnnualTypeFilter from './component/AnnualTypeFilter';
import EventFilter from './component/EventFilter';
import { useGetAllAnnualTickets } from './gql/UseGetAllAnnualTickets';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

export const FilterContainer = styled.div`
  min-width: 200px;
  margin-inline: 5px;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  height: 80%;
`;

const AnnualTickets = withEventYearFilterContext((props) => {
  const { theme, history, deleteAnnualTicket } = props;
  const [search, setSearch] = useState('');

  const {
    accountFilter,
    setAccountFilter,
    annualType,
    setAnnualType,
    annualEvent,
    setAnnualEvent,
    year,
    setYear,
  } = useFilter();

  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState();

  const { res } = useGetAllAnnualTickets(
    accountFilter ? accountFilter.value : null,
    null,
    null,
    annualType ? annualType : null,
    annualEvent ? annualEvent.value : null,
    year ? +year : null
  );

  const duplicateAnnual = useDuplicateAnnual();

  useEffect(() => {
    if (res && res.getAllAnnualTickets) {
      setTickets(res?.getAllAnnualTickets?.results);
      setCount(res?.getAllAnnualTickets?.count);
    }
  }, [res]);

  const columns = [
    {
      label: 'Track / Series',
      key: 'track',
    },
    {
      label: 'Account',
      key: 'account',
    },
    {
      label: 'Annual / Season Ticket Name',
      key: 'name',
    },
    {
      label: 'Event',
      key: 'event',
    },
    {
      label: 'Off Sale',
      key: 'offSale',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(ticket) {
    const {
      id,
      tracks,
      series,
      account,
      name,
      ticket: tick,
      status,
      events,
    } = ticket;
    return {
      track:
        tracks && tracks.length > 0 ? tracks[0]?.name : series[0]?.name ?? '',
      account: account?.business_dba,
      name: (
        <Text
          style={{
            color: theme.colors.primary,
            fontSize: 16,
          }}
        >
          <LineHeightText>{name}</LineHeightText>
        </Text>
      ),
      event: events && events.length > 0 ? events[0]?.name : '',
      offSale: tick?.sale_end
        ? moment(tick?.sale_end).format('ddd MMM D - YYYY').toUpperCase()
        : '',
      status: status,
      actions: (
        <div style={{ width: 91 }}>
          <Icon
            icon="edit"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              history.push(`/admin/editAnnualTicket/${id}`);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              if (
                window.confirm(
                  'Are you sure you want to delete this Annual Ticket?'
                )
              ) {
                const response = await deleteAnnualTicket(id, accountFilter);
                if (response && !response.errors) {
                  toast.success('Annual Ticket Deleted Successfully');
                } else {
                  toast.error('Error deleting Annual Ticket');
                }
              } else {
                return;
              }
            }}
          />
          <Icon
            icon="duplicate"
            size={22}
            color={theme.colors.primary}
            onClick={async () => {
              if (
                window.confirm(
                  'Are you sure you want to duplicate this Annual Ticket?'
                )
              ) {
                const response = await duplicateAnnual(id, {
                  ...(accountFilter
                    ? { accountFilter: accountFilter.value }
                    : null),
                  ...(annualType ? { annualType: annualType.value } : null),
                  ...(annualEvent ? { event_id: annualEvent.value } : null),
                });
                if (!response || response.errors) {
                  toast.error('Could not duplicate this Annual Ticket');
                } else {
                  toast.success('Annual Ticket successfully duplicated');
                }
              }
            }}
            padding="0 0 0 15px"
          />
        </div>
      ),
    };
  }

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  if (!tickets) return null;

  return (
    <>
      <Container>
        <ContainerHeader style={{ height: 140, gap: 10 }}>
          <ContainerWrapper>
            <TitleContainer
              style={{ justifyContent: 'space-between', width: '100%' }}
            >
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Annual / Season
              </Text>

              <div style={{ marginRight: 15 }}>
                <SearchInput
                  placeholder="Search Tickets"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div>

              <CreditButton>
                <Button
                  onClick={() => history.push('/admin/annualTickets/add')}
                  buttonStyle={{ height: 35 }}
                >
                  {'+ Annual / Season'}
                </Button>
              </CreditButton>
            </TitleContainer>

            <TitleContainer>
              <FilterContainer>
                <YearDropdown
                  customYear={year}
                  onSelect={(year) => setYear(year.value)}
                />
              </FilterContainer>

              <AccountFilter
                accountFilter={accountFilter}
                setAccountFilter={setAccountFilter}
              />

              {accountFilter && (
                <AnnualTypeFilter
                  annualType={annualType}
                  setAnnualType={setAnnualType}
                  isTicket={true}
                />
              )}

              {annualType && annualType?.value === 'registration' && (
                <EventFilter
                  annualEvent={annualEvent}
                  setAnnualEvent={setAnnualEvent}
                  account_id={accountFilter.value}
                />
              )}
            </TitleContainer>
          </ContainerWrapper>
        </ContainerHeader>

        <div style={{ padding: 20 }}>
          <Table items={tickets} columns={columns} renderRows={renderRows} />
          <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>
      </Container>
    </>
  );
});

export default withTheme(DeleteAnnualTicket(AnnualTickets));
