import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/UseGetCategories';

export default graphql(
  gql`
    mutation createCategory($input: CreateCategoryInput!) {
      createCategory(input: $input) {
        id
        name
        type
        icon_id
        icon
        code
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createCategory: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createCategory } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: {
                  input: {
                    ...search,
                  },
                },
              });

              const sort = (createCategory) => {
                const newResults =
                  data.getCategories.results.concat(createCategory);

                newResults.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });

                return newResults;
              };

              proxy.writeQuery({
                query,
                variables: {
                  input: {
                    ...search,
                  },
                },
                data: {
                  ...data,
                  getCategories: {
                    ...data.getCategories,
                    count: data.getCategories.count + 1,
                    results: sort(createCategory),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
