import React from 'react';
import { AutoSuggest } from 'components/Form/AutoSuggest';

export const MembershipDropdown = ({
  memberships,
  typeId,
  setTypeId,
  label,
}) => {
  return (
    <AutoSuggest
      label={label && label}
      options={[{ label: 'All Members', value: null }].concat(
        memberships.map((ticket) => ({
          label: ticket.name,
          value: ticket.id,
        }))
      )}
      value={typeId ? typeId : { label: 'All Members', value: null }}
      isSearchable
      isClearable
      onChange={(ticket) => {
        setTypeId(ticket ?? null);
      }}
    />
  );
};
