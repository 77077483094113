import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useState } from 'react';
import 'jspdf-autotable';
import styled from 'styled-components';
import { PrintButton } from 'components/PrintButton';
import { useGetTicketSalesReport } from 'components/Transactions/gql/useGetEventTicketSales.mutation';
import checkbox from '../TransactionsPrintList/checkbox.png';
import { KartPassLogo } from '../TransactionsPrintList/kartpass-logo';
import { Logo } from '../TransactionsPrintList/logo';
import { TicketHossLogo } from '../TransactionsPrintList/tickethoss-logo';
import 'moment-timezone';

const ButtonContainer = styled.div`
  & > button {
    width: 100%;

    @media screen and (min-width: 861px) {
      width: auto;
    }
  }
`;

// const setPriceLine = (transactions) => {
//   const priceLines = transactions
//     .map((user, index) => {
//       return `${user.tickets
//         .map((ticket, ticketIndex) => {
//           return index === 0 && ticket.name.length > 37
//             ? `${'\n'}${ticket.price}${'\n'}`
//             : ticket.name.length > 37
//             ? `${'\n'}${'\n'}${ticket.price}${'\n'}`
//             : index === 0
//             ? `${'\n'}${ticket.price}`
//             : ticketIndex === 0
//             ? `${'\n'}${'\n'}${ticket.price}`
//             : `${'\n'}${ticket.price}`;
//         })
//         .join('\n')}`;
//     })
//     .join('\n');
//   return priceLines;
// };

const getHeaders = () => {
  return [['Name', 'Price', 'Quantity']];
};

const getColumns = () => {
  return [
    { dataKey: 'name', header: 'Name' },
    { dataKey: 'price', header: 'Price' },
    { dataKey: 'qty', header: 'Quantity' },
  ];
};

const buildData = (ticketSales) => {
  return ticketSales.map((sales) => [sales.name, sales.price, sales.qty]);
};

export const TicketReportPrint = ({ date, eventId, text, textStyle }) => {
  const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const getEventTransactions = useGetTicketSalesReport();
  const [disabled] = useState(false);

  const getData = () => {
    return getEventTransactions(eventId)
      .then((response) => {
        const eventTransaction = response.data.getTicketSalesReport;
        return eventTransaction;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const exportPDF = async () => {
    const eventTransaction = await getData();
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const event = eventTransaction.event;

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const title = date;

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(title, pageWidth - 650, {});

    const trackOrSeriesName =
      event.track?.name ??
      (event.series?.length > 0 ? event.series[0].name : '');
    doc.text(text, 40, 40);
    doc.text(
      event?.name.length > 75 ? event?.name.slice(0, 75) : event?.name,
      40,
      60
    );
    let Y = 60;
    if (event?.name.length > 75) {
      Y += 20;
      doc.text(event?.name.slice(75, 150), 40, Y);
    }

    if (event?.account_name) {
      Y += 20;
      doc.text(event?.account_name, 40, Y);
    }

    if (trackOrSeriesName) {
      Y += 20;
      doc.text(trackOrSeriesName, 40, Y);
    }

    Y += 20;
    doc.text(`Total - ${eventTransaction.total}`, 40, Y);

    Y += 20;
    doc.text(`Credits Redeemed - ${eventTransaction.credits}`, 40, Y);

    Y += 20;
    doc.text(`Discounts - ${eventTransaction.discounts}`, 40, Y);

    Y += 20;

    doc.setTextColor('#fa4616');

    doc.setTextColor('#000');

    doc.text('Ticket Breakdown Report', 620, 45);
    Y += 30;

    if (checkbox) {
      if (isKartPass) {
        doc.addImage(KartPassLogo, 'PNG', 650, 50, 100, 75, '', 'FAST');
      } else if (isTicketHoss) {
        doc.addImage(TicketHossLogo, 'PNG', 660, 65, 100, 54, '', 'FAST');
      } else {
        doc.addImage(Logo, 'PNG', 660, 50, 100, 100, '', 'FAST');
      }
    }

    const headers = getHeaders();

    const data1 = buildData(eventTransaction.results);

    doc.autoTable({
      startY: Y,
      rowPageBreak: 'avoid',
      columns: getColumns(eventTransaction.event.hide_fee),
      head: headers,
      body: data1,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: `${date} - ${event.name} - Ticket Breakdown Report.pdf`,
      subject: `${date} - ${event.name} - Ticket Breakdown Report`,
      author: process.env.REACT_APP_PLATFORM,
    });
    return {
      ...doc,
      fileName: `${date} - ${event.name} - Ticket Breakdown Report.pdf`,
    };
  };

  return (
    <ButtonContainer>
      <PrintButton
        buildPdf={exportPDF}
        text={text}
        disabled={disabled}
        variant={'minimal'}
        textStyle={textStyle}
      />
    </ButtonContainer>
  );
};
