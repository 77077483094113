import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { AppContext } from 'shared/AppContext';

export const query = gql`
  query GetTrackTotalOpenCredits($account_id: Int!) {
    getTrackTotalOpenCredits(account_id: $account_id)
  }
`;

export function useGetTrackTotalOpenCredits() {
  const {
    state: { accountId },
  } = useContext(AppContext);

  return useQuery(query, { variables: { account_id: accountId } });
}
