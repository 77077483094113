import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/queries/get-accounts';

export default graphql(
  gql`
    mutation UpdateAccount($input: UpdateAccountInput!) {
      updateAccount(input: $input) {
        business_dba
        legal_name
        primary_contact
        email
        title
        country_code
        calling_code
        phone
        track {
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        mailing {
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        shipping {
          address_line_1
          address_line_2
          city
          state
          zipcode
        }
        track_ids
        series_ids
        stripe_account_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateAccount: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateAccount } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: { input: search },
              });

              const sort = (updateAccount) => {
                const newResults =
                  data.getAccounts.results.concat(updateAccount);

                newResults.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });

                return newResults;
              };

              proxy.writeQuery({
                query,
                variables: { input: search },
                data: {
                  ...data,
                  getAccounts: {
                    ...data.getAccounts,
                    count: data.getAccounts.count + 1,
                    results: sort(updateAccount),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
