import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import Icon from 'components/Icon';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import { errorMessage, successMessage } from 'shared/alerts';
import { AppContext } from 'shared/AppContext';
import { formatPhoneNumber } from 'shared/formatters';
import PermissionModal from '../PermissionModal/PermissionModal';
import Modal from './employeeModal';
import DeleteEmployee from './gql/mutations/delete-employee';
import { GetEmployees } from './gql/queries/get-employees';

const Employees = (props) => {
  const {
    state: { capabilities, accountId },
  } = useContext(AppContext);

  const [employeeModal, setEmployeeModal] = useState(false);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const { data } = GetEmployees(props.search);

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  if (!data) return null;

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Phone',
      key: 'cellphone',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(employee) {
    const { id, formatted_name, email, cellphone } = employee;
    return {
      name: `${formatted_name ? `${formatted_name}` : ''}`,
      email,
      cellphone: formatPhoneNumber(cellphone),
      actions: (
        <>
          <Icon
            icon="edit"
            size={18}
            color={props.theme.colors.primary}
            onClick={async () => {
              if (!capabilities || capabilities?.add_staff)
                props.history.push(
                  `/admin-${userType}/employees/editEmployee/${id}`
                );
              else setShowPermissionModal(true);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={18}
            color={props.theme.colors.primary}
            onClick={async () => {
              if (!capabilities || capabilities?.add_staff) {
                if (
                  window.confirm('Are you sure you want to delete this user?')
                ) {
                  const response = await props.deleteEmployee(id, accountId);
                  if (response && !response.errors)
                    successMessage('staff deleted successfully');
                  else {
                    if (response.errors) {
                      errorMessage(response.errors.message);
                    } else errorMessage('Error deleting staff');
                  }
                }
              } else setShowPermissionModal(true);
            }}
          />
        </>
      ),
    };
  }

  return data.loading ? null : (
    <>
      <SearchableListContainer
        onAddClick={
          !capabilities || capabilities?.add_staff
            ? () =>
                props.history.push(`/admin-${userType}/employees/addEmployee`)
            : () => setShowPermissionModal(true)
        }
        pageCount={data.getEmployeesV2.count}
        paginated
        searchInputPlaceholder="Search Staff"
        title="Staff"
      >
        <Table
          items={data.getEmployeesV2.results}
          columns={columns}
          renderRows={renderRows}
        />
      </SearchableListContainer>
      {/* <EmployeeEdit
        isVisible={isVisible}
        handleOutClick={handleOutClick}
        currentEmployee={currentEmployee}
        employeeModal={() => {
          setEmployeeModal(true);
        }}
      /> */}
      {employeeModal ? (
        <Modal closeModal={() => setEmployeeModal(false)} />
      ) : null}
      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={props.history}
      />
    </>
  );
};

export default compose(DeleteEmployee)(withTheme(Employees));
