import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import { useUpdateRegistration } from 'components/Registrations/gql';
import Text from 'components/Text';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;

  @media (max-width: 768px) {
    display: flex;
    padding: 30px;
    flex-direction: column;
  }
`;

export const GridItem = styled.div`
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const ButtonWrapper = styled.div`
  width: 25%;
  margin-left: auto;
  margin-right: 30px;
  min-width: 150px;
`;

const upperCase = (str) => {
  var splitStr = str.toLowerCase().split('_');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

const getFormInput = (data, keys) => {
  const input = {
    id: data.id,
    fields: keys.map((key) => ({ name: key, value: data[key] })),
  };

  return input;
};

const FormsRepeater = ({ formData }) => {
  const formRef = useRef();
  const updateRegistration = useUpdateRegistration();
  const theme = useTheme();

  const formKeys = formData.data.map((data) => data.name);

  const [isSubmitting, setIsSubmitting] = useState(false);

  function getInitialValues(data, keys) {
    const blankValues = {
      ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
    };

    const initialValues = !data
      ? blankValues
      : {
          ...data.reduce((formValues, input) => {
            return {
              ...formValues,
              [input.name]: input.value,
            };
          }, {}),
          id: formData.id,
        };

    return initialValues;
  }

  const successMessage = () => toast.success('Form updated Successfully');

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getInitialValues(formData.data, formKeys)}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formRef}
      validate={() => {
        const errors = {};

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setIsSubmitting(true);

        const data = getFormInput(values, formKeys);
        const response = await updateRegistration(data);

        if (!response || response.errors) {
          setSubmitting(false);
          return setErrors(response.errors);
        } else {
          successMessage();
          setSubmitting(false);
        }

        setIsSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ padding: 10, marginTop: 20 }}>
          <Text
            type="heading"
            color={theme.colors.primary}
            inlineStyle={{ marginLeft: 30 }}
          >
            {formData.name}
          </Text>
          <Grid>
            {formData.data.map((data, index) => (
              <GridItem key={index}>
                <Input
                  id={data.name}
                  name={data.name}
                  label={upperCase(data.name)}
                  onChange={handleChange ? handleChange : ''}
                  onBlur={handleBlur ? handleBlur : ''}
                  value={values[data.name] ? values[data.name] : ''}
                  error={errors[data.name] ? errors[data.name] : ''}
                />
              </GridItem>
            ))}
          </Grid>
          <ButtonWrapper>
            <Button type="submit" disabled={isSubmitting} block>
              Update Form
            </Button>
          </ButtonWrapper>
        </form>
      )}
    </Formik>
  );
};

export default FormsRepeater;
