import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useTransactionDateFilter } from 'components/Transactions/TransactionDateFilter';

export const ANNUAL_PURCHASE_REPORT = gql`
  mutation GetAnnualPurchaseReport($input: AnnualPurchaseListInput!) {
    getAnnualPurchaseListV2(input: $input) {
      num_transactions
      num_tickets
      num_participants
      gross_sales
      net_sales
      marketing_fund
      label
      data {
        type
        total
        data {
          id
          purchaser
          total
          subtotal
          refund
          discount
          credit
          fee
          purchase_date
          ticket_holders {
            name
            tickets {
              name
              price
            }
          }
          participants {
            name
            tickets {
              name
              price
            }
          }
        }
      }
    }
  }
`;

export function useGetAnnualPurchaseReport(account_id) {
  const { id } = useParams();
  const { startDateFilter, endDateFilter } = useTransactionDateFilter();
  const [annualPurchaseMutation] = useMutation(ANNUAL_PURCHASE_REPORT);

  const result = useCallback(() => {
    return annualPurchaseMutation({
      variables: {
        input: {
          ticket_id: Number(id),
          ...(startDateFilter ? { start_date: startDateFilter } : null),
          ...(endDateFilter ? { end_date: endDateFilter } : null),
          account_id: +account_id,
        },
      },
    });
  }, [annualPurchaseMutation, endDateFilter, id, startDateFilter, account_id]);
  return result;
}
