import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetAllInfos {
    getAllInfos {
      count
      results {
        id
        name
      }
    }
  }
`;

export default graphql(query);
