import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { GET_ANNUAL_TICKETS } from '../../gql/UseGetAnnualTickets';

export default graphql(
  gql`
    mutation DeleteAnnualTicket($id: Int!) {
      deleteAnnualTicket(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteAnnualTicket: async (id, accountFilter) => {
        return mutator(() =>
          mutate({
            variables: { id },
            update: (proxy, { data: { deleteAnnualTicket } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              if (!deleteAnnualTicket) {
                return;
              }

              const data = proxy.readQuery({
                query: GET_ANNUAL_TICKETS,
                variables: {
                  getAnnualTicketsInput: {
                    ...search,
                    ...(accountFilter ? { accountFilter } : null),
                  },
                },
              });

              proxy.writeQuery({
                query: GET_ANNUAL_TICKETS,
                variables: {
                  getAnnualTicketsInput: {
                    ...search,
                    ...(accountFilter ? { accountFilter } : null),
                  },
                },
                data: {
                  ...data,
                  getAnnualTickets: {
                    ...data.getAnnualTickets,
                    count: data.getAnnualTickets.count - 1,
                    results: data.getAnnualTickets.results.filter(
                      (annual_ticket) => annual_ticket.id !== id
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
