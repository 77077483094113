import { gql } from 'graphql-tag';
import { usePersistedQuery } from 'hooks/usePersistedQuery';

export const GET_PROMOTER_METRICS = gql`
  query GetPromoterMetrics {
    getPromoterMetrics {
      favorites
      passes
      venue
    }
  }
`;

export function useGetPromoterMetrics() {
  return usePersistedQuery(GET_PROMOTER_METRICS, 'get-promoter-metrics');
}
