import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useGetUserAccounts } from 'components/AccountsDropdown/useGetUserAccounts';
import { Button } from 'components/Button';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Input } from 'components/Form/Input';
import { ErrorText, Label } from 'components/Form/styles';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  ResponsiveCol,
  ResponsiveRow,
} from 'pages/app/Events/AddEvents/styles';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import EmployeeSearch from '../../track/Employees/EmployeeEdit/employee-search';
import { ModalContainer } from '../Users/style';
import { useCreateCredit } from './gql/queries/useCreateCredit';
import { useGetAllAccounts } from './gql/queries/useGetAllAccounts';

const formValues = {
  credit_amount: '',
  employee: '',
  account_id: '',
  cellphone: '',
  first_name: '',
  last_name: '',
  email: '',
  ownership: '',
  country_code: '',
  calling_code: '',
};

export const AlignmentContainer = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-around';
  margin-top: 10;
  margin-bottom: 10;
`;

export const IssueCreditModal = ({
  isVisible,
  setIsVisible,
  isTrackAdmin,
  viewEmployeeSearch = true,
  customerId,
  credits,
  setCredits,
  history,
}) => {
  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';
  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [creditAmount, setCreditAmount] = useState('');
  const [creditMessage, setCreditMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cellphone, setCellPhone] = useState('');
  const [accountId, setAccountId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [showForm, setShowForm] = useState(false);
  const createCredit = useCreateCredit();
  const [finalModal, setFinalModal] = useState(false);

  const resultTrack = useGetUserAccounts();
  const resultAdmin = useGetAllAccounts();
  const { data, loading } = isTrackAdmin ? resultTrack : resultAdmin;

  const handleMessageChange = (e) => setCreditMessage(e.target.value);

  if (capabilities && !capabilities?.issue_credits)
    return (
      <PermissionModal
        isVisible={isVisible}
        hideModal={() => setIsVisible(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    );
  return !finalModal ? (
    <Modal
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      maxWidth={600}
    >
      <ModalContainer>
        <Formik
          initialValues={formValues}
          validate={(values) => {
            const errors = {};

            if (!values.credit_amount) {
              errors.credit_amount = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            setCreditAmount(Number(values.credit_amount));
            setAccountId(
              values.account_id
                ? values.account_id.value
                : isTrackAdmin
                ? data.getUserAccounts[0].id
                : ''
            );
            if (customerId) setUserId(customerId);
            else setUserId(values.user_id);

            setSubmitting(false);
            resetForm();
            setFinalModal(true);
            setIsVisible(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                {viewEmployeeSearch ? (
                  <div>
                    <Label
                      style={{
                        fontWeight: '600',
                        fontSize: 20,
                        marginLeft: 10,
                        whiteSpace: 'normal',
                      }}
                    >
                      Enter the Person's Mobile Number
                    </Label>
                    <Spacer size={18} />
                    <div style={{ width: '70%', margin: 'auto' }}>
                      <EmployeeSearch
                        value={values.employee}
                        showForm={(cellphone) => {
                          setFieldValue('cellphone', cellphone);
                          setCellPhone(cellphone);
                          setShowForm(true);
                        }}
                        hideForm={() => setShowForm(false)}
                        setFieldValue={setFieldValue}
                        showSuggestion={false}
                      />
                    </div>
                    {showForm ? (
                      <div style={{ width: '70%', margin: 'auto' }}>
                        <Spacer size={18} />
                        <ResponsiveRow>
                          <ResponsiveCol>
                            <Input
                              id="first_name"
                              label="First Name"
                              placeholder="First Name"
                              onChange={(event) => {
                                setFirstName(event.target.value);
                              }}
                              onBlur={handleBlur}
                              value={firstName}
                              error={
                                errors.first_name &&
                                touched.first_name &&
                                errors.first_name
                              }
                            />
                          </ResponsiveCol>
                        </ResponsiveRow>
                        <Spacer size={18} />
                        <ResponsiveRow>
                          <ResponsiveCol>
                            <Input
                              id="last_name"
                              label="Last Name"
                              placeholder="Last Name"
                              onChange={(event) => {
                                setLastName(event.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.lastName}
                              error={
                                errors.last_name &&
                                touched.last_name &&
                                errors.last_name
                              }
                            />
                          </ResponsiveCol>
                        </ResponsiveRow>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <Spacer size={18} />
                {loading ? null : isTrackAdmin ? (
                  data.getUserAccounts.length === 1 ? null : (
                    <div style={{ width: '70%', margin: 'auto' }}>
                      <AutoSuggest
                        name="account_id"
                        label="Select the Account for this Credit"
                        inlineStyle={{ fontSize: 20 }}
                        isMulti={false}
                        isClearable
                        error={
                          errors['account_id'] &&
                          touched['account_id'] &&
                          errors['account_id']
                        }
                        value={values['account_id']}
                        touched={touched['account_id']}
                        onChange={(value) => {
                          setFieldValue('account_id', value);
                        }}
                        onBlur={() => {
                          return;
                        }}
                        closeMenuOnSelect
                        options={data.getUserAccounts.map((item) => ({
                          value: item.id,
                          label: item.business_dba,
                        }))}
                      />
                      {errors['account_id'] && (
                        <ErrorText fontSize={12}>
                          {errors['account_id']}
                        </ErrorText>
                      )}
                    </div>
                  )
                ) : (
                  <div style={{ width: '70%', margin: 'auto' }}>
                    <AutoSuggest
                      name="account_id"
                      label="Select the Account for this Credit"
                      isMulti={false}
                      isClearable
                      error={
                        errors['account_id'] &&
                        touched['account_id'] &&
                        errors['account_id']
                      }
                      value={values['account_id']}
                      touched={touched['account_id']}
                      onChange={(value) => {
                        setFieldValue('account_id', value);
                      }}
                      onBlur={() => {
                        return;
                      }}
                      closeMenuOnSelect
                      options={data.getAllAccounts.map((item) => ({
                        value: item.id,
                        label: item.business_dba,
                      }))}
                    />
                    {errors['account_id'] && (
                      <ErrorText fontSize={12}>
                        {errors['account_id']}
                      </ErrorText>
                    )}
                  </div>
                )}
                <Spacer size={18} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    marginBottom: 10,
                  }}
                >
                  <Text
                    inlineStyle={{
                      display: 'flex',
                      fontWeight: '600',
                      fontSize: 20,
                      marginLeft: 10,
                      whiteSpace: 'normal',
                    }}
                  >
                    Credit $ Amount
                  </Text>
                  <AlignmentContainer>
                    <Input
                      id="credit_amount"
                      name="credit_amount"
                      onChange={handleChange}
                      value={values.credit_amount}
                      onBlur={handleBlur}
                      error={
                        errors.message && touched.message && errors.message
                      }
                    />
                  </AlignmentContainer>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  <Button type="submit" disabled={isSubmitting}>
                    Continue
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </ModalContainer>
    </Modal>
  ) : (
    <Modal
      isVisible={finalModal}
      hideModal={() => setFinalModal(false)}
      maxWidth={600}
    >
      <ModalContainer>
        <AlignmentContainer
          style={{
            width: '80%',
            margin: 'auto',
          }}
        >
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 22,
              whiteSpace: 'normal',
            }}
          >
            Include Personalized message
          </Text>
          <Spacer size={20} />
          <Input
            inputStyle={{ height: 200, fontSize: 18 }}
            id="credit_message"
            as="textarea"
            onChange={handleMessageChange}
          />
          <Spacer size={20} />
          <AlignmentContainer style={{ margin: 'auto', width: '95%' }}>
            <Text
              inlineStyle={{
                fontWeight: '600',
                fontSize: 20,
                whiteSpace: 'normal',
                lineHeight: 1.2,
              }}
            >
              A text Message will be sent notifying them of their credit, with
              instructions on how to use the credit.
            </Text>
          </AlignmentContainer>
        </AlignmentContainer>
        <Spacer size={20} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: '10px 0',
          }}
        >
          <Button
            onClick={async () => {
              try {
                const response = await createCredit(
                  userId,
                  accountId,
                  creditAmount,
                  creditMessage ?? '',
                  firstName ?? '',
                  lastName ?? '',
                  cellphone
                );
                if (!response || response.errors) {
                  toast.error('Credit could not be issued');
                  return;
                }
                if (response) {
                  toast.success('Credit has been issued successfully');
                  if (credits)
                    setCredits([...credits, response?.data?.createCredit]);
                  setFinalModal(false);
                } else {
                  toast.error('Credit could not be issued');
                }
              } catch (e) {}
            }}
          >
            ISSUE CREDIT
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};
