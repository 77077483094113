import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchInput } from 'hooks/useSearchInput';
import { RegistrationValuesFragment } from './registrations.fragments';

export const GET_REGISTRATIONS_REPORT = gql`
  mutation GetRegistrationsReport(
    $getTrackTransactionsInput: EventRegistrationsInput!
  ) {
    getRegistrationsReport(input: $getTrackTransactionsInput) {
      count
      results {
        ...registrationValues
      }
    }
  }
  ${RegistrationValuesFragment}
`;

export function useGetRegistrationsReport() {
  const [getEventRegistration] = useMutation(GET_REGISTRATIONS_REPORT);
  const { id } = useParams();
  const { input } = useSearchInput();

  const result = useCallback(
    (ticket_id, division) => {
      return getEventRegistration({
        variables: {
          getTrackTransactionsInput: {
            event_id: Number(id),
            division: division ? division : null,
            page: input.page,
            ...(ticket_id && ticket_id !== 'All Registrations'
              ? { ticket_id: Number(ticket_id) }
              : null),
            ...input,
          },
        },
      });
    },
    [getEventRegistration, id, input]
  );
  return result;
}
