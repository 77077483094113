import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { EditTicketFieldsFragment } from './Events.fragments';
import { GET_EVENTS_ADMIN } from './Events.queries';

export default graphql(
  gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      ...editTicketFields
    }
  }
  ${EditTicketFieldsFragment}
  `,
  {
    props: ({ mutate }) => ({
      createEvent: async (input, type, object_id) => {
        return mutator(() =>
          mutate({
            variables: { input },
          update: (cache, { data: { createEvent } }) => {
            const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true
              });

            const variables = {
              input: {
                ...search,
                ...(type === 'track' ? { track_id: Number(object_id) } : null),
                ...(type === 'series'
                  ? { series_id: Number(object_id) }
                  : null),
              },
            };

            const data = cache.readQuery({
              query: GET_EVENTS_ADMIN,
              variables,
            });

            const unsortedEvents = (data?.getEventsAdmin?.results ?? []).concat(
              [{ ...createEvent }]
            );
            const updatedResults = unsortedEvents.sort((a, b) => {
              const aStartDate = a?.eventDates?.[0] ?? '';
              const bStartDate = b?.eventDates?.[0] ?? '';
              return aStartDate < bStartDate
                ? -1
                : aStartDate > bStartDate
                ? 1
                : 0;
            });

            cache.writeQuery({
              query: GET_EVENTS_ADMIN,
              variables,
              data: {
                ...data,
                getEventsAdmin: {
                  ...data.getEventsAdmin,
                  count: data.getEventsAdmin.count + 1,
                  results: updatedResults,
                },
              },
            });
          },
          })
        );
      }
    })
  }
);
