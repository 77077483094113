import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { Select } from 'components/Form/Select';
import LineHeightText from 'components/LineHeightText';
import { CheckInModal } from 'components/Modal';
import { SearchableListContainer } from 'components/SearchableListContainer';
import Spacer from 'components/Spacer';
import { WaiverNotSigned } from 'components/SVG/WaiverNotSigned';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import { useGetMembershipDivisions } from 'pages/app/AnnualTickets/Members/gql/useGetMembershipDivisions';
import { TitleContainer } from 'pages/track/Contact/style';
import {
  useCheckinRegistration,
  useGetEventRegistrations,
  useUnCheckRegistration,
} from './gql';
import { useGetRegistrationTickets } from './gql/useGetRegistrationTickets';
import { RegistrationsHeader } from './RegistrationsHeader';
import { UpdateRegistration } from './UpdateRegistration';

const FilterContainer = styled.div`
  min-width: 280px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

export const MembershipFilterContainer = styled.div`
  min-width: 250px;
  margin: 5px;
`;

const Td = styled(TableCell)`
  text-align: left;
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  &:last-child {
    padding-right: 30px;
  }
  &:first-child {
    padding-left: 30px;
  }
`;

export function getDriverName(data, user) {
  const firstName =
    data
      .find(
        (field) =>
          field.name === 'first_name' || field.name === 'drivers_first_name'
      )
      ?.value.trim() ?? '';
  const middleName =
    data
      .find(
        (field) =>
          field.name === 'middle_name' || field.name === 'drivers_middle_name'
      )
      ?.value.trim() ?? '';
  const lastName =
    data
      .find(
        (field) =>
          field.name === 'last_name' || field.name === 'drivers_last_name'
      )
      ?.value.trim() ?? '';

  if (firstName.length && lastName.length) {
    const driverName = `${lastName ? `${lastName}, ` : ''}${firstName}${
      middleName ? ` ${middleName}` : ''
    }`;
    return driverName;
  } else {
    const userName = `${user.last_name ? `${user.last_name}, ` : ''}${
      user.first_name
    }${user.middle_name ? ` ${user.middle_name}` : ''}`;
    return userName;
  }
}

export const RegistrationsDesktop = (props) => {
  const { data: tickets } = useGetRegistrationTickets();
  const [ticketFilter, setTicketFilter] = useState(null);
  const [division, setDivision] = useState(null);

  const { data } = useGetEventRegistrations(
    ticketFilter ? ticketFilter : null,
    division ? division.value : null
  );
  const { data: divisions } = useGetMembershipDivisions(
    ticketFilter && ticketFilter !== 'All Registrations' ? ticketFilter : null
  );
  const theme = useTheme();
  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const [currentRegistration, setCurrentRegistration] = useState(null);

  const [shouldDisplayUpdateModal, setShouldDisplayUpdateModal] =
    useState(false);
  const [shouldDisplayCheckInModal, setShouldDisplayCheckInModal] =
    useState(false);
  const [unCheck, setUncheck] = useState(false);

  const checkinRegistration = useCheckinRegistration();
  const unCheckRegistration = useUnCheckRegistration();

  async function onConfirmCheckIn() {
    const { id } = currentRegistration;
    return unCheck
      ? await unCheckRegistration(id)
      : await checkinRegistration(id);
  }

  if (!data?.getEvent && !data?.getEventRegistrations) return null;

  return (
    <>
      <SearchableListContainer
        header={
          <RegistrationsHeader
            {...props}
            total={data?.getEventRegistrations.count}
            ticket_id={ticketFilter ? ticketFilter : null}
            division={division ? division.value : null}
            data={data}
            registration_type={
              ticketFilter
                ? tickets?.getRegistrationTickets.find(
                    (tix) => tix.id === Number(ticketFilter)
                  )
                : null
            }
          />
        }
        pageCount={data?.getEventRegistrations.count}
        paginated
        title={`Registrations (${data?.getEventRegistrations.count})`}
        searchInputPlaceholder="Search Registrations"
        inputMaxWidth="200px"
        titleBarContent={
          <TitleContainer>
            <MembershipFilterContainer>
              <Select
                placeholder="All Registrations"
                options={tickets.getRegistrationTickets.map((ticket) => ({
                  label: ticket.name,
                  value: ticket.id,
                }))}
                value={
                  ticketFilter
                    ? ticketFilter
                    : { label: 'All Registrations', value: null }
                }
                isSearchable
                isClearable
                onChange={({ target }) => {
                  setTicketFilter(target.value ?? null);
                }}
              />
            </MembershipFilterContainer>
            <Spacer size={5} />

            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 'auto',
              }}
            >
              {ticketFilter &&
              divisions &&
              divisions.getMembershipDivisions &&
              divisions.getMembershipDivisions.length > 0 ? (
                <FilterContainer>
                  <AutoSuggest
                    placeholder="Select Division"
                    options={divisions.getMembershipDivisions.map((ticket) => ({
                      label: ticket,
                      value: ticket,
                    }))}
                    value={division ? division : null}
                    isSearchable
                    isClearable
                    onChange={(ticket) => {
                      setDivision(ticket ?? null);
                    }}
                  />
                </FilterContainer>
              ) : null}
            </div>
          </TitleContainer>
        }
      >
        <Table>
          <TableHeader>
            <TableRow>
              <Td scope="col">Check In</Td>
              <Td scope="col">Date</Td>
              <Td scope="col">Name</Td>
              <Td scope="col">Registration Name</Td>
              <Td scope="col">Purchaser Name</Td>
              <Td scope="col">Division</Td>
              <Td scope="col">Total</Td>
              <Td scope="col">Agreements</Td>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.getEventRegistrations.results.map((registration, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: !registration.is_signed
                    ? theme.colors.primaryOpacity(0.1)
                    : '',
                  color: !registration.is_signed
                    ? theme.colors.text.gray
                    : 'black',
                }}
              >
                <Td>
                  <Checkbox
                    aria-checked={!!registration.is_checked}
                    checked={!!registration.is_checked}
                    inversed
                    name="check_in"
                    onChange={() => {
                      setCurrentRegistration(registration);
                      setUncheck(!!registration.is_checked);
                      setShouldDisplayCheckInModal(true);
                    }}
                    role="checkbox"
                    tabIndex={0}
                  />
                </Td>
                <Td>{registration.purchase_date}</Td>
                <Td>
                  <Link
                    to={
                      userType === 'admin'
                        ? `/admin/registrationDetail/registration/${registration.pass_id}`
                        : `/admin-${userType}/registrationDetail/registration/${registration.pass_id}`
                    }
                    style={{
                      color: theme.colors.primary,
                    }}
                  >
                    <LineHeightText>
                      {getDriverName(registration.data, registration.user)}
                    </LineHeightText>
                  </Link>
                </Td>
                <Td>{registration.registration.name}</Td>
                <Td>
                  {registration.user
                    ? `${registration.user.last_name}, ${registration.user.first_name}`
                    : ''}
                </Td>
                <Td>{registration.division}</Td>
                <Td>{registration?.registration?.price ?? ''}</Td>
                <Td>
                  {!registration.is_signed ? (
                    <WaiverNotSigned width={40} style={{ padding: 5 }} />
                  ) : (
                    'Signed'
                  )}
                </Td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SearchableListContainer>
      <UpdateRegistration
        currentTransaction={currentRegistration}
        close={() => {
          setShouldDisplayUpdateModal(false);
          setCurrentRegistration(null);
        }}
        isVisible={shouldDisplayUpdateModal}
      />
      <CheckInModal
        itemType="guest"
        isVisible={shouldDisplayCheckInModal}
        onConfirm={onConfirmCheckIn}
        setIsVisible={setShouldDisplayCheckInModal}
        unCheck={unCheck}
      />
    </>
  );
};
