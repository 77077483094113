import { Formik } from 'formik';
import React from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { ResponsiveCol } from 'pages/app/Events/AddEvents/styles';

const formValues = [
  'first_name',
  'middle_name',
  'last_name',
  'cellphone',
  'calling_code',
  'country_code',
  'email',
  'address',
  'new_password',
  'old_password',
];

const PersonalInformation = ({
  data,
  updateSelf,
  successMessage,
  errorMessage,
}) => {
  return (
    <>
      <Text fontSize={20} fontWeight="500" color="#3C4144">
        Personal Information
      </Text>
      <Formik
        enableReinitialize={true}
        initialValues={formValues.reduce((acc, value) => {
          acc[value] = data.me && data.me[value] ? data.me[value] : '';

          return acc;
        }, {})}
        validate={() => {
          const errors = {};

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);

          const data = Object.entries(values).reduce((acc, entry) => {
            acc[entry[0]] = '' === entry[1] ? null : entry[1];
            return acc;
          }, {});
          data['cellphone'] = data['cellphone']
            ? data['cellphone'].replace(`+${data.calling_code}`, '')
            : '';

          const response = await updateSelf(data);

          setSubmitting(false);

          if (!response || response.errors) {
            errorMessage();
            return setErrors(response.errors);
          }

          successMessage();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <ResponsiveCol>
              <Spacer size={24} />
              <Input
                id="email"
                name="email"
                label="Email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email && errors.email}
              />

              <Spacer size={24} />
              <Input
                id="first_name"
                name="first_name"
                label="Name"
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                error={
                  errors.first_name && touched.first_name && errors.first_name
                }
              />
              <Spacer size={10} />
              <Input
                id="middle_name"
                name="middle_name"
                placeholder="Middle Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.middle_name}
                error={
                  errors.middle_name &&
                  touched.middle_name &&
                  errors.middle_name
                }
              />
              <Spacer size={10} />

              <Input
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
                error={
                  errors.last_name && touched.last_name && errors.last_name
                }
              />

              <Spacer size={24} />
              <PhoneInput
                id="cellphone"
                placeholder="___ ___ ____"
                size={25}
                label="Phone Number"
                onBlur={handleBlur}
                onChange={(value) => {
                  setFieldValue('cellphone', value ?? '');
                }}
                onCountryChange={(e) => {
                  if (e) {
                    setFieldValue('country_code', e ?? '');
                    setFieldValue(
                      'calling_code',
                      getCountryCallingCode(e) ?? ''
                    );
                  }
                }}
                value={values.cellphone}
                error={errors.cellphone}
              />
              <Spacer size={24} />

              <Spacer size={45} />
              <Button type="submit" disabled={isSubmitting} block>
                Save
              </Button>

              <Spacer size={80} />
            </ResponsiveCol>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PersonalInformation;
