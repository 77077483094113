import React from 'react';
import { Col, Row } from 'react-grid-system';
import { DatePicker } from 'components/Form/DatePicker';
import Spacer from 'components/Spacer';
import { SectionTitle } from 'pages/app/Events/AddEvents/styles';

const DateAndTime = ({ values, errors, setFieldValue, handleChange }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ margin: 10, width: '65%' }}>
        <Row>
          <SectionTitle>Valid</SectionTitle>
        </Row>

        <Row>
          <Col>
            <DatePicker
              id="start_date"
              name="start_date"
              type="date"
              test
              onChange={setFieldValue}
              value={values.start_date}
              error={errors.start_date}
            />
          </Col>
          <Col>
            <DatePicker
              id="end_date"
              name="end_date"
              type="date"
              test
              onChange={setFieldValue}
              value={values.end_date}
              error={errors.end_date}
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Spacer size={25} />
        </Row>

        <Row>
          <SectionTitle>On Sale</SectionTitle>
        </Row>

        <Row>
          <Col>
            <SectionTitle>On Sale</SectionTitle>
          </Col>
          <Col></Col>
          <Col>
            <SectionTitle>Off Sale</SectionTitle>
          </Col>
        </Row>

        <Row>
          <Col>
            <DatePicker
              id="sale_start"
              name="sale_start"
              type="date"
              test
              onChange={setFieldValue}
              value={values.sale_start}
              error={errors.sale_start}
            />
          </Col>
          <Col>
            <DatePicker
              id={'on_sale_time'}
              type="time"
              name="on_sale_time"
              value={values.on_sale_time}
              onChange={(name, event) => {
                handleChange({
                  target: {
                    name,
                    value: event.target.value,
                  },
                });
              }}
            />
          </Col>
          <Col>
            <DatePicker
              id="sale_end"
              name="sale_end"
              type="date"
              test
              onChange={setFieldValue}
              value={values.sale_end}
              error={errors.sale_end}
            />
          </Col>
          <Col>
            <DatePicker
              id={'off_sale_time'}
              type="time"
              name="off_sale_time"
              value={values.off_sale_time}
              onChange={(name, event) => {
                handleChange({
                  target: {
                    name,
                    value: event.target.value,
                  },
                });
              }}
            />
          </Col>
        </Row>
      </div>
      <div style={{ margin: 10, width: '35%' }}>
        <Row>
          <Col>
            <SectionTitle>
              Created By : {values.created_by?.formatted_name}
            </SectionTitle>
            <Spacer size={15} />
            <SectionTitle>
              Updated By : {values.updated_by?.formatted_name}
            </SectionTitle>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DateAndTime;
