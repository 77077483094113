import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../queries/get-users';

export default graphql(
  gql`
    mutation Updateuser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
        first_name
        middle_name
        address_1
        address_2
        city
        state
        zipcode
        last_name
        email
        role
        cellphone
        suffix
        parents {
          first_name
          last_name
          cellphone
          user_id
        }
        inactive
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUser: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateUser } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const { role } = qs.parse(window.location.search.substring(1));

              const { queryString, page } = search;

              const data = proxy.readQuery({
                query,
                variables: { queryString, page, role: null },
              });

              proxy.writeQuery({
                query,
                variables: { queryString, page, role: null },
                data: {
                  ...data,
                  getUsers: {
                    ...data.getUsers,
                    count: data.getUsers.count + 1,
                    results: data.getUsers.results.reduce((acc, user) => {
                      if (parseInt(user.id) === parseInt(input.id)) {
                        return acc.concat([updateUser]);
                      }
                      return acc.concat([user]);
                    }, []),
                  },
                },
              });

              if (role) {
                try {
                  const data2 = proxy.readQuery({
                    query,
                    variables: { queryString, page, role },
                  });

                  proxy.writeQuery({
                    query,
                    variables: { queryString, page, role },
                    data: {
                      ...data2,
                      getUsers: {
                        ...data2.getUsers,
                        count: data2.getUsers.count + 1,
                        results: data2.getUsers.results.reduce((acc, user) => {
                          if (parseInt(user.id) === parseInt(input.id)) {
                            return acc.concat([updateUser]);
                          }
                          return acc.concat([user]);
                        }, []),
                      },
                    },
                  });
                } catch (e) {}
              }
            },
          })
        );
      },
    }),
  }
);
