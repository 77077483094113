import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from './UseGetAnnualCategories';

export default graphql(
  gql`
    mutation DeleteAnnualType($id: Int!) {
      deleteAnnualType(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteAnnualType: async (id, accountFilter) => {
        return mutator(() =>
          mutate({
            variables: { id },
            update: (proxy, { data: { deleteAnnualType } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              if (!deleteAnnualType) {
                return;
              }

              const data = proxy.readQuery({
                query: query,
                variables: {
                  input: {
                    ...search,
                    ...(accountFilter ? { accountFilter } : null),
                  },
                },
              });

              proxy.writeQuery({
                query: query,
                variables: {
                  input: {
                    ...search,
                    ...(accountFilter ? { accountFilter } : null),
                  },
                },
                data: {
                  ...data,
                  getAnnualTypesAdmin: {
                    ...data.getAnnualTypesAdmin,
                    count: data.getAnnualTypesAdmin.count - 1,
                    results: data.getAnnualTypesAdmin.results.filter(
                      (annual_type) => annual_type.id !== id
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
