import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import DeleteAccount from './gql/mutations/delete-account';
import GetAccounts from './gql/queries/get-accounts';

const Accounts = (props) => {
  const { theme, data, history, deleteAccount } = props;
  const accounts = data?.getAccounts?.results ?? [];

  if (!data?.getAccounts) return null;

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Primary Contact',
      key: 'primaryContact',
    },
    {
      label: 'Properties',
      key: 'properties',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(account) {
    const { id, legal_name, primary_contact, business_dba } = account;
    return {
      name: (
        <Link
          to={`/admin/accounts/editAccount/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {business_dba}</LineHeightText>
        </Link>
      ),
      primaryContact: primary_contact,
      properties: legal_name,
      actions: (
        <>
          <Icon
            icon="edit"
            size={18}
            color={props.theme.colors.primary}
            onClick={async () => {
              history.push(`/admin/accounts/editAccount/${id}`);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              const successMessage = () =>
                toast.success('Account Successfully Deleted');
              const errorMessage = () => toast.error('Error Deleting Account');

              if (
                window.confirm('Are you sure you want to delete this Account?')
              ) {
                const response = await deleteAccount(id);

                if (!response || response.errors) {
                  errorMessage();
                }

                successMessage();
              }
            }}
          />
        </>
      ),
    };
  }

  return (
    <>
      <SearchableListContainer
        pageCount={data.getAccounts.count}
        paginated
        title="Accounts"
        searchInputPlaceholder="Search Accounts"
        onAddClick={() => {
          setTimeout(() => {
            history.push('/admin/accounts/addAccount');
          }, 300);
        }}
        v
      >
        <Table items={accounts} columns={columns} renderRows={renderRows} />
      </SearchableListContainer>
    </>
  );
};

export default withTheme(compose(GetAccounts, DeleteAccount)(Accounts));
