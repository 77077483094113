import qs from 'qs';
import React from 'react';
import { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import { DataRow } from 'components/StyledComponents';
import Text from 'components/Text';
import { ButtonWrapper } from 'pages/app/Events/CancelModal/style';

const TransferConfirmationModal = ({
  isVisible,
  onClose,
  confirmTransfer,
  newUserName,
}) => {
  const theme = useTheme();

  const { membership, oldUserName } = qs.parse(
    window.location.search.substring(1)
  );

  return (
    <Modal
      isVisible={isVisible}
      hideModal={onClose}
      title={'Transfer Confirmation'}
      maxWidth={600}
    >
      <div style={{ padding: 20 }}>
        <DataRow>
          <Text
            type="bold"
            color={theme.colors.text.gray}
            inlineStyle={{ width: '30%' }}
          >
            Membership:
          </Text>
          <Text
            type="bold"
            fontWeight="600"
            inlineStyle={{ width: '70%', whiteSpace: 'normal' }}
          >
            {membership}
          </Text>
        </DataRow>

        <Spacer size={15} />
        <DataRow>
          <Text
            type="bold"
            color={theme.colors.text.gray}
            inlineStyle={{ width: '30%' }}
          >
            Transfering From:
          </Text>
          <Text
            type="bold"
            fontWeight="600"
            inlineStyle={{ width: '70%', whiteSpace: 'normal' }}
          >
            {oldUserName}
          </Text>
        </DataRow>

        <Spacer size={15} />
        <DataRow>
          <Text
            type="bold"
            color={theme.colors.text.gray}
            inlineStyle={{ width: '30%' }}
          >
            To:
          </Text>
          <Text
            type="bold"
            fontWeight="600"
            inlineStyle={{ width: '70%', whiteSpace: 'normal' }}
          >
            {newUserName}
          </Text>
        </DataRow>
        <Spacer size={25} />

        <ButtonWrapper>
          <Button type="button" onClick={confirmTransfer}>
            Confirm
          </Button>
          <Button
            type="button"
            onClick={onClose}
            buttonStyle={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  );
};

export default TransferConfirmationModal;
