import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const EVENT_PASSES = gql`
  mutation GetEventPasses($eventPassesInput: Int!) {
    getEventPasses(event_id: $eventPassesInput) {
      ticket
      type
      participant
      purchaser
      refunded
      cost
      promo
      transfer
      charge
      fee
      international_fee
      discount
      credit
    }
  }
`;

export function useGetEventPasses() {
  const [eventPasses] = useMutation(EVENT_PASSES);

  const result = useCallback(
    (eventId) => {
      return eventPasses({
        variables: {
          eventPassesInput: Number(eventId),
        },
      });
    },
    [eventPasses]
  );
  return result;
}
