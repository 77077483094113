import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useSearchInput } from 'hooks/useSearchInput';
import { RegistrationValuesFragment } from './registrations.fragments';

export const GET_EVENT_REGISTRATIONS = gql`
  query GetEventRegistrations(
    $getTrackTransactionsInput: EventRegistrationsInput!
    $getEventInput: GetEventInput!
  ) {
    getEventRegistrations(input: $getTrackTransactionsInput) {
      count
      results {
        ...registrationValues
      }
    }
    getEvent(input: $getEventInput) {
      id
      day
      date
      month
      listDates
      isMultiDay
      fullMonth
      fullDate
      name
      start_date
      end_date
      fullDate
      series {
        id
        name
      }
      track {
        id
        name
      }
    }
  }
  ${RegistrationValuesFragment}
`;

export function useGetEventRegistrations(ticket_id, division) {
  const { id } = useParams();
  const { input } = useSearchInput();

  return useQuery(GET_EVENT_REGISTRATIONS, {
    variables: {
      getTrackTransactionsInput: {
        event_id: Number(id),
        division: division ? division : null,
        page: input.page,
        ...(ticket_id && ticket_id !== 'All Registrations'
          ? { ticket_id: Number(ticket_id) }
          : null),
        ...input,
      },
      getEventInput: {
        id: Number(id),
      },
    },
  });
}
