import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  EventsTable,
  EventStatusColorSelect,
  EventYearFilter,
  useEventYearFilter,
} from 'components/Events';
import { useDuplicateEvent, useGetEventsAdmin } from 'components/Events/gql';
import { useGetTrackSeriesEventsAdmin } from 'components/Events/gql/TrackSeriesEvents.queries';
import Icon from 'components/Icon';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { TransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import useTheme from 'hooks/useTheme';
import { CancelModal } from './CancelModal';
import { EventHeader } from './Header';

const Events = ({ match, location }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = useEventYearFilter();
  const history = useHistory();
  const theme = useTheme();
  const duplicateEvent = useDuplicateEvent();

  const isAdminEventList = location.pathname.includes('/admin');
  const eventType = location.pathname.includes('track')
    ? 'track'
    : location.pathname.includes('series')
    ? 'series'
    : 'events';

  const { data: trackData } = useGetTrackSeriesEventsAdmin(
    eventType,
    { skip: eventType === 'events' },
    '200'
  );

  const { data } = useGetEventsAdmin(eventType, {
    skip: eventType !== 'events',
  });

  const events =
    eventType === 'track' || eventType === 'series'
      ? trackData?.getEventsAdmin?.results
      : data?.getEventsAdmin?.results ?? [];

  if (!events) return null;

  const handleEdit = (id) => {
    history.push(`/admin/events/editEvent/${id}`);
  };

  const handleCancel = (event) => {
    setShowCancelModal(true);
    setSelectedEvent(event);
  };

  const handleDuplicate = async (id) => {
    if (window.confirm('Are you sure you want to duplicate this event?')) {
      const response = await duplicateEvent(id, match.params.id);
      if (response?.errors) {
        toast.error('Could not duplicate this event');
      } else {
        toast.success('Event successfully duplicated');
      }
    }
  };

  const handleTransactions = (id) => {
    const path = isAdminEventList
      ? `/admin/transactions/event/${id}`
      : `/admin-track/transactions/event/${id}`;
    history.push(path);
  };

  const renderReadyStatus = (event) => (
    <EventStatusColorSelect
      eventId={event.id}
      statusColor={event.status_color}
    />
  );

  const renderActions = (event) => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Icon
        icon="edit"
        size={18}
        padding="0 15px 0 0"
        color={theme.colors.primary}
        onClick={() => handleEdit(event.id)}
      />
      <Icon
        icon="cancel-event"
        size={22}
        color={theme.colors.primary}
        onClick={() => handleCancel(event)}
      />
      <Icon
        icon="duplicate"
        size={22}
        color={theme.colors.primary}
        onClick={() => handleDuplicate(event.id)}
        padding="0 0 0 15px"
      />
      <Icon
        icon="dollar"
        size={22}
        color={theme.colors.primary}
        onClick={() => handleTransactions(event.id)}
        padding="0 0 0 15px"
      />
    </div>
  );

  const getTitleBarContent = () => {
    if (isAdminEventList && eventType === 'events') {
      return (
        <TransactionDateFilter
          customStartDate={startDateFilter}
          customSetStartDateFilter={setStartDateFilter}
          customEndDate={endDateFilter}
          customSetEndDateFilter={setEndDateFilter}
        />
      );
    }
    return <EventYearFilter />;
  };

  return (
    <>
      <SearchableListContainer
        header={<EventHeader id={match.params.id ?? ''} type={eventType} />}
        title="Events"
        titleBarContent={getTitleBarContent()}
        searchInputPlaceholder="Search Events"
        paginated={eventType === 'events'}
        pageCount={data?.getEventsAdmin?.count ?? 0}
        onAddClick={() => history.push('/admin/events/addEvent')}
      >
        <EventsTable
          events={events}
          listType="admin-events"
          renderActions={renderActions}
          renderReadyStatus={
            isAdminEventList && eventType === 'events'
              ? renderReadyStatus
              : null
          }
        />
      </SearchableListContainer>

      {showCancelModal && (
        <CancelModal
          close={() => setShowCancelModal(false)}
          selectedEvent={selectedEvent}
          allEvents={events}
          currentType={
            match.url.includes('track')
              ? 'track'
              : match.url.includes('series')
              ? 'series'
              : null
          }
          objectId={match.params.id}
        />
      )}
    </>
  );
};

export default Events;
