import { Formik } from 'formik';
import React, { useRef } from 'react';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import {
  ResponsiveCol,
  ResponsiveRow,
} from 'pages/app/Events/AddEvents/styles';
import { formatPhoneNumber } from 'shared/formatters';

const formValues = [
  'user_id',
  'last_name',
  'middle_name',
  'first_name',
  'suffix',
  'cellphone',
  'created',
  'email',
  'birthday',
  'address_1',
  'address_2',
  'city',
  'state',
  'zipcode',
  'selfie_image',
  'temp_image_one',
  'temp_image_two',
  'image_id',
  'image',
  'status',
];

const UserDetail = (props) => {
  const { data } = props;

  const formRef = useRef();

  function getInitialValues(user, keys) {
    const blankValues = {
      ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
    };

    const initialValues = !user
      ? blankValues
      : {
          ...keys.reduce((formValues, key) => {
            return user[key]
              ? {
                  ...formValues,
                  [key]: user[key],
                }
              : formValues;
          }, {}),
          user_id: user?.id,
        };

    return initialValues;
  }
  // const successMessage = () =>
  //   toast.success('Verificaion Completed Successfully');
  // const errorMessage = (response) =>
  //   toast.error(
  //     response?.global
  //       ? 'Error Verifying User'
  //       : "There were errors with your submission check the form's field for errors."
  //   );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getInitialValues(data, formValues)}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formRef}
      validate={(values) => {
        const errors = {};

        if (!values.last_name) {
          errors.last_name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        delete values.created;
        delete values.selfie_image;
        delete values.temp_image_one;
        delete values.temp_image_two;
        delete values.image;

        // values.message_center = false;
        // values.registrations = false;
        // values.memberships = false;

        // const response = await verifyUser(values);

        // if (!response || response.errors) {
        //   errorMessage(response);
        //   setSubmitting(false);
        //   return setErrors(response.errors);
        // } else {
        //   successMessage();
        //   setSubmitting(false);
        //   resetForm();
        //   history.push('/admin/temporaryUsers');
        // }

        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ padding: 30 }}>
          <Spacer size={20} />
          <ResponsiveRow>
            <ResponsiveCol>
              <Input
                id="last_name"
                name="last_name"
                label="Last Name"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.last_name ? values.last_name : ''}
                error={errors.last_name ? errors.last_name : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="middle_name"
                name="middle_name"
                label="Middle Name"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.middle_name ? values.middle_name : ''}
                error={errors.middle_name ? errors.middle_name : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="first_name"
                name="first_name"
                label="First Name"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.first_name ? values.first_name : ''}
                error={errors.first_name ? errors.first_name : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="suffix"
                name="suffix"
                label="Suffix"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.suffix ? values.suffix : ''}
                error={errors.suffix ? errors.suffix : ''}
              />
            </ResponsiveCol>
          </ResponsiveRow>

          <Spacer size={20} />

          <ResponsiveRow>
            <ResponsiveCol>
              <Input
                id="cellphone"
                name="cellphone"
                label="Mobile Number"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={
                  values.cellphone ? formatPhoneNumber(values.cellphone) : ''
                }
                error={errors.cellphone ? errors.cellphone : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="email"
                name="email"
                label="Email"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.email ? values.email : ''}
                error={errors.email ? errors.email : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="birthday"
                name="birthday"
                label="DoB"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.birthday ? values.birthday : ''}
                error={errors.birthday ? errors.birthday : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="address_1"
                name="address_1"
                label="Adress Line 1"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.address_1 ? values.address_1 : ''}
                error={errors.address_1 ? errors.address_1 : ''}
              />
            </ResponsiveCol>
          </ResponsiveRow>

          <Spacer size={20} />

          <ResponsiveRow>
            <ResponsiveCol>
              <Input
                id="address_2"
                name="address_2"
                label="Adress Line 2"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.address_2 ? values.address_2 : ''}
                error={errors.address_2 ? errors.address_2 : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="city"
                name="city"
                label="City"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.city ? values.city : ''}
                error={errors.city ? errors.city : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="state"
                name="state"
                label="State / Province"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.state ? values.state : ''}
                error={errors.state ? errors.state : ''}
              />
            </ResponsiveCol>
            <ResponsiveCol>
              <Input
                id="zipcode"
                name="zipcode"
                label="Postal Code"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.zipcode ? values.zipcode : ''}
                error={errors.zipcode ? errors.zipcode : ''}
              />
            </ResponsiveCol>
          </ResponsiveRow>
        </form>
      )}
    </Formik>
  );
};

export default UserDetail;
