import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useContext , useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { logDevError } from 'shared/alerts';
import { AppContext } from 'shared/AppContext';
import { GuestFieldsFragment } from './guest.fragments';
import { GET_GUESTS } from './useGetGuests';

export const CREATE_GUEST = gql`
  mutation CreateGuestV2($input: CreateGuestInputV2!) {
    createGuestV2(input: $input) {
      ...guestFields
    }
  }
  ${GuestFieldsFragment}
`;

export function useCreateGuest({ queryString }) {
  const { id: eventId } = useParams();
  const {
    state: { accountId },
  } = useContext(AppContext);
  const [createGuestMutation] = useMutation(CREATE_GUEST);
  const createGuest = useCallback(
    async (input) => {
      try {
        const response = await createGuestMutation({
          variables: { input: { ...input, account_id: accountId } },
          // If we're on an event guest list, we need to refresh the list
          ...(eventId
            ? {
                refetchQueries: ['GetEventAndEventGuests'],
              }
            : null),
          // But if not on an event guest, we need to manually update the cache
          ...(!eventId
            ? {
                update: (proxy, { data: { createGuestV2 } }) => {
                  const data = proxy.readQuery({
                    query: GET_GUESTS,
                    variables: { input: { queryString } },
                  });
                  if (data) {
                    const updatedGuests =
                      data.getGuests.results.concat(createGuestV2);
                    const updatedResults = updatedGuests.sort((a, b) =>
                      a.last_name > b.last_name
                        ? 1
                        : b.last_name > a.last_name
                        ? -1
                        : 0
                    );

                    proxy.writeQuery({
                      query: GET_GUESTS,
                      variables: { input: { queryString } },
                      data: {
                        ...data,
                        getGuests: {
                          ...data.getGuests,
                          count: data.getGuests.count + 1,
                          results: updatedResults,
                        },
                      },
                    });
                  }
                },
              }
            : null),
        });
        return response;
      } catch (error) {
        logDevError(error);
      }
    },
    [createGuestMutation, queryString, eventId, accountId]
  );
  return createGuest;
}
