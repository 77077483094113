import React, { useState } from 'react';
import styled from 'styled-components';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { useMe } from 'hooks/useMe';
import { Radio } from './components/Radio';
import PushMessage from './PushMessage';
import TextMessage from './TextMessage';

const Label = styled.label`
  color: black;
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 5vw;
  display: block;
  margin-bottom: 15px;

  @media (min-width: 700px) {
    font-size: 1.5vw;
    font-weight: 600;
  }
`;

const audienceOptions = {
  full: [
    {
      label: 'Users Who Favorited Our Track In the App',
      value: 'track-favorites',
    },
    {
      label: 'Users Who Favorited Our Series In the App',
      value: 'series-favorites',
    },
    {
      label: 'Event Participants',
      value: 'event-participants',
    },
    {
      label:
        process.env.REACT_APP_PLATFORM === 'tickethoss' ? 'Annuals' : 'Members',
      value: 'memberships',
    },
    {
      label: 'Open Credits',
      value: 'credits',
    },
  ],
  track: [
    {
      label: 'Users Who Favorited Us In the App',
      value: 'track-favorites',
    },
    {
      label: 'Event Participants',
      value: 'event-participants',
    },
    {
      label:
        process.env.REACT_APP_PLATFORM === 'tickethoss' ? 'Annuals' : 'Members',
      value: 'memberships',
    },
    {
      label: 'Open Credits',
      value: 'credits',
    },
  ],
  series: [
    {
      label: 'Users Who Favorited Us In the App',
      value: 'series-favorites',
    },
    {
      label: 'Event Participants',
      value: 'event-participants',
    },
    {
      label:
        process.env.REACT_APP_PLATFORM === 'tickethoss' ? 'Annuals' : 'Members',
      value: 'memberships',
    },
    {
      label: 'Open Credits',
      value: 'credits',
    },
  ],
  other: [
    {
      label: 'Event Participants',
      value: 'event-participants',
    },
    {
      label: 'Open Credits',
      value: 'credits',
    },
  ],
};

const NotificationsEdit = ({ isVisible, handleOutClick }) => {
  const [messageType, setMessageType] = useState();
  const [clearChildState, setClearChildState] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [isTextTitle, setIsTextTitle] = useState(false);
  const [isPushNotification, setIsPushNotification] = useState(false);

  const { data } = useMe();

  if (!data.me) return null;

  return (
    <Drawer
      title={
        isTextTitle
          ? 'Compose Text Message'
          : isPushNotification
          ? 'Compose Push Notification'
          : 'Send A Message'
      }
      isVisible={isVisible}
      handleOutClick={() => {
        setClearChildState(true);
        setMessageType('');
        setShowOptions(true);
        setIsTextTitle(false);
        setIsPushNotification(false);
        handleOutClick();
      }}
    >
      <div
        style={{
          width: '90%',
          borderBottom: '1px solid #e6e6e6',
          margin: 'auto',
          marginBottom: 20,
        }}
      ></div>
      <DrawerPadding>
        {showOptions ? (
          <>
            <Label>Message Type</Label>
            <Radio
              options={[
                {
                  label: 'App Push Notification',
                  value: 'push',
                  description: [
                    'Keep Everyone in the Loop!',
                    'Send as Many Push Notifications as you would like!',
                    'They can be as long as you wish and include links.',
                  ],
                },
                {
                  label: 'Text Message',
                  value: 'text',
                  description: [
                    'Use Text Messaging for Urgent and Short Marketing Communications.',
                    'Keep Text Messages Under 130 Characters.',
                    'Annual Message Limits Apply. Contact Pit Pay Support for Details.',
                  ],
                },
              ]}
              onChange={(val) => {
                setMessageType(val.target.value);
              }}
              // onBlur={handleBlur}
              value={messageType}
            />
          </>
        ) : null}
        {messageType === 'text' ? (
          <TextMessage
            onComplete={() => {
              setClearChildState(true);
              setMessageType('');
              handleOutClick();
            }}
            clearState={clearChildState}
            audienceOptions={audienceOptions}
            setShowOptions={setShowOptions}
            setIsTextTitle={setIsTextTitle}
          />
        ) : null}
        {messageType === 'push' ? (
          <PushMessage
            onComplete={() => {
              setClearChildState(true);
              setMessageType('');
              handleOutClick();
            }}
            clearState={clearChildState}
            audienceOptions={audienceOptions}
            setShowOptions={setShowOptions}
            setIsPushNotification={setIsPushNotification}
          />
        ) : null}
      </DrawerPadding>
    </Drawer>
  );
};

export default NotificationsEdit;
