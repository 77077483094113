import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CREDIT_EVENT = gql`
  mutation CreditEvent($creditEventInput: CreditEventInput!) {
    creditEvent(input: $creditEventInput)
  }
`;

export function useCreditEvent() {
  const [creditEventMutation] = useMutation(CREDIT_EVENT);

  const creditEvent = useCallback(
    (event_id, message) => {
      return creditEventMutation({
        variables: {
          creditEventInput: {
            event_id: event_id,
            message: message,
          },
        },
      });
    },
    [creditEventMutation]
  );
  return creditEvent;
}
