import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useTransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_ACCOUNT_MEMBERS = gql`
  query GetAccountMembers($input: AccountMembersInput!) {
    getAccountMembers(input: $input) {
      count
      results {
        id
        user {
          id
          formatted_name
          email
          cellphone
        }
        divison
        membership {
          id
          name
        }
        serial_number
        is_signed
        approved
      }
    }
  }
`;

export function useGetAccountMembers({
  page,
  typeId,
  division,
  year,
  accountId,
  search,
}) {
  const { input } = useSearchInput();
  const { id } = useParams();

  const { startDateFilter, endDateFilter } = useTransactionDateFilter();

  const skip = !accountId && !id;

  return useQuery(GET_ACCOUNT_MEMBERS, {
    variables: {
      input: {
        account_id: accountId ? accountId : Number(id),
        year,
        ...(page ? { page } : null),
        ...input,
        ...(typeId ? { ticket_id: Number(typeId) } : null),
        ...(division ? { division } : null),
        ...(search ? { queryString: search } : null),
        ...(startDateFilter ? { start_date: startDateFilter } : null),
        ...(endDateFilter ? { end_date: endDateFilter } : null),
      },
    },
    skip: skip,
  });
}
