/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useRef, useState } from 'react';
import { PinInput } from 'react-input-pin-code';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import Images from 'images';
import storage from 'shared/storage';
import { makeClient } from '../../apollo';
import Login, { REQUEST_CODE } from './gql/login';
import 'react-phone-number-input/style.css';
import './../../index.css';
import VerifyCode from './gql/verify-code';
import HelpLink from './PromoterSignup/components/HavingTrouble';

const Logo = styled.img`
  width: 25px;
  height: 25px;
  margin: 5px;
  cursor: pointer;
  align-self: center;
`;

export const Container = styled.div`
  font-size: 14px;
  & .PhoneInput {
    & .PhoneInputInput {
      flex: 1 1;
      outline: none;
      min-width: 0;
      font-family: 'Barlow Condensed';
      background: ${'#00001d'};
      color: ${'white'};
      border: 0;
      text-align: center;
      box-shadow: inset 0 0 0 2px #888888;
      border-radius: 8px;
      margin: 6px;
      padding: 10px;
      font-size: 35px;
    }
  }
`;

const Auth = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const defaultRedirect = '/admin/users';
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberFull, setPhoneNumberFull] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [verificationCode, setVerificationCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const phoneInputRef = useRef();
  const [countryCode, setCountryCode] = useState('1');
  const [error, setError] = useState('');
  const [verificationTried, setVerificationTried] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <Spacer size={30} />
      {
        // 'tickethoss' === process.env.REACT_APP_PLATFORM || (
        // 'pitpay' === process.env.REACT_APP_PLATFORM ? (
        <>
          {!isMobile ? (
            <div>
              {showConfirmation ? (
                <>
                  <span
                    style={{
                      color: 'white',
                      fontSize: 20,
                      textAlign: 'center',
                      display: 'block',
                      fontFamily: 'helvetica Neue Bold',
                      marginBottom: 10,
                    }}
                  >
                    Enter Verification Code
                  </span>

                  <div
                    style={{
                      backgroundColor: '#00001d',
                      padding: 6,
                      borderRadius: 8,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 18,
                        textAlign: 'left',
                        display: 'block',
                        color: '#ccccd2',
                        fontFamily: 'Barlow Condensed Semi',
                        marginBottom: '30px',
                        marginLeft: '5%',
                        marginTop: '10px',
                      }}
                    >
                      An SMS code was sent to
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderColor: 'white',
                        borderWidth: 2,
                        borderRadius: 8,
                        alignSelf: 'center',
                        width: '90%',
                        marginLeft: '5%',
                        borderStyle: 'solid',
                        marginBottom: '30px',
                      }}
                    >
                      <Input
                        id="cellphone"
                        placeholder="Phone Number"
                        fontSize={35}
                        fontWeight="500"
                        readonly={true}
                        disabled={true}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        value={phoneNumber}
                        inputStyle={{
                          backgroundColor: '#00001d',
                          color: 'white',
                          padding: 10,
                          borderTopLeftRadius: 6,
                          borderTopRightRadius: 6,
                          outline: 'none',
                          borderWidth: 0,
                          fontFamily: 'Barlow Condensed',
                        }}
                        errorColor={'darkmagenta'}
                      />
                      <Logo
                        onClick={() => {
                          setShowConfirmation(false);
                        }}
                        src={Images.pen}
                        width={250}
                      />
                    </div>
                    <div
                      className="verificationContainer"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        backgroundColor: '#00001d',
                        padding: 6,
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      <PinInput
                        values={verificationCode}
                        textContentType="oneTimeCode"
                        autoSelect={true}
                        size={'lg'}
                        onChange={(_value, _index, values) => {
                          setVerificationCode(values);
                          setVerificationTried(false);
                        }}
                        onComplete={async (values) => {
                          const e = values.join('');

                          if (!verificationTried) {
                            const res = await props.verifyCode(e);

                            if (!res.errors) {
                              if (res?.data?.verifyCode) {
                                if (res.data.verifyCode.role !== 'user') {
                                  storage.set('user', res.data.verifyCode);
                                  history.push(defaultRedirect);
                                } else {
                                  setVerificationTried(true);
                                  setError(
                                    'You do not have access to the dashboard, please contact your promoter to give you staff access.'
                                  );
                                }
                              }
                            } else {
                              setVerificationTried(true);
                              setError(res.errors.code);
                            }
                          }
                        }}
                        placeholder={''}
                        focusBorderColor={'#FA4616'}
                        validBorderColor={'#000011'}
                        borderColor={'#000011'}
                        inputStyle={{
                          color: 'white',
                          fontSize: 30,
                          backgroundColor: '#6D6C7C',
                          width: '4.5rem',
                          height: '5rem',
                          borderRadius: '0.6rem',
                        }}
                      />
                    </div>
                    {error.length ? (
                      <span
                        style={{
                          fontSize: 18,
                          textAlign: 'center',
                          display: 'block',
                          fontWeight: '500',
                          color: theme.colors.error,
                          fontFamily: 'Barlow Condensed',
                          marginBottom: '30px',
                          marginLeft: '5%',
                          marginTop: '10px',
                        }}
                      >
                        {error}
                      </span>
                    ) : (
                      <></>
                    )}
                    <span
                      onClick={async () => {
                        setVerificationCode(['', '', '', '', '', '']);

                        const client = await makeClient();

                        client
                          .mutate({
                            mutation: REQUEST_CODE,

                            variables: {
                              input: {
                                phone: phoneNumber,
                                calling_code: countryCode,
                              },
                            },
                          })
                          .then((res) => {
                            if (res.data.checkUser) {
                              toast.success('Code resent successfully.');
                              setShowConfirmation(true);
                            } else {
                              toast.error(
                                'Please contact info@pitpay.com to request access to the platform.'
                              );
                            }
                          })
                          .catch((e) => {
                            if (e.message)
                              toast.error(
                                'Please contact info@pitpay.com to request access to the platform.'
                              );
                          });
                      }}
                      style={{
                        fontSize: 22,
                        textAlign: 'center',
                        display: 'block',
                        color: theme.colors.primary,
                        fontFamily: 'Barlow Condensed Semi',
                        marginBottom: '30px',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      Resend Code
                    </span>
                  </div>

                  <Spacer size={16} />
                  <HelpLink />
                </>
              ) : (
                <>
                  <span
                    style={{
                      color: 'white',
                      fontSize: 20,
                      textAlign: 'center',
                      display: 'block',
                      fontFamily: 'helvetica Neue Bold',
                      marginBottom: 10,
                    }}
                  >
                    Enter Your Mobile Number
                  </span>

                  <Container>
                    <PhoneInput
                      placeholder="___ ___ ____"
                      ref={phoneInputRef}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#00001d',
                        padding: '0 10px',
                        color: 'white',
                        borderColor: '#00001d',
                        fontSize: 35,
                        borderRadius: 6,
                        fontFamily: 'Barlow Condensed Semi',
                      }}
                      defaultCountry={'US'}
                      onChange={(e) => {
                        if (e) {
                          setPhoneNumberFull(e);
                          setPhoneNumber(e.replace('+' + countryCode, ''));
                        }
                      }}
                      onCountryChange={(e) => {
                        if (e) {
                          setCountryCode(getCountryCallingCode(e));
                        }
                      }}
                      value={phoneNumberFull}
                    />
                  </Container>
                  <span
                    style={{
                      color: 'white',
                      fontSize: 17,
                      textAlign: 'center',
                      display: 'block',
                      fontFamily: 'Barlow Condensed Semi',
                      marginTop: 10,
                    }}
                  >
                    You Will Recieve a Verification Code via Text Message
                  </span>
                  <Spacer size={16} />
                  <Button
                    block
                    buttonColor={'#00001d'}
                    textColor={'#fff'}
                    onClick={async () => {
                      const client = await makeClient();

                      client
                        .mutate({
                          mutation: REQUEST_CODE,

                          variables: {
                            input: {
                              phone: phoneNumber,
                              calling_code: countryCode,
                            },
                          },
                        })
                        .then((res) => {
                          if (res.data.checkUser) {
                            setShowConfirmation(true);
                          } else {
                            toast.error(
                              'Please contact info@pitpay.com to request access to the platform.'
                            );
                          }
                        })
                        .catch((e) => {
                          if (e.message)
                            toast.error(
                              'Please contact info@pitpay.com to request access to the platform.'
                            );
                        });
                    }}
                    buttonStyle={{}}
                    style={{
                      fontSize: 30,
                      padding: 20,
                      fontFamily: 'Barlow Condensed Semi',
                    }}
                  >
                    Continue
                  </Button>
                  <Spacer size={16} />

                  <HelpLink />
                </>
              )}
            </div>
          ) : (
            <div>
              {showConfirmation ? (
                <div style={{ margin: 10 }}>
                  <span
                    style={{
                      color: 'white',
                      fontSize: 25,
                      textAlign: 'center',
                      display: 'block',
                      fontWeight: 'bold',
                      fontFamily: 'Barlow Condensed',
                      marginBottom: 10,
                    }}
                  >
                    Enter Verification Code
                  </span>

                  <div
                    style={{
                      backgroundColor: '#00001d',
                      padding: 6,
                      borderRadius: 8,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 18,
                        textAlign: 'left',
                        display: 'block',
                        color: '#ccccd2',
                        fontFamily: 'Barlow Condensed Semi',
                        marginBottom: '30px',
                        marginLeft: '5%',
                        marginTop: '10px',
                      }}
                    >
                      An SMS code was sent to
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          borderColor: 'white',
                          borderWidth: 1,
                          borderRadius: 8,
                          width: '95%',
                          alignSelf: 'center',
                          borderStyle: 'solid',
                        }}
                      >
                        <Input
                          id="cellphone"
                          placeholder="Phone Number"
                          fontSize={35}
                          fontWeight="500"
                          readonly={true}
                          disabled={true}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          value={phoneNumber}
                          inputStyle={{
                            backgroundColor: '#00001d',
                            color: 'white',
                            padding: 10,
                            borderTopLeftRadius: 6,
                            borderTopRightRadius: 6,
                            borderWidth: 0,
                            fontFamily: 'Barlow Condensed',
                          }}
                          errorColor={'darkmagenta'}
                        />
                        <Logo
                          onClick={() => {
                            setShowConfirmation(false);
                          }}
                          src={Images.pen}
                          width={250}
                        />
                      </div>
                    </div>
                    <Spacer size={15} />
                    <div
                      className="verificationContainer"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        backgroundColor: '#00001d',
                        padding: 6,
                        marginBottom: 4,
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      <PinInput
                        values={verificationCode}
                        textContentType="oneTimeCode"
                        autoSelect={true}
                        size={'lg'}
                        onChange={(_value, _index, values) => {
                          setVerificationCode(values);
                          setVerificationTried(false);
                        }}
                        onComplete={async (values) => {
                          const e = values.join('');
                          if (!verificationTried) {
                            const res = await props.verifyCode(e);

                            if (!res.errors) {
                              if (res?.data?.verifyCode) {
                                if (res.data.verifyCode.role !== 'user') {
                                  storage.set('user', res.data.verifyCode);
                                  history.push(defaultRedirect);
                                } else {
                                  setVerificationTried(true);
                                  setError(
                                    'You do not have access to the dashboard, please contact your promoter to give you staff access.'
                                  );
                                }
                              }
                            } else {
                              setVerificationTried(true);
                              setError(res.errors.code);
                            }
                          }
                        }}
                        placeholder={''}
                        focusBorderColor={'#FA4616'}
                        validBorderColor={'#000011'}
                        borderColor={'#000011'}
                        inputStyle={{
                          color: 'white',
                          fontSize: 30,
                          backgroundColor: '#6D6C7C',
                          width: '3.2rem',
                          height: '3.6rem',
                          borderRadius: '0.6rem',
                        }}
                      />
                    </div>
                    {error.length ? (
                      <span
                        style={{
                          fontSize: 18,
                          textAlign: 'center',
                          display: 'block',
                          fontWeight: '500',
                          color: theme.colors.error,
                          fontFamily: 'Barlow Condensed',
                          marginBottom: '30px',
                          marginLeft: '5%',
                          marginTop: '10px',
                        }}
                      >
                        {error}
                      </span>
                    ) : (
                      <></>
                    )}

                    <span
                      onClick={async () => {
                        setVerificationCode(['', '', '', '', '', '']);

                        const client = await makeClient();

                        client
                          .mutate({
                            mutation: REQUEST_CODE,

                            variables: {
                              input: {
                                phone: phoneNumber,
                                calling_code: countryCode,
                              },
                            },
                          })
                          .then((res) => {
                            if (res.data.checkUser) {
                              toast.success('Code resent successfully.');
                              setShowConfirmation(true);
                            } else {
                              toast.error(
                                'Please contact info@pitpay.com to request access to the platform.'
                              );
                            }
                          })
                          .catch((e) => {
                            if (e.message)
                              toast.error(
                                'Please contact info@pitpay.com to request access to the platform.'
                              );
                          });
                      }}
                      style={{
                        fontSize: 22,
                        textAlign: 'center',
                        display: 'block',
                        color: theme.colors.primary,
                        fontFamily: 'Barlow Condensed Semi',
                        marginBottom: '30px',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      Resend Code
                    </span>
                  </div>

                  <Spacer size={16} />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: 22,
                      textAlign: 'right',
                      fontWeight: '600',
                      color: '#fff',
                      display: 'block',
                      fontFamily: 'Barlow Condensed',
                      textDecoration: 'none',
                    }}
                    href="https://promoters.pitpay.com"
                  >
                    Having Trouble ?
                  </a>
                </div>
              ) : (
                <div
                  style={{
                    margin: 0,
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: 25,
                      textAlign: 'center',
                      display: 'block',
                      fontWeight: 'bold',
                      fontFamily: 'Barlow Condensed',
                      marginBottom: 10,
                    }}
                  >
                    Enter Your Mobile Number
                  </span>

                  <Container>
                    <PhoneInput
                      placeholder="___ ___ ____"
                      size={25}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#00001d',
                        color: 'white',
                        borderColor: '#00001d',
                        borderRadius: 6,
                        fontFamily: 'Barlow Condensed',
                        fontSize: 25,
                      }}
                      defaultCountry={'US'}
                      onChange={(e) => {
                        if (e) {
                          setPhoneNumberFull(e);
                          setPhoneNumber(e.replace('+' + countryCode, ''));
                        }
                      }}
                      onCountryChange={(e) => {
                        if (e) {
                          setCountryCode(getCountryCallingCode(e));
                        }
                      }}
                      value={phoneNumberFull}
                    />
                  </Container>

                  <span
                    style={{
                      color: 'white',
                      fontSize: 17,
                      textAlign: 'center',
                      display: 'block',
                      fontWeight: 'bold',
                      fontFamily: 'Barlow Condensed',
                      marginTop: 10,
                    }}
                  >
                    You Will Recieve a Verification Code via Text Message
                  </span>
                  <Spacer size={20} />
                  <Button
                    block
                    buttonColor={'#00001d'}
                    textColor={'#fff'}
                    onClick={async () => {
                      const client = await makeClient();

                      client
                        .mutate({
                          mutation: REQUEST_CODE,

                          variables: {
                            input: {
                              phone: phoneNumber,
                              calling_code: countryCode,
                            },
                          },
                        })
                        .then((res) => {
                          if (res.data.checkUser) {
                            setShowConfirmation(true);
                          } else {
                            toast.error(
                              'Please contact info@pitpay.com to request access to the platform.'
                            );
                          }
                        })
                        .catch((e) => {
                          if (e.message)
                            toast.error(
                              'Please contact info@pitpay.com to request access to the platform.'
                            );
                        });
                    }}
                    buttonStyle={{}}
                    style={{
                      fontSize: 30,
                      padding: 20,
                      fontFamily: 'Barlow Condensed',
                    }}
                  >
                    Continue
                  </Button>
                  <Spacer size={16} />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: 22,
                      textAlign: 'right',
                      fontWeight: '600',
                      color: '#fff',
                      display: 'block',
                      textDecoration: 'none',
                      fontFamily: 'Barlow Condensed',
                    }}
                    href="https://promoters.pitpay.com"
                  >
                    Having Trouble?
                  </a>
                </div>
              )}
            </div>
          )}
        </>
        // )
        // ) :
        // (
        //   <Formik
        //     initialValues={{ cellphone: '', password: '' }}
        //     validate={(values) => {
        //       const errors = {};
        //       if (!values.cellphone) {
        //         errors.cellphone = 'Required';
        //       } else if (
        //         !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
        //           values.cellphone
        //         )
        //       ) {
        //         errors.cellphone = 'Invalid phone number';
        //       }
        //       if (!values.password) {
        //         errors.password = 'Required';
        //       }
        //       return errors;
        //     }}
        //     onSubmit={async (values, { setSubmitting, setErrors }) => {
        //       setSubmitting(true);

        //       const response = await props.login(values);
        //       setSubmitting(false);

        //       if (!response || response.errors) {
        //         setSubmitting(false);
        //         return setErrors(response.errors);
        //       } else {
        //         storage.set('user', response.data.login);
        //         setSubmitting(false);
        //         const { redirect } = qs.parse(
        //           window.location.search.substring(1)
        //         );
        //         if (redirect) window.location = redirect;
        //         history.push(defaultRedirect);
        //       }
        //     }}
        //   >
        //     {({
        //       values,
        //       errors,
        //       handleChange,
        //       handleBlur,
        //       handleSubmit,
        //       isSubmitting,
        //       touched,
        //     }) => (
        //       <>
        //         <MediaQuery query="(min-width: 768px)">
        //           <form onSubmit={handleSubmit}>
        //             <Input
        //               underlined
        //               id="cellphone"
        //               placeholder="Phone Number"
        //               fontSize={20}
        //               fontWeight="500"
        //               onChange={handleChange}
        //               onBlur={handleBlur}
        //               value={values.cellphone}
        //               error={
        //                 errors.cellphone && touched.cellphone && errors.cellphone
        //               }
        //             />
        //             <Spacer size={30} />

        //             <Input
        //               underlined
        //               id="password"
        //               placeholder="Password"
        //               fontSize={20}
        //               fontWeight="500"
        //               type="password"
        //               name="password"
        //               onChange={handleChange}
        //               onBlur={handleBlur}
        //               value={values.password}
        //               error={
        //                 errors.password && touched.password && errors.password
        //               }
        //             />

        //             <Spacer size={16} />
        //             <Anchor
        //               fontSize={20}
        //               textAlign="center"
        //               fontWeight="500"
        //               to="/forgot-password"
        //               color={
        //                 'pitpay' === process.env.REACT_APP_PLATFORM
        //                   ? '#fa4616'
        //                   : '#00001f'
        //               }
        //             >
        //               Forgot Password
        //             </Anchor>
        //             <Spacer size={50} />
        //             <Button
        //               type="submit"
        //               disabled={isSubmitting}
        //               block
        //               buttonColor={
        //                 'pitpay' === process.env.REACT_APP_PLATFORM
        //                   ? '#fa4616'
        //                   : '#fff'
        //               }
        //               textColor={
        //                 'pitpay' === process.env.REACT_APP_PLATFORM
        //                   ? '#fff'
        //                   : '#00001f'
        //               }
        //             >
        //               Login
        //             </Button>
        //           </form>
        //         </MediaQuery>

        //         <MediaQuery query="(max-width: 768px)">
        //           <MobileForm onSubmit={handleSubmit}>
        //             <Input
        //               underlined
        //               id="cellphone"
        //               placeholder="Phone Number"
        //               fontSize={20}
        //               fontWeight="500"
        //               onChange={handleChange}
        //               onBlur={handleBlur}
        //               value={values.cellphone}
        //               style={{ backgroundColor: 'transparent', color: 'white' }}
        //               error={
        //                 errors.cellphone && touched.cellphone && errors.cellphone
        //               }
        //             />
        //             <Spacer size={30} />

        //             <Input
        //               underlined
        //               id="password"
        //               placeholder="Password"
        //               fontSize={20}
        //               fontWeight="500"
        //               type="password"
        //               onChange={handleChange}
        //               onBlur={handleBlur}
        //               value={values.password}
        //               style={{ backgroundColor: 'transparent', color: 'white' }}
        //               error={
        //                 errors.password && touched.password && errors.password
        //               }
        //             />

        //             <Spacer size={16} />
        //             <Anchor
        //               fontSize={20}
        //               textAlign="center"
        //               fontWeight="500"
        //               to="/forgot-password"
        //               color={
        //                 'pitpay' === process.env.REACT_APP_PLATFORM
        //                   ? '#fa4616'
        //                   : '#fff'
        //               }
        //             >
        //               Forgot Password
        //             </Anchor>
        //             <Spacer size={50} />
        //             <Button
        //               type="submit"
        //               disabled={isSubmitting}
        //               block
        //               buttonColor={
        //                 'pitpay' === process.env.REACT_APP_PLATFORM
        //                   ? '#fa4616'
        //                   : '#fff'
        //               }
        //               textColor={
        //                 'pitpay' === process.env.REACT_APP_PLATFORM
        //                   ? '#fff'
        //                   : '#00001f'
        //               }
        //             >
        //               Login
        //             </Button>
        //           </MobileForm>
        //         </MediaQuery>
        //       </>
        //     )}
        //   </Formik>
        // )
      }
    </>
  );
};

export default VerifyCode(Login(Auth));
