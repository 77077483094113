import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { CardHeader } from 'pages/employee/Events/EventsList';
import MobileSearchInput from 'pages/track/GuestList/GuestListEventsMobile/search';
import { formatPhoneNumber } from 'shared/formatters';
import { useGetCustomers } from '../gql/queries/get-customers';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CustomerName = styled.p`
  display: inline;
  color: #fff;
  line-height: 28px;
  font-weight: 600;
  font-family: Barlow Condensed;
  text-align: left;
  font-size: 6.6vw;
  line-height: 28px;
  margin: 0;
`;

const CustomersListMobile = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';
  const { page } = useParams();

  const [search, setSearch] = useState('');

  const { data } = useGetCustomers();

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  if (!data?.getCustomersV2) return null;

  return (
    <div style={{ justifyContent: 'center', alignContent: 'center' }}>
      <MobileSearchInput
        placeholder="Mobile Lookup"
        onChange={handleChange}
        value={search}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleKeyPress={handleKeyPress}
      />
      <Spacer size={10} />

      {data.getCustomersV2.results.map((item) => (
        <MobileContainer
          onClick={() => {
            history.push(`/admin-${userType}/users/transactions/${item.id}`);
          }}
        >
          <FlexRow>
            <CardInfo>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <CardHeader>
                      <CustomerName>{item.formatted_name}</CustomerName>
                    </CardHeader>
                    <CustomerName>
                      {formatPhoneNumber(item.cellphone)}
                    </CustomerName>
                    <Spacer size={10} />
                  </div>
                </div>
              </div>
            </CardInfo>
            <div style={{ transform: 'rotate(-90deg)' }}>
              <Icon icon="chevron" size={22} color={'red'} />
            </div>
          </FlexRow>
        </MobileContainer>
      ))}
      <div style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}>
        <Pagination
          count={data.getCustomersV2.count ?? 0}
          perPage={15}
          currentPage={page || 1}
          color={'#fff'}
        />
      </div>
    </div>
  );
};

export default CustomersListMobile;
