import React from 'react';
import { Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { ReactSortable } from 'react-sortablejs';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import {
  DraggableRow,
  getHighestItemOrderNumber,
  getOrderedItems,
} from 'components/Form/DraggableFields';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { Label, LabelWrapper } from 'components/Form/styles';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import {
  ResponsiveCol,
  ResponsiveRow,
} from 'pages/app/Events/AddEvents/styles';
import {
  InputWrapper,
  OrderText,
} from 'pages/app/Events/EventEdit/EditEventTickets';
import GetAllInfos from '../gql/get-all-infos';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  width: 95%;
  & > div {
    // Fix for bad design of icomoon-react wrapper
    line-height: 14px;
  }
`;

const InfosRepeater = ({
  onChange,
  handleSort,
  handleBlur,
  errors,
  touched,
  infos,
  onChangeInfo,
  values,
  data,
  type,
}) => {
  const infosWithOrder = getOrderedItems(values['infos_with_order'] || []);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const filteredInfos =
    type === 'event' ? infos.filter((info) => info.type === 'event') : infos;

  return (
    <Col>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 60%' : '0 0 100%' }}>
          {filteredInfos
            ? filteredInfos.map((info, index) => (
                <Info
                  onChange={onChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  infos={infos}
                  onChangeInfo={onChangeInfo}
                  index={index}
                  info={info}
                  key={index}
                  values={values}
                  data={data}
                />
              ))
            : null}

          <ResponsiveRow>
            <ResponsiveCol>
              <Button
                type="button"
                onClick={() => {
                  onChangeInfo('infos', [
                    ...infos,
                    {
                      info: null,
                      logo: '',
                      logo_id: '',
                      order: getHighestItemOrderNumber(infos) + 1,
                      type: 'event',
                    },
                  ]);

                  onChangeInfo('infos_with_order', [
                    ...values['infos_with_order'],
                    {
                      info: null,
                      logo: '',
                      logo_id: '',
                      order: getHighestItemOrderNumber(infos) + 1,
                      type: 'event',
                    },
                  ]);
                }}
                buttonStyle={{
                  width: !isMobile ? '20vw' : '80vw',
                }}
              >
                {infos?.length > 0
                  ? 'ADD ANOTHER GENERAL INFO'
                  : 'ADD GENERAL INFO'}
              </Button>
            </ResponsiveCol>
          </ResponsiveRow>
        </ResponsiveCol>

        <ResponsiveCol
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: !isMobile ? '60px' : 0,
            flex: !isMobile ? '0 0 30%' : '0 0 100%',
          }}
        >
          {infosWithOrder?.length > 0 && (
            <ResponsiveCol>
              <LabelWrapper style={{ marginLeft: -20 }}>
                <Label>{'Infos Order In App'}</Label>
              </LabelWrapper>
              <ReactSortable
                list={infosWithOrder}
                setList={(values) => handleSort('infos_with_order', values)}
              >
                {infosWithOrder.map((info, index) => (
                  <DraggableRow key={index}>
                    <InputWrapper style={{ width: '100%' }}>
                      <OrderText>{info.info?.label}</OrderText>
                    </InputWrapper>
                  </DraggableRow>
                ))}
              </ReactSortable>
            </ResponsiveCol>
          )}
        </ResponsiveCol>
      </ResponsiveRow>

      <Spacer size={30} />
    </Col>
  );
};

const Info = ({
  onChange,
  handleBlur,
  errors,
  infos,
  info,
  onChangeInfo,
  index,
  data,
  values,
}) => {
  const { order } = info;
  const theme = useTheme();

  return (
    <>
      <ButtonWrapper>
        <div style={{ marginTop: 10 }}>
          <Icon
            icon="trash"
            size={20}
            color={theme.colors.primary}
            onClick={() => {
              const newInfos = infos.filter(
                (currentInfo) => currentInfo.order !== info.order
              );
              const newInfosWithOrder = values.infos_with_order.filter(
                (currentInfo) => currentInfo.order !== info.order
              );

              onChangeInfo('infos', newInfos);
              onChangeInfo('infos_with_order', newInfosWithOrder);
            }}
          />
        </div>
      </ButtonWrapper>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: '0 0 100%' }}>
          <Spacer size={10} />
          {data && data.getAllInfos && (
            <AutoSuggest
              isSearchable={false}
              name="General Info"
              style={{ fontSize: 22 }}
              error={''}
              value={info.info ? info.info : null}
              closeMenuOnSelect
              options={data.getAllInfos.results.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => {
                onChange({
                  target: {
                    name: 'info',
                    value: value,
                  },
                  order: order,
                });
              }}
            />
          )}
          <Spacer size={10} />
          <Input
            id={`infos.${index}.text`}
            name={`infos.${index}.text`}
            label="General Info Text"
            as="textarea"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'text',
                  value: target.value,
                },
                order: order,
              });
              errors[`infos.${index}.text`] = '';
            }}
            onBlur={handleBlur}
            value={info.text ?? ''}
            error={
              errors[`infos.${index}.text`] ? errors[`infos.${index}.text`] : ''
            }
          />
          <Spacer size={10} />
          <Input
            id={`infos.${index}.link`}
            name={`infos.${index}.link`}
            label="General Info Link"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'link',
                  value: target.value,
                },
                order: order,
              });
              errors[`infos.${index}.link`] = '';
            }}
            onBlur={handleBlur}
            value={info.link ?? ''}
            error={
              errors[`infos.${index}.link`] ? errors[`infos.${index}.link`] : ''
            }
          />
          <Spacer size={10} />
          <Input
            id={`infos.${index}.video_url`}
            name={`infos.${index}.video_url`}
            label="Video"
            placeholder=""
            onChange={({ target }) => {
              onChange({
                target: {
                  name: 'video_url',
                  value: target.value,
                },
                order: order,
              });
              errors[`infos.${index}.video_url`] = '';
            }}
            onBlur={handleBlur}
            value={info.video_url ?? ''}
            error={
              errors[`infos.${index}.video_url`]
                ? errors[`infos.${index}.video_url`]
                : ''
            }
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <Spacer size={10} />
          <Dropzone
            id="logo_id"
            files={
              info.logo_id
                ? [
                    {
                      name: info.logo_id,
                      preview: info.logo,
                    },
                  ]
                : []
            }
            onChange={(logo_id, logo) => {
              const newInfos = infos.map((currentInfo) =>
                currentInfo.order === order
                  ? {
                      ...currentInfo,
                      logo_id: logo_id,
                      logo: logo,
                    }
                  : currentInfo
              );

              const newInfosWithOrder = values.infos_with_order.map(
                (currentInfo) =>
                  currentInfo.order === order
                    ? {
                        ...currentInfo,
                        logo_id: logo_id,
                        logo: logo,
                      }
                    : currentInfo
              );

              onChangeInfo('infos', newInfos);
              onChangeInfo('infos_with_order', newInfosWithOrder);
              errors[`infos.${index}.logo_id`] = '';
            }}
            error={
              errors[`infos.${index}.logo_id`]
                ? errors[`infos.${index}.logo_id`]
                : ''
            }
            label="General Info Logo"
            isRepeated={true}
          />

          <Spacer size={10} />
          <Dropzone
            id="image_id"
            files={
              info.image_id
                ? [
                    {
                      name: info.image_id,
                      preview: info.image_url,
                    },
                  ]
                : []
            }
            onChange={(image_id, image) => {
              const newInfos = infos.map((currentInfo) =>
                currentInfo.order === order
                  ? {
                      ...currentInfo,
                      image_id: image_id,
                      image_url: image,
                    }
                  : currentInfo
              );

              const newInfosWithOrder = values.infos_with_order.map(
                (currentInfo) =>
                  currentInfo.order === order
                    ? {
                        ...currentInfo,
                        image_id: image_id,
                        image_url: image,
                      }
                    : currentInfo
              );

              onChangeInfo('infos', newInfos);
              onChangeInfo('infos_with_order', newInfosWithOrder);
              errors[`infos.${index}.image_id`] = '';
            }}
            error={
              errors[`infos.${index}.image_id`]
                ? errors[`infos.${index}.image_id`]
                : ''
            }
            label="Image"
            isRepeated={true}
          />

          <Spacer size={10} />
          <Dropzone
            id="pdf_id"
            files={
              info.pdf_id
                ? [
                    {
                      name: info.pdf_id,
                      preview: info.pdf_url?.includes('pdf')
                        ? 'https://d260w0yqhyrp8h.cloudfront.net/PDF_file_icon.svg.png'
                        : info.pdf_url,
                    },
                  ]
                : []
            }
            onChange={(pdf_id, pdf) => {
              const newInfos = infos.map((currentInfo) =>
                currentInfo.order === order
                  ? {
                      ...currentInfo,
                      pdf_id: pdf_id,
                      pdf_url: pdf,
                    }
                  : currentInfo
              );

              const newInfosWithOrder = values.infos_with_order.map(
                (currentInfo) =>
                  currentInfo.order === order
                    ? {
                        ...currentInfo,
                        pdf_id: pdf_id,
                        pdf_url: pdf,
                      }
                    : currentInfo
              );

              onChangeInfo('infos', newInfos);
              onChangeInfo('infos_with_order', newInfosWithOrder);
              errors[`infos.${index}.pdf_id`] = '';
            }}
            error={
              errors[`infos.${index}.pdf_id`]
                ? errors[`infos.${index}.pdf_id`]
                : ''
            }
            label="PDF"
            isRepeated={true}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <Spacer size={38} />
    </>
  );
};

export default GetAllInfos(InfosRepeater);
