import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import { query } from '../queries/get-employees';

export default graphql(
  gql`
    mutation CreateEmployee($input: CreateEmployeeInputV2!) {
      createEmployeeV2(input: $input) {
        id
        first_name
        last_name
        email
        role
        cellphone
        calling_code
        country_code
        middle_name
        suffix
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createEmployeeV2: async (input, account_id) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { createEmployeeV2 } }) => {
              const data = proxy.readQuery({
                query,
                variables: {
                  input: {
                    account_id,
                  },
                },
              });

              const newEmployee = {
                ...createEmployeeV2,
                suffix: '',
              };

              proxy.writeQuery({
                query,
                variables: {
                  input: {
                    account_id,
                  },
                },
                data: {
                  ...data,
                  getEmployeesV2: {
                    ...data.getEmployeesV2,
                    count: data.getEmployeesV2.count + 1,
                    results: data.getEmployeesV2.results.concat(newEmployee),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
