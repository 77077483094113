import React from 'react';
import { Label } from 'components/Form/styles';
import MobileContainer from 'components/MobileContainer';
import { DataRow } from 'components/Registrations/style';
import Spacer from 'components/Spacer';
import Text from 'components/Text';

const CreditDetail = ({ credit, isFinancial }) => {
  return (
    <>
      {!isFinancial ? (
        <MobileContainer>
          <Spacer size={10} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Ticket Holder :
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {credit?.user?.formatted_name}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Credit:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${credit?.amount}`}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Credit Number:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${credit?.id}`}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Issued:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${credit?.date}`}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Issued By:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${
                credit?.issued_by?.formatted_name ||
                credit?.user?.formatted_name
              }`}
            </Text>
          </DataRow>
          <Spacer size={15} />
        </MobileContainer>
      ) : (
        <MobileContainer>
          <Spacer size={10} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Year :
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {credit?.year}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Total:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${credit?.total}`}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Open:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${credit?.open}`}
            </Text>
          </DataRow>
          <Spacer size={15} />
          <DataRow as="div">
            <Label type="bold" inlineStyle={{ width: '40%', color: '#fff' }}>
              Redeemed:
            </Label>
            <Text type="bold" inlineStyle={{ width: '60%', color: '#fff' }}>
              {`${credit?.redeemed}`}
            </Text>
          </DataRow>
        </MobileContainer>
      )}
    </>
  );
};

export default CreditDetail;
