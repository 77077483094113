import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from '../../gql/get-infos';

export default graphql(
  gql`
    mutation UpdateInfo($input: CreateInfoInput!) {
      updateInfo(input: $input) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateInfo: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateInfo } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query,
                variables: {
                  input: search,
                },
              });

              proxy.writeQuery({
                query,
                variables: {
                  input: search,
                },
                data: {
                  ...data,
                  getInfos: {
                    ...data.getInfos,
                    count: data.getInfos.count + 1,
                    results: data.getInfos.results.reduce((acc, info) => {
                      if (parseInt(info.id) === parseInt(input.id)) {
                        return acc.concat([updateInfo]);
                      }

                      return acc.concat([info]);
                    }, []),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
