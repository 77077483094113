import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { IssueCreditModal } from 'pages/app/Credits/issueCreditModal';
import { TitleContainer } from 'pages/track/Contact/style';
import { useGetTrackRedeemedCredits } from './gql/get-track-redeemed-credits';

const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const FilterContainer = styled.div`
  min-width: 150px;
`;

const TrackRedeemedCredits = withEventYearFilterContext((props) => {
  const { theme } = props;

  const [redeemedCredits, setRedeemedCredits] = useState();
  const [countRedeemed, setCountRedeemed] = useState();
  const [search, setSearch] = useState('');

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const { resRedeemed } = useGetTrackRedeemedCredits();

  useEffect(() => {
    if (resRedeemed && resRedeemed.getTrackRedeemedCredits) {
      setRedeemedCredits(resRedeemed?.getTrackRedeemedCredits?.results);
      setCountRedeemed(resRedeemed?.getTrackRedeemedCredits?.count);
    }
  }, [resRedeemed]);

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  function renderRows(user) {
    const { id, formatted_name } = user;

    return {
      name: (
        <Link
          to={`/admin-${userType}/redeemedCredits/creditDetail/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {formatted_name}</LineHeightText>
        </Link>
      ),
    };
  }

  return !redeemedCredits ? (
    <div />
  ) : (
    <>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Redeemed Credits
              </Text>
              <FilterContainer>
                <YearDropdown showLabel={false} />
              </FilterContainer>
              <div style={{ marginLeft: 15 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                  style={{ width: 150, marginRight: 15 }}
                />
              </div>
            </TitleContainer>
            <CreditButton>
              <Button
                // eslint-disable-next-line no-console
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>
        <>
          <Table
            items={redeemedCredits}
            columns={columns}
            renderRows={renderRows}
          />
          <Pagination
            count={countRedeemed}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </>
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={true}
      />
    </>
  );
});

export default withTheme(TrackRedeemedCredits);
