import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { GET_ANNUAL_TICKETS } from '../../gql/UseGetAnnualTickets';

export default graphql(
  gql`
    mutation UpdateAnnualTicket($input: UpdateAnnualTicketInput!) {
      updateAnnualTicket(input: $input) {
        id
        name
        color_code
        price
        description
        track_price
        fee_dollar
        fee_percent
        is_featured
        featured_text
        sale_start
        on_sale_time
        sale_end
        off_sale_time
        start_date
        end_date
        status
        generate_passes
        display_events
        display_track_series
        for_all_events
        limit
        low_ticket
        account_id
        show_fee
        international_fee
        marketing_fee
        track_ids
        series_ids
        event_ids
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateAnnualTicket: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
            update: (proxy, { data: { updateAnnualTicket } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              const data = proxy.readQuery({
                query: GET_ANNUAL_TICKETS,
                variables: { input: search },
              });

              proxy.writeQuery({
                query: GET_ANNUAL_TICKETS,
                variables: { input: search },
                data: {
                  ...data,
                  getAnnualTickets: {
                    ...data.getAnnualTickets,
                    count: data.getAnnualTickets.count,
                    results: data.getAnnualTickets.results.reduce(
                      (acc, event) => {
                        if (parseInt(event.id) === parseInt(input.id)) {
                          return acc.concat([updateAnnualTicket]);
                        }

                        return acc.concat([event]);
                      },
                      []
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
