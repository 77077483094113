import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const LAPSPONSORSHIP_REPORT = gql`
  mutation GetLapSponsorshipReport($sponsorship_id: Int!) {
    getLapSponsorshipReport(sponsorship_id: $sponsorship_id) {
      event {
        name
        isMultiDay
        start_date
        end_date
        track {
          name
        }
      }
      name
      payouts {
        title
        amount
      }
      total_purse
      num_laps
      laps_sold
      data {
        id
        name
        purchaser
        price
        lap_text
      }
    }
  }
`;

export function useGetLapSponsorshipReport() {
  const [sponsorshipMutation] = useMutation(LAPSPONSORSHIP_REPORT);

  const result = useCallback(
    (sponsorship_id) => {
      return sponsorshipMutation({
        variables: {
          sponsorship_id: Number(sponsorship_id),
        },
      });
    },
    [sponsorshipMutation]
  );
  return result;
}
