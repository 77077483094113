import { Formik } from 'formik';
import moment from 'moment';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { DatePicker } from 'components/Form/DatePicker';
import { Input } from 'components/Form/Input';
import { ErrorText } from 'components/Form/styles';
import Loading from 'components/Loading';
import Spacer from 'components/Spacer';
import { AppContext } from 'shared/AppContext';
import CreatePromo from '../gql/mutations/create-promo';
import UpdatePromo from '../gql/mutations/update-promo';
import GetPromo from '../gql/queries/get-promo';
import { useGetAllAccounts } from '../gql/queries/useGetAllAccounts';

const formValues = [
  'name',
  'ticket_discount',
  'service_discount',
  'expiration',
  'no_expiration',
  'limit',
  'track_id',
  'series_id',
];

const PromoEdit = ({
  isVisible,
  handleOutClick,
  currentPromo,
  data,
  updatePromo,
  createPromo,
  newPromo,
}) => {
  const successMessage = () =>
    toast.success(currentPromo ? 'Promo Updated' : 'Promo Created');
  const errorMessage = (response) =>
    toast.error(
      currentPromo
        ? response.global
          ? 'Error Updating Promo'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Promo'
    );

  const isAdmin = window.location.pathname.includes('/admin/');
  const {
    state: { accountId },
  } = useContext(AppContext);

  const resultAdmin = useGetAllAccounts();
  const { data: dataAccounts, loading } = resultAdmin;

  if (data.loading || loading) return <Loading />;

  if (!data.getPromo && !newPromo) return null;

  const initial = formValues.reduce((acc, value) => {
    if (data.getPromo) {
      if (value === 'no_expiration') {
        acc[value] = data.getPromo[value] || false;
        return acc;
      }

      acc[value] = data.getPromo[value];
      return acc;
    }

    acc[value] = '';
    return acc;
  }, {});

  return (
    <Drawer
      title={currentPromo ? 'Edit Promo Code' : 'Create Promo Code'}
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        initialValues={initial}
        // initialValues={{ name: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }

          if (!values.ticket_discount) {
            errors.ticket_discount = 'Required';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let response;
          setSubmitting(true);

          const data = Object.entries(values).reduce((acc, entry) => {
            if (entry[0] === 'expiration') {
              acc[entry[0]] = entry[1]
                ? moment(entry[1]).format('MM-DD-YYYY')
                : null;
              return acc;
            }

            if (entry[0] === 'service_discount') {
              acc[entry[0]] = parseFloat(entry[1]);
              return acc;
            }

            if (entry[0] === 'ticket_discount') {
              acc[entry[0]] = parseFloat(entry[1]);
              return acc;
            }

            if (entry[0] === 'track_id') {
              acc[entry[0]] = entry[1];
              return acc;
            }

            if (entry[0] === 'series_ids') {
              acc['series_id'] = entry[1];
              return acc;
            }

            if (entry[0] === 'account_id') {
              acc[entry[0]] = entry[1];
              return acc;
            }

            acc[entry[0]] = '' === entry[1] ? null : entry[1];
            return acc;
          }, {});

          if (currentPromo) {
            response = await updatePromo(
              {
                ...data,
                expiration: values.expiration ? values.expiration : null,
                id: currentPromo,
                limit: parseInt(values.limit),
                track_id: data.track_id ? data.track_id.value : null,
                series_id: data.series_id ? data.series_id.value : null,
                account_id: data.account_id
                  ? data.account_id.value
                  : !isAdmin
                  ? accountId
                  : null,
              },
              isAdmin,
              accountId
            );
          } else {
            response = await createPromo(
              {
                ...data,
                expiration: values.expiration ? values.expiration : null,
                track_id: data.track_id ? data.track_id.value : null,
                series_id: data.series_id ? data.series_id.value : null,
                account_id: data.account_id
                  ? data.account_id.value
                  : !isAdmin
                  ? accountId
                  : null,
                limit: parseInt(values.limit),
              },
              isAdmin,
              accountId
            );
          }

          setSubmitting(false);

          if (!response || response.errors) {
            errorMessage(response);
            return setErrors(response.errors);
          }

          successMessage();
          handleOutClick();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DrawerPadding border>
              <Input
                label="Promo Code Name"
                name="name"
                placeholder="Promo Code Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name && touched.name && errors.name}
              />
              <Spacer size={18} />
              <Input
                label="Ticket Discount %"
                name="ticket_discount"
                placeholder="0 - 100 %"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ticket_discount}
                error={
                  errors.ticket_discount &&
                  touched.ticket_discount &&
                  errors.ticket_discount
                }
              />
              <p style={{ textAlign: 'center', fontSize: 12, marginTop: 4 }}>
                Transaction fees may apply
              </p>

              {!isAdmin ? null : (
                <>
                  <Spacer size={18} />
                  <Input
                    label="Service Charge Discount"
                    name="service_discount"
                    placeholder="0 - 100"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.service_discount}
                    error={
                      errors.service_discount &&
                      touched.service_discount &&
                      errors.service_discount
                    }
                  />
                </>
              )}
              <Spacer size={18} />
              <Input
                label="Use Limit"
                name="limit"
                placeholder="Number of Times Code Can Be Used"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.limit}
                error={errors.limit && touched.limit && errors.limit}
              />
              <Spacer size={18} />
              <DatePicker
                label="Expiration Date"
                type="date"
                name="expiration"
                onChange={setFieldValue}
                value={values.expiration || ''}
              />
              <Spacer size={18} />
              <Checkbox
                name="no_expiration"
                checked={values.no_expiration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.no_expiration &&
                  touched.no_expiration &&
                  errors.no_expiration
                }
                value={values.no_expiration || ''}
                rightText="No Expiration date for this code"
              />
              <Spacer size={18} />

              {!isAdmin ? null : (
                <>
                  <AutoSuggest
                    name="account_id"
                    label="Account"
                    isMulti={false}
                    isClearable
                    error={
                      errors['account_id'] &&
                      touched['account_id'] &&
                      errors['account_id']
                    }
                    value={values['account_id']}
                    touched={touched['account_id']}
                    onChange={(value) => {
                      setFieldValue('account_id', value);
                    }}
                    onBlur={() => {
                      return;
                    }}
                    closeMenuOnSelect
                    options={dataAccounts.getAllAccounts.map((item) => ({
                      value: item.id,
                      label: item.business_dba,
                    }))}
                  />
                  {errors['account_id'] && (
                    <ErrorText fontSize={12}>{errors['account_id']}</ErrorText>
                  )}
                </>
              )}
            </DrawerPadding>

            <DrawerPadding>
              <Button type="submit" disabled={isSubmitting} block>
                Save Promo Code
              </Button>
            </DrawerPadding>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default compose(UpdatePromo, CreatePromo, GetPromo)(PromoEdit);
