import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

const Container = styled.div`
  padding: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 80%;
`;

export const PrintModal = (props) => {
  const {
    title,
    description,
    isVisible,
    handleClick,
    setIsVisible,
    printReportOptions,
  } = props;

  return (
    <Modal
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      title={title}
      maxWidth={600}
    >
      <Container>
        <p style={{ textAlign: 'left' }}>{description}</p>
        {printReportOptions.map((printOption) => {
          return (
            <ButtonRow key={printOption.id}>
              <Button type="button" onClick={() => handleClick(printOption)}>
                {printOption.name}
              </Button>
            </ButtonRow>
          );
        })}
      </Container>
    </Modal>
  );
};
PrintModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  generatePdf: PropTypes.func.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  printReportOptions: PropTypes.array,
  setDisabled: PropTypes.func.isRequired,
};
