import React from 'react';
import { Col, Row } from 'react-grid-system';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import Loading from 'components/Loading';
import Spacer from 'components/Spacer';
import Account from 'pages/app/Events/EventEdit/Dropdown/account';
import { useGetAnnualTypesByAccountAdmin } from '../gql/GetAnnualTypesByAccountAdmin';

const Payment = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
}) => {
  const { data, loading } = useGetAnnualTypesByAccountAdmin(
    values.account_id?.value
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ margin: 10, width: '60%' }}>
        <Row>
          <div style={{ margin: 10, width: '80%' }}>
            <Account
              values={values}
              errors={errors}
              touched={touched}
              id={values.track}
              setFieldValue={setFieldValue}
              setFieldTouched={touched}
            />
          </div>
        </Row>

        <Row>
          <div style={{ margin: 10, width: '80%' }}>
            {loading && !data ? (
              <Loading />
            ) : data && data.getAnnualTypesByAccountAdmin.results.length ? (
              <AutoSuggest
                name="type_id"
                label="Category"
                value={
                  values.type_id
                    ? {
                        value: values.type_id.id,
                        label: values.type_id.label,
                      }
                    : {
                        value: '',
                        label: 'Select Category',
                      }
                }
                error={errors.type_id}
                touched={touched.type_id}
                onChange={(value) => {
                  setFieldValue('type_id', value);
                }}
                closeMenuOnSelect
                options={data.getAnnualTypesByAccountAdmin.results.map(
                  (item) => ({
                    value: item.id,
                    label: item.name,
                  })
                )}
              />
            ) : null}
          </div>
        </Row>

        <Row>
          <Col>
            <Spacer size={15} />
            <Checkbox
              name="show_fee"
              checked={!values.show_fee}
              onChange={(event) => {
                const value = !event.target?.checked;
                handleChange({
                  target: {
                    name: 'show_fee',
                    value,
                  },
                });
              }}
              onBlur={handleBlur}
              error={errors.show_fee && touched.show_fee && errors.show_fee}
              rightText="Promoter Absorbs Fees"
            />
            <Spacer size={15} />
            <Checkbox
              name="international_fee"
              checked={!!values.international_fee}
              onChange={(event) => {
                const value = event.target?.checked;
                handleChange({
                  target: {
                    name: 'international_fee',
                    value,
                  },
                });
              }}
              onBlur={handleBlur}
              error={
                errors.international_fee &&
                touched.international_fee &&
                errors.international_fee
              }
              rightText="Collect International Fees?"
            />
            <Spacer size={15} />
            <Checkbox
              name="marketing_fee"
              checked={!!values.marketing_fee}
              onChange={(event) => {
                const value = event.target?.checked;
                handleChange({
                  target: {
                    name: 'marketing_fee',
                    value,
                  },
                });
              }}
              onBlur={handleBlur}
              error={
                errors.marketing_fee &&
                touched.marketing_fee &&
                errors.marketing_fee
              }
              rightText="Collect Marketing Fees?"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Payment;
