import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useLocation, useParams } from 'react-router-dom';
import { useSearchInput } from 'hooks/useSearchInput';
import { useEventYearFilter } from '../EventYearFilter';
import { CommonEventFieldsFragment } from './Events.fragments';

export const GET_EVENTS_ADMIN = gql`
  query GetEventsAdmin($input: GetEventsAdminInput!) {
    getEventsAdmin(input: $input) {
      count
      results {
        ...commonEventFields
      }
    }
  }
  ${CommonEventFieldsFragment}
`;

/**
 *
 * @param {string} type - 'track' | 'series' | 'events'
 */
export function useGetTrackSeriesEventsAdmin(type, options = {}, limit) {
  const { yearFilter, trackFilter, seriesFilter, accountFilter } =
    useEventYearFilter();
  const { input: search } = useSearchInput();
  const { id } = useParams();
  const { pathname } = useLocation();

  const variables = {
    input: {
      ...search,
      ...(type === 'track' ? { track_id: Number(id) } : null),
      ...(type === 'series' ? { series_id: Number(id) } : null),
      ...(yearFilter && pathname !== '/admin/events'
        ? { year: yearFilter }
        : null),
      ...(trackFilter ? { track_id: trackFilter.value } : null),
      ...(seriesFilter ? { series_id: seriesFilter.value } : null),
      ...(accountFilter ? { account_id: accountFilter.value } : null),
      ...(limit ? { limit: limit } : null),
    },
  };
  return useQuery(GET_EVENTS_ADMIN, { variables, ...options });
}
