import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

const Container = styled.div`
  padding: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 100%;
`;

const ReaderEntry = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primary : 'transparent'};
  border: 1px solid #ccc;
  color: ${(props) => (props.isSelected ? '#fff' : '#000')};
  gap: 10px;
  font-size: 18px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
  }
`;

const Options = [
  { label: 'Janam Scanner', value: 'janam' },
  { label: 'Phone Camera', value: 'phone' },
];

export const ScanOptionModal = ({
  isVisible,
  setIsVisible,
  setSelectedOption,
}) => {
  const [selectedReader, setSelectedReader] = useState(null);

  const handleClick = (reader) => {
    setSelectedReader(reader);
  };

  const handleContinue = () => {
    setSelectedOption(selectedReader);

    setIsVisible(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      title={`Which type of scanner are you using?`}
      maxWidth={600}
      closable={false}
    >
      <Container>
        {Options?.map((option, index) => (
          <ReaderEntry
            key={index}
            isSelected={selectedReader && option.value === selectedReader}
            onClick={() => handleClick(option.value)}
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {option.label}
            </span>
          </ReaderEntry>
        ))}
        <ButtonRow>
          <Button
            type="button"
            onClick={handleContinue}
            disabled={!selectedReader}
          >
            Continue
          </Button>
        </ButtonRow>
      </Container>
    </Modal>
  );
};
