import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled, { withTheme } from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Tab, TabContent } from '../Events/AddEvents/styles';
import Capabilities from './Components/Capabilities';
import PersonalInformation from './Components/PersonalInformation';
import UpdateSelf from './gql/mutations/update-self';
import Me from './gql/queries/me';

const Container = styled.div`
  margin: 55px 52px 0 52px;
  width: 50%;

  @media (max-width: 768px) {
    margin: 25px 0px 0 20px;
    width: 90%;
  }
`;

const ContainerCap = styled.div`
  margin: 55px 52px 0 52px;
  width: 80%;

  @media (max-width: 768px) {
    margin: 25px 0px 0 20px;
    width: 90%;
  }
`;

export const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  font-family: Barlow Condensed;
  font-weight: 600;
  height: 3.5em;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow: visible;
    background: #fff;
    height: 4em;
    padding: 10px;
  }
`;

const Account = ({ data, updateSelf }) => {
  const successMessage = () => toast.success('Account Updated');
  const errorMessage = () => toast.error('Error Updating Account');

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin')
    ? 'admin'
    : pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const params = useParams();
  const tab = parseInt(params?.tab) ?? 0;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const tabsConfig = [
    {
      id: 0,
      name: 'User Details',
    },
    {
      id: 1,
      name: 'Capabilities',
    },
  ];

  const [active, setActive] = useState(tab);
  const [chosenTab, setChosenTab] = useState({
    value: userType === 'admin' ? 0 : tabsConfig[tab].id,
    label: userType === 'admin' ? 'User Details' : tabsConfig[tab].name,
  });

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return data.loading ? (
    <div />
  ) : (
    <div style={{ width: '100%', backgroundColor: '#F4F4F4' }}>
      {userType === 'admin' ? (
        <Container style={{ marginTop: 0, paddingTop: 20 }}>
          <PersonalInformation
            data={data}
            updateSelf={updateSelf}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        </Container>
      ) : (
        <>
          <TabContent>
            <Tabs>
              {!isMobile ? (
                tabsConfig.map((tab) => (
                  <Tab
                    onClick={(e) => handleClick(e)}
                    active={active === tab.id}
                    width={isMobile ? '100%' : null}
                    id={tab.id}
                    key={tab.id}
                  >
                    {tab.name}
                  </Tab>
                ))
              ) : (
                <div style={{ width: '90%', height: '4em', margin: '0 5vw' }}>
                  <AutoSuggest
                    value={chosenTab}
                    onChange={(value) => {
                      setChosenTab(value);
                      setActive(value.value);
                    }}
                    onBlur={() => {
                      return;
                    }}
                    closeMenuOnSelect
                    options={tabsConfig.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                  />
                </div>
              )}
            </Tabs>
          </TabContent>
          <Container>
            {active === 0 && (
              <PersonalInformation
                data={data}
                updateSelf={updateSelf}
                successMessage={successMessage}
                errorMessage={errorMessage}
              />
            )}
          </Container>
          <ContainerCap>
            {active === 1 && (
              <Capabilities
                data={data}
                successMessage={successMessage}
                errorMessage={errorMessage}
              />
            )}
          </ContainerCap>
        </>
      )}
    </div>
  );
};

export default withTheme(compose(Me, UpdateSelf)(Account));
