/* eslint-disable react-hooks/rules-of-hooks */
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import Tracks from 'components/Form/Tracks';
import Loading from 'components/Loading';
import { SeriesSelect } from 'components/SeriesSelect';
import Spacer from 'components/Spacer';
import { COLOR_CODES } from 'pages/app/Events/EventEdit/EditEventTickets';
import { useGetEventsByAccountAdmin } from '../gql/GetEvents';
import { useGetEventsAnnualAdmin } from '../gql/GetEventsByTrackSeries';

const InputWrapper = styled.div``;

const AnnualDetails = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
}) => {
  const { data, loading } = useGetEventsAnnualAdmin(
    values.series_ids ? values.series_ids?.map((series) => +series.value) : [],
    values.track_id ? values.track_id?.map((track) => +track.value) : []
  );
  const { data: accountData, loading: accountLoading } =
    useGetEventsByAccountAdmin(values.account_id?.value);

  const [trackPrice, setTrackPrice] = useState(false);
  const [ticketLimit, setTicketLimit] = useState(false);
  const [highlightedTicket, setHighlightedTicket] = useState(false);

  const [result, setResult] = useState([]);

  useEffect(() => {
    if (values.is_featured) setHighlightedTicket(true);
    if (values.track_price) setTrackPrice(true);
    if (values.limit) setTicketLimit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.is_featured, values.track_price, values.limit]);

  const types = ['annual', 'membership', 'registration'];

  useEffect(() => {
    if (values.ticket_type !== 'registration')
      setResult(data?.getEventsAnnualAdmin?.results);
    else setResult(accountData?.getEventsByAccountAdmin?.results);
  }, [values.ticket_type, data, accountData, loading, accountLoading]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '55%', marginRight: 20 }}>
        {values.ticket_type !== 'registration' ? (
          <Row>
            <div style={{ margin: 10, width: '80%' }}>
              <Tracks
                label={'Show on Tracks'}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldValue}
                isMulti
              />
            </div>
          </Row>
        ) : null}
        <Spacer size={15} />
        <Row>
          {'tickethoss' !== process.env.REACT_APP_PLATFORM ? (
            <div style={{ margin: 10, width: '80%' }}>
              <AutoSuggest
                id="ticket_type"
                label="Ticket Type"
                closeMenuOnSelect
                options={types.map((type) => ({
                  value: type,
                  label: type.charAt(0).toUpperCase() + type.slice(1),
                }))}
                onChange={(value) => {
                  setFieldValue('ticket_type', value.value);
                  if (value.value === 'registration') {
                    setFieldValue('track_id', '');
                    setFieldValue('series_ids', '');
                    setFieldValue('for_all_events', false);
                    setFieldValue('generate_passes', false);
                    setFieldValue('display_events', false);
                    setFieldValue('event_ids', []);
                  }
                }}
                value={{
                  label:
                    values.ticket_type.charAt(0).toUpperCase() +
                    values.ticket_type.slice(1),
                  value: values.ticket_type,
                }}
              />
            </div>
          ) : null}
        </Row>
        <Row>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ margin: 10, width: '75%' }}>
              <Input
                id="name"
                name="name"
                label="Annual / Season Ticket Name"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.name ? values.name : ''}
                error={errors.name ? errors.name : ''}
              />
            </div>
            <div style={{ margin: 10, width: '25%' }}>
              <Input
                id="price"
                type={'number'}
                name="price"
                label="Ticket Price"
                onChange={handleChange ? handleChange : ''}
                onBlur={handleBlur ? handleBlur : ''}
                value={values.price ? values.price : ''}
              />
            </div>
          </div>
        </Row>

        <Row>
          <Spacer size={25} />
        </Row>
        <Row>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ margin: 10, width: '75%' }}>
              <Input
                id="description"
                as="textarea"
                label="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                error={
                  errors.description &&
                  touched.description &&
                  errors.description
                }
                inputStyle={{ height: 200 }}
              />
            </div>
            <div style={{ margin: 10, width: '25%' }}>
              <Select
                id={`color_code`}
                name={`color_code`}
                error={errors[`color_code`] ? errors[`color_code`] : ''}
                label="Color Code"
                placeholder="Select a Color"
                options={COLOR_CODES}
                onChange={({ target }) => {
                  handleChange({
                    target: {
                      name: `color_code`,
                      value: target.value,
                    },
                  });
                  errors[`color_code`] = '';
                }}
                value={values.color_code}
                backgroundColor={values.color_code}
                changeTextColor={true}
              />
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <InputWrapper>
              <Input
                style={{ marginRight: '15px' }}
                id="fee_dollar"
                name="fee_dollar"
                label="Ticket Fee"
                type="number"
                placeholder=""
                onChange={({ target }) =>
                  handleChange({
                    target: {
                      name: `fee_dollar`,
                      value: parseFloat(target.value),
                    },
                  })
                }
                value={values.fee_dollar ?? ''}
              />
            </InputWrapper>
          </Col>
          <Col>
            <InputWrapper>
              <Input
                style={{ marginRight: '15px' }}
                id="fee_percent"
                name="fee_percent"
                label="Order Processing Fee"
                type="number"
                placeholder=""
                onChange={({ target }) =>
                  handleChange({
                    target: {
                      name: `fee_percent`,
                      value: parseFloat(target.value),
                    },
                  })
                }
                value={values.fee_percent ?? ''}
              />
            </InputWrapper>
          </Col>
        </Row>
        {values.ticket_type !== 'registration' ? (
          <>
            <Row>
              <Col>
                <Spacer size={15} />
                <Checkbox
                  name="generate_passes"
                  checked={!!values.generate_passes}
                  onChange={(event) => {
                    const value = event.target?.checked;
                    handleChange({
                      target: {
                        name: 'generate_passes',
                        value,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.generate_passes &&
                    touched.generate_passes &&
                    errors.generate_passes
                  }
                  rightText="Generate pass for Each Event"
                />

                <Spacer size={15} />

                <Checkbox
                  name="display_events"
                  checked={!!values.display_events}
                  onChange={(event) => {
                    const value = event.target?.checked;
                    handleChange({
                      target: {
                        name: 'display_events',
                        value,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.display_events &&
                    touched.display_events &&
                    errors.display_events
                  }
                  rightText="Display for Sale on All Events"
                />
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          <Col>
            <Checkbox
              myStyle={{ marginTop: '30px' }}
              name="is_featured"
              className="highlighted"
              checked={highlightedTicket}
              onChange={(event) => {
                const value = event.target?.checked;
                setHighlightedTicket(value);
                handleChange({
                  target: {
                    name: 'is_featured',
                    value,
                  },
                });
                if (!value)
                  handleChange({
                    target: {
                      name: `featured_text`,
                      value: null,
                    },
                  });
              }}
              rightText="Display Highlighted Ticket"
            />
          </Col>
          {highlightedTicket && (
            <>
              <Col>
                <InputWrapper>
                  <Input
                    id="featured_text"
                    name="featured_text"
                    label="Highlighted Ticket Name"
                    type="text"
                    placeholder=""
                    onChange={({ target }) =>
                      handleChange({
                        target: {
                          name: `featured_text`,
                          value: target.value,
                        },
                      })
                    }
                    value={values.featured_text ?? ''}
                  />
                </InputWrapper>
              </Col>
              <Col></Col>
            </>
          )}
        </Row>
        <Row>
          <Col>
            <Checkbox
              myStyle={{ marginTop: '30px' }}
              name="track_price"
              className="track_price"
              checked={trackPrice}
              onChange={(event) => {
                const value = event.target?.checked;

                setTrackPrice(value);
                if (!value)
                  handleChange({
                    target: {
                      name: `track_price`,
                      value: null,
                    },
                  });
              }}
              rightText="Display At Track Price"
            />
          </Col>
          <Col>
            {trackPrice && (
              <InputWrapper>
                <Input
                  style={{ marginRight: '15px' }}
                  id="track_price"
                  name="track_price"
                  label="At Track Price"
                  type="number"
                  placeholder=""
                  onChange={({ target }) =>
                    handleChange({
                      target: {
                        name: `track_price`,
                        value: parseFloat(target.value),
                      },
                    })
                  }
                  value={values.track_price ?? ''}
                />
              </InputWrapper>
            )}
          </Col>{' '}
          <Col></Col>
        </Row>
        {/* <div style={{display:'flex', alignItems:'center'}}> */}
        <Row>
          <Col>
            <Checkbox
              myStyle={{ marginTop: '20px', marginRight: '15px' }}
              name="ticket_limit"
              className="ticket_limit"
              checked={ticketLimit}
              onChange={(event) => {
                const value = event.target?.checked;

                setTicketLimit(value);
                if (!value)
                  handleChange({
                    target: {
                      name: `limit`,
                      value: null,
                    },
                  });
              }}
              rightText="Ticket Limit"
            />
          </Col>
          <Col>
            {ticketLimit && (
              <InputWrapper>
                <Input
                  style={{ marginRight: '15px' }}
                  id="limit"
                  name="limit"
                  label="Limit"
                  type="text"
                  placeholder=""
                  onChange={({ target }) =>
                    handleChange({
                      target: {
                        name: `limit`,
                        value: parseInt(target.value),
                      },
                    })
                  }
                  value={values.limit ?? ''}
                />
              </InputWrapper>
            )}
          </Col>
          <Col></Col>
        </Row>
      </div>
      <div style={{ margin: 10, width: '40%' }}>
        <Row>
          <Col>
            {values.ticket_type !== 'registration' ? (
              <>
                <SeriesSelect isMulti label="Show on Series" />

                <Spacer size={35} />
                <Checkbox
                  name="for_all_events"
                  checked={!!values.for_all_events}
                  onChange={(event) => {
                    const value = event.target?.checked;
                    handleChange({
                      target: {
                        name: 'for_all_events',
                        value,
                      },
                    });

                    if (value && result.length > 0) {
                      setFieldValue(
                        'event_ids',
                        result.map((event) => event.id)
                      );
                    } else {
                      setFieldValue('event_ids', []);
                    }
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.for_all_events &&
                    touched.for_all_events &&
                    errors.for_all_events
                  }
                  rightText="Good For All Events"
                />
              </>
            ) : null}
            <Spacer size={15} />
            <Checkbox
              name="events"
              checked={!values.for_all_events}
              onChange={(event) => {
                const value = !event.target?.checked;
                handleChange({
                  target: {
                    name: 'for_all_events',
                    value,
                  },
                });

                if (!value) setFieldValue('event_ids', []);
              }}
              onBlur={handleBlur}
              error={
                errors.for_all_events &&
                touched.for_all_events &&
                errors.for_all_events
              }
              rightText={
                values.ticket_type !== 'registration'
                  ? 'Select Events'
                  : 'Select an Event'
              }
            />
            <Spacer size={15} />
            {(values.ticket_type === 'registration' &&
              accountLoading &&
              !accountData) ||
            (loading && !data) ? (
              <Loading />
            ) : (
              result?.length > 0 &&
              result.map((event) => {
                const checked =
                  values.for_all_events &&
                  (!values.event_ids || values.event_ids?.length === 0)
                    ? values.for_all_events
                    : values.event_ids
                    ? values.event_ids.find((id) => id === event.id)
                      ? true
                      : false
                    : false;

                let date;
                if (event.isMultiDay) {
                  date =
                    moment(event.start_date, 'MM-DD-YYYY').format('MMM DD - ') +
                    moment(event.end_date, 'MM-DD-YYYY').format('DD - YYYY');
                } else {
                  date = moment(event.start_date, 'MM-DD-YYYY').format(
                    'dddd MMM DD - YYYY'
                  );
                }

                return (
                  <Fragment key={event.id}>
                    <Checkbox
                      name="events"
                      checked={checked}
                      onChange={() => {
                        const newEventIds = checked
                          ? values.event_ids.filter(
                              (event_id) => event_id !== event.id
                            )
                          : values.event_ids.concat(event.id);
                        if (values.ticket_type !== 'registration')
                          setFieldValue('event_ids', newEventIds);
                        else setFieldValue('event_ids', [event.id]);

                        if (checked) {
                          setFieldValue('for_all_events', false);
                        } else {
                          if (result?.length === values.event_ids.length + 1) {
                            setFieldValue('for_all_events', true);
                          }
                        }
                      }}
                      onBlur={handleBlur}
                      rightText={`${event.name} - ${date}`}
                    />
                    <Spacer size={15} />
                  </Fragment>
                );
              })
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AnnualDetails;
