import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { useGetAccounts } from 'pages/app/Credits/gql/queries/get-accounts';
import { TitleContainer } from 'pages/track/Contact/style';

const MembersAccount = (props) => {
  const { theme } = props;
  const [searchInput, setSearchInput] = useState('');
  const [accounts, setAccounts] = useState();
  const [count, setCount] = useState();

  const { res } = useGetAccounts();

  useEffect(() => {
    if (res && res.getAccounts) {
      setAccounts(res?.getAccounts?.results);
      setCount(res?.getAccounts?.count);
    }
  }, [res]);

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const columns = [
    {
      label: 'Account',
      key: 'account',
    },
  ];

  function renderRows(account) {
    const { id, business_dba } = account;
    return {
      account: (
        <Link
          to={`/admin/annualUsers/${id}/${business_dba}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {business_dba}</LineHeightText>
        </Link>
      ),
    };
  }

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearchInput(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  return !accounts && !count ? (
    <div />
  ) : (
    <>
      <Container>
        <ContainerHeader borderWidth={'1px'} style={{ position: 'relative' }}>
          <TitleContainer>
            <Text
              type="heading"
              color={theme.colors.secondary}
              inlineStyle={{
                whiteSpace: 'nowrap',
                display: 'inline-block',
                marginRight: 25,
              }}
            >
              Members
            </Text>
            <SearchInput
              placeholder={'Search Accounts'}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyPress}
              value={searchInput}
            />
          </TitleContainer>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table columns={columns} items={accounts} renderRows={renderRows} />
        </div>

        <Pagination count={count} perPage={15} currentPage={currentPage || 1} />
      </Container>
    </>
  );
};

export default withTheme(MembersAccount);
