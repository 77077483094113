import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { query } from './UseGetCategories';

export default graphql(
  gql`
    mutation DeleteCategory($id: Int!) {
      deleteCategory(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteCategory: async (id) => {
        return mutator(() =>
          mutate({
            variables: { id },
            update: (proxy, { data: { deleteCategory } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              if (!deleteCategory) {
                return;
              }

              const data = proxy.readQuery({
                query: query,
                variables: {
                  input: {
                    ...search,
                  },
                },
              });

              proxy.writeQuery({
                query: query,
                variables: {
                  input: {
                    ...search,
                  },
                },
                data: {
                  ...data,
                  getCategories: {
                    ...data.getCategories,
                    count: data.getCategories.count - 1,
                    results: data.getCategories.results.filter(
                      (category) => category.id !== id
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
