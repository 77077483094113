import moment from 'moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Spacer from 'components/Spacer';
import {
  YearDropdown,
  withEventYearFilterContext,
} from 'components/YearDropdown';
import { useGetAccountMemberships as useGetAccountAnnuals } from 'pages/app/AnnualTickets/Annuals/gql/useGetAccountMemberships';
import AccountFilter from 'pages/app/AnnualTickets/component/AccountFilter';
import { useGetAccountMemberships } from 'pages/app/AnnualTickets/Members/gql/useGetAccountMemberships';
import { MembershipDropdown } from 'pages/app/AnnualTickets/Members/MembershipDropdown';

export const MemberDropdown = withEventYearFilterContext(
  ({ setMemberTarget, accountId }) => {
    const isTicketHoss = process.env.REACT_APP_PLATFORM === 'tickethoss';

    const location = useLocation();
    const isTrackAdmin = location.pathname.includes('/admin-track/');

    const [accountFilter, setAccountFilter] = useState();

    const thisYear = Number(moment().format('YYYY'));
    const [year, setYear] = useState(thisYear);

    const [typeId, setTypeId] = useState();

    const { data: memberships } = useGetAccountMemberships({
      year,
      accountId:
        accountId && !isTicketHoss
          ? accountId
          : accountFilter && !isTicketHoss
          ? accountFilter.value
          : null,
    });

    const { data: annuals } = useGetAccountAnnuals({
      year,
      accountId:
        accountId && isTicketHoss
          ? accountId
          : accountFilter && isTicketHoss
          ? accountFilter.value
          : null,
    });

    const setMembership = (typeId) => {
      setTypeId(typeId);
      setMemberTarget(typeId);
    };

    return (
      <>
        <Spacer size={18} />
        {!isTrackAdmin && (
          <AccountFilter
            accountFilter={accountFilter}
            setAccountFilter={setAccountFilter}
            label={true}
          />
        )}

        <Spacer size={18} />
        <YearDropdown
          onSelect={(year) => setYear(year.value)}
          showLabel={true}
        />

        <Spacer size={18} />
        {memberships && memberships.getAccountMemberships?.length ? (
          <MembershipDropdown
            memberships={memberships.getAccountMemberships}
            typeId={typeId}
            setTypeId={setMembership}
            label={'Members'}
          />
        ) : null}
        {annuals && annuals.getAccountAnnualTickets?.length ? (
          <MembershipDropdown
            memberships={annuals.getAccountAnnualTickets}
            typeId={typeId}
            setTypeId={setMembership}
            label={'Annuals'}
          />
        ) : null}
      </>
    );
  }
);
