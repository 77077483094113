import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import { Input } from 'components/Form/Input';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { useRefundPasses } from '../gql';
import { useGetRefundEstimate } from '../gql/useGetRefundEstimate';
import { Person } from './Person';
import { Header, ModalContainer, PassItem } from './styles';
import { TransactionInformationRow } from './TransactionModal';

const AlignmentContainer = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-around';
  margin-top: 10;
  margin-bottom: 10;
`;

export const RefundModal = ({
  currentTransaction,
  close,
  match,
  isUserTransaction,
  ...props
}) => {
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [finalModal, setFinalModal] = useState(false);
  const [includeFees, setIncludeFees] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [refundAmount, setRefundAmount] = useState();

  const [estimate, setEstimate] = useState();

  const [passes, setPasses] = useState([]);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    let newPasses = [];

    const ticketCount = currentTransaction.users.reduce(
      (acc, person) => acc + person.tickets.length,
      0
    );

    const selectedCount = selectedPeople.reduce(
      (acc, person) => acc + person.tickets.length,
      0
    );

    if (ticketCount === selectedCount) {
      newPasses = currentTransaction.users
        .map((person) =>
          person.tickets
            .filter((ticket) => !ticket.refunded || ticket.credited)
            .map((ticket) => ticket.id)
        )
        .flat();
    } else {
      newPasses = selectedPeople
        .map((person) =>
          person.tickets
            .filter((ticket) => !ticket.refunded || ticket.credited)
            .map((ticket) => ticket.id)
        )
        .flat();
    }
    setPasses(newPasses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll, selectedPeople]);

  const { data, loading } = useGetRefundEstimate(
    currentTransaction.id,
    passes,
    includeFees
  );

  const refundPasses = useRefundPasses();

  useEffect(() => {
    if (props.location.query) {
      setSelectedPeople([...props.location.query]);
    }
  }, [props.location.query]);

  useEffect(() => {
    if (!loading && data?.refundEstimate) {
      setEstimate(data?.refundEstimate);
    }
  }, [data, loading]);

  useEffect(() => {
    if (passes.length === 0) {
      setEstimate(null);
    }
  }, [passes]);

  const handleChange = (e) => {
    const { value } = e.target;

    setRefundAmount(value);
  };

  if (!currentTransaction) return false;

  return !finalModal ? (
    <ModalContainer>
      <Header>
        <Text type="heading">Refund</Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </Header>
      <div style={{ borderBottom: '2px solid #eeeeee' }}>
        <TransactionInformationRow
          label="Purchased By"
          data={currentTransaction.user ? currentTransaction.user.name : ''}
        />
        <TransactionInformationRow
          label="Subtotal"
          data={currentTransaction.subtotal}
        />
        <TransactionInformationRow label="Fees" data={currentTransaction.fee} />
        <TransactionInformationRow
          label="Promos"
          data={`-${currentTransaction.discount}`}
        />
        <TransactionInformationRow
          label="Credit"
          data={`-${currentTransaction.credit}`}
        />
        <TransactionInformationRow
          label="Total"
          data={currentTransaction.total}
        />
      </div>
      <Spacer size={10} />
      <div
        style={{
          display: 'flex',
          alignItems: 'right',
          marginTop: 20,
          marginBottom: -28,
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            display: 'flex',
            zIndex: 5,
          }}
        >
          <Checkbox
            inversed
            name="checkbox"
            checked={selectAll}
            onChange={(value) => {
              if (value.target.checked) {
                const newSelectedPeople = currentTransaction.users.map(
                  (person) => {
                    const newTickets = person.tickets.filter(
                      (ticket) => !ticket.refunded
                    );

                    return { ...person, tickets: newTickets };
                  }
                );

                setSelectedPeople(newSelectedPeople);
              } else {
                setSelectedPeople([]);
              }
              setSelectAll(!selectAll);
            }}
            size={[26, 20]}
          />
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              marginTop: 5,
              whiteSpace: 'normal',
              color: '#fa4616',
            }}
          >
            {!selectAll ? 'Select All' : 'Unselect All'}
          </Text>
        </div>
      </div>
      {currentTransaction.users && currentTransaction.users.length ? (
        <>
          {currentTransaction.users &&
            currentTransaction.users.length &&
            currentTransaction.users.map((person, index) => (
              <Person
                key={person.id ?? index}
                person={person}
                setSelectedPeople={setSelectedPeople}
                selectedPeople={selectedPeople}
                index={index}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                isRefund={true}
              />
            ))}

          <div style={{ paddingBottom: 20, borderBottom: '2px solid #eeeeee' }}>
            {' '}
          </div>

          {user.role === 'admin' && (
            <PassItem>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <Checkbox
                  inversed
                  name="checkbox"
                  checked={includeFees}
                  onChange={() => {
                    setIncludeFees(!includeFees);
                  }}
                  size={[36, 20, 3]}
                />
                <Text
                  inlineStyle={{
                    fontWeight: '600',
                    fontSize: 20,
                    marginLeft: 10,
                    whiteSpace: 'normal',
                  }}
                >
                  Include Ticket and Processing Fees
                </Text>
              </div>
            </PassItem>
          )}

          <div
            style={{
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Text
              inlineStyle={{
                fontWeight: '600',
                fontSize: 20,
                marginLeft: 10,
                whiteSpace: 'normal',
              }}
            >
              OR
            </Text>
          </div>

          <Spacer size={18} />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginBottom: 10,
            }}
          >
            <Text
              inlineStyle={{
                display: 'flex',
                fontWeight: '600',
                fontSize: 20,
                marginLeft: 10,
                whiteSpace: 'normal',
              }}
            >
              Refund $ Amount
            </Text>
            <AlignmentContainer>
              <Input
                id="credit_amount"
                name="credit_amount"
                onChange={handleChange}
                value={refundAmount}
              />
            </AlignmentContainer>
          </div>

          <Spacer size={18} />

          <Text
            inlineStyle={{
              display: 'flex',
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              marginBottom: 10,
              whiteSpace: 'normal',
              color: '#00001f',
            }}
          >
            Refund Estimate
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#00001f',
              padding: 20,
              borderRadius: 5,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text
                inlineStyle={{
                  display: 'flex',
                  fontWeight: '500',
                  fontSize: 18,
                  marginLeft: 10,
                  whiteSpace: 'normal',
                  color: '#fff',
                }}
              >
                Purchase Total
              </Text>
              <Text
                inlineStyle={{
                  display: 'flex',
                  fontWeight: '600',
                  fontSize: 20,
                  marginRight: 10,
                  whiteSpace: 'normal',
                  color: '#fff',
                }}
              >
                {refundAmount
                  ? `${currentTransaction.total}`
                  : estimate?.previous_purchase_total ?? '$0.00'}
              </Text>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px 0px',
              }}
            >
              <Text
                inlineStyle={{
                  display: 'flex',
                  fontWeight: '500',
                  fontSize: 18,
                  marginLeft: 10,
                  whiteSpace: 'normal',
                  color: '#fff',
                }}
              >
                Refund Amount
              </Text>
              <Text
                inlineStyle={{
                  display: 'flex',
                  fontWeight: '600',
                  fontSize: 20,
                  marginRight: 10,
                  whiteSpace: 'normal',
                  color: '#fa4616',
                }}
              >
                -
                {refundAmount
                  ? `$${Number(refundAmount).toFixed(2)}`
                  : estimate?.refund_amount ?? '$0.00'}
              </Text>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text
                inlineStyle={{
                  display: 'flex',
                  fontWeight: '500',
                  fontSize: 18,
                  marginLeft: 10,
                  whiteSpace: 'normal',
                  color: '#fff',
                }}
              >
                New Total
              </Text>
              <Text
                inlineStyle={{
                  display: 'flex',
                  fontWeight: '600',
                  fontSize: 20,
                  marginRight: 10,
                  whiteSpace: 'normal',
                  color: '#fff',
                }}
              >
                {refundAmount
                  ? `$${(
                      +currentTransaction.total.replace(/\$|,/g, '') -
                      refundAmount
                    ).toFixed(2)}`
                  : estimate?.new_purchase_total ?? '$0.00'}
              </Text>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            <Button
              onClick={async () => {
                setFinalModal(true);
              }}
            >
              Continue
            </Button>
          </div>
        </>
      ) : null}
    </ModalContainer>
  ) : (
    <ModalContainer>
      <Header>
        <Text type="heading">
          {'tickethoss' !== process.env.REACT_APP_PLATFORM
            ? 'Refund Passes'
            : 'Refund Tickets'}
        </Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </Header>

      <Text
        inlineStyle={{
          fontWeight: '600',
          fontSize: 20,
          whiteSpace: 'normal',
          lineHeight: 1.2,
        }}
      >
        {'tickethoss' !== process.env.REACT_APP_PLATFORM
          ? 'Are you sure you want to refund the selected passes?'
          : 'Are you sure you want to refund the selected tickets?'}
      </Text>
      <Spacer size={20} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginTop: '10px 0',
        }}
      >
        <Button
          onClick={async () => {
            try {
              const passes = selectedPeople
                .map((person) => person.tickets.map((ticket) => ticket.id))
                .flat();

              const response = await refundPasses(
                passes,
                isUserTransaction
                  ? currentTransaction.event?.id
                  : Number(match.params.id),
                currentTransaction.id,
                includeFees,
                +refundAmount
              );

              if (!response || response.errors) {
                toast.error('Passes could not be refunded');
                return;
              }

              if (response.data.createRefundV3) {
                toast.success('Passes have been refunded');
                close();
              } else {
                toast.error('Passes could not be refunded');
              }
            } catch (e) {
              toast.error('Passes could not be refunded');
            }
          }}
        >
          Refund
        </Button>
      </div>
    </ModalContainer>
  );
};
