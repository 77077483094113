import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const DOWNLOAD_WAIVER = gql`
  mutation DownloadMembershipWaiver($input: DownloadMembershipWaiverInput!) {
    downloadMembershipWaiver(input: $input)
  }
`;

export function useDownloadWaiver() {
  const [downloadWaiverMutation] = useMutation(DOWNLOAD_WAIVER);
  const downloadWaiver = useCallback(
    (pass_id, waiver_id) => {
      return downloadWaiverMutation({
        variables: { input: { pass_id, waiver_id } },
      });
    },
    [downloadWaiverMutation]
  );
  return downloadWaiver;
}
