import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import qs from 'qs';

export const query = gql`
  query GetInfos($input: GetInfosInput!) {
    getInfos(input: $input) {
      count
      results {
        id
        name
      }
    }
  }
`;

export default graphql(query, {
  options: (props) => {
    const search = props
      ? qs.parse(props.location?.search, { ignoreQueryPrefix: true })
      : {};
    return {
      variables: {
        input: search,
      },
    };
  },
});
