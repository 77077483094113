import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useSearchInput } from 'hooks/useSearchInput';

export const query = gql`
  query GetAnnualTypesAdmin($input: GetAnnualTypesInput!) {
    getAnnualTypesAdmin(input: $input) {
      count
      results {
        id
        name
        account {
          id
          business_dba
        }
      }
    }
  }
`;

export function useGetAnnualCategories(accountFilter) {
  const { input: search } = useSearchInput();

  return useQuery(query, {
    variables: {
      input: {
        ...search,
        ...(accountFilter ? { accountFilter } : null),
      },
    },
  });
}
