import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { UpcomingEventFieldsFragment } from 'components/Events/gql';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const GET_UPCOMING_EVENT = gql`
  query GetUpcomingEvent($input: Int!) {
    getUpcomingEventsV3(account_id: $input) {
      ...upcomingEventFields
    }
    me {
      id
      track {
        id
        name
        lat
        lng
      }
    }
  }
  ${UpcomingEventFieldsFragment}
`;

export function useGetUpcomingEvent() {
  const {
    state: { accountId },
  } = useContext(AppContext);

  return usePersistedQuery(
    GET_UPCOMING_EVENT,
    `get-upcoming-events-${accountId}`,
    { variables: { input: accountId } }
  );
}
