import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { useGetEventsByAccountAdmin } from '../AddAnnualTickets/gql/GetEvents';

const FilterContainer = styled.div`
  min-width: 200px;
  margin-right: 10px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const EventFilter = ({ annualEvent, setAnnualEvent, account_id }) => {
  const { data, loading } = useGetEventsByAccountAdmin(account_id);

  return (
    !loading &&
    data?.getEventsByAccountAdmin.results.length > 0 && (
      <FilterContainer>
        <AutoSuggest
          placeholder="Event"
          options={data?.getEventsByAccountAdmin.results.map((event) => ({
            value: event.id,
            label: event.isMultiDay
              ? `${event.month} ${event.listDates} - ${moment(
                  event.fullDate
                ).format('YYYY')}`.toUpperCase() + ` — ${event.name}`
              : `${moment(event.fullDate).format(
                  'ddd MMM D - YYYY'
                )}`.toUpperCase() + `— ${event.name}`,
          }))}
          value={annualEvent ? annualEvent : null}
          isSearchable
          isClearable
          onChange={(annualEvent) => {
            setAnnualEvent(annualEvent ?? null);
          }}
        />
      </FilterContainer>
    )
  );
};

export default EventFilter;
