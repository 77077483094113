import { gql } from 'graphql-tag';
import qs from 'qs';
import { usePersistedQuery } from 'hooks/usePersistedQuery';

export const query = gql`
  query GetPromos($input: GetPromosInput!) {
    getPromos(input: $input) {
      count
      results {
        id
        name
        expiration
        no_expiration
        ticket_discount
        service_discount
        is_expired
        issuer
        limit
      }
    }
  }
`;

export function GetPromos() {
  const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return usePersistedQuery(query, `get-promos`, {
    variables: {
      input: {
        ...(search ? search : null),
      },
    },
  });
}
