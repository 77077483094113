import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback, useContext } from 'react';
import { AppContext } from 'shared/AppContext';

export const query = gql`
  mutation GetCustomerExport($account_id: Int!) {
    getCustomerExport(account_id: $account_id)
  }
`;

export function useGetExportCustomers() {
  const [getUserExportMutation] = useMutation(query);

  const {
    state: { accountId },
  } = useContext(AppContext);

  const result = useCallback(() => {
    return getUserExportMutation({
      variables: { account_id: accountId },
    });
  }, [accountId, getUserExportMutation]);
  return result;
}
