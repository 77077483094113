import React, { Fragment, useContext } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import { AppContext } from 'shared/AppContext';

const Container = styled.div`
  padding: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 40%;
`;

const PermissionModal = ({ hideModal, isVisible }) => {
  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const platform =
    'tickethoss' === process.env.REACT_APP_PLATFORM
      ? 'tickethoss'
      : 'pitpay' === process.env.REACT_APP_PLATFORM
      ? 'pitpay'
      : 'karpass';

  const {
    state: { promoters },
  } = useContext(AppContext);

  return (
    <Modal
      isVisible={isVisible}
      hideModal={hideModal}
      maxWidth={600}
      title={'Access Restricted'}
    >
      <Container>
        <Spacer size={10} />

        {userType === 'track' ? (
          <p style={{ textAlign: 'left', fontSize: 18 }}>
            You currently do not have access to this feature. Please contact
            help@{platform}.com to get access to this feature.
          </p>
        ) : (
          <Fragment>
            <p style={{ textAlign: 'left', fontSize: 18 }}>
              You currently do not have access to this feature. Please contact
              one of the people listed below to give you access.
            </p>
            <p style={{ textAlign: 'left', marginTop: 15, fontSize: 18 }}>
              {promoters && promoters.length > 0 && (
                <ul>
                  {promoters.map((promoter) => (
                    <li>
                      {promoter.name} {promoter.cellphone}
                    </li>
                  ))}
                </ul>
              )}
            </p>
          </Fragment>
        )}
        <Spacer size={20} />
        <ButtonRow>
          <Button type="button" onClick={hideModal}>
            Close
          </Button>
        </ButtonRow>
        <Spacer size={20} />
      </Container>
    </Modal>
  );
};

export default PermissionModal;
