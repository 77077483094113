/* eslint-disable react-hooks/rules-of-hooks */
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { ErrorText } from 'components/Form/styles';
import Spacer from 'components/Spacer';
import { useMe } from 'hooks/useMe';
import { useGetAllAccounts } from 'pages/app/Promos/gql/queries/useGetAllAccounts';
import { useGetUserAccounts } from './useGetUserAccounts';

export const AccountsSelect = ({
  isMulti,
  name = 'account_ids',
  addCapability,
  label,
  isAdmin,
}) => {
  const { data, loading } = isAdmin
    ? useGetAllAccounts()
    : useGetUserAccounts();
  const [accounts, setAccounts] = useState([]);
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const {
    data: { me },
  } = useMe();

  useEffect(() => {
    if (data) {
      const dataNew = isAdmin ? data?.getAllAccounts : data?.getUserAccounts;
      if (isAdmin) setAccounts(dataNew);
      else {
        const capabilityObj = {};
        me.capabilities.map(
          (capability) =>
            (capabilityObj[capability.account_id] = capability.add_staff)
        );
        setAccounts(dataNew.filter((account) => capabilityObj[account.id]));
      }

      if (dataNew) {
        if (1 === dataNew.length)
          setFieldValue(name, {
            value: dataNew[0].id,
            label: dataNew[0].business_dba,
          });
      }
    }
  }, [data, name, setFieldValue, isAdmin, me.capabilities]);

  if (loading) return <div />;

  return !accounts ? (
    <></>
  ) : (
    accounts.length && (
      <div>
        <Spacer size={18} />
        <AutoSuggest
          name={name}
          label={label ?? 'Accounts'}
          isMulti={isMulti}
          isClearable
          error={errors[name] && touched[name] && errors[name]}
          value={values[name]}
          touched={touched[name]}
          onChange={(value) => {
            setFieldValue(name, value);
            if (addCapability) {
              if (value) {
                const account_ids = value.map((account) => account.value);
                addCapability(account_ids);
              } else {
                addCapability([]);
              }
            }
          }}
          onBlur={() => {
            return;
          }}
          closeMenuOnSelect
          options={accounts.map((item) => ({
            value: item.id,
            label: item.business_dba,
          }))}
        />
        {errors[name] && <ErrorText fontSize={12}>{errors[name]}</ErrorText>}
      </div>
    )
  );
};
AccountsSelect.propTypes = {
  isMulti: PropTypes.bool,
};
