import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';

const thisYear = Number(moment().format('YYYY'));

const EventYearFilterContext = createContext();

export const EventYearFilterProvider = ({ children }) => {
  const [yearFilter, setYearFilter] = useState(thisYear);
  const [trackFilter, setTrackFilter] = useState();
  const [seriesFilter, setSeriesFilter] = useState();
  const [accountFilter, setAccountFilter] = useState();
  const [filterType, setFilterType] = useState();

  const [yearRegistrationFilter, setYearRegistrationFilter] =
    useState(thisYear);
  const [trackRegistrationFilter, setTrackRegistrationFilter] = useState();
  const [seriesRegistrationFilter, setSeriesRegistrationFilter] = useState();
  const [accountRegistrationFilter, setAccountRegistrationFilter] = useState();
  const [filterRegistrationType, setFilterRegistrationType] = useState();

  const [startDateFilter, setStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();

  const [startTransactionDateFilter, setStartTransactionDateFilter] =
    useState();
  const [endTransactionDateFilter, setEndTransactionDateFilter] = useState();

  return (
    <EventYearFilterContext.Provider
      value={{
        yearFilter,
        setYearFilter,
        trackFilter,
        setTrackFilter,
        seriesFilter,
        setSeriesFilter,
        accountFilter,
        setAccountFilter,
        filterType,
        setFilterType,
        yearRegistrationFilter,
        setYearRegistrationFilter,
        seriesRegistrationFilter,
        trackRegistrationFilter,
        setTrackRegistrationFilter,
        setSeriesRegistrationFilter,
        accountRegistrationFilter,
        setAccountRegistrationFilter,
        filterRegistrationType,
        setFilterRegistrationType,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        startTransactionDateFilter,
        setStartTransactionDateFilter,
        endTransactionDateFilter,
        setEndTransactionDateFilter,
      }}
    >
      {children}
    </EventYearFilterContext.Provider>
  );
};

export const useEventYearFilter = () => {
  const context = useContext(EventYearFilterContext);
  if (context === undefined) {
    throw new Error(
      'useEventYearFilter must be used within an EventYearFilterProvider'
    );
  }
  return context;
};

export const withEventYearFilterContext = (Component) => {
  return (props) => (
    <EventYearFilterProvider>
      <Component {...props} />
    </EventYearFilterProvider>
  );
};

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px;
  }
`;

export const EventYearFilter = ({ yearCustomFilter, setYearCustomFilter }) => {
  const { yearFilter, setYearFilter } = useEventYearFilter();
  const years = [thisYear - 2, thisYear - 1, thisYear, thisYear + 1];
  return (
    <FilterContainer>
      <AutoSuggest
        placeholder="Filter"
        options={years.map((year) => ({
          label: String(year),
          value: year,
        }))}
        value={{
          label: String(yearCustomFilter ? yearCustomFilter : yearFilter),
          value: yearCustomFilter ? yearCustomFilter : yearFilter,
        }}
        onChange={(year) => {
          if (setYearCustomFilter)
            setYearCustomFilter(year?.value ? Number(year.value) : thisYear);
          if (setYearFilter)
            setYearFilter(year?.value ? Number(year.value) : thisYear);
        }}
      />
    </FilterContainer>
  );
};
