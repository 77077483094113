import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { Checkbox } from 'components/Form/Checkbox';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import CreateWaiver from '../gql/createWaiver';
import GetWaiver from '../gql/getWaiver';
import UpdateWaiver from '../gql/updateWaiver';

const formValues = [
  'name',
  'waiver_id',
  'requires_spouse',
  'requires_minor',
  'is_minor_waiver',
  'notes',
];

const WaiverEdit = ({
  isVisible,
  handleOutClick,
  data,
  currentWaiver,
  createWaiver,
  updateWaiver,
}) => {
  const successMessage = () =>
    toast.success(currentWaiver ? 'Waiver Updated' : 'Waiver Created');
  const errorMessage = (response) =>
    toast.error(
      currentWaiver
        ? response.global
          ? 'Error Updating Waiver'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Waiver'
    );

  return (
    <Drawer
      title={!currentWaiver ? 'New Waiver Details' : 'Edit Waiver Details'}
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        enableReinitialize={true}
        initialValues={formValues.reduce((acc, value) => {
          acc[value] =
            currentWaiver && data.getWaiver && data.getWaiver[value]
              ? data.getWaiver[value]
              : '';

          return acc;
        }, {})}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }

          if (!values.waiver_id) {
            errors.waiver_id = 'Required';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let response;
          setSubmitting(false);

          const data = Object.entries(values).reduce(
            (acc, [key, value]) =>
              key === 'name' || key === 'waiver_id' || key === 'notes'
                ? {
                    ...acc,
                    [key]: value === '' ? null : value,
                  }
                : {
                    ...acc,
                    [key]: value === '' ? false : value,
                  },
            {}
          );

          if (currentWaiver) {
            response = await updateWaiver({ ...data, id: currentWaiver });
          } else {
            response = await createWaiver({ ...data });
          }

          if (!response || response.errors) {
            errorMessage(response);
            setSubmitting(false);
            return setErrors(response.errors);
          } else {
            successMessage();
            setSubmitting(false);
            handleOutClick();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DrawerPadding border>
              <Input
                label="Waiver Name"
                name="name"
                placeholder="Waiver Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name && touched.name && errors.name}
              />
              <Spacer size={18} />
              <Input
                label="Waiver Id"
                name="waiver_id"
                placeholder="Waiver Id"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.waiver_id}
                error={
                  errors.waiver_id && touched.waiver_id && errors.uwaiver_idl
                }
              />
              <Spacer size={18} />
              <Checkbox
                name="requires_spouse"
                checked={!!values.requires_spouse}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'requires_spouse',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors.requires_spouse &&
                  touched.requires_spouse &&
                  errors.requires_spouse
                }
                rightText="Requires Second Parent Signature?"
              />
              <Spacer size={18} />
              <Checkbox
                name="requires_minor"
                checked={!!values.requires_minor}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'requires_minor',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors.requires_minor &&
                  touched.requires_minor &&
                  errors.requires_minor
                }
                rightText="Requires Minor Signature?"
              />
              <Spacer size={18} />
              <Checkbox
                name="is_minor_waiver"
                checked={!!values.is_minor_waiver}
                onChange={(event) => {
                  const value = event.target?.checked;
                  handleChange({
                    target: {
                      name: 'is_minor_waiver',
                      value,
                    },
                  });
                }}
                onBlur={handleBlur}
                error={
                  errors.is_minor_waiver &&
                  touched.is_minor_waiver &&
                  errors.is_minor_waiver
                }
                rightText="Is this a Minor Waiver?"
              />
              <Spacer size={24} />
              <Input
                inputStyle={{ height: 200 }}
                id="notes"
                as="textarea"
                label="Notes"
                placeholder="Notes"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
                error={errors.notes && touched.notes && errors.notes}
              />
            </DrawerPadding>

            <DrawerPadding>
              <Button type="submit" disabled={isSubmitting} block>
                Save Waiver Details
              </Button>
            </DrawerPadding>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default compose(GetWaiver, CreateWaiver, UpdateWaiver)(WaiverEdit);
