import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const EVENT_TRANSACTION = gql`
  mutation GetEventTransactions($eventTransactionInput: Int!) {
    getEventTransactionsV2(event_id: $eventTransactionInput) {
      event {
        name
        hide_fee
        fullDate
        track {
          name
        }
        series {
          name
        }
        marketing_fee
        account_name
      }
      num_transactions
      num_tickets
      num_participants
      gross_sales
      net_sales
      marketing_fund
      data {
        type
        total
        data {
          id
          purchaser
          total
          subtotal
          refund
          discount
          credit
          fee
          purchase_date
          ticket_holders {
            name
            tickets {
              name
              price
            }
          }
          participants {
            name
            tickets {
              name
              price
            }
          }
        }
      }
    }
  }
`;

export function useGetEventTransactions() {
  const [eventTransaction] = useMutation(EVENT_TRANSACTION);

  const result = useCallback(
    (eventId) => {
      return eventTransaction({
        variables: {
          eventTransactionInput: Number(eventId),
        },
      });
    },
    [eventTransaction]
  );
  return result;
}
