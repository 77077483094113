import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { TrackName } from 'components/UpcomingEvents';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { CardHeader } from 'pages/employee/Events/EventsList';
import { AppContext } from 'shared/AppContext';
import MobileSearchInput from '../../../../track/GuestList/GuestListEventsMobile/search';
import { useGetAnnualTickets } from '../../gql/UseGetAnnualTickets';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
`;

const AnnualPurchaseMobile = () => {
  const [search, setSearch] = useState('');

  const [accountFilter] = useState(null);

  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState();
  const history = useHistory();

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const {
    state: { accountId },
  } = useContext(AppContext);

  const { res } = useGetAnnualTickets(
    userType === 'admin'
      ? accountFilter
        ? accountFilter.value
        : null
      : accountId,
    true,
    userType
  );

  useEffect(() => {
    if (res && res.getAnnualTickets) {
      setTickets(res?.getAnnualTickets?.results);
      setCount(res?.getAnnualTickets?.count);
    }
  }, [res]);

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const urlParams = new URLSearchParams(window.location.queryString);
  const currentPage = parseInt(urlParams.get('page'));

  if (!tickets) return null;

  return (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <ReportContainer></ReportContainer>

        <MobileSearchInput
          placeholder="Search Members"
          onChange={handleChange}
          value={search}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
        />

        <Spacer size={10} />
        {tickets.map((ticket) => (
          <MobileContainer
            onClick={() => {
              history.push(
                userType === 'admin'
                  ? `/admin/annualPurchases/purchase/${ticket.id}/?ticketName=${ticket.name}`
                  : `/admin-${userType}/annualPurchases/purchase/${ticket.id}?ticketName=${ticket.name}`
              );
            }}
            key={ticket.id}
          >
            <FlexRow>
              <CardInfo>
                <CardHeader>
                  <div>
                    <TrackName>{ticket.account?.business_dba}</TrackName>
                  </div>
                </CardHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Text
                        type="heading"
                        fontSize={25}
                        lineHeight={28}
                        color="#fff"
                      >
                        {ticket.name}
                      </Text>
                      <Spacer size={10} />
                    </div>
                  </div>
                </div>
              </CardInfo>
              <div style={{ transform: 'rotate(-90deg)' }}>
                <Icon icon="chevron" size={22} color={'red'} />
              </div>
            </FlexRow>
          </MobileContainer>
        ))}
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
    </>
  );
};

export default AnnualPurchaseMobile;
