import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const GET_WEATHER = gql`
  query GetWeather($input: Int!) {
    getWeatherV2(account_id: $input) {
      dailyRange {
        low
        high
      }
      hours {
        timestamp
        shortDescription
        longDescription
        precipitation
        temp
        icon
      }
    }
  }
`;

export function useGetWeather() {
  const {
    state: { accountId },
  } = useContext(AppContext);

  return usePersistedQuery(GET_WEATHER, `get-weather-${accountId}`, {
    variables: { input: accountId },
  });
}
