import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import Loading from 'components/Loading';
import Spacer from 'components/Spacer';
import { useGetAllAccounts } from 'pages/app/Credits/gql/queries/useGetAllAccounts';
import AnnualTicketOrder from './AnnualTicketOrder';
import CreateAnnualType from './gql/CreateCategory';
import { useGetAnnualType } from './gql/GetAnnualType';
import UpdateCategory from './gql/UpdateCategory';

const formValues = [
  'name',
  'account_id',
  'logo_id',
  'logo',
  'annual_tickets',
  'type',
];

const ANNUAL_TYPES = ['membership', 'annual', 'registration'];

function getInitialValues(category, keys) {
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
  };
  const initialValues = !category
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return key === 'account_id'
            ? {
                ...formValues,
                [key]: {
                  value: category['account']?.id,
                  label: category['account']?.business_dba,
                },
              }
            : key === 'type'
            ? {
                ...formValues,
                [key]: {
                  value: category['type'],
                  label: category['type'],
                },
              }
            : category[key]
            ? {
                ...formValues,
                [key]: category[key],
              }
            : formValues;
        }, {}),
      };
  return initialValues;
}

function getTrackInput({ data, currentCategory, action }) {
  const input = {
    ...data,
    ...(action === 'update' && currentCategory
      ? { id: currentCategory }
      : null),
    account_id: data.account_id ? data.account_id.value : '',
    type: data.type ? data.type.value : '',
    annual_tickets: data.annual_tickets
      ? data.annual_tickets.map((ticket, index) => ({
          id: ticket.id,
          order: index,
        }))
      : [],
  };

  formValues.forEach((key) => {
    if (input[key] === '' || input[key] === undefined) delete input[key];
  });

  if (!currentCategory) delete input['annual_tickets'];

  return input;
}

const CategoriesEdit = ({
  isVisible,
  handleOutClick,
  createAnnualType,
  updateAnnualType,
  currentCategory,
  accountFilter,
}) => {
  const successMessage = () =>
    toast.success(currentCategory ? 'Category Updated' : 'Category Created');
  const errorMessage = (response) =>
    toast.error(
      currentCategory
        ? response.global
          ? 'Error Updating Category'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Category'
    );

  const { data: annualTypeData } = useGetAnnualType(currentCategory);

  const { data } = useGetAllAccounts();

  if (currentCategory && !annualTypeData && !annualTypeData?.getAnnualType)
    return null;

  return (
    <Drawer
      title={!currentCategory ? 'Create New Category' : 'Update Category'}
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getInitialValues(
          annualTypeData?.getAnnualType,
          formValues
        )}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }

          if (!values.account_id) {
            errors.account_id = 'Required';
          }

          if (Object.keys(errors).length) {
            toast.error('Please check your form for errors.');
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let response;
          setSubmitting(true);

          const data = Object.entries(values).reduce((acc, [key, value]) => {
            return {
              ...acc,
              [key]: value === '' ? null : value,
            };
          }, {});

          delete data['logo'];

          if (currentCategory) {
            response = await updateAnnualType(
              getTrackInput({ data, currentCategory, action: 'update' })
            );
          } else {
            response = await createAnnualType(
              getTrackInput({ data, currentCategory, action: 'create' }),
              accountFilter
            );
          }

          setSubmitting(false);

          if (!response || response.errors) {
            errorMessage(response);
            return setErrors(response.errors);
          } else {
            successMessage();
            resetForm();
            handleOutClick();
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DrawerPadding border>
              {data && data.getAllAccounts ? (
                <AutoSuggest
                  label="Account"
                  placeholder="Account"
                  options={data.getAllAccounts.map((item) => ({
                    value: item.id,
                    label: item.business_dba,
                  }))}
                  value={values.account_id ? values.account_id : null}
                  isSearchable
                  isClearable
                  onChange={(account) => {
                    setFieldValue('account_id', account);
                  }}
                />
              ) : (
                <Loading />
              )}
              <Spacer size={18} />

              <Input
                id="name"
                label="Category Name"
                placeholder="Category Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name}
              />
              <Spacer size={18} />

              <Dropzone
                id="logo_id"
                files={
                  values.logo_id
                    ? [
                        {
                          name: values.logo_id,
                          preview: values.logo,
                        },
                      ]
                    : []
                }
                onChange={setFieldValue}
                error={errors.logo_id && errors.logo_id}
                label="Category Logo"
              />
              <Spacer size={18} />

              <AutoSuggest
                label="Category Type"
                placeholder="type"
                options={ANNUAL_TYPES.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={values.type ? values.type : null}
                isSearchable
                isClearable
                onChange={(value) => {
                  setFieldValue('type', value);
                }}
              />

              <Spacer size={18} />

              {currentCategory ? (
                <AnnualTicketOrder
                  values={values}
                  handleSort={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                />
              ) : null}
            </DrawerPadding>

            <DrawerPadding>
              <Button type="submit" disabled={isSubmitting} block>
                {currentCategory ? 'Update Category' : 'Add Category'}
              </Button>
            </DrawerPadding>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default compose(CreateAnnualType, UpdateCategory)(CategoriesEdit);
