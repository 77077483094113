import React from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';

const FilterContainer = styled.div`
  min-width: 200px;
  margin-right: 10px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const AnnualTypeFilter = ({ annualType, setAnnualType, isTicket }) => {
  return (
    <FilterContainer>
      <AutoSuggest
        placeholder="Annual Type"
        options={
          isTicket
            ? [
                { value: 'membership', label: 'Memberships' },
                { value: 'annual', label: 'Annual Tickets' },
                { value: 'registration', label: 'Registration' },
              ]
            : [
                { value: 'membership', label: 'Memberships' },
                { value: 'annual', label: 'Annual Tickets' },
              ]
        }
        value={annualType ? annualType : null}
        isSearchable
        isClearable
        onChange={(annualType) => {
          setAnnualType(annualType ?? null);
        }}
      />
    </FilterContainer>
  );
};

export default AnnualTypeFilter;
