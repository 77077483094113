import React from 'react';
import styled from 'styled-components';
import { CardContent, CardLabel, CardText } from 'components/Card/cardStyle';
import { Drawer, DrawerPadding } from 'components/Drawer';
import Spacer from 'components/Spacer';
import { formatTimestamp } from 'shared/formatters';

export const MessageView = styled.p`
  font-size: 5.5vw;
  font-weight: 600;
  line-height: 20px;
  color: #00001f;
  margin-left: 15px;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    color: #00001f;
    text-align: center;
  }
`;

const NotificationView = ({
  isVisible,
  handleOutClick,
  notification,
  getTarget,
}) => {
  if (!notification) return null;

  return (
    <Drawer
      title="Sent Message"
      isVisible={isVisible}
      handleOutClick={() => {
        handleOutClick();
      }}
    >
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #e6e6e6',
          marginTop: 5,
          marginBottom: 5,
        }}
      ></div>
      <DrawerPadding>
        <CardText>
          <CardLabel style={{ width: '30%' }}>From:</CardLabel>{' '}
          <CardContent
            style={{
              width: '70%',
              textAlign: 'left',
              marginLeft: 5,
            }}
          >
            {notification.user ? notification.user.formatted_name : ''}
          </CardContent>
        </CardText>
        <CardText>
          <CardLabel style={{ width: '30%' }}>Sent To:</CardLabel>{' '}
          <CardContent
            style={{
              width: '70%',
              textAlign: 'left',
              marginLeft: 5,
            }}
          >
            {getTarget(notification)}
          </CardContent>
        </CardText>
        <CardText>
          <CardLabel style={{ width: '30%' }}>
            {' '}
            {notification.List ? 'List' : 'Event:'}
          </CardLabel>{' '}
          <CardContent
            style={{
              width: '70%',
              textAlign: 'left',
              marginLeft: 5,
            }}
          >
            {notification.track
              ? notification.track
              : notification.series
              ? notification.series
              : notification.event
              ? notification.event
              : notification.list
              ? notification.list
              : ''}
          </CardContent>
        </CardText>
        <CardText>
          <CardLabel style={{ width: '30%' }}>Sent:</CardLabel>{' '}
          <CardContent
            style={{
              width: '70%',
              textAlign: 'left',
              marginLeft: 5,
            }}
          >
            {formatTimestamp(
              notification.unixTimestamp,
              'MMM DD - YYYY h:mm a'
            ).toUpperCase()}
          </CardContent>
        </CardText>
        <CardText>
          <CardLabel style={{ width: '30%' }}>Msg Type:</CardLabel>{' '}
          <CardContent
            style={{
              width: '70%',
              textAlign: 'left',
              marginLeft: 5,
            }}
          >
            {notification.type
              ? notification.type === 'push'
                ? 'App Push'
                : notification.type.charAt(0).toUpperCase() +
                  notification.type.slice(1)
              : ''}
          </CardContent>
        </CardText>
        <CardText>
          <CardLabel style={{ width: '30%' }}>Recipients:</CardLabel>{' '}
          <CardContent
            style={{
              width: '70%',
              textAlign: 'left',
              marginLeft: 5,
            }}
          >
            {notification.num_users}
          </CardContent>
        </CardText>
        {notification.title ? (
          <CardText>
            <CardLabel style={{ width: '30%' }}>Title:</CardLabel>{' '}
            <CardContent
              style={{
                width: '70%',
                textAlign: 'left',
                marginLeft: 5,
              }}
            >
              {notification.title ?? ''}
            </CardContent>
          </CardText>
        ) : (
          <Spacer size={25} />
        )}

        <CardText>
          <MessageView>"{notification.message ?? ''}"</MessageView>
        </CardText>
      </DrawerPadding>
    </Drawer>
  );
};

export default NotificationView;
