import React, { createContext, useState } from 'react';
import styled from 'styled-components';
import { useGetAllSeries } from 'components/Events/gql';
import { AutoSuggest } from 'components/Form/AutoSuggest';

const EventSeriesFilterContext = createContext();

export const EventSeriesFilterProvider = ({ children }) => {
  const [seriesFilter, setSeriesFilter] = useState();

  return (
    <EventSeriesFilterContext.Provider
      value={{ seriesFilter, setSeriesFilter }}
    >
      {children}
    </EventSeriesFilterContext.Provider>
  );
};

export const withEventSeriesFilterContext = (Component) => {
  return (props) => (
    <EventSeriesFilterProvider>
      <Component {...props} />
    </EventSeriesFilterProvider>
  );
};

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px;
  }
`;

export const EventSeriesFilter = ({ seriesFilter, setSeriesFilter }) => {
  const { data, loading } = useGetAllSeries();

  return loading ? null : (
    <FilterContainer>
      <AutoSuggest
        placeholder="Series"
        options={data.getSeries.map((series) => ({
          label: series.name,
          value: series.id,
        }))}
        isSearchable
        isClearable
        value={
          seriesFilter
            ? {
                label: seriesFilter?.label || null,
                value: seriesFilter?.value || null,
              }
            : null
        }
        onChange={(series) => setSeriesFilter(series)}
      />
    </FilterContainer>
  );
};
