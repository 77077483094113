import React, { lazy, Suspense, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Loading from 'components/Loading';
import { AppContext } from 'shared/AppContext';
import { ScanOptionModal } from './ScanOptionModal';

const BarcodeScanner = lazy(() => import('./BarcodeScanner'));

const Test = styled.div`
  .cameraHandler__message {
    padding: 40px;
    font-size: 20px;
    color: #fff;

    @media (min-width: 768px) {
      color: #000;
    }
  }

  button {
    width: 200px;
    margin-top: 20px;
  }
`;

const ScannerContainer = styled.div`
  z-index: 9999;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
  background: #000000;
  overflow: hidden;
  position: relativ;

  @media (min-width: 768px) {
    height: 90vh;
    width: 80%;
    margin: 0 auto;
  }
`;

const cameraPermissionGranted = () => {
  localStorage.setItem('CAM_PERMISSION', 'true');
};

const isCameraPermissionGranted = () => {
  return localStorage.getItem('CAM_PERMISSION') !== null;
};

const Scan = () => {
  const [isCameraEnabled, setIsCameraEnabled] = useState(
    isCameraPermissionGranted()
  );

  const [isVisible, setIsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    state: { janamScanners },
  } = useContext(AppContext);

  const onCamEnabled = () => {
    cameraPermissionGranted();
    setIsCameraEnabled(true);
  };

  useEffect(() => {
    if (janamScanners) {
      setIsVisible(true);
    }

    return () => {
      setSelectedOption(null);
    };
  }, [janamScanners]);

  return (
    <>
      {!selectedOption && janamScanners ? (
        <Loading />
      ) : (
        <Test>
          {isCameraEnabled || selectedOption === 'janam' ? (
            <Suspense fallback={<div>Loading...</div>}>
              <ScannerContainer>
                <BarcodeScanner useJanamScanner={selectedOption === 'janam'} />
              </ScannerContainer>
            </Suspense>
          ) : (
            <div className="cameraHandler__message">
              Enable your camera with the button below
              <Button
                aria-label="Enable Camera"
                className="btn__round camera__enable"
                onClick={onCamEnabled}
                block
              >
                Turn Camera On
              </Button>
            </div>
          )}
        </Test>
      )}
      <ScanOptionModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        setSelectedOption={setSelectedOption}
      />
    </>
  );
};

export default Scan;

// class CameraHandler extends Component {

//   onCamEnabled = () => {
//     dataHandler.cameraPermissionGranted();
//     this.setState({
//       isCamEnabled: true
//     });
//   }

//   componentWillMount() {
//     if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//       this.setState({
//         isCameraSupported: true
//       });
//     }
//   }

//   render() {
//     return (
//       <>
//         {this.state.isCameraSupported ?
//           this.state.isCamEnabled ?
//           <Suspense fallback={<div>Loading...</div>}>
//             <Video />
//           </Suspense>
//           :
//           <div className="cameraHandler__message">Enable your camera with the button below
//           <br/>
//           <div className="cameraHandler__messageIcon"><ArrowDown size={35}/></div>
//           </div>
//           :
//           <div>Camera is not supported 😢</div>
//         }
//         {this.state.isCamEnabled ?
//           ''
//           :
//           <button aria-label="Enable Camera" className="btn__round camera__enable" onClick={this.onCamEnabled}>
//             <Camera />
//           </button>
//         }
//       </>
//     );
//   }
// }

// export default CameraHandler;
