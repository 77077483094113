import React from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px;
  }
`;

const options = [
  { label: 'By Track', value: 'track' },
  { label: 'By Series', value: 'series' },
  { label: 'By Account', value: 'account' },
  { label: 'Date Range', value: 'date' },
];

export const EventFilterType = ({
  filterType,
  setFilterType,
  resetFilters,
}) => {
  return (
    <FilterContainer>
      <AutoSuggest
        placeholder="Filter Type"
        options={options}
        isSearchable
        isClearable
        value={
          filterType
            ? {
                label: filterType?.label || null,
                value: filterType?.value || null,
              }
            : null
        }
        onChange={(track) => {
          setFilterType(track);
          resetFilters();
        }}
      />
    </FilterContainer>
  );
};
