import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const query = gql`
  query GetCustomersV2($input: GetCustomersInputV2!) {
    getCustomersV2(input: $input) {
      count
      results {
        id
        first_name
        middle_name
        last_name
        formatted_name
        email
        cellphone
        suffix
        inactive
        zipcode
      }
    }
  }
`;

export function useGetCustomers() {
  const {
    state: { accountId },
  } = useContext(AppContext);

  const { input } = useSearchInput();
  const { queryString, page } = input;
  return useQuery(query, {
    variables: { input: { queryString, page, account_id: accountId } },
  });
}
