import React from 'react';
import styled from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { TitleContainer } from 'pages/track/Contact/style';
import Membership from '../../Members/MemberDetail/Components/Membership';
import { useGetMember } from '../../Members/MemberDetail/gql/useGetMember';
import FormsRepeater from './Components/FormsRepeater';
import UserDetail from './Components/UserDetail';
import Waiver from './Components/Waiver';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
  grid-auto-rows: 100px;
  grid-template-areas:
    'a a b b c c d d'
    'a a b b c c d d';
  align-items: start;
  padding: 30px;

  @media (max-width: 768px) {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const GridItem = styled.div`
  text-align: left;
  padding: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

function MemberDetail() {
  const { loading, data } = useGetMember();

  if (!data?.getMember) return <Loading />;

  const member = data.getMember;

  return loading ? (
    <Loading />
  ) : (
    member && (
      <Container>
        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              color="#3C4144"
              inlineStyle={{ marginRight: 25 }}
            >
              Member Detail
            </Text>
          </TitleContainer>
        </ContainerHeader>

        <Membership data={member} />

        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              color="#3C4144"
              inlineStyle={{ marginRight: 25 }}
            >
              Pit Pay User Info
            </Text>
          </TitleContainer>
        </ContainerHeader>

        <UserDetail data={member.user} />

        {member.forms && member.forms.length > 0 && (
          <ContainerHeader>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25 }}
              >
                Forms
              </Text>
            </TitleContainer>
          </ContainerHeader>
        )}

        {member.forms &&
          member.forms.length > 0 &&
          member.forms.map((form) => (
            <FormsRepeater formData={form} key={form.id} />
          ))}

        {member.waivers && member.waivers.length > 0 && (
          <ContainerHeader>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25 }}
              >
                Agreements
              </Text>
            </TitleContainer>
          </ContainerHeader>
        )}

        <Grid>
          {member.waivers &&
            member.waivers.length > 0 &&
            member.waivers.map((waiver) => (
              <GridItem key={waiver.id}>
                <Waiver waiver={waiver} />
              </GridItem>
            ))}
        </Grid>
      </Container>
    )
  );
}

export default MemberDetail;
