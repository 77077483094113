import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_REFUND_ESTIMATE = gql`
  query GetRefundEstimate($refundEstimateInput: RefundEstimateInput!) {
    refundEstimate(input: $refundEstimateInput) {
      refund_amount
      new_purchase_total
      previous_purchase_total
    }
  }
`;

export function useGetRefundEstimate(transaction_id, pass_ids, include_fees) {
  return useQuery(GET_REFUND_ESTIMATE, {
    variables: {
      refundEstimateInput: {
        transaction_id: transaction_id,
        pass_ids: [...pass_ids],
        include_fees: include_fees,
      },
    },
    skip: !transaction_id || !pass_ids || !pass_ids.length,
  });
}
