import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';
import qs from 'qs';
import { GET_ACCOUNT_OPEN_CREDITS } from './get-user-open-credits-by-account';

export default graphql(
  gql`
    mutation DeleteCredit($input: DeleteCreditInput!) {
      deleteCredit(input: $input)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteCredit: async (input, variables) => {
        return mutator(() =>
          mutate({
            variables: { input: input },
            update: (proxy, { data: { deleteCredit } }) => {
              const search = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
              });

              if (!deleteCredit) {
                return;
              }

              const data = proxy.readQuery({
                query: GET_ACCOUNT_OPEN_CREDITS,
                variables: { input: { ...variables, ...search } },
              });

              proxy.writeQuery({
                query: GET_ACCOUNT_OPEN_CREDITS,
                variables: { input: { ...variables, ...search } },
                data: {
                  ...data,
                  getUserOpenCreditsByAccount: {
                    ...data.getUserOpenCreditsByAccount,
                    count: data.getUserOpenCreditsByAccount.count - 1,
                    results: data.getUserOpenCreditsByAccount.results.filter(
                      (credit) => credit.id !== input.id
                    ),
                  },
                },
              });
            },
          })
        );
      },
    }),
  }
);
