import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_EVENTS_ANNUAL_ADMIN = gql`
  query GetEventsAnnualAdmin($input: EventsAnnualAdminInput!) {
    getEventsAnnualAdmin(input: $input) {
      count
      results {
        id
        name
        start_date
        isMultiDay
        end_date
      }
    }
  }
`;

export function useGetEventsAnnualAdmin(series_ids, track_ids) {
  const seriesOrTrack = series_ids.length > 0 || track_ids.length > 0;

  const variables = {
    input: {
      ...(series_ids && series_ids.length > 0
        ? { series_ids: series_ids }
        : null),
      ...(track_ids && track_ids.length > 0 ? { track_ids: track_ids } : null),
    },
  };

  return useQuery(GET_EVENTS_ANNUAL_ADMIN, { variables, skip: !seriesOrTrack });
}
