import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetAllCategories {
    getAllCategories {
      id
      name
    }
  }
`;

export function useGetAllCategories() {
  return useQuery(query);
}
