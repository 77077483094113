import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const GET_ACCOUNT_OPEN_CREDITS_REPORT = gql`
  mutation GetUserOpenCreditsByAccountReport($input: GetUserCreditsInput!) {
    getUserOpenCreditsByAccountReport(input: $input) {
      count
      results {
        id
        user {
          id
          formatted_name
        }
        amount
        date
        issued_by {
          formatted_name
        }
        track_credit
        fee_credit
        original_amount
      }
    }
  }
`;

export function useGetUserOpenCreditsReportTrack(yearFilter) {
  const { id: user_id } = useParams();
  const {
    state: { accountId },
  } = useContext(AppContext);
  const { input: search } = useSearchInput();

  const [userOpenCreditReportMutation] = useMutation(
    GET_ACCOUNT_OPEN_CREDITS_REPORT
  );

  const result = useCallback(
    (options = {}) => {
      return userOpenCreditReportMutation({
        variables: {
          input: {
            ...search,
            account_id: Number(accountId),
            user_id: Number(user_id),
            ...(yearFilter ? { year: yearFilter } : null),
          },
        },
        ...options,
      });
    },
    [userOpenCreditReportMutation, search, accountId, user_id, yearFilter]
  );
  return result;
}
