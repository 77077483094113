import React from 'react';
import EventReportHeader from './event-header';
import SeriesHeader from './series-header';
import TrackHeader from './TrackHeader';

export const EventHeader = (props) => {
  const { type, id, margin } = props;

  return 'track' === type ? (
    <TrackHeader id={id} margin={margin} type={type} />
  ) : 'series' === type ? (
    <SeriesHeader id={id} margin={margin} type={type} />
  ) : (
    <EventReportHeader margin={margin} type={type} />
  );
};
