import React from 'react';
import { Checkbox } from 'components/Form/Checkbox';
import Text from 'components/Text';
import { PassItem } from './styles';

export const Tickets = ({
  ticket,
  onChange,
  selectedTickets,
  employeeAdmin,
  isRefund,
}) => {
  return (
    <PassItem>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Checkbox
          inversed
          name="checkbox"
          check={
            ticket.refunded === false || (isRefund && ticket.credited)
              ? true
              : false
          }
          checked={
            (isRefund
              ? !!ticket.refunded && !ticket.credited
              : !!ticket.refunded) ||
            !!selectedTickets.find(
              (selectedTicket) => selectedTicket.id === ticket.id
            )
          }
          onChange={() => {
            if (employeeAdmin) return;
            if (
              isRefund ? !!ticket.refunded && !ticket.credited : ticket.refunded
            )
              return;
            const ticketExists = selectedTickets.find(
              (selectedTicket) => selectedTicket.id === ticket.id
            );

            if (ticketExists)
              return onChange(
                selectedTickets.filter(
                  (selectedTicket) => selectedTicket.id !== ticket.id
                )
              );

            return onChange([
              ...(selectedTickets || []),
              { ...ticket, refunded: false },
            ]);
          }}
          size={[36, 20, 3]}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              whiteSpace: 'normal',
            }}
          >
            {ticket.name} {(ticket.section || ticket.row || ticket.seat) && '-'}{' '}
            {ticket.section}
            {ticket.section && ','}
            {ticket.row}
            {ticket.row && ','}
            {ticket.seat}
          </Text>
          <Text
            inlineStyle={{
              fontWeight: '600',
              fontSize: 20,
              marginLeft: 10,
              marginTop: 5,
              whiteSpace: 'normal',
            }}
          >
            {ticket.barcode}
          </Text>
        </div>
      </div>
      <Text
        inlineStyle={{
          fontWeight: '600',
          fontSize: 20,
          marginLeft: 20,
        }}
      >
        {ticket.price}
      </Text>
    </PassItem>
  );
};
