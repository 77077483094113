import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_ANNUAL_TYPES_BY_ACCOUNT_ADMIN = gql`
  query GetAnnualTypesByAccountAdmin($input: Int) {
    getAnnualTypesByAccountAdmin(account_id: $input) {
      count
      results {
        id
        name
      }
    }
  }
`;

export function useGetAnnualTypesByAccountAdmin(account_id) {
  const variables = {
    input: account_id ? Number(account_id) : null,
  };

  return useQuery(GET_ANNUAL_TYPES_BY_ACCOUNT_ADMIN, { variables });
}
