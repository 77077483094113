import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetSeriesDetail($input: GetSeriesDetailInput!) {
    getSeriesDetail(input: $input) {
      id
      name
      infos {
        id
        logo
        logo_id
        link
        text
        info {
          id
          name
        }
        order
        video_url
        image_id
        image_url
        pdf_id
        pdf_url
      }
    }
  }
`;

export function useGetSeriesInfo(currentSeries) {
  const { data, loading, error } = useQuery(query, {
    variables: { input: { id: currentSeries ? currentSeries : 0 } },
    fetchPolicy: 'no-cache',
    skip: !currentSeries,
  });
  return { data, loading, error };
}
