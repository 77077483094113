import React from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from 'components/Icon';
// import { WaiverDownloadButton } from 'components/Participants';
import { WaiverNotSigned } from 'components/SVG/WaiverNotSigned';
import Text from 'components/Text';
import { DownloadMemberWaiverButton } from './DownloadWaiver';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-inline: auto;
  }
`;
const Waiver = ({ waiver }) => {
  const theme = useTheme();
  return (
    <Container>
      {!waiver.is_signed ? (
        <WaiverNotSigned
          width={40}
          style={{ padding: 5, marginInline: 'auto' }}
        />
      ) : (
        <DownloadMemberWaiverButton
          waiver_id={waiver.id}
          icon={
            <Icon icon="Waiver-Icon" color={theme.colors.primary} size={40} />
          }
          transparent={true}
        />
      )}
      <Text type="label" inlineStyle={{ textAlign: 'center' }}>
        {waiver.name}
      </Text>
    </Container>
  );
};

export default Waiver;
