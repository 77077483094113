import React, { useState } from 'react';
import Spacer from 'components/Spacer';
import {
  GuestForm,
  GuestSearch,
  GuestSearchWrapper,
  useGuestSearch,
  withGuestSearchContext,
} from 'pages/track/Guests';
import { ModalSmsNotification } from 'pages/track/Guests/ModalSmsNotification';
import { formatPhoneNumber } from 'shared/formatters';
import { useGetEventAndEventGuests } from '../../gql/useGetEventAndEventGuests';
import { PrintGuestList } from '../PrintGuestList';
import { List } from './GuestList';
import Header from './header';

export const GuestListMobile = withGuestSearchContext((props) => {
  const { guestSearchQuery: queryString } = useGuestSearch();

  const userType = props.location.pathname.includes('/admin-track/')
    ? 'track'
    : 'employee';

  const [guestTypeToAdd, setGuestTypeToAdd] = useState(null);
  const [newGuestData, setNewGuestData] = useState(null);
  const { data } = useGetEventAndEventGuests({ queryString });

  const event = data?.getEvent;
  const guests = data?.getEventGuests;

  if (!event && !guests) return null;

  async function onSetNewGuestData(guest) {
    setNewGuestData(guest);
  }

  return (
    <>
      <div>
        <Spacer size={10} />
        <Header />
        <GuestSearchWrapper
          style={{ margin: '0 10px', width: 'calc(100% - 20px)' }}
        >
          <GuestSearch />
        </GuestSearchWrapper>
        <Spacer size={20} />
        <List
          guests={guests?.event_guests ?? []}
          title="Single Event Guests"
          handleAddClick={
            userType !== 'employee'
              ? () => setGuestTypeToAdd('event_guests')
              : null
          }
        />
        <Spacer size={20} />
        <List
          guests={guests?.yearly_guests ?? []}
          title="Full Season Guests"
          handleAddClick={
            userType !== 'employee'
              ? () => setGuestTypeToAdd('event_guests')
              : null
          }
        />
        <Spacer size={20} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0 10px',
          }}
        >
          <PrintGuestList
            guests={guests}
            event={event}
            buttonLabel="Print List"
          />
        </div>
      </div>

      <GuestForm
        isVisible={!!guestTypeToAdd}
        handleOutClick={() => setGuestTypeToAdd(null)}
        guestTypeToAdd={guestTypeToAdd}
        queryString={queryString}
        setNewGuestData={onSetNewGuestData}
      />

      <ModalSmsNotification
        isVisible={newGuestData !== null}
        cancelText="Close"
        title="New Guest Added"
        cellphone={formatPhoneNumber(newGuestData?.phone_number)}
        body={`${newGuestData?.first_name} ${newGuestData?.last_name}, has been added, you can send them a message at this time.`}
        hideModal={() => {
          setNewGuestData(null);
        }}
      />
    </>
  );
});
