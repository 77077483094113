import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback, useContext } from 'react';
import { useEventYearFilter } from 'components/Events';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';

export const EVENTS_REPORT = gql`
  mutation GetTrackEventsReport($input: GetAccountEventsInput!) {
    getTrackEventsReport(input: $input) {
      count
      results {
        id
        fullDate
        start_date
        added_by
        end_date
        name
        eventDates
        admin_website_url
        admin_schedule_url
        date
        month
        listDates
        year
        day
        fullDate
        nextStartTime
        isMultiDay
        nextGateTime
        nextFullDate
        status
        sale_start
        track {
          id
          name
        }
        user {
          id
          first_name
          last_name
        }
        series {
          id
          name
        }
        status
        color_code
        isMultiDay
      }
    }
  }
`;

export function useGetTrackEventsReport() {
  const { yearFilter } = useEventYearFilter();
  const { input: routeSearch } = useSearchInput();
  const {
    state: { accountId },
  } = useContext(AppContext);

  const [eventReportMutation] = useMutation(EVENTS_REPORT);

  const result = useCallback(
    (options = {}) => {
      return eventReportMutation({
        variables: {
          input: {
            ...routeSearch,
            ...(yearFilter ? { year: yearFilter } : null),
            ...(accountId ? { account_id: accountId } : null),
          },
          ...options,
        },
        skip: !accountId,
      });
    },
    [eventReportMutation, accountId, routeSearch, yearFilter]
  );
  return result;
}
