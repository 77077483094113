import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { AppContext } from 'shared/AppContext';
import { GuestFieldsFragment } from './guest.fragments';

export const GET_GUESTS = gql`
  query GetGuests($input: GetGuestsInput!) {
    getGuestsV2(input: $input) {
      count
      results {
        ...guestFields
      }
    }
  }
  ${GuestFieldsFragment}
`;

export function useGetGuests({ queryString }) {
  const {
    state: { accountId },
  } = useContext(AppContext);

  return useQuery(GET_GUESTS, {
    variables: { input: { queryString, account_id: accountId } },
  });
}
