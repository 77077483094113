import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loading from 'components/Loading';
import useWindowSize from 'hooks/useWindowSize';
import { AppContext } from 'shared/AppContext';
import storage from 'shared/storage';
import AdminLayout from '../../layouts/AdminLayout';
import AdminMobileLayout from '../../layouts/AdminMobileLayout';

const AdminRoute = ({ component: Component, backgroundColor, ...rest }) => {
  const [width] = useWindowSize();

  const {
    state: { accountId },
  } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        const user = storage.get('user', {});
        // const capability = storage.get('capability', {});

        if (typeof user.role !== 'undefined') {
          if (user.role !== 'track' && rest.path !== '/admin-track/stripe')
            return (
              <Redirect
                to={{
                  pathname: '/admin',
                  state: { from: props.location },
                }}
              />
            );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }

        // if (props?.location?.pathname?.includes('/admin-track/guest-lists')) {
        //   if (!capability?.guests)
        //     return (
        //       <Redirect
        //         to={{
        //           pathname: '/',
        //           state: { from: props.location },
        //           accessDenied: true,
        //         }}
        //       />
        //     );
        // }

        return (
          <div>
            {width <= 860 ? (
              <AdminMobileLayout backgroundColor={backgroundColor}>
                {accountId ? <Component {...props} /> : <Loading />}
              </AdminMobileLayout>
            ) : (
              <AdminLayout>
                {accountId ? <Component {...props} /> : <Loading />}
              </AdminLayout>
            )}
          </div>
        );
      }}
    />
  );
};

export default AdminRoute;
