import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PrintButton } from 'components/PrintButton';
import colors from 'components/ThemeProvider/colors';
import useTheme from 'hooks/useTheme';
import { timeout } from 'shared/timeout';
import checkbox from '../../../../Transactions/TransactionsPrintList/checkbox.png';
import { KartPassLogo } from '../../../../Transactions/TransactionsPrintList/kartpass-logo.js';
import { Logo } from '../../../../Transactions/TransactionsPrintList/logo';
import { TicketHossLogo } from '../../../../Transactions/TransactionsPrintList/tickethoss-logo';
import { useGetAccountOpenCreditsReport } from '../gql/useGetAccountOpenCreditsReport.mutation';
import { useGetUserOpenCreditsReport } from '../gql/useGetUserOpenCreditsReport.mutation';
import { useGetUserOpenCreditsReportTrack } from '../gql/useGetUserOpenCreditsReportTrack.mutation';

export const PrintOpenCreditsReport = ({
  text,
  headerText,
  year,
  isTrackAdmin,
  isAccountReport,
  totalCredit,
}) => {
  const getUserOpenCreditsReport = useGetUserOpenCreditsReport();
  const getUserOpenCreditReportTrack = useGetUserOpenCreditsReportTrack(year);
  const getAccountOpenCreditReport = useGetAccountOpenCreditsReport(year);

  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();

  const [filename, setFileName] = useState('');

  const getData = async (headerText) => {
    try {
      const response = isAccountReport
        ? await getAccountOpenCreditReport(year)
        : isTrackAdmin
        ? await getUserOpenCreditReportTrack(year)
        : await getUserOpenCreditsReport(year);
      let title = '';
      const userOpenCreditsReport = isAccountReport
        ? response.data.getAccountOpenCreditsReport
        : response.data.getUserOpenCreditsByAccountReport;

      title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      const filenameNew = `${headerText}${
        isAccountReport
          ? ' - Account Open Credit List'
          : ' - User Open Credit List'
      }${title ? ` - ${title}` : ''}`;

      setFileName(filenameNew);

      return { userOpenCreditsReport, title, filename: filenameNew };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      toast.error('Error generating report');
      setDisabled(false);
    }
  };

  function generatePdf() {
    setDisabled(true);
    return new Promise(async (resolve) => {
      const pdf = await exportPDF();

      setDisabled(false);
      if (filename) {
        pdf.setProperties({
          title: filename,
        });
      }
      if (
        navigator.userAgent.includes('iPhone') &&
        navigator.maxTouchPoints > 1
      ) {
        var blob = new Blob([pdf.output('blob')], {
          type: 'data:application/pdf,',
        });
        FileSaver.saveAs(blob, filename + '.pdf');
      } else if (navigator.maxTouchPoints > 1) {
        pdf.output('datauri', filename + '.pdf');
      } else {
        window.open(
          pdf.output('bloburl', { filename: filename + '.pdf' }),
          '_blank'
        );
      }
      timeout(resolve, 6000);
    });
  }

  //This will generate the spectator PDF report
  const exportPDF = async () => {
    const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
    const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

    const { userOpenCreditsReport, title, filename } = await getData(
      headerText
    );

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(title, pageWidth - 650, {});
    doc.text(headerText, 40, 40);
    doc.text(text, 40, 60);
    doc.text(totalCredit ? `Total Open Credits - ${totalCredit}` : '', 40, 80);

    if (checkbox) {
      if (isKartPass) {
        doc.addImage(KartPassLogo, 'PNG', 650, 50, 100, 75, '', 'FAST');
      } else if (isTicketHoss) {
        doc.addImage(TicketHossLogo, 'PNG', 660, 65, 100, 54, '', 'FAST');
      } else {
        doc.addImage(Logo, 'PNG', 660, 50, 100, 100, '', 'FAST');
      }
    }

    doc.text(
      isAccountReport ? 'Account Open Credit List' : 'User Open Credits List',
      620,
      170
    );

    const headers = [
      [
        'Credit Balance',
        'Original Balance',
        'Track Credit',
        'Fee Credit',
        'Credit #',
        'Issued',
        'Issued By',
        'Ticket Holder',
      ],
    ];

    const tableData = userOpenCreditsReport.results.map((credit) => {
      const {
        id,
        user,
        amount,
        date,
        issued_by,
        original_amount,
        track_credit,
        fee_credit,
      } = credit;

      return [
        amount,
        original_amount,
        track_credit,
        fee_credit,
        id,
        date,
        issued_by?.formatted_name,
        user?.formatted_name,
      ];
    });

    doc.autoTable({
      startY: 190,
      rowPageBreak: 'avoid',
      columns: [
        { dataKey: 'credit', header: 'Credit Balance' },
        { dataKey: 'originalTotal', header: 'Original Balance' },
        { dataKey: 'trackCredit', header: 'Track Credit' },
        { dataKey: 'feeCredit', header: 'Fee Credit' },
        { dataKey: 'creditNum', header: 'Credit #' },
        { dataKey: 'issued', header: 'Issued' },
        { dataKey: 'issuedBy', header: 'Issued By' },
        { dataKey: 'ticketHolder', header: 'Ticket Holder' },
      ],
      head: headers,
      body: tableData,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: '#fa4616',
      },
      columnStyles: {
        Event: {
          cellWidth: 200,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 5 },
          textColor: theme.colors.text.black,
        },
      },
      didParseCell: function (tableData) {
        const hasNoWaiver = tableData.row.raw[6] === false;

        if (hasNoWaiver) {
          tableData.cell.styles.fillColor = '#feece8';
          tableData.cell.styles.textColor = colors.text.gray;
          tableData.cell.styles.opacity = 0.1;
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: filename,
      subject: 'Accounts List',
      author: process.env.REACT_APP_PLATFORM,
    });
    return doc;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <PrintButton
        buildPdf={generatePdf}
        text={text}
        variant="minimal"
        disabled={disabled}
      />
    </div>
  );
};
