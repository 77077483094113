import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const query = gql`
  mutation GetUserExportV2 {
    getUserExportV2 {
      id
      first_name
      middle_name
      address
      last_name
      email
      role
      cellphone
      name
      suffix
      races
      purchases
      lifetimeValue
      favoritesCount
    }
  }
`;

export function useGetUserExport() {
  const [getUserExportMutation] = useMutation(query);

  const result = useCallback(() => {
    return getUserExportMutation({
      variables: {},
    });
  }, [getUserExportMutation]);
  return result;
}
