import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { useTheme, withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import CategoriesEdit from './AddCategory/CategoryEdit';
import DeleteCategory from './gql/DeleteCategory';
import { useGetCategories } from './gql/UseGetCategories';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const Categories = ({ deleteCategory }) => {
  const [search, setSearch] = useState('');
  const theme = useTheme();

  const [editCategory, setEditCategory] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleOutClick = () => {
    setIsVisible(!isVisible);
    setEditCategory(null);
  };

  const [categories, setCategories] = useState([]);
  const [count, setCount] = useState();

  const { data } = useGetCategories();

  useEffect(() => {
    if (data && data.getCategories) {
      setCategories(data?.getCategories?.results);
      setCount(data?.getCategories?.count);
    }
  }, [data]);

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Type',
      key: 'type',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(category) {
    const { id, name, type } = category;
    return {
      name,
      type,
      actions: (
        <div style={{ width: 61 }}>
          <Icon
            icon="edit"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              setEditCategory(category);
              setIsVisible(true);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              if (
                window.confirm('Are you sure you want to delete this Category?')
              ) {
                const response = await deleteCategory(id);
                if (response && !response.errors) {
                  toast.success('Category Deleted Successfully');
                } else {
                  toast.error('Error deleting Category');
                }
              } else {
                return;
              }
            }}
          />
        </div>
      ),
    };
  }

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const urlParams = new URLSearchParams(window.location.queryString);
  const currentPage = parseInt(urlParams.get('page'));

  if (!categories) return null;

  return (
    <>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Categories
              </Text>

              <div style={{ marginLeft: 15 }}>
                <SearchInput
                  placeholder="Search Categories"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div>
            </TitleContainer>
            <CreditButton>
              <Button
                onClick={() => setIsVisible(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Add Category'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>

        <div style={{ padding: 20 }}>
          <Table items={categories} columns={columns} renderRows={renderRows} />
          <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>

        <CategoriesEdit
          isVisible={isVisible}
          handleOutClick={handleOutClick}
          currentCategory={editCategory}
        />
      </Container>
    </>
  );
};

export default withTheme(DeleteCategory(Categories));
