import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { formatPhoneNumber } from 'shared/formatters';
import { useGetAccountMembersReport } from './gql/useGetAccountMembersReport.mutation';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 860px) {
    margin: 0;
    border: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;

    @media (min-width: 860px) {
      text-align: center;
      font-size: 12px;
      color: #3c4144;
      text-align: center;
      margin-top: 10px;
    }
  }

  svg {
    fill: #fff;
    margin-right: 10px;

    @media (min-width: 860px) {
      fill: #fa4616;
      margin: 0;
    }
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async (props, getAccountMembersReport) => {
  try {
    const { accountId, year, typeId, division, search, type } = props;
    const response = await getAccountMembersReport(
      accountId,
      year,
      typeId,
      division,
      search,
      type
    );

    if (!response || response.errors) {
      toast.error('Account Members List can not be retrieved');
      return;
    }

    if (response.data.getAccountMembersReport) {
      const { results } = response.data.getAccountMembersReport;

      let _title = '',
        filename = '';

      _title = moment().format('MMM DD - YYYY h:mm A').toUpperCase();

      filename = 'Account Members List - ' + _title;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const index = results.findIndex((item) => item.forms.length > 0) ?? 0;

      const csv = `Account Members List
      Exported:, ${dateTimeStamp} \n,,,
      Name,Email,Phone,Member ID,Membership Name,Division, Reserved, Price, ${
        results[index]?.forms[0]?.data?.length
          ? results[index].forms[0].data.map(
              (item) => `${item.label.replace(/,/g, '')}`
            )
          : ''
      }
      ${Object.values(results)
        .map(
          (value) =>
            `${value.user.formatted_name.replace(/,/g, '') ?? ''},${
              value.user.email ?? ''
            },${formatPhoneNumber(value.user.cellphone) ?? ''},${
              value.serial_number ?? ''
            },${value.membership.name ?? ''},${value.divison ?? ''},${
              value.section || value.row || value.seat
                ? `${value.section ? value.section : ''}${
                    value.section && (value.row || value.seat) ? ',' : ''
                  } 
      ${value.row ? value.row : ''}${value.row && value.seat ? ',' : ''} 
      ${value.seat ? value.seat : ''}`
                : ''
            },${value.cost},${
              value.forms && value.forms.length
                ? value.forms[0].data.reduce((acc, item) => {
                    return item.value
                      ? typeof item.value === 'boolean'
                        ? true === item.value
                          ? acc.concat('Yes,')
                          : acc.concat('No,')
                        : acc.concat(
                            `${item.value
                              .replace(/,/g, '')
                              .replace(/(\r\n|\n|\r)/g, '')},`
                          )
                      : acc.concat(',');
                  }, '')
                : ''
            },`
        )
        .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Account Members List can not be retrieved');
    }
  } catch (e) {}
};

const ExportAccountMembers = ({ icon, ...props }) => {
  const getAccountMembersReport = useGetAccountMembersReport();

  return (
    <div>
      <Container onClick={() => exportCsv(props, getAccountMembersReport)}>
        <IconWrapper>
          {icon}
          <span>Export List</span>
        </IconWrapper>
      </Container>
    </div>
  );
};

export default ExportAccountMembers;
