import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import styled from 'styled-components';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import Loading from 'components/Loading';
import SearchUsers from '../gql/queries/search-users';

const NoSuggestion = styled.div`
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  margin-top: 10px;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 95%;
  padding: 0.5rem;
`;

const Suggestions = styled.ul`
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  list-style: none;
  margin-top: 10px;
  max-height: 143px;
  padding-left: 0;
  width: 100%;
  z-index: 100;
  .suggestion-active {
    background-color: #deebff;
    cursor: pointer;
    margin: 3px 0;
  }
`;

const SuggestionsList = styled.li`
  padding: 0.5rem;
  &:hover {
    background-color: #deebff;
    cursor: pointer;
  }
`;

const loadOptions = async (
  inputValue,
  setShowLoading,
  setSuggestions,
  data,
  showForm,
  hideForm,
  setShowEmailField
) => {
  if (inputValue && inputValue?.length > 10) {
    hideForm();
    setShowLoading(true);
    const response = await data.refetch({ query: inputValue });

    if (response.data.searchUsers && response.data.searchUsers.length) {
      const options = response.data.searchUsers.map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        user_id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        cellphone: item.cellphone,
        country_code: item.country_code,
        calling_code: item.calling_code,
        email: item.email,
      }));
      setSuggestions(options);

      setShowLoading(false);
    } else {
      setShowLoading(false);

      if (setShowEmailField) setShowEmailField(false);
      showForm(inputValue);
      return setSuggestions([]);
    }
  } else {
    return setSuggestions([]);
  }
};

const EmployeeSearch = ({
  value,
  showForm,
  hideForm,
  data,
  setFieldValue,
  setShowEmailField,
  showSuggestion = true,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const { setValues, values } = useFormikContext();
  const [showLoading, setShowLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const onChange = async (value) => {
    hideForm();
    await loadOptions(
      value,
      setShowLoading,
      setSuggestions,
      data,
      showForm,
      hideForm,
      setShowEmailField
    );
    setShowSuggestions(true);
  };

  const onClick = (selectedOption) => {
    const {
      label = '',
      user_id = '',
      cellphone = '',
      country_code = values.country_code ?? 'US',
      calling_code = values.calling_code ?? '1',
      email = '',
      first_name = '',
      last_name = '',
    } = selectedOption ?? {};

    setValues(
      {
        ...values,
        first_name,
        last_name,
        cellphone,
        country_code,
        calling_code,
        email,
        ownership: values.ownership ?? '',
        user_id,
        user: label,
      },
      false
    );
    setSuggestions([]);
    setShowSuggestions(false);
    hideForm();
    if (!email && setShowEmailField) setShowEmailField(true);
  };

  const SuggestionsListComponent = () => {
    return suggestions && suggestions?.length ? (
      <Suggestions>
        {suggestions.map((suggestion, index) => {
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <SuggestionsList
              key={index}
              className={'suggestion-active'}
              onClick={() => onClick(suggestion)}
            >
              {suggestion.label}
            </SuggestionsList>
          );
        })}
      </Suggestions>
    ) : showLoading ? (
      <Loading size={45} />
    ) : showSuggestion ? (
      <NoSuggestion>
        No match found. Fill out the form to add a new staff member
      </NoSuggestion>
    ) : null;
  };

  return (
    <div>
      {!values.user ? (
        <PhoneInput
          id="employee"
          placeholder="___ ___ ____"
          size={25}
          label="Search Phone Number"
          onChange={(value) => onChange(value)}
          onCountryChange={(e) => {
            if (e) {
              setFieldValue('country_code', e ?? '');
              setFieldValue('calling_code', getCountryCallingCode(e) ?? '');
            }
          }}
          value={value}
        />
      ) : (
        <Input
          id="user"
          label="Search User"
          onChange={() => {
            setFieldValue('user', '');
          }}
          value={values.user}
          type="search"
        />
      )}
      {showSuggestions && <SuggestionsListComponent />}
    </div>
  );
};

export default SearchUsers(EmployeeSearch);
