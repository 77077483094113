import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useWindowSize from 'hooks/useWindowSize';
import storage from 'shared/storage';
import AdminLayout from '../../layouts/AdminLayout';
import AdminMobileLayout from '../../layouts/AdminMobileLayout';

const AdminRoute = ({ component: Component, backgroundColor, ...rest }) => {
  const [width] = useWindowSize();

  const { pathname, search } = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const user = storage.get('user', {});

        if (typeof user.role !== 'undefined') {
          if (user.role !== 'admin') {
            return (
              <Redirect
                to={{
                  pathname: `${pathname.replace(
                    '/admin',
                    `/admin-${user.role}`
                  )}`,
                  state: { from: props.location },
                  search: search,
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          // <AdminLayout>
          //   <Component {...props} />
          // </AdminLayout>

          <div>
            {width <= 860 ? (
              <AdminMobileLayout backgroundColor={backgroundColor}>
                <Component {...props} />
              </AdminMobileLayout>
            ) : (
              <AdminLayout>
                <Component {...props} />
              </AdminLayout>
            )}
          </div>
        );
      }}
    />
  );
};

export default AdminRoute;
