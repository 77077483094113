import React, { Fragment } from 'react';
import {
  Wrapper,
  LabelText,
  Item,
  LabelRow,
  RadioButton,
  RadioButtonLabel,
  DescriptionText,
  DescriptionWrapper,
} from './radioStyles';

export const Radio = ({ name, value, options, onChange, title }) => {
  return (
    <Wrapper>
      {title ? (
        <LabelText style={{ marginBottom: 5 }}>{title}</LabelText>
      ) : null}
      {options.map((item) => (
        <Fragment key={item.value}>
          <Item key={item.value}>
            <LabelRow>
              <RadioButton
                key={item.value}
                type="radio"
                name={name}
                value={item.value}
                checked={value === item.value}
                onChange={(event) => onChange(event)}
              />
              <RadioButtonLabel />

              <LabelText>{item.label}</LabelText>
            </LabelRow>
          </Item>
          <div style={{ width: '80%', margin: 'auto' }}>
            <DescriptionWrapper>
              {item.description
                ? item.description.map((desc, index) => (
                    <DescriptionText key={index}>{desc}</DescriptionText>
                  ))
                : null}
            </DescriptionWrapper>
          </div>
        </Fragment>
      ))}
    </Wrapper>
  );
};
