import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { AppContext } from 'shared/AppContext';

export const GET_ACCOUNT_CREDITS_BREAKDOWN = gql`
  query GetAccountCreditsBreakdown($account_id: Int!) {
    getAccountCreditsBreakdown(account_id: $account_id) {
      year
      total
      open
      redeemed
    }
  }
`;

export function useGetAccountCreditsBreakdown() {
  const {
    state: { accountId },
  } = useContext(AppContext);

  const result = usePersistedQuery(
    GET_ACCOUNT_CREDITS_BREAKDOWN,
    `account-credits-breakdown-${accountId}`,
    {
      variables: { account_id: accountId },
      fetchPolicy: 'cache-and-network',
    }
  );

  return { ...result, res: result.data };
}
